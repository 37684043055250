module.exports = {
  AWSAmplify: {
    Auth: {
      identityPoolId: 'us-east-1:9ee3c643-4780-45ca-a6a4-6725c72a24a2',
      region: 'us-east-1',
      userPoolId: 'us-east-1_K4CsBwcO1',
      userPoolWebClientId: '6o04099vml88ilar0pnq7cf66f',
      // cookieStorage: {
      //   domain,
      //   path: '/',
      //   expires: 7,
      //   sameSite: 'strict',
      //   secure: true
      // }
    }
  }
}
