import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Authenticator from './Authenticator'
import Greetings from './Greetings'

export { default as Authenticator } from './Authenticator'
export { default as AuthPiece } from './AuthPiece'
export { default as SignIn } from './SignIn'
export { default as ConfirmSignIn } from './ConfirmSignIn'
export { default as RequireNewPassword } from './RequireNewPassword'
export { default as SignUp } from './SignUp'
export { default as ConfirmSignUp } from './ConfirmSignUp'
export { default as ForgotPassword } from './ForgotPassword'
export { default as Greetings } from './Greetings'

export function withAuthenticator(
  Comp,
  includeGreetings = false,
  authenticatorComponents = [],
) {
  return class extends Component {
    constructor(props) {
      super(props)

      this.handleAuthStateChange = this.handleAuthStateChange.bind(this)

      this.state = {
        authState: props.authState || null,
        authData: props.authData || null,
      }
    }

    handleAuthStateChange(state, data) {
      this.setState({ authState: state, authData: data })
    }

    render() {
      const { authState, authData } = this.state
      const signedIn = authState === 'signedIn'

      if (signedIn) {
        return (
          <div>
            {includeGreetings ? (
              <Greetings
                authState={authState}
                authData={authData}
                onStateChange={this.handleAuthStateChange}
              />
            ) : null}
            <Comp
              {...this.props}
              authState={authState}
              authData={authData}
              onStateChange={this.handleAuthStateChange}
            />
          </div>
        )
      }

      return (
        <Authenticator
          {...this.props}
          hideDefault={authenticatorComponents.length > 0}
          onStateChange={this.handleAuthStateChange}
          children={authenticatorComponents}
        />
      )
    }
  }
}

export class AuthenticatorWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = { auth: 'init' }

    this.handleAuthState = this.handleAuthState.bind(this)
  }

  handleAuthState(state, data) {
    this.setState({ auth: state, authData: data })
  }

  render() {
    return (
      <div>
        <Authenticator {...this.props} onStateChange={this.handleAuthState} />
        {this.props.children(this.state.auth)}
      </div>
    )
  }
}

AuthenticatorWrapper.propTypes = {
  children: PropTypes.func.isRequired,
}
