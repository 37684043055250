import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardLegend(theme)
)

class ScorecardLegend extends React.PureComponent {
  render() {
    const {
      classes,
      config: { labels, legendDetails },
      sampleSizeThreshold,
      genericConfig
    } = this.props
    return (
      <div style={{ marginLeft: 100, marginTop: 20 }}>
        <Grid
          container
          justify="flex-start"
          spacing={2}
          className={classes.container}
        >
          <Grid item className={classes.item} style={{ flex: '0 0 150px' }}>
            <Typography align="left" variant="h6" style={{fontSize: 20, width: 500}}>
              Cell Highlight Legend
            </Typography>
          </Grid>
          <Grid item className={classes.item} style={{ flex: '0 0 800px' }}>
            <Grid container spacing={2} justify="space-between">
              {labels.map(stat =>
                Object.keys(stat).map(field => {
                  const label = stat[field]
                  return (
                    <Grid item key={label.text} xs={6}>
                      <Grid container alignItems="flex-start" spacing={0}>
                        <Grid item>
                          <div
                            className={classes.swatch}
                            style={{ backgroundColor: label.color }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">
                            {label.text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" spacing={0}>
          <Grid item className={classes.item}>
            <Typography variant="caption">
              {`Results for ${genericConfig.MainHeader.baseText}s with base size \u2264 ${sampleSizeThreshold} are not shown`}
            </Typography>

            <div>
              <Typography variant="caption">
                {legendDetails || ""}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Styled(ScorecardLegend)
