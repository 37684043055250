import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'

const Styled = withStyles(theme =>
  theme.components.generic.FilterElement(theme)
)

class FilterElement extends React.PureComponent {
  state = {
    selected: [],
    initial: [],
    open: false
  }

  componentDidMount() {
    const {
      element: { options }
    } = this.props
    const selected = options.reduce((array, option) => {
      if (option.selected) array.push(option.value)
      return array
    }, [])

    this.setState({
      selected,
      initial: selected
    })
  }

  formatSelected = options => {
    const { selected } = this.state
    return options
      .filter(d => selected.includes(d.value))
      .map(d => d.label)
      .join(', ')
  }

  handleChange = event => {
    const {
      element: { multi }
    } = this.props
    const { value } = event.target
    this.setState({ selected: multi ? value : value.slice(-1) })
  }

  handleClose = () => {
    const {
      element: { code, multi },
      update
    } = this.props

    const { selected, initial } = this.state
    if (
      selected.length !== initial.length ||
      selected.some((item, index) => item !== initial[index])
    ) {
      update({
        code,
        selected: multi ? selected : selected[0]
      })
      this.setState({
        initial: selected
      })
    }

    this.setState({
      open: false
    })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  render() {
    const {
      classes,
      element: { label, options },
      disabled
    } = this.props

    return (
      <Grid className={classes.container} item xs>
        <FormControl fullWidth>
          <InputLabel
            htmlFor={`id-for-${label}-static-filter`}
            className={classes.label}
          >
            {label}
          </InputLabel>
          <Select
            multiple
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={this.state.selected}
            onChange={this.handleChange}
            renderValue={() => this.formatSelected(options)}
            input={
              <Input
                disabled={disabled}
                disableUnderline
                id={`id-for-${label}-static-filter`}
                classes={{ input: classes.inputText }}
              />
            }
          >
            {options.map(({ value, label }) => {
              const { selected } = this.state
              return (
                <MenuItem key={`option-${value}`} value={value}>
                  <Checkbox
                    checked={selected.includes(value)}
                    color="secondary"
                    classes={{ root: classes.checkBox }}
                  />
                  <ListItemText
                    classes={{
                      root: classes.menuItemRoot,
                      primary: classes.menuText
                    }}
                    primary={label}
                  />
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
    )
  }
}

export default Styled(FilterElement)
