export default theme => {
  return {
    container: {
      height: '100%',
      backgroundColor: theme.palette.grey[100]
    },
    h0: {
      variant: 'subtitle1',
      height: '100%',
      backgroundColor: theme.palette.grey[200]
    },
    d0: {
      paddingTop: 5,
      paddingBottom: 5
    },
    normValuesText: {
      padding: 2
    },
    stat: {
      marginRight: 5,
      marginLeft: 5,
      borderRadius: 8
    }
  }
}
