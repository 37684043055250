export default {
    "h0": {
        variant: "subtitle1",
        rowHeight: 30
    },
    "d0": {
        variant: "body2",
        rowHeight: 30
    },
    "eol": {
        variant: "caption",
        rowHeight: 20
    }
}