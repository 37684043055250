import axios from 'axios'
import { appSetError } from '../app'

const dashboardRequest = (type, data) => {
  return {
    type: `DASHBOARD_REQUEST_${type.replace(/\//g, '_')}`.toUpperCase(),
    data
  }
}

const dashboardRequestUpdate = (type, data) => {
  return {
    type: `DASHBOARD_REQUEST_UPDATE_${type.replace(/\//g, '_')}`.toUpperCase(),
    data
  }
}

const dashboardRequestDelete = (type, data) => {
  return {
    type: `DASHBOARD_REQUEST_DELETE_${type.replace(/\//g, '_')}`.toUpperCase(),
    data
  }
}

const dashboardReceive = (type, data) => {
  return {
    type: `DASHBOARD_RECEIVE_${type.replace(/\//g, '_')}`.toUpperCase(),
    data
  }
}

const dashboardFail = (type, error) => {
  return {
    type: `DASHBOARD_FAIL_${type.replace(/\//g, '_')}`.toUpperCase(),
    data: error
  }
}

const dashboardFetch = (type, params = {}) => {
  return dispatch => {
    dispatch(dashboardRequest(type, params))

    return axios
      .get(`/${type}`, { params })
      .then(response => dispatch(dashboardReceive(type, response.data)))
      .catch(error => {
        dispatch(dashboardFail(type, error.request.response))
        dispatch(appSetError(error.request.response))
      })
  }
}

const dashboardUpdate = (type, params = {}) => {
  return dispatch => {
    dispatch(dashboardRequestUpdate(type, params))

    return axios
      .post(`/${type}`, { params })
      .then(response => dispatch(dashboardReceive(type, response.data)))
      .catch(error => {
        dispatch(dashboardFail(type, error.request.response))
        dispatch(appSetError(error.request.response))
      })
  }
}

const dashboardDelete = (type, params = {}) => {
  return dispatch => {
    dispatch(dashboardRequestDelete(type, params))

    return axios
      .delete(`/${type}`, { params })
      .then(response => dispatch(dashboardReceive(type, response.data)))
      .catch(error => dispatch(dashboardFail(type, error)))
  }
}

export {
  dashboardFetch,
  dashboardReceive,
  dashboardUpdate,
  dashboardDelete
}
