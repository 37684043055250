import { setBuilderToolbar } from '../../../../../../Template/config/components/selection/SetBuilder'

export default {
  ...setBuilderToolbar,
  enableExcludeNonNormFilters: {
    ad: false,
    norm: false
  },
  exportButtons: {
    ad: {
      data: true,
      media: true
    },
    norm: {
      data: true,
      media: true
    }
  },
  sortOptions: [
    {
      "value": -1,
      "label": "Alpha Order by Ad Code",
      "field": "ad_code",
      "selected": false
    },
    {
      "value": 102,
      "label": "Lift to Brand Impression",
      "dataFormat": "%,0",
      "selected": true
    },
    {
      "value": 103,
      "label": "Lift to Purchase Consideration",
      "dataFormat": "%,0",
      "selected": false
    },
    {
      "value": 106,
      "label": "In-Market AdPi",
      "selected": false
    }
  ]
}
