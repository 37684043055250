import React, { Component } from 'react'
import { Amplify, Logger } from 'aws-amplify'
import Greetings from './Greetings'
import SignIn from './SignIn'
import ConfirmSignIn from './ConfirmSignIn'
import RequireNewPassword from './RequireNewPassword'
import SignUp from './SignUp'
import ConfirmSignUp from './ConfirmSignUp'
import VerifyContact from './VerifyContact'
import ForgotPassword from './ForgotPassword'
import Snackbar from '@material-ui/core/Snackbar'
import AmplifyMessageMap from './AmplifyMessageMap'

const logger = new Logger('Authenticator')

export default class Authenticator extends Component {
  constructor(props) {
    super(props)

    this.handleStateChange = this.handleStateChange.bind(this)
    this.handleAuthEvent = this.handleAuthEvent.bind(this)
    this.errorRenderer = this.errorRenderer.bind(this)

    this.state = { auth: props.authState || 'signIn' }
  }

  componentWillMount() {
    const config = this.props.amplifyConfig
    if (config) {
      Amplify.configure(config)
    }
  }

  handleStateChange(state, data) {
    logger.debug('authenticator state change ' + state, data)

    if (state === this.state.auth) {
      return
    }

    if (state === 'signedOut') {
      state = 'signIn'
    }
    this.setState({ auth: state, authData: data, error: null })
    if (this.props.onStateChange) {
      this.props.onStateChange(state, data)
    }
  }

  handleAuthEvent(state, event) {
    if (event.type === 'error') {
      const map = this.props.errorMessage || AmplifyMessageMap
      const message = typeof map === 'string' ? map : map(event.data)
      this.setState({ error: message })
    }
  }

  errorRenderer(err) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open
        message={err}
      />
    )
  }

  render() {
    const { auth, authData } = this.state
    const messageMap = this.props.errorMessage || AmplifyMessageMap

    let { hideDefault, hide } = this.props

    if (!hide) {
      hide = []
    }

    if (hideDefault) {
      hide = hide.concat([
        Greetings,
        SignIn,
        ConfirmSignIn,
        RequireNewPassword,
        SignUp,
        ConfirmSignUp,
        VerifyContact,
        ForgotPassword,
      ])
    }

    const props_children = this.props.children || []
    const default_children = [
      <Greetings />,
      <SignIn />,
      <ConfirmSignIn />,
      <RequireNewPassword />,
      <SignUp />,
      <ConfirmSignUp />,
      <VerifyContact />,
      <ForgotPassword />,
    ]

    const children = default_children.concat(props_children)
    const render_children = React.Children.map(children, child => {
      return React.cloneElement(child, {
        messageMap: messageMap,
        authState: auth,
        authData: authData,
        onStateChange: this.handleStateChange,
        onAuthEvent: this.handleAuthEvent,
        hide: hide,
      })
    })

    const errorRenderer = this.props.errorRenderer || this.errorRenderer
    const error = this.state.error
    return (
      <div>
        {render_children}
        {error ? errorRenderer(error) : null}
      </div>
    )
  }
}
