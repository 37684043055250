export default {
  slices: [
    {
      field: 'ad_type',
      display: 'Ad Type',
      map: {
        'tv': {
          display: 'TV',
          sortOrder: 1
        }
      }
    },
    {
      field: 'market',
      display: 'Market',
      map: {
        'market_smb': {
          display: 'SMB',
          sortOrder: 1
        },
        'market_mm_ent': {
          display: 'MM/ENT',
          sortOrder: 2
        }
      }
    },
    {
      field: 'ad_categories',
      display: 'Ad Category',
      variant: 'multigroup',
      map: {
        'all_ads' : {
          display: 'All Ads',
          sortOrder: 1
        },
        'connectivity': {
          display: 'Connectivity',
          sortOrder: 2
        },
        'phone': {
          display: 'Phone',
          sortOrder: 3
        },
        'phone_voip' : {
          display: 'Phone, VoIP, UC',
          sortOrder: 3,
        },
        'mobile': {
          display: 'Mobile',
          sortOrder: 4
        },
        'security_services': {
          display: 'Security Services',
          sortOrder: 5
        },
        'cybersecurity' : {
          display: 'Cybersecurity',
          sortOrder: 5,
        },
        'tv': {
          display: 'TV',
          sortOrder: 6
        },
        'managed_svc' : {
          display: 'Managed Network Services',
          sortOrder: 6,
        },
        'other_comm': {
          display: 'Other Comms Products',
          sortOrder: 7
        },
        'other_svc' : {
          display: 'Other Business Services',
          sortOrder: 7,
        },
        'misc': {
          display: 'Miscellaneous Products',
          sortOrder: 8
        },
        'connectivity_mobile': {
          display: 'Connectivity + Mobile',
          sortOrder: 9,
          multigroup: true
        },
        'phone_voip_mobile' : {
          display: 'Phone, VoIP, UC + Mobile',
          sortOrder: 9,
          multigroup: true,
        },
        'connectivity_security': {
          display: 'Connectivity + Security Services',
          sortOrder: 10,
          multigroup: true,
        },
        'connectivity_all' : {
          display: 'Connectivity + All Phone',
          sortOrder: 10,
          multigroup: true,
        },
        'connectivity_phone': {
          display: 'Connectivity + Phone',
          sortOrder: 11,
          multigroup: true,
        },
        'connectivity_managed_svc' : {
          display: 'Connectivity + Managed Network',
          sortOrder: 11,
          multigroup: true,
        },
        'connectivity_other_comm' : {
          display: 'Connectivity + Other Comms Products',
          sortOrder: 12,
          multigroup: true,
        },
        'any_comm' : {
          display: 'Any Comms Product',
          sortOrder: 13,
          multigroup: true,
        },

      }
    }
  ]
}
