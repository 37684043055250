import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Loadable from 'react-loadable'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import {
  ScorecardView
  // Wordcloud,
  // Trendchart
} from '../../new_components'

import {
  dashboardInit,
  dashboardUpdate,
  dashboardUpdateStaticFilter,
  dashboardViewerOpen,
  dashboardQuickviewClose,
  appSetWarning
} from '../../new_actions'
import {
  getMetricsLabels,
  getMetricsQuery,
  getNormQuery,
  getGridData,
  getAdType,
  getStaticFilters,
  getStaticFiltersSelections,
  getAdsMap,
  getQuickviewConfig
} from '../../new_selectors'

const LoadableDetailsView = Loadable({
  loader: () => import('../../new_components/scorecard/components/DetailsView'),
  loading() {
    return null
  }
})

const LoadableQuickview = Loadable({
  loader: () => import('../../new_components/quickview/components/Quickview'),
  loading() {
    return null
  }
})

const Styled = withStyles(theme => theme.components.scorecard(theme), {
  withTheme: true
})

class Scorecard extends React.PureComponent {
  state = {
    detailAdCode: null,
    ready: false,
    selectedTab: {
      tab: 'wordcloud'
    },
    detailOpen: false,
    collapsed: false,
    quickviewOpen: false,
    ad: {}
  }

  navigateTo = route => {
    const { history, baseRoute } = this.props

    history.push(`${baseRoute}/${route}`)
  }

  toPresets = () => {
    this.navigateTo('preset')
  }

  toBuilder = () => {
    this.navigateTo('builder')
  }

  updateFilter = data => {
    const { dispatch } = this.props
    dispatch(dashboardUpdateStaticFilter(data))
  }

  componentDidMount() {
    const {
      dispatch,
      init,
      adType,
      metricsQuery,
      normQuery,
      gridData: { metricsUUID, normUUID },
      selectedPresets: { ad, norm },
      config: {
        scorecard: {
          scorecardView: { collapsed }
        }
      }
    } = this.props

    this.setState({
      collapsed
    })

    if (!adType) {
      dispatch(dashboardInit(init))
    } else {
      this.setState({ ready: true })
      if (
        metricsUUID !== ad.uuid &&
        ad.ad_codes.length &&
        norm.ad_codes.length
      ) {
        dispatch(dashboardUpdate('metrics', metricsQuery))
      }

      if (
        normUUID !== norm.uuid &&
        norm.ad_codes.length &&
        ad.ad_codes.length
      ) {
        dispatch(dashboardUpdate('norm', normQuery))
      }
    }
  }

  componentWillUnmount() {
    // dispatch(dashboardClrStaticFilter())
  }

  openMedia = ad => {
    const {
      links: { media },
      title,
      ad_code
    } = ad
    const { dispatch } = this.props
    dispatch(dashboardViewerOpen(media, title, ad_code))
  }

  collapse = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  openDetails = ({ ad, selectedTab }) => {
    this.setState({
      detailOpen: true,
      selectedTab: {
        tab: selectedTab
      },
      ad
    })
  }

  openQuickview = ({ ad }) => {
    console.log('Open quickview with ', ad)
    this.setState({
      quickviewOpen: true,
      ad
    })
  }

  closeQuickview = () => {
    const { dispatch } = this.props
    this.setState({
      quickviewOpen: false
    })
    dispatch(dashboardQuickviewClose())
  }

  closeDetails = () => {
    this.setState({
      detailOpen: false
    })
  }

  selectTab = selectedTab => {
    this.setState({
      selectedTab
    })
  }

  componentDidUpdate(prevProps) {
    const { dispatch, metricsQuery, normQuery } = this.props

    if (!this.state.ready) {
      this.setState({ ready: true })
    }
    if (
      prevProps.filter.staticFilterUUID !== this.props.filter.staticFilterUUID
    ) {
      dispatch(dashboardUpdate('metrics', metricsQuery))
      dispatch(dashboardUpdate('norm', normQuery))
    }
  }

  generateError = message => {
    this.props.dispatch( appSetWarning(message))
  }
  render() {
    const {
      config,
      genericConfig,
      metricsLabels,
      quickviewConfig,
      filter,
      adType,
      selections,
      gridData,
      selectedPresets,
      baseEndpoint,
      groupName,
      filterQuery,
      scorecard: { calcFlags },
      adsMap,
      selectedAdsMap,
      statTestConfig,
      theme
    } = this.props

    if (!this.state.ready) return null

    const { end_month } = selectedPresets.ad
    const { selectedTab } = this.state
    return (
      <Fragment>
        <ScorecardView
          config={config}
          genericConfig={genericConfig}
          filter={filter}
          adType={adType}
          selections={selections}
          gridData={gridData}
          baseEndpoint={baseEndpoint}
          groupName={groupName}
          openMedia={this.openMedia}
          collapse={this.collapse}
          collapsed={this.state.collapsed}
          openDetails={this.openDetails}
          openQuickview={this.openQuickview}
          selectedPresets={selectedPresets}
          updateFilter={this.updateFilter}
          toPresets={this.toPresets}
          toBuilder={this.toBuilder}
          adsMap={adsMap}
          selectedAdsMap={selectedAdsMap}
          calcFlags={calcFlags}
          generateError={this.generateError}
        />
        {this.state.detailOpen ? (
          <LoadableDetailsView
            open={this.state.detailOpen}
            handleClose={this.closeDetails}
            ad={this.state.ad}
            adType={adType}
            selectTab={this.selectTab}
            selectedTab={selectedTab}
            config={config.scorecard}
            endMonth={end_month}
            filters={filterQuery}
            calcFlags={calcFlags.ad}
            normData={gridData.norms}
            openMedia={this.openMedia}
            staticFilters={filter.staticFilters}
            hasTrendchart={this.props.init.config.hasTrendchart}
            gridData={gridData}
          />
        ) : null}

        {this.state.quickviewOpen ? (
          <LoadableQuickview
            open={this.state.quickviewOpen}
            handleClose={this.closeQuickview}
            ad={this.state.ad}
            adType={adType}
            selections={selections}
            config={quickviewConfig}
            endMonth={end_month}
            groupName={groupName}
            metricsLabels={metricsLabels}
            filters={filterQuery}
            calcFlags={calcFlags.ad}
            normData={gridData.norms}
            openMedia={this.openMedia}
            gridData={gridData}
            staticFilters={filter.staticFilters}
            statTesting={config.scorecard.scorecardView.scorecardStatTesting.labels}
            theme={theme}
            statTestConfig={statTestConfig}
          />
        ) : null}
      </Fragment>
    )
  }
}

const Connected = connect(state => {
  const { dashboard } = state
  return {
    init: dashboard.init,
    baseEndpoint: dashboard.init.config.baseEndpoint,
    groupName: dashboard.init.config.main.groupName,
    config: dashboard.init.config.components,
    genericConfig: dashboard.init.config.components.generic,
    quickviewConfig: getQuickviewConfig(dashboard),
    baseRoute: dashboard.init.config.main.baseRoute,
    staticFilters: getStaticFilters(dashboard),
    statTestConfig: dashboard.init.config.components.scorecard.scorecardView.scorecardStatTesting,
    adsMap: dashboard.init.metadata.adsMap,
    scorecard: dashboard.scorecard,
    filter: dashboard.selections.filter,
    selectedPresets: dashboard.selections.presets,
    selections: dashboard.selections.slice.sliceSelections,
    adType: getAdType(dashboard),
    selectedAdsMap: getAdsMap(dashboard),
    metricsLabels: getMetricsLabels(dashboard),
    metricsQuery: getMetricsQuery(dashboard),
    normQuery: getNormQuery(dashboard),
    gridData: getGridData(dashboard),
    filterQuery: getStaticFiltersSelections(dashboard)
  }
})
export default withRouter(Connected(Styled(Scorecard)))
