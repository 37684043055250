export default {
  columnLabels: {
    code: 'Ad Code',
    title: 'Ad Title',
    category: 'Ad Category',
    type: 'Type',
    custom: 'Custom Ad Codes',
    helpText: 'Enter Ad Codes (separated by commas, tabs or spaces)'
  },
  errors: {
    nonExistent: 'Non-existent Ad Code'
  }
}
