import { setBuilderToolbar } from '../../../../../Template/config/components/selection/SetBuilder'

export default {
  ...setBuilderToolbar,
  enableExcludeNonNormFilters: {
    ad: true,
    norm: true
  },
  exportButtons: {
    ad: {
      data: true,
      media: true
    },
    norm: {
      data: true,
      media: true
    }
  },
  sortOptions: [
    {
      "value": -1,
      "label": "Alpha Order by Brand",
      "field": "brand",
      "selected": false
    },
    {
      "value": 100,
      "label": "AdPi Score",
      "selected": true,
      "dataFormat": ".,0"
    },
    {
      "value": 112,
      "label": "Branded Recall (Avg. Recall)",
      "selected": false
    },
    {
      "value": 121,
      "label": "Lift to Consideration",
      "selected": false
    },
    {
      "value": 123,
      "label": "Net Actions",
      "selected": false
    },
    {
      "value": 151,
      "label": "Likeability",
      "selected": false
    }
  ]
}
