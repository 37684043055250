export default {
  "h0": {
    variant: "subtitle1",
    rowHeight: 30
  },
  "hb0": {
    variant: "subtitle1",
    rowHeight: 25
  },
  "d0": {
    variant: "body2",
    rowHeight: 30
  },
  "d1": {
    variant: "body2",
    rowHeight: 30
  },
  "dh0": {
    variant: "body2",
    rowHeight: 30
  },
  "s0": {
    variant: "body2",
    rowHeight: 30
  },
  "eol": {
    variant: "caption",
    rowHeight: 20
  }
}