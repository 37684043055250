import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import SearchAdCodes from './SearchAdCodes'

const Styled = withStyles(theme =>
  theme.components.selection.Search.SearchManual(theme)
)
class SearchManual extends React.PureComponent {
  update = (ads, candidateRange) => {
    const { updateCandidates } = this.props
    updateCandidates({
      addCandidates: ads.map( ad => ad.ad_code)
    }, candidateRange)
  }

  render() {
    const { adType, selections, metadataAdsMap, searchInputText, updateSearchInputText, config } = this.props
    return (
      <div style={{ padding: 25 }}>
        <Grid container spacing={2} style={{ height: 500 }}>
          <SearchAdCodes
            adsMap={metadataAdsMap}
            adType={adType}
            selections={selections}
            update={this.update}
            searchClose={this.searchClose}
            searchInputText={searchInputText}
            updateSearchInputText={updateSearchInputText}
            config={config.searchManual}
          />
        </Grid>
      </div>
    )
  }
}

export default Styled(SearchManual)
