import QuickviewCanvasHeader from './QuickviewCanvasHeader'

const QuickviewCanvasContainer = Object.assign(
  theme => {
    return {
      header: {
        backgroundColor: theme.palette.grey[200]
      },
      body: { backgroundColor: theme.palette.grey[200] },
      row: {},
      rowTitle: {
        backgroundColor: theme.palette.grey[600],
        color: theme.palette.grey[50],
        fontSize: 18,
        fontStyle: 'bold'
      }
    }
  },
  {
    QuickviewCanvasHeader
  }
)

export default QuickviewCanvasContainer
