import React, { Fragment } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import {
  ListGridViewSelector as PresetViewSelector,
  Tabs,
  MainHeader
} from '../../../'

import PresetGridView from './PresetGridView'
import PresetListView from './PresetListView'

const Styled = withStyles(theme =>
  theme.components.selection.PresetSelect(theme)
)

class PresetSelect extends React.Component {
  state = {
    viewHeight: 0,
    showGridView: true,
    selectedPresetTab: { tab: 'both' },
    viewedSet: [],
    // confirmationOpen: false,
    confirmationCallback: () => {},
    selectedUUID: null,
    confirmationTitle: 'Warning',
    confirmationMessage: `This action will overwrite your unfinished work in Builder. \nAre you sure you want to continue?`,
    tabs: true,
    presetTabs: [
      {
        key: 'both',
        label: 'All'
      },
      {
        key: 'standard',
        label: 'Standard'
      },
      {
        key: 'custom',
        label: 'Saved'
      }
    ]
  }

  updateHeight = () => {
    this.setState({
      viewHeight: window.innerHeight - 350
    })
  }

  componentDidMount() {
    const { selectedTab } = this.props
    const { selectedPresetTab } = this.state
    window.addEventListener('resize', this.updateHeight)
    this.updateHeight()
    this.setState({
      viewedSet: this.updateViewedSet(selectedTab, selectedPresetTab)
    })
  }

  componentDidUpdate(prevProps) {
    const { selectedTab, presets } = this.props
    const { selectedPresetTab } = this.state
    if (selectedTab !== prevProps.selectedTab) {
      this.setState({
        viewedSet: this.updateViewedSet(selectedTab, selectedPresetTab)
      })
    } else if (
      presets[selectedTab.tab] &&
      presets[selectedTab.tab].length !==
        prevProps.presets[selectedTab.tab].length
    ) {
      this.setState({
        viewedSet: this.updateViewedSet(selectedTab, selectedPresetTab)
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight)
  }

  selectPresetTab = selectedPresetTab => {
    this.setState((state, props) => {
      const { selectedTab } = props
      return {
        selectedPresetTab,
        viewedSet: this.updateViewedSet(selectedTab, selectedPresetTab)
      }
    })
  }

  toggleGridView = showGridView => {
    this.setState({
      showGridView
    })
  }

  updateViewedSet = (selectedTab, selectedPresetTab) => {
    const { presets } = this.props
    const { tab } = selectedTab
    return (
      (presets &&
        presets[tab] &&
        presets[tab].filter(preset => {
          return (
            selectedPresetTab.tab === 'both' ||
            (selectedPresetTab.tab === 'standard' && preset.is_standard) ||
            (selectedPresetTab.tab === 'custom' && !preset.is_standard)
          )
        })) ||
      []
    )
  }

  openScorecard = uuid => {
    const { openScorecard } = this.props
    openScorecard(uuid)
  }

  render() {
    const {
      adType,
      selections,
      classes,
      deletePreset,
      buildPreset,
      selectPreset,
      selectedPresets,
      selectedTab,
      selectTab,
      adsMap,
      config: { hasNormsTab },
      genericConfig,
      calcFlags,
      config
    } = this.props

    const {
      viewHeight,
      showGridView,
      selectedPresetTab,
      viewedSet,
      presetTabs
    } = this.state

    const PresetView = showGridView ? PresetGridView : PresetListView
    const numAds = calcFlags && calcFlags.ad && calcFlags.ad.excludeNonNorm ? adsMap.ad.list.length - adsMap.ad.zeroList.length : adsMap.ad.list.length
    const numNormAds = calcFlags && calcFlags.norm && calcFlags.norm.excludeNonNorm ? adsMap.norm.list.length - adsMap.norm.zeroList.length : adsMap.norm.list.length
    const scorecardAvailable = numAds && numNormAds
   
    return (
      <Fragment>
        <MainHeader
          title="Presets"
          updateTab={selectTab}
          selectedTab={selectedTab}
          adType={adType}
          selections={selections}
          ads={adsMap}
          tabs={hasNormsTab}
          openScorecard={this.openScorecard}
          builderAction={buildPreset}
          calcFlags={calcFlags}
          config={genericConfig}
        />
        <Grid container justify="center">
          <Grid item xs={11}>
            <Paper elevation={0} className={classes.paper} classes={{elevation0: classes.boxShadow}}>
              <Grid
                container
                justify="space-between"
                className={classes.selector}
              >
                <Grid item>
                  <Tabs
                    selectedTab={selectedPresetTab}
                    selectTab={this.selectPresetTab}
                    tabs={presetTabs}
                  />
                </Grid>
                <Grid item>
                  <PresetViewSelector
                    update={this.toggleGridView}
                    showGridView={showGridView}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <PresetView
                  viewHeight={viewHeight}
                  items={viewedSet}
                  adType={adType}
                  buildItem={buildPreset}
                  selectItem={selectPreset}
                  deleteItem={deletePreset}
                  selectedItem={selectedPresets[selectedTab.tab]}
                  openScorecard={this.openScorecard}
                  scorecardAvailable={scorecardAvailable}
                  config={config}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default Styled(PresetSelect)
