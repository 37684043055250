import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  colorSwatch: {
    width: 20,
    height: 20,
  },
})

class ScorecardLegend extends PureComponent {
  render() {
    const { classes, stats, thresholds } = this.props

    return (
      <Grid container justify="space-around" style={{marginTop: 20}}>
        <Grid xs={3} lg={3} item>
          <Typography align="left" variant="h6" >
            Cell Highlight Legend
          </Typography>
          <Typography align="left" variant="caption">
            {`Cells with sample size < ${
              thresholds.minSampleSizeInScorecard.value
            }  are blanked`}
          </Typography>
        </Grid>
        <Grid xs={6} lg={6} item>
          <Grid container>
            {['above', 'below'].map(d => {
              const { bgColor, description } = stats[d]

              return (
                <Grid style={{ padding: 2 }} key={d} xs={12} item>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <div
                        className={classes.colorSwatch}
                        style={{ backgroundColor: bgColor }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography noWrap align="left" variant="caption">
                        {description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        <Grid xs={3} lg={3} item>
          <Grid container>
            {['medSampleSizeInScorecard', 'maxSampleSizeInScorecard'].map(d => {
              const { color, value } = thresholds[d]
              const description = `Sample size < ${value}`
              return (
                <Grid style={{ padding: 2 }} key={d} xs={12} item>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <div className={classes.colorSwatch} />
                    </Grid>
                    <Grid item>
                      <Typography
                        noWrap
                        align="left"
                        variant="caption"
                        style={{ color: color }}
                      >
                        {description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

ScorecardLegend.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ScorecardLegend)
