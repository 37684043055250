import dashboard from './dashboard'
import generic from './generic'
import navigation from './navigation'
import scorecard from './scorecard'
import selection from './selection'
import adminView from './adminView'
import quickview from './quickview'
import survey from './survey'

export default { dashboard, quickview, generic, navigation, selection, scorecard, survey, adminView }
