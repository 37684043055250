import { combineReducers } from 'redux'

import config from './config'
import metadata from './metadata'
import users from './users'

import {
  DASHBOARD_ADMIN_INTERFACE_OPEN,
  DASHBOARD_ADMIN_INTERFACE_CLOSE
} from '../../new_actions'

const initialState = {
  open: false,
  isAuto: false
}

function view(state=initialState, action) {

  switch (action.type) {
    case DASHBOARD_ADMIN_INTERFACE_OPEN:
      const { isAuto } = action.data
      return {
        ...state,
        open: true,
        isAuto
      }

    case DASHBOARD_ADMIN_INTERFACE_CLOSE:
      return initialState

    default:
      return { ...state }
  }
}
export default combineReducers({
  view,
  config,
  metadata,
  users
})
