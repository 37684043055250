import MainPresetsIcon from '@material-ui/icons/GroupWork'
import AdCategoriesIcon from '@material-ui/icons/Loyalty'
import BuilderIcon from '@material-ui/icons/Build'

export default [
    {
      "path": "/slicer",
      "label": "Category Select",
      "icon": AdCategoriesIcon
    },
    {
      "path": "/preset",
      "label": "Offer Sets and Norms",
      "icon": MainPresetsIcon
    },

    {
      "path": "/builder",
      "label": "Builder",
      "icon": BuilderIcon
    }
  ]
  