export default {
  '***': {
      '***': {
        headerHeight: 80,
        rowTitleHeight: 35,
        padding: 1,
        aspectRatio: 0.7727,
        externalHeaderHeight: 0,
        minContainerHeight: 1000,
        horizontalMargin: 10,
        verticalMargin: 10,
        rows: [
          {
            height: 0.33,
            title: 'IN-MARKET PERFORMANCE',
            data: [
              {
                content: [{
                  component: 'MetricDonut',
                  data: {
                    metric: 100,
                    metricScale: 200
                  }
                }],
                width: 0.3,
                key: null
              },
              {
                content: [{
                  component: 'MetricTable',
                  data: {
                    label: 'Recall & Branding',
                    metrics: [
                      { metric: 102, isHeaderMetric: true, isCapitalized: true },
                      { metric: 111, isHeaderMetric: true },
                      { metric: 112, isHeaderMetric: true }
                    ]
                  }
                }],
                width: 0.35,
                key: null
              },
              {
                content: [{
                  component: 'MetricTable',
                  data: {
                    label: 'Persuasion',
                    metrics: [
                      { metric: 103, isHeaderMetric: true, isCapitalized: true },
                      { metric: 105, isHeaderMetric: true, isCapitalized: true },
                      { metric: 107, isHeaderMetric: false, isInset: true },
                      { metric: 108, isHeaderMetric: false, isInset: true },
                      {
                        metric: 109,
                        label: 'Go to retail store',
                        isHeaderMetric: false,
                        isInset: true
                      },
                      { metric: 110, isHeaderMetric: false, isInset: true }
                    ]
                  }
                }],
                width: 0.35,
                key: null
              }
            ]
          },
          {
            height: 0.67,
            title: 'CREATIVE PERFORMANCE',
            data: [
              {
                aggregatedMetrics: [10000, 10001, 10002, 10003, 10004, 10005],
                content: [{
                  component: 'RadarChart',
                  data: {
                    score: { 
                      label: 'MMM Score',
                      metric: 10000,
                    },
                    norm: {
                      label: 'Xfinity Avg'
                    },
                    metrics: [
                      {
                        label: 'Branding / Emotional',
                        metric: 10001,
                        scale: {
                          min: -1000,
                          max: 1000
                        }
                      },
                      {
                        label: 'Relevant',
                        metric: 10002,
                        scale: {
                          min: -1000,
                          max: 1000
                        }
                      },
                      {
                        label: 'Something Different',
                        metric: 10003,
                        scale: {
                          min: -1000,
                          max: 1000
                        }
                      },
                      {
                        label: 'Call to Action',
                        metric: 10004,
                        scale: {
                          min: -1000,
                          max: 1000
                        }
                      },
                      {
                        label: 'Value',
                        metric: 10005,
                        scale: {
                          min: -1000,
                          max: 1000
                        }
                      }
                    ]
                  }
                },
                {
                  component: 'MetricTable',
                  data: {
                    label: 'Value Proposition',
                    metrics: [
                      { metric: 114, isHeaderMetric: true },
                      { metric: 115, isHeaderMetric: true },
                      { metric: 116, isHeaderMetric: true },
                      { metric: 117, isHeaderMetric: true },
                      { metric: 118, isHeaderMetric: true }
                    ]
                  }
                }],
                legend: {
                  xOffset: 10,
                  yOffset: -20
                },
                width: 0.3,
                key: null
              },
              {
                content: [{
                  component: 'MetricChart',
                  data: {
                    title: 'Creative Efficiency',
                    mainMetric: {
                      type: 'horizontal',
                      scale: {
                        start: 85,
                        end: 155
                      },
                      metric: 121,
                      label: 'Creative Summary Score'
                    },
                    subMetrics: [
                      { metric: 122 },
                      { metric: 127 },
                      { metric: 135 },
                      { metric: 139, label: 'Cognitive' },
                      { metric: 146, label: 'Emotional' },
                      { metric: 151 }
                    ]
                  }
                }],
                width: 0.35,
                key: null
              },
              {
                content: [{
                  component: 'MetricOpenend',
                  data: {
                    label: 'In Their Own Words',
                    topBox: {
                      likes: {
                        metric: 119,
                        noStatColor: '#BFBFBF'
                      },
                      dislikes: {
                        metric: 120,
                        noStatColor: '#AFABAB'
                      }
                    },
                    messageField: 'message',
                    wordcloudConfig: {
                      sentiments: [
                        { color: '#FF872F', description: 'Very Negative' },
                        { color: '#FFC397', description: 'Negative' },
                        { color: '#DAD9D6', description: 'Neutral' },
                        { color: '#C4E1AF', description: 'Positive' },
                        { color: '#89C35F', description: 'Very Positive' }
                      ],
                      scoreRange: [0, 7],
                      font: 'Arial, Helvetica, sans-serif',
                      showCounts: false,
                      padding: 5,
                      rotation: 0,
                      wordCountCutoff: 2,
                      wordLengthCutoff: 3,
                      numDisplayed: 100
                    }
                  }
                }],
                width: 0.35,
                key: null
              }
            ]
          }
        ],
        legend: {
          metadataFields: [
            {
              value: 'last_month_tested',
              label: 'Last Tested'
            }
          ],
          includeSelections: true,
          includeStatTesting: true,
          includeFilters: {
            label: 'Target',
            default: 'Total Consumers'
          }
        }
      }
    }
}
