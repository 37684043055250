// Formats: '%,n', '$,n', '$x,n', 'f,n', 'i,n'
// operations: 'last(a)', prod(a,b), ratio(a,b)
export default {
  '***': [
    {
      label: 'Recall',
      rowType: 'h1'
    },
    {
      metric: 1,
      label: 'Recall (Recognition)',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false,
      minSampleSize: 0
    },
    {
      metric: 2,
      label: 'Ease of Branding',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 3,
      operation: 'prod(1,2)', 
      label: 'Branded Recall (Breakthrough NBR)',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      label: 'Persuasion',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 4,
      label: 'Persuasion (Avg Motivation)',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 5,
      label: 'Lift to Brand Impression',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 6,
      label: 'Lift to Purchase Consideration',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      label: 'Branding',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 7,
      label: 'Correct Brand ID',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    // {
    //   metric: 8,
    //   label: 'Logo Removed',
    //   rowType: 'd1',
    //   dataFormat: '%,0',
    //   reverseStat: false
    // },
    {
      metric: 9,
      label: 'Good Fit with Brand',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 10,
      label: 'Credible Message',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      label: 'Value Proposition',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 11,
      label: 'Benefit Relevance',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 12,
      label: 'Benefit Uniqueness',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      label: 'Creative',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 13,
      label: 'Engagement',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 14,
      label: 'Information',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 15,
      label: 'Relevance',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 16,
      label: 'Empathy',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 17,
      label: 'Impact',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },

    {
      label: 'Likeability',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 18,
      label: 'Likeability - T2B',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 19,
      label: 'Likeability - B2B',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: true
    },

    {
      label: 'Engagement',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 20,
      label: 'Clever',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 21,
      label: 'Entertaining',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 22,
      label: 'Humorous',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 23,
      label: 'Unique',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },

    {
      label: 'Information',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 24,
      label: 'Believable',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 25,
      label: 'Clear',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 26,
      label: 'Informative',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 27,
      label: 'Meaningful',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },

    {
      label: 'Relevance',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 28,
      label: 'Spoke Directly To Me',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 29,
      label: 'Relatable People / Situations',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 30,
      label: 'Company to Associate With',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 31,
      label: 'Directly Addresses Imp Issue',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 32,
      label: 'Appropriate For The Times',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 33,
      label: 'Reappraisal',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },

    {
      label: 'Empathy',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 34,
      label: 'Authentic',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 35,
      label: 'Heartwarming',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 36,
      label: 'Inspiring',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 37,
      label: 'Reassuring',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },

    {
      label: 'Impact',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 38,
      label: 'Worth Seeing Again',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 39,
      label: 'Worth Talking About',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 40,
      label: 'Want to Take Action',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 41,
      label: 'Tells me somethng new',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },

    {
      label: 'Negative',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 42,
      label: 'Confusing',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: true
    },
    {
      metric: 43,
      label: 'Boring',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: true
    },
    {
      metric: 44,
      label: 'Creative Wear Out (Irritating)',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: true
    },
    {
      metric: 45,
      label: 'In-Market Wear Out (Tired of Seeing) **',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: true
    },
    {
      metric: 46,
      label: 'Seen Many Times **',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: true
    },
    {
      label: 'Overall Brand  Related Imagery (T3B)',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 67,
      label: 'Is accepted at the places I want to use it',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 68,
      label: 'Is the first credit card many people get',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 69,
      label: 'Helps me better manage my spending and saving',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 70,
      label: 'Is always offering new features and benefits',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 71,
      label: 'Is a brand I would be proud to be seen using',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 72,
      label: 'Is for someone like me',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 73,
      label: 'Is used by people I respect',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 74,
      label: 'Offers the best cash rewards',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 75,
      label: 'Offers the best miles rewards',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 76,
      label: 'Is a brand customers can trust to do what is right',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 77,
      label: 'Offers fair fees',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 78,
      label: 'Offers a variety of financial products',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      label: 'Actions Taken or Intended',
      rowType: 'h1',
      dataFormat: null
    },
    {
      metric: 56,
      label: 'Take Any Action (Net)',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 57,
      label: 'Discuss ad with friends, relatives, associates',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    // {
    //   metric: 58,
    //   label: 'Apply for your first card from this company',
    //   rowType: 'd1',
    //   dataFormat: '%,0',
    //   reverseStat: false
    // },
    // {
    //   metric: 59,
    //   label: 'Apply for additional card from this company',
    //   rowType: 'd1',
    //   dataFormat: '%,0',
    //   reverseStat: false
    // },
    // {
    //   metric: 60,
    //   label: "Go to company's website to find out more",
    //   rowType: 'd1',
    //   dataFormat: '%,0',
    //   reverseStat: false
    // },
    // {
    //   metric: 61,
    //   label: 'Go to website related to ad to find out more',
    //   rowType: 'd1',
    //   dataFormat: '%,0',
    //   reverseStat: false
    // },
    // {
    //   metric: 62,
    //   label: 'Call to find out more',
    //   rowType: 'd1',
    //   dataFormat: '%,0',
    //   reverseStat: false
    // },
    {
      metric: 63,
      label: 'Use existing card from this company more often',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    // {
    //   metric: 64,
    //   label: 'Renew your existing card from this company',
    //   rowType: 'd1',
    //   dataFormat: '%,0',
    //   reverseStat: false
    // },
    {
      metric: 65,
      label: 'Share this ad on a social media page',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    // {
    //   metric: 66,
    //   label: "Go to the brand's Facebook page",
    //   rowType: 'd1',
    //   dataFormat: '%,0',
    //   reverseStat: false
    // }
    {
      metric: 79,
      label: 'Apply for a card from this company',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 80,
      label: 'Find out more about the company',
      rowType: 'd1',
      dataFormat: '%,0',
      reverseStat: false
    }
  ]
}
