import templateSelection from '../../../../Template/config/components/selection'
import setBuilder from './SetBuilder'
import presetSelect from './PresetSelect'
import metadataSlice from './MetadataSlice'
import search from './Search'

export default {
    ...templateSelection,
    setBuilder,
    metadataSlice,
    search,
    presetSelect
}
