/***
 * Core
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { HashRouter as Router, Route, withRouter } from 'react-router-dom'
import Loadable from 'react-loadable'

/***
 * Material UI
 */
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import cn from 'classnames'

/***
 * Configuration
 */

/***
 * Local Components
 */
import {
  AsyncWrapper,
  NavBar,
  SideBar,
  MediaViewer,
  UserMessage
} from '../../new_components'

/***
 * Local Containers
 */
import Slicer from "../slicer"
import Builder from "../builder"
import Preset from "../preset"
import Scorecard from "../scorecard"
import Survey from '../survey'
/***
 * Actions
 */
import {
  dashboardSetConfig,
  dashboardFetch,
  dashboardClrConfig,
  dashboardViewerClose,
  dashboardAdminInterfaceOpen,
  appClrError
} from '../../new_actions'

/***
 * Admin interface dynamically loaded
 */
const Admin = Loadable({
  loader: () => {
   return import('../admin')
  },
  loading: () => null
})

const Styled = withStyles(theme => theme.components.dashboard(theme))

class Dashboard extends React.PureComponent {
  state = {
    sidebarOpen: false,
    configured: false
  }

  componentDidCatch(a) {
    console.log(a)
  }

  componentDidMount() {
    const { dispatch, config } = this.props
    dispatch(dashboardSetConfig(config))
    this.setState({
      configured: true
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      dispatch,
      config: {
        components: {
          selection: {
            setBuilder: { storageVersion }
          }
        }
      }
    } = this.props
    const { configured } = this.state
    if (!prevState.configured && configured) {
      dispatch(dashboardFetch('metadata', { storageVersion }))
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch(dashboardClrConfig())
  }

  toggleSidebar = () => {
    this.setState(prevState => {
      return {
        sidebarOpen: !prevState.sidebarOpen
      }
    })
  }

  openAdmin = () => {
    const {
      dispatch,
      config: {
        main: { baseRoute }
      },
      history
    } = this.props
    dispatch(dashboardAdminInterfaceOpen(false))
    history.push(`${baseRoute}/admin`)
  }

  closeViewer = () => {
    const { dispatch } = this.props
    dispatch(dashboardViewerClose())
  }

  clrError = () => {
    const { dispatch, history, message } = this.props
    if (message.type === 'error') history.push('/')
    dispatch(appClrError())
  }

  render() {
    const {
      classes,
      config: {
        main: { groupName, label, baseRoute },
        sidebar
      },
      user,
      match,
      init,
      viewer,
      environment,
      message
    } = this.props

    const { sidebarOpen } = this.state
    return (
      <AsyncWrapper
        item={ init }
        loadingMsg={`Loading ${groupName.toUpperCase()} Dashboard`}
      >
        <Grid
          className={cn(
            classes.dashboard,
            classes[environment],
            classes[message.type]
          )}
        >
          <NavBar
            appLabel={label}
            appName={groupName}
            toggleSidebar={this.toggleSidebar}
            openAdmin={this.openAdmin}
            hasAdminViewAccess={user.hasAdminViewAccess}
            user={user}
          />
          <SideBar
            links={sidebar}
            open={sidebarOpen}
            base={baseRoute}
            toggleSidebar={this.toggleSidebar}
          />

          {viewer.open ? (
            <MediaViewer viewer={viewer} handleClose={this.closeViewer} />
          ) : null}

          <Router>
            <main className={classes.content}>
              <Route path={`${match.path}/slicer`} component={Slicer} />
              <Route path={`${match.path}/preset`} component={Preset} />
              <Route path={`${match.path}/builder`} component={Builder} />
              <Route path={`${match.path}/scorecard`} component={Scorecard} />
              <Route path={`${match.path}/survey`} component={Survey} />
              <Route path={`${match.path}/admin`} component={Admin} />
            </main>
          </Router>
        </Grid>
        <UserMessage message={message} close={this.clrError} />
      </AsyncWrapper>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

const Connected = connect(state => {
  const { init } = state.dashboard
  const { viewer, admin } = state.dashboard
  const { environment, message } = state.app
  const { user } = state
  return {
    init,
    viewer,
    admin,
    user,
    environment,
    message
  }
})

export default withRouter(Connected(Styled(Dashboard)))
