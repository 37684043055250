export default theme => {
  return {
    configView: {
      marginTop: 20,
      marginBottom: 20,
      maxHeight: 450,
      overflow: 'scroll',
      padding: 20
    },
    container: {},
    tableBody: {
      marginBottom: 25,
      maxHeight: 150,
      border: `2px solid ${theme.palette.grey[400]}`,
      borderRadius: 4,
      overflow: 'scroll',
    },
    jsonViewer: {
      marginTop: 20,
      maxHeight: 350,
      overflow: 'scroll'
    },

    active: {
      backgroundColor: theme.palette.grey[100]
    },
    tableRow: {
      padding: 1,
      height: 50
    },
    displayed: {
      margin: 1,
      border: `2px solid ${theme.palette.secondary.main}`
    }
  }
}