import React, { PureComponent } from 'react'
import qs from 'qs'
import axios from 'axios'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  menu: {
    marginTop: 55,
    marginBottom: 20
  },
  mediaSelect: {
    outline: 'none',
    marginLeft: 5
  },
  media: {
    backgroundColor: theme.palette.grey[100],
    bottomMargin: 15,
    outline: 'none'
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
})

class MediaButton extends PureComponent {
  state = {
    anchorEl: null,
    jpg: true,
    pdf: true,
    mp4: true
  }

  openMenu = event => {
    this.setState(
      {
        disabled: true,
        anchorEl: event.currentTarget
      },
      this.getSessionStatus
    )
  }

  getSessionStatus = () => {
    const { setBusySession } = this.props
    axios
      .post('media/sessionCheck', { ...this.props.data })
      .then(res => setBusySession(res.status === 200))
      .catch(() => setBusySession(true))
  }

  closeMenu = () => {
    this.setState({ anchorEl: null })
  }

  getQueryString = () => {
    const {
      state: { jpg, pdf, mp4 },
      props: { adList, data, groupName, monthCode }
    } = this
    // const { session, token } = axios.defaults.headers.common
    const { session, idtoken  } = axios.defaults.headers.common

    const list = adList.map(d => d.adCode)
    return qs.stringify(
      { list, jpg, pdf, mp4, data, session, idtoken, groupName, monthCode },
      { indices: false, arrayFormat: 'brackets' }
    )
  }

  getMediaURL = () => {
    const { endPoint } = this.props

    if (process.env.NODE_ENV === 'development') {
      return `http://localhost:4000/${endPoint}?${this.getQueryString()}`
    }

    return `/${endPoint}?${this.getQueryString()}`
  }

  togglePDF = e => {
    this.setState(prevState => {
      return {
        pdf: !prevState.pdf,
        disabled: prevState.pdf && !prevState.mp4
      }
    })
  }

  toggleMP4 = e => {
    this.setState(prevState => {
      return {
        mp4: !prevState.mp4,
        disabled: prevState.mp4 && !prevState.pdf
      }
    })
  }

  render() {
    const { anchorEl } = this.state
    const { adList, busySession, classes } = this.props
    const isDisabled = adList.length === 0

    const mediaButton = (
      <Button variant="contained" disabled={busySession} fullWidth>
        Download
      </Button>
    )

    return (
      <div>
        <Button
          variant="outlined"
          color="secondary"
          onClick={this.openMenu}
          disabled={isDisabled}
        >
          Export Media
        </Button>
        {/* <ClickAwayListener onClickAway={this.closeMenu}> */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            className={classes.menu}
            TransitionComponent={Fade}
            onClose={this.closeMenu}
            style={{ height: 600 }}
          >
            <MenuItem>
              {busySession && (this.state.pdf || this.state.mp4) ? (
                <div style={{ width: '100%' }}>
                  {mediaButton}
                  <CircularProgress size={24} className={classes.progress} />
                </div>
              ) : (
                <div style={{ width: '100%' }}>
                  <a
                    style={{ textDecoration: 'none' }}
                    onClick={this.closeMenu}
                    href={this.getMediaURL()}
                  >
                    {mediaButton}
                  </a>
                </div>
              )}
            </MenuItem>
            {this.props.mediaTypesOption ? (
              <React.Fragment>
                <Divider />
                <FormGroup className={classes.media}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={this.state.mp4}
                        onChange={this.toggleMP4}
                        value="mp4"
                      />
                    }
                    label="Include MP4s"
                    className={classes.mediaSelect}
                    style={{ marginBottom: 0 }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={this.state.pdf}
                        onChange={this.togglePDF}
                        value="pdf"
                      />
                    }
                    label="Include PDFs"
                    className={classes.mediaSelect}
                  />
                </FormGroup>
              </React.Fragment>
            ) : null}
          </Menu>
        {/* </ClickAwayListener> */}
      </div>
    )
  }
}

MediaButton.defaultProps = {
  mediaTypesOption: true
}

MediaButton.propTypes = {
  adList: PropTypes.arrayOf(
    PropTypes.shape({
      adCode: PropTypes.string.isRequired
    })
  ).isRequired,
  endPoint: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(MediaButton)
