import SearchFilter from './SearchFilter'
import SearchManual from './SearchManual'
import SearchToolbar from './SearchToolbar'
import SearchActions from './SearchActions'
import BrandsList from './BrandsList'
import ExtraFilter from './ExtraFilter'
import MetadataFilter from './MetadataFilter'
import SampleSizeFilter from './SampleSizeFilter'

export default Object.assign(
  theme => {
    return {
      paper: {
        width: 1280,
      },
      colorPrimary: {
        color: theme.palette.text.primary
      }
    }
  },
  {
    SearchFilter,
    SearchManual,
    SearchToolbar,
    SearchActions,
    BrandsList,
    ExtraFilter,
    MetadataFilter,
    SampleSizeFilter
  }
)
