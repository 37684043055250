import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import SampleSizeIcon from '@material-ui/icons/People'
import { format } from '../../../../../../../../new_utils'

import cn from 'classnames'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardFixedColumn.ScorecardNormHeader(theme)
)

export default Styled(props => {
  const {
    classes,
    collapsed,
    gridData: {
      perksList,
      normHeaders: { n, header_metrics }
    }
  } = props
  return (
    <Grid
      container
      id="norm-header"
      direction="column"
      justify="center"
      alignItem="flex-start"
      className={classes.container}
    >
      <Grid item className={cn(classes.headerGrid, classes.top)}>
        <Typography variant="h6" align="center">
          Norm
        </Typography>
      </Grid>
      <Grid item className={cn(classes.headerGrid)}>
        <Typography variant="body2" align="center" />
      </Grid>
      <Grid item className={cn(classes.headerGrid)}>
        <Typography variant="body2" align="center" />
      </Grid>
      {collapsed ? null : (
        <Fragment>
          <Grid item style={{height: 100 + perksList.length * 20}}/>

          {/* <Grid item className={classes.gridData}>
            <Typography variant="body2" align="center" />
          </Grid>
          <Grid item className={classes.gridData}>
            <Typography variant="body2" align="center" />
          </Grid>
          <Grid item className={classes.gridData}>
            <Typography variant="body2" align="center" />
          </Grid>

          {perksList.map((perk, index) => (
            <Grid key={perk.label} item className={cn(classes.headerGrid)}>
              <Typography variant="body2" align="center">.</Typography>
            </Grid>
          ))}
          <Grid item className={classes.gridData}>
            <Typography variant="body2" align="center" />
          </Grid>
          <Grid item className={classes.gridData}>
            <Typography variant="body2" align="center" />
          </Grid> */}
          <Grid item className={cn(classes.headerGrid)} >
            <SampleSizeIcon className={classes.icon} style={{ marginLeft: 25 }} />
            <Typography noWrap variant="caption" align="center" style={{ display: 'inline' }}>
              {format.comma(0)(n)}
            </Typography>
          </Grid>
        </Fragment>
      )}
      {header_metrics.map(header_metric => (
        <Grid item className={cn(classes.headerGrid)} key={header_metric.label}>
          <Typography noWrap variant="body2" align="center" className={classes.headerMetric}>
            {header_metric.avg}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
})
