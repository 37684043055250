import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

const styles = theme => ({
  menu: {
    marginTop: 55,
    marginBottom: 20
  },
  disabled: {
    color: 'rgba(0,0,0,0.25)'
  },
  enabled: {
    color: 'rgba(0,0,0,0.87)'
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
})

class QueryButton extends PureComponent {

  render() {
    const { classes, busy, action, visible } = this.props
    return visible ? (
      <Fragment>
        {
          busy ? (
            <div style={{ width: '100%', position: 'relative' }}>
              <Button variant="outlined" color="secondary" disabled={true}>
                Generating
            </Button>
              <CircularProgress size={24} className={classes.progress} />
            </div>
          ) : (
              <Button variant="outlined" color="secondary" onClick={action}>
                Query Tool
              </Button>
            )
        }
      </Fragment>

    ) : null
  }
}

QueryButton.propTypes = {
  // endPoint: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(QueryButton)
