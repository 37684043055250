export default theme => {
    return {
        content: {
            marginTop: 20
        },
        monthsContainer: {
            marginBottom: 10,
            marginTop: 10
        },
        monthsTitle: {
            marginTop: 10,
            marginBottom: 0
        },
        selected: {
            backgroundColor: theme.palette.listSelectBackground.default,
            color: theme.palette.text.primary
        },
        hover: {
          "&:hover": {
            backgroundColor: theme.palette.listSelectBackground.default,
          }
        }
    }
}
