import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import SampleSizeIcon from '@material-ui/icons/People'
import { format } from '../../../../../../../../new_utils'

import cn from 'classnames'

const Styled = withStyles(theme =>
    theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardFixedColumn.ScorecardNormHeader(
        theme
    )
)

export default Styled(props => {
    const {
        classes,
        collapse,
        collapsed,
        gridData: {
            normHeaders: { n, header_metrics }
        }
    } = props
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.container}
            onClick={collapse}
        >
            <Grid item className={cn(classes.headerGrid, classes.top)}>
                <Typography noWrap variant="body2">NORM</Typography>
            </Grid>
            <Grid item className={cn(classes.headerGrid)}>
                <Typography noWrap variant="body2" />
            </Grid>
            <Grid item className={cn(classes.headerGrid)}>
                <Typography noWrap variant="body2" />
            </Grid>
            <Grid item className={cn(classes.headerGrid)}>
                <Typography noWrap variant="body2" />
            </Grid>
            {collapsed ? null : (
                <Fragment>
                    <Grid className={classes.headerGrid} item>
                        <Typography noWrap variant="body2" />
                    </Grid>
                    <Grid item className={classes.headerGrid}>
                        <Typography noWrap variant="body2" />
                    </Grid>
                    <Grid item className={classes.headerGrid}>
                        <Typography noWrap variant="body2" />
                    </Grid>
                    <Grid item className={cn(classes.headerGrid, classes.center)}>
                        <SampleSizeIcon className={classes.icon} />
                        <Typography
                            noWrap
                            variant="caption"
                            align="center"
                            style={{ display: 'inline' }}
                        >
                            {format.comma(0)(n)}
                        </Typography>
                    </Grid>
                </Fragment>
            )}
            {header_metrics.map(header_metric => (
                <Grid item className={cn(classes.headerGrid)} key={header_metric.label}>
                    <Typography noWrap variant="body2" align="center" className={classes.headerMetric}>
                        {header_metric.avg}
                    </Typography>
                </Grid>
            ))}
        </Grid>


    )
})
