import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  Button,
  Typography
} from '@material-ui/core'

import Months from './Months'
const Styled = withStyles(theme => theme.components.generic.MonthPicker(theme))

// function PaperComponent(props) {
//   return (
//     <Draggable>
//       <Paper {...props} />
//     </Draggable>
//   )
// }

class MonthPicker extends Component {
  state = {
    start_month: null,
    end_month: null
  }

  componentDidMount() {
    const {
      range: { start_month, end_month }
    } = this.props
    this.setState({
      start_month,
      end_month
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { start_month, end_month } = this.props.range
    if (
      start_month !== prevProps.range.start_month ||
      end_month !== prevProps.range.end_month
    )
      this.setState({
        start_month,
        end_month
      })
  }
  update = (type, value) => {
    this.setState({
      [type]: value
    })
  }

  apply = () => {
    const { apply } = this.props
    const { start_month, end_month } = this.state
    apply({
      start_month,
      end_month
    })
  }

  render() {
    const { classes, open, close, maxRange } = this.props

    const { start_month, end_month } = this.state
    return (
      // <Dialog open={open} onClose={close} PaperComponent={PaperComponent}>
      <Dialog open={open} onClose={close}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" color="textPrimary" align="left">
              Select Date Range - Month Picker
            </Typography>
          </Toolbar> 
        </AppBar>

        <DialogContent>
          <Grid className={classes.content}>
            <Months
              selected={start_month}
              restrictMax={end_month}
              maxRange={maxRange}
              title="Start Month"
              update={value => this.update('start_month', value)}
            />
            <Months
              selected={end_month}
              restrictMin={start_month}
              maxRange={maxRange}
              title="End Month"
              update={value => this.update('end_month', value)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.apply} color="secondary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default Styled(MonthPicker)
