const tStatIndex = (main, norm) => {
  const v =
    (main.avg - norm.avg) /
    Math.sqrt((main.std * main.std) / main.n + (norm.std * norm.std) / norm.n)
  return v > 1.645 ? 1 : v < -1.645 ? -1 : 0
}

const tStatNonIndex = ( main, norm) => {
    const numerator = (main.avg * main.n + norm.avg * norm.n)/(main.n + norm.n)
    const denominator = 1 - numerator
    const steptest = Math.sqrt( numerator * denominator  * ( 1 / norm.n + 1 / main.n) )
    const v = (main.avg - norm.avg) / steptest
    return v > 1.645 ? 1 : v < -1.645 ? -1 : 0
}

export {
  tStatIndex,
  tStatNonIndex
}
