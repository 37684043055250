import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = theme => ({
  text: {
    transform: 'scale(1.0)',
    transition: 'all 0.5s',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
})

class WordCloud extends Component {
  render() {
    const { classes, data, size, handleClick } = this.props

    return (
      <svg id="word-cloud-svg" width={size[0]} height={size[1]}>
        {data.map(d => {
          const textStyle = {
            fontSize: d.size,
            fontFamily:"OktaNeueRegular",
            fill: d.color,
            cursor: 'pointer',
          }

          return (
            <g
              key={d.text}
              transform={`translate(${size[0] / 2},${size[1] / 2})`}
            >
              <g transform={`translate(${[d.x, d.y]})rotate(${d.rotate})`}>
                <text
                  className={classes.text}
                  style={textStyle}
                  textAnchor="middle"
                  onClick={() => handleClick(d)}
                >
                  {d.text}
                </text>
              </g>
            </g>
          )
        })}
      </svg>
    )
  }
}

WordCloud.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  size: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(WordCloud)
