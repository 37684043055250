import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router-dom'
import { resourceConfig } from '../configuration'
// import { Config, resourceConfig } from '../configuration'

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 10,
    marginBottom: 10
  },
  header: {
    textAlign: 'center',
    width: '100%',
    padding: '0px 5px',
    marginTop: 10,
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary
  },
  content: {
    textAlign: 'center',
    width: '100%',
    minHeight: '200px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px'
  },
  headerLeft: {
    textAlign: 'left',
    marginTop: -4
  },
  headerRight: {
    marginTop: '-12px',
    textAlign: 'right'
  },
  image: {
    height: 20,
    // marginRight: 20
  },
  boxShadow: {
    border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
    boxShadow: `0 1px 1px #E7E7E7`
  },
  item: {
    '&:hover': {
      backgroundColor: theme.palette.listSelectBackground.default
    }
  }
})

const navigate = (route, home, history) => {
  route.match('https') ? window.open(route, '_blank').focus() : history.push(`${route}${home || ''}`)
}

const Apps = props => {
  const { user, classes, refreshApps, history } = props

  let content = (
    <div style={{ padding: 10 }}>
      <CircularProgress size={50} />
    </div>
  )

  if (user.ready) {
    const userResources = user.resources['***']
      ? resourceConfig.resources.map(config => config.groupName)
      : Object.keys(user.resources || {})

    content = userResources.length ? (
      <List style={{ paddingTop: 0 }} component="nav">
        {userResources
          .map(app => {
            return resourceConfig.resources.find(config => config.groupName === app)
          })
          .filter(app => typeof app === 'object')
          .map(app => {
            // console.log(app)
            const {
              config: { main }
            } = app.config
            return (
              <ListItem key={main.description} button onClick={() => navigate(main.baseRoute, main.home, history)} className={classes.item}>
                <img
                  className={classes.image}
                  src={`https://cloud-cdn.phoenixmi.com/groups/${main.image}`}
                  alt={main.description}
                  width={65}
                />
                <ListItemText primary={main.description} style={{paddingLeft: 20}}/>
              </ListItem>
            )
          })}
      </List>
    ) : (
      <Typography style={{ padding: 10 }} variant="body2" color="inherit" noWrap>
        You have not been given access to any applications yet.
      </Typography>
    )
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container>
          <Grid style={{ padding: 0 }} item xs={12}>
            <Paper className={classes.header} elevation={0} classes={{ elevation0: classes.boxShadow }}>
              <Grid container spacing={3} style={{paddingTop: 10}}>
                <Grid className={classes.headerLeft} item xs={12} sm={6}>
                  <Typography style={{ paddingLeft: 10 }} variant="subtitle1" color="inherit" noWrap>
                    Applications
                  </Typography>
                </Grid>
                <Grid className={classes.headerRight} item xs={12} sm={6}>
                  <Tooltip id="refresh-tooltip" title="Refresh">
                    <IconButton
                      disabled={user.isFetching}
                      className={classes.button}
                      color="inherit"
                      onClick={refreshApps}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid style={{ padding: 0 }} item xs={12}>
            <Paper className={classes.content} elevation={0} classes={{ elevation0: classes.boxShadow }}>
              {content}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

Apps.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  refreshApps: PropTypes.func.isRequired
}

export default withRouter(withStyles(styles)(Apps))
