import QuickviewCanvasContainer from './QuickviewCanvasContainer'
import MetricDonut from './MetricDonut'
import MetricChart from './MetricChart'
import MetricTable from './MetricTable'
import MetricOpenend from './MetricOpenend'
import MetricVerticalArray from './MetricVerticalArray'
import QuickviewLegend from './QuickviewLegend'

export default Object.assign(
  theme => {
    return {
      colorPrimary: {
        color: theme.palette.text.primary
      }
    }
  },
  {
    QuickviewCanvasContainer,
    MetricChart,
    MetricDonut,
    MetricTable,
    MetricOpenend,
    MetricVerticalArray,
    QuickviewLegend
  }
)
