import setBuilder from '../../../../../Template/config/components/selection/SetBuilder'
import setBuilderToolbar from './setBuilderToolbar'
import setBuilderExport from './setBuilderExport'
import setBuilderListView from './SetBuilderListView'
export default  {
    ...setBuilder,
    includeSampleSize: false,
    setBuilderToolbar,
    setBuilderExport,
    setBuilderListView
}
