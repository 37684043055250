export default theme => {
    return {
        container: {
            marginBottom: 10
        },
        swatch: {
            width: 16,
            height: 16,
            borderRadius: 4,
            display: 'inline-block',
            marginRight: 5
        },
        item: {
            padding: '3px !important'
        }
    }
}