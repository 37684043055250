export default {
  '***': [
    {
      "id": 10,
      "label": "Ad Messages",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        20,
        30,
        40,
        50,
        60,
        70,
        80,
        90,
        100,
        110,
        120,
        130,
        140,
        150,
        160
      ],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 20,
      "label": "Cash Back Rewards",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 30,
      "label": "Credit Monitoring",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 40,
      "label": "No Annual Fee",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 50,
      "label": "Security",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 60,
      "label": "Customer Service",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 70,
      "label": "Technology",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 80,
      "label": "Travel",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 90,
      "label": "CC Network benefits",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 100,
      "label": "Innovation",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 110,
      "label": "Acceptance",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 120,
      "label": "Brand image",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 130,
      "label": "Cause",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 140,
      "label": "Sponsorship",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 150,
      "label": "Bank",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 160,
      "label": "Small Business",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 200,
      "label": "Creative Style",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        210,
        220,
        230,
        240,
        250
      ],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 210,
      "label": "Humorous",
      "selected": 0,
      "collapse": 0,
      "parent": 200,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 220,
      "label": "Emotional",
      "selected": 0,
      "collapse": 0,
      "parent": 200,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 230,
      "label": "Spokesperson",
      "selected": 0,
      "collapse": 0,
      "parent": 200,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 240,
      "label": "Celebrity",
      "selected": 0,
      "collapse": 0,
      "parent": 200,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 250,
      "label": "Promotional/Limited time offer",
      "selected": 0,
      "collapse": 0,
      "parent": 200,
      "children": [],
      "tooltip": "No tooltip provided"
    }
  ]
}