export default {
  columnFormat: [
    { header: 'Advertiser', key: 'brand', width: 25 },
    { header: 'Ad Code', key: 'ad_code', width: 20 },
    { header: 'Last Month Tested', key: 'last_month_tested', width: 20 },
    { header: 'Sample Size', key: 'sample_size', width: 20 },
    { header: 'Ranking Metric', key: 'ranking_metric', width: 20 },
    { header: 'Title', key: 'title', width: 32 }
  ]
}
