export default theme => {
  return {
    container: {
      marginTop: 5,
      padding: 25
    },
    card: {
      width: 300
    },
    cardContent: {
      padding: '0 10px 0 10px !important'
    },
    media: {
      height: 200,
      margin: 10,
      boxShadow: 0,
      borderRadius: 5,
      cursor: 'pointer'
    },
    brand: {
      width: 60,
      marginTop: 15,
      marginRight: 5
    },
    button: {
      marginLeft: 0,
      paddingLeft: 0
    },
    icon: {
      marginTop: -5,
      marginRight: 8
    },
    checkBox: {
      marginLeft: -12
    },
    title: {
      fontSize: 12,
      marginBottom: 5
    },
    boxShadow: {
      border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
      boxShadow: `0 1px 1px #E7E7E7`
    }
  }
}
