export default {
  slices: [
    {
      field: 'ad_type',
      display: 'Media Type',
      map: {
        'tv': {
          display: 'TV',
          sortOrder: 1
        },
        'print': {
          display: 'Print',
          sortOrder: 2
        },
        'online_display': {
          display: 'Online Display',
          sortOrder: 3
        },
        'online_video': {
          display: 'Online Video',
          sortOrder: 4       
        }
      }
    }
  ]
}
