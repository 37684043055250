export default theme => {
  return {
    container: {
      height: '100%',
      paddingLeft: 15,
      fontFamily: 'Roboto',
      fontSize: 11,
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    top: {
      marginTop: 80
    },
    headerGrid: {
      height: 18
    },
    headerMetric: {
      fontWeight: 'bold'
    }
  }
}
