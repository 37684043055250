import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ListViewIcon from '@material-ui/icons/ViewList'
import GridViewIcon from '@material-ui/icons/ViewModule'

const Styled = withStyles( theme => theme.components.generic.ListGridViewSelector(theme))
export default Styled(props => {
  const { update, showGridView } = props
  return (
    <Grid container justify="flex-end">
      <Grid item>
        <IconButton
          color={showGridView ? 'default' : 'secondary'}
          onClick={() => update(false)}
        >
          <ListViewIcon style={{ fontSize: 32 }} />
        </IconButton>
        <IconButton
          color={showGridView ? 'secondary' : 'default'}
          onClick={() => update(true)}
        >
          <GridViewIcon style={{ fontSize: 32 }} />
        </IconButton>
      </Grid>
    </Grid>
  )
})
