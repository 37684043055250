import templateScorecard from '../../../../Template/config/components/scorecard'
import metricsLabels from './MetricsLabels'
import scorecardView from './ScorecardView'
import trendChart from './Trendchart'

export default {
  ...templateScorecard,
  maxAdsLength: 330,
  maxNormLength: 330,
  sampleSizeMetric: 4,
  sampleSizeThreshold: 40,
  hideMetricWithMissingNorm: false,
  sortMetric: 2,
  calcFlags: {
    ad: {
      includeAllHistory: true,
      excludeProprietary: false
    },
    norm: {
      includeAllHistory: true,
      excludeProprietary: true,
      excludeNonNorm: true,
      minSampleSize: 0
    }
  },
  metricsLabels,
  scorecardView,
  trendChart
}
