import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import DynamicFilterElement from './DynamicFilterElement'

const styles = theme => ({
  paper: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    marginTop: 10,
    marginBottom: 20,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  boxShadow: {
    border: `1px solid ${theme.palette.background.default}`,
    boxShadow: `0 1px 1px rgba(0,0,0,0.14)`
  }
})

class DynamicFilters extends PureComponent {
  update = (index, v) => {
    const { update } = this.props

    const selections = this.props.selections.map((selection, idx) => {
      return idx < index
        ? selection
        : idx === index
          ? { field: selection.field, value: v }
          : { field: selection.field, value: null }
    })
    update(selections)
  }

  render() {
    const { classes, elements, selections } = this.props

    return (
      <Paper elevation={0} className={classes.paper} classes={{ elevation0: classes.boxShadow }}>
        <Grid container spacing={0} alignItems="stretch">
          {elements.map((filter, index) => {
            return (
              <DynamicFilterElement
                key={filter.label}
                label={filter.label}
                options={filter.options}
                selection={Array.isArray(selections) && selections[index]}
                element={filter}
                update={v => this.update(index, v)}
              />
            )
          })}
        </Grid>
      </Paper>
    )
  }
}

DynamicFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  scorecard: PropTypes.shape({
    textHeader: PropTypes.array.isRequired,
    textTable: PropTypes.array.isRequired,
    normHeader: PropTypes.array.isRequired,
    normTable: PropTypes.array.isRequired,
    mainHeader: PropTypes.array.isRequired,
    mainTable: PropTypes.array.isRequired
  })
}

export default withStyles(styles)(DynamicFilters)
