import generic from './generic'
import selection from './selection'
import scorecard from './scorecard'
import adminView from './adminView'
import quickview from './quickview'

export default {
  generic,
  selection,
  scorecard,
  adminView,
  quickview
}
