
const DASHBOARD_REQUEST_METADATA = 'DASHBOARD_REQUEST_METADATA'
const DASHBOARD_RECEIVE_METADATA = 'DASHBOARD_RECEIVE_METADATA'
const DASHBOARD_FAIL_METADATA = 'DASHBOARD_FAIL_METADATA'

const DASHBOARD_REQUEST_UPDATE_METRICS = 'DASHBOARD_REQUEST_UPDATE_METRICS'
const DASHBOARD_REQUEST_METRICS = 'DASHBOARD_REQUEST_METRICS'
const DASHBOARD_RECEIVE_METRICS = 'DASHBOARD_RECEIVE_METRICS'
const DASHBOARD_FAIL_METRICS = 'DASHBOARD_FAIL_METRICS'

const DASHBOARD_REQUEST_UPDATE_NORM = 'DASHBOARD_REQUEST_UPDATE_NORM'
const DASHBOARD_REQUEST_NORM = 'DASHBOARD_REQUEST_NORM'
const DASHBOARD_RECEIVE_NORM = 'DASHBOARD_RECEIVE_NORM'
const DASHBOARD_FAIL_NORM = 'DASHBOARD_FAIL_NORM'

const DASHBOARD_REQUEST_QUICKVIEW  = 'DASHBOARD_REQUEST_QUICKVIEW'
const DASHBOARD_RECEIVE_QUICKVIEW  = 'DASHBOARD_RECEIVE_QUICKVIEW'
const DASHBOARD_FAIL_QUICKVIEW  = 'DASHBOARD_FAIL_QUICKVIEW'

const DASHBOARD_REQUEST_SORT = 'DASHBOARD_REQUEST_SORT'
const DASHBOARD_REQUEST_UPDATE_SORT = 'DASHBOARD_REQUEST_UPDATE_SORT'
const DASHBOARD_RECEIVE_SORT = 'DASHBOARD_RECEIVE_SORT'
const DASHBOARD_FAIL_SORT = 'DASHBOARD_FAIL_SORT'

const DASHBOARD_REQUEST_MEDIA_LINK = 'DASHBOARD_REQUEST_MEDIA_LINK'
const DASHBOARD_RECEIVE_MEDIA_LINK = 'DASHBOARD_RECEIVE_MEDIA_LINK'
const DASHBOARD_FAIL_MEDIA_LINK = 'DASHBOARD_FAIL_MEDIA_LINK'

const DASHBOARD_REQUEST_MEDIA_DOWNLOAD = 'DASHBOARD_REQUEST_MEDIA_DOWNLOAD'
const DASHBOARD_RECEIVE_MEDIA_DOWNLOAD = 'DASHBOARD_RECEIVE_MEDIA_DOWNLOAD'
const DASHBOARD_FAIL_MEDIA_DOWNLOAD = 'DASHBOARD_FAIL_MEDIA_DOWNLOAD'

const DASHBOARD_REQUEST_PRESET = 'DASHBOARD_REQUEST_PRESET'
const DASHBOARD_RECEIVE_PRESET = 'DASHBOARD_RECEIVE_PRESET'
const DASHBOARD_FAIL_PRESET = 'DASHBOARD_FAIL_PRESET'

const DASHBOARD_REQUEST_UPDATE_PRESET = 'DASHBOARD_REQUEST_UPDATE_PRESET'

const DASHBOARD_REQUEST_DELETE_PRESET = 'DASHBOARD_DELETE_PRESET'

export {
    DASHBOARD_REQUEST_METADATA,
    DASHBOARD_RECEIVE_METADATA,
    DASHBOARD_FAIL_METADATA,
    
    DASHBOARD_REQUEST_METRICS,
    DASHBOARD_REQUEST_UPDATE_METRICS,
    DASHBOARD_RECEIVE_METRICS,
    DASHBOARD_FAIL_METRICS,
    
    DASHBOARD_REQUEST_NORM,
    DASHBOARD_REQUEST_UPDATE_NORM,
    DASHBOARD_RECEIVE_NORM,
    DASHBOARD_FAIL_NORM,

    DASHBOARD_REQUEST_SORT,
    DASHBOARD_REQUEST_UPDATE_SORT,
    DASHBOARD_RECEIVE_SORT,
    DASHBOARD_FAIL_SORT,

    DASHBOARD_REQUEST_QUICKVIEW,
    DASHBOARD_RECEIVE_QUICKVIEW,
    DASHBOARD_FAIL_QUICKVIEW,

    DASHBOARD_REQUEST_MEDIA_LINK,
    DASHBOARD_RECEIVE_MEDIA_LINK,
    DASHBOARD_FAIL_MEDIA_LINK,

    DASHBOARD_REQUEST_MEDIA_DOWNLOAD,
    DASHBOARD_RECEIVE_MEDIA_DOWNLOAD,
    DASHBOARD_FAIL_MEDIA_DOWNLOAD,

    DASHBOARD_REQUEST_PRESET,
    DASHBOARD_REQUEST_UPDATE_PRESET,
    DASHBOARD_REQUEST_DELETE_PRESET,
    DASHBOARD_RECEIVE_PRESET,
    DASHBOARD_FAIL_PRESET
}