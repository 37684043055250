import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Tooltip from '@material-ui/core/Tooltip'

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    padding: 0,
  },
  item: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: 10,
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: theme.palette.listSelectBackground.default
    }
  },
  nested: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: 10,
    paddingTop: 0,
    paddingBottom: 0,
  },
})

class NestedListComponent extends React.Component {
  state = { open: false }

  handleClick = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const {
      classes,
      item,
      toggle,
      nested,
      adTagsMap,
      padding,
      listPadding,
    } = this.props

    return (
      <div className={classes.root}>
        <List
          className={classes.list}
          style={{ paddingLeft: listPadding }}
          component="nav"
        >
          <Tooltip title={item.tooltip} placement="top-start">
            <ListItem
              button
              onClick={e => {
                e.stopPropagation()
                this.handleClick()
              }}
              className={classes.item}
            >
              <Checkbox
                color="secondary"
                onClick={e => {
                  e.stopPropagation()
                  toggle(item.id)
                }}
                indeterminate={item.selected === 1}
                checked={item.selected === 2}
              />
              <ListItemText primary={item.label} primaryTypographyProps={{ variant: 'body2' }} />
              <IconButton>
                {this.state.open ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </ListItem>
          </Tooltip>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <List
              style={{ paddingLeft: 25, paddingTop: 0, paddingBottom: 0 }}
              component="div"
            >
              {nested.map(d => {
                if (d.children.length > 0) {
                  return (
                    <NestedList
                      key={`${d.id}-${d.label}`}
                      listPadding={listPadding + padding / 2 - 1}
                      toggle={toggle}
                      padding={padding + padding}
                      adTagsMap={adTagsMap}
                      item={d}
                      nested={d.children.map(c => adTagsMap[c])}
                    />
                  )
                }

                return (
                  <div key={`${d.id}-${d.label}`}>
                    <Divider light />
                    <Tooltip title={d.tooltip} placement="top-start">
                      <ListItem
                        button
                        onClick={() => toggle(d.id)}
                        style={{ paddingLeft: padding }}
                        className={classes.nested}
                      >
                        <Checkbox
                          color="secondary"
                          indeterminate={d.selected === 1}
                          checked={d.selected === 2}
                        />
                        <Typography align="left" variant="body2" noWrap>
                          {d.label}
                        </Typography>
                      </ListItem>
                    </Tooltip>
                  </div>
                )
              })}
            </List>
          </Collapse>
        </List>
      </div>
    )
  }
}

NestedListComponent.propTypes = {
  adTagsMap: PropTypes.object.isRequired,
  padding: PropTypes.number.isRequired,
  listPadding: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  nested: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
}

const NestedList = withStyles(styles)(NestedListComponent)

export default NestedList
