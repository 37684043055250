export default theme => {
  return {
    container: {
      padding: 6,
      border: `1px solid ${theme.palette.grey[300]}`
    },
    menuText: {
      fontSize: 12,
      paddingTop: 5
    },
    inputText: {
      fontSize: 12,
      maxWidth: '100%',
      color: theme.palette.text.primary
    },
    menuItemRoot: {
      padding: 0
    },
    label: {
      fontSize: 14,
      color: theme.palette.text.primary
    },
    focusedForm: {
        color: `${theme.palette.text.primary} !important`
    },
    checkBox: {
      padding: 5
    },
    activeType: {
      '&:hover': {
        backgroundColor: theme.palette.listSelectBackground.default
      }
    }
  }
}
