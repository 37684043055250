const rgbToHsv = _hex => {
  const hex = String(_hex).replace(/[^0-9a-f]/gi, '')
  const r = parseInt(hex.substr(0, 2), 16) / 255
  const g = parseInt(hex.substr(2, 2), 16) / 255
  const b = parseInt(hex.substr(4, 2), 16) / 255

  const Cmax = Math.max(r, g, b)
  const Cmin = Math.min(r, g, b)
  const delta = Cmax - Cmin

  let hue = 0
  if (delta) {
    if (Cmax === r) hue = (((g - b) / delta) % 6) * 60
    else if (Cmax === g) hue = ((b - r) / delta + 2) * 60
    else hue = ((r - b) / delta + 4) * 60
  }

  const saturation = Cmax ? delta / Cmax : 0
  const value = Cmax
  return [hue, saturation, value]
}

const hsvToRgb = ([hue, saturation, value]) => {
  const C = value * saturation
  const X = C * (1 - Math.abs(((hue / 60) % 2) - 1))
  const m = value - C

  let _r, _g, _b
  if (hue < 60) [_r, _g, _b] = [C, X, 0]
  else if (hue < 120) [_r, _g, _b] = [X, C, 0]
  else if (hue < 180) [_r, _g, _b] = [0, C, X]
  else if (hue < 240) [_r, _g, _b] = [0, X, C]
  else if (hue < 300) [_r, _g, _b] = [X, 0, C]
  else [_r, _g, _b] = [C, 0, X]

  const [r, g, b] = [(_r + m) * 255, (_g + m) * 255, (_b + m) * 255]
  return [
    Math.round(r).toString(16),
    Math.round(g).toString(16),
    Math.round(b).toString(16)
  ]
}

const zerFillToHex = hex => {
  const value = parseInt(hex, 16)
  return `${value < 16 ? '0' : ''}${hex}`
}

const changeColor = (
  color,
  { satPercentage = 0, huePercentage = 0, valuePercentage = 0 }
) => {
  const [_hue, _saturation, _value] = rgbToHsv(color)
  const [hue, saturation, value] = [
    Math.min(_hue * (1 + huePercentage / 100), 360),
    Math.min(_saturation * (1 + satPercentage / 100), 1),
    Math.min(_value * (1 + valuePercentage / 100), 1)
  ]
  const [_r, _g, _b] = hsvToRgb([hue, saturation, value])
  const r = zerFillToHex(_r)
  const g = zerFillToHex(_g)
  const b = zerFillToHex(_b)
  
  return `#${[r,g,b].join('')}`
}

export default {
  changeColor
}
