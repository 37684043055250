import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { HashRouter as Router } from 'react-router-dom'
import NavBar from './NavBar'
import Apps from './Apps'
import { connect } from 'react-redux'
import { fetchUserResources } from '../../new_actions/user'

const styles = theme => ({
  root: {
    width: '100%',
    zIndex: 1,
    overflow: 'hidden',
  },
  grid: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  fullWidth: {
    padding: 0,
    width: '100%',
  },
  pageFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    height: 'calc(100% - 56px)',
    marginTop: 56,
  },
})

class Home extends React.Component {

  render() {
    const { classes, user, dispatch } = this.props

    return (
      <div className={classes.root}>
        <Router>
          <div className={classes.pageFrame}>
            <NavBar
              showHome={false}
              showMenuIcon={false}
              title={'Application Portal'}
              user={user}
            />

            <main className={classes.content}>
              <Grid
                container
                className={classes.grid}
                justify="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    className={classes.demo}
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={6}>
                      <Typography
                        variant="h5"
                        color="inherit"
                        align="center"
                      >
                        Welcome to the MarketCast Application Portal
                          </Typography>
                      <Typography variant="body2" color="inherit">
                        You can navigate to all of the applications you have
                        been given access to in the list below. To get back
                        to the Application Portal, click on the 'App Home'
                        navigation option in the menu of any application.
                          </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={classes.demo}
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={6}>
                      <Apps
                        user={user}
                        refreshApps={() => dispatch(fetchUserResources())}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={classes.demo}
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={3}>
                      <Typography
                        variant="h6"
                        color="inherit"
                      >
                        Can't find what you're looking for?
                          </Typography>
                      <br />
                      <Typography variant="body2" color="inherit">
                        1. Try refreshing the applications list by clicking
                        the circular arrow icon.
                          </Typography>
                      <br />
                      <Typography variant="body2" color="inherit">
                        2. Email your research contact, or account representative, to begin the troubleshooting process.
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="subtitle1"
                        color="inherit"
                      >
                        Ending your session
                          </Typography>
                      <br />
                      <Typography variant="body2" color="inherit">
                        You can logout at anytime by hitting the power
                        button in the upper right of the screen. When you
                        logout, you will have to sign back in the next time
                        you want to access this application. If you prefer
                        to stay logged in on your machine just close the
                        browser when you are done.
                          </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.demo}
                  justify="center"
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <Typography variant="body2" color="inherit">
                      <a href="https://www.phoenixmi.com/privacy-policy/"
                        target="_blank" rel="noopener noreferrer">
                        Our privacy policy
                          </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </main>
          </div>
        </Router>
      </div>
    )
  }
}

Home.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return { user: state.user }
}

export default connect(mapStateToProps)(withStyles(styles)(Home))
