import React from 'react'
import { Auth, Logger } from 'aws-amplify'
import AuthPiece from './AuthPiece'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import StyledContainer from './StyledContainer'

const styles = theme => ({
  submitButton: {
    width: '100%',
    minWidth: 'unset',
    margin: '30px 0px',
  },
})

const logger = new Logger('ForgotPassword')

class ForgotPassword extends AuthPiece {
  constructor(props) {
    super(props)

    this.send = this.send.bind(this)
    this.submit = this.submit.bind(this)

    this._validAuthStates = ['forgotPassword']
    this.state = { delivery: null }
  }

  send() {
    const { username } = this.inputs
    Auth.forgotPassword(username)
      .then(data => {
        logger.debug(data)
        this.setState({ delivery: data.CodeDeliveryDetails })
      })
      .catch(err => this.error(err))
  }

  submit() {
    const { username, code, password } = this.inputs
    Auth.forgotPasswordSubmit(username, code, password)
      .then(data => {
        logger.debug(data)
        this.changeState('signIn')
        this.setState({ delivery: null })
      })
      .catch(err => this.error(err))
  }

  sendView() {
    const { classes } = this.props

    return (
      <div>
        <TextField
          id="email"
          label="email"
          fullWidth
          margin="normal"
          name="username"
          onChange={this.handleInputChange}
        />
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: 30 }}
          className={classes.submitButton}
          onClick={this.send}
        >
          Send Code
        </Button>
      </div>
    )
  }

  submitView() {
    const { classes } = this.props

    return (
      <form noValidate autoComplete="off">
        <TextField
          id="code"
          label="code"
          fullWidth
          margin="normal"
          name="code"
          onChange={this.handleInputChange}
        />
        <TextField
          id="password"
          label="new password"
          fullWidth
          margin="normal"
          name="password"
          autoComplete="new-password"
          type="password"
          onChange={this.handleInputChange}
        />
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: 30 }}
          className={classes.submitButton}
          onClick={this.submit}
        >
          Submit
        </Button>
      </form>
    )
  }

  showComponent(theme) {
    const { hide } = this.props

    if (hide && hide.includes(ForgotPassword)) {
      return null
    }

    return (
      <StyledContainer message="Forgot Password">
        {this.state.delivery ? this.submitView() : this.sendView()}
        <Button
          color="primary"
          style={{ marginLeft: 80 }}
          onClick={() => this.changeState('signIn')}
        >
          Back to Sign In
        </Button>
      </StyledContainer>
    )
  }
}

export default withStyles(styles)(ForgotPassword)
