import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import FilterElement from './FilterElement'
import {
  Grid,
  Paper
} from '@material-ui/core'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardFilter(theme)
)

class ScorecardFilter extends PureComponent {
  componentDidCatch(err) {
    console.log('ScorecardFilter error - ', err)
  }

  render() {
    const {
      classes,
      filter: { staticFilters },
      update,
      disabled
    } = this.props
    return (
      <Paper className={classes.container} elevation={0} >
        <Grid
          container
          alignItems="center"
          spacing={0}
        >
          {Object.keys(staticFilters).map(code => {
            const filter = staticFilters[code]
            return (
                <FilterElement update={update} element={filter} key={filter.code} disabled={disabled}/>
            )
          })}
        </Grid>
      </Paper>
    )
  }
}

export default Styled(ScorecardFilter)
