import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Button,
  MenuItem,
  FormControl,
  TextField,
  Select,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone'
import { AsyncWrapper } from '../../new_components'

const Styled = withStyles(theme => {
  return theme.components.survey(theme)
})

const categories = [
  { value: 0, label: 'Banking Services' },
  { value: 1, label: 'Business Amex Card' },
  { value: 2, label: 'Consumer Amex Card' }
]
const brands = [
  { value: 0, label: 'American Express' },
  { value: 1, label: 'Bank of America' },
  { value: 2, label: 'Capital One' },
  { value: 3, label: 'Chase' },
  { value: 4, label: 'Citibank' },
  { value: 5, label: 'Discover' },
  { value: 6, label: 'Mastercard' },
  { value: 7, label: 'US Bank' },
  { value: 8, label: 'Visa' },
  { value: 9, label: 'Wells Fargo' },
  { value: 10, label: 'Other' }
]

const markets = [{ value: 0, label: 'USA' }, { value: 1, label: 'Future TBD' }]
const adLengths = [{ value: 0, label: 15 }, { value: 1, label: 30 }, { value: 2, label: 60 }, { value: 3, label: 90 }]

class Survey extends React.PureComponent {
  state = {
    market: 0,
    brand: 0,
    category: 0,
    adLength: 0,
    confirmed: false,
    submitting: false,
    dialogOpen: false,
    nameError: false,
    titleError: false,
    title: '',
    name: ''
  }

  componentDidMount() {
    this.setState({
      market: 0,
      brand: 0,
      category: 0,
      adLength: 0,
      confirmed: false,
      nameError: false,
      titleError: false,
      title: '',
      name: ''
    })
  }

  updateName = event => {
    this.setState({ name: event.target.value })
  }
  updateTitle = event => {
    this.setState({ title: event.target.value })
  }

  updateCategory = event => {
    this.setState({
      category: event.target.value
    })
  }

  updateBrand = event => {
    this.setState({
      brand: event.target.value
    })
  }

  updateMarket = event => {
    this.setState({
      market: event.target.value
    })
  }

  updateAdLength = event => {
    this.setState({
      adLength: event.target.value
    })
  }

  toggleConfirm = () => {
    this.setState({
      confirmed: !this.state.confirmed
    })
  }

  submit = () => {
    if (this.state.title.length < 2) {
      this.setState({
        titleError: true
      })
      return
    }
    this.setState({
      submitting: true,
      titleError: false,
      nameError: false
    })
    setTimeout(this.openDialog, 3000)
  }

  openDialog = () => {
    this.setState({
      dialogOpen: true,
      submitting: false
    })
  }

  closeDialog = () => {
    const { history } = this.props
    this.setState({
      dialogOpen: false
    })
    history.push('/dashboard/adpi-category-wireless/slicer')
  }

  render() {
    const { classes } = this.props
    return (
      <AsyncWrapper item={{ ready: !this.state.submitting }} loadingMsg="Submitting your survey ... ">
        <Grid container className={classes.container} direction="column">
          <Typography variant="h6" className={classes.title}>
            Launch Your Copy Test By Answering the Following Questions
          </Typography>

          <TextField
            error={this.state.nameError}
            id="name-field-study"
            label="Name your study"
            variant="outlined"
            size="small"
            value={this.state.name}
            onChange={this.updateName}
          />
          <Typography variant="caption">Provide a label to identify your study</Typography>

          <div className={classes.selectContainer}>
            <Typography id="category-input">Product Category</Typography>
            <FormControl classes={{ root: classes.selectForm }} variant="outlined">
              <Select
                labelId="product-category-select-label"
                id="product-category-select"
                value={this.state.category}
                onChange={this.updateCategory}
              >
                {categories.map(category => {
                  return (
                    <MenuItem key={category.label} value={category.value}>
                      {category.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>

          <TextField
            error={this.state.titleError}
            id="name-field-title"
            label="Ad Title"
            variant="outlined"
            size="small"
            style={{ marginTop: 5 }}
            value={this.state.title}
            onChange={this.updateTitle}
          />
          <Typography variant="caption">Minimum ad title length is 2 characters</Typography>

          <div className={classes.selectContainer}>
            <Typography id="brands-input">Brands</Typography>
            <FormControl classes={{ root: classes.selectForm }} variant="outlined">
              <Select
                labelId="brand-select-label"
                id="brand-select"
                value={this.state.brand}
                onChange={this.updateBrand}
              >
                {brands.map(brand => {
                  return (
                    <MenuItem key={brand.label} value={brand.value}>
                      {brand.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>

          <div className={classes.selectContainer}>
            <Typography id="markets-input">Markets</Typography>
            <FormControl classes={{ root: classes.selectForm }} variant="outlined">
              <Select
                labelId="market-select-label"
                id="market-select"
                value={this.state.market}
                onChange={this.updateMarket}
              >
                {markets.map(market => {
                  return (
                    <MenuItem key={market.label} value={market.value}>
                      {market.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
          <div className={classes.selectContainer}>
            <Typography id="ad-length-input">Ad Length</Typography>
            <FormControl classes={{ root: classes.selectForm }} variant="outlined">
              <Select
                labelId="ad-length-select-label"
                id="ad-length-select"
                value={this.state.adLength}
                onChange={this.updateAdLength}
              >
                {adLengths.map(adLength => {
                  return (
                    <MenuItem key={adLength.label} value={adLength.value}>
                      {adLength.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>

          <div className={classes.drag}>
            <DropzoneArea
              onChange={files => console.log(files)}
              filesLimit={1}
              maxFileSize={100000000}
              acceptedFiles={['video/mp4']}
            />
          </div>

          <div className={classes.confirm}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={this.state.confirmed} onChange={this.toggleConfirm} name="confirm" />}
                label="Please confirm you've selected the right video and would like to proceed with the upload"
              />
            </FormGroup>
          </div>

          <div className={classes.upload}>
            <Button variant="contained" color="secondary" disabled={!this.state.confirmed} onClick={this.submit}>
              Upload
            </Button>
          </div>

          <div className={classes.dialogContainer}>
            <Dialog dialog={this.state.dialogOpen} onClose={this.closeDialog} open={this.state.dialogOpen}>
              <DialogContent>
                <Typography variant="body2">Your survey has been successfully submitted!</Typography>
                <Typography variant="body2">You will receive an email when results are available.</Typography>
                <Typography variant="body2">Please click OK to return to the main dashboard.</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeDialog}>Ok</Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
      </AsyncWrapper>
    )
  }
}

export default withRouter(Styled(Survey))
