import setBuilder from '../../../../../Template/config/components/selection/SetBuilder'
import setBuilderToolbar from './setBuilderToolbar'


export default  {
    ...setBuilder,
    maxAdSetSize: 800,
    hasNormsTab: false,
    includeSampleSize: false,
    setBuilderToolbar
}