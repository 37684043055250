import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import TypeIcon from './Icon'

const Styled = withStyles(theme => theme.components.generic.AdType(theme))

export default Styled(props => {
  const { adType, classes } = props

  return (
    <Grid container justify="flex-start" alignItems="center" spacing={2}>
      <Grid item>
        <TypeIcon type={adType.field} size="small"/>
      </Grid>
      <Grid item>
        <Typography noWrap align="left" variant="body2" classes={{body2: classes.mainLabel}}>
          {adType.display}
        </Typography>
      </Grid>
    </Grid>
  )
})
