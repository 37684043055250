import {
  DASHBOARD_SELECT_ADS_TAB,
  DASHBOARD_INIT,
  DASHBOARD_CLR_CONFIG
} from '../../../new_actions'

const initialState = { 
  tab: 'ad',
  index: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_INIT:
      return { ...initialState }

    case DASHBOARD_SELECT_ADS_TAB: {
      const { tab, index } = action.data
      return {tab, index}
    }

    case DASHBOARD_CLR_CONFIG:
      return { ...initialState }
    default:
      return state
  }
}
