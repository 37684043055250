import Excel from 'exceljs/dist/exceljs'
import formatDate from './formatDate'

import {
  tStatIndex,
  tStatNonIndex
} from './statTest'

const baseSampleSizeMetricMap = groupName => groupName === 'nissan' ? 102 : 106
const minSampleSize = 30
const avgSampleSize = 50
const normalSampleSize = 75

const colors = {
  lightGrey: 'eeeeee',
  grey: 'aaaaaa',
  sigHigh: {
    bgColor: 'aed581',
    color: '000000'
  },
  sigLow: {
    bgColor: 'ffb74d',
    color: '000000'
  },
  h0: '3f537a',
  t1: '3f537a',
}

const bolds = {
  h0: true
}

const lengthFactor = 0.5

const getStatTest = (metric, norm, format, stats) => {
  const stat = format === 'index' || format === 'fixed0' ? tStatIndex(metric, norm) : tStatNonIndex(metric, norm)
  if (stat === 0) return null
  return stat > 0 ? colors.sigHigh : colors.sigLow
}

const getMetricsLabels = _metricsLabels => {
  return _metricsLabels.map(metricLabel => {
    const { format, label, row_type, metric } = metricLabel
    const numFmt = format === 'fixed0' ? '0' : format === '%' ? '0%' : '0'
    const metricLabelLength = typeof metricLabel.label === 'string' ? metricLabel.label.length : 0
    return {
      header: label,
      key: metric,
      width: Math.max(Math.min(20, Math.ceil(metricLabelLength * lengthFactor)), 10),
      style: {
        numFmt
      },
      format,
      row_type
    }
  })
}

const columnWidths = {
  'month_code': 15,
  'subsegment': 15
}

const createColumns = (slicerList, metricsLabels, bulkFilters) => {
  return {
    metadata: [
      { header: 'Ad Title', key: 'title', width: 30 },
      { header: 'Ad Code', key: 'ad_code', width: 16 },
      { header: 'Brand', key: 'brand', width: 12 },
      ...slicerList.map((slice, index) => {
        return {
          header: bulkFilters[index].label,
          key: slice.field,
          width: columnWidths[slice.field] || Math.max(Math.min(20, Math.ceil(slice.field.length * lengthFactor)), 10)
        }
      }),
      { header: 'SOV/Prop', key: 'sov', width: 12 },
      { header: 'Model', key: 'brand_model_event', width: 18 },
      { header: 'Duration', key: 'length', width: 12 },
      { header: 'Base Size', key: 'n', width: 12 },
      { header: 'LTD Spend ($000s)', key: 'spend_ltd', width: 12, style: { numFmt: '"$"#,##' } },
      { header: 'First Air/Print date', key: 'first_air_date', width: 15 }
    ],
    metricsData: getMetricsLabels(metricsLabels)
  }
}


const labelSlice = (slice, bulkFilters) => {
  return slice.reduce((obj, col, index) => {
    if (col.key === '***') obj[col.slice] = 'NA'
    else {
      const item = bulkFilters[index].data.find(v => v.value === col.key)
      obj[col.slice] = (item && item.label) || 'INVALID'
    }
    return obj
  }, {})
}


const processNormGroup = ({ norm, metrics, slice }, { adsMap, bulkFilters, slicerList, metricsLabels, columns }, config, worksheet, groupName) => {

  if (!metrics) return
  const baseSampleSizeMetric = baseSampleSizeMetricMap(groupName)
  const normMap = norm.reduce((obj, item) => { obj[item.metric] = item; return obj }, {})
  const normData = columns.metricsData.reduce((obj, column) => {
    const { key } = column
    if (key < 0) return obj
    if (typeof normMap[key] === 'undefined') return obj
    obj[key] = column.format === 'index' ? 100 : normMap[key].avg
    return obj
  }, {})

  // Process Norm row
  const rowValues = {
    ...normData,
    title: 'Norm',
    ...labelSlice(slice, bulkFilters),
  }
  const row = worksheet.addRow(rowValues)
  row.font = {
    bold: true
  }
  row.eachCell({ includeEmpty: true }, cell => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: `${colors.lightGrey}`
      }
    }
  })
  row.commit()

  // Process ads
  Object.keys(metrics).forEach(ad_code => {
    const metricsMap = metrics[ad_code].reduce((obj, item) => {
      obj[item.metric] = item;
      return obj
    }, {})
    const metricsData = columns.metricsData.reduce((obj, column) => {
      const { key } = column
      if (key < 0) return obj
      if (!metricsMap[key] || !normMap[key]) return obj
      const value = column.format === 'index' ? metricsMap[key].avg / normMap[key].avg * 100 : metricsMap[key].avg
      const color = metricsMap[key].n < avgSampleSize ? 'ff0000' : metricsMap[key].n < normalSampleSize ? '0000ff' : null
      const statColor = getStatTest(metricsMap[key], normMap[key], column.format) || {}
      obj[key] = {
        metric: key,
        value: metricsMap[key].n < minSampleSize ? null : value,
        fgColor: metricsMap[key].n < minSampleSize ? null : statColor.bgColor,
        color: metricsMap[key].n < minSampleSize ? null : color || statColor.color,
        n: metricsMap[key].n
      }
      return obj
    }, {})
    const n = (metricsData[baseSampleSizeMetric] && metricsData[baseSampleSizeMetric].n) || 0
    const monthSlice = slice.find(item => item.slice === 'month_code')
    const month_code = +monthSlice.key
    const spendLtdString = adsMap[ad_code][month_code] && typeof (adsMap[ad_code][month_code].spend_ltd) === 'string' && adsMap[ad_code][month_code].spend_ltd.replace(/[$,]/g, '')// find spend_ltd from adsMap[ad_code].months
    const spend_ltd = spendLtdString ? +spendLtdString : ''
    //const first_air_date = adsMap[ad_code][month_code] && adsMap[ad_code][month_code].first_air_date ? (new Date(adsMap[ad_code][month_code].first_air_date)).toLocaleDateString('en-US') : ''
    const first_air_date = formatDate(adsMap[ad_code][month_code].first_air_date, config.dateFormat)
    const {
      title,
      brand,
      sov,
      brand_model_event,
      length,
    } = adsMap[ad_code][month_code]
    const rowValues = {
      title,
      brand,
      sov,
      brand_model_event,
      length,

      ad_code,
      first_air_date,
      n,
      spend_ltd,
      ...labelSlice(slice, bulkFilters),
      ...Object.keys(metricsData).reduce((obj, metric) => { obj[metric] = metricsData[metric].value; return obj }, {})
    }
    const row = worksheet.addRow(rowValues)
    columns.metricsData.forEach((column, index) => {
      const cell = row.getCell(columns.metadata.length + index + 1)
      const cellData = metricsData[column.key]
      if (!cellData) return
      const { color, fgColor } = cellData
      if (color)
        cell.font = {
          color: {
            argb: color
          }
        }
      if (fgColor)
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: `${fgColor}`
          }
        }
    })

    row.commit()
  })

  // Blank row between norm groups
  // worksheet.addRow().commit()
}

const generateNormGroup = (normSource, metricsSource, slicerList, cb) => {

  const f = (normSource, metricsSource, index, array, cb) => {
    Object.keys(normSource)
      .sort((a, b) => a > b ? -1 : 1)
      .forEach(key => {
        if (index === slicerList.length - 1) {
          cb({ norm: normSource[key], metrics: metricsSource[key], slice: [...array, { slice: slicerList[index].field, key }] })
        }
        else {
          f(normSource[key], metricsSource[key], index + 1, [...array, { slice: slicerList[index].field, key }], cb)
        }
      })
  }
  f(normSource, metricsSource, 0, [], cb)
}

export default ({
  adsMap,
  displayFiltersSlice,
  metrics,
  metricsLabels,
  norms,
  slicerList,
  slicerMap,
  staticFilter,
  bulkFilters,
  groupName,
  stats,
  config
}) => {

  colors.sigHigh = {
    bgColor: stats.above.bgColor.replace('#',''),
    color: stats.above.color.replace('#','')
  }
  colors.sigLow = {
    bgColor: stats.below.bgColor.replace('#',''),
    color: stats.below.color.replace('#','')
  }

  // Create Workbook
  const workbook = new Excel.Workbook()


  // Need to explicitly pick ad types!
  const metricsLabelSliceField = groupName === 'ford-ap' ? 'region' : 'ad_type'
  const metricsLabelSliceTypes = slicerMap[metricsLabelSliceField].length > 0 ? slicerMap[metricsLabelSliceField] : bulkFilters.find(filter => filter.field === metricsLabelSliceField).data.map(item => item.value)

  // Map each ad type to its own sheet
  metricsLabelSliceTypes.forEach((metricsLabelSliceType, index) => {
    const fieldLabel = bulkFilters.find(bulkFilter => bulkFilter.field === metricsLabelSliceField).data.find(item => item.value === metricsLabelSliceType).label
    const _metricsLabels = (metricsLabels[metricsLabelSliceType] || metricsLabels['***']).sort((a, b) => a.sort_order > b.sort_order ? 1 : -1)
    const worksheet = workbook.addWorksheet(`${fieldLabel}`)

    // Freeze panes
    worksheet.views = [
      {
        activeCell: 'Z1000',
        state: 'frozen',
        xSplit: 2,
        ySplit: 1,
        showGridLines: false,
      },
    ]
    // Create sheet columns
    const columns = createColumns(slicerList, _metricsLabels, bulkFilters)
    worksheet.columns = [...columns.metadata, ...columns.metricsData]

    // Style top title row
    worksheet.getRow(1).eachCell((cell, index) => {

      cell.font = {
        italic: true
      }
      cell.alignment = {
        wrapText: true
      }
      if (index <= columns.metadata.length) return
      const { row_type } = _metricsLabels[index - columns.metadata.length - 1]
      cell.font.color = {
        argb: colors[row_type],
      }
      cell.font.bold = bolds[row_type]
    })

    // Iterate over norms
    if (groupName === 'ford-ap') {
      if (norms['tv'] && metrics['tv'])
        generateNormGroup(norms['tv'], metrics['tv'], slicerList, normGroup => processNormGroup(normGroup, { adsMap, bulkFilters, slicerList, metricsLabels: _metricsLabels, columns }, config, worksheet, groupName))
    }
    else {
      if (norms[metricsLabelSliceType] && metrics[metricsLabelSliceType])
        generateNormGroup(norms[metricsLabelSliceType], metrics[metricsLabelSliceType], slicerList, normGroup => processNormGroup(normGroup, { adsMap, bulkFilters, slicerList, metricsLabels: _metricsLabels, columns }, config, worksheet, groupName))
    }

  })

  return workbook

}
