export default {
  '***': {
    '***': {
      extraFilter: {
        type: 'metadataFilter',
        ad_type: ['***'],
        disabledState: 'disabled', // 'hidden'
        data: {
          field: 'length',
          title: 'Ad Length',
          options: [
            {
              label: '15',
              value: 15,
              selected: true
            },
            {
              label: '30',
              value: 30,
              selected: true
            },
            {
              label: '60',
              value: 60,
              selected: true
            },
            {
              label: 'Other',
              value: 'other',
              selected: true
            }
          ]
        }
      }
    }
  }
}