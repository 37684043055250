import {
  DASHBOARD_REQUEST_METRICS,
  DASHBOARD_REQUEST_UPDATE_METRICS,
  DASHBOARD_RECEIVE_METRICS,
  DASHBOARD_FAIL_METRICS,
  DASHBOARD_REQUEST_NORM,
  DASHBOARD_REQUEST_UPDATE_NORM,
  DASHBOARD_RECEIVE_NORM,
  DASHBOARD_FAIL_NORM,
  DASHBOARD_INIT,
  DASHBOARD_SELECT_PRESET,
  DASHBOARD_CREATE_PRESET,
  DASHBOARD_TOGGLE_EXCLUDE_NON_NORM
} from '../../new_actions'

const initialState = {
  config: {},
  calcFlags: {},
  metrics: {
    uuid: null,
    ready: false,
    data: [],
    error: null
  },
  norm: {
    uuid: null,
    ready: false,
    data: [],
    error: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_INIT: {
      const { calcFlags } = action.data.config.components.scorecard
       
      return {
        ...initialState,
        config: calcFlags,
        calcFlags
      }
    }

    case DASHBOARD_REQUEST_METRICS:
    case DASHBOARD_REQUEST_UPDATE_METRICS:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          ready: false,
          error: false,
          uuid: null
        }
      }

    case DASHBOARD_RECEIVE_METRICS:
      const { metrics } = action.data
      return {
        ...state,
        metrics: {
          ...state.metrics,
          ...metrics,
          ready: true,
          error: null
        }
      }

    case DASHBOARD_FAIL_METRICS: {
      return {
        ...state,
        metrics: {
          ...state.metrics,
          ready: false,
          uuid: null,
          error: action.data
        }
      }
    }

    case DASHBOARD_REQUEST_NORM:
    case DASHBOARD_REQUEST_UPDATE_NORM:
      return {
        ...state,
        norm: {
          ...state.metrics,
          ready: false,
          error: null,
          uuid: null
        }
      }

    case DASHBOARD_RECEIVE_NORM:
      const { norm } = action.data
      return {
        ...state,
        norm: {
          ...state.norm,
          ...norm,
          ready: true,
          error: null
        }
      }

    case DASHBOARD_FAIL_NORM: {
      return {
        ...state,
        error: action.data,
        norm: {
          ...state.norm,
          ready: false,
          uuid: null,
          error: action.data
        }
      }
    }

    case DASHBOARD_SELECT_PRESET:
      if (action.data.type === 'ad') return state
      return {
        ...state,
        calcFlags: {
          ...state.calcFlags,
          norm: {
            ...state.calcFlags.norm,
            ...state.config.norm
          }
        }
      }

    case DASHBOARD_CREATE_PRESET:
      if (action.data.type === 'ad') return state
      return {
        ...state,
        calcFlags: {
          ...state.calcFlags,
          norm: {
            ...state.calcFlags.norm,
            // excludeNonNorm: false
          }
        }
      }

    case DASHBOARD_TOGGLE_EXCLUDE_NON_NORM:
      return {
        ...state,
        calcFlags: {
          ...state.calcFlags,
          [action.data.type]: {
            ...state.calcFlags[action.data.type],
            excludeNonNorm: !state.calcFlags[action.data.type].excludeNonNorm
          }
        }
      }

    default: {
      return { ...state }
    }
  }
}
