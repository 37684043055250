import QuickviewTemplate from '../../../../../../Template/theme/components/quickview/Quickview'
import MetricTable from './MetricTable'
import QuickviewCanvasContainer from './QuickviewCanvasContainer'

export default Object.assign(
  theme => QuickviewTemplate(theme),
  {
    ...QuickviewTemplate,
    QuickviewCanvasContainer,
    MetricTable,
    Name: 'ComcastV2 Quickview'
  }
)
