import { dashboardFetch } from '../fetch'

const DASHBOARD_VIEWER_CLOSE = 'DASHBOARD_VIEWER_CLOSE'

const viewerAvailable = state => {
  return state.dashboard.viewer.ready
}
const dashboardViewerOpen = (media, title, ad_code) => {
  return (dispatch, getState) => {
    return viewerAvailable(getState())
      ? dispatch( dashboardFetch('media/link', { media, title, ad_code }) )
      : null
  }
}

const dashboardViewerClose = () => {
  return {
    type: DASHBOARD_VIEWER_CLOSE
  }
}

export { DASHBOARD_VIEWER_CLOSE, dashboardViewerOpen, dashboardViewerClose }
