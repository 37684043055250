import {
  DASHBOARD_CLR_CONFIG,
  DASHBOARD_INIT,
  DASHBOARD_SELECT_PRESET,
  DASHBOARD_CREATE_PRESET,
  DASHBOARD_TOGGLE_EXCLUDE_NON_NORM
} from '../../../new_actions'

import { generateUUID } from '../../../new_utils'
const initialState = {
  defaultRange: {
    start_month: null,
    end_month: null
  },
  ad: {
    uuid: null,
    ad_codes: [],
    start_month: 9999,
    end_month: 0,
    calcFlags: {}
  },
  norm: {
    uuid: null,
    ad_codes: [],
    start_month: 9999,
    end_month: 0,
    calcFlags: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_INIT:
      return {
        ...initialState,
        defaultRange: {
          start_month: action.data.metadata.end_month - 1,
          end_month: action.data.metadata.end_month
        },
        ad: {
          ...initialState.ad,
          start_month: action.data.metadata.end_month - 1,
          end_month: action.data.metadata.end_month
        },
        norm: {
          ...initialState.norm,
          start_month: action.data.metadata.end_month - 1,
          end_month: action.data.metadata.end_month
        }
      }

    case DASHBOARD_SELECT_PRESET: {
      const { type, preset } = action.data
      return {
        ...state,
        [type]: { ...preset }
      }
    }

    case DASHBOARD_CREATE_PRESET: {
      const { type, preset } = action.data

      const { start_month, end_month } = preset.ad_codes.length
        ? { start_month: preset.start_month, end_month: preset.end_month }
        : state.defaultRange

      return {
        ...state,
        [type]: { ...preset, start_month, end_month, type }
      }
    }

    case DASHBOARD_TOGGLE_EXCLUDE_NON_NORM: {
      return {
        ...state,
        [action.data.type]: {
          ...state[action.data.type],
          uuid: generateUUID()
        }
      }
    }

    case DASHBOARD_CLR_CONFIG:
      return { ...initialState }

    default:
      return state
  }
}
