import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { Grid, AppBar, Toolbar, Typography } from '@material-ui/core'
import { AdType } from '../../../'
const Styled = withStyles(theme => theme.components.selection.Search(theme))

export default Styled(props => {
  const { classes, selections, genericConfig } = props
  return (
    <AppBar
      position="static"
      classes={{
        colorPrimary: classes.colorPrimary
      }}
    >
      <Toolbar>
        <Grid container justify="flex-start" spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="h6" align="left" color="textPrimary">
              {`Search ${genericConfig.baseText}`}
            </Typography>
          </Grid>
          {Object.keys(selections).map(key => (
            <Grid key={key} item>
              <AdType adType={selections[key]} />
            </Grid>
          ))}
        </Grid>
      </Toolbar>
    </AppBar>
  )
})
