import template from '../../../../../../Template/config/components/scorecard/ScorecardView/ScorecardStatTesting'
export default {
  ...template,
  statTests: {
    '%': 'wRatio',
  },
  useDeNormalized: true,
  thresholds: [0, 0.1, 0.15, 0.2],
  labels: [
    {
      high: {
        color: '#dce775',
        textColor: '#444444',
        text: 'Significantly above the norm at 90% but less than 10% pts'
      },
      low: {
        color: '#ffe082',
        textColor: '#444444',
        text: 'Significantly below the norm at 90% but less than 10% pts'
      }
    },
    {
      high: {
        color: '#aed581',
        textColor: '#444444',
        text: 'Significantly above the norm at 90% +10-14% pts above norm'
      },
      low: {
        color: '#ffb74d',
        textColor: '#444444',
        text: 'Significantly below the norm at 90% +10-14% pts below norm'
      }
    },
    {
      high: {
        color: '#4caf50',
        text: 'Significantly above the norm at 90% +15-19% pts above norm'
      },
      low: {
        color: '#ff7043',
        text: 'Significantly below the norm at 90% +15-19% pts below norm'
      }
    },
    {
      high: {
        color: '#1b5e20',
        textColor: '#ffffff',
        text: 'Significantly above the norm at 90% +20% pts above norm'
      },
      low: {
        color: '#e53935',
        text: 'Significantly below the norm at 90% +20% pts below norm'
      }
    }
  ]
}
