import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingBottom: 50,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '60px',
  },
  header: {
    textAlign: 'center',
    marginBottom: '10px',
  },
})

class AsyncWrapper extends Component {
  render() {
    const { size, thickness, item, children, classes } = this.props

    let hasErrored = item.hasErrored
    let isFetching = item.isFetching

    if (Array.isArray(item)) {
      hasErrored = item.some(d => d.hasErrored === true)
      isFetching = item.some(d => d.isFetching === true)
    }

    if (hasErrored) {
      return (
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <div className={classes.container}>
                  <div className={classes.header}>
                    <Typography variant="h6" color="primary" noWrap>
                      {`Whoops! An error has occured.`}
                    </Typography>
                    <Typography variant="body2" color="error">
                      {item.errMessage || ''}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    if (isFetching) {
      return (
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <div className={classes.container}>
                  <div className={classes.header}>
                    <CircularProgress
                      thickness={thickness}
                      className={classes.progress}
                      size={size}
                      color="inherit"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    return children
  }
}

AsyncWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
  thickness: PropTypes.number.isRequired,
  item: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        isFetching: PropTypes.bool.isRequired,
        hasErrored: PropTypes.bool.isRequired,
        errMessage: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      hasErrored: PropTypes.bool.isRequired,
      errMessage: PropTypes.string.isRequired,
    }),
  ]).isRequired,
}

AsyncWrapper.defaultProps = {
  size: 50,
  thickness: 3.6,
}

export default withStyles(styles)(AsyncWrapper)
