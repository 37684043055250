import React, { Fragment } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import SetBuilderToolbar from './SetBuilderToolbar'
import SetBuilderGridView from './SetBuilderGridView'
import SetBuilderListView from './SetBuilderListView'
import SetBuilderFooter from './SetBuilderFooter'

import { MainHeader, SaveAdSetDialog } from '../../../'

const Styled = withStyles(theme => {
  console.log( theme )
  return theme.components.selection.SetBuilder(theme)
})

class SetBuilder extends React.PureComponent {
  state = {
    tableHeight: 0,
    showGridView: false,
    searchFilter: '',
    sortMetric: null,
    checkedAds: {
      ad: [],
      norm: []
    }
  }

  updateHeight = () => {
    this.setState({
      tableHeight: window.innerHeight - 375
    })
  }

  componentDidMount() {
    this.updateHeight()
    window.addEventListener('resize', this.updateHeight)
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectSortMetric } = this.props
    const { sortMetric } = this.state
    if (prevState.sortMetric !== sortMetric) {
      selectSortMetric(sortMetric)
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight)
  }

  updateSearchFilter = e => {
    this.setState({
      searchFilter: e.target.value
    })
  }

  selectSortMetric = sortMetric => {
    this.setState({
      sortMetric
    })
  }

  toPresets = () => {
    const { toPresets } = this.props
    toPresets()
  }

  openScorecard = () => {
    const { openScorecard } = this.props
    openScorecard()
  }

  exportData = () => {}

  checkAd = ad_code => {
    const { selectedTab } = this.props
    const { checkedAds } = this.state

    let _checkedAds
    if (checkedAds[selectedTab.tab].includes(ad_code))
      _checkedAds = checkedAds[selectedTab.tab].filter(
        _ad_code => _ad_code !== ad_code
      )
    else {
      _checkedAds = [...this.state.checkedAds[selectedTab.tab]]
      _checkedAds.push(ad_code)
    }
    this.setState({
      checkedAds: {
        ...this.state.checkedAds,
        [selectedTab.tab]: _checkedAds
      }
    })
  }

  checkAllAds = () => {
    const { selectedTab } = this.props
    const { checkedAds } = this.state

    const viewedAds = this.getViewedList()
    const _checkedAds =
      checkedAds[selectedTab.tab].length === viewedAds.length
        ? []
        : viewedAds.map(ad => ad.ad_code)
    this.setState({
      checkedAds: {
        ...this.state.checkedAds,
        [selectedTab.tab]: _checkedAds
      }
    })
  }

  getViewedList = () => {
    const { adsMap, selectedTab } = this.props
    const { searchFilter } = this.state

    return adsMap[selectedTab.tab].list.filter(ad => ad.sample_size > 0).filter(ad => {
      if (searchFilter.length === 0) return true
      const searchText = searchFilter.toLowerCase()
      const { ad_code, title } = ad
      return (
        ad_code.toLowerCase().includes(searchText) ||
        title.toLowerCase().includes(searchText)
      )
    })
  }

  remove = () => {
    const { remove, selectedTab } = this.props
    const { checkedAds } = this.state
    remove(selectedTab.tab, checkedAds[selectedTab.tab])
    this.setState({
      checkedAds: {
        ...this.state.checkedAds,
        [selectedTab.tab]: []
      }
    })
  }

  updateGridView = showGridView => {
    this.setState({
      showGridView
    })
  }

  savePreset = label => {
    const {
      selectedPresets,
      selectedTab: { tab },
      savePreset
    } = this.props

    const { is_perishable, calcFlags, ...preset } = selectedPresets[tab]

    savePreset({
      ...preset,
      label
    })
  }

  render() {
    const {
      adType,
      selections,
      adsMap,
      openMedia,
      selectedPresets,
      excludeNonNormFilter,
      enableExcludeNonNormFilter,
      toggleExcludeNonNormFilter,
      config: {
        maxAdSetSize,
        hasPresetButton,
        hasNormsTab,
        hasAdStorage,
        includeSampleSize,
        setBuilderToolbar: { exportButtons },
        setBuilderExport: { columnFormat },
      },
      genericConfig,
      sortOptions,
      selectedTab,
      selectTab,
      openSearch,
      baseEndpoint,
      groupName,
      calcFlags,
      generateError
    } = this.props

    const {
      tableHeight,
      searchFilter,
      showGridView,
      checkedAds
    } = this.state

    const setBuilderListViewConfig = this.props.config.setBuilderListView
    const SetBuilderView = showGridView
      ? SetBuilderGridView
      : SetBuilderListView

    return (
      <Fragment>
        <MainHeader
          title="Builder"
          selections={selections}
          updateTab={selectTab}
          selectedTab={selectedTab}
          adType={adType}
          ads={adsMap}
          maxAdSetSize={maxAdSetSize}
          tabs={hasNormsTab}
          openScorecard={this.openScorecard}
          presetsAction={hasPresetButton && this.toPresets}
          calcFlags={calcFlags}
          config={genericConfig}
        />

        <Grid container justify="center">
          <Grid item xs={11}>
            <SetBuilderToolbar
              searchFilter={searchFilter}
              updateSearchFilter={this.updateSearchFilter}
              sortOptions={sortOptions}
              selectSortMetric={this.selectSortMetric}
              excludeNonNormFilter={excludeNonNormFilter}
              toggleExcludeNonNormFilter={toggleExcludeNonNormFilter}
              enableExcludeNonNormFilter={enableExcludeNonNormFilter}
              exportButtons={exportButtons[selectedTab.tab]}
              columnFormat={columnFormat}
              exportData={this.exportData}
              showGridView={showGridView}
              updateGridView={this.updateGridView}
              adList={this.getViewedList()}
              adType={adType}
              groupName={groupName}
              baseEndpoint={baseEndpoint}
              generateError={generateError}
              genericConfig={genericConfig}
            />
            {hasAdStorage ? (
              <Grid container justify="center" alignContent="center">
                <Typography variant="subtitle1" color="inherit">
                  {selectedPresets[selectedTab.tab] &&
                    selectedPresets[selectedTab.tab].label}
                  {selectedPresets[selectedTab.tab].is_perishable ? (
                    <SaveAdSetDialog
                      title={selectedTab.tab==='ad' ? `${genericConfig.MainHeader.baseText} Set` : 'Norm Set'}
                      savePreset={this.savePreset}
                    />
                  ) : null}
                </Typography>
              </Grid>
            ) : null}
            <SetBuilderView
              adList={this.getViewedList()}
              tableHeight={tableHeight}
              checkAd={this.checkAd}
              checkAllAds={this.checkAllAds}
              checkedAds={this.state.checkedAds[selectedTab.tab]}
              openMedia={openMedia}
              includeSampleSize={includeSampleSize}
              config={setBuilderListViewConfig}
              genericConfig={genericConfig}
            />
            <SetBuilderFooter
              openSearch={openSearch}
              remove={this.remove}
              adList={this.getViewedList().map(ad => ad.ad_code)}
              valid={adsMap[selectedTab.tab].valid}
              checkedAds={checkedAds[selectedTab.tab]}
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default Styled(SetBuilder)
