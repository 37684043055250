import templateConfig from '../../../Template/config'
import main from './main'
import metadata from './metadata'
import components from './components'

const config = {
  ...templateConfig,
  main,
  components,
  metadata
}

export {config}
export default config
