export default theme => {
  return {
    container: {
      height: '100%'
    },
    h1: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.primary.dark,
      paddingTop: 4,
      paddingLeft: 10,
      marginBottom: 5
    },
    d1: {
      paddingTop: 5,
      paddingBottom: 5
    },
    metricsValuesText: {
      color: theme.palette.text.primary
    },
    metricsValueInverseText: {
      color: '#ffffff'
    },
    stat: {
      marginRight: 5,
      marginLeft: 5,
      borderRadius: 8
    }
  }
}
