import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import cn from 'classnames'

import { withStyles } from '@material-ui/core/styles'
const styles = theme => {
  return {
    root: {
      // width: 700,
      // height: 500,
      overflow: 'hidden',
    },
    title: {
      border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
      boxShadow: `0 1px 1px rgba(0,0,0,0.14)`
    },
    paper: {
      padding: theme.spacing( 2 ),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    list: {
      marginTop: 0,
      paddingTop: 0,
      paddingBottom: 0,
      width: '100%',
      height: 325,
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    selected: {
      backgroundColor: theme.palette.listSelectBackground.default,
      // color: theme.palette.primary.contrastText,
    },
  }
}
export default withStyles(styles)(props => {
  const {
    classes,
    updateSortOrder,
    updateGroupLeft,
    updateSortMetric,
    sortParams: { list, sortOrder, sortMetric, groupLeft },
    config: { clientName, styles },
  } = props

  return (
    <Dialog open={props.open} onClose={props.closeDialog} maxWidth="md" >
      <div className={classes.root}>
        <DialogTitle className={classes.title} disableTypography>
          <Typography align="center" color="textPrimary" variant="h6">
            Sort Scorecard by Metric
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: 25,
                      marginLeft: 25,
                      paddingTop: 0,
                      paddingBottom: 10,
                    }}
                  >
                    Metric
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={classes.list}>
                    <List component="nav" dense style={{width: '100%'}}>
                      {list.map((item, index) => {
                        const paddingLeft = isNaN( styles[item.row_type].offset ) ? 5 : styles[item.row_type].offset * 20 + 5
                        return <ListItem
                          button
                          key={item.label}
                          style={{width: '100%', height: 25, paddingLeft}}
                          className={cn(
                            item.metric === sortMetric
                              ? classes.selected
                              : null,
                          )}
                          onClick={() => updateSortMetric(item.metric)}
                        >
                          <ListItemText>{item.label}</ListItemText>
                        </ListItem>
                      })}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <Grid
                container
                justify="center"
                style={{ paddingLeft: 30, paddingTop: 100 }}
              >
                <Grid item xs={12}>
                  <Checkbox
                    value="groupLeft"
                    onChange={updateGroupLeft}
                    checked={groupLeft === 1}
                    color="secondary"
                  />
                  <div
                    style={{
                      display: 'inline',
                      // fontFamily: 'Arial',
                      fontSize: 14,
                    }}
                  >
                    Group {clientName} Ads to left
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    value="sortOrder"
                    onChange={updateSortOrder}
                    checked={sortOrder === 1}
                    color="secondary"
                  />
                  <div
                    style={{
                      display: 'inline',
                      // fontFamily: 'Arial',
                      fontSize: 14,
                    }}
                  >
                    Sort by ascending order (Default: descending)
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.closeDialog}
            // color="primary"
            style={{ marginTop: -40, marginLeft: -10 }}
          >
            Done
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
})
