export default theme => {
  return {
    container: {
      padding: 6,
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    menuText: {
        fontSize: 12,
        paddingTop: 5
    },
    inputText: {
      fontSize: 12,
      maxWidth: '100%'
    },
    menuItemRoot: {
      padding: 0,
    },
    label: {
      fontSize: 14
    },
    checkBox: {
        padding: 5
    }
  }
}
