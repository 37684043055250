import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core/'

import { monthCodeToText } from '../../new_utils'
const Styled = withStyles(theme =>
  theme.components.generic.PickDateButton(theme)
)

export default Styled(props => {
  const { classes, range, onClick } = props
  return (
    <Grid className={classes.container}>
      <Typography
        align="left"
        variant="subtitle1"
        color="inherit"
        noWrap
        className={classes.title}
      >
        Select Search Range
      </Typography>

      <Button
        className={classes.button}
        variant="contained"
        fullWidth
        onClick={onClick}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.item}>
            <Typography align="left" variant="body2"  style={{fontSize: 12, display: 'inline'}}>
              Start Month
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <Typography align="right" variant="body2" style={{fontSize: 12, display: 'inline'}}>
              End Month
            </Typography>
          </Grid>

          <Grid item xs={6} className={classes.item}>
            <Typography align="left" variant="caption" style={{fontSize: 12, display: 'inline'}}>
              {range && monthCodeToText(range.start_month)}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <Typography align="right" variant="caption" style={{fontSize: 12, display: 'inline'}}>
              {range && monthCodeToText(range.end_month)}
            </Typography>
          </Grid>
        </Grid>
      </Button>
    </Grid>
  )
})
