export default theme => {
  return {
    grid: {
      flexGrow: 1
    },
    cell: {
      width: 640,
      height: 360,
      marginTop: 20
    },
    download: {
      color: theme.palette.grey[600],
      position: 'absolute',
      right: 20,
      bottom: 10
    },
    title: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0
    },
    titleText: {
      color: theme.palette.text.primary
    },
    closeIcon: {
      color: theme.palette.text.primary,
      marginRight: 20,
      marginTop: -15
    },
    headerDialog: {
      width: 400
    },
    headerGrid: {
      padding: '10px 0px'
    },
    toolbarGrid: {
      padding: 0,
    }
  }
}
