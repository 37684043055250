import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import metadataFilter from './MetadataFilter'
import sampleSizeFilter from './SampleSizeFilter'


const Filter = {
  metadataFilter,
  sampleSizeFilter
}
const Styled = withStyles(theme =>
  theme.components.selection.Search.ExtraFilter(theme)
)

class ExtraFilter extends React.PureComponent {
  render() {
    const { extraFilter: { type } } = this.props
    const ExtraFilter = Filter[type]
    return <ExtraFilter {...this.props} />
  }
}

export default Styled(ExtraFilter)
