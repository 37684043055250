import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Tooltip } from '@material-ui/core'

import cn from 'classnames'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardFixedColumn.ScorecardMetricsLabels(
    theme
  )
)

export default Styled(props => {
  const {
    classes,
    rowIndex,
    gridData: { norms, metricsLabels, rowHeights }
  } = props
  const { rowType } = norms[rowIndex] || metricsLabels[norms[rowIndex].metric]

  return (
    <div className={classes.container}>
      <div
        className={cn(
          classes[rowType],
          rowIndex % 2 ? classes.odd : classes.even
        )}
      >
        <div className={classes.stat}>
          <Tooltip title={metricsLabels[rowIndex].label} placement="top-start">
            <Typography
              noWrap
              className={classes.metricsLabelText}
              variant={rowHeights[rowIndex].variant}
              color="inherit"
            >
              {`${metricsLabels[rowIndex].label}`}
            </Typography>
          </Tooltip>
        </div>
      </div>
    </div>
  )
})
