import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import cn from 'classnames'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardFixedColumn.ScorecardMetricsLabels(
    theme
  )
)

export default Styled(props => {
  const {
    classes,
    rowIndex,
    gridData,
    gridData: { norms, metricsLabels }
  } = props
  const {rowType} = norms[rowIndex] || metricsLabels[norms[rowIndex].metric]

  return (
    <div className={cn(classes.container, classes[rowType], rowIndex % 2? classes.odd : classes.even)}>
      <Typography
        noWrap
        variant={gridData.rowHeights[rowIndex].variant}
        color="inherit"
      >
        {`${metricsLabels[rowIndex].label}`}
      </Typography>
    </div>
  )
})
