import React from 'react'
import axios from 'axios'

import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'

import Excel from 'exceljs/dist/exceljs'
import resourceConfig from '../../../app/configuration/resourceConfig'
import { ExportButton } from '../../'

const dt =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet'

const formatDate = date => {
  const d = new Date(date)
  return d.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' })
}

const formatHistory = modifications => {
  return modifications.split('|')
  .filter(m => m.length > 0)
  .map(j => JSON.parse(j))
  .reduce( (string, modification) => {
    const { status, modified_by, modified_at} = modification
    if (status) {
      const action = status === 'active' ? 'Activated' : status === 'deactivated' ? 'De-activated' : status === 'deleted' ? 'Deleted' : ''
      const message = `User ${modified_by} ${action || status} account on ${formatDate(modified_at)}`
      return `${string}
      ${message}`
    } else {
      const { access: { type, resources } } = modification
      const group = Object.keys(resources)[0]
      const { subResources, role } = resources[group]
      const action = type === 'grant' ? 'Granted' : 'Revoked'
      if ( role ) {
        const message = `User ${modified_by} ${action || type} role "${role}" to "${group}" on ${formatDate(modified_at)}`
        return `${string}
        ${message}`
      } else {
        const subResource = Object.keys(subResources)[0]
        const { role } = subResources[subResource]
        const message = `User ${modified_by} ${action || type} role "${role}" to "${subResource}" on ${formatDate(modified_at)}`
        return `${string}
        ${message}`
      }
    }
  }, '')
}

class ScorecardExport extends React.PureComponent {

  state = {
    done: true,
    error: false
  }

  startExport = () => {
    this.fetchUsers()
      .then(this.exportFile)
      .catch(error => {
        this.setState({
          error,
          done: true
        })
      })
  }

  fetchUsers = () => {
    const { groupName } = this.props
    this.setState({
      done: false
    })
    return axios.get('/users/audit', {
      baseURL: `/api/admin/${groupName}`,
      headers: { 'Cache-Control': 'no-store' }
    })
      .then(({ data }) => {
        const { users } = data
        this.setState({
          done: true
        })
        return users.sort( (a,b) => a.email > b.email ? 1 : -1)
      })
  }

  createWorksheet = users => {

    const workbook = new Excel.Workbook()
    const worksheet = workbook.addWorksheet('Users')

    worksheet.views = [
      {
        activeCell: 'Z1000',
        state: 'frozen',
        xSplit: 1,
        ySplit: 1,
        showGridLines: true
      }
    ]

    let ResourceGroups = []

    resourceConfig.resources.filter(resource => resource.groupName !== 'ford-legacy').forEach(resource => {
      const hasEA = resource.subResources && resource.subResources.find(subResource => subResource.code.match('nonEarlyAccess'))
      const header = hasEA ? `${resource.config.config.main.appName} (EA)` : `${resource.config.config.main.appName}`

      ResourceGroups.push({
        header,
        key: resource.groupName,
        width: 20,
        alignment: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      })
      if (Array.isArray(resource.subResources))
        resource.subResources.forEach(subResource => {
          const header = subResource.code.match('nonEarlyAccess') ? `${resource.config.config.main.appName} (Non-EA)` : `${resource.config.config.main.appName} ${subResource.label}`
          ResourceGroups.push({
            header,
            key: `${resource.groupName}_${subResource.code}`,
            width: 20,
            alignment: {
              vertical: 'bottom',
              horizontal: 'center'
            }
          })
        })
    })

    const columns = [
      {
        header: 'Email',
        key: 'email',
        width: 30,
        alignment: {
          vertical: 'bottom',
          horizontal: 'left'
        }
      },
      {
        header: 'Inactive',
        key: 'inactive',
        width: 8,
        alignment: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      },
      ...ResourceGroups,
      {
        header: 'Registered on',
        key: 'created_at',
        width: 25,
        alignment: {
          vertical: 'bottom',
          horizontal: 'left'
        }
      },
      {
        header: 'Last Modified on',
        key: 'modified_at',
        width: 25,
        alignment: {
          vertical: 'bottom',
          horizontal: 'left'
        }
      },
      {
        header: 'Last Modified By',
        key: 'modified_by',
        width: 30,
        alignment: {
          vertical: 'bottom',
          horizontal: 'left'
        }
      },
      {
        header: 'History',
        key: 'modifications',
        width: 400,
        alignment: {
          vertical: 'bottom',
          horizontal: 'left'
        }
      },
    ]

    worksheet.columns = [...columns]
    users.forEach((user) => {
      const { email, status, modified_at, modified_by, modifications } = user
      const { resources } = JSON.parse(user.resources)
      const access = Object.keys(resources).reduce((obj, resource) => {
        const { subResources } = resources[resource]
        if (resources[resource].role) obj[resource] = resources[resource].role
        if (subResources) {
          Object.keys(subResources).forEach(subResource => {
            const key = `${resource}_${subResource}`
            obj[key] = subResources[subResource].role
          })
        }
        return obj
      }, {})

      worksheet.addRow({
        email,
        inactive: status === 'deactivated' ? 'x' : '',
        created_at: formatDate(modified_at),
        modified_at: formatDate(modified_at),
        modified_by,
        modifications: formatHistory(modifications),
        ...access
      })
    })

    worksheet.columns.forEach((column, index) => worksheet.getColumn(column.key).alignment = columns[index].alignment)

    return {
      workbook,
      worksheet
    }
  }


  saveWorkbook = workbook => {
    workbook.xlsx.writeBuffer({ base64: true }).then(xls64 => {
      const blb = new Blob([xls64], { type: dt })
      const url = URL.createObjectURL(blb)
      const tag = document.createElement('a')
      tag.href = url
      tag.download = 'AdPi_User_Audit.xlsx'
      document.body.appendChild(tag)
      tag.click()
      this.setState({
        done: true,
        error: false,
        imageBuffer: null
      })
      setTimeout(function () {
        document.body.removeChild(tag)
        window.URL.revokeObjectURL(url)
      }, 0)
    })
  }

  exportFile = users => {
    const { workbook } = this.createWorksheet(users)
    this.saveWorkbook(workbook)
  }

  render() {
    const { disabled } = this.props
    const { done } = this.state
    return (
      <Tooltip title="Export user account data to Excel" placement="top">
        <Grid item>
          <ExportButton
            type="Data"
            action={this.startExport}
            disabled={!done || disabled}
          />
        </Grid>
      </Tooltip>
    )
  }
}

export default ScorecardExport
