// TODO: This structure cannot be hardcoded here in the component hierarchy
// it needs to be injected via a prop into the WordCloudTab component via the
// parent component.  As it stands, the `Frequency Cutoff` values make the
// frequency filtering almost meaningless for Fidelity as the values are
// much too small.

const baseFilters = [
  {
    key: 'question',
    name: 'Question',
    tip:
      'Choose which open end (OE) survey question to explore in the word cloud',
    options: [
      { label: 'Main Message', value: 1, selected: false },
      { label: 'Likeability', value: 2, selected: true },
    ],
  },
  {
    key: 'frequency',
    name: 'Show Counts',
    tip: 'Show or hide the number of times that each term occurs.',
    options: [
      { label: 'On', value: 1, selected: true },
      { label: 'Off', value: 2, selected: false },
    ],
  },
  {
    key: 'cutoff',
    name: 'Count Minimum',
    tip:
      'The number of times that a term must occur in order for it to be included in the word cloud',
    options: [
      { label: '2', value: 2, selected: true },
      { label: '5', value: 5, selected: false },
      { label: '10', value: 10, selected: false },
      { label: '20', value: 20, selected: false },
    ],
  },
  {
    key: 'length',
    name: 'Minimum Verbatim Word Length',
    tip:
      'The minimum length of characters of a term that is required before it will be included in the word cloud',
    options: [
      { label: '2', value: 2, selected: false },
      { label: '3', value: 3, selected: true },
      { label: '4', value: 4, selected: false },
      { label: '5', value: 5, selected: false },
    ],
  },
  {
    key: 'displayed',
    name: 'Number of Themes Displayed',
    tip:
      'How many terms to include in the word cloud ranked by the number of occurrences',
    options: [
      { label: '10', value: 10, selected: false },
      { label: '50', value: 50, selected: false },
      { label: '100', value: 100, selected: true },
      { label: '200', value: 200, selected: false },
    ],
  },
  {
    key: 'scores',
    name: 'Likeability Scores',
    tip: 'Scores to include in items selected for display',
    multiple: true,
    options: [
      { label: '1', value: 1, selected: false },
      { label: '2', value: 2, selected: false },
      { label: '3', value: 3, selected: false },
      { label: '4', value: 4, selected: false },
      { label: '5', value: 5, selected: false },
      { label: '6', value: 6, selected: false },
      { label: '7', value: 7, selected: false },
    ],
  },
]

const languageFilter = [
  {
    key: 'language',
    name: 'Language',
    tip: 'Choose language to include in the display',
    multiple: true,
    options: [
      { label: 'English', value: 0, selected: false },
      { label: 'Spanish', value: 1, selected: false },
    ],
  },
]

export function getDefaultOptions(market) {
  return market === 'hispanic'
    ? [...baseFilters, ...languageFilter]
    : baseFilters
}

export function getOptions(options, values, multiple) {
  if (Array.isArray(values)) {
    return (!multiple && typeof(values[0]) === 'undefined') ? options : options.map(option => {
      return { ...option, selected: values.includes(option.value) }
    })
  } else {
    return options.map(option => {
      return { ...option, selected: values === option.value }
    })
  }
}

export function getSelectedOptions(controls) {
  const selected = {}

  controls.forEach(({ key, options }) => {
    const option = options
      .filter(option => option.selected)
      .map(option => option.value)
    selected[key] = option.length === 1 ? option[0] : option
  })

  return selected
}
