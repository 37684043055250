import React from 'react'
import { Auth, Logger } from 'aws-amplify'
import AuthPiece from './AuthPiece'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import StyledContainer from './StyledContainer'

const styles = theme => ({
  submitButton: {
    width: '100%',
    minWidth: 'unset',
    margin: '30px 0px',
  },
})

const logger = new Logger('VerifyContact')

class VerifyContact extends AuthPiece {
  constructor(props) {
    super(props)

    this._validAuthStates = ['verifyContact']
    this.verify = this.verify.bind(this)
    this.submit = this.submit.bind(this)

    this.state = { verifyAttr: null }
  }

  verify() {
    const { contact } = this.inputs
    if (!contact) {
      this.error('Neither Email nor Phone Number selected')
      return
    }

    Auth.verifyCurrentUserAttribute(contact)
      .then(data => {
        logger.debug(data)
        this.setState({ verifyAttr: contact })
      })
      .catch(err => this.error(err))
  }

  submit() {
    const attr = this.state.verifyAttr
    const { code } = this.inputs
    Auth.verifyCurrentUserAttributeSubmit(attr, code)
      .then(data => {
        logger.debug(data)
        this.changeState('signedIn', this.props.authData)
        this.setState({ verifyAttr: null })
      })
      .catch(err => this.error(err))
  }

  verifyView() {
    const user = this.props.authData

    if (!user) {
      logger.debug('no user for verify')
      return null
    }

    const { unverified } = user
    if (!unverified) {
      logger.debug('no unverified on user')
      return null
    }

    // const { email, phone_number } = unverified

    return (
      <Typography variant="h6" color="primary" noWrap>
        Verify Contact
      </Typography>
    )
  }

  submitView() {
    return (
      <Typography variant="h6" color="primary" noWrap>
        Verify Contact
      </Typography>
    )
  }

  showComponent(theme) {
    const { hide } = this.props

    if (hide && hide.includes(VerifyContact)) {
      return null
    }

    return (
      <StyledContainer message="Sign In">
        {this.state.verifyAttr ? this.submitView() : this.verifyView()}
        <Button
          color="primary"
          style={{ marginLeft: 80 }}
          onClick={() => this.changeState('signIn')}
        >
          Back to Sign In
        </Button>
      </StyledContainer>
    )
  }
}

export default withStyles(styles)(VerifyContact)
