import fordUS from './fordUS'
import fordUSTier2 from './fordUSTier2'
import fordLincoln from './fordLincoln'
import autoDemo from './autoDemo'
import fordCA from './fordCA'
import fordAP from './fordAP'
import fordEU from './fordEU'
import fordMX from './fordMX'
import nissan from './nissan'
import fordCALincoln from './fordCALincoln'
import fordUSGrowth from './fordUSGrowth'
import fordLincolnGrowth from './fordLincolnGrowth'

export default {
  'ford-us': fordUS,
  'ford-us-tier2': fordUSTier2,
  'ford-us-growth': fordUSGrowth,
  'ford-lincoln-growth': fordLincolnGrowth,
  'ford-lincoln': fordLincoln,
  'ford-ca': fordCA,
  'ford-ca-lincoln': fordCALincoln,
  'ford-ap': fordAP,
  'ford-eu': fordEU,
  'ford-mx': fordMX,
  'nissan': nissan,
  'auto-demo': autoDemo,
}
