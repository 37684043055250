import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const Styled = withStyles(
  theme => theme.components.selection.SetBuilder.SetBuilderFooter(theme)
)
export default Styled(props => {
  const { classes, remove, openSearch, adList, checkedAds } = props

  const num = checkedAds.length ? checkedAds.length === adList.length ? 'All' : checkedAds.length : ''
  return (
    <Grid container justify="space-between" className={classes.container}>
      <Grid item>
        <Tooltip title={`Remove ${num} ad${num>1 || num==='All'?'s':''} from current set`}>
        <div>
          <Button variant="contained" onClick={remove} disabled={!num}>Remove {num}</Button>
        </div>
        </Tooltip>
      </Grid>
      <Grid item>
        <Grid item>
          <Tooltip title="Search for ads based on time, brand, etc.">
            <Button variant="contained" color="secondary" onClick={openSearch}>
              Search
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  )
})
