import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { Grid, Dialog, Button } from '@material-ui/core'
import SearchFilter from './SearchFilter'

const Styled = withStyles(theme => theme.components.adminView.AdminMetadataManager.Search(theme))

class Search extends React.PureComponent {

  updateAdsList = () => {
    const { updateSelectedAds, close } = this.props
    updateSelectedAds()
    close()
  }

  render() {
    const {
      search,
      hasTags,
      classes,
      isOpen,
      maxRange,
      range,
      brandsMap,
      uuid,
      adsList,
      toggleAdTag,
      selectAllBrands,
      toggleBrand,
      toggleUntaggedOnly,
      updateRange,
      updateSelectedAds,
      close,
    } = this.props

    return (
      <Dialog
        maxWidth={false}
        open={isOpen}
        onClose={close}
        classes={{ paper: classes.paper }}
      >
        <SearchFilter
          hasTags={hasTags}
          maxRange={maxRange}
          range={range}
          uuid={uuid}
          brandsMap={brandsMap}
          adsList={adsList}
          updateAdsList={this.updateAdsList}
          toggleBrand={toggleBrand}
          setAllBrands={selectAllBrands}
          updateRange={updateRange}
          updateSelectedAds={updateSelectedAds}
          toggleAdTag={toggleAdTag}
          toggleUntaggedOnly={toggleUntaggedOnly}
          untaggedOnly={search.untaggedOnly}
        />
        <Grid container justify="center" style={{marginBottom: 20}} >
          <Grid item style={{marginRight: 10}}>
            <Button color="secondary" variant="contained" onClick={this.updateAdsList}>{`Select (${search.candidates.length})`}</Button>
          </Grid>
          <Grid item style={{marginLeft: 10}}>
            <Button color="secondary" variant="contained" onClick={close}>Cancel</Button>
          </Grid>
        </Grid>
      </Dialog>
    )
  }
}

export default Styled(Search)
