import {
  applyMiddleware,
  compose,
  createStore as createReduxStore
} from 'redux'
import thunk from 'redux-thunk'
import { combineReducers } from 'redux'

import ford from '../app/Auto/reducers'
import { app, user, dashboard, admin } from '../new_reducers'

const createStore = (initialState = {}) => {
  const middleware = [thunk]
  const enhancers = []
  let composeEnhancers = compose

  if (process.env.NODE_ENV === 'development') {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    }
  }

  const store = createReduxStore(
    combineReducers({
      app,
      user,
      dashboard,
      admin,
      ford
    }),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  )

  return store
}

export default createStore
