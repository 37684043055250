import {
  ADMIN_INIT,
  ADMIN_REQUEST_USERS,
  ADMIN_RECEIVE_USERS,
  ADMIN_REQUEST_UPDATE_USERS,
  ADMIN_FAIL_USERS,
  ADMIN_DELETE_USERS,
  ADMIN_RECEIVE_DELETE_USERS,
  ADMIN_UPDATE_USER_MATCH,
  DASHBOARD_ADMIN_INTERFACE_CLOSE,
  ADMIN_UPDATE_SELECTED_USER,
  ADMIN_TOGGLE_INCLUDE_APP_USERS,
  ADMIN_TOGGLE_INCLUDE_INACTIVE_USERS
} from '../../new_actions'

const initialState = {
  ready: false,
  pending: false,
  error: null,
  groupName: null,
  list: [],
  appUsers: {},
  selectedUser: {},
  otherUsers: {},
  includeAppUsers: false,
  includeInactiveUsers: false,
  match: '',
  matched: []
}

const getUsers = (list, groupName, match = '') => {

  return list.reduce((obj, user) => {
    const resource = user.resources[groupName]
    if (!resource) obj.otherUsers.push(user)
    else if (resource.role) obj.appUsers.push({ ...user, isAppUser: true })
    else if (Object.keys(resource.subResources || {}).length > 0) obj.appUsers.push({ ...user, isAppUser: true })
    else obj.otherUsers.push(user)
    return obj
  }, { appUsers: [], otherUsers: [] })
}

const getMatchUsers = state =>
  (state.includeAppUsers ? state.appUsers : [...state.appUsers, ...state.otherUsers])
    .filter(user => user.email.match(state.match.replace(/\./gi,"\\.")))
    .filter(user => state.includeInactiveUsers || user.status === 'active')
    .sort((a, b) => a.email > b.email ? 1 : -1)

export default function usersReducer(state = initialState, action) {
  switch (action.type) {

    case ADMIN_INIT: {
      const { groupName } = action.data
      return {
        ...state,
        groupName
      }
    }

    case ADMIN_REQUEST_USERS:
      return {
        ...state,
        ready: false,
        pending: true,
        error: null
      }

    case ADMIN_RECEIVE_DELETE_USERS:
    case ADMIN_RECEIVE_USERS: {
      const { users, uuid } = action.data
      const { appUsers, otherUsers } = getUsers(users, state.groupName)
      const matched = getMatchUsers({ ...state, appUsers, otherUsers })

      const selectedUser = matched[0] || {}
      return {
        ...state,
        ready: true,
        pending: false,
        error: null,
        list: users || [],
        matched,
        appUsers,
        otherUsers,
        selectedUser,
        uuid
      }
    }

    case ADMIN_UPDATE_USER_MATCH: {
      const { match } = action.data
      // const matched = (state.includeAppUsers ? [...state.appUsers, ...state.otherUsers] : state.otherUsers).filter(user => user.email.match(match))
      const matched = getMatchUsers({ ...state, match })
      const selectedUser = matched[0] || {}

      return {
        ...state,
        match,
        matched,
        selectedUser
      }
    }

    case ADMIN_UPDATE_SELECTED_USER: {
      const selectedUser = action.data.selectedUser || {}
      return {
        ...state,
        selectedUser
      }
    }

    case ADMIN_TOGGLE_INCLUDE_APP_USERS: {
      const includeAppUsers = !state.includeAppUsers
      const matched = getMatchUsers({ ...state, includeAppUsers })
      const selectedUser = matched[0] || {}
      return {
        ...state,
        includeAppUsers,
        matched,
        selectedUser
      }
    }

    case ADMIN_TOGGLE_INCLUDE_INACTIVE_USERS: {
      const includeInactiveUsers = !state.includeInactiveUsers
      const matched = getMatchUsers({ ...state, includeInactiveUsers })
      const selectedUser = matched[0] || {}
      return {
        ...state,
        includeInactiveUsers,
        matched,
        selectedUser
      }
    }

    case ADMIN_DELETE_USERS: 
    case ADMIN_REQUEST_UPDATE_USERS:
      return {
        ...state,
        ready: false,
        pending: true,
        error: null,
      }

    case ADMIN_FAIL_USERS:
      return {
        ...initialState,
        error: action.data,
      }

    case DASHBOARD_ADMIN_INTERFACE_CLOSE:
      return initialState

    default:
      return { ...state }
  }
}
