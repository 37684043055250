export default theme => {
  return {
    root: {
      overflowX: 'auto'
    },
    selected: {
      border: `3px solid ${theme.palette.grey[800]}`      
    },
    media: {
      boxShadow: theme.shadows[4],
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      display: 'inline-block',
      margin: 2,
      width: 55,
      height: 40
    },
    title: {
      margin: 10
    },
    center: {
      textAlign: 'center'
    },
    blocked: {
      color: theme.palette.secondary.dark
    }
  }
}
