import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { AdTagsList } from '../../'
import { Grid, Button } from '@material-ui/core'

const Styled = withStyles(theme =>
  theme.components.adminView.AdminMetadataManager(theme)
)

class AdTagger extends React.PureComponent {

  render() {
    const { toggleTag, ad, adTags, update, config } = this.props
    if (!adTags.length > 0 || !ad.ad_code) return null
    return (
      <Grid container direction="column" spacing={3} alignItems="flex-end" justify="flex-start" style={{width: '100%'}}>
        <Grid item style={{width: '100%'}} id="ad-tagger-container">
          <AdTagsList adTags={adTags} toggle={toggleTag} config={config} />
        </Grid>
        <Grid item >
          <Button variant="contained" color="secondary" onClick={update}>
            Apply
        </Button>
        </Grid>
      </Grid>
    )
  }
}


export default Styled(AdTagger)
