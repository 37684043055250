// Formats: '%,n', '$,n', '$x,n', 'f,n', 'i,n'
// operations: 'last(a)', prod(a,b), ratio(a,b)
export default {
  '***': {
    '***': [
      {
        metric: 100,
        inHeader: true,
        label: 'AdPi Score',
        rowType: 'demphasized',
        dataFormat: '.,0',
        reverseStat: false,
        minSampleSize: 0
      },
      {
        label: 'IN-MARKET PERFORMANCE',
        rowType: 'hb0'
      },
      {
        label: 'BREAKTHROUGH',
        rowType: 'h0'
      },
      {
        metric: 111,
        label: 'Branded Recall (Last Month Tested)',
        rowType: 'd0',
        dataFormat: '%,1',

        reverseStat: false,
        recall: {
          metric: 112
        }
      },
      {
        metric: 112,
        label: 'Branded Recall (Avg. Recall)',
        rowType: 'd0',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        label: 'IMPACT',
        rowType: 'h0'
      },
      {
        metric: 121,
        label: 'Lift to Consideration',
        rowType: 'd0',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 122,
        label: 'Lift to Impression',
        rowType: 'd0',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 123,
        label: 'Net Actions',
        rowType: 'd0',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 124,
        label: 'Visit this company’s website for more info',
        rowType: 'd1',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 125,
        label: 'Go online for more info about company/products',
        rowType: 'd1',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 126,
        label: 'Contact this company for more info',
        rowType: 'd1',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 127,
        label: 'Discuss the ad with friends or family',
        rowType: 'd1',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 128,
        label: 'Discuss the ad with other business owners or associates',
        rowType: 'd1',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 129,
        label: 'Respond to a mail/email solicitation',
        rowType: 'd1',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 130,
        label: 'Switch your merchant of FS to this company',
        rowType: 'd1',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        label: 'CREATIVE DIAGNOSTICS',
        rowType: 'hb0'
      },
      {
        label: 'BRANDING',
        rowType: 'h0'
      },
      {
        metric: 131,
        label: 'Brand Recognition',
        rowType: 'd0',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 132,
        label: 'Ease of Branding',
        rowType: 'd0',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 133,
        label: 'Ease of Branding w/o logo',
        rowType: 'd0',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 134,
        label: 'Good Fit with Brand',
        rowType: 'd0',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        label: 'APPEAL',
        rowType: 'h0'
      },
      {
        metric: 151,
        label: 'Likes',
        rowType: 'd0',
        dataFormat: '%,1',

        reverseStat: false
      },
      {
        metric: 152,
        label: 'Dislikes',
        rowType: 'd0',
        dataFormat: '%,1',

        reverseStat: true
      },

      {
        label: 'CREATIVE PATHWAYS',
        rowType: 'hb0'
      },

      // pathway - Cognitive Connection
      {
        label: 'Cognitive Connection',
        metric: 160,
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 161,
        label: 'Credible coming from this Company',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 162,
        label: 'Makes me think of the company in a new way',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 163,
        label: 'Is informative',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 164,
        label: 'Tells me something new about this company',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 165,
        label: 'Is easy to understand',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 166,
        label: 'Is confusing',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: true
      },

      // pathway - Brand Connection
      {
        label: 'Brand Connection',
        metric: 170,
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 171,
        label: 'Portrays a company I want to be associated with',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 172,
        label: 'Fulfills my business needs',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 173,
        label: 'Is strong and credible',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 174,
        label: 'Is trustworthy',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },

      // pathway - Brand Offerings
      {
        label: 'Brand Offerings',
        metric: 180,
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 181,
        label: 'Is innovative',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 182,
        label: 'Provides helpful services for my financial needs, in-person and online',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 183,
        label: 'Offers a wide range of products and services for every aspect of my business',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 184,
        label: 'Is committed to protecting privacy and security',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },

      // pathway - Brand Perception
      {
        label: 'Brand Perception',
        metric: 190,
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 191,
        label: 'Makes a positive impact on business owners',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 192,
        label: 'Is for businesses in my industry',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 193,
        label: 'Offers better products/services than what my business is currently using',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 194,
        label: 'Has a different message than what other companies say',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 195,
        label: 'Is easy to do business with',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 196,
        label: 'Is for medium or large-sized businesses',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },

      // pathway - Engagement
      {
        label: 'Engagement',
        metric: 200,
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 201,
        label: 'Is worth talking about',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 202,
        label: 'Is inspiring',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 203,
        label: 'Is unique',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 204,
        label: 'Is entertaining',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 205,
        label: 'Is humorous',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 206,
        label: 'Is heartwarming',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 207,
        label: 'Is boring',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: true
      },

      // pathway - Relevance
      {
        label: 'Relevance',
        metric: 210,
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 211,
        label: 'Is reassuring',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 212,
        label: 'Is a relevant message to you',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 213,
        label: 'Shows people and situations I can relate to',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 214,
        label: 'Directly addresses an issue that is relevant to me',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },

      {
        label: '',
        rowType: 'eol'
      }
    ]
  }
}
