export default {
  '***': [
    {
      "id": 9,
      "label": "Save & Invest",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [],
      "tooltip": "Ads which focus on saving and general investing"
    },
    {
      "id": 10,
      "label": "Financial Planning Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        20,
        30,
        40,
        50,
        60
      ],
      "tooltip": " if there is a general, non-retirement related guidance/advisory message; Financial Advisors/Planners helping customers, personal relationship/attention from advisors, non-retirement related wealth management services, college planning and/or tools or calculators for general financial planning, etc."
    },
    {
      "id": 20,
      "label": "General Guidance",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "General message about providing help or guidance overall – e.g. a person in a call center that would help you but that you might not call directly again and again. Any kind of guidance message that may not be caught elsewhere. If ad is exclusively about Retirement Planning, tag as Retirement Guidance instead."
    },
    {
      "id": 30,
      "label": "Dedicated Advisor (1 on 1 Guidance)",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "Shows/references dedicated advisor, one on one guidance, or personalized guidance. May reference a CFP or Financial Advisor or Financial Consultant."
    },
    {
      "id": 40,
      "label": "Financial Planning Tools (incl. Education/Seminars)",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "Tools/calculators specifically for retirement planning. Includes calculators, online quizzes, education, research, seminars, etc."
    },
    {
      "id": 50,
      "label": "Wealth Management",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "Non-retirement related wealth management/guidance. This includes estate planning, inheritance, trusts, “private wealth management”, managed accounts (i.e. Investment accounts managed by firm for a fee) or tax savings strategies. If ad is about IRA/Retirement tax savings, tag as Retirement Tax Management instead"
    },
    {
      "id": 60,
      "label": "College Savings",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "Any mention of a 529 account or college savings plan/account"
    },
    {
      "id": 70,
      "label": "Retirement Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        80,
        90,
        100,
        110,
        120,
        130,
      ],
      "tooltip": " if the ad speaks specifically about retirement or special retirement investment products (e.g. IRA, 401(k)). Any mention of the below items - planning for retirement, retirement tools/calculators, making retirement savings last, retirement income , how to manage money during retirement, etc."
    },
    {
      "id": 80,
      "label": "General Retirement Guidance",
      "selected": 0,
      "collapse": 0,
      "parent": 70,
      "children": [],
      "tooltip": "General guidance related to retirement.  Financial Advisors to help with retirement (MUST relate to retirement)."
    },
    {
      "id": 90,
      "label": "IRA/Rollovers/401(k)",
      "selected": 0,
      "collapse": 0,
      "parent": 70,
      "children": [],
      "tooltip": "Any mention of IRAs (including Roth) or 401(k)s. Also includes rolling over your 401(k) to an IRA or any mention of  moving your 401(k). Any mention of tax savings related to IRAs or 401ks should ALSO be tagged under Tax Management."
    },
    {
      "id": 100,
      "label": "Retirement Tools (incl. Education/Seminars)",
      "selected": 0,
      "collapse": 0,
      "parent": 70,
      "children": [],
      "tooltip": "Tools/calculators for general planning. Any kind of education offerings – seminars, webinars, local events, online videos, etc. If brokerage or retirement specific tools/education, code in those areas instead"
    },
    {
      "id": 110,
      "label": "Retirement Income/Annuities",
      "selected": 0,
      "collapse": 0,
      "parent": 70,
      "children": [],
      "tooltip": "Includes any mention of having income in retirement and/or how to manage spending retirement savings once you are retired. Includes any mention of annuities, annuity products or other tax deferred investment products"
    },
    {
      "id": 120,
      "label": "Tax Management",
      "selected": 0,
      "collapse": 0,
      "parent": 70,
      "children": [],
      "tooltip": "Any mention of retirement specific tax planning – e.g. tax savings strategies related to IRAs, 401(k)s, Roth IRAs, overall retirement plans, etc."
    },
    {
      "id": 130,
      "label": "TEM/403(b)",
      "selected": 0,
      "collapse": 0,
      "parent": 70,
      "children": [],
      "tooltip": "Any mention of 403(b) - rather than 401(k) - or TEM (tax-exempt market). Anything speaking directly to Healthcare, Higher Education or Government Employer sponsored retirement plans."
    },
    {
      "id": 140,
      "label": "Brokerage Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        150,
        160,
        170,
        180,
        190,
        191,
        195,
        196
      ],
      "tooltip": "if message is related to brokerage services (i.e. trading & its directly related products). Any mention of the below items –trading stocks/bonds (e.g. iShares), Active Trader products and platforms, Options (active trader), ETFs (trading related), investment research, and anything about accolades/awards a firm has won, e.g. Smart Money, Kiplinger's, Morningstar related to above brokerage products"
    },
    {
      "id": 150,
      "label": "General Brokerage",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "Any general brokerage/trading message that does not explicitly mention specific products/services below"
    },
    {
      "id": 160,
      "label": "Trading",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "Any ads that talk about Trading, trading platforms, trading stock, trading price, active traders, trading tools, etc. Trading platforms like Fidelity’s ATPro, TD’s Trade Architect, Schwab’s StreetSmart Edge, etc."
    },
    {
      "id": 170,
      "label": "Research/Tools",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "Ads that mention brokerage/trading related research tools/resources, like Fidelity’s Equity Summary Score, analyst reports, stock screeners, etc."
    },
    {
      "id": 180,
      "label": "Brokerage Accolades/Awards",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "Anything about brokerage/trading related accolades/awards a firm has  won, e.g. Smart Money, Kiplinger's, Morningstar, Gomez, Barons. Otherwise categorize under Broad Brand Image"
    },
    {
      "id": 190,
      "label": "ETFs & Options",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "These ads will  specifically mention trading “ETFs” or “Options” rather than  standard stocks/equities"
    },
    {
      "id": 191,
      "label": "Direct Indexing",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "These ads talk about allowing investor to construct their own portfolio that typically mirrors the composition of an index"
    },
    {
      "id": 195,
      "label": "Cryptocurrency",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "Any mention of platforms that allow selling or buying of digital currencies or mention of the digital currency like Bitcoin, Ethereum (ETH), Tether, USD Coin, Binance Coin etc"
    },
    {
      "id": 196,
      "label": "Other Digital Security",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "Any mention of digital securities other than cryptocurrency like NFT, Metaverse etc."
    },
    {
      "id": 200,
      "label": "Asset Management Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        210,
        220,
        230
      ],
      "tooltip": "if any mention of cash back credit cards, features of checking account like free ATM access, savings, debit card, check writing, and bill pay, etc. Note, these are rare and are usually not related to other investment services products"
    },
    {
      "id": 210,
      "label": "Actively Managed Funds",
      "selected": 0,
      "collapse": 0,
      "parent": 200,
      "children": [],
      "tooltip": "Any mention of funds that are actively managed by a fund manager or team of fund managers at a firm. Examples can be equity/stock funds, ETFs, bond funds, sector funds (e.g. in a specific industry like healthcare, tech, real estate, utilities), etc."
    },
    {
      "id": 220,
      "label": "Index Funds",
      "selected": 0,
      "collapse": 0,
      "parent": 200,
      "children": [],
      "tooltip": "Any mention of passively managed funds. These are often referred to as index funds and are designed to track the major stock indices like the S&P 500. The name of the fund may reference this (e.g. Vanguard 500)"
    },
    {
      "id": 230,
      "label": "Fixed Income",
      "selected": 0,
      "collapse": 0,
      "parent": 200,
      "children": [],
      "tooltip": "Any mention of individual bonds, Fixed Income ETFs (Exchange Traded Funds), CDs, Money Market Funds, or Fixed Income Annuities. Note – ads in this category may also be tagged in Retirement/Annuities or in either of the Asset Mgmt. categories listed above"
    },
    {
      "id": 240,
      "label": "Cash Management",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [],
      "tooltip": "if any mention of cash back credit cards, features of checking account like free ATM access, savings, debit card, check writing, and bill pay, etc. Note, these are rare and are usually not related to other investment services products"
    },
    {
      "id": 250,
      "label": "Value Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        260,
        270,
        280
      ],
      "tooltip": " if message is about cost effectiveness or value for the services offered in general OR specifically in terms of fees, expenses, no load funds, expense ratio of mutual funds/ETFs. Also any special offers such as free trades, reduced price offers, $500 bonus, etc."
    },
    {
      "id": 260,
      "label": "General Value",
      "selected": 0,
      "collapse": 0,
      "parent": 250,
      "children": [],
      "tooltip": "General value message that does NOT specifically mention fees/pricing or communicate an offer. General cost mentions go here"
    },
    {
      "id": 270,
      "label": "Fees/Pricing",
      "selected": 0,
      "collapse": 0,
      "parent": 250,
      "children": [],
      "tooltip": "Any mention of fees, no hidden fees, no-load funds, trade pricing, etc."
    },
    {
      "id": 280,
      "label": "Offers",
      "selected": 0,
      "collapse": 0,
      "parent": 250,
      "children": [],
      "tooltip": "Offers for opening account, e.g. 200 free trades, or $500 bonus if you rollover your 401(k)"
    },
    {
      "id": 290,
      "label": "Brand Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        300,
        310,
        320,
        330
      ],
      "tooltip": " if message is not about a specific product or service the company offers, but is about the company image or reputation overall, accolades associated with the company overall (e.g. JD Powers, Kiplinger's) or the company sponsoring charity, sports, arts, etc."
    },
    {
      "id": 300,
      "label": "General Brand Image",
      "selected": 0,
      "collapse": 0,
      "parent": 290,
      "children": [],
      "tooltip": "Ad that doesn't talk about any particular product but rather talks about the brand image in general.  E.g. \"Our firm's been around a long time and are reliable. \"We’ve been here a long time; look at how solid we are”"
    },
    {
      "id": 310,
      "label": "General Accolades/Awards",
      "selected": 0,
      "collapse": 0,
      "parent": 290,
      "children": [],
      "tooltip": "Awards or accolades relating to the firm overall. Could mention a third party rating system such as JD Powers, Kiplinger's, MoneyMag, etc. If brokerage related, tag there instead"
    },
    {
      "id": 320,
      "label": "Thought Leadership",
      "selected": 0,
      "collapse": 0,
      "parent": 290,
      "children": [],
      "tooltip": "Demonstrating that they have the strongest products or people – e.g. trading platform, research, fund managers, leadership, resources, performance; talking in such a way that they are setting themselves apart from others in the industry because of their superior offering and/or expertise"
    },
    {
      "id": 330,
      "label": "Sponsorship",
      "selected": 0,
      "collapse": 0,
      "parent": 290,
      "children": [],
      "tooltip": "These ads communicate the company is sponsoring something such as donation drive to cancer research, Olympics sponsorship, local community events, races, theater, concert series etc."
    },
    {
      "id": 340,
      "label": "Style Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        350,
        390,
        400,
        405,
        410,
        420,
        430,
        440
      ],
      "tooltip": "This will automatically be checked if anything in the Style section is checked."
    },
    {
      "id": 350,
      "label": "People Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [
        360,
        370,
        380
      ],
      "tooltip": "This will automatically be checked if anything in the People section is checked."
    },
    {
      "id": 360,
      "label": "People - Female lead",
      "selected": 0,
      "collapse": 0,
      "parent": 350,
      "children": [],
      "tooltip": "Women – includes a lead female character"
    },
    {
      "id": 370,
      "label": "People - Couple/Family/Kids",
      "selected": 0,
      "collapse": 0,
      "parent": 350,
      "children": [],
      "tooltip": "Couples/Family/Kids – depicts any type of family, incl. kids, grandparents, couples, etc."
    },
    {
      "id": 380,
      "label": "People - Other",
      "selected": 0,
      "collapse": 0,
      "parent": 350,
      "children": [],
      "tooltip": "Any ad that includes real people and does not fall into the \"Female lead\" or \"Couples/Family/Kids\" categories. Could be one character or several."
    },
    {
      "id": 390,
      "label": "Screenshots/Demos/Charts",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "Any ad that includes screenshots, demos, simulations, charts, tables, etc."
    },
    {
      "id": 400,
      "label": "Animation",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "The commercial needs to be all or mostly cartoon or computer generated graphics. Includes ads that have people superimposed on computer generated graphics"
    },
    {
      "id": 405,
      "label": "Music",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "The commercial needs to have noticeable music. Not just sound effects or a jingle."
    },
    {
      "id": 410,
      "label": "Paragraph Style Copy",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "Anything using complete sentences rather than sentence fragments.  Multiple sentences would definitely be a paragraph."
    },
    {
      "id": 420,
      "label": "Bullet Style Copy",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "Anything using bullets to separate different pieces of information. Include lists or separate lines of short info (not sentences) that are clearly delineated from one another"
    },
    {
      "id": 430,
      "label": "Lifestyle Image",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "Image of real person or real-life environment, instead of plain text.  Real person, real environment, photo quality or converted photo quality.  Not sketches or drawings."
    },
    {
      "id": 440,
      "label": "Custom Print Unit/Layout",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "Anything that is not on one full page.  So any two plus page spread OR any ad that is less than a full page such as a half page. Any irregular (not full page) ad."
    },
    {
      "id": 450,
      "label": "Spokesperson Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        460,
        470,
        480
      ],
      "tooltip": "Is a customer of the company delivering the message? In any media, if the voice of the ad is in the customer’s voice, use this tag. Customers can be real customers of the brand or actors portraying customers of the brand. For this tag to be used they need to be “speaking” to the consumer"
    },
    {
      "id": 460,
      "label": "Employee Spokesperson",
      "selected": 0,
      "collapse": 0,
      "parent": 450,
      "children": [],
      "tooltip": "Is an employee of the company delivering the message? In any media, if the voice of the ad is in the employee’s voice, use this tag. Do not tag if ad shows an employee, but they aren’t “speaking” to the consumer"
    },
    {
      "id": 470,
      "label": "Customer Spokesperson",
      "selected": 0,
      "collapse": 0,
      "parent": 450,
      "children": [],
      "tooltip": "Is a customer of the company delivering the message? In any media, if the voice of the ad is in the customer’s voice, use this tag. Customers can be real customers of the brand or actors portraying customers of the brand. For this tag to be used they need to be “speaking” to the consumer"
    },
    {
      "id": 480,
      "label": "Celebrity Spokesperson",
      "selected": 0,
      "collapse": 0,
      "parent": 450,
      "children": [],
      "tooltip": "Does the ad feature a celebrity spokesperson speaking on behalf of the brand? This can be a famous Hollywood type or a person who is familiar because they are used in the commercials often. For example, E*TRADE baby, Sam Waterston, etc."
    },
    {
      "id": 490,
      "label": "Tone Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        500,
        510
      ],
      "tooltip": "This will automatically be checked if anything in the Tone section is checked."
    },
    {
      "id": 500,
      "label": "Emotional",
      "selected": 0,
      "collapse": 0,
      "parent": 490,
      "children": [],
      "tooltip": "Messages that discuss or think about family or how things will impact loved ones/others. Ad could be described as heartwarming, touching, reassuring, moving, inspiring, etc."
    },
    {
      "id": 510,
      "label": "Humorous",
      "selected": 0,
      "collapse": 0,
      "parent": 490,
      "children": [],
      "tooltip": "Ads that use humor to get their message across (or attempt to). Ads may or may not be overtly funny, but you get the sense the ad is trying to be lighthearted, entertaining or witty/humorous"
    }
  ]
}
