export default {
  slices: [
    {
      field: 'ad_categories',
      display: 'Restaurant Type',
      map: {
        all_restaurants: {
          display: 'All Restaurants',
          sortOrder: 1
        },
        qsr_fast_dining: {
          display: 'QSR and Fast Casual',
          sortOrder: 2
        },
        casual_dining: {
          display: 'Casual Dining',
          sortOrder: 3
        }
      }
    },
    {
      field: 'ad_type',
      display: 'Media Type',
      map: {
        tv: {
          display: 'TV',
          mediaType: 'mp4',
          sortOrder: 2
        }
      }
    },
  ]
}
