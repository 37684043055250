export default {
  '***': {
    'market_smb': {
      '***': {
        displayFilters: null,
        staticFilters: [
          {
            label: 'Number of Employees',
            code: 'employees',
            multi: true,
            options: [
              {
                label: '1 to 5 employees',
                value: 1,
                selected: false
              },
              {
                label: '6 to 10 employees',
                value: 2,
                selected: false
              },
              {
                label: '11 to 20 employees',
                value: 3,
                selected: false
              },
              {
                label: '21 to 50 employees',
                value: 4,
                selected: false
              },
            ]
          },
          {
            label: 'Monthly IT/Comms Spend',
            excludeFromVerbatims: true,
            code: 'spend',
            multi: true,
            options: [
              {
                label: '<$100/month',
                value: 1,
                selected: false
              },
              {
                label: '$100-$499/month',
                value: 2,
                selected: false
              },
              {
                label: '$500-$1,999/month',
                value: 3,
                selected: false
              }
            ]
          },
          {
            label: 'Customer/Prospect',
            code: 'customer',
            multi: true,
            options: [
              {
                label: 'Comcast Business Customer',
                value: 1,
                selected: false
              },
              {
                label: 'Comcast Business Prospect',
                value: 2,
                selected: false
              }
            ]
          },
          {
            label: 'Division',
            code: 'division',
            multi: true,
            options: [
              {
                label: 'Northeast',
                value: 1,
                selected: false
              },
              {
                label: 'Central',
                value: 2,
                selected: false
              },
              {
                label: 'West',
                value: 3,
                selected: false
              }
            ]
          },
          {
            label: 'Annual Revenue',
            excludeFromVerbatims: true,
            code: 'revenue',
            multi: true,
            options: [
              {
                label: 'Less than $250K',
                value: 1,
                selected: false
              },
              {
                label: '$250K but less than $1M',
                value: 2,
                selected: false
              },
              {
                label: '$1M or more',
                value: 3,
                selected: false
              }
            ]
          },
          {
            label: 'Xfinity Customer/Prospect',
            excludeFromVerbatims: false,
            code: 'xfinity_customer',
            multi: true,
            options: [
              {
                label: 'Xfinity Customer',
                value: 1,
                selected: false
              },
              {
                label: 'Xfinity Prospect',
                value: 2,
                selected: false
              }
            ]
          }
        ]
      }
    },
    'market_mm_ent': {
      '***': {
        displayFilters: null,
        staticFilters: [
          {
            label: 'Number of Employees',
            code: 'employees',
            multi: true,
            options: [
              {
                label: '51 to 250 employees',
                value: 1,
                selected: false
              },
              {
                label: '251 to 499 employees',
                value: 2,
                selected: false
              },
              {
                label: '500 to 2499 employees',
                value: 3,
                selected: false
              },
              {
                label: '>2500 employees',
                value: 4,
                selected: false
              },
            ]
          },
          {
            label: 'Monthly IT/Comms Spend',
            code: 'spend',
            multi: true,
            options: [
              {
                label: '$2,000-$9,999/month',
                value: 1,
                selected: false
              },
              {
                label: '$10,000-$79,999/month',
                value: 2,
                selected: false
              },
              {
                label: '>$80,000/month',
                value: 3,
                selected: false
              }
            ]
          },
          {
            label: 'Customer/Prospect',
            code: 'customer',
            multi: true,
            options: [
              {
                label: 'Comcast Business Customer',
                value: 1,
                selected: false
              },
              {
                label: 'Comcast Business Prospect',
                value: 2,
                selected: false
              }
            ]
          },
          {
            label: 'Number of Locations',
            code: 'location',
            multi: true,
            options: [
              {
                label: '1-50 Locations',
                value: 1,
                selected: false
              },
              {
                label: '51-500 Locations',
                value: 2,
                selected: false
              },
              {
                label: '501+ Locations',
                value: 3,
                selected: false
              }
            ]
          },
          {
            label: 'Number of IT Professionals',
            excludeFromVerbatims: true,
            code: 'professional',
            multi: true,
            options: [
              {
                label: '<500 Professionals',
                value: 1,
                selected: false
              },
              {
                label: '500 - 2500 Professionals',
                value: 2,
                selected: false
              },
              {
                label: '>2500 Professionals',
                value: 3,
                selected: false
              }
            ]
          },
          {
            label: 'Xfinity Customer/Prospect',
            excludeFromVerbatims: true,
            code: 'xfinity_customer',
            multi: true,
            options: [
              {
                label: 'Xfinity Customer',
                value: 1,
                selected: false
              },
              {
                label: 'Xfinity Prospect',
                value: 2,
                selected: false
              }
            ]
          }
        ]
      }
    }
  }

}
