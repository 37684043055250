import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import cn from 'classnames'

import { adRange, generateUUID } from '../../../../../new_utils'

const Styled = withStyles(theme =>
  theme.components.selection.Search.SearchManual.SearchAdCodes(theme)
)

class SearchAdCodes extends Component {
  state = {
    adCodes: [],
    invalid: [],
    unknown: [],
    range: null
  }

  onTextChange = e => {
    const { updateSearchInputText } = this.props
    updateSearchInputText(e.target.value.toUpperCase())
  }

  clear = () => {
    const { updateSearchInputText } = this.props

    this.setState({
      uuid: null,
      adCodes: [],
      invalid: [],
      unknown: [],
      range: null
    })
    updateSearchInputText("")
  }

  validate = () => {
    const { selections, adsMap, config, searchInputText } = this.props

    const candidateCodes = searchInputText
      .replace(/[^a-zA-Z\d-]/g, ',')
      .split(',')
      .filter(code => code.length)

    const { _adCodes, _invalid, _unknown, _range } = candidateCodes.reduce(
      (obj, ad_code) => {
        const ad = adsMap[ad_code]

        if (ad) {
          const validity = Object.keys(selections).reduce((array, key) => {
            const adField = Array.isArray(ad[key]) ? ad[key] : [ad[key]]
            array.push(adField.includes(selections[key].field))
            return array
          }, [])

          if (validity.reduce((valid, value) => valid && value, true)) {
            obj._adCodes[ad_code] = ad
            obj._range = {
              start_month: Math.min(
                adRange(ad).start_month,
                obj._range.start_month
              ),
              end_month: Math.max(adRange(ad).end_month, obj._range.end_month)
            }
          } else {
            obj._invalid[ad_code] = {
              ad_code,
              title: ad.title,
              validity
            }
          }
        } else {
          obj._unknown[ad_code] = {
            ad_code,
            title: config.errors.nonExistent,
            validity: Object.keys(selections).map(selection => 'N/A')
          }
        }
        return obj
      },
      {
        _adCodes: {},
        _invalid: {},
        _unknown: {},
        _range: { start_month: 9999, end_month: 0 }
      }
    )

    const adCodes = Object.keys(_adCodes)
      .reduce((array, ad_code) => {
        array.push(_adCodes[ad_code])
        return array
      }, [])
      .sort((a, b) => (a.ad_code > b.ad_code ? 1 : -1))

    const invalid = Object.keys(_invalid)
      .reduce((array, ad_code) => {
        array.push(_invalid[ad_code])
        return array
      }, [])
      .sort((a, b) => (a.ad_code > b.ad_code ? 1 : -1))

    const unknown = Object.keys(_unknown)
      .reduce((array, ad_code) => {
        array.push(_unknown[ad_code])
        return array
      }, [])
      .sort((a, b) => (a.ad_code > b.ad_code ? 1 : -1))
    const range = adCodes.length ? _range : null
    this.setState({
      adCodes,
      invalid,
      unknown,
      range,
      uuid: generateUUID()
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { update } = this.props
    if (prevState.uuid !== this.state.uuid) {
      const { range, adCodes } = this.state
      update(adCodes, range)
    }
  }
  render() {
    const { classes, searchInputText, config, selections } = this.props
    const { adCodes, invalid, unknown } = this.state

    const width = (1 / (selections.length + 2)) * 100

    return (
      <Grid className={classes.container} container spacing={2}>
        <Grid xs={5} item className={classes.cell}>
          <Grid container alignItems="flex-end" justify="space-between">
            <Grid item>
              <Typography variant="subtitle1" color="inherit">
                {config.columnLabels.custom}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                size="small"
                className={classes.button}
                onClick={this.validate}
                disabled={searchInputText.length < 2}
                variant="contained"
              >
                Validate
              </Button>
              <Button size="small" onClick={this.clear} variant="contained">
                Clear
              </Button>
            </Grid>
          </Grid>
          <TextField
            fullWidth
            style={{ marginTop: 10 }}
            placeholder={config.columnLabels.helpText}
            multiline
            rows="17"
            InputProps={{ disableUnderline: true, style: { padding: 5 } }}
            value={searchInputText}
            onChange={this.onTextChange}
            className={classes.textArea}
          />
        </Grid>
        <Grid xs={7} item className={classes.cell}>
          <Paper className={classes.table}>
            <Table>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell
                    className={classes.tableCellPadded}
                    style={{ width: `${width}%` }}
                  >
                    <Typography noWrap color="inherit" align="left">
                      {config.columnLabels.code}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ width: `${width}%` }}
                  >
                    <Typography noWrap color="inherit" align="left">
                      {config.columnLabels.title}
                    </Typography>
                  </TableCell>
                  {Object.keys(selections).map(key => (
                    <TableCell
                      className={classes.tableCellCentered}
                      style={{ width: `${width}%` }}
                      key={key}
                    >
                      {selections[key].label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {adCodes.map(ad => {
                  return (
                    <TableRow
                      className={classes.tableRowData}
                    
                      key={ad.ad_code}
                    >
                      <TableCell
                        className={classes.tableCellPadded}
                        style={{ width: `${width}%` }}
                      >
                        <Typography
                          noWrap
                          variant="caption"
                          color="inherit"
                          align="left"
                        >
                          {ad.ad_code}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ width: `${width}%` }}
                      >
                        <Typography
                          noWrap
                          variant="caption"
                          color="inherit"
                          align="left"
                          style={{ maxWidth: 300 }}
                        >
                          {ad.title}
                        </Typography>
                      </TableCell>

                      {Object.keys(selections).map( key => (
                        <TableCell
                          className={classes.tableCellCentered}
                          style={{ width: `${width}%` }}
                          key={key}
                        >
                          <CheckCircleIcon className={classes.checkIcon} />
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
                {invalid.map(ad => {
                  return (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={ad.ad_code}
                      style={{ paddingLeft: 10 }}
                    >
                      <TableCell
                        className={cn(classes.tableCellPadded, classes.invalid)}
                        style={{
                          width: `${width}%`
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="inherit"
                          align="left"
                          noWrap
                        >
                          <WarningIcon className={classes.warningIcon} />
                          {ad.ad_code}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={cn(classes.tableCell, classes.invalid)}
                        style={{
                          width: `${width}%`
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="inherit"
                          noWrap
                          align="left"
                          style={{ maxWidth: 300 }}
                        >
                          {ad.title}
                        </Typography>
                      </TableCell>
                      {ad.validity.map(valid => (
                        <TableCell
                          className={cn(
                            classes.tableCellCentered,
                            classes.invalid
                          )}
                          style={{
                            width: `${width}%`
                          }}
                          key={ad.ad_code}
                        >
                          {valid === true ? (
                            <CheckCircleIcon className={classes.checkIcon} />
                          ) : (
                            <ErrorIcon className={classes.errorIcon} />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}

                {unknown.map(ad => {
                  return (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={ad.ad_code}
                      style={{ paddingLeft: 10 }}
                    >
                      <TableCell
                        className={cn(classes.tableCellPadded, classes.invalid)}
                        style={{
                          width: `${width}%`
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="inherit"
                          align="left"
                          noWrap
                        >
                          <WarningIcon className={classes.warningIcon} />
                          {ad.ad_code}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={cn(classes.tableCell, classes.invalid)}
                        style={{
                          width: `${width}%`
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="inherit"
                          noWrap
                          align="left"
                          style={{ maxWidth: 300 }}
                        >
                          {ad.title}
                        </Typography>
                      </TableCell>
                      {ad.validity.map(valid => (
                        <TableCell
                          key={ad.ad_code}
                          className={cn(
                            classes.tableCellCentered,
                            classes.invalid
                          )}
                          style={{
                            width: `${width}%`
                          }}
                        >
                          -
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default Styled(SearchAdCodes)
