export default theme => {
  return {
    container: {
      height: '100%',
      fontFamily: 'Roboto',
      fontSize: 11,
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      paddingLeft: 15
    },
    top: {
      marginTop: 80
    },
    headerGrid: {
      height: 20
    },
    headerMetric: {
      fontWeight: 'bold'
    }
  }
}
