import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardFixedColumn.ScorecardNormHeader(
    theme
  )
)

export default Styled(props => {
  const {
    classes,
    collapse
  } = props
  return (
      <Grid
        container
        direction="column"
        justify="center"
        className={classes.container}
        onClick={collapse}
      >
      <Grid item>
        <Typography variant="h5" align="center">Norm</Typography>
      </Grid>
    </Grid>
  )
})
