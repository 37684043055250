// Formats: '%,n', '$,n', '$x,n', 'f,n', 'i,n'
// operations: 'last(a)', prod(a,b), ratio(a,b)
export default {
  tv: [
    {
      label: 'Top',
      rowType: 'h0'
    },
    {
      metric: 1,
      label: '% Ad Recall / Seen Before',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 2,
      label: '% Correct Brand ID / Recall',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 3,
      label: '% Correct Brand ID (incl. Amazon)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 4,
      label: 'Breakthrough',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 5,
      label: 'Breakthrough (incl. Amazon)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 6,
      label: 'Ease of Branding (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 7,
      label: 'Lift in Brand Impression (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 8,
      label: 'Lift in Brand Consideration (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 9,
      label: '% Positive Actions / Intentions (NET)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 10,
      label: 'Shared the ad with others (via email, social media, etc.)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 11,
      label: 'Visited the company’s website',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 12,
      label: 'Visited another website',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 13,
      label: 'Looked up more information about the company',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 14,
      label: 'Looked up more information about similar services',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 15,
      label: 'Called the company for more information',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 16,
      label: 'Discussed the company with friends, relative, or associates',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 17,
      label: 'Subscribed to the service',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 18,
      label: 'Made a purchase',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 19,
      label: 'Subscribed/Made a purchase',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 20,
      label: 'Considered using this company for a free trial',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 21,
      label: 'Signed up for a free trial',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 22,
      label: 'Talked positively about the ad',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 23,
      label: 'Talked negatively about the ad',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: true
    },
    {
      metric: 24,
      label: 'A5. If the logo and product were taken out of this ad, how easily could you tell it was for [pipe A3]?',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 25,
      label: 'A7a. Has this ad affected your likelihood to consider products or services like this one?',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 26,
      label: 'A13/14. None of the above',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 27,
      label: 'AdPi',
      inHeader: true,
      rowType: 'd0',
      dataFormat: '.,0',
      reverseStat: false
    },
    {
      metric: 28,
      label: 'Likeability',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Strongest Drivers',
      rowType: 'h0'
    },
    {
      metric: 29,
      label: 'Worth seeing/hearing again ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 30,
      label: 'Makes me think brand is worth the price ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 31,
      label: 'Spoke directly to me ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Brand Value',
      rowType: 'h0'
    },
    {
      metric: 33,
      label: 'Makes me think brand has best selection of content ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },

    {
      metric: 32,
      label: 'Makes me think brand has best quality content ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 34,
      label: 'Portrays brand I want to be associated with ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 38,
      label: 'Credible message coming from this brand ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Cognitive Connection',
      rowType: 'h0'
    },
    {
      metric: 37,
      label: 'Informative ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 35,
      label: 'Makes me think about brand in a new way ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 39,
      label: 'Clear',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Engagement',
      rowType: 'h0'
    },
    {
      metric: 47,
      label: 'Unique ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 46,
      label: 'Clever',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 45,
      label: 'Entertaining',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 48,
      label: 'Humorous',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Emotional Connection',
      rowType: 'h0'
    },
    {
      metric: 44,
      label: 'Heartwarming',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 41,
      label: 'Inspiring',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 42,
      label: 'Reassuring ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 40,
      label: 'Meaningful',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Relevance',
      rowType: 'h0'
    },
    {
      metric: 43,
      label: 'Shows people and situations I can relate to ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 36,
      label: 'Directly addresses issue that’s important to me ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: '',
      rowType: 'eol'
    }
  ],
  online_video: [
    {
      label: 'Top',
      rowType: 'h0'
    },
    {
      metric: 1,
      label: '% Ad Recall / Seen Before',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 2,
      label: '% Correct Brand ID / Recall',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 3,
      label: '% Correct Brand ID (incl. Amazon)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 4,
      label: 'Breakthrough',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 5,
      label: 'Breakthrough (incl. Amazon)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 6,
      label: 'Ease of Branding (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 7,
      label: 'Lift in Brand Impression (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 8,
      label: 'Lift in Brand Consideration (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 9,
      label: '% Positive Actions / Intentions (NET)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 10,
      label: 'Shared the ad with others (via email, social media, etc.)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 11,
      label: 'Visited the company’s website',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 12,
      label: 'Visited another website',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 13,
      label: 'Looked up more information about the company',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 14,
      label: 'Looked up more information about similar services',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 15,
      label: 'Called the company for more information',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 16,
      label: 'Discussed the company with friends, relative, or associates',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 17,
      label: 'Subscribed to the service',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 18,
      label: 'Made a purchase',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 19,
      label: 'Subscribed/Made a purchase',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 20,
      label: 'Considered using this company for a free trial',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 21,
      label: 'Signed up for a free trial',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 22,
      label: 'Talked positively about the ad',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 23,
      label: 'Talked negatively about the ad',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: true
    },
    {
      metric: 24,
      label: 'A5. If the logo and product were taken out of this ad, how easily could you tell it was for [pipe A3]?',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 25,
      label: 'A7a. Has this ad affected your likelihood to consider products or services like this one?',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 26,
      label: 'A13/14. None of the above',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 27,
      label: 'AdPi',
      inHeader: true,
      rowType: 'd0',
      dataFormat: '.,0',
      reverseStat: false
    },
    {
      metric: 28,
      label: 'Likeability',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Strongest Drivers',
      rowType: 'h0'
    },
    {
      metric: 29,
      label: 'Worth seeing/hearing again ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 30,
      label: 'Makes me think brand is worth the price ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 31,
      label: 'Spoke directly to me ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Brand Value',
      rowType: 'h0'
    },
    {
      metric: 33,
      label: 'Makes me think brand has best selection of content ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },

    {
      metric: 32,
      label: 'Makes me think brand has best quality content ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 34,
      label: 'Portrays brand I want to be associated with ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 38,
      label: 'Credible message coming from this brand ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Cognitive Connection',
      rowType: 'h0'
    },
    {
      metric: 37,
      label: 'Informative ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 35,
      label: 'Makes me think about brand in a new way ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 39,
      label: 'Clear',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Engagement',
      rowType: 'h0'
    },
    {
      metric: 47,
      label: 'Unique ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 46,
      label: 'Clever',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 45,
      label: 'Entertaining',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 48,
      label: 'Humorous',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Emotional Connection',
      rowType: 'h0'
    },
    {
      metric: 44,
      label: 'Heartwarming',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 41,
      label: 'Inspiring',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 42,
      label: 'Reassuring ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 40,
      label: 'Meaningful',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Relevance',
      rowType: 'h0'
    },
    {
      metric: 43,
      label: 'Shows people and situations I can relate to ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 36,
      label: 'Directly addresses issue that’s important to me ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: '',
      rowType: 'eol'
    }
  ],
  radio: [
    {
      label: 'Top',
      rowType: 'h0'
    },
    {
      metric: 1,
      label: '% Ad Recall / Seen Before',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 2,
      label: '% Correct Brand ID / Recall',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 3,
      label: '% Correct Brand ID (incl. Amazon)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 4,
      label: 'Breakthrough',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 5,
      label: 'Breakthrough (incl. Amazon)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 6,
      label: 'Ease of Branding (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 7,
      label: 'Lift in Brand Impression (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 8,
      label: 'Lift in Brand Consideration (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 9,
      label: '% Positive Actions / Intentions (NET)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 10,
      label: 'Shared the ad with others (via email, social media, etc.)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 11,
      label: 'Visited the company’s website',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 12,
      label: 'Visited another website',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 13,
      label: 'Looked up more information about the company',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 14,
      label: 'Looked up more information about similar services',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 15,
      label: 'Called the company for more information',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 16,
      label: 'Discussed the company with friends, relative, or associates',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 17,
      label: 'Subscribed to the service',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 18,
      label: 'Made a purchase',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 19,
      label: 'Subscribed/Made a purchase',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 20,
      label: 'Considered using this company for a free trial',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 21,
      label: 'Signed up for a free trial',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 22,
      label: 'Talked positively about the ad',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 23,
      label: 'Talked negatively about the ad',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: true
    },
    {
      metric: 24,
      label: 'A5. If the logo and product were taken out of this ad, how easily could you tell it was for [pipe A3]?',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 25,
      label: 'A7a. Has this ad affected your likelihood to consider products or services like this one?',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 26,
      label: 'A13/14. None of the above',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 27,
      label: 'AdPi',
      inHeader: true,
      rowType: 'd0',
      dataFormat: '.,0',
      reverseStat: false
    },
    {
      metric: 28,
      label: 'Likeability',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Relevance',
      rowType: 'h0'
    },
    {
      metric: 43,
      label: 'Shows people and situations I can relate to',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 31,
      label: 'Spoke directly to me',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 36,
      label: "Directly addresses issue that's important to me",
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 34,
      label: 'Portrays brand I want to be associated with',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Engagement',
      rowType: 'h0'
    },
    {
      metric: 29,
      label: 'Worth seeing/hearing again',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },

    {
      metric: 46,
      label: 'Clever ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 47,
      label: 'Unique',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Brand Value',
      rowType: 'h0'
    },
    {
      metric: 30,
      label: 'Makes me think brand is worth price',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 32,
      label: 'Makes me think brand has best quality content ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 33,
      label: 'Makes me think brand has best selection of content ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 35,
      label: 'Makes me think about brand in a new way ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Cognitive Connection',
      rowType: 'h0'
    },

    {
      metric: 38,
      label: 'Credible message coming from this brand',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 37,
      label: 'Informative ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 39,
      label: 'Clear',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Emotional Connection',
      rowType: 'h0'
    },
    {
      metric: 40,
      label: 'Meaningful',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 41,
      label: 'Inspiring',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 42,
      label: 'Reassuring ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 44,
      label: 'Heartwarming',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Breakthrough',
      rowType: 'h0'
    },
    {
      metric: 45,
      label: 'Entertaining',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 38,
      label: 'Humorous',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: '',
      rowType: 'eol'
    }
  ],
  display: [
    {
      label: 'Top',
      rowType: 'h0'
    },
    {
      metric: 1,
      label: '% Ad Recall / Seen Before',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 2,
      label: '% Correct Brand ID / Recall',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 3,
      label: '% Correct Brand ID (incl. Amazon)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 4,
      label: 'Breakthrough',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 5,
      label: 'Breakthrough (incl. Amazon)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 6,
      label: 'Ease of Branding (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 7,
      label: 'Lift in Brand Impression (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 8,
      label: 'Lift in Brand Consideration (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 9,
      label: '% Positive Actions / Intentions (NET)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 10,
      label: 'Shared the ad with others (via email, social media, etc.)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 11,
      label: 'Visited the company’s website',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 12,
      label: 'Visited another website',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 13,
      label: 'Looked up more information about the company',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 14,
      label: 'Looked up more information about similar services',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 15,
      label: 'Called the company for more information',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 16,
      label: 'Discussed the company with friends, relative, or associates',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 17,
      label: 'Subscribed to the service',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 18,
      label: 'Made a purchase',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 19,
      label: 'Subscribed/Made a purchase',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 20,
      label: 'Considered using this company for a free trial',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 21,
      label: 'Signed up for a free trial',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 22,
      label: 'Talked positively about the ad',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 23,
      label: 'Talked negatively about the ad',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: true
    },
    {
      metric: 24,
      label: 'A5. If the logo and product were taken out of this ad, how easily could you tell it was for [pipe A3]?',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 25,
      label: 'A7a. Has this ad affected your likelihood to consider products or services like this one?',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 26,
      label: 'A13/14. None of the above',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 27,
      label: 'AdPi',
      inHeader: true,
      rowType: 'd0',
      dataFormat: '.,0',
      reverseStat: false
    },
    {
      metric: 28,
      label: 'Likeability',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Strongest Drivers',
      rowType: 'h0'
    },
    {
      metric: 29,
      label: 'Worth seeing/hearing again ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 30,
      label: 'Makes me think brand is worth the price ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 31,
      label: 'Spoke directly to me ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 32,
      label: 'Makes me think brand has best quality content ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 33,
      label: 'Makes me think brand has best selection of content ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Brand Relevance & Reappraisal',
      rowType: 'h0'
    },
    {
      metric: 34,
      label: 'Portrays brand I want to be associated with ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 35,
      label: 'Makes me think about brand in a new way ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 36,
      label: 'Directly addresses issue that’s important to me ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Cognitive Connection',
      rowType: 'h0'
    },

    {
      metric: 37,
      label: 'Informative ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 38,
      label: 'Credible message coming from this brand ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },

    {
      metric: 39,
      label: 'Clear',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },

    {
      label: 'Empathetic Connection',
      rowType: 'h0'
    },
    {
      metric: 40,
      label: 'Meaningful',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 41,
      label: 'Inspiring',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 42,
      label: 'Reassuring ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 43,
      label: 'Shows people and situations I can relate to ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 44,
      label: 'Heartwarming',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },

    {
      label: 'Engagement',
      rowType: 'h0'
    },
    {
      metric: 45,
      label: 'Entertaining',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 46,
      label: 'Clever',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 47,
      label: 'Unique ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 48,
      label: 'Humorous',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: '',
      rowType: 'eol'
    }
  ],
  social_post: [
    {
      label: 'Top',
      rowType: 'h0'
    },
    {
      metric: 1,
      label: '% Ad Recall / Seen Before',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 2,
      label: '% Correct Brand ID / Recall',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 3,
      label: '% Correct Brand ID (incl. Amazon)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 4,
      label: 'Breakthrough',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 5,
      label: 'Breakthrough (incl. Amazon)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 6,
      label: 'Ease of Branding (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 7,
      label: 'Lift in Brand Impression (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 8,
      label: 'Lift in Brand Consideration (Top 2 Box)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 9,
      label: '% Positive Actions / Intentions (NET)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 10,
      label: 'Shared the ad with others (via email, social media, etc.)',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 11,
      label: 'Visited the company’s website',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 12,
      label: 'Visited another website',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 13,
      label: 'Looked up more information about the company',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 14,
      label: 'Looked up more information about similar services',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 15,
      label: 'Called the company for more information',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 16,
      label: 'Discussed the company with friends, relative, or associates',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 17,
      label: 'Subscribed to the service',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 18,
      label: 'Made a purchase',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 19,
      label: 'Subscribed/Made a purchase',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 20,
      label: 'Considered using this company for a free trial',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 21,
      label: 'Signed up for a free trial',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 22,
      label: 'Talked positively about the ad',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 23,
      label: 'Talked negatively about the ad',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: true
    },
    {
      metric: 24,
      label: 'A5. If the logo and product were taken out of this ad, how easily could you tell it was for [pipe A3]?',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 25,
      label: 'A7a. Has this ad affected your likelihood to consider products or services like this one?',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 26,
      label: 'A13/14. None of the above',
      rowType: 'd0',
      dataFormat: '%,0',
      reverseStat: false
    },
    {
      metric: 28,
      label: 'Likeability',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Strongest Drivers',
      rowType: 'h0'
    },
    {
      metric: 29,
      label: 'Worth seeing/hearing again ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 30,
      label: 'Makes me think brand is worth the price ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 31,
      label: 'Spoke directly to me ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Brand Value',
      rowType: 'h0'
    },
    {
      metric: 33,
      label: 'Makes me think brand has best selection of content ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },

    {
      metric: 32,
      label: 'Makes me think brand has best quality content ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 34,
      label: 'Portrays brand I want to be associated with ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 38,
      label: 'Credible message coming from this brand ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Cognitive Connection',
      rowType: 'h0'
    },
    {
      metric: 37,
      label: 'Informative ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 35,
      label: 'Makes me think about brand in a new way ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 39,
      label: 'Clear',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Engagement',
      rowType: 'h0'
    },
    {
      metric: 47,
      label: 'Unique ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 46,
      label: 'Clever',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 45,
      label: 'Entertaining',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 48,
      label: 'Humorous',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Emotional Connection',
      rowType: 'h0'
    },
    {
      metric: 44,
      label: 'Heartwarming',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 41,
      label: 'Inspiring',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 42,
      label: 'Reassuring ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 40,
      label: 'Meaningful',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: 'Relevance',
      rowType: 'h0'
    },
    {
      metric: 43,
      label: 'Shows people and situations I can relate to ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 36,
      label: 'Directly addresses issue that’s important to me ',
      rowType: 'd0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      label: '',
      rowType: 'eol'
    }
  ],


}
