import React from 'react'
import axios from 'axios'

import ReactGA from 'react-ga'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'

import Excel from 'exceljs/dist/exceljs'
import { rowColToAdr, mergeCellsHorizontal } from '../../../utils'
import { format } from '../../../../../new_utils'
import { ExportButton } from '../../../../'
const dt = 'application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet'

class ScorecardExport extends React.PureComponent {
  state = {
    done: true,
    error: false,
    imageBuffer: null,
    headerLength: 0
  }

  startExport = () => {
    const {
      config: { sheetHeaders },
      gridData: { normHeaders, perksList }
    } = this.props

    this.setState({
      done: false
    })

    this.fetchImages()
      .then(images => {
        const hasPerks = sheetHeaders.find(header => header.field === 'perks')

        const headerLength = sheetHeaders.length + normHeaders.header_metrics.length + 1
        this.setState({
          imageBuffer: images.data,
          headerLength: hasPerks ? headerLength + perksList.length - 1 : headerLength
        })
        ReactGA.event({
          category: 'Scorecard',
          action: 'Data Export'
        })
      })

      .catch(error => {
        this.setState({
          error,
          done: true,
          imageBuffer: null
        })
      })
  }

  fetchImages = () => {
    const {
      gridData: { adHeaders }
    } = this.props
    const imageRoute = `/thumbnails`
    const imageUrls = adHeaders.map(ad => ad.links.thumbnail)

    return axios.post(imageRoute, { params: { urls: imageUrls } })
  }

  createWorksheet = () => {
    const {
      config: { columnWidths },
      gridData: { adHeaders }
    } = this.props

    const workbook = new Excel.Workbook()
    const worksheet = workbook.addWorksheet('Scorecard')

    const { headerLength } = this.state 

    worksheet.views = [
      {
        activeCell: 'Z1000',
        state: 'frozen',
        xSplit: 2,
        ySplit: headerLength,
        showGridLines: false
      }
    ]

    worksheet.columns = [
      {
        header: '',
        key: 'metricsLabels',
        width: columnWidths.metricsLabels,
        style: {
          font: {
            name: 'Arial'
          }
        }
      },
      {
        header: '',
        key: 'norm',
        width: columnWidths.norm,
        style: {
          font: {
            name: 'Arial'
          }
        }
      },
      ...adHeaders.map(adHeader => {
        return {
          header: '',
          key: adHeader.ad_code,
          width: columnWidths.metrics,
          style: {
            font: {
              name: 'Arial'
            }
          }
        }
      })
    ]

    return {
      workbook,
      worksheet
    }
  }

  generateHeaders = (workbook, worksheet) => {
    const {
      config: { defaultRowHeight, defaultFontSize, defaultAlignment, sheetHeaders },
      gridData: { adHeaders, normHeaders, perksList }
    } = this.props

    const { imageBuffer } = this.state

    sheetHeaders.forEach(sheetHeader => {
      const { field, metricsLabel, normLabel, rowHeight, font, alignment, format } = sheetHeader

      if (field === 'thumbnail') {
        const row = worksheet.lastRow
        const rowIndex = row._number
        let cell = row.getCell(1)
        cell.value = metricsLabel
        cell.font = font || { size: defaultFontSize }

        cell = row.getCell(2)
        cell.value = normLabel
        cell.font = font || { size: defaultFontSize }
        cell.alignment = { vertical: 'bottom', horizontal: alignment || 'left' }

        imageBuffer.thumbnails.forEach((thumbnail, colIdx) => {
          for (const adCode in thumbnail) {
            const image = thumbnail[adCode]
            if (image.match('data:application') || image.match('data:image')) {
              const imageId = workbook.addImage({
                base64: image,
                extension: 'jpeg'
              })

              worksheet.addImage(imageId, {
                tl: { col: colIdx + 2, row: rowIndex },
                br: { col: colIdx + 3, row: rowIndex + 1 }
              })
              worksheet.getRow(rowIndex + 1).height = rowHeight
            }
          }
        })

        // const row = worksheet.lastRow
        // let cell = row.getCell(1)
        // cell.value = metricsLabel
        // cell.font = font || { size: defaultFontSize }

        // cell = row.getCell(2)

        // cell.value = normLabel
        // cell.font = font || { size: defaultFontSize }
        // cell.alignment = { vertical: 'bottom', horizontal: alignment || 'left' }
        // worksheet.addRow(row)
        // row.commit()
      } else if (field === 'perks') {
        perksList.forEach(perk => {
          const rowData = {
            metricsLabels: perk,
            norm: '',
            ...adHeaders.reduce((obj, adHeader) => {
              obj[adHeader.ad_code] = adHeader.perks[perk] || ''
              return obj
            }, {})
          }
          // const rowIndex = worksheet.lastRow._number === 1 ? worksheet.lastRow._number : worksheet.lastRow._number + 1
          // const row = worksheet.getRow(rowIndex)
          worksheet.addRow(rowData)
          const row = worksheet.lastRow
          row.alignment = {
            vertical: 'bottom',
            horizontal: defaultAlignment //sheetHeaders[rowIndex].alignment || defaultAlignment
          }
          row.values = rowData
          row.height = rowHeight || defaultRowHeight
          row.font = font || { size: defaultFontSize }
          row.numFmt = format || null

          row.getCell(1).alignment = {
            vertical: 'bottom',
            horizontal: defaultAlignment
          }
          row.commit()
          // worksheet.addRow()
        })
      } else {
        const rowData = {
          metricsLabels: metricsLabel || '',
          norm: normLabel || normHeaders[field] || '',
          ...adHeaders.reduce((obj, adHeader) => {
            obj[adHeader.ad_code] = field === 'brand' ? adHeader[field].label : adHeader[field]
            return obj
          }, {})
        }
        // const rowIndex = worksheet.lastRow._number === 1 ? worksheet.lastRow._number : worksheet.lastRow._number + 1
        // const row = worksheet.getRow(rowIndex)

        worksheet.addRow(rowData)
        const row = worksheet.lastRow
        row.alignment = {
          vertical: 'bottom',
          horizontal: defaultAlignment // sheetHeaders[rowIndex].alignment || defaultAlignment
        }

        // row.values = rowData
        row.height = rowHeight || defaultRowHeight
        row.font = font || { size: defaultFontSize }
        row.numFmt = format || null

        // worksheet.getCell(rowColToAdr(rowIndex, 1)).alignment = {
        //   vertical: 'bottom',
        //   horizontal: defaultAlignment
        // }
        row.getCell(1).alignment = {
          vertical: 'bottom',
          horizontal: defaultAlignment
        }
        row.commit()
        // worksheet.addRow()
      }
    })

    normHeaders.header_metrics.forEach((headerMetric, index) => {
      const rowData = {
        metricsLabels: headerMetric.label,
        norm: isNaN(headerMetric.value) ? 'N/A' : +headerMetric.value,
        ...adHeaders.reduce((obj, adHeader) => {
          obj[adHeader.ad_code] = isNaN(adHeader.header_metrics[index].value)
            ? 'N/A'
            : +adHeader.header_metrics[index].value
          return obj
        }, {})
      }
      worksheet.addRow(rowData)
      const row = worksheet.lastRow
      row.alignment = {
        vertical: 'bottom',
        horizontal: defaultAlignment
      }
      row.font = {
        bold: true,
        size: 13
      }

      row.eachCell((cell, columnIndex) => {
        cell.numFmt = '#,##'
        const color = columnIndex - 3 >= 0 && adHeaders[columnIndex - 3].header_metrics[index].statTest.color
        if (color) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: `FF${color.replace('#', '')}`
            }
          }
        }
      })

      row.commit()
    })
  }

  generateBody = worksheet => {
    const {
      gridData: { metricsLabels, metrics, norms, adHeaders } } = this.props

    const metricsLabelsArray = Object.keys(metricsLabels).reduce((array, key) => {
      array.push(metricsLabels[key])
      return array
    }, [])

    metricsLabelsArray.forEach((metricLabel, metricIndex) => {
      const isIndex = metricLabel.dataFormat === 'idx'
      const isText = metricLabel.dataFormat && metricLabel.dataFormat.match(/^s/i)
      const rowData = {
        metricsLabels: metricLabel.label,
        norm: isIndex ? 100 : norms[metricIndex].avg || '',
        ...metrics.reduce((obj, adMetric, adIndex) => {
          if (isIndex) {
            obj[adHeaders[adIndex].ad_code] = isNaN(adMetric[metricIndex].avg)
              ? ''
              : (adMetric[metricIndex].avg / norms[metricIndex].avg) * 100
          } else if (isText) {
            obj[adHeaders[adIndex].ad_code] = adMetric[metricIndex].display
          } else {
            obj[adHeaders[adIndex].ad_code] = isNaN(adMetric[metricIndex].avg) ? '' : adMetric[metricIndex].avg
          }

          return obj
        }, {})
      }
      // const row = worksheet.getRow(metricIndex + sheetHeaders.length + normHeaders.header_metrics.length + 1)
      // const rowIndex = worksheet.lastRow._number + 1
      const rowIndex = worksheet.lastRow._number === 1 ? worksheet.lastRow._number : worksheet.lastRow._number + 1
      const row = worksheet.getRow(rowIndex)
      row.values = rowData
      row.commit()
    })
  }

  styleBody = worksheet => {
    const {
      gridData: { metricsLabels, metrics },
      config: { columnFormats, styles, defaultAlignment, defaultRowHeight },
      config
    } = this.props

    const styleRow = ({ metricLabel, rowIndex, statColors }) => {
      let row = worksheet.getRow(rowIndex)
      const { rowType, dataFormat } = metricLabel
      const style = styles[rowType]
      const font = style.format && style.format.font
      switch (style.type) {
        case 'header':
          row.height = style.height || defaultRowHeight
          row.eachCell((cell, colIndex) => {
            const fgColor =
              colIndex === 2 &&
              (style.format && !style.format.norm) &&
              columnFormats &&
              columnFormats.norm &&
              columnFormats.norm.fill
                ? columnFormats.norm.fill.replace('#', 'FF').toLowerCase()
                : style.format && style.format.fill && style.format.fill.replace('#', 'FF').toLowerCase()

            cell.font = {
              ...font,
              color: {
                argb: style.format && style.format.color && style.format.color.replace('#', 'FF')
              }
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: fgColor
              }
            }
          })
          row.commit()
          break
        case 'metric':
          row.height = style.height || defaultRowHeight
          row.eachCell((cell, colIndex) => {
            if (colIndex === 1) {
              cell.value = `${style.offset ? style.offset : ''}${cell.value}`
              cell.fill = style.format
                ? {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                      argb: style.format.fill && style.format.fill.replace('#', 'FF').toLowerCase()
                    }
                  }
                : null
            } else if (colIndex === 2) {
              cell.numFmt = format.dataFormatToCellFormat(dataFormat)
              const fgColor =
                (style.format && style.format.fill && style.format.fill.replace('#', 'FF').toLowerCase()) ||
                (columnFormats && columnFormats.norm && columnFormats.norm.fill.replace('#', 'FF').toLowerCase())

              cell.fill = fgColor
                ? {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                      argb: fgColor
                    }
                  }
                : null

              cell.alignment = {
                vertical: 'bottom',
                horizontal: defaultAlignment
              }
            } else {
              cell.numFmt = format.dataFormatToCellFormat(dataFormat)
              const border = style.format &&
                style.format.border && {
                  style: style.format.border.style,
                  color: {
                    argb: style.format.border.color && style.format.border.color.replace('#', 'FF').toLowerCase()
                  }
                }
              cell.alignment = {
                vertical: 'bottom',
                horizontal: defaultAlignment
              }
              cell.border = border
                ? {
                    top: border,
                    bottom: border,
                    left: border,
                    right: border
                  }
                : null
              if (statColors[colIndex - 3].color) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: {
                    argb: `${statColors[colIndex - 3].color.replace('#', 'FF')}`
                  }
                }
              }
              if (statColors[colIndex - 3].textColor) {
                cell.font = {
                  ...cell.font,
                  color: {
                    argb: `${statColors[colIndex - 3].textColor.replace('#', 'FF')}`
                  }
                }
              }
            }
          })
          row.commit()
          break
        case 'text':
          row.eachCell((cell, colIndex) => {
            if (colIndex > 2) {
              if (statColors[colIndex - 3] && statColors[colIndex - 3].color) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: {
                    argb: `${statColors[colIndex - 3].color.replace('#', 'FF')}`
                  }
                }
              }
              cell.alignment = {
                vertical: 'bottom',
                horizontal: defaultAlignment
              }
            }
          })

          row.commit()
          break
        default:
          break
      }
    }

    const { headerLength } = this.state
    metricsLabels.filter(metricLabel => metricLabel.rowType !== 'hidden').forEach((metricLabel, metricIndex) => {

      styleRow({
        metricLabel,
        rowIndex: metricIndex + headerLength + 1, // metricIndex + sheetHeaders.length + normHeaders.header_metrics.length + 1,
        statColors: metrics.map(ad => ad[metricIndex].statTest || null),
        config
      })
    })
  }

  generateLegend = worksheet => {
    const {
      adSets,
      filter,
      adType,
      statTesting,
      adsMap,
      selections: { ad_type },
      config: { includeNormInLegend },
      genericConfig
    } = this.props

    const staticFilters = Object.keys(filter.staticFilters).reduce((array, f) => {
      array.push(filter.staticFilters[f])
      return array
    }, [])

    const width = 4
    const columnIndex = 3

    let rowIndex = worksheet.rowCount + 2
    let cell;
    cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex, width)
    cell.value = 'Export Date'
    cell.font = {
      name: 'Arial',
      size: 12,
      bold: true
    }
    cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex, width)
    cell.font = {
      name: 'Arial'
    }
    cell.value = Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(new Date(Date.now()))

    rowIndex += 1
    cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex, width)
    cell.value = 'Stat Testing'
    cell.font = {
      name: 'Arial',
      size: 12,
      bold: true
    }
    statTesting.forEach(stat => {
      let cell = mergeCellsHorizontal(worksheet, rowIndex, columnIndex, width)
      cell.value = stat.low.text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: stat.low.color.slice(1) }
      }
      cell = mergeCellsHorizontal(worksheet, statTesting.length + rowIndex++, columnIndex, width)
      cell.value = stat.high.text
      cell.font = {
        name: 'Arial'
      }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: stat.high.color.slice(1) }
      }
    })

    rowIndex += 1 + statTesting.length
    cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex, width)
    cell.value = ad_type.label
    cell.font = {
      name: 'Arial',
      size: 12,
      bold: true
    }
    cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex, width)
    cell.value = adType.display

    // rowIndex += 1
    // cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex, width)
    // cell.value = 'Ad Set Periods'
    // cell.font = {
    //   name: 'Arial',
    //   size: 12,
    //   bold: true
    // }
    // cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex, width)
    // cell.value = `Start Month: ${monthCodeToText(adSets.ad.start_month)}`
    // cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex, width)
    // cell.value = `End Month: ${monthCodeToText(adSets.ad.end_month)}`

    rowIndex += 1
    cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex, width)
    cell.value = 'Filters Applied'
    cell.font = {
      name: 'Arial',
      size: 12,
      bold: true
    }
    for (let filter in staticFilters) {
      const selections = staticFilters[filter].options
        .filter(option => option.selected)
        .map(option => option.label)
        .join(',')
      if (selections) {
        cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex, width)
        cell.value = `${staticFilters[filter].label}: ${selections}`
      }
    }

    if (includeNormInLegend !== false) {
      rowIndex += 1
      cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex, width)
      cell.value = 'Norm'
      cell.font = {
        name: 'Arial',
        size: 12,
        bold: true
      }

      cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex, width)
      cell.value = adSets.norm.label
      cell = worksheet.getCell(rowColToAdr(rowIndex, columnIndex))
      cell.value = `${genericConfig.MainHeader.baseText} Code`
      cell.font = {
        name: 'Arial',
        italic: true
      }
      cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex + 1, 5)
      cell.font = {
        name: 'Arial',
        italic: true
      }
      cell.value = `${genericConfig.MainHeader.baseText} Title`

      const excludeAds = adsMap.norm.zeroList.map(ad => ad.ad_code)
      adsMap.norm.list.filter(ad => !excludeAds.includes(ad.ad_code)).forEach(ad => {
        cell = worksheet.getCell(rowColToAdr(rowIndex, columnIndex))
        cell.value = `${ad.ad_code}`
        cell = mergeCellsHorizontal(worksheet, rowIndex++, columnIndex + 1, 5)
        cell.value = ad.title || 'N/A'
      })
    }
  }

  saveWorkbook = workbook => {
    workbook.xlsx.writeBuffer({ base64: true }).then(xls64 => {
      const blb = new Blob([xls64], { type: dt })
      const url = URL.createObjectURL(blb)
      const tag = document.createElement('a')
      tag.href = url
      tag.download = 'Scorecard.xlsx'
      document.body.appendChild(tag)
      tag.click()
      this.setState({
        done: true,
        error: false
      })
      setTimeout(function() {
        document.body.removeChild(tag)
        window.URL.revokeObjectURL(url)
      }, 0)
    })
  }

  exportFile = () => {
    const { workbook, worksheet } = this.createWorksheet()
    this.generateHeaders(workbook, worksheet)
    this.generateBody(worksheet)
    this.styleBody(worksheet)
    this.generateLegend(worksheet)
    this.saveWorkbook(workbook)
  }

  componentDidUpdate(prevProps, prevState) {
    const { imageBuffer } = this.state
    if (imageBuffer && imageBuffer !== prevState.imageBuffer) {
      this.exportFile()
    }
  }

  render() {
    const { disabled } = this.props
    const { done } = this.state
    return (
      <Tooltip title="Export scorecard to Excel" placement="top">
        <Grid item>
          <ExportButton type="Data" action={this.startExport} disabled={!done || disabled} />
        </Grid>
      </Tooltip>
    )
  }
}

export default ScorecardExport
