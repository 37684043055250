import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import TimeIcon from '@material-ui/icons/AccessTime'
import SampleSizeIcon from '@material-ui/icons/People'
import { ImageMenu } from '../../../../../../../../new_components'
import cn from 'classnames'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardScrollableColumn.ScorecardAdHeader(theme)
)

class ScorecardAdHeader extends React.PureComponent {
  state = {
    dropDownItems: []
  }

  componentDidMount() {
    const { openQuickview } = this.props
    const _dropDownItems = [
      {
        label: 'Open Media',
        handler: ad => this.openMedia(ad)
      },
      {
        label: 'Word Cloud',
        handler: ad => this.openWordcloud(ad)
      }
    ]
    const dropDownItems = openQuickview
      ? [
          ..._dropDownItems,
          {
            label: 'Quickview',
            handler: ad => this.openQuickview(ad)
          }
        ]
      : _dropDownItems

    this.setState({ dropDownItems })
  }

  openMedia = ad => {
    const { openMedia } = this.props
    openMedia(ad)
  }

  openWordcloud = ad => {
    const { openDetails } = this.props
    openDetails({ ad, selectedTab: 'wordcloud' })
  }

  openQuickview = ad => {
    const { openQuickview } = this.props
    openQuickview({ ad })
  }

  render() {
    const {
      classes,
      columnIndex,
      gridData: { adHeaders },
      collapse,
      collapsed,
      config
    } = this.props

    const { dropDownItems } = this.state
    const {
      ad_code,
      brand,
      links,
      title,
      length,
      n,
      ad_format,
      last_month_tested,
      header_metrics
    } = adHeaders[columnIndex]

    return (
      <Grid container direction="column" justify="center" alignItems="center" className={classes.container}>
        <Grid item className={cn(classes.brandImage)}>
          <img className={classes.brandLogo} src={links.brand} alt={brand} />
        </Grid>

        <Grid item className={cn(classes.thumbnail)}>
          <ImageMenu ad={adHeaders[columnIndex]} dropDownItems={dropDownItems} />
        </Grid>
        <div onClick={collapse} style={{ width: '100%' }}>
          <Grid item className={cn(classes.headerGrid)}>
            <Tooltip title={ad_code}>
              <Typography
                noWrap
                variant="caption"
                align="center"
                style={{ maxWidth: config.metricWidth }}
                display="block"
              >
                {ad_code}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item className={cn(classes.headerGrid)}>
            <Tooltip title={title}>
              <Typography
                noWrap
                variant="caption"
                align="center"
                style={{ maxWidth: config.metricWidth }}
                display="block"
              >
                {title}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item className={cn(classes.headerGrid)}>
            <Typography noWrap variant="caption" align="center" display="block">
              {last_month_tested}
            </Typography>
          </Grid>
          {collapsed ? null : (
            <Fragment>
              <Grid item className={cn(classes.headerGrid, classes.center)} display="block">
                <TimeIcon className={classes.icon} />
                <Typography noWrap variant="caption" align="center" style={{ display: 'inline' }} display="block">
                  {length}
                </Typography>
              </Grid>
              <Grid item className={cn(classes.headerGrid)}>
                <Typography noWrap variant="caption" align="center" display="block">
                  {ad_format}
                </Typography>
              </Grid>
              <Grid item className={cn(classes.headerGrid, classes.center)}>
                <SampleSizeIcon className={classes.icon} />
                <Typography noWrap variant="caption" align="center" style={{ display: 'inline' }} display="block">
                  {n}
                </Typography>
              </Grid>
            </Fragment>
          )}
          {header_metrics.map(header_metric => (
            <Grid item className={cn(classes.headerGrid)} key={header_metric.label}>
              <div className={classes.stat} style={{ backgroundColor: header_metric.statTest.color }}>
                <Typography
                  noWrap
                  variant="body2"
                  align="center"
                  className={cn(
                    header_metric.statTest.color ? classes.headerMetricValueInverseText : classes.headerMetricValueText
                  )}
                  display="block"
                >
                  {header_metric.avg}
                </Typography>
              </div>
            </Grid>
          ))}
        </div>
      </Grid>
    )
  }
}

export default Styled(ScorecardAdHeader)
