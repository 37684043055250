import template from '../../../../../../Template/config/components/scorecard/ScorecardView/ScorecardStatTesting'
export default {
  ...template,
  fixedThrTestThresholds: [ 90, 110],
  labels: [
    {
      high: {
        color: '#d8e4bc',
        // color: '#89C35F',
        text: 'Significantly above the norm at 90%'
      },
      low: {
        color: '#f2dcdb',
        // color: '#F9A23B',
        text: 'Significantly below the norm at 90%'
      }
    }
  ],
}
