import comcastV2 from "./ComcastV2"
import comcastPremarket from "./ComcastPremarket"
import comcastSMB from "./ComcastSMB"
import fidelityV2 from "./FidelityV2"
import discover from "./Discover"
import fordUS from "./FordUS"
import fordLincoln from "./FordLincoln"
import fordUSTier2 from "./FordUSTier2"
import fordUSGrowth from "./FordUSGrowth"
import fordLincolnGrowth from "./FordLincolnGrowth"
import fordCA from "./FordCA"
import fordAP from "./FordAP"
import fordEU from "./FordEU"
import fordMX from "./FordMX"
import nissan from "./Nissan"
import fordCALincoln from "./FordCALincoln"
import fordLegacy from "./FordLegacy"
// import autoDemo from "./Demo/Auto"
import autoDashboardDemo from "./Demo/AutoDashboard"
import investorServicesDemo from "./Demo/InvestorServices"
import audible from "./Audible"
// import telecomDemo from "./Demo/TelecomDemo"
// import adpiCategoryInsurance from "./CategoryInsurance"
import adpiCategoryQSR from "./CategoryQSR"
import adpiCategoryCovid from "./CategoryCovid"
import adpiCategoryTech from "./CategoryTech"
import adpiCategoryWireless from './CategoryWireless'
import adpiCategoryTentpoleEvents from "./CategoryTentpoleEvents"
import comcastSMBPremarket from "./ComcastSMBPremarket"
import comcastOffer from './ComcastOffer'
import square from './Square'
export default {
  roles: [ "user", "user+", "editor", "migrator", "manager"],
  subResourceRoles: ["user", "user+", "editor"],
  resources: [
    {
      groupName: "ford-us",
      config: fordUS,
      subResources: [{
        code: "nonEarlyAccess",
        label: "Non-Early Access"
      }]
    },
    {
      groupName: "ford-us-tier2",
      config: fordUSTier2,
    },
    {
      groupName: "ford-us-growth",
      config: fordUSGrowth,
    },
    {
      groupName: "ford-lincoln-growth",
      config: fordLincolnGrowth
    },
    {
      groupName: "ford-lincoln",
      config: fordLincoln
    },
    {
      groupName: "ford-ca",
      config: fordCA,
      subResources: [{
        code: "nonEarlyAccess",
        label: "Non-Early Access"
      }]
    },
    {
      groupName: "ford-ca-lincoln",
      config: fordCALincoln,
      subResources: [{
        code: "nonEarlyAccess",
        label: "Non-Early Access"
      }]
    },
    {
      groupName: "ford-ap",
      config: fordAP,
      subResources: [{
        code: "nonEarlyAccess",
        label: "Non-Early Access"
      }]
    },
    {
      groupName: "ford-eu",
      config: fordEU,
      subResources: [{
        code: "nonEarlyAccess",
        label: "Non-Early Access"
      }]
    },
    {
      groupName: "ford-mx",
      config: fordMX,
      subResources: [{
        code: "nonEarlyAccess",
        label: "Non-Early Access"
      }]
    },
    {
      groupName: "nissan",
      config: nissan,
    },
    {
      groupName: "ford-legacy",
      config: fordLegacy,
    },
    // {
    //   groupName: "auto-demo",
    //   config: autoDemo,
    // },
    {
      groupName: "discover",
      config: discover,
    },
    {
      groupName: "audible",
      config: audible,
    },
    {
      groupName: "fidelity",
      config: fidelityV2,
    },
    {
      groupName: "comcast",
      config: comcastV2,
      subResources: [{
        code: "comcast-tv",
        label: "TV"
      },
      {
        code: "comcast-online-video",
        label: "Online Video"
      }]
    },
    {
      groupName: "comcast-premarket",
      config: comcastPremarket,
    },
    {
      groupName: "comcast-smb",
      config: comcastSMB,
    },
    {
      groupName: "comcast-offer",
      config: comcastOffer,
    },
    {
      groupName: "investor-services-demo",
      config: investorServicesDemo,
    },
    // {
    //   groupName: "telecom-demo",
    //   config: telecomDemo,
    // },
    {
      groupName: "auto-dashboard-demo",
      config: autoDashboardDemo,
    },
    // {
    //   groupName: "adpi-category-insurance",
    //   config: adpiCategoryInsurance,
    // },
    {
      groupName: "adpi-category-qsr",
      config: adpiCategoryQSR
    },
    {
      groupName: "adpi-category-covid",
      config: adpiCategoryCovid
    },
    {
      groupName: "adpi-category-tech",
      config: adpiCategoryTech
    },
    {
      groupName: "adpi-category-wireless",
      config: adpiCategoryWireless
    },
    {
      groupName: "adpi-category-tentpole-events",
      config: adpiCategoryTentpoleEvents,
      subResources: [{
        code: "superbowl",
        label: "Superbowl LV"
      },
      {
        code: "superbowl_56",
        label: "Superbowl LVI"
      }]
    },
    {
      groupName: "comcast-smb-premarket",
      config: comcastSMBPremarket
    },
    {
      groupName: 'square',
      config: square
    }
  ]
}
