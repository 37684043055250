import generic from './generic'
import AdminConfigManager from './AdminConfigManager'
import AdminMetadataManager from './AdminMetadataManager'
import AdminUserManager from './AdminUserManager'
export default Object.assign(
  theme => {
    return {
      
    }
  },
  {
    generic,
    AdminConfigManager,
    AdminMetadataManager,
    AdminUserManager
  }
)
