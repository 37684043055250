import generateUUID from './generateUUID'
import format from './format'
import getStatTest from './statTesting'
import camelize from './camelize'
import snakeToTitle from './snakeToTitle'
import colors from './colors'
import hasArray from './hasArray'

const adRange = ad => {
  return {
    start_month: ad.months[0].month_code,
    end_month: ad.months[ad.months.length - 1].month_code
  }
}

const adSetRange = (adSet, limit_month=9999) => {
  const {start_month, end_month} = adSet.reduce(
    (range, ad) => {
      const { start_month, end_month } = adRange( ad )
      return {
        start_month: Math.min(range.start_month, start_month),
        end_month: Math.max( Math.min(range.end_month, limit_month), end_month)
      }
    },
    { start_month: 9999, end_month: 0 }
  )

  return  {
    start_month,
    end_month: limit_month ? Math.min( end_month, limit_month) : end_month
  }
}

const monthCodeToText = (() => {
  const fullMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  return (month_code, fullMonth = false) =>
    `${(fullMonth ? fullMonths : months)[month_code % 12]} ${2000 +
      Math.floor(month_code / 12)}`
})()

const compare = {
  ad_code: (a, b) => (a.ad_code > b.ad_code ? 1 : -1),
  brand: (a,b) => {
    const brand_a = a.brand.code.replace(/_/g, '')
    const brand_b = b.brand.code.replace(/_/g, '')
    return brand_a > brand_b ? 1 :
      brand_a < brand_b ? -1 :
      a.ad_code > b.ad_code ? 1 : -1
  }
}

const sortByMetric = (a, b, m) => {
  if (m.value < 0 || !m.value) return compare[m.field](a,b)
  if (!a.metrics[m.value] && b.metrics[m.value]) return 1
  if (!b.metrics[m.value] && a.metrics[m.value]) return -1
  if (!a.metrics[m.value] && !b.metrics[m.value]) return a.ad_code > b.ad_code ? 1 : -1
  return a.metrics[m.value].avg > b.metrics[m.value].avg ? -1 : 1
}

export const colToAdr = col => {
  const recurse = (n, r) => {
    if (n === 0) return r
    const rem = ((n - 1) % 26) + 1
    r = String.fromCharCode(rem + 64) + r
    return recurse((n - rem) / 26, r)
  }

  return recurse(col, '')
}

const rowColToAdr = (row, col) => {
  return `${colToAdr(col)}${row}`
}

const mergeCellsHorizontal = (sheet, row, col, numCells) => {
  const start = rowColToAdr(row, col)
  const end = rowColToAdr(row, col + numCells - 1)
  sheet.mergeCells(start, end)
  return sheet.getCell(start)
}

export {
  generateUUID,
  format,
  getStatTest,
  sortByMetric,
  compare,
  monthCodeToText,
  adRange,
  adSetRange,
  camelize,
  snakeToTitle,
  colors,
  rowColToAdr,
  mergeCellsHorizontal,
  hasArray
}
