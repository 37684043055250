import FordLogo from './FordLogo'
import LincolnLogo from './LincolnLogo'
import NissanLogo from './NissanLogo'
export default {
  'ford-us': FordLogo,
  'ford-us-growth': FordLogo,
  'ford-lincoln': LincolnLogo,
  'ford-lincoln-growth': LincolnLogo,
  'ford-ca': FordLogo,
  'ford-ca-lincoln': LincolnLogo,
  'nissan': NissanLogo,
  'auto-demo': null,
}
