import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const Styled = withStyles(theme => {
  return {
    ...theme.components.generic.ImageMenu(theme),
    thumbnail: {
      ...theme.components.generic.ImageMenu(theme).thumbnail,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      display: 'inline-block'
    }
  }
})

class ImageMenu extends React.Component {
  state = {
    anchorEl: null
  }

  componentDidCatch(err) {
    console.log('ImageMenu Error - ', err)
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const {
      classes,
      ad,
      ad: { links },
      dropDownItems
    } = this.props
    const { anchorEl } = this.state

    return (
      <div>
        <div
          className={classes.thumbnail}
          style={{
            cursor: 'pointer',
            backgroundImage: `url(${links.thumbnail})`
          }}
          onClick={this.handleClick}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className={classes.dropDown}
        >
          {dropDownItems.map(item => {
            return (
              <MenuItem
                onClick={ e  => {
                  e.preventDefault()
                  item.handler(ad)
                  this.handleClose()
                }}
                key={`${item.ad_code}${item.label}`}
              >
                {item.label}
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    )
  }
}

ImageMenu.props = {
  ad: PropTypes.shape({
    adCode: PropTypes.string.isRequired,
    links: PropTypes.shape({
      thumb: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  viewerOpen: PropTypes.func.isRequired
}

export default Styled(ImageMenu)
