export default theme => {
  return {
    base: {
      width: 150,
      '&:hover': {
        cursor: 'pointer'
      },
      // height: 200
    },
    item: {
      borderRadius: 10
    },
    button: {
      height: 120
    },
    label: {
      fontSize: 14
    },
    typeHover: {
      color: `${theme.palette.grey[800]} !important`,
      '&:hover': {
        cursor: 'pointer'
      }
    },
    type: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: theme.palette.grey[500],
        cursor: 'pointer'
      }
    },
    activeType: {
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        cursor: 'inherit'
      }
    }
  }
}
