import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core'
import cn from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
const Styled = withStyles(theme => theme.components.generic.UserMessage(theme))

const icons = {
  error: ErrorIcon,
  warning: WarningIcon,
  info: InfoIcon
}

const anchor = {
  error: {
    vertical: 'top',
    horizontal: 'center'
  },
  warning: {
    vertical: 'top',
    horizontal: 'center'
  },
  info: {
    vertical: 'top',
    horizontal: 'center'
  }
}
class UserMessage extends React.PureComponent {
  componentDidCatch(err) {
    console.log('UserMessage Error - ', err)
  }

  handleClose = (event, reason) => {
    const { close } = this.props
    if (reason === 'clickaway') {
      return
    }
    close()
  }

  render() {
    const { classes, message, close, ...other } = this.props
    const Icon = icons[message.type || 'info']
    return (
      <Snackbar
        open={!!message.text}
        anchorOrigin={anchor[message.type]}
        className={classes.snackbar}
        onClose={this.handleClose}
      >
        <SnackbarContent
          className={cn(classes[message.type])}
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={cn(classes.icon)} />
              {message.text}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          ]}
          {...other}
        />
      </Snackbar>
    )
  }
}

export default Styled(UserMessage)
