import React, { PureComponent } from 'react'
import qs from 'qs'
import axios from 'axios'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { Tooltip } from '@material-ui/core'

const styles = theme => ({
  disabled: {
    color: 'rgba(0,0,0,0.25)'
  },
  enabled: {
    color: 'rgba(0,0,0,0.87)'
  }
})

class ReportButton extends PureComponent {
  getSessionStatus = () => {
    return axios
      .post('media/sessionCheck')
      .then(res => {
        this.setState({
          disabled: res.status === 200 ? true : false
        })
        return res.status === 200
      })
      .catch(() => {
        this.setState({
          disabled: true
        })
      })
  }

  getQueryString = () => {
    const { groupName, monthText, staticFile } = this.props
    const { session, idtoken } = axios.defaults.headers.common

    const filename = staticFile
      ? staticFile.filename
      : `report_${groupName}_${monthText.toLowerCase().replace(' ', '_')}.zip`

    return qs.stringify(
      { groupName, filename, session, idtoken },
      { indices: false, arrayFormat: 'brackets' }
    )
  }

  getReportURL = () => {
    const { endPoint } = this.props

    if (process.env.NODE_ENV === 'development') {
      return `http://localhost:4000/${endPoint}?${this.getQueryString()}`
    }

    return `/${endPoint}?${this.getQueryString()}`
  }

  render() {
    const { classes, report, staticFile } = this.props
    const tooltipTitle = staticFile ?
    `Download ${staticFile.label}` :
    report
      ? 'Download monthly report'
      : 'Monthly report is not available'
    const enabled = report || staticFile
    return (
      <Tooltip placement="bottom" title={tooltipTitle}>
        <div>
          <Button variant="outlined" color="secondary" disabled={!enabled}>
            <a
              href={this.getReportURL()}
              style={{ textDecoration: 'none', color: 'inherit' }}
              className={cn(enabled ? classes.enabled : classes.disabled)}
              download
            >
              Export {staticFile ? staticFile.label : 'Report'}
            </a>
          </Button>
        </div>
      </Tooltip>
    )
  }
}

ReportButton.propTypes = {
//  endPoint: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ReportButton)
