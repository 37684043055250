import React from 'react'
import axios from 'axios'

import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import Excel from 'exceljs/dist/exceljs'

const dt =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet'

class ExportMetadata extends React.PureComponent {

  workbook = null
  thumbnails = {}

  state = {
    done: true,
    error: false
  }

  fetchImages = () => {
    const {
      adsList
    } = this.props
    const imageRoute = `/thumbnails`
    const imageUrls = adsList.map(ad => ad.links.thumbnail)

    return axios.post(imageRoute, { params: { urls: imageUrls } })
      .then(images => this.thumbnails = images.data && images.data.thumbnails.reduce((obj, imageData) => {
        const ad_code = Object.keys(imageData)[0]
        obj[ad_code] = imageData[ad_code]
        return obj
      }, {}))
  }

  startExport = () => {
    this.fetchImages()
      .then(this.exportFile)
      .catch(error => {
        this.setState({
          error,
          done: true
        })
      })
  }

  addWorksheet = (tagsConfig, slices) => {
    const { adsList, slicerConfig } = this.props

    const selectedAds = adsList.filter(ad => slices.reduce((include, slice) => {
      const field = Object.keys(slice)[0]
      return (include && slice[field] === '***') || (ad[field] === slice[field])
    }, true))

    const label = slices.map(slice => slice[Object.keys(slice)[0]]).reduce((string, slice, index) => slice === '***' ? string : `${string}${string.length ? '_' : ''}${slicerConfig[index].map[slice].display}`, '')
    const worksheetName = `Ad Tags${label.length ? ` (${label})` : ''}`


    const columns = [
      {
        header: 'Ad Code',
        key: 'ad_code',
        width: 18,
        alignment: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      },
      {
        header: 'Brand',
        key: 'brand',
        width: 20,
        alignment: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      },
      {
        header: 'Ad Type',
        key: 'ad_type',
        width: 20,
        alignment: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      },
      {
        header: 'Title',
        key: 'title',
        width: 40,
        alignment: {
          vertical: 'bottom',
          horizontal: 'left'
        }
      },
      {
        header: 'Thumbnail',
        key: 'thumbnail',
        width: 15,
        alignment: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      }]

    let fgColor = 'FFFFFFFF'
    const tagColumns = tagsConfig.map((tag, index) => {
      if (tag.children.length) {
        fgColor = (fgColor === 'FFFFFFFF' ? 'FFEEEEEE' : 'FFFFFFFF')
      }
      return {
        header: tag.label,
        key: tag.id,
        width: Math.max(Math.floor(tag.label.length * 0.95), 10),
        fgColor,
        crap: fgColor,
        // style: {
        //   fill: {
        //     type: 'pattern',
        //     pattern: 'solid',
        //     fgColor: {
        //       argb: fgColor
        //     }
        //   }
        // },
        alignment: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      }
    })

    const tagsTooltips = tagsConfig.reduce((obj, tag) => { obj[tag.id] = tag.tooltip; return obj }, {})

    const worksheet = this.workbook.addWorksheet(worksheetName.replace('/', '-'))

    worksheet.views = [
      {
        activeCell: 'Z1000',
        state: 'frozen',
        xSplit: columns.length,
        ySplit: 1,
        showGridLines: true
      }
    ]

    worksheet.columns = [...columns, ...tagColumns].map(column => { const { alignment, ...data } = column; return data })

    worksheet.columns.forEach((column, columnIndex) => {

      if (['ad_code'].includes(column.key)) {
        column.values = [column.header, '', ...selectedAds.map(ad => ad[column.key])]
      } else if (['brand'].includes(column.key)) {
        column.values = [column.header, '', ...selectedAds.map(ad => ad[column.key].label)]
      } else if (['title'].includes(column.key)) {
        column.values = [column.header, '', ...selectedAds.map(ad => ad[column.key])]
      } else if (['ad_type'].includes(column.key)) {
        column.values = [column.header, '', ...selectedAds.map(ad => Array.isArray(ad[column.key]) ? ad[column.key].join(', '): ad[column.key] )]
      }
      else if (column.key === 'thumbnail') {
        selectedAds.forEach((ad, rowIndex) => {
          const image = this.thumbnails[ad.ad_code]
          if (image && (image.match('data:application') || image.match('data:image'))) {
            const imageId = this.workbook.addImage({
              base64: image,
              extension: 'jpeg'
            })
            worksheet.addImage(imageId, {
              tl: { col: columnIndex, row: rowIndex + 2 },
              br: { col: columnIndex + 1, row: rowIndex + 3 }
            })
          }
          worksheet.getRow(rowIndex + 3).height = 50
        })
      } else {
        column.values = [column.header, tagsTooltips[column.key] || '', ...selectedAds.map(ad => ad.tags[column.key] ? '\u2713' : '')]
      }
    })

    worksheet.eachRow(row => {
      row.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      }
      if (row.number === 1) {
        row.height = 20
        row.font = {
          'bold': true,
          'size': 12
        }
      } else if (row.number === 2) {
        row.height = 50
        row.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true
        }
      }

      row.eachCell((cell, colNumber) => {
        cell.alignment = (columns[colNumber - 1] && columns[colNumber - 1].alignment) || {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true
        }
        const number = cell._column.number - columns.length
        if (number > 0) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: tagColumns[number - 1].fgColor
            }
          }
          cell.border = {
            top: { style: 'hair', color: { argb: 'FFAAAAAA' } },
            left: { style: 'hair', color: { argb: 'FFAAAAAA' } },
            bottom: { style: 'hair', color: { argb: 'FFAAAAAA' } },
            right: { style: 'hair', color: { argb: 'FFAAAAAA' } }
          };
        }
      })

      row.commit()
    })
  }

  saveWorkbook = () => {
    this.workbook.xlsx.writeBuffer({ base64: true }).then(xls64 => {
      const blb = new Blob([xls64], { type: dt })
      const url = URL.createObjectURL(blb)
      const tag = document.createElement('a')
      tag.href = url
      tag.download = 'Ad_Tags.xlsx'
      document.body.appendChild(tag)
      tag.click()
      this.setState({
        done: true,
        error: false,
        imageBuffer: null
      })
      setTimeout(function () {
        document.body.removeChild(tag)
        window.URL.revokeObjectURL(url)
      }, 0)
    })
  }

  iterate = (tagsConfig, slicerConfig, sliceLayer = 0, slices = []) => {
    return Object.keys(tagsConfig).forEach(
      sliceValue => {
        if (Array.isArray(tagsConfig[sliceValue])) {
          this.addWorksheet(tagsConfig[sliceValue], [...slices, { [slicerConfig[sliceLayer].field]: sliceValue }])
        } else {
          this.iterate(tagsConfig[sliceValue], slicerConfig, sliceLayer + 1, [...slices, { [slicerConfig[sliceLayer].field]: sliceValue }])
        }
      })
  }

  exportFile = () => {

    const { slicerConfig, tagStructureConfig } = this.props

    this.workbook = new Excel.Workbook()
    this.iterate(tagStructureConfig, slicerConfig)
    this.saveWorkbook()

  }

  render() {
    const { disabled, hasTags } = this.props
    const { done } = this.state
    return hasTags ? (
      <Tooltip title="Export ad tags to Excel" placement="top">
        <Grid item style={{ position: 'relative', right: 20 }}>
          <Grid item>
            <Button variant="outlined" color="secondary" onClick={this.startExport} disabled={!done || disabled} style={{ position: 'absolute', right: 20 }}>
              {`Export Tags`}
            </Button>
          </Grid>
        </Grid>
      </Tooltip>
    ) : null
  }

}

export default ExportMetadata
