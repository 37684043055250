export default theme => {
  return {
    container: {
      marginTop: 5
    },
    media: {
      boxShadow: theme.shadows[4],
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      display: 'inline-block',
      margin: 2,
      width: 55,
      height: 40,
      cursor: 'pointer'
    },
    headerRoot: {
      padding: '6px 16px'
    },
    bodyRoot: {
      padding: '0 16px'
    },
    activeType: {
      '&:hover': {
        backgroundColor: theme.palette.listSelectBackground.default
      }
    },
    boxShadow: {
      border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
      boxShadow: `0 1px 1px #E7E7E7`
    }
  }
}
