export default Object.assign(
  theme => {
    return {
      backgroundColor: theme.palette.grey[50],
      lineColor: theme.palette.grey[400],
      titleOffset: 10,
      titleFontSize: 17,
      titleFontStyle: 'bold',
      titleColor: theme.palette.grey[600],
      xOffset: 10,
      topTwoBoxWidthRatio: 0.6,
      topTwoBoxYMargin: 30,
      topTwoBoxHeight: 35,
      topTwoBoxTextFontSize: 12,
      topTwoBoxTextFontStyle: 'normal',
      topTwoBoxTextColor: theme.palette.grey[600],
      messageYMargin: 0,
      messageFontSize: 0,
      messageFontStyle: 'normal',
      messageColor: theme.palette.grey[600],
      lineStrokeWidth: 2,
      wordcloudYMargin: 20,
      wordcloudWidthFactor: 0.9,
      wordcloudHeightFactor: 0.9
    }
  },
  {
    Wordcloud: {}
  }
)
