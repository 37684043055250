import templateComponents from '../../../Template/theme/components'
import scorecard from './scorecard'
import quickview from './quickview'
// import generic from './generic'

export default {
    ...templateComponents,
    scorecard,
    quickview,
    // generic
}
