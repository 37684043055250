// Formats: '%,n', '$,n', '$x,n', 'f,n', 'i,n'
// operations: 'last(a)', prod(a,b), ratio(a,b)
export default {
  '***': {
    '***': [
      {
        metric: 11,
        inHeader: true,
        label: 'Branded OfferPi Score',
        rowType: 'demphasized',
        dataFormat: '.,0',
        reverseStat: false,
        minSampleSize: 0
      },
      {
        label: 'Branded Offer KPIs',
        rowType: 'h0'
      },
      {
        metric: 12,
        label: 'Appeal',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 13,
        label: 'Consideration (Likely to Switch)',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 14,
        label: 'Likely to Contact Provider to Learn More',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 15,
        label: 'Likely to Purchase',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        label: 'Other Specific Attribute Performance - Branded',
        rowType: 'h0'
      },
      {
        metric: 16,
        label: 'Better than Current Package',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 17,
        label: 'Fulfills a need',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 18,
        label: 'Good Value for the Money',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 19,
        label: 'Is Believable',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 20,
        label: 'Unique from other offers',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 1,
        inHeader: false,
        label: 'Unbranded OfferPi Score',
        rowType: 'demphasized',
        dataFormat: '.,0',
        reverseStat: false,
        minSampleSize: 0
      },
      {
        label: 'Other KPIs',
        rowType: 'hb0'
      },
      {
        label: 'Unbranded Offer KPIs',
        rowType: 'h0'
      },
      {
        metric: 2,
        label: 'Appeal',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 3,
        label: 'Consideration (Likely to Switch)',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 4,
        label: 'Likely to Contact Provider to Learn More',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 5,
        label: 'Likely to Purchase',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      // {
      //   label: 'Other Specific Attribute Performance',
      //   rowType: 'h0'
      // },
      // {
      //   metric: 6,
      //   label: 'Better than Current Package',
      //   rowType: 'd0',
      //   dataFormat: '%,1',
      //   reverseStat: false
      // },
      // {
      //   metric: 7,
      //   label: 'Fulfills a need',
      //   rowType: 'd0',
      //   dataFormat: '%,1',
      //   reverseStat: false
      // },
      // {
      //   metric: 8,
      //   label: 'Good Value for the Money',
      //   rowType: 'd0',
      //   dataFormat: '%,1',
      //   reverseStat: false
      // },
      // {
      //   metric: 9,
      //   label: 'Is Believable',
      //   rowType: 'd0',
      //   dataFormat: '%,1',
      //   reverseStat: false
      // },
      // {
      //   metric: 10,
      //   label: 'Unique from other offers',
      //   rowType: 'd0',
      //   dataFormat: '%,1',
      //   reverseStat: false
      // },
      // {
      //   label: 'Impact of Brand',
      //   rowType: 'hb0'
      // },

      {
        label: '',
        rowType: 'eol'
      }
    ]
  }
}
