import { generateUUID } from '../../../new_utils'
import {
  DASHBOARD_INIT,
  DASHBOARD_SELECT_METADATA_SLICE
} from '../../../new_actions'

const initialState = {
  slices: [],
  adsList: [],
  sliceSelections: []
}

const getFieldValues = (slice, adsList, selection) => {
  const fields = adsList.reduce((obj, ad, index) => {
    const fieldType = ad[slice.field]
    const fieldTypes = fieldType
      ? Array.isArray(fieldType)
        ? fieldType
        : [fieldType]
      : []
    fieldTypes.forEach((fieldType, idx) => {
      obj[fieldType] = {
        field: fieldType,
        ...slice.map[fieldType]
      }
    })
    return obj
  }, {})

  return Object.keys(fields)
    .sort((a, b) => (fields[a].sortOrder > fields[b].sortOrder ? 1 : -1))
    .reduce((array, field, index) => {
      array.push({
        ...fields[field],
        selected: selection && selection.field ? field === selection.field : index === 0
      })
      return array
    }, [])
}

const getSlices = (slicer, adsList, _sliceSelections) => {
  const { slices, sliceSelections } = slicer.reduce(
    (obj, slice, index) => {
      const values = getFieldValues(
        slice,
        obj.adsList,
        _sliceSelections[slice.field]
      )
      obj.slices[slice.field] = {
        ...slice,
        uuid: generateUUID(),
        values //: values.map( value => { return { ...value, field: ''+value.field}})
      }

      const selected = values.find(value => value.selected) || values[0]
      if (selected) {
        obj.sliceSelections[slice.field] = {
          label: slice.display,
          field: selected.field,
          display: selected.display
        }
        obj.adsList = obj.adsList.filter(ad => {
          const field = Array.isArray(ad[slice.field])
            ? ad[slice.field]
            : [ad[slice.field]]
          return field.includes(selected.field)
        })
      } else {
        obj.sliceSelections[slice.field] = {
          label: '',
          field: null,
          display: ''
        }
      }

      return obj
    },
    {
      adsList,
      sliceSelections: _sliceSelections,
      slices: {}
    }
  )
  return {
    slices: Object.keys(slices).reduce((array, key) => {
      array.push({
        field: key,
        ...slices[key]
      })
      return array
    }, []),
    sliceSelections
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_INIT:
      return {
        ...state,
        adsList: action.data.metadata.adsList, //.filter(ad => ad.target),
        ...getSlices(
          action.data.config.metadata.slices,
          action.data.metadata.adsList, //.filter(ad => ad.target),
          {}
        )
      }

    case DASHBOARD_SELECT_METADATA_SLICE:
      const { sliceIndex, fieldValue } = action.data
      const { adsList } = state

      const slices = state.slices.map((slice, index) => {
        if (index < sliceIndex) return slice
        return {
          ...slice,
          uuid: generateUUID(),
          values: slice.values.map(value => {
            return {
              ...value,
              selected: index > sliceIndex ? false : value.field === fieldValue
            }
          })
        }
      })
      const sliceSelections = slices.reduce((obj, slice) => {
        const selected = slice.values.find(value => value.selected)
        obj[slice.field] = selected
          ? {
              field: selected.field,
              display: selected.display
            }
          : {
              field: null,
              display: ''
            }

        return obj
      }, {})
      return {
        ...state,
        ...getSlices(slices, adsList, sliceSelections)
      }

    default:
      return state
  }
}
