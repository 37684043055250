import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Excel from 'exceljs/dist/exceljs'
import { monthCodeToText } from '../../../../new_utils'
import base64js from 'base64-js'
import TextEncoderLite from 'text-encoder-lite'

const xlsType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

function Base64Encode(str, encoding = 'utf-8') {
  var bytes = new (TextEncoder || TextEncoderLite)(encoding).encode(str)
  return base64js.fromByteArray(bytes)
}

function getDateRange(months) {
  return `${monthCodeToText(months[0].month_code)} - ${monthCodeToText(
    months[months.length - 1].month_code
  )}`
}

export default class ExportWordCloud extends Component {
  state = {
    hasErrored: false
  }

  addVerbatimSheet = (workbook, verbatims, question) => {

    const verbatimSheet = workbook.addWorksheet('Verbatims')

    verbatimSheet.columns = [...verbatims.verbatimColumns.map(column => {
      return {
        header: column.label,
        key: column.code,
        width: 30
      }
    }), 
    {
      header: 'Comment',
      key: 'comment',
      width: 300
    }
  ]

    for (let i = 0; i < verbatims.list.length; i++) {
      const row = { ...verbatims.list[i] }
      verbatimSheet.addRow(row).commit()
    }

    verbatimSheet.getRow(1).font = { size: 12, bold: true }

    verbatimSheet.columns.forEach((d, i) => {
      const col = i + 1

      if (col <= verbatimSheet.columns.length -1 ) {
        verbatimSheet.getColumn(col).eachCell((cell, rowNumber) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' }
        })
      }
    })
  }

  exportFile = () => {
    const { ad, controls, question, verbatims } = this.props

    const workbook = new Excel.Workbook()
    const wordCloudSheet = workbook.addWorksheet('Word Cloud')
    this.addVerbatimSheet(workbook, verbatims, question)

    for (let i = 0; i < 100; i++) {
      wordCloudSheet.getColumn(i + 1).width = 8
      wordCloudSheet.getRow(i + 11).height = 53
    }

    wordCloudSheet.views = [
      {
        state: 'normal',
        activeCell: 'Z1000',
        showGridLines: false
      }
    ]

    const svg = document.getElementById('word-cloud-svg')
    const w = +svg.getAttribute('width')
    const h = +svg.getAttribute('height')

    if (svg) {
      const text = new XMLSerializer().serializeToString(svg)

      const img = new Image()

      img.onload = function() {
        const canvas = document.createElement('canvas')
        canvas.width = w
        canvas.height = h

        document.body.appendChild(canvas)

        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, w, h)

        const imageId = workbook.addImage({
          base64: canvas.toDataURL(),
          extension: 'png'
        })

        wordCloudSheet.addImage(imageId, {
          tl: { col: 1, row: 10 },
          br: { col: 21, row: Math.ceil((20 * h) / w + 10) }
        })

        document.body.removeChild(canvas)

        const title = wordCloudSheet.getCell('A1')
        title.value = ad.title

        wordCloudSheet.getRow(1).font = { size: 20, bold: true }
        wordCloudSheet.getRow(1).height = 24

        const adCode = wordCloudSheet.getCell('A2')
        adCode.value = ad.ad_code

        wordCloudSheet.getRow(2).font = { size: 10, bold: false }
        wordCloudSheet.getRow(2).height = 16

        const dateRange = wordCloudSheet.getCell('A3')
        dateRange.value = getDateRange(ad.months)

        wordCloudSheet.getRow(3).font = { size: 10, bold: false }
        wordCloudSheet.getRow(3).height = 16

        controls.forEach((control, i) => {
          const { name } = control
          const disabled = control.key === 'scores' && question === 1
          const selectedLabel = !disabled && control.options
            .reduce((array, option) => {
              if (option.selected) array.push( option.label )
              return array
            }, [])
            .join(',')

          
            if (selectedLabel.length) {
            const cell = wordCloudSheet.getCell(`A${i + 5}`)
            cell.value = `${name}: ${selectedLabel}`

            wordCloudSheet.getRow(i + 5).font = { size: 10, bold: false }
            wordCloudSheet.getRow(i + 5).height = 16
          }
        })

        workbook.xlsx.writeBuffer({ base64: true }).then(xls64 => {
          const url = URL.createObjectURL(new Blob([xls64], { type: xlsType }))

          const tag = document.createElement('a')
          tag.href = url
          tag.download = 'WordCloud.xlsx'

          document.body.appendChild(tag)
          tag.click()

          setTimeout(function() {
            document.body.removeChild(tag)
            URL.revokeObjectURL(url)
          }, 0)
        })
      }

      img.onerror = err => {
        this.setState({
          hasErrored: true
        })
      }

      img.src = `data:image/svg+xml;base64,${Base64Encode(text)}`
    }
  }

  render() {
    const {
      state: { hasErrored }
    } = this

    return (
      <Button color="secondary" variant="contained" onClick={this.exportFile}>
        {hasErrored ? ' Error  ' : 'Download'}
      </Button>
    )
  }
}

// ExportWordCloud.propTypes = {
//   ad: PropTypes.shape({
//     adCode: PropTypes.string.isRequired,
//     title: PropTypes.string.isRequired,
//     months: PropTypes.array.isRequired
//   }).isRequired,
//   controls: PropTypes.arrayOf(
//     PropTypes.shape({
//       key: PropTypes.string.isRequired,
//       name: PropTypes.string.isRequired,
//       options: PropTypes.arrayOf(
//         PropTypes.shape({
//           label: PropTypes.string.isRequired,
//           value: PropTypes.number.isRequired,
//           selected: PropTypes.bool.isRequired
//         })
//       ).isRequired
//     })
//   ).isRequired,
//   question: PropTypes.number.isRequired,
//   verbatims: PropTypes.arrayOf(
//     PropTypes.shape({
//       customer: PropTypes.string.isRequired,
//       age: PropTypes.string.isRequired,
//       children: PropTypes.string.isRequired,
//       gender: PropTypes.string.isRequired,
//       value: PropTypes.string.isRequired,
//       comment: PropTypes.string.isRequired
//     })
//   ).isRequired
// }
