import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { Grid, Paper } from '@material-ui/core'
import { Tabs } from '../../'

const Styled = withStyles(theme => theme.components.adminView.generic.AdminTabs(theme))

class AdminTabs extends React.PureComponent {
  state = {
    error: null,
    maxHeight: 0
  }

  handleClose = () => {
    this.setState({ error: null })
  }

  updateHeight = () => {
    this.setState({
      maxHeight: window.innerHeight - 150
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateHeight)
    this.updateHeight()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight)
  }

  render() {
    const {
      classes,
      tabs,
      selectTab,
      selectedTab,
      environment,
      children
    } = this.props

    const {maxHeight} = this.state
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.container}
      >
        <Grid xs={12} className={classes[environment]} item>
            <Paper className={classes.paper} style={{maxHeight}}>
              <Tabs
                selectedTab={selectedTab}
                selectTab={selectTab}
                tabs={tabs}
                maxHeight={this.state.maxHeight}
              />
              {children}
            </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default Styled(AdminTabs)
