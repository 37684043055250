import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MenuControl from './MenuControl'

const styles = theme => ({
  dialog: {
    width: 400,
  },
  paper: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    marginTop: 10,
    marginBottom: 20,
  },
  grid: {
    margin: 0,
    padding: 0,
  },
  button: {
    margin: theme.spacing( 2 ),
  },
})

class WordCloudControls extends PureComponent {
  render() {
    const { classes, controls, disableAll, handleSelect, question } = this.props

    return (
      <Paper className={classes.paper} elevation={0} >
      {/* <Paper className={classes.paper} elevation={0} classes={{elevation0: classes.boxShadow}}> */}
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          justify="space-around"
        >
          {controls.map((d, i) => {
            const disabled = d.key === 'scores' && question === 1
            return (
              <MenuControl
                key={d.key}
                disabled={disableAll || disabled}
                controlId={d.key}
                name={d.name}
                options={d.options}
                handleChange={handleSelect}
                tip={d.tip}
                multiple={d.multiple}
              />
            )
          })}
        </Grid>
      </Paper>
    )
  }
}

// WordCloudControls.propTypes = {
//   classes: PropTypes.object.isRequired,
//   controls: PropTypes.arrayOf(
//     PropTypes.shape({
//       key: PropTypes.string.isRequired,
//       name: PropTypes.string.isRequired,
//       options: PropTypes.arrayOf(
//         PropTypes.shape({
//           label: PropTypes.string.isRequired,
//           value: PropTypes.number.isRequired,
//           selected: PropTypes.bool.isRequired,
//         }),
//       ).isRequired,
//     }),
//   ).isRequired,
//   disableAll: PropTypes.bool.isRequired,
//   handleSelect: PropTypes.func.isRequired,
// }

export default withStyles(styles)(WordCloudControls)
