export default {
  '***': {
    displayFilters: null,
    staticFilters: [
      {
        label: 'Age',
        code: 'age',
        multi: true,
        options: [
          {
            label: '18-34',
            value: 1,
            selected: false
          },
          {
            label: '35-54',
            value: 2,
            selected: false
          },
          {
            label: '55+',
            value: 3,
            selected: false
          }
        ]
      },
      {
        label: 'Gender',
        code: 'gender',
        multi: true,
        options: [
          {
            label: 'Male',
            value: 1,
            selected: false
          },
          {
            label: 'Female',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Income',
        code: 'income',
        multi: true,
        options: [
          {
            label: '$20-$49k',
            value: 1,
            selected: false
          },
          {
            label: '$50k-$99k',
            value: 2,
            selected: false
          },
          {
            label: '$100k+',
            value: 3,
            selected: false
          }
        ]
      },
      {
        label: 'Children < 18 in HH',
        code: 'children',
        multi: true,
        options: [
          {
            label: 'Yes',
            value: 1,
            selected: false
          },
          {
            label: 'No',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Ethnicity',
        code: 'ethnicity',
        multi: true,
        options: [
          {
            label: 'White or Caucasian (non-Hispanic)',
            value: 1,
            selected: false
          },
          {
            label: 'Hispanic, Latino, or Spanish (and Caucasian)',
            value: 2,
            selected: false
          },
          {
            label: 'Black or African American',
            value: 3,
            selected: false
          },
          {
            label: 'Asian or Pacific Islander',
            value: 4,
            selected: false
          },
          {
            label: 'Other',
            value: 5,
            selected: false
          }
        ]
      }
    ]
  }
}
