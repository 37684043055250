// TODO: quickview - Make sure to generate keys for the items in data[] using generateUUID in configuration
/* Config.config.rows.map( row => {
  return {
    ...row,
    data: row.data.map( item => {
      return {
        ...item,
        key: generateUUID()
      }
    })
  }
}) */

export default {
  enabled: false,
  headerHeight: 80,
  rowTitleHeight: 35,
  padding: 1,
  aspectRatio: 0.7727,
  externalHeaderHeight: 0,
  minContainerHeight: 800,
  rows: [
    {
      height: 0.33,
      title: 'IN-MARKET PERFORMANCE',
      data: [
        {
          content: [{
            component: 'MetricTable',
            data: {
              label: 'Response',
              metrics: [
                { metric: 3, isHeaderMetric: true },
                { metric: 7, isHeaderMetric: true, label: 'Start/Expand Relationship' },
                { metric: 5, isHeaderMetric: true, label: 'Consideration' },
                { metric: 4, isHeaderMetric: false, label: 'Impression' }
              ]
            }
          }],
          width: 0.5,
          key: null
        },
        {
          content: [{
            component: 'MetricTable',
            data: {
              label: 'Superior Product',
              metrics: [
                {
                  metric: 7,
                  isHeaderMetric: true,
                  label: 'Start/Expand Relationship'
                },
                { metric: 8, isHeaderMetric: false, label: 'Better Product/Service' },
                { metric: 12, isHeaderMetric: false, label: 'Company I Can Trust' },
                { metric: 10, isHeaderMetric: false, label: 'Good Value' },
                { metric: 9, isHeaderMetric: false, label: 'Provices Research & Tools' },
                { metric: 11, isHeaderMetric: false, label: 'Tells Me Something' },
              ]
            }
          }],
          width: 0.5,
          key: null
        }
      ]
    },
    {
      height: 0.67,
      title: 'OTHER PERFORMANCE',
      data: [
        {
          content: [{
            component: 'MetricTable',
            data: {
              label: 'Cognitive',
              metrics: [
                { metric: 16, isHeaderMetric: true },
                { metric: 20, isHeaderMetric: true },
                { metric: 17, isHeaderMetric: true },
                { metric: 18, isHeaderMetric: true }
              ]
            }
          }],
          width: 0.3,
          key: null
        },
        {
          content: [{
            component: 'MetricOpenend',
            data: {
              label: 'In Their Own Words',
              topBox: null,
              messageField: 'message',
              wordcloudConfig: {
                sentiments: [
                  { color: '#FF872F', description: 'Very Negative' },
                  { color: '#FFC397', description: 'Negative' },
                  { color: '#DAD9D6', description: 'Neutral' },
                  { color: '#C4E1AF', description: 'Positive' },
                  { color: '#89C35F', description: 'Very Positive' }
                ],
                scoreRange: [0, 7],
                font: 'Arial, Helvetica, sans-serif',
                showCounts: true,
                padding: 5,
                rotation: 0,
                wordCountCutoff: 2,
                wordLengthCutoff: 3,
                numDisplayed: 100
              }
            }
          }],
          width: 0.7,
          key: null
        }
      ]
    }
  ]
}
