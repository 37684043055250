import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { timeFormat } from 'd3-time-format'
import IconButton from '@material-ui/core/IconButton'
import ScorecardIcon from '@material-ui/icons/FormatIndentIncrease'
import DeleteIcon from '@material-ui/icons/Delete'

import BuildIcon from '@material-ui/icons/Build'
import { AdType } from '../../../'

const formatDate = d => {
  const date = new Date(d)
  const format = timeFormat('%m/%d/%Y')
  return format(date)
}
const Styled = withStyles(theme => theme.components.selection.PresetSelect.PresetGridView(theme))

class PresetGridView extends React.PureComponent {
  buildItem = (e, item) => {
    const { buildItem } = this.props
    e.stopPropagation()
    buildItem(item.uuid)
  }

  selectItem = (e, item) => {
    const { selectItem } = this.props
    e.stopPropagation()
    selectItem(item.uuid)
  }

  deleteItem = (e, item) => {
    const { deleteItem } = this.props
    e.stopPropagation()
    deleteItem(item.uuid)
  }

  openScorecard = (e, item) => {
    const { openScorecard } = this.props
    e.stopPropagation()
    openScorecard(item.uuid)
  }

  render() {
    const { adType, items, classes, selectedItem, viewHeight, scorecardAvailable } = this.props

    return (
      <Grid container spacing={2} alignItems="center" justify="center" style={{ height: viewHeight, overflow: 'auto' }}>
        {items.map((item, index) => (
          <Grid item key={`${item.uuid}`}>
            <Paper elevation={0} classes={{ elevation0: classes.boxShadow }}>
              <Card
                className={cn(classes.card, selectedItem && item.uuid === selectedItem.uuid ? classes.selected : null)}
                elevation={0}
                onClick={e => this.selectItem(e, item)}
              >
                <CardMedia className={classes.media} image={item.link} title={item.label} />
                <CardContent className={classes.cardContent}>
                  <Grid container justify="flex-start">
                    <Grid item xs={12}>
                      <Typography variant="body2" align="left" className={classes.title} noWrap>
                        {item.label}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justify="flex-start">
                    <Grid item xs={12}>
                      <Typography variant="caption" align="left" noWrap>
                        Updated {formatDate(item.date)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} alignItems="center" justify="space-between">
                    <Grid item>
                      <AdType adType={adType} />
                    </Grid>

                    <Grid item>
                      <Grid container spacing={0} alignItems="center" justify="flex-end">
                        <Grid item>
                          <Tooltip id="build-grid-tooltip" title={'Select item and go to Scorcard'}>
                            <div>
                              <IconButton
                                onClick={e => this.openScorecard(e, item)}
                                align="left"
                                disabled={!scorecardAvailable}
                              >
                                <ScorecardIcon />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip id="build-grid-tooltip" title={'Select item and go to Builder'}>
                            <IconButton onClick={e => this.buildItem(e, item)} align="left">
                              <BuildIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        {item.is_standard ? null : (
                          <Grid item>
                            <Tooltip id="delete-tooltip" title="Delete">
                              <IconButton onClick={e => this.deleteItem(e, item)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        ))}
      </Grid>
    )
  }
}

PresetGridView.propTypes = {
  classes: PropTypes.object.isRequired,
  adType: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  selectItem: PropTypes.func.isRequired,
  // buildItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired
}

export default Styled(PresetGridView)
