export default {
  columnLabels: {
    brand: 'Brand',
    title: 'Offer Headline / Offer Code',
    lastMonthTested: 'Month Tested',
    sampleSize: 'Sample Size',
    rankingMetric: 'Ranking Metric',
    preview: 'Preview'
  }
}
