export default theme => {
  return {
    dashboard: {
      width: '100%',
      zIndex: 1,
      overflow: 'hidden'
    },
    content: {
      width: '100%',
      flexGrow: 1
    },
    staging: {
      border: `2px solid ${theme.palette.secondary.main}`,
      padding: 1
    },
    error: {
      opacity: 0.5,
      pointerEvents: 'none'
    }
  }
}
