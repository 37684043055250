export const FORD_UPDATE_STATIC_FILTER = 'FORD_UPDATE_STATIC_FILTER'
export const FORD_UPDATE_DYNAMIC_FILTER = 'FORD_UPDATE_DYNAMIC_FILTER'
export const FORD_UPDATED_FILTER = 'FORD_UPDATED_FILTER'

export function updateStaticFilter(value) {
  return {
    type: FORD_UPDATE_STATIC_FILTER,
    data: value,
  }
}

export function updateDynamicFilter(selections) {
  return {
    type: FORD_UPDATE_DYNAMIC_FILTER,
    data: selections,
  }
}
