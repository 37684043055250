import ScorecardHeader from './ScorecardHeader'
import ScorecardFooter from './ScorecardFooter'
import ScorecardScroller from './ScorecardScroller'
import ScorecardLegend from './ScorecardLegend'
import ScorecardExport from './ScorecardExport'
// import ScorecardFilter from './ScorecardFilter'
import ScorecardFilter from '../../../../../Template/theme/components/scorecard/ScorecardView/ScorecardFilter'

let ScorecardView = theme => {
  return {
    container: {
      margin: 25
    }
  }
}

export default Object.assign(ScorecardView, {
  ScorecardHeader,
  ScorecardFooter,
  ScorecardScroller,
  ScorecardLegend,
  ScorecardExport,
  ScorecardFilter
})
