export default {
    "groupName": "ford-mx",
    "label": "Ford - Mexico",
    "clientName": "Ford",
    "sampleSizeMetric": 106,
    "adPiMetric": 106,
    "dateFormat": "dd/mm/yy",
    "currentcy": "$",
    "staticFile": {
      "filename": "ad_master_list_ford_mx.xlsx",
      "label": "Ad List"
    },
    "thresholds": {
      "minSampleSizeInScorecard": {
        "value": 30,
        "color": "#ffffff"
      },
      "medSampleSizeInScorecard": {
        "value": 50,
        "color": "#ffa500"
      },
      "maxSampleSizeInScorecard": {
        "value": 75,
        "color": "#322eff"
      }
    },
    "stats": {
      "above": {
        "description": "Significant strength at 90% CI",
        "color": "#ffffff",
        // "bgColor": "#009900"
        "bgColor": "#009900"
      },
      "below": {
        "description": "Significant weakness at 90% CI",
        "color": "#ffffff",
        // "bgColor": "#ff0000"
        "bgColor": "#ff0000"
      }
    },
    "staticFilters": {
        "***": [
          {
            "label": "Gender",
            "code": "gender",
            "multi": false,
            "options": [
              {
                "label": "Male",
                "value": 1,
                "selected": false
              },
              {
                "label": "Female",
                "value": 2,
                "selected": false
              }
            ]
          },
          {
            "label": "Age",
            "code": "age",
            "multi": false,
            "options": [
              {
                "label": "25-39",
                "value": 1,
                "selected": false
              },
              {
                "label": "40-65",
                "value": 2,
                "selected": false
              }
            ]
          },
          {
            "label": "SE Class",
            "code": "seclass",
            "multi": false,
            "options": [
              {
                "label": "A/B",
                "value": 1,
                "selected": false
              },
              {
                "label": "C/D/E",
                "value": 2,
                "selected": false
              }
            ]
          },
          {
            "label": "Purchase",
            "code": "purchase",
            "multi": true,
            "options": [
              {
                "label": "0-12 Month Intender",
                "value": 1,
                "selected": false
              }
            ]
          },
          {
            "label": "Children",
            "code": "children",
            "multi": false,
            "options": [
              {
                "label": "With",
                "value": 1,
                "selected": false
              },
              {
                "label": "Without",
                "value": 2,
                "selected": false
              }
            ]
          }
        ]
    },
    "displayFilters": [
      {
        "label": "Brands",
        "code": "brand",
        "multi": true
      },
      {
        "label": "AD Class",
        "code": "ad_class",
        "multi": true
      }
    ],
    "styles": {
      "h0": {
        "bgColor": "#eeeeee",
        "color": "#3F537A",
        "fontWeight": "bold"
      },
      "h1": {
        "color": "#3F537A",
        "fontWeight": "bold",
        "offset": 1
      },
      "d0": {},
      "d1": {
        "offset": 1
      },
      "d2": {
        "bgColor": "#ffffff",
        "offset": 2
      },
      "blank": {
        "bgColor": "#ffffff",
        "color": "#ffffff"
      },
      "t1": {
        "bgColor": "#eeeeee",
        "fontWeight": "bold",
        "color": "#3F537A",
        "offset": 1
      }
    },
    "routes": {
      "root": "/ford-ap"
    }
  }
  