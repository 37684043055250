import { setBuilderToolbar } from '../../../../../Template/config/components/selection/SetBuilder'

export default {
  ...setBuilderToolbar,
  enableExcludeNonNormFilters: {
    ad: false,
    norm: false
  },
  exportButtons: {
    ad: {
      data: true,
      media: true
    },
    norm: {
      data: true,
      media: true
    }
  },
  sortOptions: [
    {
      value: -1,
      label: 'Alpha Order by Ad Code',
      field: 'brand',
      selected: false
    },
    {
      value: 3,
      label: 'Branded Recall (Breakthrough NBR)',
      selected: false
    },
    {
      value: 4,
      label: 'Persuasion (Avg Motivation)',
      selected: true
    }
  ]
}