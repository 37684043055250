import React from 'react'
import ReactGA from 'react-ga'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import ScorecardScroller from './ScorecardScroller'
import ScorecardFooter from './ScorecardFooter'
import ScorecardFilter from './ScorecardFilter'

import { MainHeader, AsyncWrapper } from '../../../'

const Styled = withStyles(theme => theme.components.scorecard.ScorecardView(theme))

class ScorecardView extends React.PureComponent {
  constructor(props) {
    super(props)

    const {
      config: {
        scorecard: {
          scorecardView: {
            heights: { marginHeight, navbarHeight, headerHeight, filterHeight, footerHeight, legendHeight }
          }
        }
      }
    } = props
    this.state = {
      viewHeight: 0,
      legendOpen: false,
      collapsed: false,
      marginHeight,
      navbarHeight,
      headerHeight,
      filterHeight,
      footerHeight,
      legendHeight
    }
  }

  updateHeight = legendOpen => {
    const { navbarHeight, marginHeight, headerHeight, footerHeight, legendHeight, filterHeight } = this.state
    const viewHeight =
      window.innerHeight -
      navbarHeight -
      marginHeight -
      filterHeight -
      footerHeight -
      headerHeight -
      (legendOpen ? legendHeight : 0)

    this.setState({
      viewHeight
    })
  }

  updateLegend = legendOpen => {
    this.setState({
      legendOpen
    })
    this.updateHeight(legendOpen)
  }

  componentDidMount() {
    window.addEventListener('resize', e => this.updateHeight(this.state.legendOpen))
    this.updateHeight()
  }

  componentDidUpdate(prevProps) {
    const {
      collapsed,
      gridData: { metricsUUID }
    } = this.props
    if (collapsed !== prevProps.collapsed) {
      this.updateHeight()
    }
    if (metricsUUID && prevProps.gridData.metricsUUID !== metricsUUID) {
      ReactGA.pageview(`${this.props.groupName}/scorecard`)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight)
  }

  render() {
    const {
      classes,
      adType,
      selections,
      selectedPresets,
      gridData,
      toPresets,
      toBuilder,
      updateFilter,
      openMedia,
      collapse,
      collapsed,
      openDetails,
      openQuickview,
      groupName,
      baseEndpoint,
      config: {
        scorecard: {
          scorecardView: {
            hasPresetButton,
            hideExportMediaButton,
            scorecardScroller,
            scorecardExport,
            scorecardStatTesting,
            enableQuickview
          },
          sampleSizeThreshold
        }
      },
      genericConfig,
      filter,
      selectedAdsMap,
      calcFlags,
      generateError
    } = this.props
    const { viewHeight } = this.state

    // const msg = `Loading metrics for ${
    //   selectedPresets.ad.ad_codes.length
    // } ads in Ad Set and ${selectedPresets.norm.ad_codes.length} ads in Norm`
    return (
      <div>
        <MainHeader
          title="Scorecard"
          adType={adType}
          selections={selections}
          adsMap={selectedAdsMap}
          ads={selectedAdsMap}
          builderAction={toBuilder}
          presetsAction={hasPresetButton && toPresets}
          calcFlags={calcFlags}
          config={genericConfig}
        />
        <ScorecardFilter filter={filter} update={updateFilter} disabled={!gridData.ready} />
        <AsyncWrapper item={gridData} loadingMsg={`Loading metrics`}>
          <Paper className={classes.container} elevation={0} classes={{ elevation0: classes.boxShadow }}>
            <ScorecardScroller
              viewHeight={viewHeight}
              gridData={gridData}
              config={scorecardScroller}
              openMedia={openMedia}
              collapse={collapse}
              collapsed={collapsed}
              openDetails={openDetails}
              openQuickview={enableQuickview && openQuickview}
            />
          </Paper>
        </AsyncWrapper>
        <ScorecardFooter
          updateLegend={this.updateLegend}
          gridData={gridData}
          adType={adType}
          selections={selections}
          config={{ scorecardExport, scorecardStatTesting }}
          baseEndpoint={baseEndpoint}
          groupName={groupName}
          adSets={selectedPresets}
          // adsMap={selectedAdsMap}
          filter={filter}
          adsMap={selectedAdsMap}
          sampleSizeThreshold={sampleSizeThreshold}
          generateError={generateError}
          hideExportMediaButton={hideExportMediaButton}
          genericConfig={genericConfig}
        />
      </div>
    )
  }
}

export default Styled(ScorecardView)
