export default theme => {
  return {
    small: {
      height: 20,
      marginRight: 15
    },
    medium: {
      height: 30,
      marginRight: 20
    },
    large: {
      height: 40,
      marginRight: 25
    },
    xLarge: {
      height: 50,
      marginRight: 35
    },
    xxLarge: {
      height: 75,
      marginRight: 0
    }
  }
}
