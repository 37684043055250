import setBuilderHeader from './setBuilderHeader'
import setBuilderToolbar from './setBuilderToolbar'
import setBuilderExport from './setBuilderExport'
import setBuilderListView from './SetBuilderListView'

const setBuilder = {
  storageVersion: 4,
  maxAdSetSize: 300,
  hasPresetButton: true,
  hasNormsTab: true,
  hasAdStorage: true,
  includeSampleSize: true,
  setBuilderHeader,
  setBuilderToolbar,
  setBuilderExport,
  setBuilderListView
}

export default setBuilder
export { setBuilder, setBuilderHeader, setBuilderToolbar, setBuilderExport }
