import React from 'react'
import { Auth, Logger } from 'aws-amplify'
import AuthPiece from './AuthPiece'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import StyledContainer from './StyledContainer'

const styles = theme => ({
  formControl: {
    width: '100%',
    minWidth: 'unset',
    marginBottom: '10px',
  },
  submitButton: {
    width: '100%',
    minWidth: 'unset',
    margin: '30px 0px',
  },
})

const logger = new Logger('ConfirmSignUp')

class ConfirmSignUp extends AuthPiece {
  constructor(props) {
    super(props)

    this._validAuthStates = ['confirmSignUp']
    this.confirm = this.confirm.bind(this)
    this.resend = this.resend.bind(this)
  }

  confirm() {
    const username = this.usernameFromAuthData() || this.inputs.username
    const { code } = this.inputs

    Auth.confirmSignUp(username, code)
      .then(() => this.changeState('signedUp'))
      .catch(err => this.error(err))
  }

  resend() {
    const username = this.usernameFromAuthData() || this.inputs.username

    Auth.resendSignUp(username)
      .then(() => logger.debug('code resent'))
      .catch(err => this.error(err))
  }

  showComponent(theme) {
    const { hide, classes } = this.props
    const username = this.usernameFromAuthData()

    if (hide && hide.includes(ConfirmSignUp)) {
      return null
    }

    return (
      <StyledContainer message="Confirm Sign Up">
        {username ? (
          <Typography variant="body2" color="primary" noWrap>
            {username}
          </Typography>
        ) : (
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="username-input">email</InputLabel>
            <Input
              id="username-input"
              key="username"
              name="username"
              onChange={this.handleInputChange}
            />
          </FormControl>
        )}
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="code-input">code sent to email</InputLabel>
          <Input
            id="code-input"
            key="code"
            name="code"
            onChange={this.handleInputChange}
          />
        </FormControl>
        <br />
        <Button
          variant="contained"
          color="secondary"
          className={classes.submitButton}
          onClick={this.confirm}
        >
          Confirm
        </Button>
        <br />
        <Button color="primary" onClick={this.resend}>
          Resend Code
        </Button>
        <Button
          color="primary"
          style={{ marginLeft: 96 }}
          onClick={() => this.changeState('signIn')}
        >
          Sign In
        </Button>
      </StyledContainer>
    )
  }
}

export default withStyles(styles)(ConfirmSignUp)
