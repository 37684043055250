export default {
  slices: [
    {
      field: 'ad_type',
      display: 'Media Type',
      map: {
        tv: {
          display: 'TV',
          mediaType: 'mp4',
          sortOrder: 1
        }
      }
    },
    {
      field: 'ad_categories',
      display: 'Ad Category',
      map: {
        all_tech: {
          display: 'All Tech Devices',
          sortOrder: 1
        },
        smart_phones: {
          display: 'Smartphones',
          sortOrder: 2
        },
        computers: {
          display: 'Computers (Tablets, PCs and Laptops)',
          sortOrder: 3
        },
        wearables: {
          display: 'Wearables',
          sortOrder: 4
        },
        connected_home: {
          display: 'Connected Home',
          sortOrder: 5
        },
        other_tech: {
          display: 'Other Tech Devices',
          sortOrder: 6
        }
      }
    }
  ]
}
