// const DASHBOARD_SEARCH_SET_MONTH_RANGE = 'DASHBOARD_SEARCH_SET_MONTH_RANGE'
const DASHBOARD_SEARCH_TOGGLE_BRAND = 'DASHBOARD_SEARCH_TOGGLE_BRAND'
const DASHBOARD_SEARCH_SELECT_ALL_BRANDS = 'DASHBOARD_SEARCH_SELECT_ALL_BRANDS'
const DASHBOARD_TOGGLE_AD_TAG = 'DASHBOARD_TOGGLE_AD_TAG'
const DASHBOARD_UPDATE_EXTRA_FILTER_DATA = 'DASHBOARD_UPDATE_EXTRA_FILTER_DATA'
const DASHBOARD_SET_EXTRA_FILTER = 'DASHBOARD_SET_EXTRA_FILTER'

// const dashboardSearchSetMonthRange = data => {
//   const { tab, range } = data
//   return {
//     type: DASHBOARD_SEARCH_SET_MONTH_RANGE,
//     data: {
//       tab,
//       range
//     }
//   }
// }

const dashboardSearchToggleBrand = data => {
  return {
    type: DASHBOARD_SEARCH_TOGGLE_BRAND,
    data
  }
}

const dashboardSearchSelectAllBrands = data => {
  return {
    type: DASHBOARD_SEARCH_SELECT_ALL_BRANDS,
    data
  }
}

const dashboardToggleAdTag = id => {
  return {
    type: DASHBOARD_TOGGLE_AD_TAG,
    id
  }
}

const dashboardUpdateExtraFilterData = data => {
  return {
    type: DASHBOARD_UPDATE_EXTRA_FILTER_DATA,
    data
  }
}

const dashboardSetExtraFilter = data => {
  return {
    type: DASHBOARD_SET_EXTRA_FILTER,
    data
  }
}

export {
  // DASHBOARD_SEARCH_SET_MONTH_RANGE,
  DASHBOARD_SEARCH_TOGGLE_BRAND,
  DASHBOARD_SEARCH_SELECT_ALL_BRANDS,
  DASHBOARD_TOGGLE_AD_TAG,
  DASHBOARD_UPDATE_EXTRA_FILTER_DATA,
  DASHBOARD_SET_EXTRA_FILTER,
  // dashboardSearchSetMonthRange,
  dashboardSearchToggleBrand,
  dashboardSearchSelectAllBrands,
  dashboardToggleAdTag,
  dashboardUpdateExtraFilterData,
  dashboardSetExtraFilter
}
