export default theme => {
  return {
    container: {
      marginTop: 20,
      marginBottom: 20
    },
    title: {
      marginBottom: 10
    },
    button: {},
    dateRangeIcon: {},
    item: {}
  }
}
