
export default Object.assign( theme => {
  return {
    container: {
      width: '75%',
      margin: 'auto',
      maxWidth: 840
    },
    title: {
      marginBottom: 10
    },
    dialogContainer: {},
    selectForm: {
      width: 250
    },
    input: {
      marginTop: 5,
      marginBottom: 5,
      width: '100%'
    },
    selectContainer: {
      marginTop: 5,
      marginBottom: 5
    },
    drag: {
      marginTop: 20
    },
    confirm: {

    },
    upload: {
      width: 20,
      margin: 'auto'
    }
  }
}, {})
