import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Checkbox, Tooltip } from '@material-ui/core'


const Styled = withStyles(theme =>
  theme.components.adminView.AdminUserManager.AdminUserRoles(theme)
)

const RoleCheckboxes = Styled(props => {
  const { roles, user: { email }, subResource, role, classes, label, tooltip, update } = props

  return (<Grid item>
    <Grid container direction="column" className={classes.rolesContainer}>
      <Grid item >
        <Grid container className={classes.roleItemContainer}>
          {roles.map(_role => {
            const checked = role === _role
            const updateOptions = subResource ? {
              role:_role, 
              email, 
              grant: checked ? 'revoke' : 'grant', 
              subResource
            } : {
              role: _role,
              email,
              grant: checked ? 'revoke' : 'grant'
            }
            return (
              <Grid item key={`${email}_${_role}${subResource ? `_${subResource}` : ''}`} className={classes.roleItem}>
                <Checkbox
                  edge="start"
                  checked={checked}
                  style={{ paddingRight: 2 }}
                  color="secondary"
                  onClick={ ( ) =>  update(updateOptions)}
                />
                <Typography display="inline" variant="caption" style={{ paddingTop: 11 }}>
                  {_role}
                </Typography>
              </Grid>
            )
          })
          }
        </Grid>
      </Grid>
      <Grid item className={classes.rolesContainerLabel}>
        <Tooltip title={tooltip} placement="top">
          <Typography variant="caption">
            {label}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  </Grid>)
})

const SubresourceRoleCheckboxes = Styled(props => props.subResources.map( _subResource => {
  const subResources = (props.user.resources && props.user.resources[props.groupName] && props.user.resources[props.groupName].subResources) || {}
  const subResource = subResources[_subResource.code] || {}
  return <RoleCheckboxes key={`${props.user.email}_subResource_${_subResource.code}`} update={props.update} roles={props.roles} user={props.user} role={subResource.role} subResource={_subResource.code} label={_subResource.label} tooltip={`Grant access only to ${subResource.label} metadata`} />
}
))

class AdminUserRoles extends React.PureComponent {

  render() {
    const { resourceConfig: { roles, subResources, subResourceRoles, groupName, appName }, user, update } = this.props
    const { resources } = user
    const resource = (resources && resources[groupName]) || {}

    return (
      <Grid container direction="row">
        <RoleCheckboxes update = {update} roles={roles} role={resource.role} user={user} groupName={groupName} label={appName} tooltip={`Grant access to all of ${appName} metadata`} />
        <SubresourceRoleCheckboxes update={update} roles={subResourceRoles} subResources={subResources || []} user={user} groupName={groupName} />
      </Grid>
    )
  }
}

export default Styled(AdminUserRoles)
