import SetBuilderHeader from './SetBuilderHeader'
import SetBuilderToolbar from './SetBuilderToolbar'
import SetBuilderListView from './SetBuilderListView'
import SetBuilderGridView from './SetBuilderGridView'
import SetBuilderFooter from './SetBuilderFooter'

export default Object.assign(
  theme => {
    return {
      container: {},
      boxShadow: {
        border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
        boxShadow: `0 1px 1px #E7E7E7`
      }
    }
  },
  {
    SetBuilderHeader,
    SetBuilderToolbar,
    SetBuilderListView,
    SetBuilderFooter,
    SetBuilderGridView
  }
)
