export default theme => {
  return {
    container: {
      backgroundColor: theme.palette.grey[200],
      padding: 10
    },
    button: {
      margin: `0 20px 0 20px`
    }
  }
}
