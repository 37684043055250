import palette from './palette'
import typography from './typography'
import components from './components'

const theme = {
  palette,
  typography,
  components
}

export default theme
