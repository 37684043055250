const DASHBOARD_SELECT_SORT_METRIC = 'DASHBOARD_SELECT_SORT_METRIC'

const dashboardSelectSortMetric = data => {
  return {
    type: DASHBOARD_SELECT_SORT_METRIC,
    data
  }
}

export { DASHBOARD_SELECT_SORT_METRIC, dashboardSelectSortMetric }
