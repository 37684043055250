import scorecardScroller from './ScorecardScroller'
import scorecardExport from './ScorecardExport'
import scorecardStatTesting from './ScorecardStatTesting'
import scorecardFilter from './ScorecardFilter'

const scorecardView = {
  heights: {
    marginHeight: 50,
    navbarHeight: 64,
    headerHeight: 70,
    filterHeight: 80,
    footerHeight: 65,
    legendHeight: 100
  },
  hasPresetButton: false,
  collapsed: true,
  scorecardScroller,
  scorecardExport,
  scorecardStatTesting,
  scorecardFilter
}

export default scorecardView

export {
  scorecardView,
  scorecardScroller,
  scorecardFilter,
  scorecardExport,
  scorecardStatTesting
}
