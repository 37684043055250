import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TVIcon from '@material-ui/icons/Tv'
import OnlineVideoIcon from '@material-ui/icons/PlayCircleOutline'
import PrintIcon from '@material-ui/icons/Subject'
import OnlineDisplayIcon from '@material-ui/icons/ImportantDevices'
import SocialPostIcon from '@material-ui/icons/Group'
import OOHIcon from '@material-ui/icons/SettingsOverscan'
import UnknownIcon from '@material-ui/icons/DevicesOther'
import TapAndPlay from '@material-ui/icons/TapAndPlay'

const styles = () => ({})

function TypeIcon(props) {
  const { type, iconStyle = {} } = props

  if (type === 'TV') return <TVIcon style={iconStyle} />
  if (type === 'Print') return <PrintIcon style={iconStyle} />
  if (type === 'Digital Video') return <OnlineVideoIcon style={iconStyle} />
  if (type === 'Online Display') return <OnlineDisplayIcon style={iconStyle} />
  if (type === 'Social Post') return <SocialPostIcon style={iconStyle} />
  if (type === 'OOH') return <OOHIcon style={iconStyle} />
  if (type === 'Social Video') return <TapAndPlay style={iconStyle} />
  return <UnknownIcon style={iconStyle} />
}

TypeIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  iconStyle: PropTypes.object,
}

export default withStyles(styles)(TypeIcon)
