import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const styles = theme => ({
  thumb: {
    boxShadow: theme.shadows[4],
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    display: 'inline-block',
    margin: 2,
    width: 115,
    height: 64,
  },
})

class ImageMenu extends React.Component {
  state = {
    anchorEl: null,
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const {
      classes,
      ad: { adCode, title, links },
      monthCode,
      viewerOpen,
    } = this.props
    const { anchorEl } = this.state

    return (
      <div>
        <div
          className={classes.thumb}
          style={{
            cursor: 'pointer',
            backgroundImage: `url(${links.thumb})`,
          }}
          onClick={this.handleClick}
        />
        <Menu
          id="simple-menu"
          style={{ marginTop: 60 }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem
            onClick={() => {
              viewerOpen(adCode, monthCode, title)
              this.handleClose()
            }}
          >
            View Ad
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

ImageMenu.props = {
  ad: PropTypes.shape({
    adCode: PropTypes.string.isRequired,
    links: PropTypes.shape({
      thumb: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  viewerOpen: PropTypes.func.isRequired,
}

export default withStyles(styles)(ImageMenu)
