import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import TimeIcon from '@material-ui/icons/AccessTime'
import SampleSizeIcon from '@material-ui/icons/People'
import { ImageMenu } from '../../../../../../../../new_components'
import cn from 'classnames'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardScrollableColumn.ScorecardAdHeader(
    theme
  )
)

class ScorecardAdHeader extends React.PureComponent {
  state = {
    dropDownItems: [
      {
        label: 'Open Media',
        handler: ad => this.openMedia(ad)
      },
      {
        label: 'Word Cloud',
        handler: ad => this.openWordcloud(ad)
      },
      {
        label: 'Trend Chart',
        handler: ad => this.openTrendchart(ad)
      }
    ]
  }

  openMedia = ad => {
    const { openMedia } = this.props
    openMedia(ad)
  }

  openWordcloud = ad => {
    const { openDetails } = this.props
    openDetails({ ad, selectedTab: 'wordcloud' })
  }

  openTrendchart = ad => {
    const { openDetails } = this.props
    openDetails({ ad, selectedTab: 'trendchart' })
  }

  render() {
    const {
      classes,
      columnIndex,
      gridData: { adHeaders },
      collapse,
      collapsed,
      config
    } = this.props

    const { dropDownItems } = this.state
    const {
      ad_code,
      brand,
      links,
      title,
      length,
      n,
      first_air_date,
      last_month_tested,
      product_campaign
    } = adHeaders[columnIndex]

    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item className={cn(classes.brandImage)}>
          <img className={classes.brandLogo} src={links.brand} alt={brand} />
        </Grid>

        <Grid item className={cn(classes.thumbnail)}>
          <ImageMenu
            ad={adHeaders[columnIndex]}
            dropDownItems={dropDownItems}
          />
        </Grid>
        <div onClick={collapse}>
          <Grid item className={cn(classes.headerGrid)}>
            <Tooltip title={ad_code}>
              <Typography
                noWrap
                variant="caption"
                align="center"
                style={{ maxWidth: config.metricWidth }}
                display="block"
                >
                {ad_code}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item className={cn(classes.headerGrid)}>
            <Tooltip title={title}>
              <Typography
                noWrap
                variant="caption"
                align="center"
                style={{ maxWidth: config.metricWidth }}
                display="block"
                >
                {title}
              </Typography>
            </Tooltip>
          </Grid>
          {collapsed ? null : (
            <Fragment>
              <Grid item className={cn(classes.headerGrid, classes.center)}>
                <TimeIcon className={classes.icon} />
                <Typography
                  noWrap
                  variant="caption"
                  align="center"
                  style={{ display: 'inline' }}
                  display="block"
                  >
                  {length}
                </Typography>
              </Grid>
              <Grid item className={cn(classes.headerGrid)}>
                <Typography noWrap variant="caption" align="center" display="block">
                  {first_air_date}
                </Typography>
              </Grid>
              <Grid item className={cn(classes.headerGrid)}>
                <Tooltip title={product_campaign}>
                  <Typography
                    noWrap
                    variant="caption"
                    align="center"
                    style={{ maxWidth: config.metricWidth }}
                    display="block"
                    >
                    {product_campaign}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item className={cn(classes.headerGrid, classes.center)}>
                <SampleSizeIcon className={classes.icon} />
                <Typography
                  noWrap
                  variant="caption"
                  align="center"
                  style={{ display: 'inline' }}
                  display="block"
                  >
                  {n}
                </Typography>
              </Grid>
            </Fragment>
          )}
          <Grid item className={cn(classes.headerGrid)}>
            <Typography noWrap variant="caption" align="center" display="block">
              {last_month_tested}
            </Typography>
          </Grid>
        </div>
      </Grid>
    )
  }
}

export default Styled(ScorecardAdHeader)
