export default theme => {
    return {
      rolesContainer: {
        paddingRight: 30,
      },
      roleItemContainer: {
        borderBottom: `1px solid ${theme.palette.grey[600]}`
      },
      roleItem: {
        marginRight: 10
      }
    }
}