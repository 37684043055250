import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { PresetSelect, Confirmation } from '../../new_components'

import {
  getPresets,
  getAdType,
  getAdsMap,
  getSelectedAds,
  getSelectedTab,
  getSelectedPresets
} from '../../new_selectors'

import {
  dashboardInit,
  dashboardSelectPreset,
  dashboardSelectAdsTab,
  dashboardDelete
} from '../../new_actions'

const Styled = withStyles(theme =>
  theme.components.selection.PresetSelect(theme)
)

class Preset extends React.PureComponent {
  state = {
    ready: false,
    confirmationOpen: false,
    uuid: null,
    confirmationTitle: 'Warning',
    confirmationMessage: `This action will overwrite your unfinished work in Builder. \nAre you sure you want to continue?`
  }

  componentDidMount() {
    const { adType, init, dispatch } = this.props
    if (!adType) {
      dispatch(dashboardInit(init))
    } else {
      this.setState({
        ready: true
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedPresets, presets, dispatch } = this.props
    if (!prevProps.adType) {
      this.setState({ ready: true })
    } else if (selectedPresets.norm.uuid === null) {
      dispatch(
        dashboardSelectPreset({
          type: 'norm',
          preset: (presets.norm && presets.norm[0]) || {}
        })
      )
    }
  }

  componentDidCatch(a) {
    console.log(a)
  }

  navigateTo = route => {
    const { history, baseRoute } = this.props

    history.push(`${baseRoute}/${route}`)
  }

  selectPreset = _uuid => {
    const { dispatch, presets, selectedTab: { tab } } = this.props

    const uuid = _uuid || this.state.uuid
    const preset = presets[tab].find(preset => preset.uuid === uuid)
    dispatch(
      dashboardSelectPreset({
        type: tab,
        preset
      })
    )
  }

  buildPreset = _uuid => {
    const { selectedPresets, selectedTab } = this.props 
    const uuid = _uuid || this.state.uuid
    if (uuid && uuid !== selectedPresets[selectedTab.tab].uuid) 
      this.selectPreset(uuid)
    this.navigateTo('builder')
  }

  deletePreset = uuid => {
    const { dispatch, storageVersion } = this.props
    dispatch(dashboardDelete('preset', { uuid, storageVersion }))
  }

  openScorecard = _uuid => {
    const { selectedPresets, selectedTab } = this.props 

    const uuid = _uuid || this.state.uuid
    if (uuid && uuid !== selectedPresets[selectedTab.tab].uuid) 
      this.selectPreset(uuid)
    this.navigateTo('scorecard')
  }

  selectPresetWithConfirmation = uuid => {
    const { selectedPresets, selectedTab } = this.props

    selectedPresets[selectedTab.tab] &&
      selectedPresets[selectedTab.tab].is_perishable
      ? this.getConfirmation(this.selectPreset, uuid)
      : this.selectPreset(uuid)
  }

  buildPresetWithConfirmation = (uuid) => {
    const { selectedPresets, selectedTab } = this.props

    selectedPresets[selectedTab.tab] &&
      selectedPresets[selectedTab.tab].is_perishable
      ? this.getConfirmation(this.buildPreset, uuid)
      : this.buildPreset(uuid)
  }

  openScorecardWithConfirmation = uuid => {
    const { selectedPresets, selectedTab } = this.props

    selectedPresets[selectedTab.tab] &&
      selectedPresets[selectedTab.tab].is_perishable
      ? this.getConfirmation(this.openScorecard, uuid)
      : this.openScorecard(uuid)
  }

  getConfirmation = (confirmationCallback, uuid) => {
    this.setState({
      confirmationCallback,
      uuid,
      confirmationOpen: true
    })
  }

  cancelSelect = () => {
    this.setState({
      confirmationCallback: () => { },
      uuid: null,
      confirmationOpen: false,
    })
  }

  confirmSelect = () => {
    const { uuid } = this.state
    this.state.confirmationCallback(uuid)
    this.setState({
      confirmationCallback: () => { },
      uuid: null,
      confirmationOpen: false
    })
  }


  viewPreset = () => {
    this.navigateTo('builder')
  }

  selectTab = ({ tab, index }) => {
    const { dispatch } = this.props
    dispatch(dashboardSelectAdsTab({ tab, index }))
  }

  render = () => {
    const {
      adType,
      selections,
      selectedTab,
      selectedPresets,
      selectedAds,
      adsMap,
      presets,
      config: { presetSelect },
      genericConfig,
      calcFlags
    } = this.props

    if (!this.state.ready) return null

    return (
      <Fragment>
        <PresetSelect
          config={presetSelect}
          adType={adType}
          selections={selections}
          presets={presets}
          adsMap={adsMap}
          selectedPresets={selectedPresets}
          selectedAds={selectedAds}
          selectedTab={selectedTab}
          selectTab={this.selectTab}
          deletePreset={this.deletePreset}
          selectPreset={this.selectPresetWithConfirmation}
          buildPreset={this.buildPresetWithConfirmation}
          openScorecard={this.openScorecardWithConfirmation}
          calcFlags={calcFlags}
          genericConfig={genericConfig}
        />
        <Confirmation
          open={this.state.confirmationOpen}
          confirm={this.confirmSelect}
          cancel={this.cancelSelect}
          message={this.state.confirmationMessage}
          title={this.state.confirmationTitle}
        />
      </Fragment>
    )
  }
}

const Connected = connect(state => {
  const { dashboard } = state
  return {
    init: dashboard.init,
    config: dashboard.init.config.components.selection,
    genericConfig: dashboard.init.config.components.generic,
    storageVersion:
      dashboard.init.config.components.selection.setBuilder.storageVersion,
    adType: getAdType(dashboard),
    selections: dashboard.selections.slice.sliceSelections,
    presets: getPresets(dashboard),
    adsMap: getAdsMap(dashboard),
    selectedPresets: getSelectedPresets(dashboard),
    selectedAds: getSelectedAds(dashboard),
    selectedTab: getSelectedTab(dashboard),
    ready: dashboard.selections.slice.ready,
    baseRoute: dashboard.init.config.main.baseRoute,
    calcFlags: dashboard.scorecard.calcFlags
  }
})

export default withRouter(Connected(Styled(Preset)))
