import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { withStyles } from '@material-ui/core/styles'
const Styled = withStyles(theme => theme.components.generic.Tabs(theme))

export default Styled(props => {
  const { selectTab, selectedTab, tabs } = props

  if (!tabs || tabs.length < 2) return null

  const { tab, index } = selectedTab

  const selectedIndex = !tab ? index : tabs
    .map(tab => tab.key)
    .indexOf(tab)

  return (
    <Tabs
      value={selectedIndex}
      onChange={(e, index) => selectTab({tab: tabs[index].key, index})}
    >
      {tabs.map(tab => {
        return <Tab label={tab.label} key={tab.label} />
      })}
    </Tabs>
  )
})
