export const colToAdr = col => {
  const recurse = (n, r) => {
    if (n === 0) return r
    const rem = ((n - 1) % 26) + 1
    r = String.fromCharCode(rem + 64) + r
    return recurse((n - rem) / 26, r)
  }

  return recurse(col, '')
}

export const rowColToAdr = (row, col) => {
  return `${colToAdr(col)}${row}`
}

export const mergeCellsHorizontal = (sheet, row, col, numCells) => {
  const start = rowColToAdr(row, col)
  const end = rowColToAdr(row, col + numCells - 1)
  sheet.mergeCells(start, end)
  return sheet.getCell(start)
}

export const mergeCellsVertical = (sheet, row, col, numCells) => {
  const start = rowColToAdr(row, col)
  const end = rowColToAdr(row + numCells - 1, col)
  sheet.mergeCells(start, end)
  return sheet.getCell(start)
}

export const grey = cell => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: `dddddd` },
  }
  return cell
}

export const center = cell => {
  cell.alignment = {
    vertical: 'top',
    horizontal: 'center',
  }
  return cell
}

export const bold = cell => {
  cell.font = {
    bold: true,
  }
  return cell
}

export const left = cell => {
  cell.alignment = {
    vertical: 'top',
    horizontal: 'left',
  }
  return cell
}
