import templateComponents from '../../../../Template/config/components'
import scorecard from './scorecard'
import quickview from './quickview'
import selection from './selection'
import adminView from './adminView'


export default {
    ...templateComponents,
    scorecard,
    quickview,
    selection,
    adminView
}