export default {
  slices: [
    {
      field: 'ad_type',
      display: 'Ad Category',
      map: {
        'tv' : {
          display: 'TV',
          sortOrder: 1
        },
        'online_video' : {
          display: 'Online Video',
          sortOrder: 2
        },
        'online_display' : {
          display: 'Online Display',
          sortOrder: 3
        },
        'print' : {
          display: 'Print',
          sortOrder: 4
        },
        'ooh' : {
          display: 'OOH',
          sortOrder: 5
        },
      }
    },
    {
      field: 'region',
      display: 'Region',
      map: {
        '22': {
          display: 'France',
          sortOrder: 2
        },
        '23': {
          display: 'Germany',
          sortOrder: 6
        },
        '24': {
          display: 'Italy',
          sortOrder: 3
        },
        '25': {
          display: 'Spain',
          sortOrder: 4
        },
        '26': {
          display: 'UK',
          sortOrder: 1
        },
      }
    },
  ]
}
