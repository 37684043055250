import scorecardView from './ScorecardView'
import metricsLabels from './MetricsLabels'
import trendChart from './Trendchart'

const scorecard = {
  maxAdsLength: 300,
  maxNormLength: 300,
  sampleSizeMetric: 1,
  sampleSizeThreshold: 50,
  hideMetricWithMissingNorm : false,
  undeterminedStatColor: '#e0e0e0',
  normStatAccumulateLastMonthOnly: false,
  sortMetric: 1,
  calcFlags: {
    ad: {
      includeAllHistory: true,
      excludeProprietary: false,
      excludeNonNorm: false,
      existsAugment: false,
      monthIndependentData: false
    },
    norm: {
      includeAllHistory: false,
      excludeProprietary: false,
      excludeNonNorm: true,
      existsAugment: false,
      minSampleSize: 0
    }
  },
  scorecardView,
  metricsLabels,
  trendChart
}

export default scorecard
