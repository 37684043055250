/***
 * Core
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

/***
 * Material UI
 */
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

/***
 * Local components
 */
import HomeButton from './HomeButton'
import SignOutButton from './SignOutButton'
import MenuButton from './MenuButton'
import { AdminButton } from '../../../new_components'
import { Logo } from '../../'

const Styled = withStyles(theme => theme.components.navigation.NavBar(theme) )

const NavBar = props => {
  const {
    classes,
    toggleSidebar,
    openAdmin,
    appName,
    appLabel,
    disableGutters,
    showMenuIcon,
    showHomeIcon,
    showSignOutIcon,
    appLogoSize,
    hasAdminViewAccess,
    user
  } = props

  return (
    <AppBar className={classes.appBar} elevation={0}>
      <Paper elevation={0} className={classes.paper} classes={{elevation0: classes.boxShadow}}>
        <Toolbar disableGutters={disableGutters}>
          <MenuButton visible={showMenuIcon} action={toggleSidebar} />
          {!(appName.match('category')||appName.match('demo')) && <Logo name={appName} size={appLogoSize} />}
          <Typography
            variant="subtitle1"
            className={classes.grow}
            color="textPrimary"
          >
            {appLabel}
          </Typography>
          <AdminButton visible={hasAdminViewAccess} style={{marginRight: 40}} action={openAdmin}/>
          <Logo name="marketcast" size="medium" />
          <HomeButton visible={showHomeIcon} />
          <SignOutButton visible={showSignOutIcon} email={user.email}/>
        </Toolbar>
      </Paper>
    </AppBar>
  )
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  appLabel: PropTypes.string.isRequired
}

NavBar.defaultProps = {
  showMenuIcon: true,
  showHomeIcon: true,
  showSignOutIcon: true,
  disableGutters: false,
  appLogoSize: 'medium',
  phoenixLogoSize: 'large'
}

export default withRouter(Styled(NavBar))
