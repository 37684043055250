export default theme => {
  return {
    container: {
      height: '100%',
      fontFamily: 'Roboto',
      fontSize: 11,
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[100]
    },
    top: {
      marginTop: 23
    },
    align: {
      paddingTop: 183
    },
    headerGrid: {
      height: 20
    },
    headerMetric: {
      fontWeight: 'bold'
    },
    center: {
      margin: 'auto',
      display: 'block',
      width: 'max-content'
    },
    icon: {
      fontSize: 14,
      marginRight: 4,
      marginBottom: -3,
      color: theme.palette.grey[600]
    },

  }
}
