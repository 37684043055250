import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import cn from 'classnames'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardScrollableColumn.ScorecardMetricsValues(
    theme
  )
)

export default Styled(props => {
  const {
    classes,
    rowIndex,
    columnIndex,
    gridData: { metrics }
  } = props

  const { display, rowType, statTest } = metrics[columnIndex][rowIndex]

  return (
    <div className={cn(classes.container, classes[rowType])}>
      <div className={classes.stat} style={{ backgroundColor: statTest.color}}>
        <Typography
          variant="body2"
          className={cn(statTest.color ? classes.metricsValueInverseText : classes.metricsValuesText)}
          align="center"
        >
          {display}
        </Typography>
      </div>
    </div>
  )
})
