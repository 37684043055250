export default {
  columnLabels: {
    code: 'Offer Code',
    title: 'Offer Title',
    category: 'Offer Category',
    type: 'Type',
    custom: 'Custom Offer Codes',
    helpText: 'Enter Offer Codes (separated by commas, tabs or spaces)'
  },
  errors: {
    nonExistent: 'Non-existent Offer Code'
  }
}
