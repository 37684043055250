import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import {
  adminInit,
  dashboardAdminInterfaceClose,
} from '../../new_actions'

import {
  AdminConfigManager,
  AdminMetadataManager,
  AdminUserManager,
  AdminBar,
  AdminTabs,
  AsyncWrapper
} from '../../new_components'

const Styled = withStyles(theme => theme.components.adminView(theme))

const AdminViews = {
  config: AdminConfigManager,
  metadata: AdminMetadataManager,
  user: AdminUserManager
}

class Admin extends React.Component {
  state = {
    selectedTab: {
      tab: null
    }
  }

  selectTab = selectedTab => {
    this.setState({
      selectedTab
    })
  }

  handleClose = () => {
    const { history, dispatch } = this.props
    dispatch(dashboardAdminInterfaceClose())
    history.push(`/`)
  }

  componentDidMount() {
    const { adminTabs, user: { accessRights }, dispatch, adsMap, adsList, brandsMap, brandsList, tagStructureConfig, slices, groupName, isAuto } = this.props
    const tabs = adminTabs.tabs.filter(tab => accessRights[tab.access])

    dispatch( adminInit({adsMap, adsList, brandsMap, brandsList, tagStructureConfig, slices, groupName, isAuto}))
    this.setState({
      tabs,
      selectedTab: {
        tab: tabs[0].key
      }
    })
  }

  render() {
    const { admin, app, groupName, software } = this.props
    const { tabs, selectedTab } = this.state
    const AdminView = AdminViews[selectedTab.tab] || null

    
    return AdminView ? (
      <AsyncWrapper item={admin.metadata}>
        <AdminBar
          close={this.handleClose}
          environment={app.environment}
          appName={groupName}
          software={software}
        />
        <AdminTabs
          tabs={tabs}
          selectTab={this.selectTab}
          selectedTab={selectedTab}
          environment={app.environment}
        >
          <AdminView
            data={admin[selectedTab.tab]}
            environment={app.environment}
            appName={groupName}
          />       
        </AdminTabs>
      </AsyncWrapper>
    ) : null
  }
}

const Connected = connect(state => {
  const {
    user,
    app,
    admin,
    admin: {
      view: {
        isAuto
      }
    },
    app: {
      software
    },
    dashboard: {
      init,
      init: {
        config: {
          main: { groupName },
          components: {
            adminView: { adminTabs }
          }
        }
      }
    }
  } = state

  const slices = state.dashboard.init.config.metadata && state.dashboard.init.config.metadata.slices

    const tagStructureConfig = state.dashboard.init.config.components.selection ? state.dashboard.init.config.components.selection.search.tagHierarchy : {}
    const adsMap = Object.keys(state.dashboard.init.metadata.adsMap).length > 0 ? state.dashboard.init.metadata.adsMap : state.ford.ads.map
    const adsList = Object.keys(adsMap).reduce( (array, ad_code) => { array.push(adsMap[ad_code]); return array; }, []).sort((a, b) => a.ad_code > b.ad_code ? 1 : -1)
    const brandsList = state.dashboard.init.metadata.brandsList 
    const brandsMap = state.dashboard.init.metadata.brandsMap 

  return {
    user,
    app,
    isAuto,
    init,
    adminTabs,
    admin,
    groupName,
    software,
    adsMap,
    adsList,
    brandsMap,
    brandsList,
    tagStructureConfig,
    slices
  }
})

export default withRouter(Connected(Styled(Admin)))
