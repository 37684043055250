import axios from 'axios'
import { FORD_UPDATED_FILTER } from './filter'

export const FORD_SCORECARD_REQUEST = 'FORD_SCORECARD_REQUEST'
export const FORD_SCORECARD_RECEIVE = 'FORD_SCORECARD_RECEIVE'
export const FORD_SCORECARD_FAILURE = 'FORD_SCORECARD_FAILURE'
export const FORD_SCORECARD_UPDATE_BRANDS = 'FORD_SCORECARD_UPDATE_BRANDS'
export const FORD_SCORECARD_UPDATE_SORT = 'FORD_SCORECARD_UPDATE_SORT'
export const FORD_SET_BUSY_SESSION = 'FORD_SET_BUSY_SESSION'

export function setBusySession(data) {
  return {
    type: FORD_SET_BUSY_SESSION,
    data
  }
}

export function scorecardRequest() {
  return {
    type: FORD_SCORECARD_REQUEST,
  }
}

export function scorecardReceive(data) {
  return {
    type: FORD_SCORECARD_RECEIVE,
    data: data,
  }
}

export function scorecardFailure(data) {
  return {
    type: FORD_SCORECARD_FAILURE,
    data: data,
  }
}

export function updateScorecardBrands(brands) {
  return {
    type: FORD_SCORECARD_UPDATE_BRANDS,
    data: brands,
  }
}

export function updateScorecardSort(sort) {
  return {
    type: FORD_SCORECARD_UPDATE_SORT,
    data: sort,
  }
}

export function fetchScorecardData({
  dynamicFilters,
  displayFilters,
  staticFilterQuery,
  config,
}) {
  return dispatch => {
    dispatch(scorecardRequest())
    const { groupName } = config
    const sel = dynamicFilters.filters.reduce((obj, filter, index) => {
      return {
        ...obj,
        [filter.field]: filter.options[dynamicFilters.selections[index].value].value,
      }
    }, {})

    const params = {
      sel: JSON.stringify(sel),
      staticFilter: staticFilterQuery,
      displayFilters,
      groupName
    }
    return axios
      .get(`/main`, { params }
      )
      .then(
        res => {
          dispatch(scorecardReceive(res.data))
          dispatch({ type: FORD_UPDATED_FILTER })
        },
        err => {
          dispatch(scorecardFailure(err.message))
        },
      )
  }
}

export function fetchScorecardIfNeeded({
  dynamicFilters,
  displayFilters,
  staticFilterQuery,
  config,
}) {
  return (dispatch, getState) => {
    const state = getState()
    const app = state.app
    const main = state.ford.scorecard.main

    if (app.ready && !main.isFetching && !main.hasErrored) {
      dispatch(
        fetchScorecardData({
          dynamicFilters,
          displayFilters,
          staticFilterQuery,
          config,
        }),
      )
    }
  }
}
