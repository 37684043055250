import React, { PureComponent } from 'react'
import qs from 'qs'
import axios from 'axios'
import ReactGA from 'react-ga'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  menu: {
    height: 500,
    marginTop: -55,
    marginBottom: 20
  },
  mediaSelect: {
    outline: 'none',
    marginLeft: 5
  },
  media: {
    backgroundColor: theme.palette.grey[100],
    bottomMargin: 15,
    outline: 'none'
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
})

class MediaButton extends PureComponent {
  state = {
    anchorEl: null,
    jpg: true,
    pdf: true,
    mp4: true,
    disabled: true,
    tooManyAds: false
  }

  openMenu = event => {
    this.setState(
      {
        disabled: true,
        anchorEl: event.currentTarget
      },
      this.getSessionStatus
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.adList && this.props.adList.length > 200 && !this.state.tooManyAds) {
      this.setState({
        tooManyAds: true,
        disabled: true
      })
    } else if (this.props.adList && this.props.adList.length <= 200 && this.state.tooManyAds) {
      this.setState({
        tooManyAds: false,
        disabled: false
      })
    }
  }

  getSessionStatus = () => {
    const { generateError } = this.props

    if (this.state.tooManyAds) {
      this.closeMenu()
      return generateError(`Cannot export media for more than 200 ads. Please revise your ad set and export again.`)
    }

    axios
      .post('/media/sessionCheck', { ...this.props.data })
      .then(res => {
        this.setState({
          disabled: res.status === 200 ? true : false
        })
      })
      .catch(() => {
        this.setState({
          disabled: true
        })
      })
  }

  closeMenu = exported => {
    this.setState({ anchorEl: null })
    if (exported) {
      ReactGA.event({
        category: 'Scorecard',
        action: 'Media Export'
      })
    }
  }

  getQueryString = () => {
    const {
      props: { adList, groupName }
    } = this
    const ads = (adList && adList.map(d => d.ad_code)) || []
    const { session, idtoken } = axios.defaults.headers.common
    return qs.stringify(
      { ads, groupName, idtoken, session },
      { indices: false, arrayFormat: 'brackets' }
    )
  }

  getMediaURL = () => {
    const { endPoint } = this.props

    const queryString = this.getQueryString()

    if (process.env.NODE_ENV === 'development') {
      return `http://localhost:4000/${endPoint}?${queryString}`
    }

    return `${endPoint}?${queryString}`
  }

  togglePDF = e => {
    this.setState(prevState => {
      return {
        pdf: !prevState.pdf,
        disabled: prevState.pdf && !prevState.mp4
      }
    })
  }

  toggleMP4 = e => {
    this.setState(prevState => {
      return {
        mp4: !prevState.mp4,
        disabled: prevState.mp4 && !prevState.pdf
      }
    })
  }

  render() {
    const { anchorEl, disabled } = this.state
    const { adList, classes } = this.props
    const isDisabled = !adList || adList.length === 0

    const mediaButton = (
      <Button variant="contained" disabled={disabled} fullWidth>
        Download
      </Button>
    )

    return (
      <div>
        <Button variant="outlined" color="secondary" onClick={this.openMenu} disabled={isDisabled}>
          Export Media
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          className={classes.menu}
          TransitionComponent={Fade}
          onClose={() => this.closeMenu(false)}
        >
          <MenuItem>
            {disabled && (this.state.pdf || this.state.mp4) ? (
              <div style={{ width: '100%' }}>
                {mediaButton}
                <CircularProgress size={24} className={classes.progress} />
              </div>
            ) : (
              <div style={{ width: '100%' }}>
                <a style={{ textDecoration: 'none' }} onClick={() => this.closeMenu(true)} href={this.getMediaURL()}>
                  {mediaButton}
                </a>
              </div>
            )}
          </MenuItem>
          {this.props.mediaTypesOption ? (
            <React.Fragment>
              <Divider />
              <FormGroup className={classes.media}>
                <FormControlLabel
                  control={<Checkbox  checked={this.state.mp4} onChange={this.toggleMP4} value="mp4" color="secondary"/>}
                  label="Include MP4s"
                  className={classes.mediaSelect}
                  style={{ marginBottom: 0 }}
                />
                <FormControlLabel
                  control={<Checkbox  checked={this.state.pdf} onChange={this.togglePDF} value="pdf" color="secondary"/>}
                  label="Include PDFs"
                  className={classes.mediaSelect}
                />
              </FormGroup>
            </React.Fragment>
          ) : null}
        </Menu>
      </div>
    )
  }
}

MediaButton.defaultProps = {
  mediaTypesOption: false
}

export default withStyles(styles)(MediaButton)
