import template from '../../../../../../Template/config/components/scorecard/ScorecardView/ScorecardStatTesting'

export default {
  ...template,
  thresholdConstant: 1.96,
  labels: [
    {
      high: {
        color: '#d8e4bc',
        text: 'Significantly above the norm at 95%'
      },
      low: {
        color: '#f2dcdb',
        text: 'Significantly below the norm at 95%'
      }
    }
  ]
}
