export default {
  interpolateMonths: true,
  trendMetrics: [
    {
      metric: 100,
      field: 'adpi',
      label: 'Adpi Score',
      format: '.0f'
    },
    {
      metric: 102,
      field: 'recall',
      label: 'Branded Recall',
      format: '.0%'
    },
    {
      metric: 103,
      field: 'consideration',
      label: 'Lift to Consideration',
      format: '.0%'
    },
    {
      metric: 104,
      field: 'impression',
      label: 'Lift to Impression',
      format: '.0%'
    },
    {
      metric: 105,
      field: 'actions',
      label: 'Net Actions',
      format: '.0%'
    }
  ]
}
