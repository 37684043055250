export default {
  '***': {
    displayFilters: null,
    staticFilters: [
      {
        label: 'Age',
        code: 'age',
        multi: true,
        excludeFromVerbatims: true,
        options: [
          {
            label: '21-24',
            value: 2,
            selected: false
          },
          {
            label: '25-29',
            value: 3,
            selected: false
          },
          {
            label: '30-34',
            value: 4,
            selected: false
          },
          {
            label: '35-39',
            value: 5,
            selected: false
          },
          {
            label: '40-44',
            value: 6,
            selected: false
          },
          {
            label: '45-49',
            value: 7,
            selected: false
          },
          {
            label: '50-54',
            value: 8,
            selected: false
          },
          {
            label: '55-59',
            value: 9,
            selected: false
          },
          {
            label: '60-64',
            value: 10,
            selected: false
          },
          {
            label: '65-69',
            value: 11,
            selected: false
          },
          {
            label: '70-74',
            value: 12,
            selected: false
          },
          {
            label: '75+',
            value: 13,
            selected: false
          }
        ]
      },
      {
        label: 'Gender',
        code: 'gender',
        multi: true,
        options: [
          {
            label: 'Male',
            value: 1,
            selected: false
          },
          {
            label: 'Female',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Investable Assets',
        code: 'invest',
        multi: true,
        options: [
          {
            label: '$100,000 - $249,999',
            value: 5,
            selected: false
          },
          {
            label: '$250,000 - $499,999',
            value: 9,
            selected: false
          },
          {
            label: '$500,000 - $999,999',
            value: 10,
            selected: false
          },
          {
            label: '$1,000,000 - $4,999,999',
            value: 11,
            selected: false
          },
          {
            label: '$5,000,000+',
            value: 12,
            selected: false
          }
        ]
      },
      {
        label: 'Trades',
        code: 'trades',
        multi: true,
        options: [
          {
            label: '0',
            value: 1,
            selected: false
          },
          {
            label: '1',
            value: 2,
            selected: false
          },
          {
            label: '2',
            value: 11,
            selected: false
          },
          {
            label: '3',
            value: 12,
            selected: false
          },
          {
            label: '4-5',
            value: 4,
            selected: false
          },
          {
            label: '6-7',
            value: 5,
            selected: false
          },
          {
            label: '8-9',
            value: 6,
            selected: false
          },
          {
            label: '10-19',
            value: 7,
            selected: false
          },
          {
            label: '20+',
            value: 8,
            selected: false
          }
        ]
      },
      {
        label: 'Fidelity C/P',
        code: 'fidelity',
        multi: false,
        excludeFromVerbatims: true,
        options: [
          {
            label: 'Fidelity Customers',
            value: 1,
            selected: false
          },
          {
            label: 'Fidelity Prospects',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Schwab C/P',
        code: 'schwab',
        multi: false,
        excludeFromVerbatims: true,
        options: [
          {
            label: 'Charles Schwab Customers',
            value: 1,
            selected: false
          },
          {
            label: 'Charles Schwab Prospects',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Ethnicity',
        code: 'ethnicity',
        multi: true,
        excludeFromVerbatims: false,
        options: [
          {
            label: 'Hispanic',
            value: 1,
            selected: false
          },
          {
            label: 'Non-Hispanic',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Race',
        code: 'race',
        multi: true,
        excludeFromVerbatims: false,
        options: [
          {
            label: 'White',
            value: 1,
            selected: false
          },
          {
            label: 'African American',
            value: 2,
            selected: false
          },
          {
            label: 'Asian or Pacific Islander',
            value: 3,
            selected: false
          },
          {
            label: 'Native American or Alaskan Native',
            value: 4,
            selected: false
          },    
          {
            label: 'Mixed Racial Background',
            value: 5,
            selected: false
          },   
          {
            label: 'Other Race',
            value: 6,
            selected: false
          },      
          {
            label: 'Prefer Not to Answer',
            value: 7,
            selected: false
          },  
        ]
      },
      {
        label: 'Segmentation',
        code: 'segment',
        multi: true,
        excludeFromVerbatims: false,
        options: [
          {
            label: 'EII (Aug 13+)',
            value: 9,
            selected: false
          },
          {
            label: 'CC (Aug 13+)',
            value: 10,
            selected: false
          },
          {
            label: 'IB (Aug 13+)',
            value: 11,
            selected: false
          },
          {
            label: 'TPGS (Aug 13+)',
            value: 12,
            selected: false
          },
          {
            label: 'UGS (Aug 13+)',
            value: 13,
            selected: false
          },
          {
            label: 'Highly Engaged Expert (May 13 - Jul 13)',
            value: 6,
            selected: false
          },
          {
            label: 'Highly Engaged Collaborator (May 13 - Jul 13)',
            value: 7,
            selected: false
          },
          {
            label: 'Not Highly Engaged (May 13 - Jul 13)',
            value: 8,
            selected: false
          },
          {
            label: 'IB (Mar 10-Apr 13)',
            value: 1,
            selected: false
          },
          {
            label: 'CC (Mar 10-Apr 13)',
            value: 2,
            selected: false
          },
          {
            label: 'TPGS (Mar 10-Apr 13)',
            value: 3,
            selected: false
          },
          {
            label: 'EII (Mar 10-Apr 13)',
            value: 4,
            selected: false
          },
          {
            label: 'UGS (Mar 10-Apr 13)',
            value: 5,
            selected: false
          }
        ]
      },
    ]
  }
}
