import axios from 'axios'

const DASHBOARD_SET_CONFIG = 'DASHBOARD_SET_CONFIG'
const DASHBOARD_CLR_CONFIG = 'DASHBOARD_CLR_CONFIG'

const dashboardSetConfig = config => {
  const {
    main: { groupName },
    baseEndpoint
  } = config
  
  // axios.defaults.headers['Group-Name-Header'] = groupName
  axios.defaults.headers['Cache-Control'] = 'no-store'
  axios.defaults.baseURL = `/${baseEndpoint}/${groupName}`

  return {
    type: DASHBOARD_SET_CONFIG,
    data: config
  }
}

const dashboardClrConfig = () => {
  axios.defaults.baseURL = ``
  return {
    type: DASHBOARD_CLR_CONFIG
  }
}

export {
  DASHBOARD_SET_CONFIG,
  DASHBOARD_CLR_CONFIG,
  dashboardSetConfig,
  dashboardClrConfig,
}
