import React from 'react'
import { FixedSizeList } from 'react-window'
import { AutoSizer } from "react-virtualized";
import { withStyles } from '@material-ui/core/styles'
import { ListItem, Typography } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import cn from 'classnames'

const Styled = withStyles(theme =>
  theme.components.adminView.AdminUserManager.AdminUsers(theme)
)

class AdminUsers extends React.Component {

  renderRow = ({ index, style, data: { users, selectedUser } }) => {
    const { selectUser, classes } = this.props
    
    const user = users[index]
    return (
      <div style={style} >
        <ListItem key={user.email} onClick={() => selectUser(user)} className={cn(classes.hover, selectedUser.email === user.email ? classes.selected : user.status !== 'active' ? classes.inactive : user.isAppUser ? classes.appUser :  null)} >
          <Typography variant="body2" align="left" display="inline" style={{width: 40}}>{index + 1}</Typography> 
          <Typography variant="body2" align="left" display="inline"style={{width: 250}}  noWrap> {user.email}  </Typography>
          {
            user.isAppUser ? <Check /> : null
          }
        </ListItem>
      </div>
    )
  }

  itemKey = (index, data) => data.users[index].email
  
  render() {
    const { users, selectedUser, uuid, classes } = this.props
    return (
      <div style={{ height: '90%' }} >
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList itemKey={this.itemKey} height={height} width={width} itemSize={45} itemCount={users.length} itemData={{ users, selectedUser }} className={classes.userList} uuid={uuid} >
              {this.renderRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    )
  }
}

export default Styled(AdminUsers)
