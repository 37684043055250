export default {
  interpolateMonths: true,
  trendMetrics: [
    {
      metric: 1,
      field: 'recall_recognition',
      label: 'Recall (Recognition)',
      format: '.0%',
      hasSpendData: true
    },
    {
      metric: 2,
      field: 'ease_of_branding',
      label: 'Ease of Branding',
      format: '.0%'
    },
    {
      metric: 3,
      operation: 'prod(1,2)', 
      field: 'branded_recall',
      label: 'Branded Recall (Breakthrough NBR)',
      format: '.0%',
      hasSpendData: true
    },
    {
      metric: 4,
      field: 'persuasion',
      label: 'Persuasion (Avg Motivation)',
      format: '.0%'
    },
    {
      metric: 9,
      field: 'good_fit_with_brand',
      label: 'Good Fit with Brand',
      format: '.0%'
    },
    {
      metric: 10,
      field: 'credible_message',
      label: 'Credible Message',
      format: '.0%'
    },
    {
      metric: 11,
      field: 'benefit_relevance',
      label: 'Benefit Relevance',
      format: '.0%'
    },
    {
      metric: 12,
      field: 'benefit_uniqueness',
      label: 'Benefit Uniqueness',
      format: '.0%'
    },
    {
      metric: 13,
      field: 'engagement',
      label: 'Engagement',
      format: '.0%'
    },
    {
      metric: 14,
      field: 'information',
      label: 'Information',
      format: '.0%'
    },
    {
      metric: 15,
      field: 'relevance',
      label: 'Relevance',
      format: '.0%'
    },
    {
      metric: 16,
      field: 'empathy',
      label: 'Empathy',
      format: '.0%'
    },
    {
      metric: 17,
      field: 'impact',
      label: 'Impact',
      format: '.0%'
    },
    {
      metric: 18,
      field: 'likeability',
      label: 'Likeability (T2B)',
      format: '.0%'
    }
  ]
}
