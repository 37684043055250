import { DASHBOARD_RECEIVE_QUICKVIEW, DASHBOARD_QUICKVIEW_CLOSE } from '../../../new_actions'

const initialState = {
    data: {
        ready: false,
        error: null,
        uuid: null,
    }
}

export default (state = initialState, action) => {

    switch (action.type) {
        case DASHBOARD_RECEIVE_QUICKVIEW: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data
                }
            }
        }

        case DASHBOARD_QUICKVIEW_CLOSE: {
            return initialState
        }
        
        default: return state
    }
}