export default theme => {
  return {
    container: {
      marginTop: 30
    },
    paper: {
      padding: 25,
      overflow: 'scroll'
    },
    staging: {
      border: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: 16
    },
    development: {
      border: `2px solid ${theme.palette.grey[600]}`,
      borderRadius: 16,
    },
    production: {
      border: `8px solid ${theme.palette.highlight.main}`,
      borderRadius: 16,
    }
  }
}
