import templateSearch from '../../../../../Template/config/components/selection/Search'
import searchFilter from './SearchFilter'
import searchManual from './SearchManual'
import tagHierarchy from './tagHierarchy'

export default {
    ...templateSearch,
    tabs: [
      {
        key: 'search',
        label: 'Search Filters'
      },
      {
        key: 'manual',
        label: 'Enter Offer Codes'
      }
    ] ,
    searchFilter, 
    searchManual,
    tagHierarchy
}
