export default theme => {
  return {
      list: {
          height: 500,
          // width: 525,
          // overflow: 'hidden'
      },
      adCode: {
          marginTop: 8,
          height: 40,
          // width: 175
          // flex: '1 1 175'
      },
      title: {
          marginTop: 8,
          height: 40,
          // width: 225,
          // flex: '1 1 225'
      },
      thumbnail: {
          height: 40,
          width: 72,
          marginRight: 0,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          display: 'inline-block',
          position: 'absolute',
          right: 10
      },
      selected: {
          color: theme.palette.secondary.main
      },
      tagged: {

      }
  }
}