export default {
  "groupName": "ford-eu",
  "label": "Ford - Europe",
  "clientName": "Ford",
  "sampleSizeMetric": 106,
  "adPiMetric": 106,
  "dateFormat": "dd/mm/yy",
  "currentcy": "$",
  "staticFile": {
    "filename": "ad_master_list_ford_eu.xlsx",
    "label": "Ad List"
  },
  "thresholds": {
    "minSampleSizeInScorecard": {
      "value": 30,
      "color": "#ffffff"
    },
    "medSampleSizeInScorecard": {
      "value": 50,
      "color": "#ffa500"
    },
    "maxSampleSizeInScorecard": {
      "value": 75,
      "color": "#322eff"
    }
  },
  "stats": {
    "above": {
      "description": "Significant strength at 90% CI",
      "color": "#ffffff",
      // "bgColor": "#009900"
      "bgColor": "#009900"
    },
    "below": {
      "description": "Significant weakness at 90% CI",
      "color": "#ffffff",
      // "bgColor": "#ff0000"
      "bgColor": "#ff0000"
    }
  },
  "staticFilters": {
    "***": [
      {
        "label": "Gender",
        "code": "gender",
        "multi": false,
        "options": [
          {
            "label": "Male",
            "value": 1,
            "selected": false
          },
          {
            "label": "Female",
            "value": 2,
            "selected": false
          }
        ]
      },
      {
        "label": "Age",
        "code": "age",
        "multi": false,
        "options": [
          {
            "label": "18-40",
            "value": 1,
            "selected": false
          },
          {
            "label": "41-70",
            "value": 2,
            "selected": false
          }
        ]
      },
      {
        "label": "Segment",
        "code": "seg",
        "multi": false,
        "options": [
          {
            "label": "Upper",
            "value": 1,
            "selected": false
          },
          {
            "label": "Lower",
            "value": 2,
            "selected": false
          }
        ]
      },
      {
        "label": "Purchase",
        "code": "purchase",
        "multi": true,
        "options": [
          {
            "label": "0-12 Month Intender",
            "value": 1,
            "selected": false
          }
        ]
      },
      {
        "label": "Children",
        "code": "children",
        "multi": false,
        "options": [
          {
            "label": "With",
            "value": 1,
            "selected": false
          },
          {
            "label": "Without",
            "value": 2,
            "selected": false
          }
        ]
      },
      {
        "label": "Residential",
        "code": "residential",
        "multi": false,
        "options": [
          {
            "label": "More Urban",
            "value": 1,
            "selected": false
          },
          {
            "label": "More Rural",
            "value": 2,
            "selected": false
          }
        ]
      }
    ]
  },
  "displayFilters": [
    {
      "label": "Brands",
      "code": "brand",
      "multi": true
    },
    {
      "label": "Vehicle Types",
      "code": "vehicle_category",
      "multi": true
    }
  ],
  "styles": {
    "h0": {
      "bgColor": "#eeeeee",
      "color": "#3F537A",
      "fontWeight": "bold"
    },
    "h1": {
      "color": "#3F537A",
      "fontWeight": "bold",
      "offset": 1
    },
    "d0": {},
    "d1": {
      "offset": 1
    },
    "d2": {
      "bgColor": "#ffffff",
      "offset": 2
    },
    "blank": {
      "bgColor": "#ffffff",
      "color": "#ffffff"
    },
    "t1": {
      "bgColor": "#eeeeee",
      "fontWeight": "bold",
      "color": "#3F537A",
      "offset": 1
    }
  },
  "routes": {
    "root": "/ford-ap"
  }
}
