
import RadarChartTemplate from '../../../../../../configuration/Template/theme/components/quickview/Quickview/RadarChart'
export default theme => {
    return {
      ...RadarChartTemplate(theme),
      xOffset: 10,
      radarDiameter: 0.6,
      scaleLines: [0.25, 0.5, 0.75, 1.0],
      scaleColor:  theme.palette.grey[400],
      radiusScale: 0.9,
      scaleStroke: 1,
      scaleDash: [15, 1], 
      lineColors: [ theme.palette.grey[800], '#5580B6'],
      lineWidths: [3, 3],
      titleMargin: 20,
      metricYOffset: 10,
      metricFontSize: 50,
      metricFontStyle: 'bold',
      metricFontColor: theme.palette.grey[600],
      titleX: 10,
      titleY: 10,
      statColorSetBackground: true,
      statColorSaturationPercent: 250,
      statColorLightenPercent: 0,
      statColorValuePercent: -45,
    }
  }
  