import { setBuilderToolbar } from '../../../../../Template/config/components/selection/SetBuilder'

export default {
  ...setBuilderToolbar,
  enableExcludeNonNormFilters: {
    ad: false,
    norm: false
  },
  exportButtons: {
    ad: {
      data: true,
      media: true
    },
    norm: {
      data: true,
      media: true
    }
  },
  sortOptions: [
    {
      "value": -1,
      "label": "Alpha Order by Brand",
      "field": "brand",
      "selected": false
    },
    {
      "value": 1,
      "label": "AdPi Score",
      "dataFormat": ".,0",
      "selected": true
    },
    // {
    //   "value": 2,
    //   "label": "Branded Recall (Avg. Recall)",
    //   "selected": true
    // },
    {
      "value": 4,
      "label": "Lift to Consideration",
      "selected": false
    },
    {
      "value": 6,
      "label": "Net Actions",
      "selected": false
    },
    {
      "value": 22,
      "label": "Likes",
      "selected": false
    }
  ]
}
