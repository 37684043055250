import AdminConfigView from './AdminConfigView'
import AdminDataMigrationView from './AdminDataMigrationView'
import AdminDataMigrationFilter from './AdminDataMigrationFilter'
import AdminDataMigrationComment from './AdminDataMigrationComment'
import AdminDataMigrations from './AdminDataMigrations'

export default Object.assign(
  theme => {
    return {}
  },
  {
    AdminConfigView,
    AdminDataMigrationView,
    AdminDataMigrationFilter,
    AdminDataMigrationComment,
    AdminDataMigrations
  }
)
