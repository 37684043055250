export default theme => {
  return {
    grow: {
      flexGrow: 1
    },
    appBar: {
      position: 'static',
      marginBottom: 10
    },
    paper: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main
    },
    boxShadow: {
      border: `1px solid ${theme.palette.background.default}`,
      boxShadow: `0 1px 1px rgba(0,0,0,0.14)`
    }
  }
}
