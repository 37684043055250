import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, List, ListItem, ListItemText } from '@material-ui/core'
import MetadataSliceListItem from './MetadataSliceListItem'

const Styled = withStyles(theme =>
  theme.components.selection.MetadataSlice.MetadataSliceListView(theme)
)

export default Styled(props => {
  const { classes, values, select, selection } = props
  const singlegroup = values.filter(value => !value.multigroup)
  const multigroup = values.filter(value => value.multigroup)
  return (
    <Grid
      direction="column"
      container
      alignItems="center"
      justify="center"
      spacing={3}
    >
      <List className={classes.listRoot}>
        <ListItem className={classes.listHeader}>
          <ListItemText align="center">Individual Categories</ListItemText>
        </ListItem>
        {singlegroup.map((value, index) => (
          <Grid item key={value.field}>
            <MetadataSliceListItem
              type={value}
              item={{ fieldValue: value.field, fieldIndex: index }}
              select={select}
              selection={selection}
            />
          </Grid>
        ))}

        <ListItem className={classes.listHeader}>
          <ListItemText align="center">Multiple Categories</ListItemText>
        </ListItem>
        {multigroup.map((value, index) => (
          <Grid item key={value.field}>
            <MetadataSliceListItem
              type={value}
              item={{
                fieldValue: value.field,
                fieldIndex: index + singlegroup.length
              }}
              select={select}
              selection={selection}
            />
          </Grid>
        ))}
      </List>
    </Grid>
  )
})
