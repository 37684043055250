import templateConfig from '../../Template/config'

import main from './main'
import sidebar from './sidebar'
import components from './components'
import metadata from './metadata'

const config = {
  ...templateConfig,
  hasTrendchart: false,
  main,
  sidebar,
  components,
  metadata
}

export {config}
export default config
