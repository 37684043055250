import templateAdminConfigManager from '../../../../Template/config/components/adminView/AdminConfigManager'

export default {
  ...templateAdminConfigManager,
  adminDataMigrationFilters: [
    {
      label: 'Tables',
      code: 'tables',
      multi: true,
      options: [
        {
          label: 'Metrics',
          value: 'metricsTable',
          selected: false
        },
        {
          label: 'Openends',
          value: 'openEndsTable',
          selected: false
        },
        {
          label: 'Norms',
          value: 'normsTable',
          selected: false
        }
      ]
    },
    {
      label: 'Start Month',
      code: 'startMonth',
      multi: false,
      options: []
    },
    {
      label: 'End Month',
      code: 'endMonth',
      multi: false,
      options: []
    },
    {
      label: 'Metadata Config',
      code: 'metadata',
      multi: false,
      options: []
    },
    {
      label: 'Presets Config',
      code: 'preset',
      multi: false,
      options: []
    },
    {
      label: 'Server Config',
      code: 'config',
      multi: false,
      options: []
    },
  ]
}
