export default theme => {
  return {
    thumbnail: {
      margin: 2,
      width: 90,
      height: 50,
      boxShadow: theme.shadows[4],
      display: 'inline-block'
    },
    dropDown: {
        marginTop: 60
    }
  }
}
