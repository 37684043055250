import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AdType from './AdType'
import cn from 'classnames'
import AdNormSetsTab from './AdNormSetsTab'
import ViewScorecardButton from './ViewScorecardButton'
import ViewBuilderButton from './ViewBuilderButton'
import ViewPresetsButton from './ViewPresetsButton'

const Styled = withStyles(theme => theme.components.generic.MainHeader(theme))

class MainHeader extends React.PureComponent {
  componentDidMount() {
    const { updateHeight, title } = this.props
    if (updateHeight) {
      const element = document.getElementById(`${title}-app-bar-id`)
      if (element) updateHeight(element.clientHeight)
    }
  }

  render = () => {
    const {
      classes,
      title,
      updateTab,
      selectedTab,
      selections,
      ads,
      tabs,
      config,
      presetsAction,
      builderAction,
      openScorecard,
      maxAdSetSize,
      calcFlags,
    } = this.props

    const mainHeaderConfig = config.MainHeader
    const { baseText } = mainHeaderConfig
    const adNormSetsTabConfig = config.AdNormSetsTab

    const validAdSets = ads['ad'].valid && ads['norm'].valid
    const highlight = {
      ad: ads.ad.is_perishable,
      norm: ads.norm.is_perishable
    }
    const numAds = calcFlags && calcFlags.ad && calcFlags.ad.excludeNonNorm ? ads.ad.list.length - ads.ad.zeroList.length : ads.ad.list.length
    const numNormAds = calcFlags && calcFlags.norm && calcFlags.norm.excludeNonNorm ? ads.norm.list.length - ads.norm.zeroList.length : ads.norm.list.length
    const scorecardAvailable = numAds && numNormAds
    return (
      <AppBar
        style={{ boxShadow: 'none' }}
        position="static"
        className={classes.container}
        classes={{
          colorPrimary: classes.colorPrimary
        }}
        id={`${title}-app-bar-id`}
      >
        <Grid container>
          <Grid item xs={12} className={classes.titleBar}>
            <Grid container justify="space-between">
              {/* Title & AdType */}
              <Grid item>
                <Grid
                  container
                  justify="flex-start"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item>
                    <Typography variant="h4" color="textPrimary">
                      {title}
                    </Typography>
                  </Grid>
                  {selections &&
                    Object.keys(selections).map(key => (
                      <Grid key={key} item>
                        <AdType adType={selections[key]} />
                      </Grid>
                    ))}
                </Grid>
              </Grid>

              {/* Ads Selected / View Scorecard / Builder buttons */}
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item style={{ marginRight: 40 }}>
                    <Typography
                      variant="h6"
                      align="right"
                      color="textPrimary"
                      className={cn(highlight.ad ? classes.highlight : null)}
                      classes={{h6:classes.mainLabel}}
                    >
                      {numAds}
                    </Typography>
                    <Typography variant="caption" color="textPrimary" align="right">
                      {mainHeaderConfig.selectedLabel}
                    </Typography>
                    {ads['ad'].valid ? null : (
                      <Typography
                        variant="caption"
                        style={{ color: 'red' }}
                        align="right"
                      >
                        {`Limit of ${maxAdSetSize} ${ baseText}s Exceeded`}
                      </Typography>
                    )}
                    {numAds === maxAdSetSize ? (
                      <Typography
                        variant="caption"
                        style={{ color: 'orange' }}
                        align="right"
                      >
                        {`Limit of ${maxAdSetSize} ${baseText}s Reached`}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item style={{ marginRight: 40 }}>
                    <Typography
                      variant="h6"
                      align="right"
                      color="textPrimary"
                      className={cn(highlight.norm ? classes.highlight : null)}
                      classes={{h6:classes.mainLabel}}
                    >
                      {numNormAds}
                    </Typography>
                    <Typography variant="caption" color="textPrimary" align="right">
                      {mainHeaderConfig.normSelectedLabel}
                    </Typography>
                    {ads['norm'].valid ? null : (
                      <Typography
                        variant="caption"
                        style={{ color: 'red' }}
                        align="right"
                      >
                        {`Limit of ${maxAdSetSize} Norm ${baseText}s Exceeded`}
                      </Typography>
                    )}
                    {numNormAds === maxAdSetSize ? (
                      <Typography
                        variant="caption"
                        style={{ color: 'orange' }}
                        align="right"
                      >
                        {`Limit of ${maxAdSetSize} Norm ${baseText}s Reached`}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      justify="flex-end"
                      spacing={2}
                      alignItems="center"
                      style={{marginTop: 0}}
                    >
                      {openScorecard ? (
                        <ViewScorecardButton
                          disabled={!scorecardAvailable || !validAdSets}
                          action={openScorecard}
                        />
                      ) : null}
                      {builderAction ? (
                        <ViewBuilderButton action={builderAction} />
                      ) : null}
                      {presetsAction ? (
                        <ViewPresetsButton action={presetsAction} />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {tabs ? (
            <AdNormSetsTab selectedTab={selectedTab} updateTab={updateTab} config={adNormSetsTabConfig} />
          ) : null}
        </Grid>
      </AppBar>
    )
  }
}

MainHeader.defaultProps = {
  selectedTab: 0,
  highlight: {}
}
export default Styled(MainHeader)
