// TODO: Fix the Fidelity hardcoded values in this file.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { generateUUID } from '../../../../new_utils'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
  cell: {
    padding: '2px 10px',
    textAlign: 'center',
    height: 48
  },
  children: {
    padding: '2px 10px',
    textAlign: 'center',
    width: '10%'
  },
  comment: {
    padding: '2px 10px',
    textAlign: 'left',
    width: '35%'
  },
  body: {
    height: 600,
    overflowY: 'scroll'
  },
  chip: {
    margin: theme.spacing(1)
  },
  hover: {
    '&:hover': {
      backgroundColor: theme.palette.listSelectBackground.default
    }
  }
})

class VerbatimList extends Component {
  state = {
    page: 0,
    rowsPerPage: 25
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  render() {
    const { page, rowsPerPage } = this.state
    const {
      classes,
      word,
      data: { list, verbatimColumns }
    } = this.props

    const regex = new RegExp(`(^| |\\.|\\?|\\,)${word}($| |\\.|\\?|\\,)`, 'g')

    const filteredData = word.length
      ? list.filter(d => {
          return d.comment && !!d.comment.toLowerCase().match(regex)
        })
      : list

    const rows = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    if (rows.length === 0) {
      return (
        <Typography align="center" variant="subtitle1" color="inherit" noWrap>
          (there are currently no items in this view)
        </Typography>
      )
    }

    return (
      <div className={classes.root}>
        {word.length ? (
          <Grid style={{ marginTop: -60 }} container justify="flex-end">
            <Grid item>
              <Chip label={`${word} (${filteredData.length})`} className={classes.chip} />
            </Grid>
          </Grid>
        ) : null}
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                {verbatimColumns.map(column => (
                  <TableCell className={classes.cell} key={column.code}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell className={classes.comment}>Comment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(d => {
                return (
                  <TableRow key={d.key} classes={{ root: classes.hover }}>
                    {verbatimColumns.map(column => (
                      <TableCell className={classes.cell} key={generateUUID()}>
                        {d[column.code]}
                      </TableCell>
                    ))}
                    <TableCell className={classes.comment}>{d.comment}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    )
  }
}

// VerbatimList.propTypes = {
//   classes: PropTypes.object.isRequired,
//   word: PropTypes.string.isRequired,
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       customer: PropTypes.string.isRequired,
//       age: PropTypes.string.isRequired,
//       children: PropTypes.string.isRequired,
//       gender: PropTypes.string.isRequired,
//       value: PropTypes.string.isRequired,
//       comment: PropTypes.string.isRequired
//     })
//   ).isRequired,
//   question: PropTypes.number.isRequired
// }

export default withStyles(styles)(VerbatimList)
