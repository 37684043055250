import React  from 'react'
import { timeFormat } from 'd3-time-format'
import cn from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import BuildIcon from '@material-ui/icons/Build'
import ScorecardIcon from '@material-ui/icons/FormatIndentIncrease'


import AdType from '../../../generic/AdType'

const Styled = withStyles(theme =>
  theme.components.selection.PresetSelect.PresetListView(theme)
)

const formatDate = d => {
  const date = new Date(d)
  const format = timeFormat('%m/%d/%Y')
  return format(date)
}

class PresetListView extends React.PureComponent {

  buildItem = (e, item) => {
    const { buildItem } = this.props
    e.stopPropagation()
    buildItem(item.uuid)
  }

  selectItem = (e, item) => {
    const { selectItem } = this.props
    e.stopPropagation()
    selectItem(item.uuid)
  }


  deleteItem = (e, item) => {
    const {deleteItem } = this.props
    e.stopPropagation()
    deleteItem(item.uuid)
  }

  openScorecard = (e, item) => {
    const { openScorecard } = this.props
    e.stopPropagation()
    openScorecard(item.uuid)
  }

  render() {
    const {
      adType,
      items,
      classes,
      selectedItem,
      viewHeight,
      scorecardAvailable,
    } = this.props

    const presetListViewConfig = this.props.config.presetListView.columnLabels

    return (
      <div style={{ overflowX: 'auto', height: viewHeight }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{presetListViewConfig.view}</TableCell>
              <TableCell>{presetListViewConfig.title}</TableCell>
              <TableCell>{presetListViewConfig.mediaType}</TableCell>
              <TableCell>{presetListViewConfig.dateCreated}</TableCell>
              <TableCell>{presetListViewConfig.type}</TableCell>
              <TableCell>{presetListViewConfig.scorecard}</TableCell>
              <TableCell>{presetListViewConfig.build}</TableCell>
              <TableCell>{presetListViewConfig.delete}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map( (item, index) => (
              <TableRow
                hover
                key={`${item.uuid}`}
                onClick={e => this.selectItem(e, item)}
                className={cn(
                 selectedItem && item.uuid === selectedItem.uuid ? classes.selected : null                  
                )}
              >
                <TableCell style={{ padding: '4px 0px 4px 12px' }}>
                  <div
                    className={classes.media}
                    style={{
                      backgroundImage: `url(${item.link})`
                    }}
                  />
                </TableCell>
                <TableCell style={{ padding: '4px 12px 4px 0px' }}>
                  <Typography
                    className={classes.title}
                    variant="body2"
                    color="inherit"
                    noWrap
                  >
                    {item.label}
                  </Typography>
                </TableCell>
                <TableCell>
                  <AdType adType={adType} />
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.center}
                    variant="body2"
                    color="inherit"
                    noWrap
                  >
                    {formatDate(item.date)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.center}
                    variant="body2"
                    color="inherit"
                    noWrap
                  >
                    {item.is_standard ? 'Standard' : 'Custom'}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', padding: '4px 2px 4px 2px' }}
                >
                  <Tooltip
                    id="build-list-tooltip"
                    title={'Select item and go to Scorecard'}
                  >
                    <IconButton
                      onClick={e => this.openScorecard(e, item)}
                      align="left"
                      disabled={!scorecardAvailable}
                    >
                      <ScorecardIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', padding: '4px 2px 4px 2px' }}
                >
                  <Tooltip
                    id="build-list-tooltip"
                    title={'Select item and go to Builder'}
                  >
                    <IconButton
                      onClick={e => this.buildItem(e, item)}
                      align="left"
                    >
                      <BuildIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', padding: '4px 22px 4px 2px' }}
                >
                  <Tooltip
                    id="delete-tooltip"
                    title={
                      item.is_standard
                        ? 'Standard Sets cannot be deleted'
                        : 'Delete'
                    }
                  >
                    <div>
                      <IconButton
                        onClick={e => this.deleteItem(e, item)}
                        disabled={item.is_standard}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default Styled(PresetListView)
