import { generateUUID } from '../../../new_utils/'

export function addHashesToPresets(list) {
  const hashMap = {}
  const updated = []

  for (let i = 0; i < list.length; i++) {
    const nextItem = list[i]
    const saveHash = generateUUID()

    if (!hashMap[saveHash]) {
      updated.push({ ...nextItem, saveHash })
      hashMap[saveHash] = true
    }
  }

  return updated
}

export const listToMap = (list, fieldMap) => {
  return list.reduce((obj, item) => {
    obj[item[fieldMap]] = item
    return obj
  }, {})
}

export const initStaticFilters = staticFiltersStructure => {
  const key = Object.keys(staticFiltersStructure)[0]
  return key === '***' ? staticFiltersStructure[key] : staticFiltersStructure[key][0]
}

export const selectStaticFilter = state => {
  const {
    staticFiltersStructure,
    dynamicFilters: { selections }
  } = state

  const key = Object.keys(staticFiltersStructure)[0]

  if (key === '***') return staticFiltersStructure['***'].slice()

  const field = selections.find(selection => selection.field === key)
  return staticFiltersStructure[key][field.value] || staticFiltersStructure[key][0]
}

export const updateStaticFilter = (state, value) => {
  const staticFilters = state.staticFilters.map(staticFilter => {
    if (staticFilter.code !== value.code)
      return {
        ...staticFilter,
        options: staticFilter.options.map(option => {
          return { ...option, selected: false }
        })
      }

    return {
      ...staticFilter,
      options: staticFilter.options.map(option => {
        const selected = Array.isArray(value.value)
          ? value.value.includes(option.value)
          : value.value === option.value && !option.selected
        return {
          ...option,
          selected
        }
      })
    }
  })
  const selected = staticFilters.find(filter =>
    filter.options.find(option => option.selected)
  )
  //const staticFilterQuery = selected ? { code: selected.code, value: multiIndexMap[selected.options.filter( option => option.selected).map( option => option.value).join('')]} : {}
  const staticFilterQuery = selected
    ? {
      code: selected.code,
      value: selected.options
        .filter(option => option.selected)
        .map(option => option.value)
    }
    : {}

  return {
    staticFilters,
    staticFilterQuery
  }
}

export const  getDisplayFilters = struct => {
  return struct.map(displayFilter => {
    return {
      ...displayFilter,
      options: [],
    }
  })
}

export const getDisplayFilterOptions = options => {
  return (options || []).map((option, index) => {
    return {
      label: option,
      value: index,
      selected: false,
    }
  })
}

export const updateDisplayFilters = (state, { code, value, multi }) => {
  return state.map(displayFilter => {
    if (displayFilter.code !== code) return displayFilter
    return {
      ...displayFilter,
      options: displayFilter.options.map((option, index) => {
        return {
          ...option,
          selected: multi
            ? value.includes(index)
              ? true
              : false
            : index === value && !option.selected,
        }
      }),
    }
  })
}

export const clearDisplayFilters = state => {
  return state.map( displayFilter => {
    return {
      ...displayFilter,
      options: displayFilter.options.map( option => {
        return {
          ...option,
          selected: false
        }
      })
    }
  })
}
