export default theme => {
  return {
    dialog: {
      width: 400
    },
    grid: {
      padding: '10px 0px'
    },
    button: {
      margin: '-5px 0 0 20px !important'
    },
    textField: {
      color: theme.palette.text.primary
    }
  }
}
