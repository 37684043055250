import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Loading from './Loading'
import { appSetError } from '../../new_actions'

const Styled = withStyles(theme => theme.components.generic.AsyncWrapper(theme))

class AsyncWrapper extends Component {

  componentDidUpdate(prevProps, prevState) {
    const { item: { error }} = this.props
    if (prevProps.item.error !== error) {
      appSetError(error)
    }
  }

  render() {
    const {
      size,
      thickness,
      children,
      item: { error, ready },
      loadingMsg
    } = this.props

    return ready || error ? (
      children
    ) : (
      <div style={{ height: '100%', marginTop:100 }}>
        <Loading size={size} thickness={thickness} msg={loadingMsg} />
      </div>
    )
  }
}

AsyncWrapper.defaultProps = {
  size: 50,
  thickness: 3.6
}

export default Styled(AsyncWrapper)
