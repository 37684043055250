import {
  APP_CLR_CONFIG,
  FORD_SCORECARD_RECEIVE,
  FORD_UPDATE_DISPLAY_FILTER,
  FORD_UPDATE_DYNAMIC_FILTER,
  APP_SET_AUTO_CONFIG,
} from '../actions'

import { getDisplayFilters, getDisplayFilterOptions, updateDisplayFilters, clearDisplayFilters} from './utils'
const initialState = []

export default function(state = initialState, action) {
  switch (action.type) {
    case FORD_SCORECARD_RECEIVE:
      return state.map(filter => {
        return {
          ...filter,
          options: getDisplayFilterOptions(action.data.displayFilters[filter.code]),
        }
      })

    case FORD_UPDATE_DISPLAY_FILTER:
      return updateDisplayFilters(state, action.data)

      case FORD_UPDATE_DYNAMIC_FILTER:
        return clearDisplayFilters(state)
        
    case APP_SET_AUTO_CONFIG:
      return getDisplayFilters(action.data.displayFilters)

    case APP_CLR_CONFIG:
      return [...initialState]

    default:
      return [...state]
  }
}
