const adNormSetsTabConfig = {
  tabsList: [
    {
      key: 'ad',
      label: 'Ad Sets'
    },
    {
      key: 'norm',
      label: 'norms'
    }
  ]
}
export default adNormSetsTabConfig
