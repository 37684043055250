export default {
  '***': {
    extraFilter: {
      type: 'sampleSizeFilter',
      ad_type: ['***'],
      disabledState: 'hidden',
      data: {
        sampleSize: 0
      }
    }
  }
}