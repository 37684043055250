import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Excel from 'exceljs/dist/exceljs'
import { rowColToAdr } from '../../utils'
import { monthCodeToText } from '../../../../new_utils'

const dt = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export default class ExportTrendCharts extends Component {
  getImageId(workbook, selector) {
    const dataURL = this.getDataURL(selector)
    const imageId = workbook.addImage({
      base64: dataURL,
      extension: 'png'
    })

    return imageId
  }

  getDataURL(selector) {
    const container = document.getElementById(selector)

    if (container && container instanceof HTMLElement) {
      const canvas = container.getElementsByTagName('canvas')

      if (canvas && canvas.length > 0) {
        return canvas[0].toDataURL()
      }
    }

    return ''
  }

  addChartSheet(workbook) {
    const { data } = this.props
    const {
      metricsData: { trendMetrics }
    } = data
    const sheet = workbook.addWorksheet('Charts')

    sheet.views = [
      {
        state: 'normal',
        activeCell: 'Z1000',
        showGridLines: false
      }
    ]

    const rowSize = 25
    const imgRowSize = 20
    const imgColSize = 20

    trendMetrics.forEach((trendMetric, index) => {
      const row = index * rowSize + 1
      const cell = rowColToAdr(index * rowSize + 1, 1)
      sheet.getCell(cell).value = trendMetric.label
      sheet.getRow(row).font = { size: imgRowSize, bold: true }
      sheet.getRow(row).height = rowSize
      const adpiImageId = this.getImageId(workbook, trendMetric.field)
      sheet.addImage(adpiImageId, {
        tl: { col: 1, row },
        br: { col: imgColSize, row: row + imgRowSize }
      })
    })
  }

  addDataSheet(workbook) {
    const { data, ad, gridData : {
      normHeaders
    } } = this.props
    const {
      metricsData: { trendMetrics, trendCharts, spend },
      normData
    } = data
    const { title } = ad

    const sheet = workbook.addWorksheet('Data')

    sheet.columns = [
      { header: 'Month', key: 'monthText', width: 15 },
      { header: 'Metric Label', key: 'label', width: 35 },
      { header: 'Ad Code', key: 'adCode', width: 15 },
      { header: 'Ad Title', key: 'title', width: 35 },
      { header: 'Metric Value', key: 'main', width: 15 },
      { header: 'Norm Value', key: 'norm', width: 15 },
      { header: 'Spend', key: 'spend', width: 15 },
      { header: 'Sample Size', key: 'size', width: 15 }
    ]

    sheet.views = [
      {
        state: 'normal',
        activeCell: 'Z1000',
        showGridLines: true
      }
    ]

    sheet.getRow(1).font = { size: 12, bold: true }

    const rows = trendMetrics.reduce((array, trendMetric) => {
      const temp = trendCharts[trendMetric.field].map((row, index) => {
        const _temp = normData.find(norm => norm.metric === row.metric) || normHeaders.header_metrics.find( normHeader => normHeader.metric === row.metric)
        const norm = (_temp && _temp.avg) || 0
        return {
          ...trendMetric,
          monthText: monthCodeToText(row.month_code),
          adCode: row.ad_code,
          title,
          main: row.avg,
          norm: isNaN(norm) ? 'N/A' : +norm,
          spend: (spend[index] && spend[index].spend) || null,
          size: row.n
        }
      })
      return [...array, ...temp]
    }, [])

    const filteredRows = rows.filter(row=>row.main)
    filteredRows.forEach(row => sheet.addRow(row))

    const textColumns = [1, 2, 3, 4]

    textColumns.forEach(col => {
      sheet.getColumn(col).eachCell(function(cell) {
        const horizontal = col === 2 || col === 4 ? 'left' : 'center'
        cell.alignment = { vertical: 'middle', horizontal }
      })
    })

    const numericColumns = [5, 6, 7, 8]

    numericColumns.forEach(col => {
      sheet.getColumn(col).eachCell(function(cell, rowNumber) {
        if (rowNumber === 1 || col === 8) {
          cell.numFmt = '#'
        } else {
          if (col === 5 || col === 6) {
            cell.numFmt = filteredRows[rowNumber-2].format.includes('%') ? '0.0%' : '#'
          } else if (col === 7) {
            cell.numFmt = '$#,###'
          }
        }
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
      })
    })
  }

  exportFile = () => {

    const workbook = new Excel.Workbook()
    this.addChartSheet(workbook)
    this.addDataSheet(workbook)

    workbook.xlsx.writeBuffer({ base64: true }).then(xls64 => {
      const blb = new Blob([xls64], { type: dt })
      const url = URL.createObjectURL(blb)

      const tag = document.createElement('a')
      tag.href = url
      tag.download = 'TrendCharts.xlsx'

      document.body.appendChild(tag)
      tag.click()

      this.setState({ isFetching: false, hasErrored: false })

      setTimeout(function() {
        document.body.removeChild(tag)
        window.URL.revokeObjectURL(url)
      }, 0)
    })
  }

  render() {
    const { children } = this.props

    return (
      <Button color="secondary" variant="contained" onClick={this.exportFile}>
        {children || 'Download'}
      </Button>
    )
  }
}
