import React from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const Styled = withStyles(theme => theme.components.generic.Loading(theme) ) // theme.components.generic.Loading(theme))

export default Styled(props => {
  const { size, thickness, msg } = props
  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        <CircularProgress thickness={thickness || 3} size={size || 300} color="inherit"/>
      </Grid>
      <Grid item>
        <Typography variant="body2">{msg}</Typography>
      </Grid>
    </Grid>
  )
})
