import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid as VGrid, AutoSizer } from 'react-virtualized'
import scrollbarSize from 'dom-helpers/util/scrollbarSize'

const Styled = withStyles(theme => {
  const defaultTheme = theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardScrollableColumn(theme)
  return {
    ...defaultTheme,
    gridLeft: {
      overflow: 'hidden !important'
    },
    gridHeader: {
      ...defaultTheme.gridHeader,
      width: '100%',
      overflow: 'hidden !important'
    },
    gridBody: {
      width: '100%'
    }
  }
})

class ScorecardSCrollableColumn extends React.PureComponent {
  renderTitle = ({ columnIndex, key, style }) => {
    const { classes, title, ...params } = this.props
    return (
      <div key={key} style={style}>
        {React.createElement(title, { ...params, columnIndex })}
      </div>
    )
  }

  renderRows = ({ columnIndex, rowIndex, key, style }) => {
    const { classes, rows, ...params } = this.props
    return (
      <div key={key} style={style}>
        {React.createElement(rows, { ...params, rowIndex, columnIndex })}
      </div>
    )
  }

  render() {
    const {
      classes,
      config: {
        rowCount,
        columnCount,
        headerHeight,
        metricWidth,
        scrollLeft,
        onScroll
      },
      leftOffset,
      viewHeight,
      gridData
    } = this.props
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto'
        }}
      >
        <AutoSizer disableHeight dummy={viewHeight}>
          {({ width }) => (
            <Fragment>
              <div
                style={{
                  marginLeft: leftOffset,
                  height: headerHeight,
                  width: viewHeight - leftOffset
                }}
              >
                <VGrid
                  className={classes.gridHeader}
                  cellRenderer={this.renderTitle}
                  rowCount={1}
                  columnCount={columnCount}
                  height={headerHeight}
                  rowHeight={headerHeight}
                  width={width - leftOffset - scrollbarSize()}
                  columnWidth={metricWidth}
                  scrollLeft={scrollLeft}
                  gridData={gridData}
                />
              </div>
              <div
                style={{
                  marginLeft: leftOffset,
                  height: viewHeight,
                  width: width - leftOffset
                }}
                className={classes.metrics}
              >
                <VGrid
                  className={classes.gridBody}
                  cellRenderer={this.renderRows}
                  rowCount={rowCount}
                  columnCount={columnCount}
                  height={viewHeight - headerHeight}
                  width={width - leftOffset}
                  rowHeight={({ index }) =>
                    gridData.rowHeights[index].rowHeight
                  }
                  columnWidth={metricWidth}
                  onScroll={onScroll}
                  gridData={gridData}
                />
              </div>
            </Fragment>
          )}
        </AutoSizer>
      </div>
    )
  }
}

export default Styled(ScorecardSCrollableColumn)
