export default {
  tabs: [
    {
      key: 'user',
      label: 'Manage Users',
      access: 'isManager'
    },
    {
      key: 'config',
      label: 'Configure Server',
      access: 'isMigrator'
    },
    {
      key: 'metadata',
      label: 'Edit Metadata',
      access: 'isEditor'
    }
  ]
}
