import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid as VGrid, AutoSizer, ScrollSync } from 'react-virtualized'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import TimeIcon from '@material-ui/icons/AccessTime'
import SizeIcon from '@material-ui/icons/People'
import scrollbarSize from 'dom-helpers/util/scrollbarSize'
import ImageMenu from './ImageMenu'
import cn from 'classnames'
import TypeIcon from '../../TypeIcon'
import { format as d3format } from 'd3-format'
const styles = theme => ({
  cell: {
    width: '100%',
    height: '100%'
  },
  header: {
    width: '100%',
    height: '100%',
    color: '#000000',
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  normHeader: {
    // marginTop: 0,
    width: '100%',
    height: '100%',
    color: '#000000',
    padding: 0,
    backgroundColor: theme.palette.grey[200]
  },
  headerCell: {
    width: 120,
    height: 18
    // paddingTop: 2
  },
  mediaHeader: {
    width: '100%',
    height: 92,
    backgroundColor: '#eeeeee',
    marginBottom: 3
  },
  textHeaderCell: {
    width: '100%',
    paddingLeft: 14,
    height: 18
  },
  mainCell: {
    padding: 0
  },
  normCell: {
    padding: 0,
    width: '100%'
  },
  textCell: {
    paddingLeft: 0
  },
  gridRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row'
  },
  gridColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto'
  },
  leftSideGridContainer: {
    flex: '0 0 75px',
    zIndex: 1000
  },
  leftSideGrid: {
    overflow: 'hidden !important'
  },
  headerGrid: {
    width: '100%',
    overflow: 'hidden !important'
  },
  bodyGrid: {
    width: '100%'
  },
  brand: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    display: 'inline-block',
    margin: 2,
    width: 80,
    height: 50
  },
  thumb: {
    boxShadow: theme.shadows[4],
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    display: 'inline-block',
    margin: 2,
    width: 90,
    height: 40
  },
  iconSmall: {
    width: 10,
    height: 10,
    fontSize: 10,
    marginRight: 5
  }
})

class Scorecard extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      columnWidth: 125,
      columnCount: 50,
      overscanColumnCount: 0,
      overscanRowCount: 5
    }
  }

  getRowHeight({ index, rowCount }) {
    if (index === rowCount - 1) {
      return 15
    }
    return 20
  }

  renderTextHeader = ({ key, style }) => {
    const {
      classes,
      data: { mediaType, monthText, phase }
    } = this.props

    return (
      <div className={classes.header} key={key} style={style}>
        <Grid
          style={{ flexGrow: 1, marginTop: 0 }}
          container
          direction="column"
          alignItems="center"
          justify="flex-end"
        >
          <Grid className={classes.mediaHeader} item>
            <Grid container spacing={2} />
            <Grid
              item
              style={{ marginLeft: 10, marginTop: 18, marginBottom: 8 }}
            >
              <TypeIcon
                type={mediaType}
                iconStyle={{
                  display: 'inline-block',
                  fontSize: 18,
                  marginTop: 10,
                  marginBottom: -3,
                  marginRight: 5
                }}
              />
              <Typography
                variant="caption"
                style={{ display: 'inline-block' }}
              >{`${mediaType} Ads`}</Typography>
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>
              <Typography variant="h6" style={{ fontSize: 18 }}>
                {`${monthText} ${
                  phase ? (phase === 2 ? 'Phase 1 & 2' : 'Phase 1') : ''
                }`}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={cn(classes.headerCell, classes.textHeaderCell)} item>
            <Typography color="inherit" align="left" variant="caption">
              Title
            </Typography>
          </Grid>
          <Grid className={cn(classes.headerCell, classes.textHeaderCell)} item>
            <Typography color="inherit" align="left" variant="caption">
              Ad Code
            </Typography>
          </Grid>
          <Grid className={cn(classes.headerCell, classes.textHeaderCell)} item>
            <Typography color="inherit" align="left" variant="caption">
              Vehicle Type
            </Typography>
          </Grid>
          <Grid className={cn(classes.headerCell, classes.textHeaderCell)} item>
            <Typography color="inherit" align="left" variant="caption">
              SOV/Proprietary
            </Typography>
          </Grid>
          <Grid className={cn(classes.headerCell, classes.textHeaderCell)} item>
            <Typography color="inherit" align="left" variant="caption">
              Model
            </Typography>
          </Grid>
          <Grid className={cn(classes.headerCell, classes.textHeaderCell)} item>
            <Typography color="inherit" align="left" variant="caption">
              Duration
            </Typography>
          </Grid>
          <Grid className={cn(classes.headerCell, classes.textHeaderCell)} item>
            <Typography color="inherit" align="left" variant="caption">
              Base Size
            </Typography>
          </Grid>
          <Grid className={cn(classes.headerCell, classes.textHeaderCell)} item>
            <Typography color="inherit" align="left" variant="caption">
              LTD Media Spend ($000s)
            </Typography>
          </Grid>
          <Grid className={cn(classes.headerCell, classes.textHeaderCell)} item>
            <Typography color="inherit" align="left" variant="caption">
              First Air or Print Date
            </Typography>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderNormHeader = ({ columnIndex, key, style }) => {
    const {
      classes,
      normLabel
    } = this.props

    return (
      <div className={classes.normHeader} key={key} style={style}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="flex-end"
        >
          <Grid style={{ padding: 9 }} item>
            <Tooltip title={normLabel}>
              <Typography color="inherit" align="center" variant="subtitle1">
                NORM
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderMainHeader = ({ columnIndex, key, style }) => {
    const {
      classes,
      gridData: { mainHeader, monthlyStats },
      viewerOpen,
      clickHeader,
      monthCode,
    } = this.props
    const ad = mainHeader[columnIndex] || { links: {} }

    let spend_ltd =
      monthlyStats &&
      monthlyStats[ad.ad_code] &&
      monthlyStats[ad.ad_code].spend_ltd
    spend_ltd = spend_ltd && +spend_ltd.replace('$', '').replace(/,/g, '')

    spend_ltd =
        isNaN(spend_ltd) || spend_ltd === null
        ? 'N/A'
        : d3format('$,.0f')(`${Math.round(spend_ltd)}`)

    return (
      <div
        className={classes.header}
        key={key}
        style={{ ...style, paddingLeft: 10 }}
      >
        {/* <div style={{ marginLeft: 10, marginRight: 10, width: 80 }}> */}
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          justify="center"
        >
          <Grid style={{ height: 25, margin: 'auto' }} item>
            <img style={{ height: 25 }} src={ad.links.brand} alt={ad.brand} />
          </Grid>
          <Grid style={{ padding: 0 }} item>
            <ImageMenu ad={ad} monthCode={monthCode} viewerOpen={viewerOpen} />
          </Grid>
          <Grid onClick={clickHeader} style={{ marginTop: -3 }}>
            <Tooltip title={ad.title} placement="bottom">
              <Grid className={classes.headerCell} item>
                <Typography
                  noWrap
                  align="left"
                  variant="caption"
                  color="inherit"
                  display="block"
                >
                  {ad.title}
                </Typography>
              </Grid>
            </Tooltip>
            <Grid className={classes.headerCell} item>
              <Tooltip title={ad.adCode} placement="bottom">
                <Typography
                  noWrap
                  align="left"
                  variant="caption"
                  color="inherit"
                  display="block"
                >
                  {ad.adCode}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid className={classes.headerCell} item>
              <Tooltip title={ad.sample || ''} placement="bottom">
                <Typography
                  noWrap
                  align="left"
                  variant="caption"
                  color="inherit"
                  display="block"
                >
                  {ad.sample || 'N/A'}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid className={classes.headerCell} item>
              <Typography noWrap align="left" variant="caption" color="inherit">
                {ad.sov || 'N/A'}
              </Typography>
            </Grid>
            <Tooltip title={ad.model} placement="bottom">
              <Grid className={classes.headerCell} item>
                <Typography
                  noWrap
                  align="left"
                  variant="caption"
                  color="inherit"
                  display="block"
                >
                  {ad.model || 'N/A'}
                </Typography>
              </Grid>
            </Tooltip>
            <Grid
              className={classes.headerCell}
              style={{ fontFamily: 'Roboto', fontSize: 12 }}
              item
            >
              <div
                style={{
                  paddingTop: 0,
                  display: 'inline-block',
                  marginLeft: 0
                }}
              >
                <TimeIcon className={classes.iconSmall} />
              </div>
              <div style={{ display: 'inline-block' }}>{ad.length}</div>
            </Grid>
            <Grid
              style={{ padding: 0, fontFamily: 'Roboto', fontSize: 12 }}
              item
            >
              <div
                style={{
                  paddingTop: 3,
                  display: 'inline-block',
                  marginLeft: 0
                }}
              >
                <SizeIcon className={classes.iconSmall} />
              </div>
              <div style={{ display: 'inline-block' }}>{ad.sampleSize}</div>
            </Grid>
            <Grid className={classes.headerCell} item>
              <Typography
                noWrap
                align="left"
                variant="caption"
                color="inherit"
                display="block"
              >
                {spend_ltd}
              </Typography>
            </Grid>
            <Grid className={classes.headerCell} item>
              <Typography
                noWrap
                align="left"
                variant="caption"
                color="inherit"
                display="block"
              >
                {ad.first_air_date || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
      // </div>
    )
  }

  renderTextCell = ({ columnIndex, rowIndex, key, style }) => {
    const {
      classes,
      gridData: { textTable }
    } = this.props
    const { value } = textTable[rowIndex][columnIndex]
    const s = textTable[rowIndex][columnIndex].style
    return (
      <div className={classes.textCell} key={key} style={style}>
        <Tooltip id={`tooltip-${value}`} placement="top-start" title={value}>
          <div
            style={{
              paddingTop: 2,
              color: s.color,
              backgroundColor: s.bgColor,
              paddingLeft: (+s.offset || 0) * 20 + 5,
              height: '100%',
              fontWeight: s.fontWeight || 'normal'
            }}
          >
            <Typography
              color="inherit"
              align="left"
              variant="caption"
              style={{ fontWeight: s.fontWeight || 'normal' }}
              display="block"
              noWrap
            >
              {value}
            </Typography>
          </div>
        </Tooltip>
      </div>
    )
  }

  renderNormCell = ({ columnIndex, rowIndex, key, style }) => {
    const {
      classes,
      gridData: { normTable }
    } = this.props
    const { value, color, bgColor } = normTable[rowIndex][columnIndex]
    return (
      <div
        className={classes.normCell}
        key={key}
        style={{
          ...style,
          color,
          backgroundColor: bgColor,
          textAlign: 'center'
        }}
      >
        {/* <div
          style={{
            paddingTop: 2,
            color,
            backgroundColor: bgColor,
            height: '100%',
            width: '100%'
          }}
        > */}
        <Typography
          color="inherit"
          align="center"
          noWrap
          variant="caption"
          display="block"
        >
          {value}
        </Typography>
        {/* </div> */}
      </div>
    )
  }

  renderMainCell = ({ columnIndex, rowIndex, key, style }) => {
    const {
      classes,
      gridData: { mainTable }
    } = this.props
    const { value, color, bgColor } = mainTable[rowIndex][columnIndex]
    const s = mainTable[rowIndex][columnIndex].style
    const stat = mainTable[rowIndex][columnIndex].stat
    return (
      <div
        className={classes.mainCell}
        key={key}
        style={{ ...style, backgroundColor: s.bgColor }}
      >
        <div
          style={{
            marginTop: 4,
            marginLeft: 5,
            paddingTop: 2,
            color,
            backgroundColor: stat ? bgColor : s.bgColor,
            height: '75%',
            width: '95%',
            borderRadius: 5,
            textAlign: 'center'
          }}
        >
          <Typography
            color="inherit"
            align="center"
            noWrap
            variant="caption"
            style={{ display: 'inline-block', marginTop: -5 }}
            display="block"
          >
            {value}
          </Typography>
        </div>
      </div>
    )
  }

  render() {
    const {
      columnWidth,
      // height,
      overscanColumnCount,
      overscanRowCount
    } = this.state
    const {
      classes,
      height,
      headerHeight,
      gridData: { mainHeader, mainTable },
      sortParams
    } = this.props
    const columnCount = mainHeader.length
    const rowCount = mainTable.length

    return (
      <div style={{ marginBottom: 50 }}>
        <ScrollSync>
          {({
            clientHeight,
            clientWidth,
            onScroll,
            scrollHeight,
            scrollLeft,
            scrollTop,
            scrollWidth
          }) => {
            const textWidth = 300
            const normWidth = 75

            return (
              <div className={classes.gridRow}>
                <div
                  className={classes.leftSideGridContainer}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0
                  }}
                >
                  <VGrid
                    cellRenderer={this.renderTextHeader}
                    className={classes.headerGrid}
                    width={textWidth}
                    height={headerHeight}
                    rowHeight={headerHeight}
                    columnWidth={textWidth}
                    rowCount={1}
                    columnCount={1}
                  />
                </div>
                <div
                  className={classes.leftSideGridContainer}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: headerHeight
                  }}
                >
                  <VGrid
                    overscanColumnCount={overscanColumnCount}
                    overscanRowCount={overscanRowCount}
                    cellRenderer={this.renderTextCell}
                    columnWidth={textWidth}
                    columnCount={1}
                    className={classes.leftSideGrid}
                    height={height - scrollbarSize()}
                    rowHeight={({ index }) =>
                      this.getRowHeight({ index, rowCount })
                    }
                    rowCount={rowCount}
                    scrollTop={scrollTop}
                    width={textWidth}
                  />
                </div>
                <div
                  className={classes.leftSideGridContainer}
                  style={{
                    position: 'absolute',
                    left: textWidth,
                    top: 0
                  }}
                >
                  <VGrid
                    cellRenderer={this.renderNormHeader}
                    className={classes.headerGrid}
                    width={normWidth}
                    height={headerHeight}
                    rowHeight={headerHeight}
                    columnWidth={normWidth}
                    rowCount={1}
                    columnCount={1}
                  />
                </div>
                <div
                  className={classes.leftSideGridContainer}
                  style={{
                    position: 'absolute',
                    left: textWidth,
                    top: headerHeight
                  }}
                >
                  <VGrid
                    overscanColumnCount={overscanColumnCount}
                    overscanRowCount={overscanRowCount}
                    cellRenderer={this.renderNormCell}
                    columnWidth={normWidth}
                    columnCount={1}
                    className={classes.leftSideGrid}
                    height={height - scrollbarSize()}
                    rowHeight={({ index }) =>
                      this.getRowHeight({ index, rowCount })
                    }
                    rowCount={rowCount}
                    scrollTop={scrollTop}
                    width={normWidth}
                  />
                </div>
                <div className={classes.gridColumn}>
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <div>
                        <div
                          style={{
                            marginLeft: textWidth + normWidth,
                            height: headerHeight,
                            width:
                              width - scrollbarSize() - (textWidth + normWidth)
                          }}
                        >
                          <VGrid
                            className={classes.headerGrid}
                            columnWidth={columnWidth}
                            columnCount={columnCount}
                            height={headerHeight}
                            overscanColumnCount={overscanColumnCount}
                            cellRenderer={this.renderMainHeader}
                            sortBy={sortParams}
                            rowHeight={headerHeight}
                            rowCount={1}
                            scrollLeft={scrollLeft}
                            width={
                              width - scrollbarSize() - (textWidth + normWidth)
                            }
                          />
                        </div>
                        <div
                          style={{
                            marginLeft: textWidth + normWidth,
                            height,
                            width: width - (textWidth + normWidth)
                          }}
                        >
                          <VGrid
                            className={classes.bodyGrid}
                            columnWidth={columnWidth}
                            columnCount={columnCount}
                            height={height}
                            onScroll={onScroll}
                            overscanColumnCount={overscanColumnCount}
                            overscanRowCount={overscanRowCount}
                            cellRenderer={this.renderMainCell}
                            rowHeight={({ index }) =>
                              this.getRowHeight({ index, rowCount })
                            }
                            rowCount={rowCount}
                            width={width - (textWidth + normWidth)}
                            sortBy={sortParams}
                          />
                        </div>
                      </div>
                    )}
                  </AutoSizer>
                </div>
              </div>
            )
          }}
        </ScrollSync>
      </div>
    )
  }
}

Scorecard.propTypes = {
  gridData: PropTypes.object.isRequired,
  viewerOpen: PropTypes.func.isRequired
}

export default withStyles(styles)(Scorecard)
