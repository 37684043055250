
const prepTrendCharts = (data, props) => {
  const {
    ad,
    config: {
      trendChart: { trendMetrics, spendField, interpolateMonths }
    },
    normData
  } = props

  const trendCharts = trendMetrics.reduce((obj, trendMetric) => {
    const { field, metric } = trendMetric
    const trends = data
      .filter(sample => sample.metric === metric)
      .map(sample => {
        return {
          ...sample,
          ...trendMetric
        }
      })
      .sort((a, b) => (a.month_code > b.month_code ? 1 : -1))

    if (interpolateMonths) {
      const months = trends.map(trend => trend.month_code)
      let month = months[0]
      obj[field] = []
      while (month <= months[months.length - 1]) {
        const trendPoint = findMonthCode(trends,month)
        obj[field].push(
          trendPoint || {
            ...trends[0],
            avg: null,
            spend_ltd: null,
            n: null,
            month_code: month
          }
        )
        month += 1
      }
    } else {
      obj[field] = trends
    }

    obj.normData = normData[metric]
    return obj
  }, {})

  // const spendField = trendMetrics[0].field
  const field = Object.keys(trendCharts)[0]
  return {
    trendMetrics,
    trendCharts,
    spend: trendCharts[field].map(sample => {
      const month = ad.months.find(
        month => month.month_code === sample.month_code
      )
      const spend = month ? month[spendField || 'spend_ltd'] : 0
      return {
        month_code: sample.month_code,
        ad_code: sample.ad_code,
        metric: null,
        spend
      }
    })
  }
}

const findMonthCode = (trends,month) =>{
  return trends.find(trend => trend.month_code === month)
}

const prepWordCloud = (data, props) => {
  const { staticFilters, likeability } = props

  const counts = data.counts.map(d => ({
    word: d.word,
    score: +d.mean_score || null,
    count: +d.count_records,
    length: +d.word_length
  }))

  const verbatimColumns = likeability
    ? [
        ...staticFilters.filter(filter => !filter.excludeFromVerbatims),
        {
          code: 'value',
          label: 'Score'
        }
      ]
    : staticFilters.filter(filter => !filter.excludeFromVerbatims)

  const list = data.list.map((item, index) => {
    const columns = staticFilters.reduce((obj, filter) => {
      const option = filter.options.find(
        option => option.value === item[filter.code]
      )
      obj[filter.code] = (option && option.label) || ''
      return obj
    }, {})
    return {
      ...item,
      key: `verbatim-key${index}`,
      ...columns
    }
  })

  return { list, counts, verbatimColumns }
}

export const colToAdr = col => {
  const recurse = (n, r) => {
    if (n === 0) return r
    const rem = ((n - 1) % 26) + 1
    r = String.fromCharCode(rem + 64) + r
    return recurse((n - rem) / 26, r)
  }

  return recurse(col, '')
}

const rowColToAdr = (row, col) => {
  return `${colToAdr(col)}${row}`
}

const mergeCellsHorizontal = (sheet, row, col, numCells) => {
  const start = rowColToAdr(row, col)
  const end = rowColToAdr(row, col + numCells - 1)
  sheet.mergeCells(start, end)
  return sheet.getCell(start)
}

// export const grey = cell => {
//   cell.fill = {
//     type: 'pattern',
//     pattern: 'solid',
//     fgColor: { argb: `dddddd` },
//   }
//   return cell
// }

// export const center = cell => {
//   cell.alignment = {
//     vertical: 'top',
//     horizontal: 'center',
//   }
//   return cell
// }

// export const bold = cell => {
//   cell.font = {
//     bold: true,
//   }
//   return cell
// }

// export const left = cell => {
//   cell.alignment = {
//     vertical: 'top',
//     horizontal: 'left',
//   }
//   return cell
// }

export { rowColToAdr, prepTrendCharts, prepWordCloud, mergeCellsHorizontal }
