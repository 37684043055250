import defaultTheme from '../../Template/theme'
import components from './components'

const theme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    secondary: {
      main: '#005BBB',
      light: '#5887ee',
      dark: '#00338a',
      contrastText: '#ffffff'
    }
  },
  components
}

export { theme }
export default theme
