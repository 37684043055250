export default {
  '***': {
    '***': [
      {
        id: 10,
        label: 'Verical',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [20, 30, 40, 50, 60, 70, 75, 80],
        tooltip: 'This will be checked automatically when any item in this section is checked.'
      },
      {
        id: 20,
        label: 'Retail',
        selected: 0,
        collapse: 0,
        parent: 10,
        children: [],
        tooltip: 'Ads showing any retail store setting e.g., cycle shop, shoe store, lighting, rugs '
      },
      {
        id: 30,
        label: 'Restaurant',
        selected: 0,
        collapse: 0,
        parent: 10,
        children: [],
        tooltip:
          "Ads showing any restaurant setting"
      },
      {
        id: 40,
        label: 'Beauty',
        selected: 0,
        collapse: 0,
        parent: 10,
        children: [],
        tooltip: 'Ads showing any cosmetic, hair salon, nail salon setting'
      },
      {
        id: 50,
        label: 'Hardware',
        selected: 0,
        collapse: 0,
        parent: 10,
        children: [],
        tooltip: 'Ads highlighting hardware, point-of-sale equipment, credit card readers, contactless/tap-to-pay device '
      },
      {
        id: 60,
        label: 'Home and Repairs',
        selected: 0,
        collapse: 0,
        parent: 10,
        children: [],
        tooltip:
          'Ads highlighting home renovation, repairs, redecorating'
      },
      {
        id: 70,
        label: 'Professional Services',
        selected: 0,
        collapse: 0,
        parent: 10,
        children: [],
        tooltip: 'General professional services, not specific to an industry'
      },
      {
        id: 75,
        label: 'Other Businesses',
        selected: 0,
        collapse: 0,
        parent: 10,
        children: [],
        tooltip: 'Other types of businesses not listed above (e.g, bakery, ceramics, coffee)'
      },
      {
        id: 80,
        label: 'Multiple Businesses',
        selected: 0,
        collapse: 0,
        parent: 10,
        children: [],
        tooltip: 'More than one type of business shown in the ads (more than one code checked above)'
      },
      {
        id: 100,
        label: 'Product',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160],
        tooltip: 'This will be checked automatically when any item in this section is checked.'
      },
      {
        id: 105,
        label: 'Tap to pay/in-store payment',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Shows credit card tapping hardware to make payment and/or credit card inserted into device'
      },
      {
        id: 110,
        label: 'Hardware',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Shots of hardware and point-of-sale equipment, credit card readers, contactless/tap-to-pay device '
      },
      {
        id: 115,
        label: 'Online sales/orders',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Shows/says online orders/sales'
      },
      {
        id: 120,
        label: 'Inventory management',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Shows/says anything about inventory'
      },
      {
        id: 125,
        label: 'Access money',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Shows/says anything about access money'
      },
      {
        id: 130,
        label: 'Pay employees/Employee management',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Shows/say anything about paying employees, managing employee schedules'
      },
      {
        id: 135,
        label: 'Invoicing',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Shows/says anything about invoicing'
      },      
      {
        id: 140,
        label: 'Appointments',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Shows/says anything about appointments'
      },
      {
        id: 145,
        label: 'Sales trends/reporting',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Shows/says anything about sales trends, reporting sales numbers'
      },
      {
        id: 150,
        label: 'Marketing and promotion',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Shows/says anything about marketing and promotion'
      },
      {
        id: 155,
        label: 'Multiple products',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'When more than one of the above products are mentioned in the ad'
      },
      {
        id: 160,
        label: 'One stop shop/all in one',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: '"Messages about being able to do everything with one company. Messaging about connecting all of your business tools together, move in sync, connecting every side of your business "'
      },
      {
        id: 200,
        label: 'Square Slogan',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [210, 220, 230],
        tooltip: 'This will be checked automatically when any item in this section is checked.'
      },
      {
        id: 210,
        label: 'Everything your business needs',
        selected: 0,
        collapse: 0,
        parent: 200,
        children: [],
        tooltip: 'Ad must say/show the slogan, "everything your business needs"'
      },
      {
        id: 220,
        label: 'Tools for Commerce, Staff, Customers. All Working together',
        selected: 0,
        collapse: 0,
        parent: 200,
        children: [],
        tooltip: 'Ad must say/show the slogan, "Tools for Commerce, Staff, Customers. All Working together."'
      },
      {
        id: 230,
        label: 'The Shape of the Things to Come.',
        selected: 0,
        collapse: 0,
        parent: 200,
        children: [],
        tooltip: 'Ad must say/show the slogan, "The Shape of the Things to Come"'
      },
      {
        id: 300,
        label: 'Value',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [310, 320, 330],
        tooltip:  'This will be checked automatically when any item in this section is checked.'
      },
      {
        id: 310,
        label: 'Free trial',
        selected: 0,
        collapse: 0,
        parent: 300,
        children: [],
        tooltip: 'Show/says anything about a free trial'
      },
      {
        id: 320,
        label: 'Low fees',
        selected: 0,
        collapse: 0,
        parent: 300,
        children: [],
        tooltip: 'Shows/says anything about low/lowest transaction fees'
      },
      {
        id: 330,
        label: 'No fees',
        selected: 0,
        collapse: 0,
        parent: 300,
        children: [],
        tooltip: 'Shows/says anything about no fees, no hidden fees'
      },
      {
        id: 500,
        label: 'Brand',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [510, 520, 530, 540, 550],
        tooltip: 'This will be checked automatically when any item in this section is checked.'
      },
      {
        id: 510,
        label: 'Awards/Accolades',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Awards or accolades relating to the company overall. Could mention a third party rating system such as JD Powers, Kiplinger\'s, Local Weekly Best Of, etc.'
      },
      {
        id: 520,
        label: 'Brand / Reputation',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Ad that doesn\'t talk about any particular product but rather talks about the brand image in general.  E.g. "Our firm\'s been around a long time and are reliable. "We’ve been here a long time; look at how solid we are”'
      },
      {
        id: 530,
        label: 'Product Superiority',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Demonstrating that they have the strongest products or people – e.g. hardware, software, leadership, resources, performance; talking in such a way that they are setting themselves apart from others in the industry because of their superior offering and/or expertise'
      },
      {
        id: 540,
        label: 'Customer Service',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Showing any type of customer service interaction'
      },
      {
        id: 550,
        label: 'Sponsorship / Partnership',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'These ads communicate the company is sponsoring something such as donation drive to cancer research, Olympics sponsorship, local community events, races, theater, concert series etc.'
      },
      {
        id: 600,
        label: 'Creative Type',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [610, 620, 630, 640, 650, 660, 665, 670, 675, 680, 685, 690],
        tooltip: 'This will be checked automatically when any item in this section is checked.'
      },
      {
        id: 610,
        label: 'Live Action',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Refers to ads consisting of mostly filmed scenes.'
      },
      {
        id: 620,
        label: 'Vignette',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Scenes with different protagonists shown, no continuous story'
      },
      {
        id: 630,
        label: 'Story',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Continuous story with the same protagonists throughout the ad'
      },
      {
        id: 640,
        label: 'Animated',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Not live action'
      },
      {
        id: 650,
        label: 'Graphic',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Ad is computer generated primarily of product images, little to no focus on people or product demonstrations.'
      },
      {
        id: 660,
        label: 'Product Demo',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Refers to any ad focused on the product features or demonstration of the products, little to no focus on people.'
      },
      {
        id: 665,
        label: 'Voiceover Only',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Only Voice Overs, no character speaking parts'
      },
      {
        id: 670,
        label: 'Character speaking Only',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Only character speaking parts'
      },
      {
        id: 675,
        label: 'Multi Voice',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Ads that contain voiceover and characters speaking'
      },
      {
        id: 680,
        label: 'Supers only',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'No voice over or speaking'
      },
      {
        id: 685,
        label: 'Brand focused',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Ads that position the brand/product as the hero, is the focus of the spot'
      },
      {
        id: 690,
        label: 'Business Owner/Seller focused',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Ads that focus primarly on the business owner/seller, less on the sponsor or product'
      },
      {
        id: 700,
        label: 'Creative Style',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [710, 720, 730, 740, 750, 760, 770, 780, 790],
        tooltip: 'This will be checked automatically when any item in this section is checked.'
      },
      {
        id: 710,
        label: 'Emotional',
        selected: 0,
        collapse: 0,
        parent: 700,
        children: [],
        tooltip: 'Any ad that is designed to incite emotions (anger, sadness, joy, etc.) '
      },
      {
        id: 720,
        label: 'Spokesperson',
        selected: 0,
        collapse: 0,
        parent: 700,
        children: [],
        tooltip: 'Includes celebrity and non-celebrity spokepeople.'
      },
      {
        id: 730,
        label: 'Celebrity',
        selected: 0,
        collapse: 0,
        parent: 700,
        children: [],
        tooltip: 'Includes any celebrity acting or voice-over, even if celebrity is not a brand spokesperson.'
      },
      {
        id: 740,
        label: 'Real Business Owners',
        selected: 0,
        collapse: 0,
        parent: 700,
        children: [],
        tooltip: 'Includes images or narratives from real business owners.'
      },
      {
        id: 750,
        label: 'Real Employees',
        selected: 0,
        collapse: 0,
        parent: 700,
        children: [],
        tooltip: 'Includes images or narratives from real employees of the brand.'
      },
      {
        id: 760,
        label: 'Testimonial',
        selected: 0,
        collapse: 0,
        parent: 700,
        children: [],
        tooltip: 'Includes a person\'s written or spoken statement praising the virtue of a brand or service.'
      },
      {
        id: 770,
        label: 'Case Study',
        selected: 0,
        collapse: 0,
        parent: 700,
        children: [],
        tooltip: 'Provides specific examples of how a brand helped a business with a real business issue.'
      },
      {
        id: 780,
        label: 'Humorous',
        selected: 0,
        collapse: 0,
        parent: 700,
        children: [],
        tooltip: 'Ads that use humor to get their message across (or attempt to). Ads may or may not be overtly funny, but you get the sense the ad is trying to be lighthearted, entertaining or witty/humorous'
      },
      {
        id: 790,
        label: 'Diverse cast',
        selected: 0,
        collapse: 0,
        parent: 700,
        children: [],
        tooltip: 'Mix of actors of diverse ethnicities, race, gender, age'
      },
    ]
  }
}
