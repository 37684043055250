export default {
  '***': {
    headerHeight: 80,
    rowTitleHeight: 35,
    padding: 1,
    aspectRatio: 0.7727,
    externalHeaderHeight: 0,
    minContainerHeight: 1000,
    horizontalMargin: 10,
    verticalMargin: 10,
    rows: [
      {
        height: 0.33,
        title: 'IN-MARKET POTENTIAL',
        data: [
          {
            content: [{
              component: 'MetricDonut',
              data: {
                metric: 1,
                metricScale: 200
              }
            }],
            width: 0.3,
            key: null
          },
          {
            content: [{
              component: 'MetricVerticalArray',
              data: {
                label: 'Persuasion',
                metrics: [
                  {
                    metric: 5,
                    maxValue: 0.8,
                    format: '%,1'
                  },
                  {
                    metric: 4,
                    maxValue: 0.8,
                    format: '%,1'
                  }
                ]
              }
            }],
            width: 0.35,
            key: null
          },
          {
            content: [{
              component: 'MetricTable',
              data: {
                label: 'Action',
                metrics: [
                  { metric: 6, isHeaderMetric: true, isCapitalized: true },
                  { metric: 7, isHeaderMetric: false, isInset: true },
                  { metric: 10, isHeaderMetric: false, isInset: true },
                  { metric: 11, isHeaderMetric: false, isInset: true },
                  { metric: 9, isHeaderMetric: false, isInset: true },
                  { metric: 12, isHeaderMetric: false, isInset: true }
                ]
              }
            }],
            width: 0.35,
            key: null
          }
        ]
      },
      {
        height: 0.67,
        title: 'CREATIVE PERFORMANCE',
        data: [
          {
            content: [{
              component: 'MetricTable',
              data: {
                label: 'Branding',
                metrics: [
                  { metric: 16, isHeaderMetric: false },
                  { metric: 17, isHeaderMetric: false },
                  { metric: 19, isHeaderMetric: false },
                  { metric: 20, isHeaderMetric: false },
                  { metric: 21, isHeaderMetric: false }
                ]
              }
            }],
            legend: {
              xOffset: 10,
              yOffset: -20
            },
            width: 0.3,
            key: null
          },
          {
            content: [{
              component: 'MetricChart',
              data: {
                title: 'Creative Efficiency',
                mainMetric: {
                  //   type: 'horizontal',
                  //   scale: {
                  //     start: 85,
                  //     end: 155
                  //   },
                  //   metric: 121,
                  //   label: 'Creative Summary Score'
                },
                subMetrics: [
                  { metric: 24 },
                  { metric: 28 },
                  { metric: 34 },
                  { metric: 40 }
                ]
              }
            }],
            width: 0.35,
            key: null
          },
          {
            content: [{
              component: 'MetricOpenend',
              data: {
                label: 'In Their Own Words',
                topBox: {
                  likes: {
                    metric: 22,
                    noStatColor: '#BFBFBF'
                  },
                  dislikes: {
                    metric: 23,
                    noStatColor: '#AFABAB'
                  }
                },
                messageField: 'message',
                wordcloudConfig: {
                  sentiments: [
                    { color: '#FF872F', description: 'Very Negative' },
                    { color: '#FFC397', description: 'Negative' },
                    { color: '#DAD9D6', description: 'Neutral' },
                    { color: '#C4E1AF', description: 'Positive' },
                    { color: '#89C35F', description: 'Very Positive' }
                  ],
                  scoreRange: [0, 7],
                  font: 'Arial, Helvetica, sans-serif',
                  showCounts: false,
                  padding: 5,
                  rotation: 0,
                  wordCountCutoff: 2,
                  wordLengthCutoff: 3,
                  numDisplayed: 100
                }
              }
            }],
            width: 0.35,
            key: null
          }
        ]
      }
    ],
    legend: {
      metadataFields: [
        {
          value: 'first_air_date',
          label: 'Launch Date'
        }
      ],
      includeSelections: true,
      includeStatTesting: true,
      includeFilters: {
        label: 'Target',
        default: 'Total Consumers'
      }
    }
  }
}
