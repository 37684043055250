// Formats: '%,n', '$,n', '$x,n', 'f,n', 'i,n'
// operations: 'last(a)', prod(a,b), ratio(a,b)
export default {
  tv: [
    {
      label: 'Response',
      rowType: 'h0'
    },
    {
      metric: 7,
      label: 'Makes Me Want to Start/Expand Relationship',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 5,
      label: 'Lift to Brand Consideration',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 4,
      label: 'Lift to Brand Impression',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 100,
      label: 'Recall (Last Month Tested)',
      rowType: 'd0',
      dataFormat: '%,1',
      recall: {
        metric: 3
      }
    },
    {
      metric: 3,
      label: 'Recall (Avg.)',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Superior Product',
      rowType: 'h0'
    },
    {
      metric: 8,
      label:
        'Shows a Financial Product or Service that is Better than what Is Currently Available to Me',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 12,
      label: 'Shows a Company I Can Trust',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 10,
      label: 'Shows a Company that Provides its Customers with Good Value',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 9,
      label:
        'Shows a Company that Provides Investment Research and/or Tools that Make Me Feel Confident in my Investment Decisions',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 75,
      label: 'Tells Me Something New about the Company',
      rowType: 'd0',
      dataFormat: '%,1'
    },

    {
      metric: 11,
      label: 'Shows a Company that Has Outstanding Customer Service',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Relevance',
      rowType: 'h0'
    },
    {
      metric: 6,
      label: 'Portrays a Company I Want to Be Associated with',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 21,
      label: 'Spoke Directly to Me',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 13,
      label: 'Demonstrates an Understanding of my Financial Needs and Goals',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 22,
      label: 'Directly Addresses an Issue Important to Me',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 23,
      label: 'Shows People & Situations I Can Relate to',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 25,
      label: 'Inspiring',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Breakthrough',
      rowType: 'h0'
    },
    {
      metric: 27,
      label: 'Worth Talking About',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 26,
      label: 'Worth Seeing Again',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 28,
      label: 'Clever',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 29,
      label: 'Unique',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 31,
      label: 'Humorous',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 32,
      label: 'Heartwarming',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Cognitive',
      rowType: 'h0'
    },

    {
      metric: 16,
      label: 'Believable',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 20,
      label: 'Serious',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 17,
      label: 'Clear',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 18,
      label: 'Informative',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Extras',
      rowType: 'h0'
    },
    {
      metric: 34,
      label: 'Likeability',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 77,
      label: 'Net Actions Intended/Taken',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: '',
      rowType: 'eol'
    },
  ],
  print: [
    {
      label: 'Response',
      rowType: 'h0'
    },
    {
      metric: 7,
      label: 'Makes Me Want to Start/Expand Relationship',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 5,
      label: 'Lift to Brand Consideration',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 4,
      label: 'Lift to Brand Impression',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 100,
      label: 'Recall (Last Month Tested)',
      rowType: 'd0',
      dataFormat: '%,1',
      recall: {
        metric: 3
      }
    },
    {
      metric: 3,
      label: 'Recall (Avg.)',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Superior Product',
      rowType: 'h0'
    },
    {
      metric: 8,
      label:
        'Shows a Financial Product or Service that is Better than what Is Currently Available to Me',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 12,
      label: 'Shows a Company I Can Trust',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 10,
      label: 'Shows a Company that Provides its Customers with Good Value',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 9,
      label:
        'Shows a Company that Provides Investment Research and/or Tools that Make Me Feel Confident in my Investment Decisions',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 75,
      label: 'Tells Me Something New about the Company',
      rowType: 'd0',
      dataFormat: '%,1'
    },

    {
      metric: 11,
      label: 'Shows a Company that Has Outstanding Customer Service',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Relevance',
      rowType: 'h0'
    },
    {
      metric: 6,
      label: 'Portrays a Company I Want to Be Associated with',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 21,
      label: 'Spoke Directly to Me',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 13,
      label: 'Demonstrates an Understanding of my Financial Needs and Goals',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 22,
      label: 'Directly Addresses an Issue Important to Me',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 25,
      label: 'Inspiring',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 23,
      label: 'Shows People & Situations I Can Relate to',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Breakthrough',
      rowType: 'h0'
    },
    {
      metric: 26,
      label: 'Worth Seeing Again',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 27,
      label: 'Worth Talking About',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 29,
      label: 'Unique',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 32,
      label: 'Heartwarming',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 31,
      label: 'Humorous',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 76,
      label: 'Eye-catching',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Cognitive',
      rowType: 'h0'
    },

    {
      metric: 16,
      label: 'Believable',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 20,
      label: 'Serious',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 17,
      label: 'Clear',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 18,
      label: 'Informative',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Extras',
      rowType: 'h0'
    },
    {
      metric: 34,
      label: 'Likeability',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 77,
      label: 'Net Actions Intended/Taken',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: '',
      rowType: 'eol'
    },
  ],
  online_display: [
    {
      label: 'Response',
      rowType: 'h0'
    },
    {
      metric: 7,
      label: 'Makes Me Want to Start/Expand Relationship',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 5,
      label: 'Lift to Brand Consideration',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 4,
      label: 'Lift to Brand Impression',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 73,
      label: 'Click on the ad (Q25)',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 100,
      label: 'Recall (Last Month Tested)',
      rowType: 'd0',
      dataFormat: '%,1',
      recall: {
        metric: 3
      }
    },
    {
      metric: 3,
      label: 'Recall (Avg.)',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Breakthrough',
      rowType: 'h0'
    },
    {
      metric: 74,
      label: 'Makes me want to click on the ad',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 76,
      label: 'Eye Catching',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 26,
      label: 'Worth seeing again',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 27,
      label: 'Worth talking about',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Superior Product/Relevance',
      rowType: 'h0'
    },
    {
      metric: 8,
      label:
        'Shows a Financial Product or Service that is Better than what Is Currently Available to Me',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 22,
      label: 'Directly addresses an issue import to me',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 21,
      label: 'Spoke directly to me',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 6,
      label: 'Portrays a Company I Want to Be Associated with',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 11,
      label: 'Shows a Company that Has Outstanding Customer Service',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 12,
      label: 'Shows a company I can trust',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 10,
      label: 'Shows a company that provides its customers with good value',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 75,
      label: 'Tells me something new about the company',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 9,
      label:
        'Shows a company that provides investment research and/or tools that make me feel confident in my investment decisions',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Engagement',
      rowType: 'h0'
    },
    {
      metric: 29,
      label: 'Unique',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 33,
      label: 'Moving',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 31,
      label: 'Humorous',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Cognitive',
      rowType: 'h0'
    },
    {
      metric: 17,
      label: 'Clear',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 16,
      label: 'Believable',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 20,
      label: 'Serious',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Extras',
      rowType: 'h0'
    },
    {
      metric: 34,
      label: 'Likeability',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 77,
      label: 'Net Actions Intended/Taken',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: '',
      rowType: 'eol'
    },
  ],
  online_video: [
    {
      label: 'Response',
      rowType: 'h0'
    },
    {
      metric: 7,
      label: 'Makes Me Want to Start/Expand Relationship',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 5,
      label: 'Lift to Brand Consideration',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 4,
      label: 'Lift to Brand Impression',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 73,
      label: 'Click on the ad (Q25)',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 100,
      label: 'Recall (Last Month Tested)',
      rowType: 'd0',
      dataFormat: '%,1',
      recall: {
        metric: 3
      }
    },
    {
      metric: 3,
      label: 'Recall (Avg.)',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Breakthrough',
      rowType: 'h0'
    },
    {
      metric: 74,
      label: 'Makes me want to click on the ad',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 76,
      label: 'Eye Catching',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 26,
      label: 'Worth seeing again',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 27,
      label: 'Worth talking about',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Superior Product/Relevance',
      rowType: 'h0'
    },
    {
      metric: 8,
      label:
        'Shows a Financial Product or Service that is Better than what Is Currently Available to Me',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 22,
      label: 'Directly addresses an issue import to me',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 21,
      label: 'Spoke directly to me',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 6,
      label: 'Portrays a Company I Want to Be Associated with',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 11,
      label: 'Shows a Company that Has Outstanding Customer Service',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 12,
      label: 'Shows a company I can trust',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 10,
      label: 'Shows a company that provides its customers with good value',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 75,
      label: 'Tells me something new about the company',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 9,
      label:
        'Shows a company that provides investment research and/or tools that make me feel confident in my investment decisions',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Engagement',
      rowType: 'h0'
    },
    {
      metric: 29,
      label: 'Unique',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 33,
      label: 'Moving',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 31,
      label: 'Humorous',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Cognitive',
      rowType: 'h0'
    },
    {
      metric: 17,
      label: 'Clear',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 16,
      label: 'Believable',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 20,
      label: 'Serious',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: 'Extras',
      rowType: 'h0'
    },
    {
      metric: 34,
      label: 'Likeability',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      metric: 77,
      label: 'Net Actions Intended/Taken',
      rowType: 'd0',
      dataFormat: '%,1'
    },
    {
      label: '',
      rowType: 'eol'
    },
  ]
}
