export default theme => {
  return {
    titleColor: theme.palette.grey[600],
    titleX: 10,
    titleY: 10,
    titleMargin: 10,
    titleFontSize: 17,
    titleFontStyle: 'bold',

    yOffset: -40,

    noStatColor: theme.palette.grey[400],

    metricLabelFontSize: 12,
    metricLabelColor: theme.palette.grey[600],
    metricLabelFontStyle: 'normal',
    metricLabelMargin: 10,
    metricColorSaturationPercent: 250,
    metricColorLightenPercent: 0,
    metricColorValuePercent: -45,

    metricFontSize: 16,
    metricFontStyle: 'bold',
    metricMargin: 10,

    normFontSize: 10,
    normFontStyle: 'bold',
    normMargin: 10,
    normLabelColor: theme.palette.grey[600],
    strokeWidth: 1,
    lineColor: theme.palette.grey[400],

  }
}
