const DASHBOARD_TOGGLE_EXCLUDE_NON_NORM = 'DASHBOARD_TOGGLE_EXCLUDE_NON_NORM'

const dashboardToggleExcludeNonNorm = data => {
    return {
      type: DASHBOARD_TOGGLE_EXCLUDE_NON_NORM,
      data
    }
  }

  export {
    DASHBOARD_TOGGLE_EXCLUDE_NON_NORM,
    dashboardToggleExcludeNonNorm
  }