import React  from 'react'
import { withStyles } from '@material-ui/core/styles'
import { ListItem, Radio} from '@material-ui/core'
import { AdType } from '../../../'
import classnames from 'classnames'

const Styled = withStyles(theme =>
  theme.components.selection.MetadataSlice.MetadataSliceListView(theme)
)

class MetadataSliceListItem extends React.PureComponent {
  render() {
    const { classes, select, type, item, selection } = this.props

    return (
      <ListItem
        dense
        onClick={() => select(item)}
        className={classnames(
          classes.listItem,
          type.field===selection.field ? classes.activeType : classes.type,
        )}
      >
        <AdType adType={type} />
        <Radio checked={type.field === selection.field} style={{ height: 32, marginLeft: 50 }} />
      </ListItem>
    )
  }
}

export default Styled(MetadataSliceListItem)
