import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import HomeIcon from '@material-ui/icons/Home'
import MenuIcon from '@material-ui/icons/Menu'
import LogoutIcon from '@material-ui/icons/PowerSettingsNew'
import Typography from '@material-ui/core/Typography'
import { Auth } from 'aws-amplify'
import { withRouter } from 'react-router-dom'
import { Logo } from '../../new_components/generic'
const styles = theme => ({
  root: {
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  boxShadow: {
    border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
    boxShadow: `0 1px 1px #E7E7E7`
  }
})

const NavBar = props => {
  const {
    title,
    toggleDrawer,
    logo,
    classes,
    disableGutters,
    showHome,
    history,
    showMenuIcon,
    user
  } = props
  return (
    // <AppBar className={classes.root}>
      <Paper elevation={0} className={classes.root} classes={{elevation0: classes.boxShadow}}>
      <Toolbar disableGutters={disableGutters}>
        {showMenuIcon ? (
          <IconButton
            style={{ margin: '0px 12px' }}
            color="inherit"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        <div style={{ marginTop: 6, marginRight: 20 }}>
          {logo && React.createElement(logo)}
        </div>
        <div style={{ marginTop: 5.5, flex: 1 }}>
          <Typography font="OktaNeueRegular" variant="subtitle1"  color="textPrimary" noWrap>
            {title}
          </Typography>
        </div>
        <div style={{ marginTop: 5, marginRight: 40 }}>
           <Logo name='marketcast' size="medium"/>
        </div>
        <div>
          {showHome ? (
            <Tooltip id="home-tooltip" title="Home">
              <IconButton
                color="inherit"
                onClick={e => {
                  e.preventDefault()
                  history.push('/')
                }}
              >
                <HomeIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip id="logout-tooltip" title={`${user.email}`}>
            <IconButton
              onClick={e => {
                e.preventDefault()
                Auth.signOut().then(() => { history.push('/') ; window.location.reload(true); })
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
      </Paper>
    // </AppBar>
  )
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  showHome: PropTypes.bool.isRequired,
  disableGutters: PropTypes.bool.isRequired,
}

NavBar.defaultProps = {
  showHome: true,
  showMenuIcon: true,
  disableGutters: false,
}

export default withRouter(withStyles(styles)(NavBar))
