import { createSelector } from 'reselect'
import { formatDate } from '../lib'

// const getAdsMap = state => state.ford.ads.map
const getAdsList = state => state.ford.ads.list
const getDisplayFilters = state => state.ford.displayFilters
const getScorecardAdCodes = state => state.ford.scorecard.adCodes
const getDateFormat = state => state.app.config.dateFormat || 'mm/dd/yy'
const getCurrentMonthCode = state => {
  const monthCode = state.ford.filter.dynamicFilters.selections.find(
    selection => selection.field === 'month_code'
  ).value
  return state.ford.filter.dynamicFilters.filters[0].options[monthCode].value
}


function getFormattedAds(adsList, adCodes, monthCode, dateFormat, displayFilters = []) {
  return adCodes
    .map(adCode => {
      const ad = adsList.find( ad => ad.ad_code === adCode && ad.month_code === monthCode)
      if (!ad) {
        return null
      }

      const {
        segment,
        month_code,
        brand,
        ad_type,
        sov,
        brand_model_event,
        title,
        ad_code,
        first_air_date,
        length,
        spend_ltd,
        links
      } = ad

      const f_string = formatDate(first_air_date, dateFormat)
      // let f_string
      // if (first_air_date) {
      //   const f = first_air_date.match(/([0-9]*-[0-9]*-[0-9]*)/)
      //   if (f && f.length && typeof f[0] === 'string') {
      //     let [f_year, f_month, f_day] = f[0].split('-')
      //     f_string =
      //       dateFormat === 'dd/mm/yy'
      //         ? `${f_day}/${f_month}/${f_year}`
      //         : `${f_month}/${f_day}/${f_year}`
      //   } else f_string = `N/A`
      // } else {
      //   f_string = 'N/A'
      // }
      return {
        ...ad,
        sample: segment,
        ad_type,
        sov,
        model: brand_model_event,
        title,
        adCode: ad_code,
        first_air_date: f_string,
        length,
        spend_ltd,
        month_code,
        month_text: `text_${month_code}`,
        brand,
        links
      }
    })
    .filter(adCode => {
      return displayFilters.every(filter => {
        const selections = filter.options
          .filter(option => option.selected)
          .map(option => option.label)
        if (!selections.length) return true
        return selections.includes(adCode[filter.code])
      })
    })
  //.filter(adCode => brands.includes(adCode.brand) || !brands.length)
}
export const getMainFormatted = createSelector(
  [getAdsList, getScorecardAdCodes, getCurrentMonthCode, getDateFormat, getDisplayFilters],
  getFormattedAds
)
