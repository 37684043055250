import templateComponents from '../../../Template/config/components'
import selection from './selection'
import scorecard from './scorecard'
import adminView from './adminView'

export default {
  ...templateComponents,
  selection,
  scorecard,
  adminView
}
