export default {
  spendField: 'spend_ltd',
  interpolateMonths: true,
  trendMetrics: [
    {
      metric: 106,
      field: 'adpi',
      label: 'IN-MARKET AdPi® (Modeled Score)',
      format: '.0f',
      scale: [80, 160]
    }
  ]
}
