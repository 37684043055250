import ScorecardAdHeader from './ScorecardAdHeader'
import ScorecardMetricsValues from './ScorecardMetricsValues'
export default Object.assign(
  theme => {
    return {
      gridHeader: {
      }
    }
  },
  {
    ScorecardAdHeader,
    ScorecardMetricsValues
  }
)
