import {
  FORD_SCORECARD_REQUEST,
  FORD_SCORECARD_RECEIVE,
  FORD_SCORECARD_FAILURE,
  FORD_SCORECARD_UPDATE_BRANDS,
  FORD_SCORECARD_UPDATE_SORT,
  APP_CLR_CONFIG,
  FORD_META_DATA_RECEIVE,
  FORD_META_DATA_FAILURE,
  FORD_SET_BUSY_SESSION
} from '../actions'
import { listToMap } from '../selectors/scorecard/utils'

const defaultAsync = {
  hasFetched: false,
  isFetching: false,
  hasErrored: false,
  errMessage: '',
}

const defaultList = {
  ...defaultAsync,
  received: 0,
  uuid: '',
  list: [],
}

const defaultSort = {
  list: [],
  sortMetric: null,
  sortOrder: -1,
  groupLeft: 1,
}

const initialState = {
  monthlyStats: [],
  main: { ...defaultList, metricsLabels: {}, report: false },
  norm: { ...defaultList, normLabel: 'NORM' },
  phase: 0,
  busySession: false,
  adCodes: [],
  brands: {
    name: 'Brands',
    metadataField: 'brand',
    multi: true,
    options: [],
  },
  sortParams: { ...defaultSort },
}

const numerics = ['metric', 'avg', 'std', 'n']

function updateNorms(action) {
  const list = action.data.metrics.norms
  const {normLabel} = action.data.metrics
  const received = Date.now()
  for (let i = 0; i < list.length; i++) {
    // list[i].adCode = list[i].ad_code
    // delete list[i].ad_code

    for (let j = 0; j < numerics.length; j++) {
      if (numerics[j] === 'n') {
        list[i][numerics[j]] = Math.round(+list[i][numerics[j]])
      } else {
        list[i][numerics[j]] = +list[i][numerics[j]]
      }
    }
  }

  return { list, normLabel, received }
}

function updateMetrics(action) {
  // TODO: This filtering for metrics with non-existent labels should be necessary
  const list = action.data.metrics.main.filter(metric => metric.label)
  
  const received = Date.now()

  for (let i = 0; i < list.length; i++) {
    list[i].adCode = list[i].ad_code
    delete list[i].ad_code
    for (let j = 0; j < numerics.length; j++) {
      if (numerics[j] === 'n') {
        list[i][numerics[j]] = Math.round(+list[i][numerics[j]])
      } else {
        list[i][numerics[j]] = +list[i][numerics[j]]
      }
    }
  }

  return { list, received }
}

function updateMetricsLabels(action) {
  const {
    data: { metricsLabels },
  } = action
  return { metricsLabels: listToMap(metricsLabels, 'metric') }
}

function initBrands(action) {
  const brands = action.data.brands || []
  return brands.map((brand, index) => {
    return {
      label: brand,
      value: index,
      selected: false,
    }
  })
}

function updateBrands(options, action) {
  const selections = action.data
  return options.map(option => {
    return {
      ...option,
      selected: selections.includes(option.value),
    }
  })
}

function getSortItems(action) {
  return action.data.metricsLabels
    .filter(metricLabel => metricLabel.format)
    .sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1))
    .map(item => {
      return {
        label: item.label,
        metric: item.metric,
        row_type: item.row_type
      }
    })
}

export default function scorecardReducer(state = initialState, action) {
  switch (action.type) {
    case FORD_SCORECARD_REQUEST:
      return {
        ...state,
        main: {
          ...defaultList,
          metricsLabels: {},
          isFetching: true,
          report: false,
        },
      }

    case FORD_SCORECARD_RECEIVE:
      return {
        ...state,
        ...updateMetricsLabels(action),
        monthlyStats: action.data.stats,
        main: {
          ...defaultAsync,
          hasFetched: true,
          report: action.data.report,
          ...updateMetrics(action),
        },
        norm: {
          ...updateNorms(action),
        },
        brands: {
          ...state.brands,
          options: initBrands(action),
        },
        sortParams: {
          ...defaultSort,
          list: getSortItems(action),
        },
        adCodes: action.data.adCodes,
      }

    case FORD_META_DATA_RECEIVE: {
      return {
        ...state,
        phase: action.data.phase,
      }
    }

    case FORD_META_DATA_FAILURE: {
      return { 
        ...state,
        hasFetched: false,
        isFetching: false,
        hasErrored: true,
        errMessage: action.data,
      }

    }
    case FORD_SCORECARD_UPDATE_BRANDS:
      return {
        ...state,
        brands: {
          ...state.brands,
          options: updateBrands(state.brands.options, action),
        },
      }
    case FORD_SCORECARD_UPDATE_SORT:
      const sortMetric =
        action.data.sortMetric === state.sortParams.sortMetric
          ? null
          : action.data.sortMetric || state.sortParams.sortMetric
      const sortOrder =
        action.data.sortOrder !== undefined
          ? action.data.sortOrder
          : state.sortParams.sortOrder
      const groupLeft =
        action.data.groupLeft !== undefined
          ? action.data.groupLeft
          : state.sortParams.groupLeft
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sortMetric: sortMetric,
          sortOrder: sortOrder,
          groupLeft: groupLeft,
        },
      }

    case FORD_SCORECARD_FAILURE:
      return {
        ...state,
        main: {
          ...defaultList,
          hasErrored: true,
          errMessage: `${'Could not fetch data from server.'} ${action.data}.`,
        },
      }

    case FORD_SET_BUSY_SESSION:
      return {
        ...state,
        busySession: action.data
      }

    case APP_CLR_CONFIG:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
