import { createSelector } from 'reselect'
import { getMainFormatted } from '../adSelection'
//import config from '../../configuration/settings.json'

import { listToMap, tStatIndex, tStatNonIndex, format } from './utils'

function cell(
  { value, rawValue, color, bgColor, borderColor, style, stat } = {},
  f = null,
) {
  return {
    value: format(f)(value) || '',
    rawValue:
      rawValue !== undefined ? rawValue : value !== undefined ? value : '',
    color: color || '#000000',
    bgColor: bgColor || '#eeeeee',
    borderColor: borderColor,
    style: style || {},
    stat: stat || 0,
  }
}

const getMainData = state => state.ford.scorecard.main.list
const getMainStats = state => state.ford.scorecard.monthlyStats
const getNormData = state => state.ford.scorecard.norm.list
const getLabels = state => state.ford.scorecard.metricsLabels
const getSortParams = state => state.ford.scorecard.sortParams
const sortMetrics = (a, b) => (a.sort_order > b.sort_order ? 1 : -1)
const getConfig = state => state.app.config

export const getSelections = state => {
  const {
    dynamicFilters: { filters, selections },
  } = state

  return filters.reduce((obj, filter, index) => {
    return {
      ...obj,
      [filter.field]:
        (selections[index].value !== null &&
          filters[index].options[selections[index].value].label) ||
        '',
    }
  }, {})
}

export const getFinalSelections = state => {
  const {
    dynamicFilters: { filters, selections },
    staticFilters,
  } = state
  const dynamicFilterSelections = filters
    .map((dynamicFilter, index) => {
      return {
        [dynamicFilter.label]:
          selections[index].value !== null &&
          dynamicFilter.options[selections[index].value].label,
      }
    })
    .reduce((obj, sel) => {
      const key = Object.keys(sel)[0]
      obj[key] = sel[key]
      return obj
    }, {})

  const staticFilterSelection = staticFilters
    .filter(staticFilter => {
      return staticFilter.options.some(option => option.selected)
    })
    .map(filter => {
      const selected = filter.options.find(option => option.selected) || null
      return {
        [filter.label]: selected && selected.label,
      }
    })
  return {
    dynamicFilterSelections,
    staticFilterSelection: staticFilterSelection[0],
  }
}

export const getPhase = state => {
  return (state.ford.filter.dynamicFilters.selections[0] && state.ford.filter.dynamicFilters.selections[0].value === 0 && state.ford.scorecard.phase) || null
}

const getMainMap = createSelector(
  [getLabels, getMainData, getMainStats],
  (labels, main, monthlyStats) => {
    const mainMap = {}
    const metricsMap = {}

    if (!labels) return { mainMap, metrics: [], labels }

    for (let i = 0; i < main.length; i++) {
      const { adCode, metric } = main[i]

      if (!mainMap[adCode]) {
        mainMap[adCode] = {}
      }

      mainMap[adCode][metric] = {
        ...main[i],
        row_type: labels[metric].row_type,
        format: labels[metric].format,
      }

      if (!metricsMap[metric] && labels[metric]) {
        metricsMap[metric] = labels[metric]
      }
    }

    // add the extra rows with dummy metric values
    for (let adCode in mainMap) {
      Object.keys(labels)
        .filter(metric => labels[metric].metric < 0)
        .forEach(dummyMetric => {
          mainMap[adCode][dummyMetric] = {
            adCode,
            label: labels[dummyMetric].label,
            metric: dummyMetric,
          }
          metricsMap[dummyMetric] = labels[dummyMetric]
        })
    }

    const metrics = Object.keys(metricsMap)
      .map(m => {
        return metricsMap[m]
      })
      .sort(sortMetrics)

    return { mainMap, metrics, labels, monthlyStats }
  },
)

const getNormMap = createSelector([getNormData], norm => {
  return listToMap(norm, 'metric')
})

export const getScorecard = createSelector(
  [getMainMap, getNormMap, getMainFormatted, getSortParams, getConfig],
  (
    { mainMap, metrics, labels, monthlyStats },
    normMap,
    allMainAds,
    sortParams,
    config,
  ) => {
    const {
      stats,
      sampleSizeMetric,
      thresholds: {
        minSampleSizeInScorecard,
        medSampleSizeInScorecard,
        maxSampleSizeInScorecard,
      },
      styles,
      clientName,
    } = config

    const textTable = []
    const normTable = []

    const mainAds = allMainAds.filter(ad => ad).sort((a, b) => {
      if (sortParams.groupLeft) {
        if (
          clientName.toLowerCase() === a.brand.toLowerCase() &&
          clientName.toLowerCase() !== b.brand.toLowerCase()
        )
          return -1
        if (
          clientName.toLowerCase() !== a.brand.toLowerCase() &&
          clientName.toLowerCase() === b.brand.toLowerCase()
        )
          return 1
      }
      if (!sortParams.sortMetric) {
        return a.adCode < b.adCode
          ? sortParams.sortOrder
          : -1 * sortParams.sortOrder
      } else {
        if (!mainMap[a.adCode]) return 0
        return mainMap[a.adCode][sortParams.sortMetric].avg >
          mainMap[b.adCode][sortParams.sortMetric].avg
          ? sortParams.sortOrder
          : -1 * sortParams.sortOrder
      }
    })

    for (let i = 0; i < metrics.length; i++) {
      const text = metrics[i]
      if (text) {
        textTable.push([
          cell({ value: text.label, style: styles[metrics[i].row_type] }),
        ])
      } else {
        textTable.push([cell({ style: styles[metrics[i].row_type] })])
      }

      const norm = normMap[metrics[i].metric]
      const f = labels[metrics[i].metric].format
      const isIndex = f === 'index'
      if (norm) {
        normTable.push([
          cell({
            value: isIndex ? format(f)(100) : format(f)(norm.avg),
            rawValue: isIndex ? 100 : norm.avg,
            style: styles[metrics[i].row_type],
          }),
        ])
      } else {
        normTable.push([cell()])
      }
    }

    const mainHeader = []

    for (let i = 0; i < mainAds.length; i++) {
      let { adCode, sampleSize, length } = mainAds[i]

      if (mainMap && mainMap[adCode]) {
        if (mainMap[adCode][sampleSizeMetric]) {
          // sampleSize = mainMap[adCode][sampleSizeMetric].n
           sampleSize = parseInt(mainMap[adCode][sampleSizeMetric].n_unweighted,10)
        }
      }

      mainHeader.push({ ...mainAds[i], sampleSize, length })
    }

    const mainTable = []

    for (let i = 0; i < metrics.length; i++) {
      const row = []
      mainTable.push(row)
      for (let j = 0; j < mainAds.length; j++) {
        const m = metrics[i].metric
        const a = mainAds[j].adCode

        let main

        if (m === 4 && mainMap[a] && mainMap[a][m]) {
          // mainHeader[j].sampleSize = mainMap[a][m].n
          mainHeader[j].sampleSize = parseInt(mainMap[a][m].n_unweighted,10)
        }

        if (mainMap[a] && mainMap[a][m]) {
          main = mainMap[a][m]
        }

        let norm

        if (normMap[m]) {
          norm = normMap[m]
        }

        let color = '#000000'
        let bgColor = '#ffffff'
        let thresholdColor = null

        if (main && norm) {
          const f = labels[metrics[i].metric].format
          const reverseStat = labels[metrics[i].metric].reverse_stat_polarity
          const isIndex = f === 'index'
          const isAdpi = m === 106 || m === 109
          const scaledValue = main.n_unweighted >=minSampleSizeInScorecard.value ? isIndex ? (main.avg / norm.avg) * 100 : main.avg : null
          const rawScaledValue = main.n_unweighted >=minSampleSizeInScorecard.value ? isIndex
            ? (main.avg / norm.avg) * 100
            : main.avg : null

          const UNWEIGHTED = false
          const v = main.n_unweighted >=minSampleSizeInScorecard.value ? isIndex || isAdpi ? tStatIndex(main, norm, reverseStat, UNWEIGHTED) : tStatNonIndex(main, norm, reverseStat, UNWEIGHTED) : 0

          thresholdColor =
            main.n_unweighted < minSampleSizeInScorecard.value
              ? minSampleSizeInScorecard.color
              : main.n_unweighted < medSampleSizeInScorecard.value
                ? medSampleSizeInScorecard.color
                : main.n_unweighted < maxSampleSizeInScorecard.value
                  ? maxSampleSizeInScorecard.color
                  : null

          if (v === 1) {
            color = stats.above.color
            bgColor = stats.above.bgColor
          } else if (v === -1) {
            color = stats.below.color
            bgColor = stats.below.bgColor
          }

          color = thresholdColor ? thresholdColor : color

          row.push(
            cell({
              value: scaledValue !== null ? format(f)(scaledValue) : null,
              rawValue: rawScaledValue !== null ? rawScaledValue : null,
              color: color,
              bgColor: bgColor,
              style: styles[metrics[i].row_type],
              stat: v,
            }),
          )
        } else {
          row.push(
            cell({
              value: '',
              rawValue: '',
              color: color,
              bgColor: bgColor,
              style: styles[metrics[i].row_type],
            }),
          )
        }
      }
    }

    let minAdLen = Infinity
    let maxAdLen = -Infinity

    let i
    for (i = 0; i < mainAds.length; i++) {
      const ad = mainAds[i]

      if (ad.length < minAdLen) {
        minAdLen = ad.length
      }

      if (ad.length > maxAdLen) {
        maxAdLen = ad.length
      }
    }

    let adLengths = `N/A`

    if (minAdLen === maxAdLen) {
      adLengths = `${minAdLen}`
    } else {
      adLengths = `${minAdLen}/${maxAdLen}`
    }

    let normSampleSize = ''

    if (normMap[sampleSizeMetric] && normMap[sampleSizeMetric].n) {
      normSampleSize = normMap[sampleSizeMetric].n.toLocaleString()
    }

    const textHeader = [cell()]
    const normHeader = [{ adLengths, normSampleSize }]

    return {
      textHeader,
      textTable,
      normHeader,
      normTable,
      mainHeader,
      mainTable,
      monthlyStats,
    }
  },
)
