import React, { PureComponent, Fragment } from 'react'
import { scaleLinear, scaleQuantize } from 'd3-scale'
import cloud from 'd3-cloud'

import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { AsyncWrapper } from '../../../'
import DetailHeader from '../DetailsView/DetailHeader'

import WordCloud from './WordCloud'
import WordCloudControls from './WordCloudControls'
import VerbatimList from './VerbatimList'
import * as utils from './utils'
import ExportWordCloud from './ExportWordCloud'
import WordCloudLegend from './WordCloudLegend'

const likeabilityMap = {
  sentiments: [
    { color: '#FF872F', description: 'Very Negative' },
    { color: '#FFC397', description: 'Negative' },
    { color: '#DAD9D6', description: 'Neutral' },
    { color: '#C4E1AF', description: 'Positive' },
    { color: '#89C35F', description: 'Very Positive' }
  ],
  scoreRange: [0, 7]
}

const styles = theme => {
  return {
    boxShadow: {
      border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
      boxShadow: `0 1px 1px #E7E7E7`
    }
  }
}

const scaleColor = scaleQuantize()
  .domain(likeabilityMap.scoreRange)
  .range(likeabilityMap.sentiments.map(sentiment => sentiment.color))

const layout = cloud()
  .font('Arial, Helvetica, sans-serif')
  .padding(5)
  .rotate(0)

class Wordcloud extends PureComponent {
  state = {
    activeTab: 0,
    verbatimFilter: '',
    wordCloudData: [],
    wordCloudSize: [800, 600],
    controls: utils.getDefaultOptions()
  }

  componentDidMount() {
    this.updateWordCloud(this.props)
    this.setState({
      controls: utils.getDefaultOptions(this.props.ad.market)
    })
  }

  componentWillReceiveProps(next) {
    if (next.data !== this.props.data || next.tabWidth !== this.props.tabWidth) {
      this.updateWordCloud(next)
    }
  }

  handleSelect = (key, values) => {
    this.setState(
      ({ controls }) => ({
        controls: controls.map(control => {
          if (control.key === key) {
            return {
              ...control,
              options: utils.getOptions(control.options, values, control.multiple)
            }
          }

          return control
        })
      }),
      () => {
        if (key === 'scores' || key === 'language') {
          // FIXME This hardcoded controls[5] referring to the scores menu selector is a horrible hack
          // REFACTOR Re-examine the way data is fetched by DetailDialog and controlled here
          const selQuery = this.state.controls[5].options.filter(option => option.selected).map(option => option.value)

          const langQuery = this.state.controls[6]
            ? this.state.controls[6].options.filter(option => option.selected).map(option => option.value)
            : []

          this.props.fetchData(selQuery, langQuery)
        } else this.updateWordCloud(this.props)
      }
    )
  }

  updateWordCloud = props => {
    const { controls } = this.state
    const {
      tabWidth,
      data: { ready, mainMessage, likeability }
    } = props

    if (!ready) return

    const { question, frequency, cutoff, length, displayed } = utils.getSelectedOptions(controls)

    const counts = question === 1 ? mainMessage.counts : likeability.counts

    const domain = [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER]

    const filteredCounts = counts
      .filter(d => {
        const c0 = d.length >= length
        const c1 = d.count >= cutoff

        if (!c0 || !c1) {
          return false
        }

        if (d.count < domain[0]) {
          domain[0] = d.count
        }

        if (d.count > domain[1]) {
          domain[1] = d.count
        }

        return true
      })
      .slice(0, displayed)

    if (domain[0] === domain[1]) {
      domain[0] = 0 // looks better with single item
    }

    const scale = scaleLinear()
      .domain(domain)
      .range([0.2, 1])

    const wordCounts = filteredCounts.map(d => {
      const textColor = question === 1 ? `rgba(0,0,0,${scale(d.count)})` : scaleColor(d.score)

      return {
        text: `${d.word}${frequency === 1 ? `(${d.count})` : ''}`,
        word: d.word,
        value: d.count,
        color: textColor
      }
    })

    scale.range([10, 100])

    const fontSize = d => scale(d.value)

    const w = tabWidth * (10 / 12)
    const h = w / 1.77

    layout
      .size([w, h])
      .words(wordCounts)
      .fontSize(fontSize)
      .on('end', words => {
        this.setState({
          wordCloudData: words,
          wordCloudSize: layout.size()
        })
      })

    layout.start()
  }

  switchTabs = (event, value) => {
    this.setState({
      activeTab: value,
      verbatimFilter: ''
    })
  }

  setVerbatimFilter = ({ word }) => {
    this.setState({
      activeTab: 1,
      verbatimFilter: word
    })
  }

  render() {
    const { activeTab, controls, wordCloudData, wordCloudSize, verbatimFilter } = this.state
    const { ad, adType, selectTab, selectedTab, handleClose, openMedia, hasTrendchart, data, classes } = this.props
    const { question } = utils.getSelectedOptions(controls)
    const { mainMessage, likeability } = data
    const verbatims = question === 1 ? data.mainMessage : data.likeability

    const verbatimColumns = data.verbatimColumns
    return (
      <Fragment>
        <DetailHeader
          ad={ad}
          adType={adType}
          handleClose={handleClose}
          selectTab={selectTab}
          selectedTab={selectedTab}
          openMedia={openMedia}
          hasTrendchart={hasTrendchart}
        >
          <ExportWordCloud
            data={{ mainMessage, likeability }}
            ad={ad}
            controls={controls}
            verbatims={verbatims}
            question={question}
          />
        </DetailHeader>
        <AsyncWrapper item={data} size={300} thickness={1}>
          <Grid container justify="center">
            <Grid item xs={10}>
              <div style={{ padding: '50px 0px 200px 0px' }}>
                <Grid container>
                  <Grid item xs={12}>
                    <WordCloudControls
                      controls={controls}
                      disableAll={activeTab === 1}
                      handleSelect={this.handleSelect}
                      question={question}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Tabs value={activeTab} onChange={this.switchTabs}>
                      <Tab label="Word Cloud" />
                      <Tab label="Verbatims" />
                    </Tabs>
                    {activeTab === 0 ? (
                      <Paper style={{ marginTop: 25 }} elevation={0} classes={{ elevation0: classes.boxShadow }}>
                        {wordCloudData.length ? (
                          <div>
                            <WordCloud data={wordCloudData} size={wordCloudSize} handleClick={this.setVerbatimFilter} />
                            <WordCloudLegend height={50} mainMessage={question === 1} />
                          </div>
                        ) : (
                          <Grid container justify="center" alignItems="center" style={{ height: 500 }}>
                            <Grid item xs={12}>
                              <Typography align="center" variant="h6">
                                There are currently no words in the wordcloud
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Paper>
                    ) : (
                      <div>
                        <VerbatimList data={verbatims} word={verbatimFilter} verbatimColumns={verbatimColumns} />
                        <div
                          style={{
                            opacity: 0,
                            position: 'absolute',
                            left: '-9999px'
                          }}
                        >
                          <WordCloud data={wordCloudData} size={wordCloudSize} handleClick={this.setVerbatimFilter} />
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </AsyncWrapper>
      </Fragment>
    )
  }
}

// Wordcloud.propTypes = {
//   ad: PropTypes.shape({
//     adCode: PropTypes.string.isRequired,
//     title: PropTypes.string.isRequired,
//     links: PropTypes.object.isRequired
//   }).isRequired,
//   types: PropTypes.arrayOf(
//     PropTypes.shape({
//       value: PropTypes.string.isRequired,
//       label: PropTypes.string.isRequired,
//       selected: PropTypes.bool.isRequired
//     })
//   ).isRequired,
//   typeIcon: PropTypes.func.isRequired,
//   tabWidth: PropTypes.number.isRequired,
//   data: PropTypes.shape({
//     mainMessage: PropTypes.shape({
//       list: PropTypes.array.isRequired,
//       counts: PropTypes.array.isRequired
//     }).isRequired,
//     likeability: PropTypes.shape({
//       list: PropTypes.array.isRequired,
//       counts: PropTypes.array.isRequired
//     }).isRequired
//   }).isRequired,
//   viewerOpen: PropTypes.func.isRequired,
//   switchTabs: PropTypes.func.isRequired,
//   classes: PropTypes.object.isRequired
// }

export default withStyles(styles, { withTheme: true })(Wordcloud)
