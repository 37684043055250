import AdminUsers from './AdminUsers'
import AdminUserRoles from './AdminUserRoles'
import AdminUserAccount from './AdminUserAccount'

export default Object.assign(
  theme => {
    return {
      container: {
        height: '100%'
      },
      userManagerView: {
        marginTop: 20,
        height: 525,
        // overflow: 'scroll',
        padding: 25
      },
      userSelectItem: {
        flex: 3,
        padding: 5,
        marginRight: 10
      },
      userDataEditItem: {
        flex: 7,
        padding: 5,
        marginLeft: 10
      },
      userMatchItem: {
        flex: 1,
      },
      userListItem: {
        flex: 10
      },
      userMatchText: {
        width: '50%',
        paddingLeft: 20,
        marginBottom: 10
      },
      userListLabelItem: {
        flex: 1
      },
      userListListItem: {
        flex: 10,        
      },
      userList: {
        border: `1px solid ${theme.palette.grey[400]}`
      }
    }
  },
  {
    AdminUsers,
    AdminUserRoles,
    AdminUserAccount
  }
)