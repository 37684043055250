
import MetricDonutTemplate from '../../../../../Template/theme/components/quickview/Quickview/MetricDonut'
export default theme => {
    return {
      ...MetricDonutTemplate(theme),
      metricColorSaturationPercent: 250,
      metricColorLightenPercent: 0,
      metricColorValuePercent: -45,
    }
  }
  