import { createSelector } from 'reselect'
import { getMetricsLabels } from '../metrics'

const getSelectedPresets = dashboard => dashboard.selections.presets
const getSelectedTab = dashboard => dashboard.selections.selectedTab
const getCalcFlags = dashboard => dashboard.scorecard.calcFlags

const _getSearchFilter = dashboard => dashboard.init.config.components.selection.search.searchFilter
const getStaticFilters = dashboard => dashboard.init.config.components.scorecard.scorecardView.scorecardFilter.staticFilters

const getSelections = dashboard => {
  const { sliceSelections } = dashboard.selections.slice
  return Object.keys(sliceSelections).reduce((array, selection) => {
    array.push({
      type: selection,
      ...sliceSelections[selection]
    })
    return array
  }, [])
}

const getSearchFilter = createSelector(
  [getSelections, _getSearchFilter],
  (selected, searchFilter) => {
    return selected.reduce((obj, selection) => {
      return obj[selection.field] || obj['***']
    }, searchFilter)
    
  }
)

const getSortMetrics = dashboard =>
  dashboard.selections.sort.sortOptions
    .filter(option => option.value > 0)
    .map(option => option.value)

const getSelectedAds = dashboard => {
  const {
    selections: { presets } } = dashboard
  return ['ad', 'norm'].reduce((obj, type) => {
    const { uuid, ad_codes, start_month, end_month } = presets[type]
    
    obj[type] = {
      uuid,
      ad_codes,
      start_month,
      end_month
    }
    return obj
  }, {})
}

const getSortQuery = createSelector(
  [getMetricsLabels, getSortMetrics, getSelectedAds, getCalcFlags],
  (labels, sortMetrics, selectedAds, calcFlags) => {
    return Object.keys(selectedAds).reduce((obj, type) => {
      const { uuid, ad_codes, start_month, end_month } = selectedAds[type]
      if (uuid === null) return obj
      const operators = labels
        .filter(label => {
          const { operation } = label
          return operation
        })
        .map(label => {
          const { operation, metric } = label
          return { metric, operation }
        })
      obj[type] = {
        uuid,
        adCodes: ad_codes,
        startMonth: start_month,
        endMonth: end_month,
        operators,
        metrics: sortMetrics,
        calcFlags: calcFlags[type],
        type
      }

      return obj
    }, {})
  }
)

export { getSelectedPresets, getSelectedAds, getSelectedTab, getSortQuery, getSearchFilter, getStaticFilters, getSelections }
