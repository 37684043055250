import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

import {withStyles} from '@material-ui/core/styles'
const Styled = withStyles( theme => theme.components.generic.ViewPresetsButton(theme))

export default Styled( props => {
    return (
        <Grid item>
        <Tooltip title={"Open Presets View"}>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={e => props.action()}
            >
              Preset
            </Button>
          </div>
        </Tooltip>
      </Grid>
    )
})