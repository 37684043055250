import scorecardScroller from './ScorecardScroller'
import scorecardExport from './ScorecardExport'
import scorecardStatTesting from './ScorecardStatTesting'
import scorecardFilter from './ScorecardFilter'

const scorecardView = {
    heights: {
        marginHeight: 50,
        navbarHeight: 64,
        headerHeight: 70,
        footerHeight: 65,
        legendHeight: 20,
        filterHeight: 60
    },
    hasPresetButton: true,
    enableQuickview: false,
    hideExportMediaButton: false,
    scorecardScroller,
    scorecardExport,
    scorecardStatTesting,
    scorecardFilter
}

export default scorecardView

export { scorecardView, scorecardScroller, scorecardFilter, scorecardExport, scorecardStatTesting }
