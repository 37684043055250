import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
import { Provider } from 'react-redux'
import createStore from './store'
import './index.css'

const store = createStore(window.__INITIAL_STATE__)

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root'),
)
