export default {
  useUnWeightedSampleSize: false,
  useNormStandardSampleSize: true,
  statTests: {
    '.': 'mean',
    '%': 'ratio',
    'idx': 'mean'
  },
  thresholdConstant: 1.645,
  thresholds: [1],
  labels: [
    {
      high: {
        // color: '#d8e4bc',
        color: '#89C35F',
        text: 'Significantly above the norm at 90%'
      },
      low: {
        // color: '#f2dcdb',
        color: '#F9A23B',
        text: 'Significantly below the norm at 90%'
      }
    }
  ],
  excludeLegendText: true,
}
