import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

import { withStyles } from '@material-ui/core/styles'

const Styled = withStyles(theme =>
  theme.components.navigation.MenuButton(theme)
)

export default Styled(
  props =>
    props.visible ? (
      <IconButton
        style={props.style}
        // color="inherit"
        onClick={props.action}
      >
        <MenuIcon />
      </IconButton>
    ) : null
)
