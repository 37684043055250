import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  Typography,
  Paper,
  TextField,
  IconButton
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import cn from 'classnames'
import { AsyncWrapper } from '../../../new_components'
import AdminDataMigrationFilter from './AdminDataMigrationFilter'
import AdminDataMigrationComment from './AdminDataMigrationComment'
import AdminDataMigrations from './AdminDataMigrations'
import AdminExternalDataMigrations from './AdminExternalDataMigrations'

const Styled = withStyles(theme =>
  theme.components.adminView.AdminConfigManager.AdminDataMigrationView(theme)
)

class AdminDataMigrationView extends React.PureComponent {
  state = {
    filter: {},
    comment: {
      text: '',
      open: false
    },
    _schedule: {
      ready: true,
      date: null,
      time: null,
      schedule: null
    }
  }

  test = () => {
    const { testMigration } = this.props
    const { filter } = this.state

    testMigration({ filter })
  }

  commit = () => {
    const { commitMigration } = this.props
    const { filter, _schedule, comment } = this.state
    this.setState({
      comment: {
        open: false,
        comment: ''
      }
    })
    _schedule.schedule = new Date(`${_schedule.date} ${_schedule.time}`)
    const client_time_zone_offset = new Date().getTimezoneOffset()

    const schedule = {
      schedule: _schedule.schedule,
      client_time_zone_offset
    }

    commitMigration({ filter, schedule, comment: comment.text })
  }

  getMigrationComment = () => {
    this.setState({
      comment: {
        text: '',
        open: true
      }
    })
  }

  updateComment = e => {
    this.setState({
      comment: {
        ...this.state.comment,
        text: e.target.value.slice(0, 64)
      }
    })
  }

  cancelComment = () => {
    this.setState({
      commentOpen: false,
      comment: ''
    })
  }

  updateFilter = selection => {
    this.setState({
      filter: {
        ...this.state.filter,
        [selection.code]: selection.selected
      }
    })
  }

  updateTime = time => {
    this.setState(prevState => {
      return {
        _schedule: {
          ...this.state._schedule,
          time: time.length ? time : null,
          ready: (prevState._schedule.date && time.length) || (!prevState._schedule.date && !time.length)
        }
      }
    })
  }

  updateDate = date => {
    this.setState(prevState => {
      return {
        _schedule: {
          ...this.state._schedule,
          date: date.length ? date : null,
          ready: (prevState._schedule.time && date.length) || (!prevState._schedule.time && !date.length)
        }
      }
    })
  }

  render() {
    const {
      classes,
      appName,
      refreshMigrationSource,
      refreshMigrationStatus,
      cancelMigration,
      acknowledgeMigration,
      dataMigration: { migrations, migrationTest, filters },
      environment
    } = this.props
    const { comment, _schedule } = this.state
    const appMigrations = migrations.list.filter(
      migration => migration.app_name === appName
    )
    const otherMigrations = migrations.list.filter(
      migration => migration.app_name !== appName || migration.environment !== environment
    )

    return (
      <Paper className={classes.migrationView}>
        <Grid container direction="column" justify="space-around" spacing={0}>
          <Grid item>
            <Grid container justify="space-between">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="h6"
                  align="center"
                  color="inherit"
                  style={{ display: 'inline' }}
                >
                  Data Migration Source
                </Typography>
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  key="refresh"
                  color="inherit"
                  onClick={refreshMigrationSource}
                  disabled={!filters.ready || !migrationTest.ready}
                >
                  <RefreshIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justify="space-between" spacing={3}>
              <Grid item className={classes.test}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="stretch"
                  direction="column"
                  spacing={2}
                  className={cn(classes.container)}
                >
                  <Grid item>
                    <AsyncWrapper item={filters} loadingMsg={`Loading Filters`}>
                      <AdminDataMigrationFilter
                        filters={filters.list}
                        update={this.updateFilter}
                      />
                    </AsyncWrapper>
                  </Grid>

                  <AsyncWrapper
                    item={migrationTest}
                    loadingMsg={`Testing Migration`}
                  >
                    <Grid item style={{ margin: 'auto' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.test}
                        disabled={!filters.ready || !migrationTest.ready}
                      >
                        Test
                      </Button>
                    </Grid>
                  </AsyncWrapper>
                </Grid>
              </Grid>
              <Grid item className={classes.commit}>
                <Grid
                  container
                  justify="flex-start"
                  direction="column"
                  alignItems="center"
                  spacing={2}
                  className={cn(classes.container)}
                >
                  <Grid item>
                    <Grid container spacing={3} justify="flex-start">
                      <Grid item>
                        <form noValidate>
                          <TextField
                            fullWidth={false}
                            id="date"
                            label="Date"
                            type="date"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true
                            }}
                            onChange={e => this.updateDate(e.target.value)}
                          />
                        </form>
                      </Grid>
                      <Grid item>
                        <form noValidate>
                          <TextField
                            fullWidth={false}
                            id="time"
                            label="Time"
                            type="time"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              step: 60 // 1 min
                            }}
                            onChange={e => this.updateTime(e.target.value)}
                          />
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this.getMigrationComment}
                      disabled={
                        !migrations.ready ||
                        !migrationTest.ready ||
                        !filters.ready ||
                        !_schedule.ready
                      }
                    >
                      Commit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justify="space-between">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="h6"
                  align="center"
                  color="inherit"
                  style={{ display: 'inline' }}
                >
                  Data Migration Status
                </Typography>
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  key="refresh"
                  color="inherit"
                  onClick={refreshMigrationStatus}
                  disabled={!migrations.ready}
                >
                  <RefreshIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                justify="space-between"
                alignItems="stretch"
                direction="column"
                spacing={2}
                className={cn(classes.container)}
              >
                <AsyncWrapper
                  item={migrations}
                  loadingMsg={`Loading Migrations`}
                >
                  <Grid item>
                    <AdminDataMigrations
                      migrations={appMigrations}
                      name={appName}
                      cancelMigration={cancelMigration}
                      acknowledgeMigration={acknowledgeMigration}
                      environment={environment}
                    />
                  </Grid>
                  <Grid item>
                    <AdminExternalDataMigrations migrations={otherMigrations} />
                  </Grid>
                </AsyncWrapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AdminDataMigrationComment
          open={comment.open}
          update={this.updateComment}
          done={this.commit}
          cancel={this.cancelComment}
          comment={comment.text}
        />
      </Paper>
    )
  }
}

export default Styled(AdminDataMigrationView)
