import templateScorecard from '../../../../../Template/config/components/scorecard'
import metricsLabels from './MetricsLabels'
import scorecardView from './ScorecardView'
import trendChart from './Trendchart'

export default {
  ...templateScorecard,
  maxAdsLength: 300,
  maxNormLength: 300,
  sampleSizeMetric: 4,
  sampleSizeThreshold: 50,
  hideMetricWithMissingNorm: true,
  normStatAccumulateLastMonthOnly: true,
  sortMetric: 1,
  calcFlags: {
    ad: {
      includeAllHistory: true,
      excludeProprietary: false
    },
    norm: {
      includeAllHistory: false,
      excludeProprietary: false,
      excludeNonNorm: true,
      minSampleSize: 50
    }
  },
  metricsLabels,
  scorecardView,
  trendChart
}
