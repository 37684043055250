import { createSelector } from 'reselect'
// import { getSelections } from '../selections'
const getSelections = dashboard => {
  const { sliceSelections } = dashboard.selections.slice
  return Object.keys(sliceSelections).reduce((array, selection) => {
    array.push({
      type: selection,
      ...sliceSelections[selection]
    })
    return array
  }, [])
}

const _getMetricsLabels = dashboard =>
  dashboard.init.config.components.scorecard.metricsLabels

const getMetricsLabels = createSelector(
  [getSelections, _getMetricsLabels],
  (selected, metricsLabels) => {
    return selected.reduce((obj, selection) => {
      return obj[selection.field] || obj['***']
    }, metricsLabels.labels)
  }
)

const _getQuickviewConfig = dashboard =>
  dashboard.init.config.components.quickview

const getQuickviewConfig = createSelector(
  [getSelections, _getQuickviewConfig],
  (selected, quickviewConfig) => {
    return selected.reduce((obj, selection) => {
      return obj[selection.field] || obj['***']
    }, quickviewConfig )
  }
)

export { getMetricsLabels, getQuickviewConfig }
