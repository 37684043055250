import React from 'react'
import { Auth, Logger } from 'aws-amplify'
import AuthPiece from './AuthPiece'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import StyledContainer from './StyledContainer'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '350px',
    marginTop: '50px',
  },
  rounded: {
    borderRadius: '10px',
  },
  header: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  submitButton: {
    width: '100%',
    minWidth: 'unset',
    margin: '30px 0px',
  },
})

const logger = new Logger('RequireNewPassword')

class RequireNewPassword extends AuthPiece {
  constructor(props) {
    super(props)

    this._validAuthStates = ['requireNewPassword']
    this.change = this.change.bind(this)
  }

  change() {
    const user = this.props.authData
    const { password } = this.inputs
    const { requiredAttributes } = user.challengeParam

    Auth.completeNewPassword(user, password, requiredAttributes)
      .then(user => {
        logger.debug('complete new password', user)

        if (user.challengeName === 'SMS_MFA') {
          this.changeState('confirmSignIn', user)
        } else {
          this.changeState('signedIn')
        }
      })
      .catch(err => this.error(err))
  }

  showComponent(theme) {
    const { hide, classes } = this.props

    if (hide && hide.includes(RequireNewPassword)) {
      return null
    }

    return (
      <StyledContainer message="Require New Password">
        <TextField
          id="password"
          label="new password"
          fullWidth
          margin="normal"
          name="password"
          helperText="Passwords require a minimum length of 8 characters and must contain at least one number, one special character, one uppercase letter and one lowercase letter."
          autoComplete="new-password"
          type="password"
          onChange={this.handleInputChange}
        />
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: 30 }}
          className={classes.submitButton}
          onClick={this.change}
        >
          Submit
        </Button>
        <Button
          color="primary"
          style={{ marginLeft: 80 }}
          onClick={() => this.changeState('signIn')}
        >
          Back to Sign In
        </Button>
      </StyledContainer>
    )
  }
}

export default withStyles(styles)(RequireNewPassword)
