import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Checkbox from '@material-ui/core/Checkbox'

const Styled = withStyles( theme => theme.components.selection.Search.BrandsList(theme) )
  
class BrandsList extends React.Component {
  state = {
    search: '',
  }

  updateText = e => {
    this.setState({
      search: e.target.value,
    })
  }

  clearText = () => {
    this.setState({
      search: '',
    })
  }

  render() {
    const { brands, toggle, classes, setAll } = this.props
    const { search } = this.state

    const filtered = brands.filter(d => {
      return d.label.toLowerCase().includes(search.toLowerCase())
    })

    const hasUnselected = brands.find(d => d.selected === false)
    const hasSelected = brands.find(d => d.selected === true)

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <Checkbox
            color="secondary"
            checked={!!hasSelected && !hasUnselected}
            indeterminate={!!hasSelected && !!hasUnselected}
            onClick={() => {
              if (!!hasSelected && !!hasUnselected) {
                setAll(true)
              } else if (!!hasSelected && !hasUnselected) {
                setAll(false)
              } else {
                setAll(true)
              }
            }}
          />
          <TextField
            fullWidth
            style={{ marginLeft: 5, width: '80%' }}
            placeholder="Filter Brands"
            value={search}
            onChange={this.updateText}
          />
        </div>
        <div className={classes.list}>
          <List style={{ paddingTop: 0 }}>
            {filtered.map(d => (
              <div key={d.code}>
                <Divider light />
                <ListItem
                  role={undefined}
                  dense
                  button
                  onClick={() => toggle(d)}
                  className={classes.listItem}
                >
                  <Checkbox
                    color="secondary"
                    checked={d.selected}
                    tabIndex={-1}
                    disableRipple
                  />
                  <img style={{ height: 20 }} src={d.link} alt={d.label} />
                </ListItem>
              </div>
            ))}
          </List>
        </div>
      </div>
    )
  }
}

BrandsList.propTypes = {
  brands: PropTypes.array.isRequired,
  toggle: PropTypes.func.isRequired,
  setAll: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

BrandsList.defaultProps = {
  toggle: item => console.log(item),
  setAll: () => {},
}

export default Styled(BrandsList)
