import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import NestedList from './NestedList'
import CheckIcon from '@material-ui/icons/CheckCircle'
import Tooltip from '@material-ui/core/Tooltip'

const styles = theme => ({
  list: {
    overflowY: 'scroll',
    height: 430,
    width: '100%',
    padding: 0,
    borderRadius: 5,
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    padding: '0px 16px 0px 16px',
    backgroundColor: theme.palette.background.paper,
  },
  checkIcon: {
    fill: theme.palette.primary.main,
    marginBottom: -4,
    width: 20,
    height: 20,
  },
})

class AdTagsList extends Component {

  render() {
    const { adTags, toggle, config, classes } = this.props

    if( adTags.length === 0) return null 
    
    const adTagsMap = {}
    let tagActive = false

    for (let i = 0; i < adTags.length; i++) {
      const tag = adTags[i]
      adTagsMap[tag.id] = tag

      if (tag.selected === 2) {
        tagActive = true
      }
    }

    const filtered = adTags.filter(d => d.parent === 0)

    return (
      <div style={{width: '100%'}}>
        <Typography variant="subtitle1" color="inherit">
          {`Select ${config.baseText}Tags`}
          {tagActive ? <CheckIcon className={classes.checkIcon} /> : null}
        </Typography>
        <List className={classes.list} component="nav">
          {filtered.map(d => {
            if (d.children.length > 0) {
              return (
                <div key={`${d.id}-${d.label}`}>
                  <Divider light />
                  <Tooltip title={d.tooltip} placement="top-start">
                  <NestedList
                    listPadding={0}
                    padding={30}
                    adTagsMap={adTagsMap}
                    item={d}
                    toggle={toggle}
                    nested={d.children.map(c => adTagsMap[c])}
                  />
                  </Tooltip>
                </div>
              )
            }

            return (
              <div key={`${d.id}-${d.label}`}>
                <Divider light />
                <Tooltip title={d.tooltip} placement="top-start">
                  <ListItem
                    button
                    onClick={e => {
                      e.stopPropagation()
                      toggle(d.id)
                    }}
                    key={`${d.id}-${d.label}`}
                    className={classes.item}
                  >
                    <Checkbox checked={d.selected === 2} color="secondary" />
                    <ListItemText primary={d.label} primaryTypographyProps={{ variant: 'body2' }} />
                  </ListItem>
                </Tooltip>
              </div>
            )
          })}
        </List>
      </div>
    )
  }
}

AdTagsList.propTypes = {
  adTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.number.isRequired,
      collapse: PropTypes.number.isRequired,
      parent: PropTypes.number.isRequired,
      children: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
  ).isRequired,
  toggle: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

AdTagsList.defaultProps = {
  toggle: item => console.log(item),
  setAll: () => { },
}

export default withStyles(styles)(AdTagsList)
