import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import { CheckboxSelector } from '../../../../'
import { generateUUID } from '../../../../../new_utils'

const Styled = withStyles(theme =>
  theme.components.selection.Search.MetadataFilter(theme)
)

class MetadataFilter extends React.PureComponent {
  handleChange = value => {
    const { updateFilter, extraFilter } = this.props

    updateFilter({
      data: {
        ...extraFilter.data,
        uuid: generateUUID(),
        options: extraFilter.data.options.map(
          (option, index) =>
            index === value
              ? {
                ...option,
                selected: !option.selected
              }
              : option
        )
      }
    })
  }

  filter = ad => {
    const { extraFilter } = this.props

    const field = extraFilter.data.field
    const adValues = Array.isArray(ad[field]) ? ad[field] : [ad[field]]

    const selected = extraFilter.data.options
      .filter(option => option.selected)

    const otherSelected = selected.filter(item => item.value === 'other').length > 0

    return selected.some(option => adValues.filter(value => {
      const values = Array.isArray(option.value) ? option.value : [option.value]
      return values.includes(value)
    }).length > 0) ||
      (otherSelected && !extraFilter.data.options
        .filter(option => option.value !== 'other')
        .some(option => adValues
          .filter(value => {
            const values = Array.isArray(option.value) ? option.value : [option.value]
            return values.includes(value)
          }).length > 0))

  }

  componentDidMount() {
    const { setFilter, extraFilter, adType, updateFilter } = this.props

    const disabled =
      extraFilter.ad_type &&
      !extraFilter.ad_type.includes(adType.field) &&
      !extraFilter.ad_type.includes('***')
    updateFilter({
      uuid: generateUUID(),
      disabled
    })
    setFilter(this.filter)
  }

  componentDidUpdate(prevProps) {
    const { update } = this.props
    if (
      !prevProps.extraFilter ||
      prevProps.extraFilter.data.uuid !== this.props.extraFilter.data.uuid
    )
      update()
  }

  render() {
    if (!this.props.extraFilter) return null

    const {
      extraFilter: {
        disabledState,
        disabled,
        data: { options, title }
      }
    } = this.props

    if (disabled && disabledState === 'hidden') return null

    return (
      <CheckboxSelector
        update={this.handleChange}
        items={options}
        title={title}
        disabled={disabled}
      />
    )
  }
}

export default Styled(MetadataFilter)
