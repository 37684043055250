export default {
  '***': {
    '***': {
      '***': {
        displayFilters: null,
        staticFilters: [
          {
            label: 'Age',
            code: 'age',
            multi: true,
            options: [
              {
                label: '18-34',
                value: 1,
                selected: false
              },
              {
                label: '35-54',
                value: 2,
                selected: false
              },
              {
                label: '55+',
                value: 3,
                selected: false
              }
            ]
          },
          {
            label: 'Gender',
            code: 'gender',
            multi: true,
            options: [
              {
                label: 'Male',
                value: 1,
                selected: false
              },
              {
                label: 'Female',
                value: 2,
                selected: false
              }
            ]
          },

          {
            label: 'HHI',
            excludeFromVerbatims: true,
            code: 'income',
            multi: true,
            options: [
              {
                label: 'Under $50k',
                value: 1,
                selected: false
              },
              {
                label: '$50k - $99k',
                value: 2,
                selected: false
              },
              {
                label: '$100k+',
                value: 3,
                selected: false
              }
            ]
          },
          {
            label: 'Children <18 in HH',
            code: 'children',
            multi: true,
            options: [
              {
                label: 'Yes',
                value: 1,
                selected: false
              },
              {
                label: 'No',
                value: 2,
                selected: false
              }
            ]
          },
          {
            label: 'Customer/Prospect',
            code: 'customer',
            multi: true,
            options: [
              {
                label: 'Xfinity Customers',
                value: 1,
                selected: false
              },
              {
                label: 'Xfinity Prospects',
                value: 2,
                selected: false
              }
            ]
          },
          {
            label: 'Division',
            excludeFromVerbatims: true,
            code: 'division',
            multi: true,
            options: [
              {
                label: 'Northeast',
                value: 1,
                selected: false
              },
              {
                label: 'Central',
                value: 2,
                selected: false
              },
              {
                label: 'West',
                value: 3,
                selected: false
              },
            ]
          }
        ]
      }
    }
  }
}