import MainPresetsIcon from '@material-ui/icons/GroupWork'
import AdCategoriesIcon from '@material-ui/icons/Loyalty'
import BuildIcon from '@material-ui/icons/Build'
export default [
    {
      "path": "/slicer",
      "label": "Restaurant Type",
      "icon": AdCategoriesIcon
    },
    {
      "path": "/preset",
      "label": "Ad Sets and Norms",
      "icon": MainPresetsIcon
    },
    {
      "path": "/builder",
      "label": "Builder",
      "icon": BuildIcon
    },
  ]
  