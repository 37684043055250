import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { AsyncWrapper } from '../../generic'
import AdLister from './AdLister'
import AdTagger from './AdTagger'
import ExportMetadata from './ExportMetadata'
import Search from './Search'
import { Confirmation } from '../../../new_components'

import {
  Grid,
  Paper,
  TextField,
  Button
} from '@material-ui/core'

import {
  adminUpdate,
  adminSelectAd,
  adminToggleAdTag,
  adminUpdateMetadataField,
  dashboardViewerOpen,
  adminMetadataOpenSearch,
  adminMetadataUpdateRange,
  adminMetadataToggleBrand,
  adminMetadataToggleAllBrands,
  adminMetadataToggleAdTag,
  adminMetadataToggleUntaggedOnly,
  adminMetadataUpdateSeletedAds
} from '../../../new_actions'

const Styled = withStyles(theme =>
  theme.components.adminView.AdminMetadataManager(theme)
)

class AdminMetadataManager extends React.PureComponent {

  state = {
    searchOpen: false,
    stale: false,
    selectedAd: null,
    confirmationTitle: "Confirm Selecting New Ad",
    confirmationMessage: ad_code => [`You have not clicked 'Apply' on changes to ${ad_code}.`, `These will be lost.`],
    match: ''
  }

  update = ({ field, value }) => {
    const { dispatch, ad } = this.props
    dispatch(adminUpdate('metadata', { id: ad.ad_code, field, value }))
  }

  updateField = (field, value) => {
    const { dispatch } = this.props
    dispatch(adminUpdateMetadataField(field, value))
  }

  selectAd = ad_code => {
    const { search: { selectedAdsMap }, dispatch } = this.props
    if (this.state.stale) {
      this.setState({
        openConfirmation: true,
        selectedAd: ad_code
      })
    } else
      dispatch(adminSelectAd(selectedAdsMap[ad_code]))
  }

  confirm = () => {
    const { search: { selectedAdsMap }, dispatch } = this.props
    this.setState({ stale: false, openConfirmation: false, selectedAd: null })
    dispatch(adminSelectAd(selectedAdsMap[this.state.selectedAd]))
  }

  toggleTag = tag => {
    const { dispatch } = this.props
    this.setState({ stale: true })
    dispatch(adminToggleAdTag(tag))
  }

  getActiveTags = () => {
    const { adTags } = this.props
    return adTags.filter(tag => tag.selected).reduce((obj, tag) => { obj[tag.id] = true; return obj }, {})
  }

  openMedia = ad_code => {

    const {
      dispatch,
      adsMap
    } = this.props

    const {
      links: { media },
      title,
    } = adsMap[ad_code]

    this.selectAd(ad_code)
    if (media)
      dispatch(dashboardViewerOpen(media, title, ad_code))
  }

  updateMatch = match => {
    this.setState({
      match: match.replace(/[?*.[\]()^%/\\]/gi, "")
    })
  }

  openSearch = status => {
    const { dispatch } = this.props
    dispatch(adminMetadataOpenSearch(status))
  }

  updateRange = range => {
    const { dispatch } = this.props
    dispatch(adminMetadataUpdateRange(range))
  }

  toggleBrand = brand => {
    const { dispatch } = this.props
    dispatch(adminMetadataToggleBrand(brand))
  }

  selectAllBrands = () => {
    const { dispatch } = this.props
    dispatch(adminMetadataToggleAllBrands())
  }

  toggleAdTag = tag => {
    const { dispatch } = this.props
    dispatch(adminMetadataToggleAdTag(tag))
  }

  toggleUntaggedOnly = () => {
    const { dispatch } = this.props
    dispatch(adminMetadataToggleUntaggedOnly())
  }

  updateSelectedAds = () => {
    const { dispatch } = this.props
    dispatch(adminMetadataUpdateSeletedAds())
  }

  componentDidUpdate(prevProps, prevState) {

  }

  render() {

    const {
      search,
      adsList,
      brandsMap,
      asyncItem,
      hasTags,
      adTags,
      tagStructureConfig,
      slicerConfig,
      ad,
      appName,
      genericConfig,
      classes
    } = this.props

    return (
      <Paper className={classes.metadataView}>
        <AsyncWrapper item={asyncItem}>
          <Grid container className={classes.container} spacing={4} alignItems="flex-start">
            <Grid item xs={5}>
              <Grid container justify="space-between">
                <Grid item style={{ width: 300, marginLeft: 10 }}>
                  <TextField
                    size="medium"
                    helperText={`fiter Ad Code`}
                    value={search.match}
                    onChange={e => this.updateMatch(e.target.value)}
                    autoComplete="off"
                    fullWidth={true}
                  />
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="secondary" onClick={() => this.openSearch(true)}>Select Ads</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={4}>
              <ExportMetadata
                hasTags={hasTags}
                tagStructureConfig={tagStructureConfig}
                slicerConfig={slicerConfig}
                adsList={search.selectedAdsList}
                appName={appName}
              />
            </Grid>
            <Search
              search={search}
              hasTags={hasTags}
              untaggedOnly={search.untaggedOnly}
              maxRange={search.maxRange}
              range={search.range}
              uuid={search.uuid}
              brandsMap={brandsMap}
              adsList={adsList}
              toggleAdTag={this.toggleAdTag}
              toggleBrand={this.toggleBrand}
              toggleUntaggedOnly={this.toggleUntaggedOnly}
              selectAllBrands={this.selectAllBrands}
              updateRange={this.updateRange}
              updateSelectedAds={this.updateSelectedAds}
              close={() => this.openSearch(false)}
              isOpen={search.isOpen}
            />
          </Grid>
          <Grid container className={classes.container} spacing={4} alignItems="flex-start">
            <Grid item className={classes.listContainer} xs={5}>
              <AdLister
                hasTags={hasTags}
                adTags={adTags}
                openMedia={this.openMedia}
                selected={ad && ad.ad_code}
                adsList={search.selectedAdsList.filter(ad => ad.ad_code.match(this.state.match))}
                callback={this.selectAd}
                uuid={search.uuid} />
            </Grid>
            <Grid item className={classes.editContainer} xs={3}>
              <TextField
                size="medium"
                value={(ad && ad.title) || ''}
                onChange={event => this.updateField('title', event.target.value)}
                onKeyPress={event => event.key === "Enter" && this.update({ field: 'title', value: ad.title })}
                fullWidth
                helperText={'Update title'} />
            </Grid>
            <Grid item className={classes.tagContainer} xs={4}>
              <AdTagger
                adTags={adTags}
                ad={ad}
                update={() => this.update({ field: 'tags', value: this.getActiveTags() })}
                toggleTag={this.toggleTag} 
                config={genericConfig}/>
              <Confirmation open={this.state.openConfirmation}
                confirm={this.confirm}
                cancel={() => this.setState({ openConfirmation: false })}
                message={this.state.confirmationMessage(ad.ad_code)}
                title={this.state.confirmationTitle}
              />
            </Grid>
          </Grid>
        </AsyncWrapper>
      </Paper>
    )
  }
}

const Connected = connect(state => {
  const { isAuto } = state.admin.view
  const { viewer } = state.dashboard
  const asyncItem = state.admin.metadata
  const { hasTags, adTags, ad, search, adsList, adsMap, brandsMap, tagStructureConfig } = state.admin.metadata

  const slices = !isAuto && state.dashboard.init.config.metadata.slices
  const appName = !isAuto && state.dashboard.init.resourceConfig.appName
  const genericConfig = !isAuto && state.dashboard.init.config.components.generic
  return {
    hasTags,
    tagStructureConfig,
    slicerConfig: slices,
    appName,
    adTags,
    ad,
    search,
    adsList,
    adsMap,
    brandsMap,
    asyncItem,
    viewer,
    genericConfig
  }
})

export default Connected(Styled(AdminMetadataManager))
