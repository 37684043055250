export default {
  '***': {
    '***': {
      '***': {
        headerHeight: 80,
        rowTitleHeight: 35,
        padding: 1,
        aspectRatio: 0.7727,
        externalHeaderHeight: 0,
        minContainerHeight: 1000,
        horizontalMargin: 10,
        verticalMargin: 10,
        rows: [
          {
            height: 0.33,
            title: 'IN-MARKET PERFORMANCE',
            data: [
              {
                content: [
                  {
                    component: 'MetricDonut',
                    data: {
                      metric: 100,
                      metricScale: 200
                    }
                  }
                ],
                width: 0.3,
                key: null
              },
              {
                content: [
                  {
                    component: 'MetricTable',
                    data: {
                      label: 'Recall & Branding',
                      metrics: [
                        { metric: 111, isHeaderMetric: true, isCapitalized: true }, //102
                        { metric: 131, isHeaderMetric: true }, //111
                        { metric: 132, isHeaderMetric: true } //112
                      ]
                    }
                  }
                ],
                width: 0.35,
                key: null
              },
              {
                content: [
                  {
                    component: 'MetricTable',
                    data: {
                      label: 'Persuasion',
                      metrics: [
                        { metric: 121, isHeaderMetric: true, isCapitalized: true }, // 103 -> 121
                        { metric: 123, isHeaderMetric: true, isCapitalized: true }, // 105 -> 123
                        { metric: 125, isHeaderMetric: false, isInset: true }, // 107 -> 125
                        { metric: 126, isHeaderMetric: false, isInset: true }, // 108 -> 126
                        {
                          metric: 127, // 109 -> 127
                          label: 'Go to retail store',
                          isHeaderMetric: false,
                          isInset: true
                        },
                        { metric: 128, isHeaderMetric: false, isInset: true } // 110 -> 128
                      ]
                    }
                  }
                ],
                width: 0.35,
                key: null
              }
            ]
          },
          {
            height: 0.67,
            title: 'CREATIVE PERFORMANCE',
            data: [
              {
                aggregatedMetrics: [10000, 10001, 10002, 10003, 10004, 10005],
                content: [
                  {
                    component: 'RadarChart',
                    data: {
                      score: {
                        label: 'MMM Score',
                        metric: 10000
                      },
                      norm: {
                        label: 'Xfinity Avg'
                      },
                      metrics: [
                        {
                          label: 'Branding / Emotional',
                          metric: 10001,
                          scale: {
                            min: -1000,
                            max: 1000
                          }
                        },
                        {
                          label: 'Relevant',
                          metric: 10002,
                          scale: {
                            min: -1000,
                            max: 1000
                          }
                        },
                        {
                          label: 'Something Different',
                          metric: 10003,
                          scale: {
                            min: -1000,
                            max: 1000
                          }
                        },
                        {
                          label: 'Call to Action',
                          metric: 10004,
                          scale: {
                            min: -1000,
                            max: 1000
                          }
                        },
                        {
                          label: 'Value',
                          metric: 10005,
                          scale: {
                            min: -1000,
                            max: 1000
                          }
                        }
                      ]
                    }
                  },
                  {
                    component: 'MetricTable',
                    data: {
                      label: 'Value Proposition',
                      metrics: [
                        { metric: 141, isHeaderMetric: true }, // 114 -> 141
                        { metric: 142, isHeaderMetric: true }, // 115 -> 142
                        { metric: 143, isHeaderMetric: true }, // 116 -> 143
                        { metric: 144, isHeaderMetric: true }, // 117 -> 144
                        { metric: 145, isHeaderMetric: true } // 118 -> 145
                      ]
                    }
                  }
                ],
                legend: {
                  xOffset: 10,
                  yOffset: -20
                },
                width: 0.3,
                key: null
              },
              {
                content: [
                  {
                    component: 'MetricChart',
                    data: {
                      title: 'Creative Efficiency',
                      mainMetric: {
                        type: 'horizontal',
                        scale: {
                          start: 85,
                          end: 155
                        },
                        metric: 200,
                        label: 'Creative Summary Score' // 121 -> 200
                      },
                      subMetrics: [
                        { metric: 210 }, // 122 -> 210
                        { metric: 240 }, // 127 -> 240
                        { metric: 220 }, // 135 -> 220
                        { metric: 250, label: 'Cognitive' }, // 139 -> 250
                        { metric: 260, label: 'Emotional' }, // 146 -> 260
                        { metric: 270 } // 151 -> 270
                      ]
                    }
                  }
                ],
                width: 0.35,
                key: null
              },
              {
                content: [
                  {
                    component: 'MetricOpenend',
                    data: {
                      label: 'In Their Own Words',
                      topBox: {
                        likes: {
                          metric: 151,
                          noStatColor: '#BFBFBF' // 119 -> 151
                        },
                        dislikes: {
                          metric: 152,
                          noStatColor: '#AFABAB' // 120 -> 152
                        }
                      },
                      messageField: 'message',
                      wordcloudConfig: {
                        sentiments: [
                          { color: '#FF872F', description: 'Very Negative' },
                          { color: '#FFC397', description: 'Negative' },
                          { color: '#DAD9D6', description: 'Neutral' },
                          { color: '#C4E1AF', description: 'Positive' },
                          { color: '#89C35F', description: 'Very Positive' }
                        ],
                        scoreRange: [0, 7],
                        font: 'Arial, Helvetica, sans-serif',
                        showCounts: false,
                        padding: 5,
                        rotation: 0,
                        wordCountCutoff: 2,
                        wordLengthCutoff: 3,
                        numDisplayed: 100
                      }
                    }
                  }
                ],
                width: 0.35,
                key: null
              }
            ]
          }
        ],
        legend: {
          metadataFields: [
            {
              value: 'last_month_tested',
              label: 'Last Tested'
            }
          ],
          includeSelections: true,
          includeStatTesting: true,
          includeFilters: {
            label: 'Additional Targets',
            default: ''
          }
        }
      }
    }
  }
}
