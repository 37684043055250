export default {
  slices: [
    {
      field: 'ad_type',
      display: 'Media Type',
      map: {
        tv: {
          display: 'TV',
          mediaType: 'mp4',
          sortOrder: 1
        }
      }
    }
  ]
}
