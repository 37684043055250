export default {
  '***': {
    '***': {
      '***': [
        {
          "id": 10,
          "label": "Internet, TV, OTT Product Type Overall",
          "selected": 0,
          "collapse": 0,
          "parent": 0,
          "children": [
            20,
            25,
            30,
            40,
            50,
            60,
            65,
            70,
            80,
            90,
            91,
            95
          ],
          "tooltip": "This will be checked automatically when any item in this section is checked."
        },
        {
          "id": 20,
          "label": "High Speed Internet",
          "selected": 0,
          "collapse": 0,
          "parent": 10,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 25,
          "label": "xFi",
          "selected": 0,
          "collapse": 0,
          "parent": 10,
          "children": [],
          "tooltip": "For Xfinity ads only. Any reference to xFi which is Xfinity's digital dashboard to manage your home WiFi network"
        },
        {
          "id": 30,
          "label": "Cable TV",
          "selected": 0,
          "collapse": 0,
          "parent": 10,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 40,
          "label": "Satellite TV",
          "selected": 0,
          "collapse": 0,
          "parent": 10,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 50,
          "label": "TV Add-Ons (e.g. DVR, OnDemand, etc.)",
          "selected": 0,
          "collapse": 0,
          "parent": 10,
          "children": [],
          "tooltip": "Includes any product that is additional to core TV service. Does not include Voice Remote or OTT apps."
        },
        {
          "id": 60,
          "label": "Voice Control",
          "selected": 0,
          "collapse": 0,
          "parent": 10,
          "children": [],
          "tooltip": "Refers to any ad focusing on voice control via a remote, a streaming device, a set-top box, etc."
        },
        {
          "id": 65,
          "label": "X1",
          "selected": 0,
          "collapse": 0,
          "parent": 10,
          "children": [],
          "tooltip": "For Xfinity ads only. Any reference to X1 which is a set-top box platform"
        },
        {
          "id": 70,
          "label": "Streaming Services",
          "selected": 0,
          "collapse": 0,
          "parent": 10,
          "children": [],
          "tooltip": "Includes any streaming services provided by cable, satellite or OTT companies"
        },
        {
          "id": 80,
          "label": "Live TV Services",
          "selected": 0,
          "collapse": 0,
          "parent": 10,
          "children": [],
          "tooltip": "Refers to streaming services that provide live TV access (e.g. sports games)"
        },
        {
          "id": 90,
          "label": "Video Content Focus (e.g. TV program or movie)",
          "selected": 0,
          "collapse": 0,
          "parent": 10,
          "children": [],
          "tooltip": "Refers to ads focusing on the TV program, movie, series, etc. offered by the streaming or TV service (such as a trailer)"
        },
        {
          "id": 91,
          "label": "Gaming Focus",
          "selected": 0,
          "collapse": 0,
          "parent": 10,
          "children": [],
          "tooltip": "Refers to ads focusing on play from a Video Game or App"
        },
        {
          "id": 95,
          "label": "Retail",
          "selected": 0,
          "collapse": 0,
          "parent": 10,
          "children": [],
          "tooltip": "Refers to ads that drive consumers to visit a retail store or focus on a retail store"
        },
        {
          "id": 100,
          "label": "Mobile Product Type Overall",
          "selected": 0,
          "collapse": 0,
          "parent": 0,
          "children": [
            110,
            120,
            130,
            140,
            145
          ],
          "tooltip": "This will be checked automatically when any item in this section is checked."
        },
        {
          "id": 110,
          "label": "Unlimited Data Plan",
          "selected": 0,
          "collapse": 0,
          "parent": 100,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 120,
          "label": "Family Plan",
          "selected": 0,
          "collapse": 0,
          "parent": 100,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 130,
          "label": "No Contract/Pay as You Go",
          "selected": 0,
          "collapse": 0,
          "parent": 100,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 140,
          "label": "Mobile Hotspots",
          "selected": 0,
          "collapse": 0,
          "parent": 100,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 145,
          "label": "Retail-Mobile",
          "selected": 0,
          "collapse": 0,
          "parent": 100,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 150,
          "label": "Home Security Product Type Overall",
          "selected": 0,
          "collapse": 0,
          "parent": 0,
          "children": [
            160,
            170,
            180,
            190
          ],
          "tooltip": "This will be checked automatically when any item in this section is checked."
        },
        {
          "id": 160,
          "label": "Home Security Monitoring",
          "selected": 0,
          "collapse": 0,
          "parent": 150,
          "children": [],
          "tooltip": "Refers to remote, professional security monitoring via a service or call center"
        },
        {
          "id": 170,
          "label": "Home Automation (e.g. lights, heat, doors, etc.)",
          "selected": 0,
          "collapse": 0,
          "parent": 150,
          "children": [],
          "tooltip": "Includes control of any item in house outside of an actual alarm or camera"
        },
        {
          "id": 180,
          "label": "HS Equipment Focus (e.g. Doorbell, Floodlight Cameras)",
          "selected": 0,
          "collapse": 0,
          "parent": 150,
          "children": [],
          "tooltip": "Refers to ads focusing on specific home security equipment"
        },
        {
          "id": 190,
          "label": "Remote App",
          "selected": 0,
          "collapse": 0,
          "parent": 150,
          "children": [],
          "tooltip": "Refers to ads referencing an app that allows you to control your home security or automation system"
        },
        {
          "id": 200,
          "label": "Brand Image Overall",
          "selected": 0,
          "collapse": 0,
          "parent": 0,
          "children": [
            210,
            220,
            230,
            240
          ],
          "tooltip": "This will be checked automatically when any item in this section is checked."
        },
        {
          "id": 210,
          "label": "Awards/Accolades",
          "selected": 0,
          "collapse": 0,
          "parent": 200,
          "children": [],
          "tooltip": "Refers to any reference of awards or rankings, 3rd party or commissioned research based."
        },
        {
          "id": 220,
          "label": "Customer Service",
          "selected": 0,
          "collapse": 0,
          "parent": 200,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 230,
          "label": "General Reputation",
          "selected": 0,
          "collapse": 0,
          "parent": 200,
          "children": [],
          "tooltip": "Refers to any ad that focuses on a brand's general reputation, but does not mention any products"
        },
        {
          "id": 240,
          "label": "Sponsorship",
          "selected": 0,
          "collapse": 0,
          "parent": 200,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 250,
          "label": "Offers Overall",
          "selected": 0,
          "collapse": 0,
          "parent": 0,
          "children": [
            260,
            270,
            280,
            290,
            300,
            310,
            320,
            330
          ],
          "tooltip": "This will be checked automatically when any item in this section is checked."
        },
        {
          "id": 260,
          "label": "Bundle Pricing (Services, Lines, etc.)",
          "selected": 0,
          "collapse": 0,
          "parent": 250,
          "children": [],
          "tooltip": "Includes bundled services (e.g. TV, Internet) or line bundles (e.g. up to 5 lines)"
        },
        {
          "id": 270,
          "label": "Cash Back/Credit/Rewards Cards",
          "selected": 0,
          "collapse": 0,
          "parent": 250,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 280,
          "label": "Free Equipment/Service",
          "selected": 0,
          "collapse": 0,
          "parent": 250,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 290,
          "label": "Free Trial",
          "selected": 0,
          "collapse": 0,
          "parent": 250,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 300,
          "label": "Limited Time/Special Discount",
          "selected": 0,
          "collapse": 0,
          "parent": 250,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 310,
          "label": "No Contract",
          "selected": 0,
          "collapse": 0,
          "parent": 250,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 320,
          "label": "Premium Channels/Streaming Apps Included",
          "selected": 0,
          "collapse": 0,
          "parent": 250,
          "children": [],
          "tooltip": "Includes references to channels/apps like HBO, Showtime or Netflix, Hulu"
        },
        {
          "id": 330,
          "label": "Price Guarantee",
          "selected": 0,
          "collapse": 0,
          "parent": 250,
          "children": [],
          "tooltip": "Refers to any guarantee (e.g. \"price for life\", \"life of contract\" etc.)"
        },
        {
          "id": 340,
          "label": "Creative Type Overall",
          "selected": 0,
          "collapse": 0,
          "parent": 0,
          "children": [
            350,
            360,
            370,
            380,
            385,
            387
          ],
          "tooltip": "This will be checked automatically when any item in this section is checked."
        },
        {
          "id": 350,
          "label": "Narrative",
          "selected": 0,
          "collapse": 0,
          "parent": 340,
          "children": [],
          "tooltip": "Ad follows a story trajectory, even if short"
        },
        {
          "id": 360,
          "label": "Vignette",
          "selected": 0,
          "collapse": 0,
          "parent": 340,
          "children": [],
          "tooltip": "Ad does not have a story, but rather many different scenes cut together"
        },
        {
          "id": 370,
          "label": "Animated",
          "selected": 0,
          "collapse": 0,
          "parent": 340,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 380,
          "label": "Graphics/Product Images Only",
          "selected": 0,
          "collapse": 0,
          "parent": 340,
          "children": [],
          "tooltip": "Ad is a computer generated ad usually focusing on a product with few or no actual filmed scenes"
        },
        {
          "id": 385,
          "label": "Direct Response",
          "selected": 0,
          "collapse": 0,
          "parent": 340,
          "children": [],
          "tooltip": "Refers to ads that ask consumers to make an immediate response (e.g. Prominent 800 Number, \"Click Here\")"
        },
        {
          "id": 387,
          "label": "Closed Captioning",
          "selected": 0,
          "collapse": 0,
          "parent": 340,
          "children": [],
          "tooltip": "Include only if the spot has built-in Closed Captioning"
        },
        {
          "id": 390,
          "label": "Creative Style Overall",
          "selected": 0,
          "collapse": 0,
          "parent": 0,
          "children": [
            400,
            410,
            420,
            430,
            435,
            440,
            450,
            455
          ],
          "tooltip": "This will be checked automatically when any item in this section is checked."
        },
        {
          "id": 400,
          "label": "Humorous",
          "selected": 0,
          "collapse": 0,
          "parent": 390,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 410,
          "label": "Emotional",
          "selected": 0,
          "collapse": 0,
          "parent": 390,
          "children": [],
          "tooltip": "No tooltip provided"
        },
        {
          "id": 420,
          "label": "Family Focus (e.g. kids, families, etc.)",
          "selected": 0,
          "collapse": 0,
          "parent": 390,
          "children": [],
          "tooltip": "Includes any ads focusing on families or children. Does not include couples only"
        },
        {
          "id": 430,
          "label": "Older Focus (e.g. older adults)",
          "selected": 0,
          "collapse": 0,
          "parent": 390,
          "children": [],
          "tooltip": "Includes any ads with a particular focus on adults 60+"
        },
        {
          "id": 435,
          "label": "Younger Focus (e.g. Younger Adults)",
          "selected": 0,
          "collapse": 0,
          "parent": 390,
          "children": [],
          "tooltip": "Includes any ads with a particular focus on young adults under 25"
        },
        {
          "id": 440,
          "label": "Spokesperson",
          "selected": 0,
          "collapse": 0,
          "parent": 390,
          "children": [],
          "tooltip": "Includes any celebrity inclusion, whether or not that person is a spokesperson"
        },
        {
          "id": 450,
          "label": "Celebrity",
          "selected": 0,
          "collapse": 0,
          "parent": 390,
          "children": [],
          "tooltip": "Includes any celebrity inclusion, whether or not that person is a spokesperson"
        },
        {
          "id": 455,
          "label": "Special Group Focus",
          "selected": 0,
          "collapse": 0,
          "parent": 390,
          "children": [],
          "tooltip": "Includes societal groups or occupations (e.g., Military, Firefighters, Ethnic Groups)"
        }
      ]
    }
  }
}