export default Object.assign(
    theme => {
        return {
            titleOffset: 10,
            titleFontSize: 17,
            titleFontStyle: 'bold',
            titleColor: theme.palette.grey[600],
            noStatColor: theme.palette.grey[400],
            xOffset: 0
        }
    })
