import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

const likeabilityMap = {
  sentiments: [
    { color: '#FF872F', description: 'Very Negative' },
    { color: '#FFC397', description: 'Negative' },
    { color: '#DAD9D6', description: 'Neutral' },
    { color: '#C4E1AF', description: 'Positive' },
    { color: '#89C35F', description: 'Very Positive' }
  ],
  scoreRange: [0, 7]
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  sentiment: {
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  paper: {
    height: 75,
    background: theme.palette.background,
    padding: 25,
    paddingTop: 30,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 15,
    },
    position: 'relative',
  },
})

class WordCloudLegend extends PureComponent {
  render() {
    const { classes, mainMessage } = this.props

    const mainLegend = (
      <Grid item>
        <Typography variant="caption">
          Each term's darkness is shaded based on volume
        </Typography>
      </Grid>
    )

    const likeabilityLegend = likeabilityMap.sentiments.map(sentiment => {
      return (
        <Grid item key={sentiment.description}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <div
                className={classes.sentiment}
                style={{ backgroundColor: sentiment.color }}
              />
            </Grid>
            <Grid item>
              <Typography noWrap variant="caption">
                {sentiment.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )
    })

    return (
      <Paper className={classes.paper} elevation={0} >
        <Grid container className={classes.root} alignItems="center">
          <Grid item xs={2}>
            <Typography align="left" variant="caption">
              {mainMessage ? null : `Sentiment Indication:`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid
              container
              direction="row"
              justify="center"
              spacing={3}
              alignItems="center"
              alignContent="center"
            >
              {mainMessage ? mainLegend : likeabilityLegend}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}
WordCloudLegend.propTypes = {
  mainMessage: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(WordCloudLegend)
