export default {
  slices: [
    {
      field: 'ad_type',
      display: 'Media Type',
      hidden: false,
      map: {
        tv: {
          display: 'TV',
          mediaType: 'mp4',
          sortOrder: 1
        },
        radio: {
          display: 'Radio',
          mediaType: 'mp3',
          sortOrder: 2
        },
        print: {
          display: 'Print',
          mediaType: 'pdf',
          sortOrder: 3
        },
        online_display: {
          display: 'Online Display',
          mediaType: 'pdf',
          sortOrder: 4
        },
        online_video: {
          display: 'Online Video',
          mediaType: 'mp4',
          sortOrder: 5
        }
      }
    }
  ]
}
