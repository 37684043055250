import { setBuilderToolbar } from '../../../../../Template/config/components/selection/SetBuilder'

export default {
  ...setBuilderToolbar,
  enableExcludeNonNormFilters: {
    ad: false,
    norm: false
  },
  exportButtons: {
    ad: {
      data: true,
      media: true
    },
    norm: {
      data: true,
      media: true
    }
  },
  sortOptions: [
    {
      "value": -1,
      "label": "Alpha Order by Ad Code",
      "field": "ad_code",
      "selected": false
    },
    {
      "value": 27,
      "label": "AdPi Score",
      "selected": true,
      "dataFormat": ".,0"
    },
    {
      "value": 1,
      "label": "% Ad Recall / Seen Before",
      "selected": false
    },
    {
      "value": 2,
      "label": "% Correct Brand ID / Recall",
      "selected": false
    },
    {
      "value": 7,
      "label": "Lift in Brand Impression (Top 2 Box)",
      "selected": false
    },
    {
      "value": 8,
      "label": "Lift in Brand Consideration (Top 2 Box)",
      "selected": false,
      "dataFormat": ".,0"
    },
    {
      "value": 9,
      "label": "% Positive Actions / Intentions (NET)",
      "selected": false
    },
    {
      "value": 19,
      "label": "Subscribed/Made a purchase",
      "selected": false
    },
    {
      "value": 21,
      "label": "Signed up for a free trial",
      "selected": false
    }

  ]
}
