import searchFilter from './SearchFilter'
import searchManual from './SearchManual'
import tagHierarchy from './TagHierarchy'

export default {
  tabs: [
    {
      key: 'search',
      label: 'Search Filters'
    },
    {
      key: 'manual',
      label: 'Enter Ad Codes'
    }
  ],
  searchManual,
  searchFilter,
  tagHierarchy
}

export { searchManual, searchFilter, tagHierarchy }
