import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Paper
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { Logo } from '../../'
const Styled = withStyles(theme => {
  return theme.components.adminView.generic.AdminBar(theme)
})

const AdminBar = props => {
  const {
    close,
    classes,
    environment,
    appName,
    appLogoSize,
    disableGutters,
    software
  } = props
  // const software = {
  //   serverVersion: 'v2.0.2-comc1-p1-dash-p2',
  //   clientVersion: 'v2.0.2-comc1-p1-dash-p2'
  // }
  return (
    <AppBar className={classes.appBar}>
      <Paper elevation={3} className={classes.paper}>
        <Toolbar disableGutters={disableGutters}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Grid container justify="flex-start">
                <Grid item>
                  <IconButton
                    edge="start"
                    color="primary"
                    onClick={close}
                    aria-label="close"
                    classes={{
                      colorPrimary: classes.colorPrimary
                    }}
                  >
                    <Close />
                  </IconButton>
                </Grid>
                <Grid item>
                {!(appName.match('category')||appName.match('demo')) && <Logo name={appName} size={appLogoSize} /> }
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="textPrimary" display="block">
                Software Version
              </Typography>
              <Typography variant="caption" color="textPrimary" display="block">
                Server: {software.serverVersion} - Client: {software.clientVersion}
              </Typography>
            </Grid>
            <Grid item className={classes.title}>
              <Typography
                variant="h6"
                align="center"
                color="textPrimary"
                style={{ display: 'inline', marginRight: 15 }}
              >
                AdPi Dashboard Admin Interface
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="textPrimary"
                style={{ display: 'inline' }}
                className={classes[environment]}
              >
                {environment.toUpperCase()} Environment
              </Typography>
            </Grid>
            <Grid item>
              <Logo
                name="marketcast"
                size="medium"
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Paper>
    </AppBar>
  )
}

AdminBar.defaultProps = {
  appLogoSize: 'large',
  disableGutters: false
}

export default Styled(AdminBar)
