export default {
  spendField: 'thirty_day_spend',
  interpolateMonths: true,
  trendMetrics: [
    {
      metric: 100,
      field: 'adpi',
      label: 'AdPi Score',
      format: '.0f',
      scale: [80, 160]
    },
    {
      metric: 102,
      field: 'recall',
      label: 'Branded Recall',
      format: '.0%',
      hasSpendData: true,
      scale: [0, 0.8]
    },
    {
      metric: 103,
      field: 'consideration',
      label: 'Lift to Consideration',
      format: '.0%',
      scale: [0, 0.6]

    },
    {
      metric: 105,
      field: 'actions',
      label: 'Net Actions',
      format: '.0%', 
      scale: [0, 0.6]
    },
    {
      metric: 104,
      field: 'impression',
      label: 'Lift to Impression',
      format: '.0%',
      scale: [0, 0.6]
    },
  ]
}
