const DASHBOARD_CLR_STATIC_FILTER = 'DASHBOARD_CLR_STATIC_FILTER'
const DASHBOARD_UPDATE_STATIC_FILTER = 'DASHBOARD_UPDATE_STATIC_FILTER'

const dashboardUpdateStaticFilter = data => {
  return {
    type: DASHBOARD_UPDATE_STATIC_FILTER,
    data
  }
}

const dashboardClrStaticFilter = () => {
  return {
    type: DASHBOARD_CLR_STATIC_FILTER
  }
}

export {
  DASHBOARD_CLR_STATIC_FILTER,
  DASHBOARD_UPDATE_STATIC_FILTER,
  dashboardUpdateStaticFilter,
  dashboardClrStaticFilter
}
