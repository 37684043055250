import MainPresetsIcon from '@material-ui/icons/GroupWork'
import AdCategoriesIcon from '@material-ui/icons/Loyalty'
import BuildIcon from '@material-ui/icons/Build'
import NewSurveyIcon from '@material-ui/icons/Create'

export default [
    {
      "path": "/slicer",
      "label": "Category Select",
      "icon": AdCategoriesIcon
    },
    {
      "path": "/preset",
      "label": "Ad Sets and Norms",
      "icon": MainPresetsIcon
    },
    {
      "path": "/builder",
      "label": "Builder",
      "icon": BuildIcon
    },
    {
      "path": "/survey",
      "label": "Test Ad",
      "icon": NewSurveyIcon
    },
  ]
  