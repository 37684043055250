import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import HomeIcon from '@material-ui/icons/Home'
import MenuIcon from '@material-ui/icons/Menu'
import LogoutIcon from '@material-ui/icons/PowerSettingsNew'
import Typography from '@material-ui/core/Typography'
import { Auth } from 'aws-amplify'
import { withRouter } from 'react-router-dom'
import { AdminButton, Logo } from '../../../new_components'

const styles = theme => ({
  root: {
    position: 'fixed',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  paper: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  boxShadow: {
    border: `1px solid ${theme.palette.background.default}`,
    boxShadow: `0 1px 1px rgba(0,0,0,0.14)`
  }
})

const NavBar = props => {
  const {
    title,
    toggleDrawer,
    logo,
    classes,
    disableGutters,
    showHome,
    history,
    showMenuIcon,
    openAdmin,
    hasAdminViewAccess
  } = props
  return (
    <AppBar className={classes.root} elevation={0}>
      <Paper elevation={0} className={classes.paper} classes={{ elevation0: classes.boxShadow }}>
        <Toolbar disableGutters={disableGutters}>
          {showMenuIcon ? (
            <IconButton style={{ margin: '0px 12px' }} onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          ) : null}
          <div style={{ marginTop: 6, marginRight: 20, marginLeft:20 }}>{logo && React.createElement(logo)}</div>
          <div style={{ marginTop: 5.5, flex: 1 }}>
            <Typography variant="subtitle1" noWrap color="textPrimary">
              {title}
            </Typography>
          </div>
          <AdminButton visible={hasAdminViewAccess} style={{ marginRight: 40 }} action={openAdmin} />
          <div style={{ marginTop: 0, marginRight: 40 }}>
            <Logo name="marketcast" size="medium" />
          </div>
          <div>
            {showHome ? (
              <Tooltip id="home-tooltip" title="Home">
                <IconButton
                  onClick={e => {
                    e.preventDefault()
                    history.push('/')
                  }}
                >
                  <HomeIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip id="logout-tooltip" title="Logout">
              <IconButton
                onClick={e => {
                  e.preventDefault()
                  Auth.signOut().then(() => {
                    history.push('/')
                    window.location.reload(true)
                  })
                }}
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </Paper>
    </AppBar>
  )
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  showHome: PropTypes.bool.isRequired,
  disableGutters: PropTypes.bool.isRequired
}

NavBar.defaultProps = {
  showHome: true,
  showMenuIcon: true,
  disableGutters: false
}

export default withRouter(withStyles(styles)(NavBar))
