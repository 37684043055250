export default {
  '***': [
      {
        "id": 10,
        "label": "Ad Messages Overall",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          20,
          30,
          40,
          50,
          60,
          70,
          80,
          90,
          100,
          110,
          120,
          130,
          140,
          160
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 20,
        "label": "Cash Back Rewards",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "CB %, on categories, special conditions"
      },
      {
        "id": 30,
        "label": "Credit Monitoring",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "FICO"
      },
      {
        "id": 40,
        "label": "No Annual Fee",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 50,
        "label": "Security",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "Fraud, Protecting SSN …"
      },
      {
        "id": 60,
        "label": "Customer Service",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 70,
        "label": "Technology",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "Apps, AI, P2P Payment…"
      },
      {
        "id": 80,
        "label": "Travel",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "Travel Card ads: Aadvantage, Venture, Miles, Sapphire..."
      },
      {
        "id": 90,
        "label": "CC Network benefits",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "All Visa, Mastercard ads"
      },
      {
        "id": 100,
        "label": "Innovation",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "New benefits introduced in the sector"
      },
      {
        "id": 110,
        "label": "Acceptance",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 120,
        "label": "Brand image",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 130,
        "label": "Cause",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 140,
        "label": "Sponsorship",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 160,
        "label": "Small Business",
        "selected": 0,
        "collapse": 0,
        "parent": 10,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 200,
        "label": "Creative Style Overall",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          210,
          220,
          230,
          240,
          250
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 210,
        "label": "Humorous",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 220,
        "label": "Emotional",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 230,
        "label": "Spokesperson",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Includes non-celebrity spokes person"
      },
      {
        "id": 240,
        "label": "Celebrity",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Includes any celebrity inclusion, whether or not that person is a spokesperson"
      },
      {
        "id": 250,
        "label": "Promotional/Limited time offer",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 300,
        "label": "Ad Category Overall",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          305,
          310
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 305,
        "label": "Bank",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Bank, Debit, Checking…"
      },
      {
        "id": 310,
        "label": "Credit Card",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Check is the ad is about credit card"
      }
    ]
}