export default {
  enableExcludeNonNormFilters: {
    ad: true,
    norm: true
  },
  exportButtons: {
    ad: {
      data: true,
      media: true
    },
    norm: {
      data: true,
      media: true
    }
  },
  sortOptions: [
    {
      value: -1,
      label: 'Alpha Order by Ad Code',
      selected: false
    },
    {
      value: 3,
      label: 'Branded Recall (Breakthrough NBR)',
      selected: false
    },
    {
      value: 4,
      label: 'Persuasion (Avg Motivation)',
      selected: true
    }
  ]
}
