import { resourceConfig } from '../../../app/configuration'

import {
  DASHBOARD_SET_CONFIG,
  DASHBOARD_REQUEST_METADATA,
  DASHBOARD_RECEIVE_METADATA,
  DASHBOARD_FAIL_METADATA,
  DASHBOARD_CLR_CONFIG,
  DASHBOARD_RECEIVE_PRESET,
  ADMIN_RECEIVE_METADATA,
  ADMIN_FAIL_METADATA
} from '../../../new_actions'

const _resourceConfig = resourceConfig.resources.reduce((obj, item) => {
  const { config, ...data } = item
  obj[item.groupName] = data
  return obj
}, {})

const initialState = {
  ready: false,
  resourceConfig: {},
  metadata: {
    adsList: [],
    adsMap: {},
    brandsList: [],
    brandsMap: {},
    presets: []
  },
  config: {},
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_SET_CONFIG:
      const { ...config } = action.data
      const { groupName, appName } = config.main
      return {
        ...state,
        resourceConfig: {
          ..._resourceConfig[groupName],
          roles: resourceConfig.roles,
          subResourceRoles: resourceConfig.subResourceRoles,
          groupName,
          appName
        },
        config
      }

    case DASHBOARD_REQUEST_METADATA:
      return {
        ...state,
        ready: false,
        error: null
      }

    case DASHBOARD_RECEIVE_METADATA: {
      const { accessRights, ...data } = action.data
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...data
        },
        ready: true,
        error: null
      }
    }

    case ADMIN_RECEIVE_METADATA: {
      const { accessRights, ...data } = action.data
      return action.data.isAuto ? state : {
        ...state,
        metadata: {
          ...state.metadata,
          ...data
        },
        ready: true,
        error: null
      }
    }

    case DASHBOARD_RECEIVE_PRESET:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          presets: [
            ...state.metadata.presets.filter(preset => preset.is_standard),
            ...action.data
          ]
        }
      }
    case DASHBOARD_CLR_CONFIG:
      return {
        ...initialState
      }

    case DASHBOARD_FAIL_METADATA:
    case ADMIN_FAIL_METADATA:
      return {
        ...state,
        ready: false,
        error: action.data
      }
    default:
      return { ...state }
  }
}
