import template from '../../../../../../Template/config/components/scorecard/ScorecardView/ScorecardStatTesting'
export default {
  ...template,
  // labels: [
  //   {
  //     high: {
  //       color: '#7ED7A7',
  //       text: 'Significantly above norm'
  //     },
  //     low: {
  //       color: '#FF7F7F',
  //       text: 'Significantly below norm'
  //     }
  //   }
  // ],
  legendDetails: "Negative sentiments shaded in reverse. ** : Asked of Recallers and based to total."
}
