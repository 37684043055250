import {
  DASHBOARD_INIT,
  DASHBOARD_SELECT_SORT_METRIC,
  DASHBOARD_REQUEST_UPDATE_SORT,
  DASHBOARD_RECEIVE_SORT,
  DASHBOARD_FAIL_SORT,
  DASHBOARD_CLR_CONFIG
} from '../../../new_actions'

const initialState = {
  ready: true,
  uuid: null,
  sortOptions: [],
  sortData: {
    ad: [],
    norm: []
  },
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_INIT:
      const {
        config: {
          components: {
            selection: {
              setBuilder: { setBuilderToolbar }
            }
          }
        }
      } = action.data
      return {
        ...initialState,
        sortOptions: [...setBuilderToolbar.sortOptions]
      }

    case DASHBOARD_SELECT_SORT_METRIC:
      const { sortOptions } = state
      const selectedOption = action.data
      return {
        ...state,
        sortOptions: sortOptions.map(sortOption => {
          return {
            ...sortOption,
            selected: sortOption.value === selectedOption
          }
        })
      }

    case DASHBOARD_REQUEST_UPDATE_SORT:
      return {
        ...state,
        ready: false
      }

    case DASHBOARD_RECEIVE_SORT:
      const { type, metrics, uuid } = action.data
      const data = metrics.reduce((obj, ad) => {
        obj[ad.ad_code] = ad.metrics
        return obj
      }, {})
      return {
        ...state,
        ready: true,
        sortData: {
          ...state.sortData,
          [type]: {
            uuid,
            data
          }
        }
      }

    case DASHBOARD_FAIL_SORT:
      return {
        ...state,
        error: action.data
      }

    case DASHBOARD_CLR_CONFIG:
      return {
        ...initialState
      }

    default:
      return state
  }
}
