import AdminBar from './AdminBar'
import AdminTabs from './AdminTabs'
import ItemEditor from './ItemEditor'

export default Object.assign(
  theme => {
    return {
      
    }
  },
  {
    AdminBar,
    AdminTabs,
    ItemEditor
  }
)
