export default {
  slices: [
    {
      field: 'ad_categories',
      display: 'Event',
      map: {
        superbowl: {
          display: 'Super Bowl LV',
          sortOrder: 1
        },
        superbowl_56: {
          display: 'Super Bowl LVI',
          sortOrder: 2
        }
      }
    },
    {
      field: 'ad_type',
      display: 'Media Type',
      map: {
        tv: {
          display: 'TV',
          mediaType: 'mp4',
          sortOrder: 1
        }
      }
    },
  ]
}
