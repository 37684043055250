import ScorecardMainHeader from './ScorecardMainHeader'
import ScorecardMetricsLabels from './ScorecardMetricsLabels'
import ScorecardNormHeader from './ScorecardNormHeader'
import ScorecardNormValues from './ScorecardNormValues'
export default Object.assign(theme => {
  return {
      gridContainer: {
      }
  }
}, {
    ScorecardMainHeader,
    ScorecardMetricsLabels,
    ScorecardNormHeader,
    ScorecardNormValues
})
