import React, { PureComponent } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'

const styles = theme => ({
  control: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  disabled: {
    backgroundColor: theme.palette.grey[100],
  },
  menuItemRoot: {
    padding: 0,
  },
  menuText: {
    fontSize: 14,
  },
  icon: {
    color: 'red',
  },
  activeType: {
    '&:hover': {
      backgroundColor: `${theme.palette.listSelectBackground.default} !important`,
    }
  },
  selected: {
    backgroundColor: `${theme.palette.listSelectBackground.default} !important`,
  }
})

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 600,
      width: 225,
      padding: 0,
    },
  },
}

class DynamicFilterElement extends PureComponent {
  render() {
    const {
      classes,
      element: { label, options },
      update,
      selection,
    } = this.props
    const disabled = !options || options.length === 1
    const classnames = cn(classes.control, disabled ? classes.disabled : null)

    return (
      <Grid className={classnames} style={{ padding: 6 }} item xs>
        <FormControl disabled={disabled} fullWidth >
          <InputLabel
            style={{ fontSize: 14 }}
            htmlFor={`id-for-${label}-dynamic-filter`}
          >
            {label}
          </InputLabel>
          <Select
            value={selection.value}
            disabled={options.length < 2}
            onChange={e => update(e.target.value)}
            input={
              <Input disableUnderline id={`id-for-${label}-dynamic-filter`} />
            }
            renderValue={() =>
              selection.value === null ? '' : options[selection.value].label
            }
            MenuProps={MenuProps}
            style={
              selection.value === null && options.length
                ? {
                    color: '#ff8372',
                    borderBottom: '3px solid',
                    paddingBottom: 2,
                  }
                : {}
            }
          >
            {options.map(({ value, label }, index) => (
              <MenuItem
                key={`option-${value}`}
                value={index}
                style={{ paddingTop: 8, paddingBottom: 8 }}
                className={cn(index === selection.value ? classes.selected: null, classes.activeType)}
              >
                <ListItemText
                  classes={{
                    root: classes.menuItemRoot,
                    primary: classes.menuText,
                  }}
                  primary={label}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    )
  }
}

DynamicFilterElement.propTypes = {
  // label: PropTypes.string.isRequired,
  // filterId: PropTypes.string.isRequired,
  // options: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     // value: PropTypes.number.isRequired,
  //     label: PropTypes.string.isRequired,
  //     //selected: PropTypes.bool.isRequired,
  //   }),
  // ).isRequired,
  disabled: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

DynamicFilterElement.defaultProps = {
  disabled: false,
  multi: false,
}

export default withStyles(styles)(DynamicFilterElement)
