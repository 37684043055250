import PresetGridView from './PresetGridView'
import PresetListView from './PresetListView'

export default Object.assign(
  theme => {
    return {
      root: {
        flexGrow: 1,
        paddingTop: 25
      },
      paper: {
        padding: 20,
        marginTop: 25,
        marginBottom: 20
      },
      selector: {
        marginBottom: 25
      },
      boxShadow: {
        border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
        boxShadow: `0 1px 1px #E7E7E7`
      }
    }
  },
  { PresetGridView, PresetListView }
)
