export default Object.assign(
  theme => {
    return {}
  },
  {
    SearchAdCodes: theme => {
      return {
        container: {
          padding: 25
        },
        cell: {
          padding: 0,
          height: 450
        },
        textArea: {
          backgroundColor: theme.palette.background.paper
        },
        button: {
          marginRight: 10
        },
        table: {
          height: 450,
          overflowX: 'scroll',
          overflowY: 'scroll'
        },
        tableRow: {
          height: 40,
        },
        tableRowData: {
          height: 40,
          "&:hover": {
            backgroundColor: theme.palette.listSelectBackground.default
          }
        },
        tableCellCentered: {
          textAlign: 'center'
        },
        tableCellPadded: {
          textAlign: 'left',
          padding: '0px 25px'
        },
        invalid: {
          backgroundColor: 'rgba(255,255,0, 0.1)',
        },
        tableCell: {
          textAlign: 'left'
        },
        warningIcon: {
          fill: 'rgb(234,234,50)',
          width: 24,
          height: 24,
          marginBottom: -7,
          marginRight: 5
        },
        footer: {
          width: '100%',
          margin: 0,
          padding: '0px 5px 5px 10px',
          backgroundColor: theme.palette.background.paper
        },
        checkIcon: {
          color: theme.palette.grey[600]
        },
        errorIcon: {
          color: theme.palette.error.main
        }
      }
    }
  }
)
