export default {
  '***': {
    '***': [
      {
        "id": 100,
        "label": "Brand Image",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          105,
          110,
          115,
          120,
          125
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 105,
        "label": "Awards/Accolades",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Refers to any reference of awards or rankings, 3rd party or commissioned research based."
      },
      {
        "id": 110,
        "label": "Customer Service",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Refers to a primary focus on customer service"
      },
      {
        "id": 115,
        "label": "Co-Brand",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Single ad advertising for more than one brand ('Seamless + Taco Bell' or 'Marriott Chase credit card')"
      },
      {
        "id": 120,
        "label": "General Reputation",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Refers to any ad that focuses on a brand's general reputation, but does not mention any products"
      },
      {
        "id": 125,
        "label": "Sponsorship",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Sponsorship of an event, sport or project (such as a food bank)"
      },
      {
        "id": 200,
        "label": "Creative Type",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          205,
          210,
          215,
          220
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 205,
        "label": "Narrative",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad follows a story trajectory, even if short"
      },
      {
        "id": 210,
        "label": "Vignette",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad does not have a story, but rather many different scenes cut together"
      },
      {
        "id": 215,
        "label": "Animated",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "At least half of ad is cartoon/animation and not filmed"
      },
      {
        "id": 220,
        "label": "Graphics/Product Images Only",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad is a computer generated ad usually focusing on a product with few or no actual filmed scenes"
      },
      {
        "id": 300,
        "label": "Creative Style",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          305,
          310,
          315,
          320,
          325,
          330,
          335,
          340,
          345
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 305,
        "label": "Humorous",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "The intent of the ad is to make you laugh"
      },
      {
        "id": 310,
        "label": "Emotional",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "The intent of the ad is to elicit sad or heartwarming emotion"
      },
      {
        "id": 315,
        "label": "Spokesperson",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Includes both celebrity and non-celebrity spokespeople"
      },
      {
        "id": 320,
        "label": "Celebrity",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Includes any celebrity inclusion, whether or not that person is a spokesperson"
      },
      {
        "id": 325,
        "label": "Music (jingle)",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Refers to a consistent jingle associated with a brand, not just background music"
      },
      {
        "id": 330,
        "label": "Music (lyrics/song)",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Refers to an identifiable song - either lyrics or instrumental"
      },
      {
        "id": 335,
        "label": "Branding in intro (first 5 seconds)",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "The advertising brand is shown or spoken in the first 5 seconds"
      },
      {
        "id": 340,
        "label": "Voice over narration",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "An off-screen narrator is featured"
      },
      {
        "id": 345,
        "label": "Continuity",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Ad uses the same creative construct and/or established brand cues"
      },

      {
        "id": 400,
        "label": "Offers - Device",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          405,
          410
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 405,
        "label": "BOGO Phone",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": "Offers Buy-One-Get-One Free device"
      },
      {
        "id": 410,
        "label": "Free Phone (not tied to BOGO)",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": "Free phone for opening an account or adding a line"
      },
      {
        "id": 500,
        "label": "Offers - Freebies",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          505,
          510
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 505,
        "label": "Perks Bundled with Streaming (Netflix, Hulu, Disney+, etc.)",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": "Free streaming, even if for limited time, for customers"
      },
      {
        "id": 510,
        "label": "Cashback or Rebate",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": "Cashback or gift card incentive"
      },
      {
        "id": 600,
        "label": "Offers - Price Related",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          605,
          610
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 605,
        "label": "Bundling with home services",
        "selected": 0,
        "collapse": 0,
        "parent": 600,
        "children": [],
        "tooltip": "Mentions of bundling with non-wireless phone service"
      },
      {
        "id": 610,
        "label": "Multi-line pricing",
        "selected": 0,
        "collapse": 0,
        "parent": 600,
        "children": [],
        "tooltip": "Lower price per line if multiple lines"
      },
      {
        "id": 700,
        "label": "Other Wireless Ad Elements",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          705,
          710,
          715,
          720
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 705,
        "label": "Includes Spanish",
        "selected": 0,
        "collapse": 0,
        "parent": 700,
        "children": [],
        "tooltip": "Ad is in two languages"
      },
      {
        "id": 710,
        "label": "B2B",
        "selected": 0,
        "collapse": 0,
        "parent": 700,
        "children": [],
        "tooltip": "Business targeted"
      },
      {
        "id": 715,
        "label": "Mix-and-Match",
        "selected": 0,
        "collapse": 0,
        "parent": 700,
        "children": [],
        "tooltip": "Multiple lines can have different plans types - usually Data"
      },
      {
        "id": 720,
        "label": "No Contract",
        "selected": 0,
        "collapse": 0,
        "parent": 700,
        "children": [],
        "tooltip": "No Contract required for service, or special pricing"
      },
    ]
  }
}
