import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { Grid, Typography, TextField, Button } from '@material-ui/core'
import { generateUUID } from '../../../../../new_utils'

const Styled = withStyles(theme =>
  theme.components.selection.Search.SearchManual(theme)
)

class SampleSizeFilter extends React.PureComponent {

  handleChange = e => {
    const { updateFilter } = this.props
    updateFilter({
      uuid: generateUUID(),
      data: {
        sampleSize: Math.max(+e.target.value || 0, 0)
      }
    })
  }

  filter = ad => {
    const {
      range: { end_month }
    } = this.props
    const { data: { sampleSize } } = this.props.extraFilter
    return (
      ad.months
        .filter(d => d.month_code <= end_month)
        .reduce((sum, month) => sum + month.n, ad.rolledSampleSize || 0) >=
      sampleSize
    )
  }

  componentDidMount() {
    const { setFilter, extraFilter, adType, updateFilter } = this.props

    const disabled =
      extraFilter.ad_type &&
      !extraFilter.ad_type.includes(adType.field) &&
      !extraFilter.ad_type.includes('***')

    updateFilter({
      uuid: generateUUID(),
      disabled
    })
    setFilter(this.filter)
  }

  render() {
    const { classes, extraFilter, adType, update } = this.props

    const { data: { sampleSize } } = extraFilter

    const disabled =
      extraFilter.ad_type &&
      !extraFilter.ad_type.includes(adType.field) &&
      !extraFilter.ad_type.includes('***')

    return (
      <div className={classes.sampleSize}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography variant="subtitle1" color="inherit">
              {`Min Sample Size: ${sampleSize}`}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid xs={8} item>
                <TextField
                  fullWidth
                  placeholder="min sample size"
                  value={sampleSize || ''}
                  onChange={this.handleChange}
                  type="number"
                />
              </Grid>
              <Grid xs={4} item>
                <Button
                  fullWidth
                  className={classes.button}
                  color="inherit"
                  variant="contained"
                  size="small"
                  onClick={update}
                  disabled={disabled}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Styled(SampleSizeFilter)
