export default {
  '***': {
    '***': {
      displayFilters: null,
      staticFilters: [
        {
          label: 'Age',
          code: 'age',
          multi: true,
          options: [
            {
              label: '18-40',
              value: 1,
              selected: false
            },
            {
              label: '41-70',
              value: 2,
              selected: false
            }
          ]
        },
        {
          label: 'Gender',
          code: 'gender',
          multi: true,
          options: [
            {
              label: 'Male',
              value: 1,
              selected: false
            },
            {
              label: 'Female',
              value: 2,
              selected: false
            }
          ]
        },

        {
          label: 'Purchase',
          excludeFromVerbatims: true,
          code: 'purchase',
          multi: true,
          options: [
            {
              label: '0-12 Month Intender',
              value: 1,
              selected: false
            }
          ]
        },
        {
          label: 'Children <18',
          code: 'children',
          multi: true,
          options: [
            {
              label: 'With',
              value: 1,
              selected: false
            },
            {
              label: 'Without',
              value: 2,
              selected: false
            }
          ]
        },
        {
          label: 'Segment',
          code: 'seg',
          multi: true,
          options: [
            {
              label: 'Upper',
              value: 1,
              selected: false
            },
            {
              label: 'Lower',
              value: 2,
              selected: false
            }
          ]
        },
        {
          label: 'Residential',
          excludeFromVerbatims: true,
          code: 'residential',
          multi: true,
          options: [
            {
              label: 'More Urban',
              value: 1,
              selected: false
            },
            {
              label: 'More Rural',
              value: 2,
              selected: false
            }
          ]
        }
      ]
    }
  }
}