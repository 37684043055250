import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { HashRouter as Router, Route, withRouter } from 'react-router-dom'
import NavBar from './components/NavBar'
import AsyncWrapper from './components/AsyncWrapper'
import Scorecard from './components/ScorecardPage'
import ViewerDialog from './components/ViewerDialog'
import * as actions from './actions'
import FordLogos from './components/logos'
import ReactGA from 'react-ga'

import FordConfig from './configuration'
import {
  dashboardAdminInterfaceOpen,
  dashboardSetConfig,
  appSetError,
  appClrError
} from '../../new_actions'
import { UserMessage } from '../../new_components'
import Admin from '../../new_containers/admin'

const Styled = withStyles(theme => theme.components.auto(theme))

class FordUS extends Component {
  constructor(props) {
    super(props)
    const {
      match: { path }
    } = props

    const ford = path.replace('/', '')
    const logo = FordLogos[ford]
    const config = FordConfig[ford]
    this.state = {
      logo,
      config,
      ready: false,
      drawerOpen: false
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    const { config } = this.state

    dispatch(actions.appSetConfig(config)).then(() => {
      this.setState({ ready: true })
    })
    dispatch(dashboardSetConfig(this.props.config))
    dispatch(actions.fetchMetaData(config)).catch ( error => appSetError(error))
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch(actions.appClrConfig(null))
  }

  componentDidUpdate(prevProps, prevState) {
    const { user: { userType }, app: { config: { label } } } = this.props
    const { ready } = this.state
    if (prevState.ready !== ready) {
      ReactGA.event({
        category: label,
        action: `Generate Scorecard (${userType})`,
        label: userType
      })
    }
  }

  toggleDrawer = () => {
    this.setState(prevState => {
      return { drawerOpen: !prevState.drawerOpen }
    })
  }

  viewerHide = () => {
    const { dispatch } = this.props
    dispatch(actions.viewerHide(false))
  }

  openAdmin = () => {
    const {
      dispatch,
      config: {
        main: { baseRoute }
      },
      history
    } = this.props
    dispatch(dashboardAdminInterfaceOpen(true))
    history.push(`${baseRoute}/admin`)
  }

  clrError = () => {
    // const { dispatch, history, config: { main: {baseRoute, home}} } = this.props
    // history.push(`${baseRoute}${home || ''}`)
    const { dispatch, history, message } = this.props
    if (message.type === 'error') history.push('/')
    dispatch(appClrError())
  }

  render() {
    const {
      classes,
      app,
      user,
      viewer,
      message,
      match: { path },
      ads
    } = this.props
    const { logo,
        ready } = this.state
    if (!ready) return null
    return (
      <div className={classes.root}>
        <AsyncWrapper item={ads} size={200} thickness={1.5}>
          <Router>
            <div>
              <div className={classes.appFrame}>
                <NavBar
                  disableGutters
                  title={'Advertising Dashboard'}
                  logo={logo}
                  showMenuIcon={false}
                  root={app.config.routes.root}
                  openAdmin={this.openAdmin}
                  hasAdminViewAccess={user.hasAdminViewAccess}
                />
                <main className={classes.content}>
                  <Route exact path={`${path}/`} component={Scorecard} />
                  <Route path={`${path}/admin`} component={Admin} />
                </main>
              </div>
            </div>
          </Router>
          <UserMessage message={message} close={this.clrError} />
          {app.ready && viewer.open ? (
            <ViewerDialog viewer={viewer} handleClose={this.viewerHide} />
          ) : null}
        </AsyncWrapper>
      </div>
    )
  }
}

FordUS.propTypes = {
  classes: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired
}

const Connected = connect(state => {
  const { message } = state.app
  return {
    app: state.app,
    user: state.user,
    viewer: state.ford.viewer,
    ads: state.ford.ads,
    message
  }
})

export default withRouter(Connected(Styled(FordUS)))
