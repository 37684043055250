export default theme => {
    return {
        title: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            padding: '20px',
          },
          button: {
            backgroundColor: theme.palette.background.paper,
          },
    }
}