export default {
  slices: [
    {
      field: 'country',
      display: 'Country',
      map: {
        'us': {
          display: 'US',
          sortOrder: 1
        },
        'uk': {
          display: 'United Kingdom',
          sortOrder: 2
        },
        'fr': {
          display: 'France',
          sortOrder: 3
        },
        'jp': {
          display: 'Japan',
          sortOrder: 4
        },
      }
    },
    {
      field: 'ad_type',
      display: 'Ad Type',
      hidden: true,
      map: {
        'tv': {
          display: 'TV & OLV',
          sortOrder: 1
        },
        'online_video': {
          display: 'Online Video',
          sortOrder: 2
        }
      }
    }
  ]
}
