export {
  DASHBOARD_SET_CONFIG,
  DASHBOARD_CLR_CONFIG,
  dashboardSetConfig,
  dashboardClrConfig,
} from './config'

export {
  DASHBOARD_SELECT_PRESET,
  DASHBOARD_CLEAR_PRESET,
  DASHBOARD_CREATE_PRESET,
  dashboardSelectPreset,
  dashboardClearPreset,
  dashboardCreatePreset
} from './preset'

export {
  DASHBOARD_SELECT_METADATA_SLICE,  
  DASHBOARD_COMPLETE_METADATA_SLICE,  
  dashboardSelectMetadataSlice,
  dashboardCompleteMetadataSlice
} from './slice'

export {
  DASHBOARD_SELECT_SORT_METRIC,
  dashboardSelectSortMetric
} from './sort'

export  {
  DASHBOARD_UPDATE_STATIC_FILTER,
  DASHBOARD_CLR_STATIC_FILTER,
  dashboardUpdateStaticFilter,
  dashboardClrStaticFilter 
} from './filter'

export {
  DASHBOARD_SELECT_ADS_TAB,
  dashboardSelectAdsTab
} from './adsTabs'

export {
  // DASHBOARD_SEARCH_SET_MONTH_RANGE,
  DASHBOARD_SEARCH_TOGGLE_BRAND,
  DASHBOARD_SEARCH_SELECT_ALL_BRANDS,
  DASHBOARD_TOGGLE_AD_TAG,
  DASHBOARD_UPDATE_EXTRA_FILTER_DATA,
  DASHBOARD_SET_EXTRA_FILTER,
  // dashboardSearchSetMonthRange,
  dashboardSearchToggleBrand,
  dashboardSearchSelectAllBrands,
  dashboardToggleAdTag,
  dashboardUpdateExtraFilterData,
  dashboardSetExtraFilter
} from './search'

export {
  DASHBOARD_VIEWER_CLOSE,
  dashboardViewerOpen,
  dashboardViewerClose
} from './viewer'

export {
  DASHBOARD_TOGGLE_EXCLUDE_NON_NORM,
  dashboardToggleExcludeNonNorm,
} from './calcFlags'

export {
  DASHBOARD_ADMIN_INTERFACE_OPEN,
  DASHBOARD_ADMIN_INTERFACE_CLOSE,
  dashboardAdminInterfaceOpen,
  dashboardAdminInterfaceClose,
} from './admin'

const DASHBOARD_INIT='DASHBOARD_INIT'
const dashboardInit = data => {
  return {
    type: DASHBOARD_INIT,
    data
  }
}

export {
  DASHBOARD_QUICKVIEW_CLOSE,
  dashboardQuickviewClose
} from './quickview'

export {
  DASHBOARD_INIT,
  dashboardInit
}