import React from 'react'
import { Auth, Logger } from 'aws-amplify'
import AuthPiece from './AuthPiece'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import StyledContainer from './StyledContainer'

const styles = theme => ({
  formControl: {
    width: '100%',
    minWidth: 'unset',
    marginBottom: '10px',
  },
  submitButton: {
    width: '100%',
    minWidth: 'unset',
    margin: '30px 0px',
  },
})

new Logger('ConfirmSignIn')

class ConfirmSignIn extends AuthPiece {
  constructor(props) {
    super(props)

    this._validAuthStates = ['confirmSignIn']
    this.confirm = this.confirm.bind(this)
  }

  confirm() {
    const user = this.props.authData
    const { code } = this.inputs

    Auth.confirmSignIn(user, code)
      .then(() => {
        if (user.signInUserSession && user.signInUserSession.accessToken) {
          this.changeState('signedIn')
        } else {
          this.error('There was a problem logging in.')
        }
      })
      .catch(err => this.error(err))
  }

  showComponent(theme) {
    const { hide, classes } = this.props

    if (hide && hide.includes(ConfirmSignIn)) {
      return null
    }

    return (
      <StyledContainer message="Confirm Sign In">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="code-input">code sent to device</InputLabel>
          <Input
            id="code-input"
            key="code"
            name="code"
            onChange={this.handleInputChange}
          />
        </FormControl>
        <br />
        <Button
          variant="contained"
          color="secondary"
          className={classes.submitButton}
          onClick={this.confirm}
        >
          Confirm
        </Button>
        <br />
        <Button
          color="primary"
          style={{ marginLeft: 80 }}
          onClick={() => this.changeState('signIn')}
        >
          Back to Sign In
        </Button>
      </StyledContainer>
    )
  }
}

export default withStyles(styles)(ConfirmSignIn)
