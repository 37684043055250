export default theme => {
  return {
    container: {
      height: '100%'
    },
    h1: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.primary.dark,
      paddingTop: 5,
      paddingLeft: 10,
      marginBottom: 5,
      // marginTop: 5,
    },
    d1: {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 25
    },
    odd: {
    },
    even: {
    }
  }
}
