import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid as VGrid } from 'react-virtualized'
import scrollbarSize from 'dom-helpers/util/scrollbarSize'

const Styled = withStyles(theme => {
  return {
    ...theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardFixedColumn(
      theme
    ),
    gridLeft: {
      overflow: 'hidden !important'
    },
    gridHeader: {
      width: '100%',
      overflow: 'hidden !important'
    }
  }
})

class ScorecardFixedColumn extends React.PureComponent {
  renderTitle = ({ key, style }) => {
    const { title, classes, ...params } = this.props
    return (
      <div key={key} style={style}>
        {React.createElement(title, { ...params })}
      </div>
    )
  }

  renderRows = ({ rowIndex, key, style }) => {
    const { classes, rows, ...params } = this.props
    return (
      <div className={classes.metricsLabels} key={key} style={style}>
        {React.createElement(rows, { ...params, rowIndex })}
      </div>
    )
  }

  render() {
    const {
      classes,
      config: { rowCount, headerHeight, columnWidth, scrollTop },
      leftOffset,
      viewHeight,
      gridData
    } = this.props
    return (
      <Fragment>
        <div
          style={{
            position: 'absolute',
            left: leftOffset || 0,
            top: 0
          }}
          className={classes.gridContainer}
        >
          <VGrid
            className={classes.gridHeader}
            cellRenderer={this.renderTitle}
            rowCount={1}
            columnCount={1}
            height={headerHeight}
            width={columnWidth}
            rowHeight={headerHeight}
            columnWidth={columnWidth}
            gridData={gridData}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            zIndex: 1000,
            left: leftOffset || 0,
            top: headerHeight
          }}
        >
          <VGrid
            className={classes.gridLeft}
            cellRenderer={this.renderRows}
            rowCount={rowCount}
            columnCount={1}
            height={viewHeight - headerHeight - scrollbarSize()}
            width={columnWidth}
            rowHeight={({ index }) => gridData.rowHeights[index].rowHeight}
            columnWidth={columnWidth}
            scrollTop={scrollTop}
            gridData={gridData}
          />
        </div>
      </Fragment>
    )
  }
}

export default Styled(ScorecardFixedColumn)
