import axios from 'axios'
import { appSetError } from '../app'
import { generateUUID } from '../../new_utils'

const adminRequest = (type, data) => {
  return {
    type: `ADMIN_REQUEST_${type.replace(/\//g, '_')}`.toUpperCase(),
    data
  }
}

const adminReceive = (type, data) => {
  return {
    type: `ADMIN_RECEIVE_${type.replace(/\//g, '_')}`.toUpperCase(),
    data
  }
}

const adminFail = (type, error) => {
  return {
    type: `ADMIN_FAIL_${type.replace(/\//g, '_')}`.toUpperCase(),
    data: error
  }
}

const adminFetch = (type, _params = {}) => {
  return dispatch => {
    const { id, ...params } = { ..._params, cacheTag: generateUUID() }
    dispatch(adminRequest(type, params))

    let baseURL = axios.defaults.baseURL.replace('/api/dashboard', '/api/admin')
    baseURL = baseURL.replace('/api/auto', '/api/admin')

    return axios
      .get(`/${type}${typeof _params.id === 'undefined' ? '' : `/${_params.id}`}`,
        {
            params,
            baseURL,
            headers: { 'Cache-Control': 'no-store' }
        })
      .then(response => { 
        dispatch(adminReceive(type, response.data))
        return response.data
      })
      .catch(error => {
        dispatch(
          adminFail(type, error.request ? error.request.response : error)
        )
        dispatch(appSetError(error.request.response))
      })
  }
}

const adminRequestUpdate = (type, data) => {
  return {
    type: `ADMIN_REQUEST_UPDATE_${type.replace(/\//g, '_')}`.toUpperCase(),
    data
  }
}

const adminUpdate = (type, _params = {}) => {

  return dispatch => {
    const { id, ...params } = _params
    dispatch(adminRequestUpdate(type, params))
    let baseURL = axios.defaults.baseURL.replace('/api/dashboard', '/api/admin')
    baseURL = baseURL.replace('/api/auto', '/api/admin')

    return axios
      .post(`/${type}${typeof _params.id === 'undefined' ? '' : `/${_params.id}`}`,
        { params },
        { baseURL }
      )
      .then(response => {
        dispatch(adminReceive(type, response.data))
        return response.data
      })
      .catch(error => {
        dispatch(adminFail(type, error.request.response))
        dispatch(appSetError(error.request.response))
      })
  }
}

const adminRequestDelete = (type, data) => {
  return {
    type: `ADMIN_DELETE_${type.replace(/\//g, '_')}`.toUpperCase(),
    data
  }
}

const adminReceiveDelete = (type, data) => {
  return {
    type: `ADMIN_RECEIVE_DELETE_${type.replace(/\//g, '_')}`.toUpperCase(),
    data
  }
}

const adminFailDelete = (type, error) => {
  return {
    type: `ADMIN_FAIL_DELETE_${type.replace(/\//g, '_')}`.toUpperCase(),
    data: error
  }
}

const adminDelete = (type, _params = {}) => {
  return dispatch => {
    const { id, ...params } = _params
    dispatch(adminRequestDelete(type, params))
    let baseURL = axios.defaults.baseURL.replace('/api/dashboard', '/api/admin')
    baseURL = baseURL.replace('/api/auto', '/api/admin')

    return axios
      .delete(`/${type}${typeof _params.id === 'undefined' ? '' : `/${_params.id}`}`,
        {
          params,
          baseURL
        }
      )
      .then(response => {
        dispatch(adminReceiveDelete(type, response.data))
        return response.data
      })
      .catch(error => dispatch(adminFailDelete(type, error)))
  }
}

export {
  adminFetch,
  adminUpdate,
  adminDelete
}
