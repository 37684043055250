import { format } from 'd3-format'

const percent = n => format(`.${n}%`)
const dollars = (n, delim = ',') => format(`$${delim ? delim : ''}.${n}f`)
const comma = n => format(`,.${n}f`)
const fixed = n => format(`.${n}f`)
const integer = () => format(`.0`)

const string = n => s => {
  return s? s.toString().slice(0, n || s.toString().length) : ''
}

const date = (d, f) => {
  const s = f.match('/') || f.match('-')
  if (!s) return null
  const t = new Date(d)
  return f
    .split(s[0])
    .map(field => {
      switch (field) {
        case 'yy':
          return t.getYear() - 100
        case 'yyyy':
          return t.getFullYear()
        case 'mm':
          return t.getMonth() < 9 ? `0${t.getMonth() + 1}` : t.getMonth() + 1
        case 'dd':
          return t.getDate() < 9 ? `0${t.getDate()}` : t.getDate()
        default:
          return '??'
      }
    })
    .join(s[0])
}

const getFormat = f => {
  if (!f) return () => { }
  const [formatType, precision] = f.split(',')
  let formatter
  switch (formatType) {
    case '%':
      formatter = percent(precision || 0)
      break
    case '.':
    case 'idx':
      formatter = fixed(precision || 0)
      break
    case '$':
      formatter = dollars(precision || 0)
      break
    case '$x':
      formatter = dollars(precision || 0, null)
      break
    case 's':
      formatter = string(precision)
      break
    default:
      formatter = () => { }
  }
  return {
    formatter,
    formatType
  }
}

const dataFormatToCellFormat = dataFormat => {
  const f = dataFormat.split(',')
  const p = parseInt(f[1],10)

  let precision = p ? '.' : ''

  for (let i = 0; i < p; i++) {
    precision += '0'
  }

  switch (f[0]) {
    case '.':
      return `0${precision}`
    case '%':
      return `0${precision}%`
    case 'idx':
      return '#'
    case '$':
      return '$#,###'
    default:
      return '#,###'
  }
}

export default {
  percent,
  dollars,
  comma,
  fixed,
  integer,
  date,
  getFormat,
  dataFormatToCellFormat
}
