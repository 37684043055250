import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MetadataSliceGridView from './MetadataSliceGridView'
import MetadataSliceListView from './MetadataSliceListView'

const Styled = withStyles(theme =>
  theme.components.selection.MetadataSlice(theme)
)

class MetadataSlice extends PureComponent {

  render() {

    const { classes, slice: { display, variant, values}, select, selection } = this.props

    const SliceView = variant === 'multigroup' ? MetadataSliceListView : MetadataSliceGridView
    return (
      <Grid container spacing={2} alignItems="center" justify="center">
        <Grid item>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            align="center"
            className={classes.title}
          >
            Select {display}
          </Typography>
        </Grid>
        <SliceView select={select} values={values} selection={selection}/>
      </Grid>
    )
  }
}


export default Styled(MetadataSlice)
