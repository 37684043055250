import { setBuilderToolbar } from '../../../../../Template/config/components/selection/SetBuilder'

export default {
  ...setBuilderToolbar,
  enableExcludeNonNormFilters: {
    ad: false,
    norm: true
  },
  exportButtons: {
    ad: {
      data: true,
      media: true
    },
    norm: {
      data: true,
      media: true
    }
  },
  sortOptions: [
    {
      "value": -1,
      "label": "Alpha Order by Brand",
      "field": "brand",
      "selected": false
    },
    {
      "value": 3,
      "label": "Recall",
      "selected": false
    },
    {
      "value": 7,
      "label": "Makes Me Want to Start/Expand Relationship",
      "selected": false
    },
    {
      "value": 4,
      "label": "Lift to Brand Impression",
      "selected": false
    },
    {
      "value": 5,
      "label": "Lift to Brand Consideration",
      "selected": true
    },
    {
      "value": 10,
      "label": "Portrays a Company I Want to Be Associated with",
      "selected": false
    },
    {
      "value": 75,
      "label": "Tells Me Something New about the Company",
      "selected": false
    },
    {
      "value": 34,
      "label": "Likeability",
      "selected": false
    }
  ]
}
