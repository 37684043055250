import templateScorecardExport from  '../../../../../../Template/config/components/scorecard/ScorecardView/ScorecardExport'
export default {
  ...templateScorecardExport,
  columnWidths: {
    metricsLabels: 35,
    norm: 10,
    metrics: 15
  },
  columnFormats: {
    norm: {
      fill: '#eeeeee'
    }
  },
  defaultAlignment: 'center',
  defaultRowHeight: 15,
  defaultFontSize: 10,
  styles: {
    h0: {
      type: 'header',
      height: 15,
      format: {
        fill: '#eeeeee',
        color: '#002C59',
        font: {
          name: 'Arial',
          size: 12,
          bold: true
        }
      }
    },
    d0: {
      type: 'metric',
      height: 15,
      format: {
        border: {
          style: 'thin',
          color: '#eeeeee'
        }
      },
      font: {
        name: 'Arial'
      }
    },
    eol: {
      type: 'header',
      height: 20
    }
  },

  sheetHeaders: [
    {
      field: 'ad_code',
      rowHeight: 25,
      metricsLabel: 'Ad Code',
      alignment: 'center',
      font: {
        bold: true,
        size: 12
      }
    },
    {
      field: 'title',
      metricsLabel: 'Title',
      alignment: 'center'
    },
    {
      field: 'brand',
      metricsLabel: 'Brand',
      alignment: 'center',
    },
    {
      field: 'thumbnail',
      rowHeight: 50,
      normLabel: 'NORM',
      alignment: 'center',
      font: {
        bold: true,
        size: 12
      }
    },
    {
      field: 'last_month_tested',
      metricsLabel: 'Last Month Tested'
    },
    {
      field: 'length',
      metricsLabel: 'Ad Length'
    },
    {
      field: 'n',
      metricsLabel: 'Sample Size',
      format: '#,0'
    },
    {
      field: 'spend_ltd',
      metricsLabel: 'LTD Spend',
      format: '"$"#,0'
    },
    {
      field: 'days_in_market',
      metricsLabel: 'Days in Market',
      format: '#,0'
    },
    {
      field: 'recall_efficiency',
      metricsLabel: 'Recall Efficiency',
      format: '"$"#,0'
    }
  ]
}
