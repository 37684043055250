export default {
  spendField: 'spend_ltd',
  interpolateMonths: true,
  trendMetrics: [
    {
      metric: 3,
      field: 'consideration',
      label: 'Lift to Consideration',
      format: '.0%',
      hasSpendData: true,
      scale: [0, 0.8]
    },
    {
      metric: 2,
      field: 'recall',
      label: 'Branded Recall (Avg. Recall)',
      format: '.0%',
      hasSpendData: true,
      scale: [0, 0.8]
    }
  ]
}
