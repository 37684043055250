import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import cn from 'classnames'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardFixedColumn.ScorecardMainHeader(theme)
)

export default Styled(props => {
  const {
    classes,
    collapsed,
    gridData: {
      normHeaders: { header_metrics }
    }
  } = props
  return (
    <Grid container direction="column" justify="center" alignItems="flex-start" className={classes.container}>
      <Grid item className={cn(classes.headerGrid, classes.top)}>
        <Typography noWrap variant="body2">
          Ad Code
        </Typography>
      </Grid>
      <Grid item className={cn(classes.headerGrid)}>
        <Typography noWrap variant="body2">
          Title
        </Typography>
      </Grid>
      {collapsed ? null : (
        <Fragment>
          <Grid className={classes.headerGrid} item>
            <Typography noWrap variant="body2">
              Ad Length
            </Typography>
          </Grid>
          <Grid item className={classes.headerGrid}>
            <Typography noWrap variant="body2">
              First Air Date
            </Typography>
          </Grid>
          <Grid item className={classes.headerGrid}>
            <Typography noWrap variant="body2">
              Product
            </Typography>
          </Grid>
          <Grid item className={classes.headerGrid}>
            <Typography noWrap variant="body2">
              Sample Size
            </Typography>
          </Grid>
          <Grid item className={classes.headerGrid}>
            <Typography noWrap variant="body2">
              LTD Spend
            </Typography>
          </Grid>
          <Grid item className={classes.headerGrid}>
            <Typography noWrap variant="body2">
              Occurrences
            </Typography>
          </Grid>
        </Fragment>
      )}
      {header_metrics.map(header_metric => (
        <Grid item className={cn(classes.headerGrid)} key={header_metric.label}>
          <Typography noWrap variant="body2" align="center" className={classes.headerMetric}>
            {header_metric.label}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
})
