import { generateUUID } from '../../../new_utils'
import { initStaticFilters, selectStaticFilter, updateStaticFilter } from './utils'
import {
  APP_SET_AUTO_CONFIG,
  FORD_UPDATE_STATIC_FILTER,
  FORD_UPDATE_DYNAMIC_FILTER,
  FORD_UPDATED_FILTER,
  FORD_META_DATA_REQUEST,
  FORD_META_DATA_RECEIVE,
  FORD_META_DATA_FAILURE,
  APP_CLR_CONFIG
} from '../actions'

const initialState = {
  uuid: '',
  hasFetched: false,
  isFetching: false,
  hasErrored: false,
  errMessage: '',
  stale: true,
  query: {},
  flags: {},
  staticFiltersStructure: {},
  staticFilters: [],
  staticFilterQuery: null,
  dynamicFiltersStructure: {},
  dynamicFilters: {
    filters: [],
    selections: []
  }
}

function getDynamicFilters(struct) {

  function getFilterStructure(filters, obj) {
    const { label, field, data } = { ...obj }
    filters.push({
      label,
      field,
      options: data.map(item => {
        return { value: item.value, label: item.label }
      })
    })
    return obj.data[0].next ? getFilterStructure(filters, obj.data[0].next) : filters
  }
  const filters = getFilterStructure([], struct)

  return {
    filters,
    selections: filters.map(f => {
      return { field: f.field, value: 0 }
    })
  }
}

function updateDynamicFilters(state, selections) {
  const { dynamicFiltersStructure, dynamicFilters } = state

  let _dynamicFilters = { filters: [], selections: [] }

  let { data } = dynamicFiltersStructure

  selections.forEach((selection, index) => {
    const { label, field } = dynamicFilters.filters[index]
    selection.value =
      selection.value === null && data.length === 1 ? 0 : selection.value
    const options = data.map(item => {
      return { value: item.value, label: item.label }
    })
    _dynamicFilters.selections[index] = selection
    _dynamicFilters.filters[index] = {
      label,
      field,
      options
    }
    data =
      selection.value === null || !data[selection.value].next
        ? []
        : data[selection.value].next.data
  })

  return _dynamicFilters
}

export default function filterReducer(state = initialState, action) {
  switch (action.type) {
    case FORD_META_DATA_REQUEST:
      return {
        ...state,
        isFetching: true
      }

    case FORD_META_DATA_FAILURE:
      return {
        ...initialState,
        isFetching: false,
        hasErrored: true,
        errMessage: action.data
      }

    case FORD_META_DATA_RECEIVE:
      const dynamicFilters = getDynamicFilters(action.data.dynamicFilters)
      return {
        ...state,
        dynamicFiltersStructure: action.data.dynamicFilters,
        dynamicFilters,
        flags: action.data.flags,
        hasFetched: true
      }

    case FORD_UPDATE_DYNAMIC_FILTER:
      const selections = action.data
      return {
        ...state,
        staticFilterQuery: null,
        dynamicFilters: updateDynamicFilters(state, selections),
        staticFilters: selectStaticFilter(state),
        stale: selections.some(selection => selection.value === null)
      }

    case FORD_UPDATE_STATIC_FILTER:
      const { staticFilterQuery, staticFilters } = updateStaticFilter(
        state,
        action.data
      )

      return {
        ...state,
        uuid: generateUUID(),
        staticFilterQuery,
        staticFilters
      }

    case FORD_UPDATED_FILTER:
      return { ...state, stale: false }

    case APP_SET_AUTO_CONFIG:
      return {
        ...initialState,
        uuid: generateUUID(),
        staticFiltersStructure: { ...action.data.staticFilters },
        staticFilters: initStaticFilters(action.data.staticFilters)
      }

    case APP_CLR_CONFIG:
      return {
        ...initialState
      }
    default:
      return state
  }
}
