export default theme => {
  return {
    container: {
      height: '100%',
    },
    h0: {
      variant: 'subtitle1',
      height: '100%',
      backgroundColor: theme.palette.grey[200]
    },
    d0: {
      marginTop: 5,
      marginBottom: 5,
      paddingLeft: 5,
      paddingRight: 5
    },
    metricsValuesText: {
      color: theme.palette.text.primary
    },
    metricsValueInverseText: {
      color: '#ffffff'
    },
    stat: {
      marginRight: 5,
      marginLeft: 5,
      borderRadius: 8
    }
  }
}
