import templateConfig from '../../Template/config'

import main from './main'
import metadata from './metadata'
import components from './components'
import sidebar from './sidebar'


const config = {
  ...templateConfig,
  hasTrendchart: false,
  hasEditableNorm: false,
  main,
  sidebar,
  metadata,
  components
}

export {config}
export default config
