export default Object.assign(
  theme => {
    return {
      backgroundColor: theme.palette.grey[50],
      titleFontSize: 17,
      titleFontStyle: 'bold',
      titleColor: theme.palette.grey[600],
      xOffset: 10,
      mainMetricHeightRatio: 0.125,
      mainMetricWidthRatio: 0.65,
      titleOffset: 10,
      mainMetricOffset: 45,
      subMetricsOffset: 20
    }
  },
  {
    MainMetric: theme => {
      return {
        yOffset: 50,
        metricWidthRatio: 0.75,
        metricHeight: 30,
        backgroundColor: theme.palette.grey[300],
        noStatColor: theme.palette.grey[400],
        lineColor: theme.palette.grey[400],
        strokeWidth: 2,
        mainMetricLabelFontSize: 11,
        mainMetricLabelFontStyle: 'bold',
        mainMetricColorSaturationPercent: 0,
        mainMetricColorLightenPercent: 0,
        mainMetricColorValuePercent: 0,
        metricFontSize: 20,
        metricFontStyle: 'bold',
        normLineColor: theme.palette.grey[600],
        normLineStrokeWidth: 2,
        normFontSize: 11,
        normFontStyle: 'italic',
        normColor: theme.palette.grey[600]
      }
    },
    SubMetric: theme => {
      return {
        yOffset: 70,
        lineSpacing: 40,
        labelFontSize: 11,
        labelFontStyle: 'bold',
        labelColor: theme.palette.grey[600],
        normLineColor: theme.palette.grey[400],
        noStatColor: theme.palette.grey[300],
        normLineStrokeWidth: 2,
        normLineDash: [5, 5],
        metricFontSize: 11,
        metricFontStyle: 'bold',
        metricColor: theme.palette.grey[600]
      }
    }
  }
)
