import React, { Component } from 'react'
import { HashRouter as Router, Route } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { fetchUserResourcesIfNeeded } from '../../new_actions/user'
import Home from '../Home'
import Apps from '../configuration'
import ReactGA from 'react-ga'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  progress: {
    margin: theme.spacing( 2 )
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '60px'
  },
  rounded: {
    borderRadius: '10px'
  },
  header: {
    textAlign: 'center',
    marginBottom: '10px'
  }
})

class App extends Component {
  componentDidMount() {
    const { dispatch } = this.props

    dispatch(fetchUserResourcesIfNeeded())
    // dispatch(dashboardFetch('resources'))
  }

  componentDidUpdate(prevProps) {
    const { user: { ready, userType, username }  } = this.props

    if (prevProps.user.ready !== ready) {
      // TODO: Temp clooge. 
      const gaID = window.location.href.match('cloud.phoenixmi.com') ? 'UA-207850-12' : 'UA-207850-13'
      ReactGA.initialize(gaID, {
        gaOptions: {
          userId: username
        }
      })
      ReactGA.event({
        category: `Access`,
        action: `Login (${userType})`,
        label: userType
      })
    }
  }
 
  render() {
    const { app, user, classes } = this.props

    if (app.ready === false && user.hasErrored === true) {
      return (
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <div className={classes.container}>
                  <div className={classes.header}>
                    <Typography variant="body2" color="primary" noWrap>
                      Application Portal
                    </Typography>
                    <br />
                    <Typography variant="h6" color="primary" noWrap>
                      Whoops! An error has occured.
                    </Typography>
                    <Typography variant="body2" color="error">
                      {user.errMessage}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    if (app.ready === false && user.isFetching === true) {
      return (
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <div className={classes.container}>
                  <div className={classes.header}>
                    <Typography variant="body2" color="textPrimary" noWrap>
                      Application Portal
                    </Typography>
                    <br />
                    <CircularProgress className={classes.progress} size={100} color="inherit" />
                    <Typography variant="body2" color="textPrimary" noWrap>
                      Fetching user information...
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    return (
      <Router>
        <div>
          <Route exact path="/" component={Home} />
          {Apps.map(app => {
            return (
              <Route key={app.name} path={app.path} component={app.component} />
            )
          })}
        </div>
      </Router>
    )
  }
}

const mapStateToProps = state => {
  return { user: state.user, app: state.app }
}

export default connect(mapStateToProps)(withStyles(styles)(App))
