import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { timer } from 'd3-timer'
import React, { Component } from 'react'
import cn from 'classnames'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import ReactGA from 'react-ga'

import Scorecard from '../../components/ScorecardPage/Scorecard'
import ScorecardHeader from '../../components/ScorecardPage/ScorecardHeader'
import ScorecardFilters from '../../components/ScorecardPage/ScorecardFilters'
import AsyncWrapper from '../AsyncWrapper'
import ScorecardLegend from '../../components/ScorecardPage/ScorecardLegend'
import * as actions from '../../actions'
import * as selectors from '../../selectors'
import BulkQueryDialog from './ScorecardFilters/BulkQueryDialog'
//import config from '../configuration/settings.json'

const delay = 1500
const luxury = 0

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingBottom: 50
  },
  paper: {
    padding: 0,
    border: `1px solid ${theme.palette.background.default}`,
    boxShadow: `0 1px 1px rgba(0,0,0,0.14)`
  },
  fetchBar: {
    height: 2,
    backgroundColor: theme.palette.secondary.main
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '60px'
  },
  header: {
    textAlign: 'center',
    marginBottom: '10px'
  },
  drawerRight: {
    margin: 10,
    padding: 5,
    textAlign: 'right'
  },
  drawerLeft: {
    margin: 10,
    padding: 5,
    textAlign: 'left'
  },
  drawerButton: {
    margin: '0px 10px'
  },
  stale: {
    opacity: 0.35
  }
})

class ScorecardPage extends Component {
  state = {
    scorecardHeight: window.innerHeight - 540,
    headerHeight: 265,
    showHeader: true,
    showLegend: false,
    fetchingIn: 0,
    detailOpen: false,
    detailTab: 'trend',
    detailAdcode: null,
    bulkFilterDialogOpen: false
  }

  filterTimer = null

  toggleLegend = () => {
    const showLegend = !this.state.showLegend
    const legendHeightChange = showLegend ? 20 : 0
    const headerHeightChange = this.state.showHeader ? 0 : 137
    this.setState(prevState => ({
      showLegend,
      scorecardHeight:
        window.innerHeight - 540 - legendHeightChange + headerHeightChange
    }))
  }

  toggleHeader = () => {
    const showHeader = !this.state.showHeader
    showHeader ? this.setState({ headerHeight: 265 }) : this.setState({ headerHeight: 133 })
    const headerHeightChange = showHeader ? 0 : 137
    const legendHeightChange = this.state.showLegend ? 20 : 0

    this.setState({
      showHeader,
      scorecardHeight: window.innerHeight - 540 - legendHeightChange + headerHeightChange
    })
  }

  updateHeight = () => {
    const headerHeightChange = this.state.showHeader ? 0 : 137
    const legendHeightChange = this.state.showLegend ? 20 : 0

    this.setState({
      scorecardHeight: window.innerHeight - 540 - legendHeightChange + headerHeightChange
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateHeight)
    this.updateScorecard(this.props)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight)
    if (this.filterTimer) {
      this.filterTimer.stop()
    }
  }

  componentWillReceiveProps(nextProps) {
    const { filter: { uuid } } = nextProps

    const previousSelections = this.props.dynamicFilters.selections
    const nextSelections = nextProps.dynamicFilters.selections
    const finalIndex = nextSelections.length - 1

    if (
      nextSelections[finalIndex].value !== null &&
      (nextSelections.some((selection, index) => {
        return selection.value !== previousSelections[index].value
      }) ||
        uuid !== this.props.filter.uuid)
    ) {
      this.setState({ fetchingIn: 100 })

      if (this.filterTimer) {
        this.filterTimer.restart(elapsed => {
          if (elapsed > delay) {
            this.setState({ fetchingIn: 0 })
            this.filterTimer.stop()
            this.updateScorecard(nextProps)
          } else {
            this.setState({ fetchingIn: ((delay - elapsed) / delay) * 100 })
          }
        })
      } else {
        this.filterTimer = timer(elapsed => {
          if (elapsed > delay) {
            this.setState({ fetchingIn: 0 })
            this.filterTimer.stop()
            this.updateScorecard(nextProps)
          } else {
            this.setState({ fetchingIn: ((delay - elapsed) / delay) * 100 })
          }
        })
      }
    }
  }

  toggleDrawer = () => {
    this.setState(prevState => {
      return { drawerOpen: !prevState.drawerOpen }
    })
  }

  updateDynamicFilter = selections => {
    const { dispatch } = this.props
    dispatch(actions.updateDynamicFilter(selections))
  }

  updateDisplayFilter = value => {
    const { dispatch } = this.props
    dispatch(actions.updateDisplayFilter(value))
  }

  updateStaticFilter = value => {
    const { dispatch } = this.props
    dispatch(actions.updateStaticFilter(value))
  }

  toggleBrands = (name, value, multi) => {
    const { dispatch } = this.props
    dispatch(actions.updateScorecardBrands(value, luxury))
  }

  updateSortOrder = e => {
    const {
      dispatch,
      scorecard: { sortParams }
    } = this.props
    dispatch(
      actions.updateScorecardSort({
        sortOrder: sortParams.sortOrder === 1 ? -1 : 1
      })
    )
  }

  updateGroupLeft = e => {
    const {
      dispatch,
      scorecard: { sortParams }
    } = this.props

    dispatch(
      actions.updateScorecardSort({
        groupLeft: sortParams.groupLeft ? 0 : 1
      })
    )
  }

  updateSortMetric = metric => {
    const { dispatch } = this.props

    dispatch(
      actions.updateScorecardSort({
        sortMetric: metric
      })
    )

    window.resizeBy(100, 100)
  }

  openBulkQuery = state => {
    const { dispatch } = this.props
    dispatch(actions.openBulkQuery(state))
  }

  generateBulkQuery = generate => {
    const { dispatch, bulkQuery: { slicerMap, slicerList, staticFilterQuery, displayFiltersSlicer } } = this.props

    if (generate) {
      dispatch(actions.fetchBulkQueryData({ slicerMap, slicerList, staticFilterQuery, displayFiltersSlicer }))
    }

    this.openBulkQuery(false)
  }

  bulkQueryDone = () => {
    const { dispatch, userType, app: { config: { label }} }  = this.props

    dispatch( actions.bulkQueryDone())
    ReactGA.event({
      category: label,
      action: `Bulk Query (${userType})`,
      label: userType
    })
  }

  updateBulkQuery = ({ filterIndex, value }) => {
    const { dispatch } = this.props
    dispatch(actions.updateBulkQueryFilter({ filterIndex, value }))
  }

  updateBulkQueryStaticFilters = value => {
    const { dispatch } = this.props
    dispatch(actions.updateBulkQueryStaticFilter(value))
  }

  updateBulkQueryDisplayFilters = value => {
    const { dispatch } = this.props
    dispatch(actions.updateBulkQueryDisplayFilter(value))
  }

  setBusySession = value => {
    const { dispatch } = this.props
    dispatch(actions.setBusySession(value))
  }

  updateScorecard = props => {
    const {
      dispatch,
      config,
      dynamicFilters,
      filter: { staticFilterQuery }
    } = props

    dispatch(
      actions.fetchScorecardIfNeeded({
        dynamicFilters,
        displayFilters: props.displayFilters.map(filter => filter.code),
        staticFilterQuery,
        config
      })
    )
  }

  viewerOpen = (adCode, monthCode, title) => {
    const { dispatch, config } = this.props
    dispatch(actions.fetchViewerLinkIfNeeded(adCode, monthCode, title, config))
  }

  render() {
    const {
      classes,
      scorecard: {
        filter,
        main,
        norm,
        sortParams,
        data,
        phase,
        selections,
        finalSelections,
        busySession
      },
      dynamicFilters,
      bulkQuery,
      displayFilters,
      staticFilters,
      config,
      baseEndpoint,
      stale,
      hasAdminViewAccess,
      isPrivilegedUser
    } = this.props
    const { fetchingIn, showLegend, headerHeight, scorecardHeight } = this.state

    const { groupName, label, stats, thresholds } = config

    const mediaType = selections['ad_type']
    const monthText = selections['month_code']
    const monthFilterIndex = dynamicFilters.selections.findIndex( selection => selection.field === 'month_code')
    const monthCode = dynamicFilters.filters[monthFilterIndex].options[ dynamicFilters.selections[monthFilterIndex].value ].value
    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          className={classes.grid}
          alignItems="center"
          justify="center"
        >
          
          <Grid item xs={12}>
            <div style={{ padding: '0px 30px' }}>
              <ScorecardHeader
                scorecard={this.props.scorecard}
                stats={stats}
                thresholds={thresholds}
                baseEndpoint={baseEndpoint}
                data={{ mediaType, monthText, phase }}
                groupName={groupName}
                label={label}
                monthText={monthText}
                monthCode={monthCode}
                staticFile={config.staticFile}
                openBulkQuery={this.openBulkQuery}
                setBusySession={this.setBusySession}
                busySession={busySession}
                bulkQueryBusy={bulkQuery.isFetching}
                hasAdminViewAccess={hasAdminViewAccess}
                isPrivilegedUser={isPrivilegedUser}
              />
              <div
                className={classes.fetchBar}
                style={{ width: `${fetchingIn}%` }}
              />
              <ScorecardFilters
                filter={filter}
                dynamicFilters={dynamicFilters}
                staticFilters={staticFilters}
                displayFilters={displayFilters}
                fetchingIn={fetchingIn}
                sortParams={sortParams}
                updateSortOrder={this.updateSortOrder}
                updateGroupLeft={this.updateGroupLeft}
                updateSortMetric={this.updateSortMetric}
                updateDynamicFilters={this.updateDynamicFilter}
                updateDisplayFilters={this.updateDisplayFilter}
                updateStaticFilters={this.updateStaticFilter}
                finalSelections={finalSelections}
                config={config}
              />
              <AsyncWrapper item={[main]} size={200} thickness={1.5}>
                <Paper className={classes.paper} elevation={0}>
                  {main.hasFetched ? (
                    <div className={cn(stale ? classes.stale : null)}>
                      <Scorecard
                        gridData={data}
                        viewerOpen={this.viewerOpen}
                        monthCode={monthCode}
                        sortParams={sortParams}
                        data={{ mediaType, monthText, phase }}
                        normLabel={norm.normLabel}
                        height={scorecardHeight}
                        headerHeight={headerHeight}
                        clickHeader={this.toggleHeader}
                      />
                    </div>
                  ) : null}
                </Paper>
              </AsyncWrapper>
            </div>
          </Grid>
          <Grid item xs={12}>
            <BulkQueryDialog
              open={bulkQuery.open}
              generate={this.generateBulkQuery}
              bulkQuery={bulkQuery}
              updateBulkQueryFilters={this.updateBulkQuery}
              updateStaticFilters={this.updateBulkQueryStaticFilters}
              updateDisplayFilters={this.updateBulkQueryDisplayFilters}
              groupName={groupName}
              stats={stats}
              done={this.bulkQueryDone}
              config={config}
            />
          </Grid>
          <Drawer
            variant="permanent"
            anchor="bottom"
            PaperProps={{ style: { overflowY: 'hidden' } }}
          >
            <Grid container alignItems="center" justify="space-between">
              <Grid style={{ padding: 0 }} xs={2} item>
                <div className={classes.drawerLeft}>
                  <Button
                    color="default"
                    variant="contained"
                    onClick={this.toggleLegend}
                  >
                    {showLegend ? 'Hide Legend' : 'Show Legend'}
                  </Button>
                </div>
              </Grid>
              {showLegend ? (
                <Grid xs={10} item>
                  <div style={{ padding: '0px 20px 20px 20px' }}>
                    <ScorecardLegend stats={stats} thresholds={thresholds} />
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </Drawer>
        </Grid>
      </div>
    )
  }
}

ScorecardPage.propTypes = {
  scorecard: PropTypes.shape({
    main: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      hasErrored: PropTypes.bool.isRequired,
      errMessage: PropTypes.string.isRequired
    }).isRequired
  })
}

const mapStateToProps = state => {
  return {
    app: state.app,
    userType: state.user.userType,
    hasAdminViewAccess: state.user.hasAdminViewAccess,
    isPrivilegedUser: state.user.accessRights.isPrivilegedUser,
    filter: state.ford.filter,
    dynamicFilters: state.ford.filter.dynamicFilters,
    displayFilters: state.ford.displayFilters,
    staticFilters: state.ford.filter.staticFilters,
    bulkQuery: state.ford.bulkQuery,
    stale: state.ford.filter.stale,
    config: state.app.config,
    baseEndpoint: state.dashboard.init.config.baseEndpoint,
    scorecard: {
      finalSelections: selectors.getFinalSelections(state.ford.filter),
      selections: selectors.getSelections(state.ford.filter),
      phase: selectors.getPhase(state),
      main: state.ford.scorecard.main,
      norm: state.ford.scorecard.norm,
      normLabel: state.ford.scorecard.norm.normLabel,
      data: selectors.getScorecard(state),
      months: state.ford.months,
      filter: state.ford.filter,
      brands: state.ford.scorecard.brands,
      sortParams: state.ford.scorecard.sortParams,
      busySession: state.ford.scorecard.busySession
    }
  }
}

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(ScorecardPage))
)
