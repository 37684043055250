import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { SingleSelectMenu, ListGridViewSelector, MediaButton } from '../../../'
import SetBuilderExport from './SetBuilderExport'

const Styled = withStyles(
  theme => theme.components.selection.SetBuilder.SetBuilderToolbar(theme)
)

class SetBuilderToolbar extends React.PureComponent {

  render() {
    const {
      classes,

      searchFilter,
      updateSearchFilter,

      sortOptions,
      selectSortMetric,

      enableExcludeNonNormFilter,
      excludeNonNormFilter,
      toggleExcludeNonNormFilter,

      exportButtons,
      columnFormat, 

      showGridView,
      updateGridView,

      adList,
      adType,
      groupName,
      baseEndpoint,

      generateError,

      genericConfig
    } = this.props

    const selectedRankingMetric = sortOptions.filter( sortOption => sortOption.selected)[0].label
    const { baseText } = genericConfig.MainHeader
    return (
      <Grid container className={classes.container} justify="space-between">
        <Grid item>
          <Grid
            container
            className={classes.grid}
            alignItems="flex-end"
            spacing={2}
          >
            <Grid item>
              <Tooltip title={`Filter ${baseText}s by Title or ${baseText} Code`}>
                <TextField
                  fullWidth
                  style={{ marginTop: -10, width: 250 }}
                  label={`Filter ${baseText}s`}
                  placeholder={`Type in part of a title or ${baseText} Code`}
                  value={searchFilter}
                  className={classes.textField}
                  onChange={updateSearchFilter}
                />
              </Tooltip>
            </Grid>

              <Grid item>
                <SingleSelectMenu
                  items={sortOptions}
                  disabled={false}
                  handleSelect={metric => selectSortMetric(metric.value)}
                />
              </Grid>

            {enableExcludeNonNormFilter ? (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={excludeNonNormFilter}
                        onChange={toggleExcludeNonNormFilter}
                      />
                    }
                    label={`Exclude Proprietary ${baseText}s`}
                  />
                </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            className={classes.grid}
            justify="flex-end"
            alignItems="center"
            spacing={2}
          >
            {exportButtons.data ? (
              <Grid item>
                  <SetBuilderExport adList={adList} columnFormat={columnFormat} selectedRankingMetric={selectedRankingMetric}> Export Data </SetBuilderExport>
              </Grid>
            ) : null}

            {exportButtons.media ? (
              <Grid item>
                  <MediaButton
                    mediaType={adType.mediaType}
                    adList={adList}
                    endPoint={`${baseEndpoint}/${groupName}/media/download`}
                    groupName={groupName}
                    generateError={generateError}
                  />
              </Grid>
            ) : null}
            <Grid item>
              <ListGridViewSelector
                showGridView={showGridView}
                update={updateGridView}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

SetBuilderToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  // adList: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     adCode: PropTypes.string.isRequired,
  //     brand: PropTypes.string.isRequired,
  //     title: PropTypes.string.isRequired,
  //     links: PropTypes.object.isRequired,
  //     sampleSize: PropTypes.number.isRequired,
  //     selectedText: PropTypes.string.isRequired,
  //     selectedDate: PropTypes.string.isRequired
  //   })
  // ).isRequired,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired
    })
  ),
  updateGridView: PropTypes.func.isRequired,
  // updateSortMetric: PropTypes.func.isRequired,
  updateSearchFilter: PropTypes.func.isRequired,
}

export default Styled(SetBuilderToolbar)
