import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ScorecardExport from './ScorecardExport'
import MediaButton from '../MediaButton'
import TypeIcon from '../TypeIcon'
import ReportButton from '../ReportButton'
import QueryButton from '../QueryButton'

const styles = () => ({
  dialog: {
    width: 400
  }
})

class ScorecardHeader extends PureComponent {
  render() {
    const {
      groupName,
      label,
      scorecard,
      monthText,
      monthCode,
      stats,
      thresholds,
      baseEndpoint,
      data: { mediaType },
      scorecard: { finalSelections },
      staticFile,
      openBulkQuery,
      bulkQueryBusy,
      busySession,
      setBusySession,
      hasAdminViewAccess,
      isPrivilegedUser
    } = this.props

    return (
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Grid container spacing={2} alignItems="center" justify="flex-start">
            <Grid item>
              <div style={{ paddingTop: 4 }}>
                <TypeIcon type={mediaType || ''} />
              </div>
            </Grid>
            <Grid item>
              <Typography variant="h5" color="inherit">
                {label}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            spacing={2}
          >
            <Grid item> { isPrivilegedUser || hasAdminViewAccess ? 
              <QueryButton action={ () => openBulkQuery(true)} busy={bulkQueryBusy} visible={true}/>
              : null }
            </Grid>
            {staticFile ? (
              <Grid item>
                <ReportButton
                  endPoint={`${baseEndpoint}/${groupName}/report`}
                  staticFile={staticFile}
                  groupName={groupName}
                />
              </Grid>
            ) : null}
            <Grid item>
              <ReportButton
                endPoint={`${baseEndpoint}/${groupName}/report`}
                monthText={monthText}
                groupName={groupName}
                report={scorecard.main.report}
              />
            </Grid>
            <Grid item>
              <ScorecardExport
                groupName={groupName}
                stats={stats}
                thresholds={thresholds}
                urlPath={`data-urls`}
                scorecard={scorecard}
                data={this.props.data}
              >
                Export Data
              </ScorecardExport>
            </Grid>
            <Grid item>
              <MediaButton
                endPoint={`${baseEndpoint}/${groupName}/media`}
                adList={scorecard.data.mainHeader}
                groupName={groupName}
                data={{ finalSelections }}
                mediaTypesOption={false}
                setBusySession={setBusySession}
                busySession={busySession}
                monthCode={monthCode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

ScorecardHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  groupName: PropTypes.string.isRequired,
  scorecard: PropTypes.shape({
    main: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      hasErrored: PropTypes.bool.isRequired,
      errMessage: PropTypes.string.isRequired
    }).isRequired,
    data: PropTypes.shape({
      textHeader: PropTypes.array.isRequired,
      textTable: PropTypes.array.isRequired,
      normHeader: PropTypes.array.isRequired,
      normTable: PropTypes.array.isRequired,
      mainHeader: PropTypes.array.isRequired,
      mainTable: PropTypes.array.isRequired
    }).isRequired
  }).isRequired,
  baseEndpoint: PropTypes.string.isRequired
}

export default withStyles(styles)(ScorecardHeader)
