import {
  ADMIN_INIT,
  ADMIN_REQUEST_UPDATE_METADATA,
  ADMIN_RECEIVE_METADATA,
  ADMIN_SELECT_AD,
  ADMIN_TOGGLE_AD_TAG,
  ADMIN_UPDATE_METADATA_FIELD,
  ADMIN_METADATA_OPEN_SEARCH,
  ADMIN_METADATA_UPDATE_RANGE,
  ADMIN_METADATA_TOGGLE_BRAND,
  ADMIN_METADATA_TOGGLE_ALL_BRANDS,
  ADMIN_METADATA_TOGGLE_AD_TAG,
  ADMIN_METADATA_TOGGLE_UNTAGGED_ONLY,
  ADMIN_METADATA_UPDATE_SELECTED_ADS,
  DASHBOARD_ADMIN_INTERFACE_CLOSE
} from '../../new_actions'

import { generateUUID, hasArray } from '../../new_utils'


function checkParent(list, id) {
  const index = list.findIndex(tag => tag.id === id)

  if (index === -1) {
    return list
  } else {
    const listMap = {}

    for (let i = 0; i < list.length; i++) {
      listMap[list[i].id] = list[i].selected
    }

    let allChecked = true
    let allUnChecked = true

    list[index].children.forEach(d => {
      if (listMap[d] !== 2) {
        allChecked = false
      }

      if (listMap[d] !== 0) {
        allUnChecked = false
      }
    })

    const next = [
      ...list.slice(0, index),
      { ...list[index], selected: allChecked ? 2 : allUnChecked ? 0 : 1 },
      ...list.slice(index + 1)
    ]

    if (list[index].parent !== 0) {
      return checkParent(next, list[index].parent)
    }

    return next
  }
}

function updateAdTag(list, id, value) {
  const index = list.findIndex(tag => tag.id === id)

  if (index === -1) {
    return list
  } else {
    let next = [
      ...list.slice(0, index),
      { ...list[index], selected: value },
      ...list.slice(index + 1)
    ]

    list[index].children.forEach(child => {
      next = updateAdTag(next, child, value)
    })

    return next
  }
}

function toggleAdTag(list, { id }) {
  const adTag = list.find(t => t.id === id)

  if (adTag) {
    const next = updateAdTag(list, id, adTag.selected === 0 ? 2 : 0)

    if (adTag.parent !== 0) {
      return checkParent(next, adTag.parent)
    }

    return next
  }

  return list
}

function updateCandidates(adsList, brandsMap, hasTags, search) {
  const { range: { start_month, end_month }, untaggedOnly  } = search
  const data = adsList
    .filter(ad => brandsMap[ad.brand.code] && brandsMap[ad.brand.code].selected)
    .filter(ad => ad.months.filter(month => {
      return (
        month.month_code >= start_month &&
        month.month_code <= end_month
      )
    }).length > 0)
    .filter(ad => !hasTags || !untaggedOnly || Object.keys(ad.tags || {}).length === 0)

    return data
}

const initialState = {
  adTags: [],
  tagStructureConfig: {},
  tagStructure: [],
  adsMap: {},
  adsList: [],
  brandsMap: {},
  brandsList: [],
  hasTags: false,
  search: {
    isOpen: false,
    untaggedOnly: false,
    candidates: [],
    selectedAdsList: [],
    selectedAdsMap: {},
    uuid: null,
    maxRange: {},
    range: {},
  },
  ad: {},
  ready: false,
  pending: false,
  error: null
}

export default function metadataReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_INIT: {
      const { tagStructureConfig, adsMap, adsList, brandsList, slices } = action.data

      const brandsMap = brandsList.reduce((obj, brand) => { obj[brand.code] = { ...brand, selected: true }; return obj }, {})

      const maxRange = adsList.reduce((obj, ad) => {
        obj['start_month'] = Array.isArray(ad.months) && ad.months[0].month_code < obj['start_month'] ? ad.months[0].month_code : obj['start_month']
        obj['end_month'] = Array.isArray(ad.months) && ad.months[0].month_code > obj['end_month'] ? ad.months[0].month_code : obj['end_month']
        return obj
      }, { start_month: 9999, end_month: 0 })
      const range = { start_month: maxRange.end_month - 1, end_month: maxRange.end_month }
      const selectedAdsList = adsList
      const selectedAdsMap = adsMap
      const candidates = [...selectedAdsList]
      const ad = adsList[0] || {}
      const hasTags = hasArray(tagStructureConfig)
      const tagStructure = (hasTags && slices && slices.reduce((tagStructure, slice) => {
        const adSlice = Array.isArray(ad[slice.field]) ? ad[slice.field][0] : ad[slice.field]
        return tagStructure[adSlice] || tagStructure['***']
      }, tagStructureConfig)) || []
      const adTags = hasTags && tagStructure.map(tag => {
        let selected = 0
        if (ad.tags && ad.tags[tag.id]) {
          selected = tag.children.length === 0 || tag.children.every(id => ad.tags[id]) ? 2 : 1
        }

        return {
          ...tag,
          selected
        }
      })
      return {
        ...state,
        ready: true,
        tagStructureConfig,
        adTags,
        adsMap,
        adsList,
        brandsMap,
        brandsList,
        hasTags,
        search: {
          ...state.search,
          maxRange,
          range,
          candidates,
          selectedAdsList,
          selectedAdsMap,
          uuid: generateUUID()
        },
        ad,
        slices
      }
    }

    case ADMIN_SELECT_AD: {
      const ad = action.data
      if (!ad) return state
      // if (state.ad && ad.ad_code === state.ad.ad_code) return {
      //   ...state,
      //   ad: {},
      //   adTags: []
      // }
      const tagStructure = (state.slices && state.slices.reduce((tagStructure, slice) => {
        const adSlice = Array.isArray(ad[slice.field]) ? ad[slice.field][0] : ad[slice.field]
        return tagStructure[adSlice] || tagStructure['***']
      }, state.tagStructureConfig)) || []
      const adTags = tagStructure.map(tag => {
        let selected = 0
        if (ad.tags && ad.tags[tag.id]) {
          selected = tag.children.length === 0 || tag.children.every(id => ad.tags[id]) ? 2 : 1
        }

        return {
          ...tag,
          selected
        }
      })

      return {
        ...state,
        ad,
        adTags
      }
    }

    case ADMIN_TOGGLE_AD_TAG: {
      const tag = action.data
      const adTags = toggleAdTag(state.adTags, { id: tag })

      const { adsList, brandsMap, hasTags, search } = state
      const candidates = updateCandidates(adsList, brandsMap, hasTags, search)
      return {
        ...state,
        search: {
          ...state.search,
          candidates
        },
        adTags
      }
    }

    case ADMIN_REQUEST_UPDATE_METADATA:
      return {
        ...state,
        ready: false,
        pending: true
      }

    case ADMIN_UPDATE_METADATA_FIELD: {
      const { field, value } = action.data
      return {
        ...state,
        search: {
          ...state.search,
          uuid: generateUUID()
        },
        ad: {
          ...state.ad,
          [field]: value
        }
      }
    }

    case ADMIN_RECEIVE_METADATA:
      const adsMap = action.data.adsMap
      const adsList = Object.keys(adsMap).reduce((array, ad_code) => { array.push(adsMap[ad_code]); return array; }, []).sort((a, b) => a.ad_code > b.ad_code ? 1 : -1)

      const { brandsMap, hasTags, search } = state
      const candidates = updateCandidates(adsList, brandsMap, hasTags, search)
      return {
        ...state,
        adsMap,
        adsList,
        search: {
          ...state.search,
          candidates,
          selectedAdsList: candidates,
          selectedAdsMap: adsMap,
          uuid: generateUUID()
        },
        ready: true,
        pending: false
      }

    case ADMIN_METADATA_OPEN_SEARCH:

      return {
        ...state,
        search: {
          ...state.search,
          isOpen: !!action.data.status
        }
      }

    case ADMIN_METADATA_UPDATE_RANGE: {
      const search = {
        ...state.search,
        range: action.data.range
      }
      const { adsList, brandsMap, hasTags } = state
      const candidates = updateCandidates(adsList, brandsMap, hasTags, search)
      return {
        ...state,
        search: {
          ...search,
          candidates,
          uuid: generateUUID()
        }
      }
    }

    case ADMIN_METADATA_TOGGLE_BRAND: {
      const { brand } = action.data
      const { adsList, hasTags, search } = state

      const brandsMap = {
        ...state.brandsMap,
        [brand.code]: {
          ...state.brandsMap[brand.code],
          selected: !state.brandsMap[brand.code].selected
        }
      }

      const candidates = updateCandidates(adsList, brandsMap, hasTags, search)
      return {
        ...state,
        brandsMap,
        search: {
          ...state.search,
          candidates,
          uuid: generateUUID()
        }
      }
    }

    case ADMIN_METADATA_TOGGLE_ALL_BRANDS: {
      const selected = Object.keys(state.brandsMap).some(brand => !state.brandsMap[brand].selected)
      const { adsList, hasTags, search } = state
      const brandsMap = state.brandsList.reduce((obj, brand) => { obj[brand.code] = { ...brand, selected }; return obj }, {})
      const candidates = updateCandidates(adsList, brandsMap, hasTags, search)
      return {
        ...state,
        brandsMap,
        search: {
          ...state.search,
          candidates,
          uuid: generateUUID()
        }
      }
    }

    case ADMIN_METADATA_TOGGLE_UNTAGGED_ONLY: {
      const search = {
        ...state.search,
        untaggedOnly: !state.search.untaggedOnly
      }
      const { adsList, brandsMap, hasTags } = state
      const candidates = updateCandidates(adsList, brandsMap, hasTags, search)

      return {
        ...state,
        search: {
          ...search,
          candidates,
          uuid: generateUUID()
        }
      }
    }

    case ADMIN_METADATA_TOGGLE_AD_TAG: {
      return state
    }

    case ADMIN_METADATA_UPDATE_SELECTED_ADS: {
      const selectedAdsList = [...state.search.candidates]
      const ad = selectedAdsList[0] || {}
      return {
        ...state,
        ad,
        search: {
          ...state.search,
          selectedAdsList,
          uuid: generateUUID()
        }
      }
    }

    case DASHBOARD_ADMIN_INTERFACE_CLOSE:
      return initialState

    default:
      return { ...state }
  }

}
