import {
  ADMIN_INIT,
  ADMIN_SELECT_AD,
  ADMIN_TOGGLE_AD_TAG,
  ADMIN_UPDATE_METADATA_FIELD,
  ADMIN_UPDATE_USER_MATCH,
  ADMIN_UPDATE_SELECTED_USER,
  ADMIN_TOGGLE_INCLUDE_APP_USERS,
  ADMIN_TOGGLE_INCLUDE_INACTIVE_USERS,
  ADMIN_METADATA_OPEN_SEARCH,
  ADMIN_METADATA_UPDATE_RANGE,
  ADMIN_METADATA_TOGGLE_BRAND,
  ADMIN_METADATA_TOGGLE_ALL_BRANDS,
  ADMIN_METADATA_TOGGLE_AD_TAG,
  ADMIN_METADATA_TOGGLE_UNTAGGED_ONLY,
  ADMIN_METADATA_UPDATE_SELECTED_ADS
} from './constants'

const adminInit = data => {
  return {
    type: ADMIN_INIT,
    data
  }
}

const adminSelectAd = ad => {
  return {
    type: ADMIN_SELECT_AD,
    data: ad
  }
}

const adminToggleAdTag = tag => {
  return {
    type: ADMIN_TOGGLE_AD_TAG,
    data: tag
  }
}

const adminUpdateMetadataField = (field, value) => {
  return {
    type: ADMIN_UPDATE_METADATA_FIELD,
    data: { field, value }
  }
}

const adminUpdateUserMatch = match => {
  return {
    type: ADMIN_UPDATE_USER_MATCH,
    data: { match }
  }
}

const adminUpdateSelectedUser = selectedUser => {
  return {
    type: ADMIN_UPDATE_SELECTED_USER,
    data: { selectedUser }
  }
}

const adminToggleIncludeAppUsers = () => {
  return {
    type: ADMIN_TOGGLE_INCLUDE_APP_USERS
  }
}

const adminToggleIncludeInactiveUsers = () => {
  return {
    type: ADMIN_TOGGLE_INCLUDE_INACTIVE_USERS
  }
}

const adminMetadataOpenSearch = status => {
  return {
    type: ADMIN_METADATA_OPEN_SEARCH,
    data: { status }
  }
}

const adminMetadataUpdateRange = range => {
  return {
    type: ADMIN_METADATA_UPDATE_RANGE,
    data: { range }
  }
}

const adminMetadataToggleBrand = brand => {
  return {
    type: ADMIN_METADATA_TOGGLE_BRAND,
    data: { brand }
  }
}

const adminMetadataToggleAllBrands = () => {
  return {
    type: ADMIN_METADATA_TOGGLE_ALL_BRANDS
  }
}

const adminMetadataToggleAdTag = tag => {
  return {
    type: ADMIN_METADATA_TOGGLE_AD_TAG,
    data: { tag }
  }
}

const adminMetadataToggleUntaggedOnly = tag => {
  return {
    type: ADMIN_METADATA_TOGGLE_UNTAGGED_ONLY
  }
}

const adminMetadataUpdateSeletedAds = selectedAdsList => {
  return {
    type: ADMIN_METADATA_UPDATE_SELECTED_ADS,
    data: { selectedAdsList }
  }
}

export {
  adminInit,
  adminSelectAd,
  adminToggleAdTag,
  adminUpdateMetadataField,
  adminUpdateUserMatch,
  adminUpdateSelectedUser,
  adminToggleIncludeAppUsers,
  adminToggleIncludeInactiveUsers,
  adminMetadataOpenSearch,
  adminMetadataUpdateRange,
  adminMetadataToggleBrand,
  adminMetadataToggleAllBrands,
  adminMetadataToggleAdTag,
  adminMetadataToggleUntaggedOnly,
  adminMetadataUpdateSeletedAds
}
