import MetricsLabels from './MetricsLabels'
import ScorecardView from './ScorecardView'

export default Object.assign(
  theme => {
    return {}
  },
  {
    ScorecardView,
    MetricsLabels
  }
)
