export default theme => {
  return {
    container: {
      height: '100%',
      backgroundColor: theme.palette.grey[100]
      
    },
    h1: {
      color: theme.palette.primary.dark,
      paddingTop: 10,
      // paddingBottom: 5
    },
    d1: {
      paddingTop: 5,
      paddingBottom: 5
    }
  }
}
