import {
  DASHBOARD_CLR_CONFIG,
  DASHBOARD_INIT,
  DASHBOARD_COMPLETE_METADATA_SLICE,
  DASHBOARD_TOGGLE_AD_TAG
} from '../../../new_actions'

const initialState = {
  tagTreeConfig: {},
  tagTree: {}
}

function checkParent(list, id) {
  const index = list.findIndex(tag => tag.id === id)

  if (index === -1) {
    return list
  } else {
    const listMap = {}

    for (let i = 0; i < list.length; i++) {
      listMap[list[i].id] = list[i].selected
    }

    let allChecked = true
    let allUnChecked = true

    list[index].children.forEach(d => {
      if (listMap[d] !== 2) {
        allChecked = false
      }

      if (listMap[d] !== 0) {
        allUnChecked = false
      }
    })

    const next = [
      ...list.slice(0, index),
      { ...list[index], selected: allChecked ? 2 : allUnChecked ? 0 : 1 },
      ...list.slice(index + 1)
    ]

    if (list[index].parent !== 0) {
      return checkParent(next, list[index].parent)
    }

    return next
  }
}

function updateAdTag(list, id, value) {
  const index = list.findIndex(tag => tag.id === id)

  if (index === -1) {
    return list
  } else {
    let next = [
      ...list.slice(0, index),
      { ...list[index], selected: value },
      ...list.slice(index + 1)
    ]

    list[index].children.forEach(child => {
      next = updateAdTag(next, child, value)
    })

    return next
  }
}

function toggleAdTag(list, { id }) {
  const adTag = list.find(t => t.id === id)

  if (adTag) {
    const next = updateAdTag(list, id, adTag.selected === 0 ? 2 : 0)

    if (adTag.parent !== 0) {
      return checkParent(next, adTag.parent)
    }

    return next
  }

  return list
}

export default (state = initialState, action) => {
  switch (action.type) {

    case DASHBOARD_INIT:

      const tagTreeConfig = action.data.config.components.selection.search.tagHierarchy

      return {
        ...state,
        tagTreeConfig
      }

    case DASHBOARD_COMPLETE_METADATA_SLICE: {

      const selected = action.data.map(slice => slice.values.find(option => option.selected))

      const tagTree = state.tagTreeConfig && selected.reduce((obj, selection) => {
        return (selection && obj[selection.field] ) || obj['***'] || {}
      }, state.tagTreeConfig)

      return tagTree ? {
        ...state,
        tagTree
      } : state
    }

    case DASHBOARD_TOGGLE_AD_TAG:
      return {
        ...state,
        tagTree: toggleAdTag(state.tagTree, action)
      }

    case DASHBOARD_CLR_CONFIG:
      return {
        ...initialState
      }

    default:
      return state
  }
}
