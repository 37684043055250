import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { Grid , Button} from '@material-ui/core'

const Styled = withStyles(theme => theme.components.selection.Search.SearchActions(theme))

export default Styled( props => {
  const { classes, close, add, remove, numAdd, numRemove } = props
  return (
    <Grid container justify="center" spacing={0} className={classes.container}>
      <Grid item>
        <Button onClick={add} color="secondary" variant="contained" disabled={!numAdd} size="large" className={classes.button}>
          {`Add ${numAdd ? `(${numAdd})`:''}`}
        </Button>
      </Grid>
      <Grid item>
        <Button onClick={remove} color="secondary" variant="contained" disabled={!numRemove} size="large" className={classes.button}>
        {`Remove ${numRemove ? `(${numRemove})`:''}`}
        </Button>
      </Grid>
      <Grid item>
        <Button onClick={close} color="inherit" size="large"  className={classes.button}>
          close
        </Button>
      </Grid>
    </Grid>
  )
})
