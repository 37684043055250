export default theme => {
  return {
    grow: {
      flexGrow: 1
    },
    appBar: {
      backgroundColor: theme.palette.grey[800]
    },
    paper: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main
    },
    // title: {
    //   paddingTop: 12
    // },
    production: {
      color: theme.palette.highlight.light,
      textDecoration: 'underline',
      fontWeight: 'bold'
    },
    colorPrimary: {
      color: theme.palette.text.primary
    }
  }
}
