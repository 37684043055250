import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DisplayFilters from './DisplayFilters'

import { withStyles } from '@material-ui/core/styles'
const styles = theme => ({
  dialog: {
    backgroundColor: theme.palette.background,
  },
  title: {
    border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
    boxShadow: `0 1px 1px rgba(0,0,0,0.14)`
  },
  content: {
    height: 250,
  },
})
export default withStyles(styles)(props => {
  const { classes, filters, update } = props
  return (
    <Dialog
      open={props.open}
      onClose={props.closeDialog}
      maxWidth="md"
      fullWidth={true}
      className={classes.dialog}
    >
      <DialogTitle className={classes.title}>
        <div>
          <Typography align="center" color="textPrimary" variant="h6">
            Filter Norm and Metrics Data
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          className={classes.content}
        >
          <Grid item xs={12}>
            <DisplayFilters elements={filters} update={update} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeDialog} >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
})
