/***
 * Core
 */
import React from 'react'

/***
 * Material UI
 */
import { withStyles } from '@material-ui/core/styles'

import marketcast from './png/marketcast.png'
import discover from './png/discover-logo.png'
import fidelity from './png/fidelity-logo.png'
import comcast from './png/xfinity-logo.png'
import comcastSMB from './png/comcast-smb-logo.png'
import nissan from './png/nissan-logo.png'
import ford from './png/ford-logo.png'
import lincoln from './png/lincoln-logo.png'
import audible from './png/audible-logo.png'
import square from './png/square-logo.png'
/***
 * Local components
 */
const logos = {
  'investor-services-demo': marketcast,
  'auto-dashboard-demo': marketcast,
  'adpi-category-insurance': marketcast,
  'adpi-category-qsr': marketcast,
  'adpi-category-covid': marketcast,
  'adpi-category-tech': marketcast,
  'adpi-category-tentpole-events': marketcast,
  'adpi-category-wireless': marketcast,
  'telecom-demo': marketcast,
  'auto-demo': marketcast,
  'comcast-v2': comcast,
  'comcast-smb': comcastSMB,
  'fidelity-v2': fidelity,
  'comcast-premarket': comcast,
  'comcast-smb-premarket': comcastSMB,
  'comcast-offer': comcast,
  marketcast,
  discover,
  fidelity,
  comcast,
  audible,
  nissan,
  'ford-us': ford,
  'ford-us-tier2': ford,
  'ford-ca': ford,
  'ford-ap': ford,
  'ford-eu': ford,
  'ford-mx': ford,
  'ford-lincoln': lincoln,
  'ford-ca-lincoln': lincoln,
  'ford-us-growth': ford,
  'ford-lincoln-growth': lincoln,
  'square': square
}

const Styled = withStyles(theme => theme.components.generic.Logo(theme))

export default Styled(props => {
  const { classes, size } = props
  return (
    <div className={classes[size]}>
      {props.href ? (
        <a href={props.href} target="blank">
          <img
            src={logos[props.name]}
            alt={props.name}
            style={{ maxHeight: '100%' }}
          />
        </a>
      ) : (
        <img
          src={logos[props.name]}
          alt={props.name}
          style={{ maxHeight: '100%' }}
        />
      )}
    </div>
  )
})

