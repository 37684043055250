import React from 'react'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { withStyles } from '@material-ui/core/styles'
const Styled = withStyles(theme => theme.components.navigation.SideBar(theme))

export default Styled(props => {
  const { toggleSidebar, open, links, base, classes } = props
  return (
    <Drawer open={open} onClose={toggleSidebar}>
      <List component="nav" onClick={toggleSidebar}>
        {links.map(link => {
          return (
            <Link style={{ textDecoration: 'none' }} to={`${base}${link.path}`} key={link.path}>
              <ListItem>
                <ListItemIcon>
                  {React.createElement(link.icon, {
                    color: 'primary',
                    classes: { colorPrimary: classes.colorPrimary }
                  })}
                </ListItemIcon>
                <ListItemText primary={link.label} disableTypography={true} className={classes.link} />
              </ListItem>
            </Link>
          )
        })}
      </List>
    </Drawer>
  )
})
