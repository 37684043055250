export default theme => {
  return {
    container: {
      height: '100%',
      borderBottom: `1px solid ${theme.palette.grey[400]}`
    },
    headerGrid: {
      height: 18
    },
    brandImage: {
      height: 25
    },
    brandLogo: {
      height: 18,
      marginBottom: 5
    },
    icon: {
      fontSize: 14,
      marginRight: 4,
      marginBottom: -3,
      color: theme.palette.grey[600]
    },
    center: {
      display: 'block',
      margin: 'auto',
      width: 'max-content'
    },
    headerMetricValueText: {
      fontWeight: 'bold'
    },
    headerMetricValueInverseText: {
      fontWeight: 'bold',
      color: theme.palette.text.primary
    },
  }
}
