export default {
  slices: [
    {
      field: 'market',
      display: 'Market',
      map: {
        'general': {
          display: 'General Market',
          sortOrder: 1
        },
        'hispanic': {
          display: 'Hispanic Market',
          sortOrder: 2
        }
      }
    },
    {
      field: 'ad_type',
      display: 'Ad Category',
      variant: 'multigroup',
      map: {
        'all_telecom' : {
          display: 'All Telecom',
          sortOrder: 1
        },
        'internet': {
          display: 'Internet',
          sortOrder: 2
        },
        'video_tv_ott': {
          display: 'Video (TV & OTT)',
          sortOrder: 3
        },
        'video_tv': {
          display: 'Video (TV Only)',
          sortOrder: 4
        },
        'video_ott': {
          display: 'Video (OTT Only)',
          sortOrder: 5
        },
        'home_security': {
          display: 'Home Security',
          sortOrder: 6
        },
        'mobile': {
          display: 'Mobile',
          sortOrder: 7
        },
        'internet_mobile': {
          display: 'Internet & Mobile',
          sortOrder: 8,
          multigroup: true
        },
        'internet_tv': {
          display: 'Internet & TV',
          sortOrder: 9,
          multigroup: true,
        },
        'internet_video': {
          display: 'Internet & Video (TV & OTT)',
          sortOrder: 10,
          multigroup: true,
        },
        'internet_tv_mobile' : {
          display: 'Internet, Video & Mobile',
          sortOrder: 11,
          multigroup: true,
        }
      }
    },
    {
      field: 'target',
      display: 'Target',
      useInQuery: true,
      map: {
        'gen_pop' : {
          display: 'General Population',
          sortOrder: 1
        },
        'elites': {
          display: 'Elites',
          sortOrder: 2
        },
        'prospects': {
          display: 'Prospects',
          sortOrder: 3
        },
        'customers': {
          display: 'Customers',
          sortOrder: 4
        },
        'black_consumers': {
          display: 'Black Consumers',
          sortOrder: 5
        }
      }
    }
  ]
}
