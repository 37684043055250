import React from 'react'
import ReactJSON from 'react-json-tree'
import cn from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import {
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { AsyncWrapper } from '../../generic'
const Styled = withStyles(theme => {
  return theme.components.adminView.AdminConfigManager.AdminConfigView(theme)
})

class AdminConfigView extends React.PureComponent {
  state = {
    index: 0,
    selected: {},
    invertTheme: true,
    displayedObj: {},
    displayedHash: null
  }

  select = row => {
    const { selected } = this.state
    this.setState({
      selected: {
        ...selected,
        [row.config_type]:
          row.hash === selected[row.config_type] ? null : row.hash
      }
    })
  }

  displayRow = row => {
    if (row.hash === this.state.displayedHash)
      this.setState({
        displayedObj: {},
        displayedHash: null
      })
    else
      this.setState({
        displayedObj: JSON.parse(row.config_content),
        displayedHash: row.hash
      })
  }

  cancel = () => {
    this.setState({
      selected: {},
      displayedObj: {},
      displayedHash: null
    })
  }

  isClean(obj) {
    return Object.keys(obj).reduce((clean, field) => clean && !obj[field], true)
  }

  apply = () => {
    const { apply } = this.props
    const selected = Object.keys( this.state.selected ).reduce( (obj,field) => { if (this.state.selected[field] !== null) obj[field]=this.state.selected[field]; return obj }, {})
    apply('config/config', selected)
    this.setState({
      selected: {},
      displayedObj: {},
      displayedHash: null
    })
  }

  render() {
    const { serverConfig, classes, refresh } = this.props
    const { selected, displayedObj, displayedHash } = this.state

    const metadata = serverConfig.data.filter(
      item => item.config_type === 'metadata'
    )
    const presets = serverConfig.data.filter(
      item => item.config_type === 'preset'
    )
    const config = serverConfig.data.filter(
      item => item.config_type === 'config'
    )

    return (
      <Paper className={classes.configView}>
        <Grid container justify="space-between">
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              align="center"
              color="inherit"
              style={{ display: 'inline' }}
            >
              JSON Configuration
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              key="refresh"
              color="inherit"
              onClick={refresh}
              disabled={serverConfig.pending}
            >
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <AsyncWrapper
              thickness={6}
              item={serverConfig}
              loadingMsg="Loading Server Configurations"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Select</TableCell>
                    <TableCell>Hash</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Version</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              {metadata.length ? (
                <div className={classes.tableBody}>
                  <Table>
                    <TableBody>
                      {metadata.map(row => {
                        return (
                          <TableRow
                            className={cn(
                              classes.tableRow,
                              row.active ? classes.active : null,
                              row.hash === displayedHash
                                ? classes.displayed
                                : null
                            )}
                            key={row.hash}
                            hover={true}
                            onClick={() => this.displayRow(row)}
                          >
                            <TableCell>
                              <Checkbox
                                color="secondary"
                                disabled={row.active}
                                checked={selected[row.config_type] === row.hash}
                                onClick={() => this.select(row)}
                              />
                            </TableCell>
                            <TableCell>{row.hash.slice(0, 10)}...</TableCell>
                            <TableCell>{row.config_type}</TableCell>
                            <TableCell>{row.config_version}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </div>
              ) : null}
              {presets.length ? (
                <div className={classes.tableBody}>
                  <Table>
                    <TableBody>
                      {presets.map(row => {
                        return (
                          <TableRow
                            className={cn(
                              row.active ? classes.active : null,
                              row.hash === displayedHash
                                ? classes.displayed
                                : null
                            )}
                            key={row.hash}
                            hover={true}
                            onClick={() => this.displayRow(row)}
                          >
                            <TableCell>
                              <Checkbox
                                color="secondary"
                                disabled={row.active}
                                checked={selected[row.config_type] === row.hash}
                                onClick={() => this.select(row)}
                              />
                            </TableCell>
                            <TableCell>{row.hash.slice(0, 10)}...</TableCell>
                            <TableCell>{row.config_type}</TableCell>
                            <TableCell>{row.config_version}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </div>
              ) : null}
              {config.length ? (
                <div className={classes.tableBody}>
                  <Table>
                    <TableBody>
                      {config.map(row => {
                        return (
                          <TableRow
                            className={cn(
                              classes.tableRow,
                              row.active ? classes.active : null,
                              row.hash === displayedHash
                                ? classes.displayed
                                : null
                            )}
                            key={row.hash}
                            hover={true}
                            onClick={() => this.displayRow(row)}
                          >
                            <TableCell>
                              <Checkbox
                                color="secondary"
                                disabled={row.active}
                                checked={selected[row.config_type] === row.hash}
                                onClick={() => this.select(row)}
                              />
                            </TableCell>
                            <TableCell>{row.hash.slice(0, 10)}...</TableCell>
                            <TableCell>{row.config_type}</TableCell>
                            <TableCell>{row.config_version}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </div>
              ) : null}

              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.apply}
                    disabled={this.isClean(selected)}
                  >
                    Apply
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.cancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </AsyncWrapper>
          </Grid>
          <Grid item xs={4} className={classes.jsonViewer}>
            <ReactJSON
              data={displayedObj}
              theme={serverConfig.jsonTheme.theme}
              invertTheme={serverConfig.jsonTheme.invert}
              getItemString={(type, data, itemType, itemString) => (
                <span>
                  {['Array', 'Object'].includes(type)
                    ? type === 'Array'
                      ? `[${itemString}]`
                      : '{}'
                    : itemString}{' '}
                </span>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default Styled(AdminConfigView)
