export default theme => {
  return {
    container: {
      paddingTop: 10,
      marginBottom: 10
    },
    headerItem: {
      paddingLeft: 40,
      paddingRight: 40
    },
    titleBar: {
      marginTop: 10
    },
    statusBar: {},
    tabBar: {},

    title: {
      margin: 5
    }
  }
}
