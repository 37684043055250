// Migration Constants
const ADMIN_REQUEST_CONFIG_CONFIG = 'ADMIN_REQUEST_CONFIG_CONFIG'
const ADMIN_RECEIVE_CONFIG_CONFIG = 'ADMIN_RECEIVE_CONFIG_CONFIG'
const ADMIN_REQUEST_UPDATE_CONFIG_CONFIG = 'ADMIN_REQUEST_UPDATE_CONFIG_CONFIG'
const ADMIN_FAIL_CONFIG_CONFIG = 'ADMIN_FAIL_CONFIG_CONFIG'
const ADMIN_REQUEST_CONFIG_MIGRATION = 'ADMIN_REQUEST_CONFIG_MIGRATION'
const ADMIN_RECEIVE_CONFIG_MIGRATION = 'ADMIN_RECEIVE_CONFIG_MIGRATION'
const ADMIN_FAIL_CONFIG_MIGRATION = 'ADMIN_FAIL_CONFIG_MIGRATION'
const ADMIN_REQUEST_MIGRATION_TEST = 'ADMIN_REQUEST_MIGRATION_TEST'
const ADMIN_RECEIVE_MIGRATION_TEST = 'ADMIN_RECEIVE_MIGRATION_TEST'
const ADMIN_FAIL_MIGRATION_TEST = 'ADMIN_FAIL_MIGRATION_TEST'
const ADMIN_REQUEST_UPDATE_MIGRATION = 'ADMIN_REQUEST_UPDATE_MIGRATION'
const ADMIN_REQUEST_MIGRATION = 'ADMIN_REQUEST_MIGRATION'
const ADMIN_RECEIVE_MIGRATION = 'ADMIN_RECEIVE_MIGRATION'
const ADMIN_RECEIVE_MIGRATION_ACK = 'ADMIN_RECEIVE_MIGRATION_ACK'
const ADMIN_RECEIVE_DELETE_MIGRATION = 'ADMIN_RECEIVE_DELETE_MIGRATION'
const ADMIN_FAIL_UPDATE_CONFIG_CONFIG = 'ADMIN_FAIL_UPDATE_CONFIG_CONFIG'

// User constants
const ADMIN_REQUEST_USERS = 'ADMIN_REQUEST_USERS'
const ADMIN_RECEIVE_USERS = 'ADMIN_RECEIVE_USERS'
const ADMIN_REQUEST_UPDATE_USERS = 'ADMIN_REQUEST_UPDATE_USERS'
const ADMIN_FAIL_USERS = 'ADMIN_FAIL_USERS'
const ADMIN_DELETE_USERS = 'ADMIN_DELETE_USERS'
const ADMIN_RECEIVE_DELETE_USERS = 'ADMIN_RECEIVE_DELETE_USERS'

// Metadata constants
const ADMIN_REQUEST_UPDATE_METADATA = 'ADMIN_REQUEST_UPDATE_METADATA'
const ADMIN_RECEIVE_METADATA = 'ADMIN_RECEIVE_METADATA'
const ADMIN_FAIL_METADATA = 'ADMIN_FAIL_METADATA'

export {
    ADMIN_REQUEST_CONFIG_CONFIG,
    ADMIN_RECEIVE_CONFIG_CONFIG,
    ADMIN_FAIL_CONFIG_CONFIG,
    ADMIN_REQUEST_CONFIG_MIGRATION,
    ADMIN_RECEIVE_CONFIG_MIGRATION,
    ADMIN_FAIL_CONFIG_MIGRATION,
    ADMIN_REQUEST_UPDATE_CONFIG_CONFIG,
    ADMIN_FAIL_UPDATE_CONFIG_CONFIG,
    ADMIN_REQUEST_UPDATE_MIGRATION,
    ADMIN_REQUEST_MIGRATION,
    ADMIN_RECEIVE_MIGRATION,
    ADMIN_RECEIVE_MIGRATION_ACK,
    ADMIN_REQUEST_MIGRATION_TEST,
    ADMIN_RECEIVE_MIGRATION_TEST,
    ADMIN_FAIL_MIGRATION_TEST,
    ADMIN_RECEIVE_DELETE_MIGRATION,
  
    ADMIN_REQUEST_USERS,
    ADMIN_RECEIVE_USERS,
    ADMIN_REQUEST_UPDATE_USERS,
    ADMIN_FAIL_USERS,
    ADMIN_DELETE_USERS,
    ADMIN_RECEIVE_DELETE_USERS,

    ADMIN_REQUEST_UPDATE_METADATA,
    ADMIN_RECEIVE_METADATA,
    ADMIN_FAIL_METADATA

  }