import main from './main'
import sidebar from './sidebar'
import components from './components'
import metadata from './metadata'

const hasEditableNorm = true
const hasTrendchart = true
const hasWordcloud = true
const hasAdStorage = true

const config = {
  baseEndpoint: 'api/dashboard',
  endPoints: {
    metadata: '/metadata',
    metrics: '/metrics',
    norm: '/norm'
  },
  hasEditableNorm,
  hasTrendchart,
  hasWordcloud,
  hasAdStorage,
  main,
  sidebar,
  components,
  metadata
}

export { config }

export default config
