import AdCategoriesIcon from '@material-ui/icons/Loyalty'
import BuilderIcon from '@material-ui/icons/Build'

export default [
    {
      "path": "/slicer",
      "label": "Market and Category Select",
      "icon": AdCategoriesIcon
    },
    {
      "path": "/builder",
      "label": "Builder",
      "icon": BuilderIcon
    }
  ]
  