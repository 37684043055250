export default theme => {
  return {
    snackbar: {},
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.info.contrastText
    },
    info: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText
    },
    icon: {
      fontSize: 20,
      marginRight: 10
    },
    closeIcon: {
        fontSize: 20
    },
    message: {
      display: 'flex',
      alignItems: 'center'
    }
  }
}
