export default theme => {
    return {
      backgroundColor: theme.palette.grey[50],
      lineColor: theme.palette.grey[400],
      maxRowHeight: 40,
      fillPercent: 95,
      lineStroke: 1,
      noStatColor: theme.palette.grey[300],
      titleOffset: 10,
      titleFontSize: 17,
      titleFontStyle: 'bold',
      titleColor: theme.palette.grey[600],
      xOffset: 10,
      titleMargin: 30,
      headerMetricFontSize: 12,
      headerMetricFontStyle: 'bold',
      headerMetricColor: theme.palette.grey[600],
      subHeaderMetricFontSize: 12,
      subHeaderMetricColor: theme.palette.grey[500],
      subHeaderMetricFontStyle: 'normal',
      subHeaderInset: 10,
      statRadius: 5,
      statColorSetBackground: false,
      statColorSaturationPercent: 0,
      statColorLightenPercent: 0,
      statColorValuePercent: 0,
    }
  }
  