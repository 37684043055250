export default {
  '***': {
    '***': [
      {
        "id": 100,
        "label": "Brand Image",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          105,
          110,
          115,
          120,
          125
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 105,
        "label": "Awards/Accolades",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Refers to any reference of awards or rankings, 3rd party or commissioned research based."
      },
      {
        "id": 110,
        "label": "Customer Service",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Refers to a primary focus on customer service"
      },
      {
        "id": 115,
        "label": "Co-Brand",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Single ad advertising for more than one brand ('Seamless + Taco Bell' or 'Marriott Chase credit card')"
      },
      {
        "id": 120,
        "label": "General Reputation",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Refers to any ad that focuses on a brand's general reputation, but does not mention any products"
      },
      {
        "id": 125,
        "label": "Sponsorship",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Sponsorship of an event, sport or project (such as a food bank)"
      },
      {
        "id": 200,
        "label": "Creative Type",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          205,
          210,
          215,
          220
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked"
      },
      {
        "id": 205,
        "label": "Narrative",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad follows a story trajectory, even if short"
      },
      {
        "id": 210,
        "label": "Vignette/Montage",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad does not have a story, but rather many different scenes cut together"
      },
      {
        "id": 215,
        "label": "Animated",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "At least half of ad is cartoon/animation and not filmed"
      },
      {
        "id": 220,
        "label": "Graphics/Product Images Only",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad is a computer generated ad usually focusing on a product with few or no actual filmed scenes"
      },
      {
        "id": 300,
        "label": "Creative Style",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          305,
          310,
          315,
          320,
          325,
          330,
          335,
          340,
          345,
          350
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 305,
        "label": "Humorous",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "The intent of the ad is to make you laugh"
      },
      {
        "id": 310,
        "label": "Emotional",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "The intent of the ad is to elicit sad or heartwarming emotion"
      },
      {
        "id": 315,
        "label": "Spokesperson",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Includes both celebrity and non-celebrity spokespeople"
      },
      {
        "id": 320,
        "label": "Celebrity",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Includes any celebrity inclusion, whether or not that person is a spokesperson"
      },
      {
        "id": 325,
        "label": "Music (jingle)",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Refers to a consistent jingle associated with a brand, not just background music"
      },
      {
        "id": 330,
        "label": "Music (lyrics/song)",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Refers to an identifiable song - either lyrics or instrumental"
      },
      {
        "id": 335,
        "label": "Branding in intro (First 5 seconds)",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "The advertising brand is shown or spoken in the first 5 seconds"
      },
      {
        "id": 340,
        "label": "Voice Over Narration",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "An off-screen narrator is featured"
      },
      {
        "id": 345,
        "label": "Continuity",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Ad uses the same creative construct and/or established brand cues"
      },
      {
        "id": 350,
        "label": "COVID-19 Reference",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Directly or indirectly refences COVID-19 including depictions of social distancing or masks "
      },
      {
        "id": 400,
        "label": "Airing Block",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          405,
          410,
          415,
          420,
          425
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked"
      },
      {
        "id": 405,
        "label": "First Quarter",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 410,
        "label": "Second Quarter",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 415,
        "label": "Halftime",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 420,
        "label": "Third Quarter",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 425,
        "label": "Fourth Quarter",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 500,
        "label": "Industry",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          502,
          504,
          506,
          508,
          510,
          512,
          514,
          516,
          518,
          520,
          522,
          524,
          550,
          526,
          528,
          530,
          532,
          534,
          536,
          538,
          540,
          542,
          544,
          546,
          548
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked"
      },
      {
        "id": 502,
        "label": "Auto",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 504,
        "label": "Alcoholic Beverages",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 506,
        "label": "Non-Alcoholic Beverages",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 508,
        "label": "Consumer Financial Services",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 510,
        "label": "Cosmetic & Beauty",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 512,
        "label": "Ecommerce",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 514,
        "label": "Entertainment",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 516,
        "label": "Fitness",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 518,
        "label": "Food",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 520,
        "label": "Grocery",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 522,
        "label": "Household Supplies",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 524,
        "label": "Insurance",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 550,
        "label": "Internet and TV",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 526,
        "label": "Investment Services",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 528,
        "label": "Personal Health",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 530,
        "label": "Pets",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 532,
        "label": "Pharmaceuticals & Healthcare",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 534,
        "label": "Public Service Announcements",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 536,
        "label": "Restaurant",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 538,
        "label": "Retail",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 540,
        "label": "Software",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 542,
        "label": "Tech Devices",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 544,
        "label": "Travel",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 546,
        "label": "Wireless",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
      {
        "id": 548,
        "label": "Other",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": ""
      },
    ]
  }
}
