import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

import {
  AlarmOff,
  DeleteSweep,
  Alarm,
  DoneAll,
  DonutLarge,
  Warning,
  Clear,
  Block
} from '@material-ui/icons'
import { appSetWarning } from '../../../new_actions'

const Styled = withStyles(theme =>
  theme.components.adminView.AdminConfigManager.AdminDataMigrations(theme)
)

const actionIcons = {
  scheduled: AlarmOff,
  completed: DeleteSweep,
  failed: DeleteSweep,
  canceled: DeleteSweep,
  acknowledged: Clear,
  pending: Block,
}

const statusIcons = {
  canceled: AlarmOff,
  scheduled: Alarm,
  completed: DoneAll,
  pending: DonutLarge,
  failed: Warning,
  acknowledged: Clear
}
const CustomTableCell = TableCell
class AdminDataMigrations extends React.PureComponent {
  handleAction = migration => {
    const { cancelMigration, acknowledgeMigration, dispatch } = this.props

    if (migration.status === 'scheduled') cancelMigration(migration)
    if (
      migration.status === 'completed' ||
      migration.status === 'canceled' ||
      migration.status === 'failed'
    )
      acknowledgeMigration(migration)
    if (migration.status === 'pending')
      dispatch( appSetWarning('Cannot alter a pending job.') )
  }

  render() {
    const { classes, migrations, environment } = this.props

    return (
      <Grid container className={classes.container}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell>Created by</CustomTableCell>
              <CustomTableCell>Notes</CustomTableCell>
              <CustomTableCell>Status</CustomTableCell>
              <CustomTableCell>Resolved by</CustomTableCell>
              <CustomTableCell>Resolved at</CustomTableCell>
              <CustomTableCell>Scheduled at</CustomTableCell>
              <CustomTableCell>Action</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {migrations.filter(migration => migration.environment === environment).map(migration => {
              const resolved_by =
                migration.status === 'canceled' ||
                migration.status === 'acknowledged'
                  ? migration.canceled_by || migration.resolved_by
                  : migration.status === 'failed' ||
                    migration.status === 'completed'
                    ? 'System'
                    : '-'
              const ActionIcon = actionIcons[migration.status] || null
              const StatusIcon = statusIcons[migration.status] || null
              return (
                <TableRow
                  className={classes.migration}
                  key={migration.migration_id}
                >
                  <CustomTableCell component="th" scope="migration">
                    {migration.created_by}
                  </CustomTableCell>
                  <CustomTableCell>{migration.comment}</CustomTableCell>
                  <CustomTableCell>
                    <StatusIcon />
                  </CustomTableCell>
                  <CustomTableCell>{resolved_by}</CustomTableCell>
                  <CustomTableCell>{migration.resolution_time}</CustomTableCell>
                  <CustomTableCell>{migration.scheduled_time}</CustomTableCell>
                  <CustomTableCell>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => this.handleAction(migration)}
                    >
                      <ActionIcon />
                    </IconButton>
                  </CustomTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Grid>
    )
  }
}

const Connected = connect( state => {
  return {}
})
export default Connected( Styled(AdminDataMigrations) )
