export default {
  '***': [
    {
      "id": 10,
      "label": "Financial Planning Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        20,
        30,
        40,
        50,
        60
      ],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 20,
      "label": "General Guidance",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 30,
      "label": "Dedicated Advisor (1 on 1 Guidance)",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 40,
      "label": "Financial Planning Tools (incl. Education/Seminars)",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 50,
      "label": "Wealth Management",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 60,
      "label": "College Savings",
      "selected": 0,
      "collapse": 0,
      "parent": 10,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 70,
      "label": "Retirement Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        100,
        110,
        120,
        130,
        80,
        90
      ],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 80,
      "label": "General Retirement Guidance",
      "selected": 0,
      "collapse": 0,
      "parent": 70,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 90,
      "label": "IRA/Rollovers/401(k)",
      "selected": 0,
      "collapse": 0,
      "parent": 70,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 100,
      "label": "Retirement Tools (incl. Education/Seminars)",
      "selected": 0,
      "collapse": 0,
      "parent": 70,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 110,
      "label": "Retirement Income/Annuities",
      "selected": 0,
      "collapse": 0,
      "parent": 70,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 120,
      "label": "Tax Management",
      "selected": 0,
      "collapse": 0,
      "parent": 70,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 130,
      "label": "TEM/403(b)",
      "selected": 0,
      "collapse": 0,
      "parent": 70,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 140,
      "label": "Brokerage Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        150,
        160,
        170,
        180,
        190
      ],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 150,
      "label": "General Brokerage",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 160,
      "label": "Trading",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 170,
      "label": "Research/Tools",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 180,
      "label": "Brokerage Accolades/Awards",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 190,
      "label": "ETFs & Options",
      "selected": 0,
      "collapse": 0,
      "parent": 140,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 200,
      "label": "Asset Management Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        210,
        220,
        230
      ],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 210,
      "label": "Actively Managed Funds",
      "selected": 0,
      "collapse": 0,
      "parent": 200,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 220,
      "label": "Index Funds",
      "selected": 0,
      "collapse": 0,
      "parent": 200,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 230,
      "label": "Fixed Income",
      "selected": 0,
      "collapse": 0,
      "parent": 200,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 240,
      "label": "Cash Management",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 250,
      "label": "Value Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        260,
        270,
        280
      ],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 260,
      "label": "General Value",
      "selected": 0,
      "collapse": 0,
      "parent": 250,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 270,
      "label": "Fees/Pricing",
      "selected": 0,
      "collapse": 0,
      "parent": 250,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 280,
      "label": "Offers",
      "selected": 0,
      "collapse": 0,
      "parent": 250,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 290,
      "label": "Brand Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        300,
        310,
        320,
        330
      ],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 300,
      "label": "General Brand Image",
      "selected": 0,
      "collapse": 0,
      "parent": 290,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 310,
      "label": "General Accolades/Awards",
      "selected": 0,
      "collapse": 0,
      "parent": 290,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 320,
      "label": "Thought Leadership",
      "selected": 0,
      "collapse": 0,
      "parent": 290,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 330,
      "label": "Sponsorship",
      "selected": 0,
      "collapse": 0,
      "parent": 290,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 340,
      "label": "Style Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        350,
        390,
        400,
        405,
        410,
        420,
        430,
        440
      ],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 350,
      "label": "People Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [
        360,
        370,
        380
      ],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 360,
      "label": "People - Female lead",
      "selected": 0,
      "collapse": 0,
      "parent": 350,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 370,
      "label": "People - Couple/Family/Kids",
      "selected": 0,
      "collapse": 0,
      "parent": 350,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 380,
      "label": "People - Other",
      "selected": 0,
      "collapse": 0,
      "parent": 350,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 390,
      "label": "Screenshots/Demos/Charts",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 400,
      "label": "Animation",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 405,
      "label": "Music",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 410,
      "label": "Paragraph Style Copy",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 420,
      "label": "Bullet Style Copy",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 430,
      "label": "Lifestyle Image",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 440,
      "label": "Custom Print Unit/Layout",
      "selected": 0,
      "collapse": 0,
      "parent": 340,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 450,
      "label": "Spokesperson Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        460,
        470,
        480
      ],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 460,
      "label": "Employee Spokesperson",
      "selected": 0,
      "collapse": 0,
      "parent": 450,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 470,
      "label": "Customer Spokesperson",
      "selected": 0,
      "collapse": 0,
      "parent": 450,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 480,
      "label": "Celebrity Spokesperson",
      "selected": 0,
      "collapse": 0,
      "parent": 450,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 490,
      "label": "Tone Overall",
      "selected": 0,
      "collapse": 0,
      "parent": 0,
      "children": [
        500,
        510
      ],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 500,
      "label": "Emotional",
      "selected": 0,
      "collapse": 0,
      "parent": 490,
      "children": [],
      "tooltip": "No tooltip provided"
    },
    {
      "id": 510,
      "label": "Humorous",
      "selected": 0,
      "collapse": 0,
      "parent": 490,
      "children": [],
      "tooltip": "No tooltip provided"
    }
  ]
}
