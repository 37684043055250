const formatDate = (date, format) => {
  if (!date) return 'N/A'
  const f = date.match(/([0-9]*-[0-9]*-[0-9]*)/)
  if (!(f && f.length && typeof f[0] === 'string')) return 'N/A'

  let [f_year, f_month, f_day] = f[0].split('-')
  const f_string = format === 'dd/mm/yy' ? `${f_day}/${f_month}/${f_year}` : `${f_month}/${f_day}/${f_year}`

  return f_string
}

export default formatDate
