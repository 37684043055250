import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DynamicFilters from './DynamicFilters'
import FilterIcon from '@material-ui/icons/FilterList'
import SortIcon from '@material-ui/icons/Sort'
import FilterDialog from './FilterDialog'
import SortDialog from './SortDialog'
import DisplayFilters from './DisplayFilters'
import FilterButton from './FilterButton'

const styles = theme => ({
  paper: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    marginTop: 10,
    marginBottom: 20,
  },
  hover: {
    '&:hover': {
      backgroundColor: '#e0e0e0',
      cursor: 'pointer',
    },
  },
})

class ScorecardFilters extends PureComponent {
  state = {
    filterDialogOpen: false,
    sortDialogOpen: false,
    sortKeepLeft: false,
    staticFilterMessage: null,
    sortOrder: 0,
  }
  openFilterDialog(value) {
    this.setState({
      filterDialogOpen: value,
    })
  }
  openSortDialog(value) {
    this.setState({
      sortDialogOpen: value,
    })
  }

  componentDidUpdate(props) {
    const {
      finalSelections: { staticFilterSelection },
    } = props

    const staticFilterMessage = staticFilterSelection
      ? Object.keys(staticFilterSelection).reduce((msg, key) => {
          return `Filter [${key}=${staticFilterSelection[key]}] is applied.`
        }, '')
      : null

    this.setState({ staticFilterMessage })
  }

  render() {
    const {
      updateDynamicFilters,
      dynamicFilters: { filters, selections },
      displayFilters,
      updateDisplayFilters,
      staticFilters,
      updateStaticFilters,
    } = this.props

    const stale = selections.find( selection => selection.value === null)
    return (
      <div>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
        >
          <Grid item xs={7}>
            <DynamicFilters
              elements={filters}
              selections={selections}
              update={updateDynamicFilters}
            />
          </Grid>
          <Grid item xs={3}>
            <DisplayFilters
              elements={displayFilters}
              update={updateDisplayFilters}
              stale={stale}
            />
          </Grid>

          <Grid item xs={2}>
            <Grid container justify="flex-end" spacing={2}>
              <FilterButton
                update={() => this.openFilterDialog(true)}
                label={'Filter'}
                icon={FilterIcon}
                activated={this.state.staticFilterMessage}
                disabled={stale}
              />
              <FilterButton
                update={() => this.openSortDialog(true)}
                label={'Sort'}
                icon={SortIcon}
                disabled={stale}
              />
            </Grid>
          </Grid>
        </Grid>

        <FilterDialog
          open={this.state.filterDialogOpen}
          closeDialog={() => this.openFilterDialog(false)}
          filters={staticFilters}
          update={updateStaticFilters}
        />

        <SortDialog
          open={this.state.sortDialogOpen}
          closeDialog={() => this.openSortDialog(false)}
          updateSort={this.updateSort}
          sortParams={this.props.sortParams}
          updateGroupLeft={this.props.updateGroupLeft}
          updateSortMetric={this.props.updateSortMetric}
          updateSortOrder={this.props.updateSortOrder}
          config={this.props.config}
        />
      </div>
    )
  }
}

ScorecardFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  updateStaticFilters: PropTypes.func.isRequired,
  scorecard: PropTypes.shape({
    textHeader: PropTypes.array.isRequired,
    textTable: PropTypes.array.isRequired,
    normHeader: PropTypes.array.isRequired,
    normTable: PropTypes.array.isRequired,
    mainHeader: PropTypes.array.isRequired,
    mainTable: PropTypes.array.isRequired,
  }),
}

export default withStyles(styles)(ScorecardFilters)
