export default {
  '***': {
    'market_smb': {
      '***': {
        headerHeight: 80,
        rowTitleHeight: 35,
        padding: 1,
        aspectRatio: 0.7727,
        externalHeaderHeight: 0,
        minContainerHeight: 1000,
        horizontalMargin: 10,
        verticalMargin: 10,
        rows: [
          {
            height: 0.33,
            title: 'IN-MARKET PERFORMANCE',
            data: [
              {
                content: [{
                  component: 'MetricDonut',
                  data: {
                    metric: 100,
                    metricScale: 200
                  }
                }],
                width: 0.3,
                key: null
              },
              {
                content: [{
                  component: 'MetricTable',
                  data: {
                    label: 'Recall & Branding',
                    metrics: [
                      { metric: 101, isHeaderMetric: true, isCapitalized: true },
                      { metric: 113, isHeaderMetric: true },
                      { metric: 114, isHeaderMetric: true },
                      { metric: 115, isHeaderMetric: true, label: "Fit with Brand"  },
                      { metric: 116, isHeaderMetric: true, label: "Demonstrates Brand Credibility"  }
                    ]
                  }
                }],
                width: 0.35,
                key: null
              },
              {
                content: [{
                  component: 'MetricTable',
                  data: {
                    label: 'Impact',
                    metrics: [
                      { metric: 102, isHeaderMetric: true, isCapitalized: true },
                      { metric: 104, isHeaderMetric: true, isCapitalized: true },
                      { metric: 107, isHeaderMetric: false, isInset: true },
                      { metric: 109, isHeaderMetric: false, isInset: true },
                      { metric: 110, isHeaderMetric: false, isInset: true },
                      { metric: 111, isHeaderMetric: false, isInset: true },
                      { metric: 112, isHeaderMetric: false, isInset: true }
                    ]
                  }
                }],
                width: 0.35,
                key: null
              }
            ]
          },
          {
            height: 0.67,
            title: 'CREATIVE PERFORMANCE',
            data: [
              {
                content: [{
                  component: 'MetricTable',
                  data: {
                    label: 'Value Proposition',
                    metrics: [
                      { metric: 117, isHeaderMetric: true },
                      { metric: 118, isHeaderMetric: true },
                      { metric: 119, isHeaderMetric: true },
                      { metric: 120, isHeaderMetric: true },
                      { metric: 121, isHeaderMetric: true },
                      { metric: 122, isHeaderMetric: true }
                    ]
                  }
                }],
                legend: {
                  xOffset: 10,
                  yOffset: -20
                },
                width: 0.3,
                key: null
              },
              {
                content: [{
                  component: 'MetricChart',
                  data: {
                    title: 'Creative Efficiency',
                    mainMetric: {
                      type: 'horizontal',
                      scale: {
                        start: 85,
                        end: 155
                      },
                      metric: 125,
                      label: 'Creative Summary Score'
                    },
                    subMetrics: [
                      { metric: 126 },
                      { metric: 132 },
                      { metric: 139 },
                      { metric: 144, label: 'Cognitive' },
                      { metric: 151, label: 'Emotional' },
                      { metric: 157 }
                    ]
                  }
                }],
                width: 0.35,
                key: null
              },
              {
                content: [{
                  component: 'MetricOpenend',
                  data: {
                    label: 'In Their Own Words',
                    topBox: {
                      likes: {
                        metric: 123,
                        noStatColor: '#BFBFBF'
                      },
                      dislikes: {
                        metric: 124,
                        noStatColor: '#AFABAB'
                      }
                    },
                    messageField: 'message',
                    wordcloudConfig: {
                      sentiments: [
                        { color: '#FF872F', description: 'Very Negative' },
                        { color: '#FFC397', description: 'Negative' },
                        { color: '#DAD9D6', description: 'Neutral' },
                        { color: '#C4E1AF', description: 'Positive' },
                        { color: '#89C35F', description: 'Very Positive' }
                      ],
                      scoreRange: [0, 7],
                      font: 'Arial, Helvetica, sans-serif',
                      showCounts: false,
                      padding: 5,
                      rotation: 0,
                      wordCountCutoff: 2,
                      wordLengthCutoff: 3,
                      numDisplayed: 100
                    }
                  }
                }],
                width: 0.35,
                key: null
              }
            ]
          }
        ],
        legend: {
          metadataFields: [
            {
              value: 'last_month_tested',
              label: 'Last Tested'
            }
          ],
          includeSelections: true,
          includeStatTesting: true,
          includeFilters: {
            label: 'Target',
            default: 'Total SMB'
          }
        }
      }
    },
    'market_mm_ent': {
      '***': {
        headerHeight: 80,
        rowTitleHeight: 35,
        padding: 1,
        aspectRatio: 0.7727,
        externalHeaderHeight: 0,
        minContainerHeight: 1000,
        horizontalMargin: 10,
        verticalMargin: 10,
        rows: [
          {
            height: 0.33,
            title: 'IN-MARKET PERFORMANCE',
            data: [
              {
                content: [{
                  component: 'MetricDonut',
                  data: {
                    metric: 100,
                    metricScale: 200
                  }
                }],
                width: 0.3,
                key: null
              },
              {
                content: [{
                  component: 'MetricTable',
                  data: {
                    label: 'Recall & Branding',
                    metrics: [
                      { metric: 101, isHeaderMetric: true, isCapitalized: true },
                      { metric: 113, isHeaderMetric: true },
                      { metric: 114, isHeaderMetric: true },
                      { metric: 115, isHeaderMetric: true, label: "Fit with Brand"  },
                      { metric: 116, isHeaderMetric: true, label: "Demonstrates Brand Credibility"  }
                    ]
                  }
                }],
                width: 0.35,
                key: null
              },
              {
                content: [{
                  component: 'MetricTable',
                  data: {
                    label: 'Impact',
                    metrics: [
                      { metric: 102, isHeaderMetric: true, isCapitalized: true },
                      { metric: 104, isHeaderMetric: true, isCapitalized: true },
                      { metric: 107, isHeaderMetric: false, isInset: true },
                      { metric: 109, isHeaderMetric: false, isInset: true },
                      { metric: 166, isHeaderMetric: false, isInset: true },
                      { metric: 167, isHeaderMetric: false, isInset: true },
                      { metric: 112, isHeaderMetric: false, isInset: true }
                    ]
                  }
                }],
                width: 0.35,
                key: null
              }
            ]
          },
          {
            height: 0.67,
            title: 'CREATIVE PERFORMANCE',
            data: [
              {
                content: [{
                  component: 'MetricTable',
                  data: {
                    label: 'Value Proposition',
                    metrics: [
                      { metric: 117, isHeaderMetric: true },
                      { metric: 118, isHeaderMetric: true },
                      { metric: 168, isHeaderMetric: true },
                      { metric: 121, isHeaderMetric: true },
                      { metric: 169, isHeaderMetric: true }
                    ]
                  }
                }],
                legend: {
                  xOffset: 10,
                  yOffset: -20
                },
                width: 0.3,
                key: null
              },
              {
                content: [{
                  component: 'MetricChart',
                  data: {
                    title: 'Creative Efficiency',
                    mainMetric: {
                      type: 'horizontal',
                      scale: {
                        start: 85,
                        end: 155
                      },
                      metric: 125,
                      label: 'Creative Summary Score'
                    },
                    subMetrics: [
                      { metric: 126 },
                      { metric: 132 },
                      { metric: 139 },
                      { metric: 144, label: 'Cognitive' },
                      { metric: 151, label: 'Emotional' },
                      { metric: 157 }
                    ]
                  }
                }],
                width: 0.35,
                key: null
              },
              {
                content: [{
                  component: 'MetricOpenend',
                  data: {
                    label: 'In Their Own Words',
                    topBox: {
                      likes: {
                        metric: 123,
                        noStatColor: '#BFBFBF'
                      },
                      dislikes: {
                        metric: 124,
                        noStatColor: '#AFABAB'
                      }
                    },
                    messageField: 'message',
                    wordcloudConfig: {
                      sentiments: [
                        { color: '#FF872F', description: 'Very Negative' },
                        { color: '#FFC397', description: 'Negative' },
                        { color: '#DAD9D6', description: 'Neutral' },
                        { color: '#C4E1AF', description: 'Positive' },
                        { color: '#89C35F', description: 'Very Positive' }
                      ],
                      scoreRange: [0, 7],
                      font: 'Arial, Helvetica, sans-serif',
                      showCounts: false,
                      padding: 5,
                      rotation: 0,
                      wordCountCutoff: 2,
                      wordLengthCutoff: 3,
                      numDisplayed: 100
                    }
                  }
                }],
                width: 0.35,
                key: null
              }
            ]
          }
        ],
        legend: {
          metadataFields: [
            {
              value: 'last_month_tested',
              label: 'Last Tested'
            }
          ],
          includeSelections: true,
          includeStatTesting: true,
          includeFilters: {
            label: 'Target',
            default: 'Total MM/ENT'
          }
        }
      }
    }
  }
}
