import {
  DASHBOARD_RECEIVE_METADATA,
  DASHBOARD_REQUEST_MEDIA_LINK,
  DASHBOARD_RECEIVE_MEDIA_LINK,
  DASHBOARD_FAIL_MEDIA_LINK,
  DASHBOARD_VIEWER_CLOSE
} from '../../new_actions'

const initialState = {
  ready: false,
  error: null,
  open: false,
  data: {
    ad_code: '',
    media: '',
    title: '',
    url: '',
    mediaType: null
  }
}

export default function viewerReducer(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_RECEIVE_METADATA:
      return {
        ...state,
        ready: true
      }

    case DASHBOARD_REQUEST_MEDIA_LINK:
      const { media, title, ad_code } = action.data
      return {
        ...state,
        error: false,
        data: {
          ...state.data,
          media,
          title,
          ad_code
        }
      }

    case DASHBOARD_RECEIVE_MEDIA_LINK:
      const { url, mediaType } = action.data
      return {
        ...state,
        open: true,
        ready: true,
        data: {
          ...state.data,
          url,
          mediaType
        }
      }

    case DASHBOARD_FAIL_MEDIA_LINK:
      return {
        ...initialState,
        error: action.data || 'Unknown media error'
      }

    case DASHBOARD_VIEWER_CLOSE:
      return {
        ...state,
        open: false,
        data: {
          ...initialState.data
        }
      }

    default:
      return state
  }
}
