export default theme => ({
    container: {
        flexGrow: 1
    },
    root: {
      paddingLeft: 10,
      marginLeft: -8,
      marginTop: 26,
      width: '100%'
    },
    row: {
      height: 38
    },
    title: {
      color: theme.palette.grey[700],
      marginBottom: 5
    },
    paper: { 
        padding: 10 
    }
  })