export default {
  '***': {
    '***': [
      {
        id: 100,
        label: 'Speeds',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [105, 110, 120, 130, 135, 140, 145, 150, 160, 165, 170, 180, 190, 195],
        tooltip: 'This will be checked automatically when any item in this section is checked.'
      },
      {
        id: 105,
        label: '25 Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 110,
        label: '50 Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 120,
        label: '75 Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 130,
        label: '100 Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 135,
        label: '200 Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 140,
        label: '300 Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 145,
        label: '400 Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 150,
        label: '500 Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 160,
        label: '600 Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 165,
        label: '800 Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 170,
        label: '940 Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 180,
        label: '1 Gbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 190,
        label: '1.2 Gbps/1200Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 195,
        label: '2 Gbps/2000Mbps',
        selected: 0,
        collapse: 0,
        parent: 100,
        children: [],
        tooltip: 'Refers to Internet speed referenced in offer'
      },
      {
        id: 200,
        label: 'Terms',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [210, 220, 230, 240, 250, 260, 270],
        tooltip: 'This will be checked automatically when any item in this section is checked.'
      },
      {
        id: 210,
        label: 'No contract',
        selected: 0,
        collapse: 0,
        parent: 200,
        children: [],
        tooltip: 'Refers to the terms referenced in the offer'
      },
      {
        id: 220,
        label: '12 months/1 year',
        selected: 0,
        collapse: 0,
        parent: 200,
        children: [],
        tooltip: 'Refers to the terms referenced in the offer'
      },
      {
        id: 230,
        label: '24 months/2 years',
        selected: 0,
        collapse: 0,
        parent: 200,
        children: [],
        tooltip: 'Refers to the terms referenced in the offer'
      },
      {
        id: 240,
        label: '60 months',
        selected: 0,
        collapse: 0,
        parent: 200,
        children: [],
        tooltip: 'Refers to the terms referenced in the offer'
      },
      {
        id: 250,
        label: 'Service/Device requirement',
        selected: 0,
        collapse: 0,
        parent: 200,
        children: [],
        tooltip: 'Refers to the terms referenced in the offer'
      },
      {
        id: 260,
        label: 'Price guarantee',
        selected: 0,
        collapse: 0,
        parent: 200,
        children: [],
        tooltip: 'Refers to the terms referenced in the offer'
      },
      {
        id: 270,
        label: 'Other',
        selected: 0,
        collapse: 0,
        parent: 200,
        children: [],
        tooltip: 'Refers to the terms referenced in the offer'
      },
      {
        id: 300,
        label: 'Streaming Services',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [310, 320, 330, 340, 350, 360, 370, 380, 390],
        tooltip: 'This will be checked automatically when any item in this section is checked.'
      },

      {
        id: 310,
        label: 'Disney+/Disney Bundle',
        selected: 0,
        collapse: 0,
        parent: 300,
        children: [],
        tooltip: 'Refers to the streaming service referenced in the offer'
      },
      {
        id: 320,
        label: 'HBO Max',
        selected: 0,
        collapse: 0,
        parent: 300,
        children: [],
        tooltip: 'Refers to the streaming service referenced in the offer'
      },
      {
        id: 330,
        label: 'Peacock Premium',
        selected: 0,
        collapse: 0,
        parent: 300,
        children: [],
        tooltip: 'Refers to the streaming service referenced in the offer'
      },
      {
        id: 340,
        label: 'Paramount+',
        selected: 0,
        collapse: 0,
        parent: 300,
        children: [],
        tooltip: 'Refers to the streaming service referenced in the offer'
      },
      {
        id: 350,
        label: 'Sling TV',
        selected: 0,
        collapse: 0,
        parent: 300,
        children: [],
        tooltip: 'Refers to the streaming service referenced in the offer'
      },
      {
        id: 360,
        label: 'Discovery+',
        selected: 0,
        collapse: 0,
        parent: 300,
        children: [],
        tooltip: 'Refers to the streaming service referenced in the offer'
      },
      {
        id: 370,
        label: 'YouTube',
        selected: 0,
        collapse: 0,
        parent: 300,
        children: [],
        tooltip: 'Refers to the streaming service referenced in the offer'
      },
      {
        id: 380,
        label: 'Apple',
        selected: 0,
        collapse: 0,
        parent: 300,
        children: [],
        tooltip: 'Refers to the streaming service referenced in the offer'
      },
      {
        id: 390,
        label: 'Other',
        selected: 0,
        collapse: 0,
        parent: 300,
        children: [],
        tooltip: 'Refers to the streaming service referenced in the offer'
      },
      {
        id: 400,
        label: 'Discounts',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [410, 420, 430, 440],
        tooltip: 'This will be checked automatically when any item in this section is checked.'
      },
      {
        id: 410,
        label: 'Unlimited mobile line',
        selected: 0,
        collapse: 0,
        parent: 400,
        children: [],
        tooltip: 'Refers to the type of discount referenced in the offer'
      },
      {
        id: 420,
        label: 'Mobile line',
        selected: 0,
        collapse: 0,
        parent: 400,
        children: [],
        tooltip: 'Refers to the type of discount referenced in the offer'
      },
      {
        id: 430,
        label: 'Bill Credit',
        selected: 0,
        collapse: 0,
        parent: 400,
        children: [],
        tooltip: 'Refers to the type of discount referenced in the offer'
      },
      {
        id: 440,
        label: 'Other',
        selected: 0,
        collapse: 0,
        parent: 400,
        children: [],
        tooltip: 'Refers to the type of discount referenced in the offer'
      },
      {
        id: 500,
        label: 'Perks',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [510, 520, 530, 540, 550, 560, 570, 580, 590],
        tooltip: 'This will be checked automatically when any item in this section is checked.'
      },
      {
        id: 510,
        label: 'Gift card',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Refers to the type of perk referenced in the offer'
      },
      {
        id: 520,
        label: 'Device inclusion',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Refers to the type of perk referenced in the offer'
      },
      {
        id: 530,
        label: 'Device discount',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Refers to the type of perk referenced in the offer'
      },
      {
        id: 540,
        label: 'Service inclusion',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Refers to the type of perk referenced in the offer'
      },
      {
        id: 550,
        label: 'Service discount',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Refers to the type of perk referenced in the offer'
      },
      {
        id: 560,
        label: 'Other',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Refers to the type of perk referenced in the offer'
      },
      {
        id: 570,
        label: 'Wifi guarantee',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Refers to the type of perk referenced in the offer'
      },
      {
        id: 580,
        label: 'Equipment upgrade',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Refers to the type of perk referenced in the offer'
      },
      {
        id: 590,
        label: 'Internet back-up',
        selected: 0,
        collapse: 0,
        parent: 500,
        children: [],
        tooltip: 'Refers to the type of perk referenced in the offer'
      },
      {
        id: 600,
        label: 'Internet equipment',
        selected: 0,
        collapse: 0,
        parent: 0,
        children: [610, 620],
        tooltip: 'This will be checked automatically when any item in this section is checked.'
      },
      {
        id: 610,
        label: 'Optional',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Refers to the type of internet equipment referenced in the offer'
      },
      {
        id: 620,
        label: 'Included',
        selected: 0,
        collapse: 0,
        parent: 600,
        children: [],
        tooltip: 'Refers to the Giftcards referenced in the offer'
      }
    ]
  }
}
