import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import TimeIcon from '@material-ui/icons/DateRange'
import SizeIcon from '@material-ui/icons/People'
import Tooltip from '@material-ui/core/Tooltip'

import { monthCodeToText, format } from '../../../../new_utils'

const Styled = withStyles(theme => theme.components.selection.SetBuilder.SetBuilderGridView(theme))

class SetBuilderGridView extends React.PureComponent {
  getFormattedRankingMetric = ad => {
    return format.getFormat(ad.ranking_metric_format).formatter(ad.ranking_metric)
  }

  render = () => {
    const { classes, adList, tableHeight, checkAd, checkedAds, openMedia, includeSampleSize } = this.props

    return (
      <Paper
        style={{ height: tableHeight, overflow: 'auto' }}
        className={classes.container}
        classes={{ elevation0: classes.boxShadow }}
        elevation={0}
      >
        <Grid container spacing={2} alignItems="center" justify="center">
          {adList.map(ad => {
            const checked = checkedAds.includes(ad.ad_code)
            return (
              <Grid item key={ad.ad_code}>
                <Paper elevation={0} classes={{ elevation0: classes.boxShadow }}>
                  <Card className={classes.card} elevation={0}>
                    <CardMedia
                      className={classes.media}
                      image={ad.links.thumbnail}
                      title={ad.title}
                      elevation={0}
                      onClick={e => {
                        e.preventDefault()
                        openMedia(ad)
                      }}
                    />
                    <CardContent className={classes.cardContent} onClick={e => checkAd(ad.ad_code)}>
                      <Grid container justify="flex-start">
                        <Grid item xs={12}>
                          <Tooltip title={ad.title} placement="top-start">
                            <Typography variant="body2" align="left" className={classes.title} noWrap>
                              {ad.title}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="caption" align="left" className={classes.title} noWrap>
                            {ad.ad_code}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container justify="space-between">
                            <Grid item>
                              {includeSampleSize ? (
                                <Button disabled size="small" className={classes.button}>
                                  <SizeIcon className={classes.icon} />
                                  {ad.sample_size || 'TBD'}
                                </Button>
                              ) : null}
                            </Grid>
                            <Grid item>
                              <Button disabled size="small" className={classes.button}>
                                <TimeIcon className={classes.icon} />
                                {ad.last_month_tested ? monthCodeToText(ad.last_month_tested) : 'TBD'}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container justify="space-between">
                            <Grid item>
                              <Checkbox
                                color="secondary"
                                checked={checked}
                                onClick={e => checkAd(ad.ad_code)}
                                className={classes.checkBox}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" color="inherit" noWrap style={{ marginTop: 15 }}>
                                Ranking Metric:
                                {ad.ranking_metric === null ? '  N/A' : `  ${this.getFormattedRankingMetric(ad)}`}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <img className={classes.brand} src={ad.links.brand} alt={ad.ad_code} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            )
          })}
        </Grid>
      </Paper>
    )
  }
}

SetBuilderGridView.propTypes = {
  classes: PropTypes.object.isRequired,
  adList: PropTypes.array.isRequired
}

export default Styled(SetBuilderGridView)
