import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import ReactPlayer from 'react-player'
import { Document, Page } from 'react-pdf'
import AsyncWrapper from './AsyncWrapper'

const styles = theme => ({
  grid: {
    flexGrow: 1
  },
  cell: {
    padding: '20px 20px',
    width: 680,
    height: 400
  },
  dialog: {
    backgroundColor: theme.palette.grey[100]
  },
  footer: {
    margin: 0,
    padding: 10,
    backgroundColor: theme.palette.background.paper
  }
})

class ViewerDialog extends Component {
  state = {
    numPages: null,
    pageNumber: 0,
    pages: [],
    playerWidth: 640,
    playerHeight: 360
  }
  
  advance = () => {
    this.setState({
      pageNumber: (this.state.pageNumber + 1) % this.state.numPages
    })
  }
  onDocumentLoad = ({ numPages }) => {
    this.setState({
      numPages
    })
  }

  onPageLoad = data => {
    const { originalWidth, originalHeight } = data
    const pdfAspectRatio = originalWidth / originalHeight
    const windowAspectRatio = window.innerWidth / window.innerHeight

    let playerHeight, playerWidth, scale
    if (windowAspectRatio > pdfAspectRatio) {
      playerHeight = Math.floor(window.innerHeight * 0.75)
      playerWidth = Math.floor(playerHeight * pdfAspectRatio)
      scale = playerHeight / originalHeight
    } else {
      playerWidth = Math.floor(window.innerWidth * 0.75)
      playerHeight = Math.floor(playerWidth / pdfAspectRatio)
      scale = originalWidth / playerWidth
    }
    console.log(
      `playerWidth: ${playerWidth}, playerHeight: ${playerHeight}, scale: ${scale}`
    )
    this.setState({
      playerWidth,
      playerHeight,
      scale
    })
  }

  render() {
    const { classes, viewer, handleClose } = this.props
    const { pageNumber , playerWidth, playerHeight } = this.state

    return (
      <Dialog maxWidth={false} open={viewer.open} onClose={handleClose}>
        <div className={classes.dialog}>
          <AppBar className={classes.grid} position="static">
            <Toolbar>
              <Grid
                className={classes.grid}
                container
                alignItems="center"
                justify="space-between"
              >
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    {`${viewer.adCode} - ${viewer.title}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={handleClose}
                    style={{ marginRight: -14 }}
                    // color="inherit"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid className={classes.grid} container justify="center">
            {viewer.ext === 'mp4' ? (
              <Grid item>
                <div className={classes.cell}>
                  <AsyncWrapper item={viewer} size={200} thickness={1.0}>
                    <ReactPlayer
                      width={playerWidth}
                      height={playerHeight}
                      url={viewer.url}
                      controls
                      playing
                    />
                  </AsyncWrapper>
                </div>
              </Grid>
            ) : (
              <AsyncWrapper item={viewer} size={200} thickness={1.0}>
                <Grid
                  item
                  style={{
                    overflow: 'scroll',
                    width: playerWidth + 40,
                    padding: 20
                  }}
                  onClick={this.advance}
                >
                  <Document
                    file={{
                      url: viewer.url
                    }}
                    onLoadSuccess={this.onDocumentLoad}
                    onRenderSuccess={this.onDocumentRender}
                    loading="Computing PDF Image Size"
                  >
                    <Page
                      pageNumber={pageNumber + 1}
                      onLoadSuccess={this.onPageLoad}
                      width={this.state.playerWidth}
                      loading={null}
                    />
                  </Document>
                </Grid>
              </AsyncWrapper>
            )}
          </Grid>
        </div>
      </Dialog>
    )
  }
}

ViewerDialog.propTypes = {
  viewer: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    adCode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ViewerDialog)
