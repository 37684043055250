import AdLister from './AdLister'
import Search from './Search'

export default Object.assign(theme => {
  return {
    metadataView: {
      marginTop: 20,
      marginBottom: 20,
      maxHeight: 700,
      // overflow: 'scroll',
      padding: 20
    },
    container: {
      // height: 625,
      marginBottom: 20
    },
    listContainer: {
      flex: '1 1 500px'
    },
    editContainer: {
      // flex: '1 1 300px'
    },
    tagContainer: {
      flex: '1 1 500px'
    },
  }
}, {
  AdLister,
  Search
})