 import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const Styled = withStyles(theme => theme.components.generic.CheckboxSelector(theme))

class CheckboxSelector extends React.PureComponent {

  handleChange = index => event => {
    const {update} = this.props
    update(index, event.target.checked)
  }

  render() {
    const { classes, title, items,disabled } = this.props

    return (
      <Grid container className={classes.container}>
        <FormControl className={classes.root}>
          <Grid item xs={12}>
            <FormLabel className={classes.title}>
              <Typography
                align="left"
                variant="subtitle1"
                color="inherit"
                noWrap
              >
                {title}
              </Typography>
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FormGroup>
                {items.map((item, index) => {
                  return (
                    <FormControlLabel
                      className={classes.row}
                      key={item.label}
                      control={
                        <Checkbox
                          checked={item.selected}
                          onChange={this.handleChange(index)}
                          value={`${item.value}`}
                          color="secondary"
                          disabled={disabled}
                        />
                      }
                      label={item.label}
                    />
                  )
                })}
              </FormGroup>
            </Paper>
          </Grid>
        </FormControl>
      </Grid>
    )
  }
}


export default Styled(CheckboxSelector)
