export default theme => {
  return {
    container: {
      height: '100%',
    },
    h0: {
      backgroundColor: theme.palette.grey[200],
      height: '100%',
      textAlign: 'center',
      paddingLeft: 10
    },
    d0: {
      marginTop: 5,
      marginBottom: 5,
      paddingLeft: 15,
      paddingRight: 5
    },
    metricsLabelText: {
      padding: 2
    }
  }
}
