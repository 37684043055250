export default {
  '***': {
    '***': [
      {
        "id": 100,
        "label": "Brand Image",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          105,
          110,
          115,
          120
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 200,
        "label": "Creative Type",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          205,
          210,
          215,
          220
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 300,
        "label": "Creative Style",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          305,
          310,
          315,
          320,
          325,
          330,
          335,
          340
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 400,
        "label": "Promotion",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          405,
          410,
          415,
          420
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 500,
        "label": "Product",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          505,
          510,
          512,
          515,
          520,
          525
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 105,
        "label": "Awards/Accolades",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Refers to any reference of awards or rankings, 3rd party or commissioned research based."
      },
      {
        "id": 110,
        "label": "Customer Service",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Refers to a primary focus on customer service"
      },
      {
        "id": 115,
        "label": "General Reputation",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Refers to any ad that focuses on a brand's general reputation, but does not mention any products"
      },
      {
        "id": 120,
        "label": "Sponsorship",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 205,
        "label": "Narrative",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad follows a story trajectory, even if short"
      },
      {
        "id": 210,
        "label": "Vignette",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad does not have a story, but rather many different scenes cut together"
      },
      {
        "id": 215,
        "label": "Animated",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 220,
        "label": "Graphics/Product Images Only",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad is a computer generated ad usually focusing on a product with few or no actual filmed scenes"
      },
      {
        "id": 305,
        "label": "Humorous",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 310,
        "label": "Emotional",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 315,
        "label": "Family Focus (e.g. kids, families, etc.)",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Includes any ads focusing on families or children. Does not include couples only"
      },
      {
        "id": 320,
        "label": "Older Focus (e.g. older adults)",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Includes any ads with a particular focus on adults 60+"
      },
      {
        "id": 325,
        "label": "Spokesperson",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Includes both celebrity and non-celebrity spokespeople"
      },
      {
        "id": 330,
        "label": "Celebrity",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Includes any celebrity inclusion, whether or not that person is a spokesperson"
      },
      {
        "id": 335,
        "label": "Music (jingle)",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Refers to a consistent jingle associated with a brand, not just background music"
      },
      {
        "id": 340,
        "label": "Branding in first 3 seconds",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 405,
        "label": "New menu item",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": "New flavor/line extension OR completely new item"
      },
      {
        "id": 410,
        "label": "Dollar amount mentioned",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": "Dollar Amount mentioned in ad, \"A buck\", $2, $2, $123, $10 etc."
      },
      {
        "id": 415,
        "label": "Multi-product",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": "A line of products or multiple items promoted"
      },
      {
        "id": 420,
        "label": "Limited promotion period",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": "Has an end date (or says limited time) to an offer, or a product's availability"
      },
      {
        "id": 505,
        "label": "QSR",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": "Ad is advertising a Quick Service Restaurant"
      },
      {
        "id": 510,
        "label": "Fast casual",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": "Ad is advertising a Fast Casual Restaurant"
      },
      {
        "id": 512,
        "label": "Casual dining",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 515,
        "label": "Beverage only",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": "Mentions on a beverage, not a food item"
      },
      {
        "id": 520,
        "label": "Breakfast food",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": "Focused on a breakfast offering, even if \"all day\""
      },
      {
        "id": 525,
        "label": "Health food / Healthy alternative",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": "Is clean or healthy food message incorporated?"
      }
    ]
  }
}
