import ScorecardHeader from './ScorecardHeader'
import ScorecardFooter from './ScorecardFooter'
import ScorecardScroller from './ScorecardScroller'
import ScorecardLegend from './ScorecardLegend'
import ScorecardExport from './ScorecardExport'
import ScorecardFilter from './ScorecardFilter'

let ScorecardView = theme => {
  return {
    container: {
      margin: 25
    },
    boxShadow: {
      border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
      boxShadow: `0 1px 1px #E7E7E7`
    }
  }
}

export default Object.assign(ScorecardView, {
  ScorecardHeader,
  ScorecardFooter,
  ScorecardScroller,
  ScorecardLegend,
  ScorecardExport,
  ScorecardFilter
})
