const ADMIN_INIT = 'ADMIN_INIT'


const ADMIN_SELECT_AD = 'ADMIN_SELECT_AD'
const ADMIN_TOGGLE_AD_TAG = 'ADMIN_TOGGLE_AD_TAG'
const ADMIN_UPDATE_METADATA_FIELD = 'ADMIN_UPDATE_METADATA_FIELD'
const ADMIN_METADATA_OPEN_SEARCH = 'ADMIN_METADATA_OPEN_SEARCH'
const ADMIN_METADATA_UPDATE_ADS_LIST = 'ADMIN_METADATA_UPDATE_ADS_LIST'
const ADMIN_METADATA_UPDATE_RANGE = 'ADMIN_METADATA_UPDATE_RANGE'
const ADMIN_METADATA_TOGGLE_BRAND = 'ADMIN_METADATA_TOGGLE_BRAND'
const ADMIN_METADATA_TOGGLE_ALL_BRANDS = 'ADMIN_METADATA_TOGGLE_ALL_BRANDS'
const ADMIN_METADATA_TOGGLE_AD_TAG = 'ADMIN_METADATA_TOGGLE_AD_TAG'
const ADMIN_METADATA_TOGGLE_UNTAGGED_ONLY = 'ADMIN_METADATA_TOGGLE_UNTAGGED_ONLY'
const ADMIN_METADATA_UPDATE_SELECTED_ADS = 'ADMIN_METADATA_UPDATE_SELECTED_ADS'
const ADMIN_UPDATE_USER_MATCH = 'ADMIN_UPDATE_USER_MATCH'
const ADMIN_UPDATE_SELECTED_USER = 'ADMIN_UPDATE_SELECTED_USER'
const ADMIN_TOGGLE_INCLUDE_APP_USERS = 'ADMIN_TOGGLE_INCLUDE_APP_USERS'
const ADMIN_TOGGLE_INCLUDE_INACTIVE_USERS = 'ADMIN_TOGGLE_INCLUDE_INACTIVE_USERS'


export {
    ADMIN_INIT,
    ADMIN_SELECT_AD,
    ADMIN_TOGGLE_AD_TAG,
    ADMIN_UPDATE_METADATA_FIELD,
    ADMIN_METADATA_OPEN_SEARCH,
    ADMIN_METADATA_UPDATE_ADS_LIST,
    ADMIN_METADATA_UPDATE_RANGE,
    ADMIN_METADATA_TOGGLE_BRAND,
    ADMIN_METADATA_TOGGLE_ALL_BRANDS,
    ADMIN_METADATA_TOGGLE_AD_TAG,
    ADMIN_METADATA_TOGGLE_UNTAGGED_ONLY,
    ADMIN_METADATA_UPDATE_SELECTED_ADS,
    ADMIN_UPDATE_SELECTED_USER,
    ADMIN_UPDATE_USER_MATCH,
    ADMIN_TOGGLE_INCLUDE_APP_USERS,
    ADMIN_TOGGLE_INCLUDE_INACTIVE_USERS
}