export default {
  slices: [
    {
      field: 'ad_categories',
      display: 'Offer Category',
      // variant: 'multigroup',
      map: {
        'internet': {
          display: 'Internet Only',
          sortOrder: 1
        },
        'mobile': {
          display: 'Mobile Only',
          sortOrder: 2,
        }
      }
    },
    {
      field: 'ad_type',
      display: 'Type',
      hidden: true,
      map: {
        'offer': {
          display: 'Offer',
          sortOrder: 1
        }
      }
    },
  ]
}
