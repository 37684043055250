import {
  APP_SET_AUTO_CONFIG,
  USER_RESOURCES_RECEIVE,
  APP_CLR_CONFIG,
  APP_SET_MESSAGE,
  APP_CLR_MESSAGE,
  DASHBOARD_INIT,
  DASHBOARD_RECEIVE_METADATA
} from '../../new_actions'

import {
  FORD_META_DATA_RECEIVE
} from '../../app/Auto/actions'

const initialState = {
  start: false,
  ready: false,
  message: {
    text: null,
    type: null
  },
  software: {
    clienVersion: null,
    serverVersion: null
  },
  config: {}
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_RESOURCES_RECEIVE:
      return {
        ...state,
        environment: action.data.environment,
        start: true
      }
    case APP_SET_AUTO_CONFIG:
      return {
        ...state,
        ready: state.start,
        config: action.data
      }

    case APP_SET_MESSAGE:
      return {
        ...state,
        message: action.data
      }

    case DASHBOARD_RECEIVE_METADATA:
    case FORD_META_DATA_RECEIVE:
      return {
        ...state,
        software: action.data.software
      }
    case APP_CLR_MESSAGE:
      return {
        ...state,
        message: {
          text: null,
          type: null
        }
      }
    case APP_CLR_CONFIG:
      return {
        ...state,
        config: {},
        ready: false
      }
    case DASHBOARD_INIT:
      return {
        ...state,
        error: null
      }
    default:
      return state
  }
}
