export default theme => {
  return {
    container: {
      height: '100%',
      fontFamily: 'Roboto',
      fontSize: 11,
      backgroundColor: theme.palette.grey[100],
      borderBottom: `1px solid ${theme.palette.grey[400]}`
    },
    top: {
      marginTop: 62
    },
    headerGrid: {
      height: 20
    },
    center: {
      display: 'block',
      width: 'max-content',
      paddingTop: 2
    },
    icon: {
      fontSize: 14,
      marginRight: 4,
      marginBottom: -3,
      color: theme.palette.grey[600]
    },
    headerMetric: {
      fontWeight: 'bold'
    }
  }
}
