import { grey } from '@material-ui/core/colors'
export default {
  type: 'light',
  primary: {
    light: '#ffffff',
    main: '#ffffff',
    dark: '#ffffff',
    contrastText: '#ffffff'
  },
  secondary: {
    light: '#ffa740',
    main: '#6DCFF6',
    dark: '#3CB5E6',
    contrastText: '#ffffff'
  },
  error: {
    main: '#c62828',
    light: '#ff5f52',
    dark: '#8e0000',
    contrastText: '#ffffff'  
  },
  warning: {
    main: '#902e00',
    light: '#c75c2d',
    dark: '#5d0000',
    contrastText: '#ffffff'  
  },
  text: {
    primary: '#444444'
  },
  info: {
    main: '#0578bc',
    light: '#59a7ef',
    dark: '#004d8b',
    contrastText: '#ffffff'  
  },
  highlight: {
    main: '#c62828',
    light: '#ff5f52',
    dark: '#8e0000',
    contrastText: '#ffffff'   
  },
  graphs: {
    default : '#FF872F'
  },
  background: {
    default: '#FAF9FA'
  },
  listSelectBackground: {
    default: '#daf3fd'
  },
  trendchartBox: {
    default: '#B6E7FA'
  },
  action: {
    hover:  '#daf3fd',
    selected: '#fff'
  },
  grey : {
    50: grey[50],
    100: grey[100],
    200: grey[200],
    300: grey[300],
    400: grey[400],
    500: grey[500],
    600: grey[600],
    700: grey[700],
    800: grey[800],
    900: grey[900],
  }
}
