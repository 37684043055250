export const APP_SET_AUTO_CONFIG = 'APP_SET_AUTO_CONFIG'
// export const APP_SET_FIDELITY_CONFIG = 'APP_SET_FIDELTY_CONFIG'
// export const APP_SET_COMCAST_CONFIG = 'APP_SET_COMCAST_CONFIG'
export const APP_CLR_CONFIG = 'APP_CLR_CONFIG'
export const APP_SET_MESSAGE = 'APP_SET_MESSAGE'
export const APP_CLR_MESSAGE = 'APP_CLR_MESSAGE'

// const configTypes = {
//   'ford-us': APP_SET_AUTO_CONFIG,
//   'ford-us-tier2': APP_SET_AUTO_CONFIG,
//   'ford-lincoln': APP_SET_AUTO_CONFIG,
//   'ford-ca': APP_SET_AUTO_CONFIG,
//   'ford-ap': APP_SET_AUTO_CONFIG,
//   'ford-eu': APP_SET_AUTO_CONFIG,
//   'ford-mx': APP_SET_AUTO_CONFIG,
//   nissan: APP_SET_AUTO_CONFIG,
//   'ford-ca-lincoln': APP_SET_AUTO_CONFIG,
//   'auto-demo': APP_SET_AUTO_CONFIG,
//   // fidelity: APP_SET_FIDELITY_CONFIG,
//   // 'investor-services-demo': APP_SET_FIDELITY_CONFIG,
//   // comcast: APP_SET_COMCAST_CONFIG
// }

const appSetConfig = config => {
  // axios.defaults.headers['Group-Name-Header'] = groupName
  // axios.defaults.headers['Cache-Control'] = 'no-store'
  // axios.defaults.baseURL = `/${baseEndpoint}/${groupName}`

  return dispatch => Promise.resolve( dispatch({
    type: APP_SET_AUTO_CONFIG,
    data: config
  }))
}

const appClrConfig = () => {
  return {
    type: APP_CLR_CONFIG
  }
}

const appSetError = data => {
  return {
    type: APP_SET_MESSAGE,
    data: {
      text: data,
      type: 'error'
    }
  }
}

const appSetWarning = data => {
  return {
    type: APP_SET_MESSAGE,
    data: {
      text: data,
      type: 'warning'
    }
  }
}

const appSetInfo = data => {
  return {
    type: APP_SET_MESSAGE,
    data: {
      text: data,
      type: 'info'
    }
  }
}

const appClrError = () => {
  return {
    type: APP_CLR_MESSAGE
  }
}

const appClrWarning = () => {
  return {
    type: APP_CLR_MESSAGE
  }
}

const appClrInfo = () => {
  return {
    type: APP_CLR_MESSAGE
  }
}

export { appSetConfig, appClrConfig, appSetError, appClrError, appSetWarning, appClrWarning, appSetInfo, appClrInfo }
