import scorecardMainHeader from './ScorecardMainHeader'
import scorecardAdHeader from './ScorecardAdHeader'
import scorecardNormHeader from './ScorecardNormHeader'
import scorecardMetricsLabels from './ScorecardMetricsLabels'
import scorecardNormValues from './ScorecardNormValues'
import scorecardMetricsValues from './ScorecardMetricsValues'

export default {
  dimensions: {
    rowHeight: 30,
    headerHeight: 285,
    headerHeightCollapsed: 150,
    metricsLabelsWidth: 350,
    normWidth: 100,
    metricWidth: 120
  },
  scorecardMainHeader,
  scorecardNormHeader,
  scorecardAdHeader,
  scorecardMetricsLabels,
  scorecardNormValues,
  scorecardMetricsValues
}
