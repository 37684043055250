// const getAdpiStd = (metric, norm) => {
//   const ws = metric.n / norm.n
//   const nk = norm.n - metric.n
//   const xk = (norm.avg - metric.avg * ws) / (1 - ws)
//   const xt_minus_xs = norm.avg - metric.avg
//   const xt_minus_xk = norm.avg - xk
//   const sx = Math.sqrt((norm.std * norm.std * norm.n - metric.std * metric.std * metric.n - xt_minus_xs * xt_minus_xs * metric.n - xt_minus_xk * xt_minus_xk * nk) / nk)
//   const value = metric.std
//   return (1 - ws) * Math.sqrt(
//     value * value / metric.n +
//     sx * sx / nk
//   )
// }

const getStd = (metric, norm, useUnweighted) => {
  const normn = useUnweighted ? norm.n_unweighted : norm.n
  const metricn = useUnweighted ? metric.n_unweighted : metric.n
  const ws = metricn / normn
  const nk = normn - metricn
  const xk = (norm.avg - metric.avg * ws) / (1 - ws)
  const xt_minus_xs = norm.avg - metric.avg
  const xt_minus_xk = norm.avg - xk
  const sx = Math.sqrt((norm.std * norm.std * normn - metric.std * metric.std * metricn - xt_minus_xs * xt_minus_xs * metricn - xt_minus_xk * xt_minus_xk * nk) / nk)
  const value = metric.std
  return (1 - ws) * Math.sqrt(
    (value * value) / metricn + (sx * sx) / nk
  )
}


const getPTestStd = (metric, norm, useUnweighted) => {
  const metricn = useUnweighted ? metric.n_unweighted : metric.n
  const normn = useUnweighted ? norm.n_unweighted : norm.n
  const ws = metricn / normn
  const nk = normn - metricn
  const gk = (norm.avg - ws * metric.avg) / (1 - ws)
  const sek =  gk * (1 - gk) / nk
  const ses =  metric.avg * (1 - metric.avg) / metricn
  const std =  (1 - ws) * Math.sqrt( sek + ses)
  // const z = (norm.avg - metric.avg ) / std
  return std
}

const getpWTestStd = (metric, norm, useUnweighted) => {
  const sampleSize = useUnweighted ? metric.n_unweighted : metric.n
  const normSampleSize = useUnweighted ? norm.n_unweighted : norm.n
  const value = metric.avg
  const normValue = norm.avg
  return Math.sqrt(
    (value * (1 - value)) / sampleSize +
    (normValue * (1 - normValue)) / normSampleSize
  )
}

const compareFixedThreshold = (metric, threshold) => {
  return metric.avg < threshold[0] ? -Infinity : metric.avg >= threshold[1] ? +Infinity : 0
}

const stdTests = {
  mean: getStd,
  ratio: getPTestStd,
  wRatio: getpWTestStd,
}

const getNormalizedStat = (metric, norm, statStruct, stdTest) => {
  const {
    useUnweightedSampleSize,
    thresholdConstant,
    useDeNormalized,
    fixedThrTestThresholds
  } = statStruct
  // const std = stdTests[stdTest]
  //   ? stdTests[stdTest](metric, norm, useUnweightedSampleSize)
  //   : 1e12

  const std = stdTest === 'fixedThr' ? null : stdTests[stdTest](metric, norm, useUnweightedSampleSize)

  const normalizedStat = stdTest === 'fixedThr' ? compareFixedThreshold(metric, fixedThrTestThresholds) : (metric.avg - norm.avg) / std / thresholdConstant
  const diff = metric.avg - norm.avg
  
  return {
    normalizedStat: useDeNormalized ? diff : normalizedStat,
    isSignificant: useDeNormalized ? Math.abs(normalizedStat) >= 1 : !isNaN(normalizedStat),
    diff,
    std
  }
}
const getStatTest = (
  metric,
  norm,
  statStruct,
  metricStdTest,
  formatType,
  reverseStat = false
) => {
  const {
    thresholds,
    labels,
    undeterminedStatColor
  } = statStruct

  if (metric === null) return {}
  if (!norm.avg && metricStdTest !== 'fixedThr')
    return {
      color: undeterminedStatColor || '#e0e0e0'
    }
  // const stdTest = metricStdTest || statStruct.stdTests[formatType] || 'mean'
  const stdTest =  metricStdTest || statStruct.statTests[formatType] || 'mean'
  const { normalizedStat, isSignificant, diff, std, mean } = getNormalizedStat(
    metric,
    norm,
    statStruct,
    stdTest
  )

  const thresholdValue = thresholds.find(
    threshold => threshold > Math.abs(normalizedStat)
  )
  // let index
  // if (useDeNormalized) {
  const index = isSignificant
      ? thresholdValue
        ? thresholds.indexOf(thresholdValue) - 1
        : thresholds.length - 1
      : -1
  // } else {
  //   index = thresholdValue
  //     ? thresholds.indexOf(thresholdValue) - 1
  //     : thresholds.length - 1
  // }

  return {
    normalizedStat,
    diff,
    std,
    mean,
    index,
    color:
      index < 0
        ? null
        : normalizedStat * (reverseStat ? -1 : 1) > 0
          ? labels[index].high.color
          : labels[index].low.color,
    textColor:
      index < 0
        ? null
        : normalizedStat * (reverseStat ? -1 : 1) > 0
          ? labels[index].high.textColor
          : labels[index].low.textColor
  }
}

export { getStd, getPTestStd, getNormalizedStat }

export default getStatTest
