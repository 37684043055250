import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Input from '@material-ui/core/Input'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'

const styles = theme => ({
  control: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  paper: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    marginTop: 10,
    marginBottom: 20,
    border: `1px solid ${theme.palette.background.default}`,
    boxShadow: `0 1px 1px rgba(0,0,0,0.14)`
  },
  activeType: {
    '&:hover': {
      backgroundColor: theme.palette.listSelectBackground.default,
    }
  }
})
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 225,
      padding: 0,
    },
  }
}
class BulkFilters extends Component {

  componentDidMount() {
    const { elements } = this.props
    const values = elements.reduce((obj, element) => {
      obj[element.field] = element.options.reduce((array, option, optionIndex) => {
        if (option.selected) array.push(optionIndex)
        return array
      }, [])
      return obj
    }, {})


    this.setState({
      values
    })
  }

  render() {
    const { classes, update, elements } = this.props

    return (
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={0} alignItems="stretch">
          {elements.map((filter, filterIndex) => {
            const { label, options, selections } = filter
            const numItems = selections.length
            return (
              <Grid key={label} item xs className={classes.control}>
                <FormControl fullWidth>
                  <InputLabel style={{ fontSize: 14, paddingLeft: 5 }} htmlFor={`id-for-${label}-bulk-filter`}>
                    {label}
                  </InputLabel>
                  <Select MenuProps={MenuProps} multiple={true} value={selections}
                    input={<Input disableUnderline id={`id-for-${label}-dynamic-filter`} />}
                    renderValue={() => `${numItems} item${numItems > 1 ? 's' : ''}`}
                    onChange={event => update({value:event.target.value, filterIndex})}
                  >

                    {options.map((option, optionIndex) => {
                      const { label } = option
                      return (
                        <MenuItem key={label} dense value={optionIndex} className={classes.activeType}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              color="secondary"
                              checked={selections.includes(optionIndex)}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={label} />
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )
          })}
        </Grid>
      </Paper>
    )
  }
}

BulkFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  scorecard: PropTypes.shape({
    textHeader: PropTypes.array.isRequired,
    textTable: PropTypes.array.isRequired,
    normHeader: PropTypes.array.isRequired,
    normTable: PropTypes.array.isRequired,
    mainHeader: PropTypes.array.isRequired,
    mainTable: PropTypes.array.isRequired
  })
}

export default withStyles(styles)(BulkFilters)
