const DASHBOARD_SELECT_PRESET = 'DASHBOARD_SELECT_PRESET'
const DASHBOARD_CLEAR_PRESET = 'DASHBOARD_CLEAR_PRESET'
const DASHBOARD_CREATE_PRESET = 'DASHBOARD_CREATE_PRESET'

const dashboardSelectPreset = data => {
    return {
        type: DASHBOARD_SELECT_PRESET,
        data
    }
}
const dashboardClearPreset = data => {
    return {
        type: DASHBOARD_CLEAR_PRESET,
        data
    }
}

const dashboardCreatePreset = data => {
    return {
        type: DASHBOARD_CREATE_PRESET,
        data
    }
}
export { DASHBOARD_SELECT_PRESET, DASHBOARD_CLEAR_PRESET, DASHBOARD_CREATE_PRESET }
export { dashboardSelectPreset, dashboardClearPreset, dashboardCreatePreset }