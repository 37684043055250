export default {
    "h0": {
        variant: "subtitle1",
        rowHeight: 30
    },
    "hb0": {
        variant: "subtitle1",
        rowHeight: 25
    },
    "demphasized": {
        variant: "subtitle1",
        rowHeight: 40
    },
    "dh0": {
        variant: "subtitle1",
        rowHeight: 30
    },
    "d0": {
        variant: "body2",
        rowHeight: 30
    },
    "d1": {
        variant: "body2",
        rowHeight: 30
    },
    "hidden": {
        rowHeight: 0
    },
    "eol": {
        variant: "caption",
        rowHeight: 20
    }
}