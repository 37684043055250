import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import TVIcon from '@material-ui/icons/Tv'
import RadioIcon from '@material-ui/icons/Radio'
import OnlineVideoIcon from '@material-ui/icons/PlayCircleOutline'
import PrintIcon from '@material-ui/icons/Description'
import OnlineDisplayIcon from '@material-ui/icons/Computer'
import AllTelecomIcon from '@material-ui/icons/ViewComfy'
import InternetIcon from '@material-ui/icons/Wifi'
import VideoTvAndOttIcon from '@material-ui/icons/LiveTv'
import VideoTvOnlyIcon from '@material-ui/icons/Tv'
import VideoOttOnlyIcon from '@material-ui/icons/OndemandVideo'
import HomeSecurityIcon from '@material-ui/icons/Security'
import SmartPhone from '@material-ui/icons/Smartphone'
import InternetAndMobileIcon from '@material-ui/icons/Phonelink'
import InternetAndTvIcon from '@material-ui/icons/Cast'
import InternetAndVideoIcon from '@material-ui/icons/CastConnected'
import DevicesOther from '@material-ui/icons/DevicesOther'
import GeneralMarketIcon from '@material-ui/icons/SelectAll'
import HispanicMarketIcon from '@material-ui/icons/Language'
import GenPopTargetIcon from '@material-ui/icons/Accessibility'
import ElitesTargetIcon from '@material-ui/icons/PermIdentity'
import ProspectsTargetIcon from '@material-ui/icons/RecordVoiceOver'
import AllAdsIcon from '@material-ui/icons/Dashboard'
import ConnectivityIcon from '@material-ui/icons/Wifi'
import PhoneIcon from '@material-ui/icons/Phone'
import SecurityServicesIcon from '@material-ui/icons/Lock'
import OtherCommIcon from '@material-ui/icons/Backup'
import MiscIcon from '@material-ui/icons/Work'
import ConnectivityMobileIcon from '@material-ui/icons/TapAndPlay'
import ConnectivitySecurityIcon from '@material-ui/icons/ScreenLockPortrait'
import ConnectivityPhoneIcon from '@material-ui/icons/PhoneInTalk'
import ConnectivityOtherCommIcon from '@material-ui/icons/SettingsSystemDaydream'
import AnyCommIcon from '@material-ui/icons/Devices'
import PhoneVoipMobile from '@material-ui/icons/AddIcCall'
import ConnectivityAll from '@material-ui/icons/PhoneInTalk'
import FootballIcon from '@material-ui/icons/SportsFootball'
import PersonIcon from '@material-ui/icons/Person'
import Restaurant from '@material-ui/icons/Restaurant'
import Fastfood from '@material-ui/icons/Fastfood'
import CasualDining from '@material-ui/icons/EventSeat'
import OfferIcon from '@material-ui/icons/LibraryBooks'
import IPhone from '@material-ui/icons/PhoneIphone'
import USIcon from '@material-ui/icons/ViewComfy'
import UKIcon from '@material-ui/icons/ViewQuilt'
import FRIcon from '@material-ui/icons/ViewColumn'
import JPIcon from '@material-ui/icons/FiberManualRecord'

import { Laptop, Watch, SettingsRemote, Cast, AccessTime, Done } from '@material-ui/icons'

import GenericIcon from '@material-ui/icons/Inbox'
const icons = {
  tv: TVIcon,
  offer: OfferIcon,
  print: PrintIcon,
  radio: RadioIcon,
  online_video: OnlineVideoIcon,
  online_display: OnlineDisplayIcon,
  all_telecom: AllTelecomIcon,
  internet: InternetIcon,
  video_tv_ott: VideoTvAndOttIcon,
  video_tv: VideoTvOnlyIcon,
  video_ott: VideoOttOnlyIcon,
  home_security: HomeSecurityIcon,
  mobile: IPhone,
  internet_mobile: InternetAndMobileIcon,
  internet_tv: InternetAndTvIcon,
  internet_video: InternetAndVideoIcon,
  internet_tv_mobile: DevicesOther,
  hispanic: HispanicMarketIcon,
  general: GeneralMarketIcon,
  market_smb: GeneralMarketIcon,
  market_mm_ent: HispanicMarketIcon,
  elites: ElitesTargetIcon,
  gen_pop: GenPopTargetIcon,
  prospects: ProspectsTargetIcon,
  all_ads: AllAdsIcon,
  connectivity: ConnectivityIcon,
  phone: PhoneIcon,
  security_services: SecurityServicesIcon,
  other_comm: OtherCommIcon,
  misc: MiscIcon,
  connectivity_mobile: ConnectivityMobileIcon,
  connectivity_security: ConnectivitySecurityIcon,
  connectivity_phone: ConnectivityPhoneIcon,
  connectivity_other_comm: ConnectivityOtherCommIcon,
  any_comm: AnyCommIcon,
  phone_voip: PhoneIcon,
  cybersecurity: SecurityServicesIcon,
  managed_svc: OtherCommIcon,
  other_svc: MiscIcon,
  phone_voip_mobile: PhoneVoipMobile,
  connectivity_all: ConnectivityAll,
  connectivity_managed_svc: ConnectivityOtherCommIcon,
  smart_phones: SmartPhone,
  computers: Laptop,
  wearables: Watch,
  connected_home: SettingsRemote,
  all_tech: DevicesOther,
  other_tech: Cast,
  all_plans: SmartPhone,
  post_paid_plans: AccessTime,
  pre_paid_plans: Done,
  superbowl: FootballIcon,
  superbowl_56: FootballIcon,
  black_consumers: PersonIcon,
  all_restaurants: Restaurant,
  qsr_fast_dining: Fastfood,
  casual_dining: CasualDining,
  internet_video_mobile_home: AllTelecomIcon,
  internet_video_home: SettingsRemote,
  internet_video_mobile: DevicesOther,
  us: USIcon,
  uk: UKIcon,
  jp: JPIcon,
  fr: FRIcon,
}

const Styled = withStyles(theme => theme.components.generic.Icon(theme))

export default Styled(props => {
  const { type, classes, size, selected } = props
  const Icon = icons[type] || GenericIcon

  return selected ? <Icon className={cn(classes[size])} style={{color: '#ffffff'}}/> : <Icon className={cn(classes[size])}/>
})
