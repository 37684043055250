export default theme => {
  return {
    card: {
      width: 300
    },
    selected: {
      border: `1px solid ${theme.palette.grey[600]}`
    },
    cardContent: {
      padding: '0 10px 0 10px !important'
    },
    media: {
      height: 200,
      margin: 10,
      boxShadow: 8,
      borderRadius: 5
    },
    title: {
      marginBottom: 20,
      fontSize: 14
    },
    boxShadow: {
      border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
      boxShadow: `0 1px 1px rgba(0,0,0,0.14)`
    }
  }
}
