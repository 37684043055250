export default {
  columnLabels: {
    view: 'View',
    title: 'Title',
    mediaType: 'Media Type',
    dateCreated: 'Date Created',
    type: 'Type',
    scorecard: 'Scorecard',
    build: 'Build',
    delete: 'Delete'
  }
}
