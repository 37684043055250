import templateConfig from '../Template/config'
import templateTheme from '../Template/theme'
import customConfig from './config'
import customTheme from './theme'

const config = {
    ...templateConfig,
    ...customConfig
}

const theme = {
    ...templateTheme,
    ...customTheme
}

export { config }
export { theme }

export default {
  config,
  theme
}
