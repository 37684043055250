import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { monthCodeToText, format } from '../../../../new_utils'
const Styled = withStyles(theme =>theme.components.selection.SetBuilder.SetBuilderListView(theme))

class SetBuilderListView extends React.PureComponent {
  state = {
    checked: false,
    indeterminate: false
  }

  componentDidUpdate(prevProps, prevState) {
    const { adList, checkedAds } = this.props
    if (checkedAds.length !== prevProps.checkedAds.length || adList.length !== prevProps.adList.length) {
      const adCodes = adList.map(ad => ad.ad_code)
      const allChecked = adCodes.reduce((flag, ad_code) => flag && checkedAds.includes(ad_code), true)
      const someChecked = adCodes.reduce((flag, ad_code) => flag || checkedAds.includes(ad_code), false)
      this.setState({
        checked: adList.length && checkedAds.length && allChecked,
        indeterminate: !!adList.length && !!checkedAds.length && someChecked && !allChecked
      })
    }
  }

  getFormattedRankingMetric = ad => {
    return format.getFormat(ad.ranking_metric_format).formatter(ad.ranking_metric)
  }

  render = () => {
    const {
      classes,
      adList,
      tableHeight,
      checkAd,
      checkAllAds,
      checkedAds,
      openMedia,
      includeSampleSize,
      config
    } = this.props

    const { indeterminate, checked } = this.state

    return (
      <Paper style={{ height: tableHeight, overflow: 'auto' }} className={classes.container} elevation={0} classes={{elevation0: classes.boxShadow}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.checkCell} classes={{ root: classes.headerRoot }}>
                <Checkbox checked={!!checked} indeterminate={indeterminate} onClick={checkAllAds} color="secondary"/>
              </TableCell>
              <TableCell className={classes.brandCell} classes={{ root: classes.headerRoot }}>
                {config.columnLabels.brand}
              </TableCell>
              <TableCell classes={{ root: classes.headerRoot }}>{config.columnLabels.title}</TableCell>
              <TableCell className={classes.center} classes={{ root: classes.headerRoot }}>
                {config.columnLabels.lastMonthTested}
              </TableCell>

              {includeSampleSize ? <TableCell>{config.columnLabels.sampleSize}</TableCell> : null}
              <TableCell classes={{ root: classes.headerRoot }}>{config.columnLabels.rankingMetric}</TableCell>
              <TableCell classes={{ root: classes.headerRoot }}>{config.columnLabels.preview}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {adList.map(ad => {
              const { ad_code, title, links } = ad
              const checked = checkedAds.includes(ad.ad_code)

              return (
                <TableRow key={ad_code} classes={{ root: classes.activeType}}>
                  <TableCell className={classes.checkCell} classes={{ root: classes.bodyRoot }}>
                    <Checkbox checked={checked}  onClick={e => checkAd(ad.ad_code)} color="secondary"/>
                  </TableCell>
                  <TableCell
                    className={classes.brandCell}
                    onClick={e => checkAd(ad.ad_code)}
                    classes={{ root: classes.bodyRoot }}
                  >
                    <img style={{ height: 25 }} src={links.brand} alt={ad_code} />
                  </TableCell>
                  <TableCell onClick={e => checkAd(ad.ad_code)} classes={{ root: classes.bodyRoot }}>
                    <Tooltip title={title} placement="top-start">
                      <Typography style={{ width: 150 }} variant="subtitle1" color="inherit" noWrap>
                        {title}
                      </Typography>
                    </Tooltip>
                    <Typography variant="caption" color="inherit" noWrap>
                      {ad_code}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.center}
                    onClick={e => checkAd(ad.ad_code)}
                    classes={{ root: classes.bodyRoot }}
                  >
                    <Typography variant="body2" color="inherit" noWrap>
                      {monthCodeToText(ad.last_month_tested) || 'N/A'}
                    </Typography>
                  </TableCell>
                  {includeSampleSize ? (
                    <TableCell
                      className={classes.center}
                      onClick={e => checkAd(ad.ad_code)}
                      classes={{ root: classes.bodyRoot }}
                    >
                      <Typography variant="body2" color="inherit" noWrap>
                        {ad.sample_size}
                      </Typography>
                    </TableCell>
                  ) : null}
                  <TableCell
                    className={classes.center}
                    onClick={e => checkAd(ad.ad_code)}
                    classes={{ root: classes.bodyRoot }}
                  >
                    <Typography variant="body2" color="inherit" noWrap>
                      {ad.ranking_metric === null
                        ? 'N/A'
                        : ad.ranking_metric_n
                          ? this.getFormattedRankingMetric(ad)
                          : ''}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.center}
                    onClick={e => {
                      e.preventDefault()
                      openMedia(ad)
                    }}
                    style={{ padding: '4px 0px 4px 12px' }}
                    classes={{ root: classes.bodyRoot }}
                  >
                    <div
                      className={classes.media}
                      style={{
                        backgroundImage: `url(${links.thumbnail})`
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

SetBuilderListView.defaultProps = {
  adList: [],
  checks: {}
}
export default Styled(SetBuilderListView)
