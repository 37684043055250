// import QuickviewContainer from './QuickviewContainer'
// import QuickviewCanvasContainer from './QuickviewCanvasContainer'
// import QuickviewHeader from './QuickviewHeader'
// import QuickviewCanvasHeader from './QuickviewCanvasHeader'
// import MetricDonut from './MetricDonut'
// import MetricChart from './MetricChart'
// import MetricTable from './MetricTable'
// import MetricOpenend from './MetricOpenend'

import  quickview  from '../../../../Template/theme/components/quickview'
export default Object.assign( quickview,
  {
    // MetricTable
  }
)
