import templateScorecardView from '../../../../../Template/config/components/scorecard/ScorecardView'
import scorecardStatTesting from './ScorecardStatTesting'
import scorecardScroller from './ScorecardScroller'
import scorecardExport from './ScorecardExport'
import scorecardFilter from './ScorecardFilter'
const scorecardView = {
  ...templateScorecardView,
  heights: {
    marginHeight: 50,
    navbarHeight: 64,
    headerHeight: 70,
    filterHeight: 80,
    footerHeight: 65,
    legendHeight: 130,
  },
  collapsed: true,
  scorecardExport,
  scorecardScroller,
  scorecardStatTesting,
  scorecardFilter
}

export default scorecardView
