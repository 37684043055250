import templateConfig from '../../Template/config'

import main from './main'
import components from './components'

const config = {
  ...templateConfig,
  main,
  components,
}

export {config}
export default config
