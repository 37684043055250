import React from 'react'
import { Auth, Logger, Hub } from 'aws-amplify'
import AuthPiece from './AuthPiece'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import StyledContainer from './StyledContainer'

const logger = new Logger('Greetings')

const styles = theme => ({
  submitButton: {
    width: '100%',
    minWidth: 'unset',
  },
})

class Greetings extends AuthPiece {
  constructor(props) {
    super(props)

    this.signOut = this.signOut.bind(this)
    this.checkUser = this.checkUser.bind(this)
    this.onHubCapsule = this.onHubCapsule.bind(this)

    this.state = {
      authState: props.authState,
      authData: props.authData,
    }

    Hub.listen('auth', this)
  }

  componentDidMount() {
    this._isMounted = true
    this.checkUser()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  signOut() {
    Auth.signOut()
      .then(() => this.changeState('signedOut'))
      .catch(err => {
        logger.error(err)
        this.error(err)
      })
  }

  checkUser() {
    const that = this
    const { authState } = this.state

    return Auth.currentAuthenticatedUser()
      .then(user => {
        if (!that._isMounted) {
          return
        }

        if (authState !== 'signedIn') {
          this.setState({
            authState: 'signedIn',
            authData: user,
          })

          this.changeState('signedIn', user)
        }
      })
      .catch(err => {
        if (!that._isMounted) {
          return
        }
        if (!authState || authState === 'signedIn') {
          this.setState({ authState: 'signIn' })
          this.changeState('signIn')
        }
      })
  }

  onHubCapsule(capsule) {
    const { channel } = capsule
    if (channel === 'auth') {
      this.checkUser()
    }
  }

  inGreeting(name) {
    return 'Hello ' + name
  }
  outGreeting() {
    return ''
  }

  userGreetings() {
    return (
      <Typography variant="h6" color="primary" noWrap>
        Welcome
      </Typography>
    )
  }

  noUserGreetings() {
    return (
      <Typography variant="h6" color="primary" noWrap>
        Welcome
      </Typography>
    )
  }

  showComponent(theme) {
    const { hide } = this.props

    if (hide && hide.includes(Greetings)) {
      return null
    }

    const { authState } = this.state
    const signedIn = authState === 'signedIn'

    const greeting = signedIn ? this.userGreetings() : this.noUserGreetings()

    if (!greeting) {
      return null
    }

    return <StyledContainer message="">{greeting}</StyledContainer>
  }
}

export default withStyles(styles)(Greetings)
