import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import cn from 'classnames'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardFixedColumn.ScorecardNormValues(
    theme
  )
)

export default Styled(props => {
  const {
    classes,
    rowIndex,
    gridData: { norms, metricsLabels, rowHeights }
  } = props
  const { rowType } = norms[rowIndex] || metricsLabels[norms[rowIndex].metric]

  return (
    <div className={classes.container}>
      <div
        className={cn(
          classes[rowType],
          rowIndex % 2 ? classes.odd : classes.even
        )}
      >
        <div className={classes.stat}></div>
        <Typography
          variant={rowHeights[rowIndex].variant}
          className={classes.normValuesText}
          align="center"
          color="inherit"
          display="block"
          >
          {metricsLabels[rowIndex].metric && norms[rowIndex].display}
        </Typography>
      </div>
    </div>
  )
})
