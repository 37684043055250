import { format as d3format } from 'd3-format'

export const tStatIndex = (main, norm, reverseStat=false, useWeighted=true) => {
  const main_n = useWeighted ? main.n : +main.n_unweighted  
  const v =
    (main.avg - norm.avg) /
    Math.sqrt((main.std * main.std) / main_n + (norm.std * norm.std) / norm.n) * (reverseStat ? -1 : 1)
  return v > 1.645 ? 1 : v < -1.645 ? -1 : 0
}

export const tStatNonIndex = ( main, norm, reverseStat=false, useWeighted=true) => {
    const main_n = useWeighted ? main.n : +main.n_unweighted  
    const numerator = (main.avg * main_n + norm.avg * norm.n)/(main_n + norm.n)
    const denominator = 1 - numerator
    const steptest = Math.sqrt( numerator * denominator  * ( 1 / norm.n + 1 / main_n) )
    const v = (main.avg - norm.avg) / steptest * (reverseStat ? -1 : 1)
    return v > 1.645 ? 1 : v < -1.645 ? -1 : 0
}

export const listToMap = (list, fieldMap) => {
  return list.reduce((obj, item) => {
    obj[item[fieldMap]] = item
    return obj
  }, {})
}

export const format = f => {
  switch (f) {
    case '%':
      return d3format('.0%')

    case 'fixed0':
    case 'index':
      return d3format('.0f')

    case 'fixed2':
      return d3format('.2f')

    case 'dollars':
      return d3format('$,.0f')

    default:
      return x => x
  }
}
