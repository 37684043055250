import { combineReducers } from 'redux'

import selections from './selections'
import scorecard from './scorecard'
import quickview from './quickview'
import viewer from './viewer'
import init from './init'

export default combineReducers({
  init,
  selections,
  scorecard,
  quickview,
  viewer
})
