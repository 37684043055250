export default {
  '***': {
    displayFilters: null,
    staticFilters: [
      {
        label: 'Gender',
        code: 'gender',
        multi: true,
        options: [
          {
            label: 'Male',
            value: 1,
            selected: false
          },
          {
            label: 'Female',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Age / Generations',
        excludeFromVerbatims: true,
        code: 'age',
        multi: true,
        options: [
          {
            label: 'Gen Z (1997-present)',
            value: 1,
            selected: false
          },
          {
            label: 'Millenial (1981-1996)',
            value: 2,
            selected: false
          },
          {
            label: 'Gen X (1965-1980)',
            value: 3,
            selected: false
          },
          {
            label: 'Boomers+ (1964 and prior)',
            value: 4,
            selected: false
          }
        ]
      },
      {
        label: 'Cardmember / Prospect',
        code: 'customer',
        multi: true,
        options: [
          {
            label: 'Cardmember',
            value: 1,
            selected: false
          },
          {
            label: 'Prospect',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'In-Market',
        code: 'market',
        multi: true,
        options: [
          {
            label: 'Actively Looking',
            value: 1,
            selected: false
          },
          {
            label: 'Thinking, not actively looking',
            value: 2,
            selected: false
          },
          {
            label: 'Not even thinking about',
            value: 3,
            selected: false
          }
        ]
      },
      {
        label: 'Transactor / Revolver',
        code: 'transrev',
        multi: true,
        options: [
          {
            label: 'Transactor',
            value: 1,
            selected: false
          },
          {
            label: 'Revolver',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Credit Worthiness',
        code: 'credit',
        multi: true,
        options: [
          {
            label: 'Prime',
            value: 1,
            selected: false
          },
          {
            label: 'Non-Prime',
            value: 2,
            selected: false
          }
        ]
      }
    ]
  }
}