export default theme => {
    return {
        headerBackgroundColor: theme.palette.primary.main,
        thumbnailWidth: 130,
        thumbnailHeight: 72,
        brandFontSize: 18,
        brandFontStyle: 'bold',
        brandColor: theme.palette.text.primary,
        brandWidth: 90,
        brandMargin: 20,
        titleColor: theme.palette.text.primary,
        titleFontStyle: 'bold',
        titleFontSize: 16,
        titleXOffset: 100,
        titleYOffset: -10,
        adCodeColor: theme.palette.text.primary,
        adCodeFontStyle: 'bold',
        adCodeFontSize: 11,
        adCodeXOffset: 100,
        adCodeYOffset: 12,
    }
}
