export default theme => {
  return {
    container: {
    },
    lowerBorder: {
      borderBottom: `1px solid ${theme.palette.grey[600]}`,
      paddingBottom: 10
    },
    root: {
      fontSize: 9
    }
  }
}