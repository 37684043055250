export default theme => {
  return {
    container: {
      height: '100%',
      paddingLeft: 5,
      paddingRight: 5
    },
    h0: {
      variant: 'subtitle1',
      paddingTop: 5,
      backgroundColor: theme.palette.grey[200]
    },
    hb0: {
      variant: 'subtitle1',
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.grey[100]
    },
    demphasized: {
      paddingTop: 10
    },
    d0: {
      paddingTop: 5
    },
    d1: {
      paddingTop: 5
    },
    dh0: {
      paddingTop: 5,
      backgroundColor: theme.palette.grey[200]
    },
    metricsValuesText: {},
    stat: {
      marginRight: 5,
      marginLeft: 5,
      borderRadius: 8
    }
  }
}
