export default {
  groupName: 'ford-ca-lincoln',
  label: 'Lincoln - Canada',
  clientName: 'Lincoln',
  dateFormat: 'dd/mm/yy',
  sampleSizeMetric: 106,
  staticFile: {
    filename: 'ad_master_list_ford_ca_luxury.xlsx',
    label: 'Ad List'
  },
  adPiMetric: 106,
  thresholds: {
    minSampleSizeInScorecard: {
      value: 30,
      color: '#ffffff'
    },
    medSampleSizeInScorecard: {
      value: 50,
      color: '#ffa500'
    },
    maxSampleSizeInScorecard: {
      value: 75,
      color: '#322eff'
    }
  },
  stats: {
    above: {
      description: 'Significant strength at 90% CI',
      color: '#ffffff',
      bgColor: '#009900'
      // bgColor: '#e3f4ba'
    },
    below: {
      description: 'Significant weakness at 90% CI',
      color: '#ffffff',
      bgColor: '#ff0000'
      // bgColor: '#fed099'
    }
  },
  staticFilters: {
    '***': [
      {
        label: 'Gender',
        code: 'gender',
        multi: false,
        options: [
          {
            label: 'Male',
            value: 1,
            selected: false
          },
          {
            label: 'Female',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Income',
        code: 'income',
        multi: false,
        options: [
          {
            label: '<$150k',
            value: 1,
            selected: false
          },
          {
            label: '$150k+',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Age',
        code: 'age',
        multi: false,
        options: [
          {
            label: '25-44',
            value: 1,
            selected: false
          },
          {
            label: '45-65',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Millennial',
        code: 'millennial',
        multi: false,
        options: [
          {
            label: 'Millennial',
            value: 1,
            selected: false
          }
        ]
      },

      {
        label: 'HEV Intention',
        code: 'hev',
        multi: false,
        options: [
          {
            label: 'HEV Intenders',
            value: 1,
            selected: false
          }
        ]
      },
      {
        label: 'Ownership',
        code: 'ownership',
        multi: true,
        options: [
          {
            label: 'Only Owns Import',
            value: 1,
            selected: false
          },
          {
            label: 'Only Owns Domestic',
            value: 2,
            selected: false
          },
          {
            label: 'Owns Import & Domestic',
            value: 3,
            selected: false
          }
        ]
      },
      {
        label: 'Purchase',
        code: 'purchase',
        multi: true,
        options: [
          {
            label: '0-12 Month Intender',
            value: 1,
            selected: false
          }
        ]
      },
      {
        label: 'Children',
        code: 'children',
        multi: false,
        options: [
          {
            label: 'With',
            value: 1,
            selected: false
          },
          {
            label: 'Without',
            value: 2,
            selected: false
          }
        ]
      }
    ]
  },
  displayFilters: [
    {
      label: 'Brands',
      code: 'brand',
      multi: true
    }
  ],
  styles: {
    h0: {
      bgColor: '#eeeeee',
      color: '#3F537A',
      fontWeight: 'bold'
    },
    h1: {
      color: '#3F537A',
      fontWeight: 'bold',
      offset: 1
    },
    d0: {},
    d1: {
      offset: 1
    },
    d2: {
      bgColor: '#ffffff',
      offset: 2
    },
    blank: {
      bgColor: '#ffffff',
      color: '#ffffff'
    },
    t1: {
      bgColor: '#eeeeee',
      fontWeight: 'bold',
      color: '#3F537A',
      offset: 1
    }
  },
  routes: {
    root: '/ford-ca'
  }
}
