import MainPresetsIcon from '@material-ui/icons/GroupWork'
import AdCategoriesIcon from '@material-ui/icons/Loyalty'

export default [
    {
      "path": "/slicer",
      "label": "Media Types",
      "icon": AdCategoriesIcon
    },
    {
      "path": "/preset",
      "label": "Ad Sets",
      "icon": MainPresetsIcon
    }
  ]
  