
import MetricTableTemplate from '../../../../../Template/theme/components/quickview/Quickview/MetricTable'
export default theme => {
    return {
      ...MetricTableTemplate(theme),
      titleMargin: 20,
      statColorSetBackground: true,
      statColorSaturationPercent: 250,
      statColorLightenPercent: 0,
      statColorValuePercent: -45,
    }
  }
  