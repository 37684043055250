export default {
  spendField: 'spend_ltd',
  interpolateMonths: true,
  trendMetrics: [
    {
      metric: 27,
      field: 'adpi',
      label: 'AdPi Score',
      format: '.0f',
      scale: [80, 160]
    },
    {
      metric: 1,
      field: 'recall',
      label: '% Ad Recall / Seen Before',
      format: '.0%',
      hasSpendData: true,
      scale: [0, 0.8]
    },
    {
      metric: 2,
      field: 'correct',
      label: '% Correct Brand ID / Recall',
      format: '.0%',
      hasSpendData: true,
      scale: [0, 0.8]
    },
    {
      metric: 7,
      field: 'impression',
      label: 'Lift in Brand Impression (Top 2 Box)',
      format: '.0%',
      scale: [0, 0.8]
    },
    {
      metric: 8,
      field: 'consideration',
      label: 'Lift in Brand Consideration (Top 2 Box)',
      format: '.0%',
      scale: [0, 0.8]
    },
    {
      metric: 9,
      field: 'intention',
      label: '% Positive Actions / Intentions (NET)',
      format: '.0%',
      scale: [0, 0.8]
    },
    {
      metric: 19,
      field: 'purchase',
      label: 'Subscribed/Made a purchase',
      format: '.0%',
      scale: [0, 0.8]
    },
    {
      metric: 21,
      field: 'trial',
      label: 'Signed up for a free trial',
      format: '.0%',
      scale: [0, 0.8]
    }
  ]
}
