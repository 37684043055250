import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FilterElement } from '../.././../new_components'
import { Grid, Paper } from '@material-ui/core'

const Styled = withStyles(theme => {
  console.log(theme)
  return theme.components.adminView.AdminConfigManager.AdminDataMigrationFilter(
    theme
  )
})

class AdminDataMigrationFilter extends PureComponent {
  componentDidCatch(err) {
    console.log('ScorecardFilter error - ', err)
  }

  render() {
    const { classes, filters, update, disabled } = this.props

    return (
      <Paper className={classes.container}>
        <Grid container alignItems="center" spacing={0}>
          {Object.keys(filters).map(filter => {
            return (
              <FilterElement
                update={update}
                element={filters[filter]}
                key={filters[filter].code}
                disabled={disabled}
              />
            )
          })}
        </Grid>
      </Paper>
    )
  }
}

export default Styled(AdminDataMigrationFilter)
