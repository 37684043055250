import {
  FORD_VIEWER_OPEN_REQUEST,
  FORD_VIEWER_OPEN_RECIEVE,
  FORD_VIEWER_OPEN_FAILURE,
  FORD_VIEWER_HIDE,
} from '../actions'

const initialState = {
  hasFetched: false,
  isFetching: false,
  hasErrored: false,
  errMessage: '',
  open: false,
  adCode: '',
  title: '',
  url: '',
  ext: '',
}

export default function viewerReducer(state = initialState, action) {
  switch (action.type) {
    case FORD_VIEWER_OPEN_REQUEST:
      return {
        ...initialState,
        isFetching: true,
        open: true,
        adCode: action.adCode,
        title: action.title,
      }
    case FORD_VIEWER_OPEN_RECIEVE:
      return {
        ...state,
        isFetching: false,
        url: action.data.url,
        ext: action.data.ext,
      }
    case FORD_VIEWER_OPEN_FAILURE:
      return {
        ...initialState,
        hasErrored: true,
        errMessage: action.data || '',
      }
    case FORD_VIEWER_HIDE:
      return { ...initialState }
    default:
      return state
  }
}
