// Formats: '%,n', '$,n', '$x,n', 'f,n', 'i,n'
// operations: 'last(a)', prod(a,b), ratio(a,b)
export default {
  '***': {
    '***': [
      {
        metric: 1,
        label: 'AdPi Score',
        inHeader: true,
        rowType: 'demphasized',
        dataFormat: '.,0',
        reverseStat: false
      },
      {
        label: 'IN-MARKET POTENTIAL',
        rowType: 'hb0'
      },
      {
        label: 'IMPACT',
        rowType: 'h0'
      },
      {
        metric: 4,
        label: 'Lift to Consideration',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 5,
        label: 'Lift to Impression',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 6,
        label: 'Net Actions',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 7,
        label: 'Visit this brand’s website for more information',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 8,
        label: 'Contact this brand for more information',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 9,
        label: 'Take advantage of an offer or promotion',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 10,
        label: 'Discuss the brand with friends or family',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 11,
        label: 'Recommend the brand to friends or family',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 12,
        label: 'Search online for more information',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 13,
        label: 'Communicate or share content on social media',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 14,
        label: 'Interact with the brand through social media',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 15,
        label: 'Pay more for the featured product/service',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 16,
        label: 'Look at/test out products from this brand',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 17,
        label: 'Purchase products from this brand in the future',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 18,
        label: 'Compare the features/design against competitors',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 19,
        label: 'Compare the prices/deals against competitors',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        label: 'BRAND EFFECT POTENTIAL',
        rowType: 'h0'
      },
      {
        metric: 901,
        label: 'Ad Memorability Potential Indicator',
        rowType: 's0',
        thresholds: [
          {
            min: 0.000,
            max: 0.375,
            text: 'Below Avg.',
            color: '#F9A23B'
          },
          {
            min: 0.375,
            max: 0.445,
            text: 'Avg.',
            color: null
          },
          {
            min: 0.445,
            max: 1.000,
            text: 'Above Avg.',
            color: '#89C35F'
          },
        ],
        dataFormat: 's,10'
      },
      {
        metric: 902,
        label: 'Brand Linkage Potential Indicator',
        rowType: 's0',
        thresholds: [
          {
            min: 0.000,
            max: 0.495,
            text: 'Below Avg.',
            color: '#F9A23B'
          },
          {
            min: 0.495,
            max: 0.585,
            text: 'Avg.',
            color: null
          },
          {
            min: 0.585,
            max: 1.000,
            text: 'Above Avg.',
            color: '#89C35F'
          },
        ],
        dataFormat: 's,10'
      },
      {
        label: 'CREATIVE DIAGNOSTICS',
        rowType: 'hb0'
      },
      {
        label: 'BRANDING',
        rowType: 'h0'
      },
      {
        metric: 20,
        label: 'Brand Recognition',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 21,
        label: 'Ease of Branding - Overall',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 22,
        label: 'Ease of Branding - Excluding Logo/Brand Name',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 23,
        label: 'Brand Prominence',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 24,
        label: 'Unique to this Brand',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 25,
        label: 'Good Fit with Brand',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        label: 'APPEAL',
        rowType: 'h0'
      },
      {
        metric: 26,
        label: 'Likes',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 27,
        label: 'Dislikes',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: true
      },
      {
        label: 'CREATIVE EFFICIENCY',
        rowType: 'h0'
      },
      {
        metric: 28,
        label: 'Consumer Experience',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 29,
        label: 'Shows innovative/cutting edge technology',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 30,
        label: 'Product integrates well with other devices ',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 31,
        label: 'Product can help me maintain connectivity with others',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 32,
        label: 'Product provides unique entertainment',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 33,
        label: 'Consumer Perception',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 34,
        label: 'Product solves an important need ',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 35,
        label: 'Product provides good value for the money ',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 36,
        label: 'Brand/product is committed to protecting user privacy',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 37,
        label: 'Product is a good fit with my lifestyle',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 38,
        label: 'Product is safe to use with children',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 39,
        label: 'Brand Connection',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 40,
        label: 'Portrays a brand I want to be associated with',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 41,
        label: 'Portrays a brand that fulfills my needs',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 42,
        label: 'Portrays a brand I can trust',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 43,
        label: 'Relevance',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 44,
        label: 'Spoke directly to me',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 45,
        label: 'Reassuring',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 46,
        label: 'Shows people and situations I can relate to',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 47,
        label: 'Addresses an issue that\'s important to me',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 48,
        label: 'Cognitive Connection',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 49,
        label: 'Is a credible message coming from this brand',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 50,
        label: 'Tells me something new about this brand',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 51,
        label: 'Informative',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 52,
        label: 'Clear',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 53,
        label: 'Presents information at a good pace',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 54,
        label: 'Confusing',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: true
      },
      {
        metric: 55,
        label: 'Engagement',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 56,
        label: 'Worth talking about',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 57,
        label: 'Heartwarming',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 58,
        label: 'Clever',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 59,
        label: 'Unique',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        label: 'MEMORABILITY',
        rowType: 'h0'
      },
      {
        metric: 61,
        label: 'Entertaining',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 62,
        label: 'Humorous',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 63,
        label: 'Grabs my attention',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 64,
        label: 'Memorable',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 65,
        label: 'Stays with me (able to describe later)',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 66,
        label: 'Boring',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: true
      },
      {
        metric: 67,
        label: 'Annoying',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: true
      },
      {
        label: '',
        rowType: 'eol'
      }
    ]
  }
}
