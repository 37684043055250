export default {
  slices: [
    {
      field: 'ad_type',
      display: 'Media Type',
      map: {
        'tv': {
          display: 'TV',
          mediaType: 'mp4',
          sortOrder: 1
        }
      }
    },
    {
      field: 'ad_categories',
      display: 'Ad Category',
      map: {
        'all_plans': {
          display: 'All Wireless Plans',
          mediaType: 'mp4',
          sortOrder: 1
        },
        'post_paid_plans': {
          display: 'Post-Paid Plans',
          mediaType: 'mp4',
          sortOrder: 2
        },
        'pre_paid_plans': {
          display: 'Pre-Paid Plans',
          mediaType: 'mp4',
          sortOrder: 3
        }
      }
    }
  ]
}
