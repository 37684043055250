import defaultTheme from '../../../Template/theme'
import components from './components'

const theme = {
  ...defaultTheme,
  components
}

export {theme}
export default theme
