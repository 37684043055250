export default {
  '***': {
    '***': {
      '***': {
        extraFilter: {
          type: 'metadataFilter',
          ad_type: ['***'],
          disabledState: 'disabled', // 'hidden'
          data: {
            field: 'region',
            title: 'Select Region',
            options: [
              {
                label: 'Total Footprint',
                value: ['Total Footprint'],
                selected: true
              },
              {
                label: 'Northeast',
                value: ['Northeast'],
                selected: true
              },
              {
                label: 'West',
                value: ['West'],
                selected: true
              },
              {
                label: 'Central',
                value: ['Central'],
                selected: true
              }
            ]
          }
        }
      }
    }
  }
}
