export default Object.assign(
  theme => {
    return {
      container: {
        padding: 25
      },
      paper: {
        width: 800,
      }
    }
  }
)
