import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

import {withStyles} from '@material-ui/core/styles'
const Styled = withStyles( theme => theme.components.generic.ViewScorecardButton(theme))

export default Styled( props => {
    return (
        <Grid item>
        <Tooltip title={props.disabled ? "Both Ads and Norm needed to view scorecard" : "View Scorecard"}>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={e => props.action()}
              disabled={props.disabled}
            >
              View Scorecard
            </Button>
          </div>
        </Tooltip>
      </Grid>
    )
})