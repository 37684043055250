import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Logo } from '../../new_components/generic'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '350px',
    marginTop: '50px',
  },
  rounded: {
    borderRadius: '10px',
  },
  header: {
    textAlign: 'center',
    width: '100%',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    color: theme.palette.primary.contrastText,
  },
  paper: {
    marginBottom: '20px',
    padding: '20px',
    width: '100%',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    backgroundColor: theme.palette.primary.main,
  },
  bottom: {
    padding: '0px 20px 20px 20px',
  },
})

class StyledContainer extends Component {
  render() {
    const { classes, children, message } = this.props

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <div className={classes.container}>
                <Paper
                  style={{ width: '100%' }}
                  classes={{ rounded: classes.rounded }}
                >
                  <div className={classes.header}>
                    <Paper className={classes.paper}>
                    <div style={{ marginTop: 5, marginRight: 0 }}>
                      <Logo name='marketcast' size="large"/>
                    </div>
                    </Paper>
                    <Typography variant="h6" color="primary" noWrap>
                      {message}
                    </Typography>
                  </div>
                  <div className={classes.bottom}>{children}</div>
                </Paper>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(StyledContainer)
