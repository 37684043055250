import React from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

const withConfig = config =>  Component => props => {
  return (
    <MuiThemeProvider theme={createMuiTheme({
      ...config.theme,
      overrides: {
        MuiButton: {
          contained: {
            boxShadow: 'none',
          },
        },
      },
    })}>
      <CssBaseline />
      <Component {...props} config={config.config}/>
    </MuiThemeProvider>
  )
}

export { withConfig }
