import {
  USER_RESOURCES_REQUEST,
  USER_RESOURCES_RECEIVE,
  USER_RESOURCES_FAILURE,
  DASHBOARD_RECEIVE_METADATA,
  DASHBOARD_FAIL_METADATA,

} from '../../new_actions'

import {
  FORD_META_DATA_RECEIVE,
  FORD_META_DATA_FAILURE
} from '../../app/Auto/actions'

const initialState = {
  ready: false,
  isFetching: false,
  error: null,
  resources: [],
  accessRights: {},
  hasAdminViewAccess: false,
  isPhoenix: true,
  userType: 'MarketCast User'
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_RESOURCES_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      }
    case USER_RESOURCES_RECEIVE:
      const { email, resources } = action.data
      const isPhoenix = email.match('phoenixmi.com') || email.match('marketcast.com')
      const userType = isPhoenix ? 'MarketCast User' : 'Client'
      return {
        ...initialState,
        ready: true,
        isFetching: false,
        email,
        isPhoenix,
        userType,
        resources
      }
    case USER_RESOURCES_FAILURE:
      return {
        ...initialState,
        isFetching: false,
        error: `Could not fetch user information from the server: ${action.data}.`,
      }
    case DASHBOARD_RECEIVE_METADATA:
    case FORD_META_DATA_RECEIVE:
      const { accessRights } = action.data
      return {
        ...state,
        accessRights,
        hasAdminViewAccess: Object.keys(accessRights).filter(accessRight => ['isUser', 'isPrivilegedUser'].includes(accessRight) === false).reduce( (flag, accessRight) => flag || accessRights[accessRight], false )
      }
    case DASHBOARD_FAIL_METADATA:
    case FORD_META_DATA_FAILURE:
      return {
        ...state,
        accessRights: initialState.accessRights,
        hasAdminViewAccess: false
      }
    default:
      return state
  }
}
