export default theme => {
  return {
    backgroundColor: theme.palette.grey[50],
    titleColor: theme.palette.grey[600],
    normColor: theme.palette.grey[600],
    offColor: theme.palette.grey[300],
    strokeWidth: 4,
    metricFontSize: 50,
    titleX: 10,
    titleY: 10,
    titleFontSize: 17,
    titleFontStyle: 'bold',
    metricYOffset: 10,
    metricFontStyle: 'bold',
    normYOffset: -60,
    normLabelFontSize: 12,
    normLabelFontStyle: '',
    normFontSize: 22,
    normFontStyle: 'bold',
    noStatColor: theme.palette.grey[400],
    metricColorSaturationPercent: 250,
    metricColorLightenPercent: 0,
    metricColorValuePercent: -45,
  }
}
