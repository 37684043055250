export default theme => {
  return {
    button: {
      cursor: 'pointer',
      padding: '5px 20px',
      minWidth: 100,
      backgroundColor: theme.palette.background.paper
    },
    activeType: {
      '&:hover': {
        backgroundColor: theme.palette.listSelectBackground.default,
      }
    }
  }
}
