export default {
  '***': {
    '***': [
      {
        "id": 100,
        "label": "Brand Image",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          105,
          110,
          115,
          120,
          125
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 105,
        "label": "Awards/Accolades",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Refers to any reference of awards or rankings, 3rd party or commissioned research based."
      },
      {
        "id": 110,
        "label": "Customer Service",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Refers to a primary focus on customer service"
      },
      {
        "id": 115,
        "label": "Co-Brand",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Single ad advertising for more than one brand ('Seamless + Taco Bell' or 'Marriott Chase credit card')"
      },
      {
        "id": 120,
        "label": "General Reputation",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Refers to any ad that focuses on a brand's general reputation, but does not mention any products"
      },
      {
        "id": 125,
        "label": "Sponsorship",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "Sponsorship of an event, sport or project (such as a food bank)"
      },
      {
        "id": 200,
        "label": "Creative Type",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          205,
          210,
          215,
          220
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked"
      },
      {
        "id": 205,
        "label": "Narrative",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad follows a story trajectory, even if short"
      },
      {
        "id": 210,
        "label": "Vignette/Montage",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad does not have a story, but rather many different scenes cut together"
      },
      {
        "id": 215,
        "label": "Animated",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "At least half of ad is cartoon/animation and not filmed"
      },
      {
        "id": 220,
        "label": "Graphics/Product Images Only",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad is a computer generated ad usually focusing on a product with few or no actual filmed scenes"
      },
      {
        "id": 300,
        "label": "Creative Style",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          305,
          310,
          315,
          320,
          325,
          330,
          335,
          340,
          345
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked."
      },
      {
        "id": 305,
        "label": "Humorous",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "The intent of the ad is to make you laugh"
      },
      {
        "id": 310,
        "label": "Emotional",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "The intent of the ad is to elicit sad or heartwarming emotion"
      },
      {
        "id": 315,
        "label": "Spokesperson",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Includes both celebrity and non-celebrity spokespeople"
      },
      {
        "id": 320,
        "label": "Celebrity",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Includes any celebrity inclusion, whether or not that person is a spokesperson"
      },
      {
        "id": 325,
        "label": "Music (jingle)",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Refers to a consistent jingle associated with a brand, not just background music"
      },
      {
        "id": 330,
        "label": "Music (lyrics/song)",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Refers to an identifiable song - either lyrics or instrumental"
      },
      {
        "id": 335,
        "label": "Branding in intro (First 5 seconds)",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "The advertising brand is shown or spoken in the first 5 seconds"
      },
      {
        "id": 340,
        "label": "Voice Over Narration",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "An off-screen narrator is featured"
      },
      {
        "id": 345,
        "label": "Continuity",
        "selected": 0,
        "collapse": 0,
        "parent": 300,
        "children": [],
        "tooltip": "Ad uses the same creative construct and/or established brand cues"
      },
      {
        "id": 400,
        "label": "Primary Message",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          405,
          410,
          415,
          420,
          425
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked"
      },
      {
        "id": 405,
        "label": "Plan",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "Ad focuses on carrier's plan or value offering"
      },
      {
        "id": 410,
        "label": "Device",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": "Ad focuses on touting a device or product sale"
      },
      {
        "id": 415,
        "label": "Network",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": "Ad focuses on the carrier's network"
      },
      {
        "id": 420,
        "label": "Brand",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": "Ad focuses on a corporate/brand value message"
      },
      {
        "id": 425,
        "label": "Combo",
        "selected": 0,
        "collapse": 0,
        "parent": 400,
        "children": [],
        "tooltip": "Ad includes significant focus on more than one message"
      },
      {
        "id": 500,
        "label": "Offers",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          505,
          510
        ],
        "tooltip": "This will be checked automatically when any item in this section is checked"
      },
      {
        "id": 505,
        "label": "Perks Bundled with Streaming (netflix, Hulu, Disney+, etc)",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": "Streaming services included"
      },
      {
        "id": 510,
        "label": "Concrete Offer",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": "Concrete offer is present anywhere in the ad, Note: Includes dollar value, BOGO, etc. but excludes \"perks\" such as a streaming service included."
      },
      {
        "id": 515,
        "label": "Bundled with home services",
        "selected": 0,
        "collapse": 0,
        "parent": 500,
        "children": [],
        "tooltip": "No tooltip provided"
      },

    ]
  }
}
