export default {
  spendField: 'thirty_day_spend',
  interpolateMonths: true,
  trendMetrics: [
    {
      metric: 3,
      field: 'recall',
      label: 'Recall',
      format: '.1%',
      hasSpendData: true,
      scale: [0, 0.6]

    },
    {
      metric: 5,
      field: 'consideration',
      label: 'Lift to Purchase Consideration',
      format: '.1%',
      scale: [0, 0.6]

    },
    {
      metric: 4,
      field: 'impression',
      label: 'Lift to Brand Impression',
      format: '.1%',
      scale: [0, 0.6]
    },
    {
      metric: 7,
      field: 'relationship',
      label: 'Makes Me Want to Start/Expand Relationship',
      format: '.1%',
      scale: [0, 0.6]
    }
  ]
}
