export default {
  '***': {
    '***': {
      '***': {
        extraFilter: {
          type: 'metadataFilter',
          ad_type: ['***'],
          disabledState: 'disabled', // 'hidden'
          data: {
            field: 'ad_format',
            title: 'Select Format',
            options: [
              {
                label: 'Animatic',
                value: 'Animatic',
                selected: true
              },
              {
                label: 'Boardomatic',
                value: 'Boardomatic',
                selected: true
              },
              {
                label: 'Full Finish',
                value: 'Full Finish',
                selected: true
              },
              {
                label: 'Other',
                value: 'other',
                selected: true
              }
            ]
          }
        }
      }
    }
  }
}
