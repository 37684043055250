import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core'

const Styled = withStyles(theme => theme.components.adminView.AdminConfigManager.AdminDataMigrationComment(theme))

export default Styled(props => {
  const { open, done, cancel, comment, update } = props
  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Migration Note</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Provide a comment to be included as a migration note
        </DialogContentText>
        <TextField
          margin="dense"
          id="migration-comment"
          label="Migration Note"
          type="text"
          onChange={update}
          value={comment || ''}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={done} color="secondary">
          Commit
        </Button>
      </DialogActions>
    </Dialog>
  )
})
