import {
    ADMIN_REQUEST_CONFIG_CONFIG,
    ADMIN_RECEIVE_CONFIG_CONFIG,
    ADMIN_FAIL_CONFIG_CONFIG,
    ADMIN_REQUEST_CONFIG_MIGRATION,
    ADMIN_RECEIVE_CONFIG_MIGRATION,
    ADMIN_FAIL_CONFIG_MIGRATION,
    ADMIN_REQUEST_UPDATE_CONFIG_CONFIG,
    ADMIN_REQUEST_UPDATE_MIGRATION,
    ADMIN_REQUEST_MIGRATION,
    ADMIN_RECEIVE_MIGRATION,
    ADMIN_RECEIVE_MIGRATION_ACK,
    ADMIN_REQUEST_MIGRATION_TEST,
    ADMIN_RECEIVE_MIGRATION_TEST,
    ADMIN_RECEIVE_DELETE_MIGRATION,
    ADMIN_FAIL_MIGRATION_TEST,
    DASHBOARD_ADMIN_INTERFACE_OPEN,
    DASHBOARD_ADMIN_INTERFACE_CLOSE,
    DASHBOARD_SET_CONFIG
} from '../../new_actions'

import { monthCodeToText } from '../../new_utils'

const initialState = {
    serverConfig: {
        ready: false,
        pending: false,
        error: null,
        jsonTheme: {},
        data: []
    },
    dataMigration: {
        migrations: {
            ready: false,
            pending: false,
            error: null,
            list: []
        },
        filters: {
            ready: false,
            pending: false,
            error: null,
            list: {}
        },
        migrationTest: {
            ready: true,
            pending: false,
            error: null,
            status: null
        }
    }
}

export default function serverConfigReducer(state = initialState, action) {
    switch (action.type) {
        case DASHBOARD_SET_CONFIG:
            const {
                components: {
                    adminView: {
                        adminConfigManager: { adminDataMigrationFilters, jsonTheme }
                    }
                }
            } = action.data
            return {
                ...state,
                serverConfig: {
                    ...state.serverConfig,
                    ready: false,
                    error: false,
                    pending: false,
                    jsonTheme
                },
                dataMigration: {
                    ...state.dataMigration,
                    filters: {
                        ready: false,
                        pending: false,
                        error: null,
                        list: adminDataMigrationFilters.reduce((obj, filter) => {
                            obj[filter.code] = filter
                            return obj
                        }, {})
                    }
                }
            }

        case ADMIN_REQUEST_CONFIG_CONFIG:
        case ADMIN_REQUEST_UPDATE_CONFIG_CONFIG:
            return {
                ...state,
                ...state.config,
                serverConfig: {
                    ...state.serverConfig,
                    ready: false,
                    pending: true,
                    error: null
                }
            }

        case ADMIN_RECEIVE_CONFIG_CONFIG:
            return {
                ...state,
                ...state.config,
                serverConfig: {
                    ...state.serverConfig,
                    ready: true,
                    pending: false,
                    error: null,
                    data: action.data.configurations
                }
            }

        case ADMIN_FAIL_CONFIG_CONFIG: {
            return {
                ...state,
                ...state.config,
                serverConfig: {
                    ...state.serverConfig,
                    ready: false,
                    pending: false,
                    error: action.data
                }
            }
        }

        case ADMIN_REQUEST_CONFIG_MIGRATION:
            return {
                ...state,
                ...state.config,
                dataMigration: {
                    ...state.dataMigration,
                    filters: {
                        ...state.dataMigration.filters,
                        ready: false,
                        pending: true
                    }
                }
            }

        case ADMIN_RECEIVE_CONFIG_MIGRATION: {
            const { stagingStore } = action.data.dataMigration
            const configFilters = Object.keys(stagingStore).reduce((obj, key) => {
                obj[key] = {
                    ...state.dataMigration.filters.list[key],
                    options: stagingStore[key].map(item => {
                        return {
                            label: item.config_version,
                            value: item.hash,
                            selected: false
                        }
                    })
                }
                return obj
            }, {})
            return {
                ...state,
                ...state.config,
                dataMigration: {
                    ...state.dataMigration,
                    migrations: {
                        ...state.dataMigration.migrations,
                        ready: true,
                        pending: false
                    },
                    filters: {
                        ...state.dataMigration.filters,
                        ready: true,
                        pending: false,
                        list: {
                            ...state.dataMigration.filters.list,
                            startMonth: {
                                ...state.dataMigration.filters.list.startMonth,
                                options: action.data.dataMigration.stagingMonths.map(
                                    month => {
                                        return {
                                            label: monthCodeToText(month),
                                            value: month,
                                            selected: false
                                        }
                                    }
                                )
                            },
                            endMonth: {
                                ...state.dataMigration.filters.list.endMonth,
                                options: action.data.dataMigration.stagingMonths.map(
                                    month => {
                                        return {
                                            label: monthCodeToText(month),
                                            value: month,
                                            selected: false
                                        }
                                    }
                                )
                            },
                            ...configFilters
                        }
                    }
                }
            }
        }

        case ADMIN_FAIL_CONFIG_MIGRATION: {
            return {
                ...state,
                ...state.config,
                dataMigration: {
                    ...state.dataMigration,
                    filters: {
                        ...state.dataMigration.filters,
                        ready: false,
                        pending: false,
                        error: action.data
                    }
                }
            }
        }

        case ADMIN_REQUEST_MIGRATION_TEST:
            return {
                ...state,
                ...state.config,
                dataMigration: {
                    ...state.dataMigration,
                    migrationTest: {
                        ...state.dataMigration.migrationTest,
                        ready: false,
                        pending: true,
                        status: null
                    }
                }
            }

        case ADMIN_RECEIVE_MIGRATION_TEST:
            return {
                ...state,
                ...state.config,
                dataMigration: {
                    ...state.dataMigration,
                    migrationTest: {
                        ...state.dataMigration.migrationTest,
                        ready: true,
                        pending: false,
                        status: action.data
                    }
                }
            }

        case ADMIN_FAIL_MIGRATION_TEST:
            return {
                ...state,
                ...state.config,
                dataMigration: {
                    ...state.dataMigration.migrationTest,
                    ready: true,
                    pending: false,
                    error: action.data
                }
            }

        case ADMIN_REQUEST_MIGRATION:
        case ADMIN_REQUEST_UPDATE_MIGRATION:
            return {
                ...state,
                ...state.config,
                dataMigration: {
                    ...state.dataMigration,
                    migrations: {
                        ...state.dataMigration.migrations,
                        ready: false,
                        pending: true
                    }
                }
            }

        case ADMIN_RECEIVE_DELETE_MIGRATION:
        case ADMIN_RECEIVE_MIGRATION_ACK:
        case ADMIN_RECEIVE_MIGRATION:
            return {
                ...state,
                ...state.config,
                dataMigration: {
                    ...state.dataMigration,
                    migrations: {
                        ...state.dataMigration.migrations,
                        list: action.data,
                        ready: true,
                        pending: false
                    }
                }
            }

        case DASHBOARD_ADMIN_INTERFACE_OPEN:
            return {
                ...state,
                open: true
            }

        case DASHBOARD_ADMIN_INTERFACE_CLOSE:
            return initialState

        default:
            return { ...state }
    }
}
