// Formats: '%,n', '$,n', '$x,n', 'f,n', 'i,n'
// operations: 'last(a)', prod(a,b), ratio(a,b)
export default {
  '***': [
    {
      metric: 1,
      label: 'AdPi Score',
      inHeader: true,
      rowType: 'demphasized',
      dataFormat: '.,0',
      reverseStat: false
    },
    {
      label: 'IN-MARKET POTENTIAL',
      rowType: 'hb0'
    },
    // {
    //   label: 'BREAKTHROUGH',
    //   rowType: 'h0'
    // },
    // {
    //   metric: 100,
    //   recall: {
    //     metric: 2,
    //   },
    //   label: 'Branded Recall (Last Month)',
    //   rowType: 'd0',
    //   dataFormat: '%,1',
    //   reverseStat: false,
    // },
    // {
    //   metric: 2,
    //   label: 'Branded Recall (Avg. Recall)',
    //   rowType: 'd0',
    //   dataFormat: '%,1',
    //   reverseStat: false
    // },
    // {
    //   metric: 3,
    //   label: 'Total Recall',
    //   rowType: 'd0',
    //   dataFormat: '%,1',
    //   reverseStat: false
    // },
    {
      label: 'IMPACT',
      rowType: 'h0'
    },
    {
      metric: 4,
      label: 'Lift to Consideration',
      rowType: 'd0',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 5,
      label: 'Lift to Impression',
      rowType: 'd0',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 6,
      label: 'Net Actions',
      rowType: 'd0',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 7,
      label: 'Visit this brand’s website for more information',
      rowType: 'd1',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 8,
      label: 'Contact this brand for more information',
      rowType: 'd1',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 9,
      label: 'Take advantage of an offer or promotion',
      rowType: 'd1',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 10,
      label: 'Discuss the brand with friends or family',
      rowType: 'd1',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 11,
      label: 'Recommend the brand to friends or family',
      rowType: 'd1',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 12,
      label: 'Search online for more information',
      rowType: 'd1',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 13,
      label: 'Communicate or share content on social media',
      rowType: 'd1',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 14,
      label: 'Interact with the brand through social media',
      rowType: 'd1',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 15,
      label: 'Pay more for the featured product/service',
      rowType: 'd1',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      label: 'CREATIVE DIAGNOSTICS',
      rowType: 'hb0'
    },
    {
      label: 'BRANDING',
      rowType: 'h0'
    },
    {
      metric: 16,
      label: 'Brand Recognition',
      rowType: 'd0',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 17,
      label: 'Ease of Branding - Overall',
      rowType: 'd0',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 18,
      label: 'Ease of Branding - Excluding Logo/Brand Name',
      rowType: 'd0',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 19,
      label: 'Brand Prominence',
      rowType: 'd0',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 20,
      label: 'Unique to this Brand',
      rowType: 'd0',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 21,
      label: 'Good Fit with Brand',
      rowType: 'd0',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      label: 'APPEAL',
      rowType: 'h0'
    },
    {
      metric: 22,
      label: 'Likes',
      rowType: 'd0',
      dataFormat: '%,1',
      reverseStat: false
    },
    {
      metric: 23,
      label: 'Dislikes',
      rowType: 'd0',
      dataFormat: '%,1',
      reverseStat: true
    },
    {
      label: 'CREATIVE EFFICIENCY',
      rowType: 'h0'
    },
    {
      metric: 24,
      label: 'Brand Connection',
      rowType: 'dh0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 25,
      label: 'Portrays a brand I want to be associated with',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 26,
      label: 'Portrays a brand that fulfills my needs',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 27,
      label: 'Portrays a brand I can trust ',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 28,
      label: 'Relevance',
      rowType: 'dh0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 29,
      label: 'Spoke directly to me',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 30,
      label: 'Reassuring',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 31,
      label: 'Shows people and situations I can relate to  ',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 32,
      label: 'Addresses an issue that\'s important to me',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 33,
      label: 'Approprite for the Times',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 34,
      label: 'Cognitive Connection',
      rowType: 'dh0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 35,
      label: 'Is a credible message coming from this brand',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 36,
      label: 'Tells me something new about this brand',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 37,
      label: 'Informative',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 38,
      label: 'Clear',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    // {
    //   metric: 39,
    //   label: 'Presents information at a good pace',
    //   rowType: 'd0',
    //   dataFormat: '.,2',
    //   reverseStat: false
    // },
    {
      metric: 39,
      label: 'Confusing',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: true
    },

    {
      metric: 40,
      label: 'Engagement',
      rowType: 'dh0',
      dataFormat: 'idx',
      reverseStat: false
    },
    {
      metric: 41,
      label: 'Worth talking about',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 42,
      label: 'Heartwarming',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 43,
      label: 'Clever',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 44,
      label: 'Unique',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      label: 'Memorability',
      rowType: 'h0',
    },
    {
      metric: 45,
      label: 'Entertaining',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    {
      metric: 46,
      label: 'Humorous',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    // {
    //   metric: 48,
    //   label: 'Humorous',
    //   rowType: 'd0',
    //   dataFormat: '.,2',
    //   reverseStat: false
    // },
    {
      metric: 47,
      label: 'Memorable',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: false
    },
    // {
    //   metric: 50,
    //   label: 'Stays with me (able to describe later)',
    //   rowType: 'd0',
    //   dataFormat: '.,2',
    //   reverseStat: false
    // },
    {
      metric: 48,
      label: 'Boring',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: true
    },
    {
      metric: 49,
      label: 'Annoying',
      rowType: 'd0',
      dataFormat: '.,2',
      reverseStat: true
    },
    {
      label: '',
      rowType: 'eol'
    }
  ]
}
