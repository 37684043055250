import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import Search from '../search'
import { SetBuilder, AsyncWrapper } from '../../new_components'
import {
  getAdType,
  getAdsMap,
  getSelectedPresets,
  getSelectedTab,
  getSortQuery,
  getPresets
} from '../../new_selectors'
import {
  dashboardInit,
  dashboardCreatePreset,
  dashboardSelectPreset,
  dashboardSelectSortMetric,
  dashboardSelectAdsTab,
  dashboardViewerOpen,
  dashboardUpdate,
  dashboardToggleExcludeNonNorm,
  appSetWarning
  // dashboardSearchSetMonthRange
} from '../../new_actions'
import { generateUUID } from '../../new_utils'

const Styled = withStyles(theme => {
  return theme.components.selection.SetBuilder(theme)
})

class Builder extends React.PureComponent {
  state = {
    searchOpen: false,
    ready: false
  }

  openMedia = ({ links, title, ad_code }) => {
    const { dispatch } = this.props
    dispatch(dashboardViewerOpen(links.media, title, ad_code))
  }

  openSearch = () => {
    this.setState({
      searchOpen: true
    })
  }

  closeSearch = () => {
    this.setState({
      searchOpen: false
    })
  }

  navigateTo = route => {
    const { history, baseRoute } = this.props

    history.push(`${baseRoute}/${route}`)
  }

  openScorecard = () => {
    this.navigateTo('scorecard')
  }

  selectSortMetric = metric => {
    const { dispatch } = this.props
    dispatch(dashboardSelectSortMetric(metric))
  }

  selectTab = ({ tab, index }) => {
    const { dispatch } = this.props
    dispatch(dashboardSelectAdsTab({ tab, index }))
  }

  updatePreset = preset => {
    const {
      dispatch,
      selectedTab: { tab },
      selections
    } = this.props

    const meta = Object.keys(selections).reduce((obj, key) => {
      obj[key] = selections[key].field
      return obj
    }, {})
    dispatch(
      dashboardCreatePreset({
        type: tab,
        preset: {
          ...preset,
          ...meta,
          label: `Custom set`,
          uuid: generateUUID()
        }
      })
    )
    this.setState({
      searchOpen: false
    })
  }

  savePreset = preset => {
    const {
      dispatch,
      selectedTab,
      config: {
        setBuilder: { storageVersion }
      },
      selectedTab: { tab }
    } = this.props

    const sortMetric = this.props.config.setBuilder.setBuilderToolbar.sortOptions.find(
      sortOption => sortOption.selected
    ).value
    const sortData = this.props.sort.sortData[selectedTab.tab].data
    const filteredSortData = Object.keys(sortData)
      .filter(ad_code => preset.ad_codes.includes(ad_code))
      .reduce((obj,ad_code) => {
        obj[ad_code] = sortData[ad_code]
        return obj
      },{})

    const ad_codes = Object.keys(filteredSortData).sort((a, b) => {
        return filteredSortData[a][sortMetric].avg < filteredSortData[b][sortMetric].avg ? 1 : -1
      })
    const linkAd = this.props.adsMap[selectedTab.tab].list.find(
      ad => ad.ad_code === ad_codes[0]
    )
    const link = linkAd && linkAd.links.thumbnail
    preset.uuid = generateUUID()
    dispatch(
      dashboardUpdate('preset', {
        storageVersion,
        preset: {
          ...preset,
          link
        }
      })
    )
    dispatch(
      dashboardSelectPreset({
        type: tab,
        preset
      })
    )
  }

  remove = (type, list) => {
    const {
      dispatch,
      presets,
      selectedTab,
      genericConfig
    } = this.props

    const sortMetric = this.props.config.setBuilder.setBuilderToolbar.sortOptions.find(
      sortOption => sortOption.selected
    ).value
    const sortData = this.props.sort.sortData[selectedTab.tab].data
    const ad_codes = presets[selectedTab.tab].ad_codes.filter(
      ad_code => !list.includes(ad_code)
    )
    ad_codes.sort((a, b) => {
      if (
        !sortData[a] ||
        !sortData[a][sortMetric] ||
        !sortData[b] ||
        !sortData[b][sortMetric]
      )
        return 0
      return sortData[a][sortMetric].avg < sortData[b][sortMetric].avg ? 1 : -1
    })
    const linkAd = this.props.adsMap[selectedTab.tab].list.find(
      ad => ad.ad_code === ad_codes[0]
    )
    const link = linkAd && linkAd.links.thumbnail
    const end_month = ad_codes.length ? presets[selectedTab.tab].end_month : 0
    const { uuid } = presets[selectedTab.tab]
    const preset = {
      ...presets[selectedTab.tab],
      ad_codes,
      link,
      label: `Custom ${selectedTab.tab === 'ad' ? genericConfig.MainHeader.baseText : 'Norm'} set`,
      is_standard: false,
      is_perishable: true,
      end_month,
      uuid: type === 'ad' ? uuid : generateUUID(),
      id: -1
    }

    dispatch(
      dashboardCreatePreset({
        type,
        preset
      })
    )
  }

  componentDidMount() {
    const {
      dispatch,
      init,
      adType,
      presets,
      sortQuery,
      sort: { sortData }
    } = this.props
    if (!adType) {
      dispatch(dashboardInit(init))
    } else {
      this.setState({
        ready: true
      })

      if (
        presets['ad'].ad_codes.length &&
        presets['ad'].uuid !== sortData['ad'].uuid
      ) {
        dispatch(dashboardUpdate('sort', sortQuery['ad']))
      }
    }
  }

  shouldSort = previous => {
    const {
      presets,
      selectedTab: { tab },
      sort: { ready, sortData }
    } = this.props

    return (
      (presets[tab].uuid !== previous.presets[tab].uuid &&
        presets[tab].ad_codes.length) ||
      (ready &&
        presets[tab].ad_codes.length &&
        sortData[tab].uuid !== presets[tab].uuid)
    )
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      allPresets,
      presets,
      sortQuery,
      selectedTab
    } = this.props

    if (!prevProps.adType) {
      this.setState({
        ready: true
      })
    } else if (presets.norm.uuid === null) {
      dispatch(
        dashboardSelectPreset({ type: 'norm', preset: allPresets.norm[0] })
      )
    } else {
      if (this.shouldSort(prevProps)) {
        dispatch(dashboardUpdate('sort', sortQuery[selectedTab.tab]))
      }
    }
  }

  toggleExcludeNonNormFilter = () => {
    const { selectedTab, dispatch } = this.props
    dispatch(dashboardToggleExcludeNonNorm({ type: selectedTab.tab }))
  }

  // updateMonthRange = range => {
  //   const {
  //     dispatch,
  //     selectedTab: { tab }
  //   } = this.props
  //   dispatch(
  //     dashboardSearchSetMonthRange({
  //       tab,
  //       range
  //     })
  //   )
  // }

  generateError = message => {
    this.props.dispatch( appSetWarning(message))
  }

  render() {
    const {
      config: { setBuilder },
      genericConfig,
      calcFlags,
      adType,
      selections,
      adsMap,
      presets,
      sort,
      selectedTab,
      groupName,
      baseEndpoint
    } = this.props

    if (!this.state.ready) return null
    const preset = presets[selectedTab.tab]

    return (
      <AsyncWrapper item={sort}>
        <SetBuilder
          config={setBuilder}
          genericConfig={genericConfig}
          sortOptions={sort.sortOptions}
          adsMap={adsMap}
          adType={adType}
          selections={selections}
          selectedTab={selectedTab}
          selectTab={this.selectTab}
          selectedPresets={presets}
          toPresets={() => this.navigateTo('preset')}
          openScorecard={this.openScorecard}
          adSetTitle="Build New Ad Set"
          remove={this.remove}
          selectSortMetric={this.selectSortMetric}
          openSearch={this.openSearch}
          groupName={groupName}
          baseEndpoint={baseEndpoint}
          openMedia={this.openMedia}
          savePreset={this.savePreset}
          calcFlags={calcFlags}
          excludeNonNormFilter={calcFlags[selectedTab.tab].excludeNonNorm}
          enableExcludeNonNormFilter={
            setBuilder.setBuilderToolbar.enableExcludeNonNormFilters[
              selectedTab.tab
            ]
          }
          toggleExcludeNonNormFilter={this.toggleExcludeNonNormFilter}
          generateError={this.generateError}
        />
        {this.state.searchOpen ? (
          <Search
            preset={preset}
            isOpen={this.state.searchOpen}
            close={this.closeSearch}
            update={this.updatePreset}
            // updateMonthRange={this.updateMonthRange}
            adsMap={adsMap[selectedTab.tab]}
            adType={adType}
            selectedTab={selectedTab}
          />
        ) : null}
      </AsyncWrapper>
    )
  }
}

const Connected = connect(state => {
  const { dashboard } = state
  return {
    init: dashboard.init,
    groupName: dashboard.init.config.main.groupName,
    baseEndpoint: dashboard.init.config.baseEndpoint,
    config: dashboard.init.config.components.selection,
    genericConfig: dashboard.init.config.components.generic,
    sort: dashboard.selections.sort,
    baseRoute: dashboard.init.config.main.baseRoute,
    selections: dashboard.selections.slice.sliceSelections,
    monthRanges: dashboard.selections.search,
    calcFlags: dashboard.scorecard.calcFlags,
    adsMap: getAdsMap(dashboard),
    adType: getAdType(dashboard),
    selectedTab: getSelectedTab(dashboard),
    allPresets: getPresets(dashboard),
    presets: getSelectedPresets(dashboard),
    sortQuery: getSortQuery(dashboard)
  }
})

export default withRouter(Connected(Styled(Builder)))
