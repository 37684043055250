import templateComponents from '../../../Template/config/components'
import selection from './selection'
import scorecard from './scorecard'
import adminView from './adminView'
import quickview from './quickview'
import generic from './generic'
export default {
  ...templateComponents,
  selection,
  scorecard,
  adminView,
  quickview,
  generic
}
