import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MetadataSliceGridItem from './MetadataSliceGridItem'

const Styled = withStyles(theme =>
  theme.components.selection.MetadataSlice.MetadataSliceGridView(theme)
)

export default Styled(props => {
  const { values, select, selection } = props
  return (
    <Grid container alignItems="flex-start" justify="center" spacing={3}>
      {values.map((value, index) => (
        <Grid item key={value.field}>
          <MetadataSliceGridItem
            type={value}
            item={{ fieldValue: value.field, fieldIndex: index }}
            select={select}
            selection={selection}
          />
        </Grid>
      ))}
    </Grid>
  )
})
