import { combineReducers } from 'redux'

import { DASHBOARD_INIT, DASHBOARD_CLR_CONFIG } from '../../../new_actions'
import selectedTab from './tab'
import presets from './presets'
import filter from './filter'
import search from './search'
import slice from './slice'
import sort from './sort'
import tagHierarchy from './tagHierarchy'

const initialState = {
  start_month: null,
  end_month: null
}

const baseRange = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_INIT:
      const {
        metadata: { start_month, end_month }
      } = action.data
      return { start_month, end_month }

    case DASHBOARD_CLR_CONFIG: 
      return { ...initialState }
      
    default:
      return state
  }
}

export default combineReducers({
  baseRange,
  presets,
  selectedTab,
  filter,
  search,
  slice,
  sort,
  tagHierarchy
})
