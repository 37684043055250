export default {
  'tv': {
    '***': {
      '***': {
        extraFilter: {
          type: 'metadataFilter',
          ad_type: ['***'],
          disabledState: 'disabled', // 'hidden'
          data: {
            field: 'region',
            title: 'Select Region',
            options: [
              {
                label: 'Total Footprint',
                value: ['Total Footprint'],
                selected: true
              },
              {
                label: 'Northeast',
                value: ['Northeast'],
                selected: true
              },
              {
                label: 'West',
                value: ['West'],
                selected: true
              },
              {
                label: 'Central',
                value: ['Central'],
                selected: true
              }
            ]
          }
        }
      }
    }
  },
  'online_video': {
    '***': {
      '***': {
        extraFilter: {
          type: 'metadataFilter',
          ad_type: ['***'],
          disabledState: 'disabled', // 'hidden'
          data: {
            field: 'length',
            title: 'Ad Length (seconds)',
            options: [
              {
                label: '10 or less',
                value: [1,2,3,4,5,6,7,8,9,10],
                selected: true
              },
              {
                label: '15',
                value: [15],
                selected: true
              },
              {
                label: '30',
                value: [30],
                selected: true
              },
              {
                label: 'Other',
                value: 'other',
                selected: true
              }
            ]
          }
        }
      }
    }
  }
}
