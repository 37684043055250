import React from 'react'
import { Auth } from 'aws-amplify'
import AuthPiece from './AuthPiece'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import StyledContainer from './StyledContainer'

const styles = theme => ({
  submitButton: {
    width: '100%',
    minWidth: 'unset',
    margin: '30px 0px',
  },
})

class SignUp extends AuthPiece {
  constructor(props) {
    super(props)

    this._validAuthStates = ['signUp']
    this.signUp = this.signUp.bind(this)
  }

  signUp() {
    const { password, username, phone_number } = this.inputs

    if (!username) {
      return this.error('Email cannot be empty.')
    }

    if (!password) {
      return this.error('Password cannot be empty.')
    }

    if (!phone_number) {
      return this.error('Phone cannot be empty.')
    }

    Auth.signUp(username, password, username, phone_number)
      .then(() => this.changeState('confirmSignUp', username))
      .catch(err => this.error(err))
  }

  showComponent(theme) {
    const { hide, classes } = this.props
    if (hide && hide.includes(SignUp)) {
      return null
    }

    return (
      <StyledContainer message="Register">
        <TextField
          id="email"
          label="email"
          fullWidth
          margin="normal"
          name="username"
          onChange={this.handleInputChange}
        />
        <TextField
          id="password"
          label="password"
          fullWidth
          margin="normal"
          name="password"
          helperText="Passwords require a minimum length of 8 characters and must contain at least one number, one special character, one uppercase letter and one lowercase letter."
          type="password"
          onChange={this.handleInputChange}
        />
        <TextField
          id="phone_number"
          label="phone"
          name="phone_number"
          helperText="U.S. phone numbers add +1 e.g. +15551212"
          fullWidth
          margin="normal"
          onChange={this.handleInputChange}
        />
        <br />
        <Button
          variant="contained"
          color="secondary"
          className={classes.submitButton}
          onClick={this.signUp}
        >
          Submit
        </Button>
        <br />
        <Button
          color="primary"
          onClick={() => this.changeState('confirmSignUp')}
        >
          Confirm a Code
        </Button>
        <Button
          color="primary"
          style={{ marginLeft: 76 }}
          onClick={() => this.changeState('signIn')}
        >
          Sign In
        </Button>
      </StyledContainer>
    )
  }
}

export default withStyles(styles)(SignUp)
