export default theme => {
  return {
    root: {
      width: '100%'
    },
    header: {
        marginTop: 10,
        marginBottom: 10,
        padding: '3px 10px 3px 10px',
        borderRadius: 5,
        boxShadow: theme.shadows[4],
        backgroundColor: theme.palette.background.paper
      },
    list: {
      height: 380,
      width: '100%',
      overflow: 'scroll',
      borderRadius: 5,
      boxShadow: theme.shadows[4],
      backgroundColor: theme.palette.background.paper
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 7
    }
  }
}
