import { generateUUID } from '../../../new_utils'
import {
  DASHBOARD_INIT,
  DASHBOARD_SELECT_PRESET,
  DASHBOARD_SEARCH_TOGGLE_BRAND,
  DASHBOARD_SEARCH_SELECT_ALL_BRANDS,
  DASHBOARD_SET_EXTRA_FILTER,
  // DASHBOARD_TOGGLE_AD_TAG,
  // DASHBOARD_SEARCH_SET_MONTH_RANGE,
  DASHBOARD_UPDATE_EXTRA_FILTER_DATA
} from '../../../new_actions'

const initialState = {
  config: {},
  meta: {},
  maxRange: {
    start_month: null,
    end_month: null
  },
  // ad: {
  //   range: {
  //     start_month: null,
  //     end_month: null
  //   }
  // },
  // norm: {
  //   range: {
  //     start_month: null,
  //     end_month: null
  //   }
  // },
  brandsMap: {},
  // tags: [],
  extraFilter: {}
}

// function checkParent(list, id) {
//   const index = list.findIndex(tag => tag.id === id)

//   if (index === -1) {
//     return list
//   } else {
//     const listMap = {}

//     for (let i = 0; i < list.length; i++) {
//       listMap[list[i].id] = list[i].selected
//     }

//     let allChecked = true
//     let allUnChecked = true

//     list[index].children.forEach(d => {
//       if (listMap[d] !== 2) {
//         allChecked = false
//       }

//       if (listMap[d] !== 0) {
//         allUnChecked = false
//       }
//     })

//     const next = [
//       ...list.slice(0, index),
//       { ...list[index], selected: allChecked ? 2 : allUnChecked ? 0 : 1 },
//       ...list.slice(index + 1)
//     ]

//     if (list[index].parent !== 0) {
//       return checkParent(next, list[index].parent)
//     }

//     return next
//   }
// }

// function updateAdTag(list, id, value) {
//   const index = list.findIndex(tag => tag.id === id)

//   if (index === -1) {
//     return list
//   } else {
//     let next = [
//       ...list.slice(0, index),
//       { ...list[index], selected: value },
//       ...list.slice(index + 1)
//     ]

//     list[index].children.forEach(child => {
//       next = updateAdTag(next, child, value)
//     })

//     return next
//   }
// }

// function toggleAdTag(list, { id }) {
//   const adTag = list.find(t => t.id === id)

//   if (adTag) {
//     const next = updateAdTag(list, id, adTag.selected === 0 ? 2 : 0)

//     if (adTag.parent !== 0) {
//       return checkParent(next, adTag.parent)
//     }

//     return next
//   }

//   return list
// }

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_INIT:
      // const tags = action.data.config.components.selection.search.tagHierarchy
      // const extraFilter =
      //   action.data.config.components.selection.search.searchFilter.extraFilter
      return {
        ...state,
        // tags,
        // extraFilter,
        meta: action.data.metadata,
        maxRange: {
          start_month: action.data.metadata.start_month,
          end_month: action.data.metadata.end_month
        },
        ad: {
          range: {
            start_month: action.data.metadata.end_month - 1,
            end_month: action.data.metadata.end_month
          }
        },
        norm: {
          range: {
            start_month: action.data.metadata.end_month - 1,
            end_month: action.data.metadata.end_month
          }
        },
        brandsMap: action.data.metadata.brandsList.reduce((obj, brand) => {
          obj[brand.code] = true
          return obj
        }, {})
      }

    case DASHBOARD_SELECT_PRESET:
      const { start_month, end_month } = action.data.preset
      return {
        ...state,
        [action.data.type]: {
          range: {
            start_month,
            end_month
          }
        }
      }

    case DASHBOARD_SEARCH_TOGGLE_BRAND:
      const { code } = action.data

      return {
        ...state,
        brandsMap: {
          ...state.brandsMap,
          [code]: !state.brandsMap[code],
          uuid: generateUUID()
        }
      }

    case DASHBOARD_SEARCH_SELECT_ALL_BRANDS: {
      const { selected } = action.data
      const brandsMap = Object.keys(state.brandsMap).reduce((obj, brand) => {
        obj[brand] = selected
        return obj
      }, {})
      return {
        ...state,
        brandsMap: {
          ...brandsMap,
          uuid: generateUUID()
        }
      }
    }

    // case DASHBOARD_TOGGLE_AD_TAG:
    //   return {
    //     ...state,
    //     tags: toggleAdTag(state.tags, action)
    //   }

    // case DASHBOARD_SEARCH_SET_MONTH_RANGE:
    //   return {
    //     ...state,
    //     [action.data.tab]: {
    //       range: action.data.range
    //     }
    //   }

    case DASHBOARD_SET_EXTRA_FILTER:
      return {
        ...state,
        extraFilter: action.data
      }

    case DASHBOARD_UPDATE_EXTRA_FILTER_DATA:
      return {
        ...state,
        extraFilter: {
          ...state.extraFilter,
          ...action.data
        }
      }

    default:
      return state
  }
}
