import { createSelector } from 'reselect'
import { getSelectedAds } from '../selections'
import {compare } from '../../new_utils'
const getHasEditableNorm = dashboard => dashboard.init.config.hasEditableNorm
const _getAdsMap = dashboard => dashboard.init.metadata.adsMap

const getSort = dashboard => dashboard.selections.sort
const getTarget = dashboard => dashboard.selections.slice.sliceSelections.target
const getMaxAdSetSize = dashboard =>
  dashboard.init.config.components.selection.setBuilder.maxAdSetSize || 100

const getRanges = dashboard => {
  return {
    norm: { 
      start_month: dashboard.selections.presets.norm.start_month,
      end_month: dashboard.selections.presets.norm.end_month
    },
    ad: {
      start_month: dashboard.selections.presets.ad.start_month,
      end_month: dashboard.selections.presets.ad.end_month
    }
  }
}

const getCalcFlags = dashboard => dashboard.scorecard.calcFlags

const getPresets = dashboard => {
  const { sliceSelections } = dashboard.selections.slice
  if (Object.keys(sliceSelections).some(key => sliceSelections[key] === null)) {
    return dashboard.init.metadata.presets
  }

  return dashboard.init.metadata.presets
    .filter(preset => {
      return Object.keys(sliceSelections).reduce((flag, key) => {
        return flag && preset[key] === sliceSelections[key].field
      }, true)
    })
    .reduce((obj, preset) => {
      if (!Array.isArray(obj[preset.type])) obj[preset.type] = []
      obj[preset.type].push(preset)
      return obj
    }, {})
}

const getSampleSizeMetric = dashboard =>
  dashboard.init.config.components.scorecard.sampleSizeMetric

const getAdsList = dashboard => {
  const { slice: { sliceSelections }, selectedTab: { tab } } = dashboard.selections
  if (Object.keys(sliceSelections).some(key => sliceSelections[key] === null))
    return dashboard.init.metadata.adList

  return dashboard.init.metadata.adsList.filter(ad => {
    if ((tab === 'ad' && ad.normonly) || (tab === 'norm' && ad.adsonly))
      return false 
      
    return Object.keys(sliceSelections).reduce((flag, key) => {
      const field = Array.isArray(ad[key]) ? ad[key] : [ad[key]]
      return flag && field.includes(sliceSelections[key].field)
    }, true)
  })
}

const getAdType = dashboard => {
  return dashboard.selections.slice.sliceSelections.ad_type
}

const sortByMetric = (a, b, m) => {
  if (m.value < 0 || !m.value) return compare[m.field || 'ad_code'](a,b)
  if (!a.ranking_metric && b.ranking_metric) return 1
  if (!b.ranking_metric && a.ranking_metric) return -1
  if (!a.ranking_metric && !b.ranking_metric) return a.ad_code > b.ad_code ? 1 : -1
  return a.ranking_metric > b.ranking_metric ? -1 : 1
}

const getAdsMap = createSelector(
  [
    getSelectedAds,
    _getAdsMap,
    getSort,
    getHasEditableNorm,
    getTarget,
    getMaxAdSetSize,
    getSampleSizeMetric,
    getRanges,
    getCalcFlags
  ],
  (ads, adsMap, sort, hasEditableNorm, target, maxAdSetSize, sampleSizeMetric, ranges, calcFlags) => {
    const keys = Object.keys(ads)
    const adsData = keys.reduce((obj, key) => {
      const { ad_codes, uuid } = ads[key]
      const sortData = sort.sortData[key].data
      const sortMetric = sort.sortOptions.find(option => option.selected)
      const range = ranges[key]
      const excludeNonNorm = calcFlags[key].excludeNonNorm
      const includeAllHistory = calcFlags[key].includeAllHistory
      const list = ad_codes
      .map(ad_code => {
        let ad
        if (adsMap[ad_code]) {
          ad = { ...adsMap[ad_code] }
        } else {
          ad = {
            months: [],
            rollup_n: []
          }
        }
        const { months, rollup_n } = ad
        ad.ranking_metric =
          sortMetric && sortMetric.value > 0
            ? (sortData &&
              sortData[ad_code] &&
              sortData[ad_code][sortMetric.value] &&
              sortData[ad_code][sortMetric.value].avg) || 0 // See Item 7, #811
            : null

            ad.ranking_metric_n =
            sortMetric && sortMetric.value > 0
              ? (sortData &&
                sortData[ad_code] &&
                sortData[ad_code][sortMetric.value] &&
                sortData[ad_code][sortMetric.value].n) || 0 // See Item 7, #811
              : null

        ad.ranking_metric_format = sortMetric.dataFormat || '%,1'
        ad.last_month_tested =
          (months.length && months[months.length - 1].month_code) || null

        // ad.sample_size =
        //     sortData &&
        //     sortData[ad_code] &&
        //     sortData[ad_code][sampleSizeMetric] &&
        //     sortData[ad_code][sampleSizeMetric].n || 0

        ad.sample_size = ad.months.reduce( (sum, month) => {
            if ( month.month_code > range.end_month) return sum
            if ( !includeAllHistory && month.month_code < range.start_month ) return sum
            if ( excludeNonNorm && !month.is_norm) return sum
            return sum + month.n
        }, 0)


        ad.sample_size += (Array.isArray(rollup_n)
        ? rollup_n.reduce(
            (rolled_n, rollup) => {
              if (rollup.month_code > range.end_month) return rolled_n
              if ( !includeAllHistory && rollup.month_code < range.start_month ) return rolled_n
              if ( excludeNonNorm && !rollup.is_norm) return rolled_n
              return rolled_n + rollup.n
            },
            0
          )
        : 0)

          ad.sample_size = Math.round(ad.sample_size)
        return ad
      })
      .sort((a, b) => sortByMetric(a,b, sortMetric))
      const zeroList = list.filter( item => item.sample_size === 0)
      obj[key] = {
        list,
        zeroList,
        valid: ad_codes.length <= maxAdSetSize,
        uuid
      }
      return obj
    }, {})

    return adsData
  }
)

// export { getMetadataSlices, getPresets, getAdType, getAdsMap }
export { getPresets, getAdType, getAdsMap, getAdsList }
