import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import cn from 'classnames'

import {Icon as TypeIcon} from '../../../'

const Styled = withStyles(theme =>
  theme.components.selection.MetadataSlice.MetadataSliceGridView(theme)
)

class MetadataSliceGridItem extends Component {
  render() {
    const { classes, select, type, item, selection } = this.props

    return (
      <Grid
        container
        alignItems="flex-start"
        justify="center"
        className={classes.base}
        onClick={() => select(item)}
      >
        <Grid
          item
          xs={12}
          className={cn(
            classes.item,
            type.field === selection.field ? classes.activeType : classes.type
          )}
        >
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <IconButton
                disableRipple
                disabled
                className={cn(
                  classes.button,
                  type.field === selection.field ? classes.activeHover : classes.typeHover
                )}
              >
                <TypeIcon type={type.field} size="large" selected={type.field === selection.field}/>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Typography
            variant="h5"
            color="inherit"
            align="center"
            // noWrap
            className={classes.label}
          >
            {type.display}
          </Typography>
        </Grid>
      </Grid>
    )
  }
}


export default Styled(MetadataSliceGridItem)
