import React, { Component } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Excel from 'exceljs/dist/exceljs'
import {
  mergeCellsHorizontal,
  mergeCellsVertical,
} from './utils'

const dt = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export default class ScorecardExport extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFetching: false,
      hasErrored: false,
    }

    this.exportFile = this.exportFile.bind(this)
    this.fetchDataUrls = this.fetchDataUrls.bind(this)
  }

  // ASK: WH_6/9/18_1:26 PM - why name this fetchDataUrls when it's fetching images?
  fetchDataUrls() {
    const {
      scorecard: {
        data: { mainHeader },
      },
      urlPath,
      groupName,
    } = this.props
    this.setState({ isFetching: true })

    axios
      .get(urlPath, { params: { groupName, list: mainHeader.map(d => d.adCode) } }) // should fidelity be hard coded here?
      .then(
        res => {
          this.exportFile(res.data)
        },
        err => {
          this.setState({ isFetching: false, hasErrored: true })
        },
      )
  }

  formatColumn(sheet, col, dataIndex, cells, headerRows, format) {
    sheet.getColumn(col).eachCell(function(cell, row) {
      const index = row - headerRows - 1

      let item

      if (index >= 0 && index <= cells.length - 1) {
        item = cells[index][dataIndex]
      }

      format(cell, item, index)
    })
  }

  // ASK: WH_6/9/18_1:31 PM - is nh/normHeader the correctr field for the norm-n ?
  headerTitles = {
    title: 'Ad Title',
    adCode: 'Ad Code',
    sample: 'Sample',
    sov: 'SOV/Proprietary',
    model: 'Model',
    length: 'Duration',
    sampleSize: 'Base Size',
    spend_ltd: 'LTD Media Spend ($000s)',
    first_air_date: 'First Air or Print Date',
  }

  addHeaderRow(sheet, key, adCodeIndex, nh) {
    const {
      scorecard: {
        data: { mainHeader },
      },
    } = this.props

    const row = {}

    row.metric = ''
    row.norm = ''

    for (let i = 0; i < mainHeader.length; i++) {
      let d = mainHeader[i][key] || 'N/A'

      if (key === 'spend_ltd' && d !== 'N/A') {
        d = +d.replace('$', '').replace(/,/g, '')
        d = isNaN(d) ? 'N/A' : d
      }
      row[adCodeIndex[i]] = d
      row.metric = this.headerTitles[key]
    }
    sheet.addRow(row)
  }

  addLegend(sheet) {
    let length = 0
    const width = 4
    const { stats, thresholds } = this.props
    let cell;
    const rowNum = sheet.lastRow.model.number + 2
    const colNum = 3
    
    for (let key in stats) {
      const cell = mergeCellsHorizontal(sheet, rowNum + length++, colNum, width)
      cell.value = stats[key].description
      cell.alignment = {
        vertical: 'top',
        horizontal: 'left',
      }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: stats[key].bgColor.slice(1) },
      }
      cell.font = {
        name: 'Arial',
      }
    }

    length += 1

    cell = mergeCellsHorizontal(sheet, rowNum + length++, colNum, width)
    cell.value = `Cells with sample size < ${thresholds && thresholds.minSampleSizeInScorecard.value} are blanked`
    cell.font = { name: 'Arial' }
    cell.alignment = {
      vertical: 'top',
      horizontal: 'left',
    }

    cell = mergeCellsHorizontal(sheet, rowNum + length++, colNum, width)
    cell.value = `Cells with sample size < ${thresholds && thresholds.medSampleSizeInScorecard.value}`
    cell.alignment = {
      vertical: 'top',
      horizontal: 'left',
    }
    cell.font = {
      name: 'Arial',
      color: { argb: `${thresholds && thresholds.medSampleSizeInScorecard.color.replace('#','')}` },
      size: 10,
    }

    cell = mergeCellsHorizontal(sheet, rowNum + length++, colNum, width)
    cell.value = `Cells with sample size < ${thresholds && thresholds.maxSampleSizeInScorecard.value}`
    cell.alignment = {
      vertical: 'top',
      horizontal: 'left',
    }
    cell.font = {
      name: 'Arial',
      color: { argb: `${thresholds && thresholds.maxSampleSizeInScorecard.color.replace('#','')}` },
      size: 10,
    }

    const staticFilterSelection = this.props.scorecard.finalSelections
      .staticFilterSelection
    const key =
      typeof staticFilterSelection === 'object'
        ? Object.keys(staticFilterSelection)[0]
        : null
    cell = mergeCellsHorizontal(sheet, rowNum + length++, colNum, width)
    cell.value = `Filter Applied: ${
      key ? `${key}=${staticFilterSelection[key]}` : 'None'
    } `

    cell.alignment = {
      vertical: 'top',
      horizontal: 'left',
    }
    cell.font = {
      name: 'Arial',
      color: { argb: `392eff` },
      size: 10,
    }

    return length
  }

  exportFile(dataUrls) {
    const {
      scorecard: {
        data: {
          textTable: tt,
          normTable: nt,
          mainHeader: mh,
          normHeader: nh,
          mainTable: mt,
        },
      },
      data: { mediaType, monthText, phase },
      groupName,
    } = this.props

    const workbook = new Excel.Workbook()
    const sheet = workbook.addWorksheet('Scorecard')

    const headerRows = 11

    sheet.views = [
      {
        activeCell: 'Z1000',
        state: 'frozen',
        xSplit: 2,
        ySplit: headerRows,
        showGridLines: false,
      },
    ]

    const columns = [
      { header: '', key: 'metric', width: 45 },
      { header: '', key: 'norm', width: 10 },
    ]

    const adCodeIndex = {}

    for (let i = 0; i < mh.length; i++) {
      const { adCode } = mh[i]
      columns.push({ header: '', key: adCode, width: 15 })
      adCodeIndex[i] = adCode

      if (dataUrls[adCode].match('data:application')  || dataUrls[adCode].match('data:image')) {
        const imageId = workbook.addImage({
          base64: dataUrls[adCode],
          extension: 'jpeg',
        })

        sheet.addImage(imageId, {
          tl: { col: i + 2, row: 1 },
          br: { col: i + 3, row: 2 },
        })
      }
    }

    sheet.columns = columns

    sheet.getRow(1).font = { size: 12, bold: true }
    sheet.getRow(2).height = 50

    this.addHeaderRow(sheet, 'title', adCodeIndex)
    this.addHeaderRow(sheet, 'adCode', adCodeIndex)
    this.addHeaderRow(sheet, 'sample', adCodeIndex)
    this.addHeaderRow(sheet, 'sov', adCodeIndex)
    this.addHeaderRow(sheet, 'model', adCodeIndex)
    this.addHeaderRow(sheet, 'length', adCodeIndex)
    this.addHeaderRow(sheet, 'sampleSize', adCodeIndex, nh)
    this.addHeaderRow(sheet, 'spend_ltd', adCodeIndex)
    this.addHeaderRow(sheet, 'first_air_date', adCodeIndex)

    let cell = sheet.getCell('A1')
    cell.font = {
      name: 'Arial',
      bold: false,
      color: { argb: `33999999` },
    }
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: `33eeeeee` },
    }
    cell.value = `${mediaType} Ads`

    cell = sheet.getCell('A2')
    cell.value = `${monthText} - Phase 1 ${phase === 2 ? ` & 2` : ''}`
    cell.alignment = { vertical: 'top', horizontal: 'left' }
    cell.font = {
      name: 'Arial',
      bold: true,
      size: 14,
      color: { argb: `33505050` },
    }
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: `33eeeeee` },
    }

    cell = mergeCellsVertical(sheet, 1, 2, 11)
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: `33eeeeee` },
    }
    cell.font = {
      name: 'Arial',
      bold: true,
      size: 14,
      color: { argb: `33505050` },
    }
    cell.alignment = { vertical: 'top', horizontal: 'center' }
    cell.value = 'NORM'

    for (let i = 0; i < mt.length; i++) {
      const rowData = {}

      const offset =
        tt[i][0].style.offset === 1
          ? '    '
          : tt[i][0].style.offset === 2
            ? '        '
            : ''
      rowData.metric = tt[i][0] ? offset + tt[i][0].value : ''
      rowData.norm = nt[i][0] ? nt[i][0].rawValue : ''

      for (let j = 0; j < mh.length; j++) {
        const item = mt[i][j]
        rowData[adCodeIndex[j]] = item ? item.rawValue : ''
      }

      sheet.addRow(rowData)
    }

    this.formatColumn(sheet, 1, 0, tt, headerRows, (cell, item) => {
      cell.font = { name: 'Arial' }
      if (item) {
        const bold = !!(
          item.style.fontWeight && item.style.fontWeight.match(/bold/i)
        )
        cell.font = {
          name: 'Arial',
          bold: bold,
          color: {
            argb: `33${
              item.style.color ? item.style.color.slice(1) : '000000'
            }`,
          },
          size: 10,
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: `33${
              item.style.bgColor ? item.style.bgColor.slice(1) : 'ffffff'
            }`,
          },
        }
      }
    })

    this.formatColumn(sheet, 2, 0, nt, headerRows, (cell, item, index) => {
      cell.numFmt = cell.value > 1 ? '0' : '0%'
      if (item) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: `33${item.bgColor.slice(1)}` },
        }
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
        cell.font = { name: 'Arial' }
      }
    })

    for (let i = 0; i < mh.length; i++) {
      this.formatColumn(
        sheet,
        i + 3,
        i,
        mt,
        headerRows,
        (cell, item, index) => {
          if (index === -2) {
            cell.numFmt = '$#,###'
            cell.alignment = { vertical: 'middle', horizontal: 'center' }
          } else {
            cell.numFmt = cell.value > 1 ? '0' : '0%'
            cell.alignment = { vertical: 'middle', horizontal: 'center' }
          }
          cell.font = { name: 'Arial' }
          if (item) {
            if (item.value !== '') {
              cell.border = {
                top: { style: 'thin', color: { argb: '33dddddd' } },
                left: { style: 'thin', color: { argb: '33dddddd' } },
                bottom: { style: 'thin', color: { argb: '33dddddd' } },
                right: { style: 'thin', color: { argb: '33dddddd' } },
              }
            }

            cell.font = {
              name: 'Arial',
              color: { argb: `33${item.color.slice(1)}` },
              size: 10,
            }

            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: `33${item.bgColor.slice(1)}` },
            }
          }
        },
      )
    }

    this.addLegend(sheet)

    workbook.xlsx.writeBuffer({ base64: true }).then(xls64 => {
      const blb = new Blob([xls64], { type: dt })
      const url = URL.createObjectURL(blb)

      const selections = this.props.scorecard.finalSelections
        .dynamicFilterSelections
      const tag = document.createElement('a')
      tag.href = url
      tag.download = Object.keys(selections || {}).reduce( (name, selection) => {
          return `${name}_${selections[selection]}`
      }, `ScorecardExport_${groupName}`)
      tag.download = tag.download.replace(/[ -]/g, '_').replace(/_{2,}/g,'_') + '.xlsx'
      document.body.appendChild(tag)
      tag.click()

      this.setState({ isFetching: false, hasErrored: false })

      setTimeout(function() {
        document.body.removeChild(tag)
        window.URL.revokeObjectURL(url)
      }, 0)
    })
  }

  render() {
    const {
      scorecard: {
        data: { mainHeader },
      },
      children,
    } = this.props
    const { isFetching, hasErrored } = this.state
    const isDisabled = mainHeader.length === 0 || isFetching || hasErrored

    return (
      <Button
        color="secondary"
        variant="outlined"
        disabled={isDisabled}
        onClick={this.fetchDataUrls}
      >
        {children || 'Export'}
      </Button>
    )
  }
}

ScorecardExport.propTypes = {
  scorecard: PropTypes.shape({
    main: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      hasErrored: PropTypes.bool.isRequired,
      errMessage: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
      textHeader: PropTypes.array.isRequired,
      textTable: PropTypes.array.isRequired,
      normHeader: PropTypes.array.isRequired,
      normTable: PropTypes.array.isRequired,
      mainHeader: PropTypes.array.isRequired,
      mainTable: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}
