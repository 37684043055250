import MetadataSliceGridView from './MetadataSliceGridView'
import MetadataSliceListView from './MetadataSliceListView'

export default Object.assign(theme => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    title: {
      padding: 15
    },
    grid: {
      paddingBottom: 10
    },
    button: {
      margin: 20
    },
    boxShadow: {
      border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
      boxShadow: `0 1px 1px #E7E7E7`
    }
  }
},
{MetadataSliceGridView, MetadataSliceListView})
