import React from 'react'
import { FixedSizeList } from 'react-window'
import { AutoSizer } from "react-virtualized";
import { withStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { Grid, Typography, Tooltip } from '@material-ui/core'

const Styled = withStyles(theme =>
  theme.components.adminView.AdminMetadataManager.AdLister(theme)
)

class AdLister extends React.Component {

  renderRow = row => {
    const { adsList, selected, callback, classes, openMedia } = this.props
    const { index, style } = row
    const ad = adsList[index]
    const _tagged = ad.tags && Object.keys(ad.tags).length > 0
    const _selected = selected === ad['ad_code']
    return (
      <div style={style} key={adsList[index]['ad_code']} onClick={() => callback(adsList[index]['ad_code'])}>
        {/* <Grid container justify="space-between" alignContent="stretch" className={cn(_selected ? classes.selected : _tagged ? classes.tagged : null)}> */}
        <Grid container className={cn(_selected ? classes.selected : _tagged ? classes.tagged : null)}>
          <Grid item className={classes.adCode} xs={4}>
            <Tooltip title={adsList[index]['ad_code']} placement="top">
              <Typography variant="body2" noWrap>
                {adsList[index]['ad_code']}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item className={classes.title} xs={6}>
            <Typography variant="body2" noWrap >
              {adsList[index].title}
            </Typography>
          </Grid>
          <Grid item onClick={() => { openMedia(adsList[index]['ad_code']) }} xs={2}>
            <div
              className={classes.thumbnail}
              style={{
                cursor: `${ad.links.media ? 'pointer' : 'normal'}`,
                backgroundImage: `url(${ad.links.thumbnail || ad.links.thumb})`,
              }} >
            </div>
          </Grid>
        </Grid >
      </div >
    )
  }

  render() {
    const { adsList, uuid, selected, classes } = this.props

    return (
        <div className={classes.list}>
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList height={height} width={width} itemSize={45} itemCount={adsList.length} selected={selected} uuid={uuid}>
                {this.renderRow}
              </FixedSizeList>
            )
            }
          </AutoSizer>
        </div>
    )
  }
}

export default Styled(AdLister)
