import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Tabs from './Tabs'

const Styled = withStyles(theme => theme.components.generic.Tabs(theme))

// const tabsList = [
//     {
//       key: 'ad',
//       label: 'Ad Sets'
//     },
//     {
//       key: 'norm',
//       label: 'norms'
//     }
//   ]

export default Styled(props => {
  const { selectedTab, updateTab, classes, config } = props

  return (
    <Grid item xs={12} className={classes.tabBar}>
      <Tabs selectedTab={selectedTab} selectTab={updateTab} tabs={config.tabsList} />
    </Grid>
  )
})
