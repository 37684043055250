export default theme => {
    return {
        link: {
            color: theme.palette.text.primary,
            fontFamily: 'OktaNeueRegular',
            fontWeight: 500,
        },
        colorPrimary: {
          color: theme.palette.text.primary,
          fontFamily: 'OktaNeueRegular',
          fontWeight: 500
        }
    }
}
