import React, { PureComponent } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'

const styles = theme => ({
  control: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  disabled: {
    backgroundColor: theme.palette.grey[100],
  },
  menuItemRoot: {
    padding: 0,
  },
  menuText: {
    fontSize: 14,
  },
  icon: {
    color: 'red',
  },
  activeType: {
    '&:hover': {
      backgroundColor: theme.palette.listSelectBackground.default,
    }
  }
})

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 600,
      width: 225,
      padding: 0,
    },
  },
}

class DisplayFilterElement extends PureComponent {
  formatSelected = options => {
    return options
      .filter(d => d.selected === true)
      .map(d => d.label)
      .join(', ')
  }

  render() {
    const {
      classes,
      element: { label, code, multi, options },
      update,
      stale
    } = this.props
    const disabled = !options //|| options.length === 1

    const classnames = cn(classes.control, disabled ? classes.disabled : null)
   
    return (
      <Grid className={classnames} style={{ padding: 6 }} item xs>
        <FormControl disabled={disabled || stale} fullWidth>
          <InputLabel
            style={{ fontSize: 14 }}
            htmlFor={`id-for-${label}-display-filter`}
          >
            {label}
          </InputLabel>
          <Select
            multiple={multi}
            value={
              options.length &&
              options.filter(d => d.selected === true).map(d => d.value)
            }
            onChange={e => update({ code, value: e.target.value, multi })}
            input={
              <Input disableUnderline id={`id-for-${label}-display-filter`} />
            }
            renderValue={() => this.formatSelected(options)}
            MenuProps={MenuProps}
          >
            {options.map(({ value, label, selected }) => (
              <MenuItem key={`option-${value}`} value={value} className={classes.activeType}>
                <Checkbox checked={selected} color="secondary" />
                <ListItemText
                  classes={{
                    root: classes.menuItemRoot,
                    primary: classes.menuText,
                  }}
                  primary={label}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    )
  }
}

DisplayFilterElement.propTypes = {
  // label: PropTypes.string.isRequired,
  // options: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     label: PropTypes.string.isRequired,
  //   }),
  // ).isRequired,
  disabled: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

DisplayFilterElement.defaultProps = {
  disabled: false,
  multi: false,
}

export default withStyles(styles)(DisplayFilterElement)
