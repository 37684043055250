import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core';

const Styled = withStyles( theme => theme.components.generic.ExportButton(theme))
export default Styled( props => {
  const {disabled} = props
  return (
    <Grid item>
      <Button variant="outlined" color="secondary" onClick={props.action} disabled={disabled}>
        {`Export ${props.type}`}
      </Button>
    </Grid>
  )
})
