import {
  DASHBOARD_CLR_CONFIG,
  DASHBOARD_INIT,
  DASHBOARD_UPDATE_STATIC_FILTER,
  DASHBOARD_COMPLETE_METADATA_SLICE
} from '../../../new_actions'

import { generateUUID } from '../../../new_utils'

const initialState = {
  displayFilters: {
    uuid: null
  },
  staticFiltersConfig: {},
  staticFilters: {
    uuid: null
  },
  staticFilterUUID: null
}

export default (state = initialState, action) => {
  switch (action.type) {

    case DASHBOARD_INIT:

      const staticFilters  = action.data.config.components.scorecard.scorecardView.scorecardFilter

      return {
        ...state,
        staticFiltersConfig: staticFilters,
        selectedFilterConfig: {},
        staticFilterUUID: null
      }

    case DASHBOARD_COMPLETE_METADATA_SLICE: {

      const selected = action.data.map( slice => slice.values.find( option => option.selected ))

      const selectedFilterConfig = state.staticFiltersConfig && selected.reduce((obj, selection) => {
        return ( selection && obj[selection.field] ) || obj['***'] || {}
      }, state.staticFiltersConfig)

      return selectedFilterConfig.staticFilters ? {
        ...state,
        displayFilters: selectedFilterConfig.displayFilters,
        staticFilters: {
          ...selectedFilterConfig.staticFilters.reduce((obj, filter) => {
            obj[filter.code] = filter
            return obj
          }, {})
        },
        staticFilterUUID: generateUUID()
      } : state
    }

    case DASHBOARD_UPDATE_STATIC_FILTER: {
      const { code, selected } = action.data
      const filter = state.staticFilters[code]
      // const options = filter.options.filter(option => filter.multi || !option.selected).map(option => {
      const options = filter.options.map(option => {
        return {
          ...option,
          selected: selected.includes(option.value)
        }
      })

      return {
        ...state,
        staticFilters: {
          ...state.staticFilters,
          [code]: {
            ...state.staticFilters[code],
            options
          }
        },
        staticFilterUUID: generateUUID()
      }
    }

    case DASHBOARD_CLR_CONFIG:
      return {
        ...initialState
      }

    default:
      return state
  }
}
