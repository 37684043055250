import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {
  Grid,
  Button,
  AppBar,
  Dialog,
  DialogActions,
  Toolbar,
  Typography,
  TextField
} from '@material-ui/core'

const Styled = withStyles(theme =>
  theme.components.generic.SaveAdSetDialog(theme)
)

class SaveAdSetDialog extends React.PureComponent {
  state = {
    saveOpen: false,
    saveText: '',
    validText: false
  }

  updateText = e => {
    const saveText = e.target.value
    const validText =
      saveText === saveText.replace(/[^a-zA-Z0-9!:#&\- ]/g, '_') &&
      saveText.length > 3
    this.setState({
      saveText,
      validText
    })
  }

  open = () => {
    this.setState(() => ({
      saveOpen: true,
      saveText: '',
      validText: false
    }))
  }

  close = setName => {
    const { savePreset } = this.props
    if (setName) savePreset(setName)

    this.setState(() => ({
      saveOpen: false,
      saveText: '',
      validText: false
    }))
  }

  keyPressed = e => {
    if (e.key === 'Enter') this.close(e.target.value)
  }

  render() {
    const { title, classes } = this.props
    const { saveOpen, saveText, validText } = this.state
    return (
      <Fragment>
        <Button onClick={this.open} variant="outlined" color="secondary" className={classes.button}>Save</Button>
        <Dialog maxWidth={false} open={saveOpen} onClose={() => this.close(null)}>
          <div className={classes.dialog}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" color="textPrimary">
                  {`Save ${title}`}
                </Typography>
              </Toolbar>
            </AppBar>
            <Grid container justify="center" className={classes.grid}>
              <Grid xs={10} item>
                <TextField
                  classes={{root: classes.textField}}
                  fullWidth
                  value={saveText}
                  onChange={this.updateText}
                  onKeyPress={this.keyPressed}
                  label="Set Name"
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={() => this.close(null)} color="inherit">
                Close
              </Button>
              <Button
                disabled={!validText}
                onClick={() => this.close(saveText)}
                color="secondary"
              >
                Save
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </Fragment>
    )
  }
}

export default Styled(SaveAdSetDialog)
