import React, { Component } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import BulkFilters from './BulkFilters'
import DisplayFilters from './DisplayFilters'
import { withStyles } from '@material-ui/core/styles'
import { exportBulkQuery } from '../../../lib'

const dt = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

const styles = theme => ({
  title: {
    backgroundColor: theme.palette.background,
    color: theme.palette.text.primaryt,
  },
  content: {
    height: 400,
    position: 'relative'
  },
})


class BulkQueryDialog extends Component {

  state = {
    disabled: false
  }

  exportFile(result) {

    const { groupName, bulkQuery: { staticFilters }, config, done } = this.props
    const filename = `BulkQuery-${groupName}`

    const workbook = exportBulkQuery({...result, config, groupName})

    let filterString = 'NoFilter'
    if (result.staticFilter) {
      const filterStruct = staticFilters.find(f => f.code === result.staticFilter.code)
      const temp = {
        label: filterStruct.label,
        value: filterStruct.options.find(option => result.staticFilter.value.includes(option.value)).label
      }
      filterString = `${temp.label}=${temp.value}`
    }

    workbook.xlsx.writeBuffer({ base64: true }).then(xls64 => {
      const blb = new Blob([xls64], { type: dt })
      const url = URL.createObjectURL(blb)

      const tag = document.createElement('a')
      tag.href = url

      tag.download = `${filename}-${filterString}`
      document.body.appendChild(tag)
      tag.click()

      setTimeout(function () {
        document.body.removeChild(tag)
        window.URL.revokeObjectURL(url)
        done()
      }, 0)

    })

  }

  componentDidUpdate(prevProps) {
    const { bulkQuery: { result }, stats } = this.props
    if (result.uuid !== prevProps.bulkQuery.result.uuid) {
      this.exportFile({...result, stats})
    }
  }

  render() {
    const { classes, bulkQuery, updateBulkQueryFilters, updateStaticFilters, generate, open, closeDialog } = this.props
    return (
      <Dialog
        open={open}
        onClose={closeDialog}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle className={classes.title}>
          <div>
            <Typography align="center" className={classes.title} variant="h6">
              Report Download Parameters
          </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            alignItems="flex-start"
            justify="space-around"
            className={classes.content}
          >
            <Grid item xs={12}>
              <Typography variant='h6'>Primary Filters</Typography>
              <BulkFilters elements={bulkQuery.bulkFilters} update={updateBulkQueryFilters} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6'>Secondary Filters</Typography>
              <DisplayFilters elements={bulkQuery.staticFilters} update={updateStaticFilters} />
            </Grid>
            <Grid item xs={6}>
              {/* <Typography variant='h6'>Brand Filter</Typography>
              <DisplayFilters elements={bulkQuery.displayFilters} update={updateDisplayFilters} /> */}
            </Grid>
            <Grid item xs={6} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => generate(false)} variant="contained" >
            Cancel
        </Button>
          <Button onClick={() => generate(true)} variant="contained" color="secondary" disabled={this.state.disabled}>
            Generate
        </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(BulkQueryDialog)
