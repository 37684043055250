import defaultTheme from '../../Template/theme'
import components from './components'
// import palette from './palette'

const theme = {
  ...defaultTheme,
  // palette,
  components
}

export { theme }
export default theme
