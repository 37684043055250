export default theme => {
  return {
    listRoot: {
      width: 450,
      maxHeight: 400,
      position: 'relative',
      overflow: 'auto'
    },
    listHeader: {
      padding: 5,
      backgroundColor: theme.palette.grey[100],
      border: `thin solid ${theme.palette.grey[100]}`
    },
    activeType: {
      color: theme.palette.secondary.main
    },
    type: {
      color: theme.palette.text.primary
    },
    listItem: {
      width: 400,
      padding: 5,
      border: `thin solid ${theme.palette.grey[100]}`
    }
  }
}
