// Formats: '%,n', '$,n', '$x,n', 'f,n', 'i,n'
// operations: 'last(a)', prod(a,b), ratio(a,b)
export default {
  '***': {
    '***': [
      {
        metric: 1,
        label: 'AdPi Score',
        inHeader: true,
        rowType: 'demphasized',
        dataFormat: '.,0',
        reverseStat: false
      },
      {
        label: 'IN-MARKET POTENTIAL',
        rowType: 'hb0'
      },
      // {
      //   label: 'BREAKTHROUGH',
      //   rowType: 'h0'
      // },
      // {
      //   metric: 200,
      //   label: 'Branded Recall (Last Month Tested)',
      //   rowType: 'd0',
      //   dataFormat: '%,1',
      //   reverseStat: false,
      //   recall: {
      //     metric: 2
      //   }
      // },
      // {
      //   metric: 2,
      //   label: 'Branded Recall (Avg. Recall)',
      //   rowType: 'd0',
      //   dataFormat: '%,1',
      //   reverseStat: false
      // },
      // {
      //   metric: 3,
      //   label: 'Total Recall',
      //   rowType: 'd0',
      //   dataFormat: '%,1',
      //   reverseStat: false
      // }, 
      {
        label: 'IMPACT',
        rowType: 'h0'
      },
      {
        metric: 4,
        label: 'Lift to Consideration',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 5,
        label: 'Lift to Impression',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 6,
        label: 'Net Actions',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 7,
        label: 'Discuss the brand with friends or family',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 8,
        label: 'Search online for more information',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 9,
        label: 'Take advantage of an offer or promotion',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 10,
        label: 'Go out of my way to eat at this restaurant',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 11,
        label: 'Visit a location in the next 30 days',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 12,
        label: 'Try the product/menu item in the next 30 days',
        rowType: 'd1',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        label: 'CREATIVE DIAGNOSTICS',
        rowType: 'hb0'
      },
      {
        label: 'BRANDING',
        rowType: 'h0'
      },
      {
        metric: 13,
        label: 'Brand Recognition',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 14,
        label: 'Ease of Branding - Overall',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 15,
        label: 'Ease of Branding - Excluding Logo/Brand Name',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 16,
        label: 'Brand Prominence',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 17,
        label: 'Unique to this Brand',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 18,
        label: 'Good Fit with Brand',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        label: 'APPEAL',
        rowType: 'h0'
      },
      {
        metric: 19,
        label: 'Likes',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: false
      },
      {
        metric: 20,
        label: 'Dislikes',
        rowType: 'd0',
        dataFormat: '%,1',
        reverseStat: true
      },
      {
        label: 'CREATIVE EFFICIENCY',
        rowType: 'h0'
      },
      {
        metric: 21,
        label: 'Brand Value',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 22,
        label: 'Shows food I\'m willing to pay a bit more for',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 23,
        label: 'Shows a restaurant that I would be proud to visit',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 24,
        label: 'Is a good value for the money',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 25,
        label: 'Product Presentation',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 26,
        label: 'Shows high quality food items',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 27,
        label: 'Shows new menu item/deal I want to try',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 28,
        label: 'Shows a restaurant that provides excellent service',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 29,
        label: 'Shows a restaurant that is welcoming and friendly',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 30,
        label: 'Shows a restaurant available wherever I might be',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 31,
        label: 'Brand Connection',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 32,
        label: 'Portrays a brand I want to be associated with',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 33,
        label: 'Portrays a brand that fulfills my needs',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 34,
        label: 'Portrays a brand I can trust',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 35,
        label: 'Relevance',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 36,
        label: 'Spoke directly to me',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 37,
        label: 'Reassuring',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 38,
        label: 'Shows people and situations I can relate to',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 39,
        label: 'Addresses an issue that\'s important to me',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 40,
        label: 'Cognitive Connection',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 41,
        label: 'Is a credible message coming from this brand',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 42,
        label: 'Tells me something new about this brand',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 43,
        label: 'Informative',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 44,
        label: 'Clear',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 45,
        label: 'Presents information at a good pace',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 46,
        label: 'Confusing',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: true
      },
      {
        metric: 47,
        label: 'Engagement',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 48,
        label: 'Worth talking about',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 49,
        label: 'Heartwarming',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 50,
        label: 'Clever',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 51,
        label: 'Unique',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        // metric: 52,
        label: 'MEMORABILITY',
        rowType: 'h0'
        // rowType: 'dh0',
        // dataFormat: 'idx',
        // reverseStat: false
      },
      {
        metric: 53,
        label: 'Entertaining',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 54,
        label: 'Humorous',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 55,
        label: 'Grabs my attention',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 56,
        label: 'Memorable',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 57,
        label: 'Stays with me (able to describe later)',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: false
      },
      {
        metric: 58,
        label: 'Boring',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: true
      },
      {
        metric: 59,
        label: 'Annoying',
        rowType: 'd0',
        dataFormat: '.,2',
        reverseStat: true
      },
      {
        label: '',
        rowType: 'eol'
      }
    ]
  }
}
