import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MetadataSlice } from '../../new_components'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Tabs } from '../../new_components'

import {
  dashboardInit,
  dashboardSelectMetadataSlice,
  dashboardCompleteMetadataSlice,
  dashboardSelectPreset,
} from '../../new_actions'
import { getPresets } from '../../new_selectors'

const Styled = withStyles(theme =>
  theme.components.selection.MetadataSlice(theme)
)

class Slicer extends React.PureComponent {
  state = {
    ready: false,
    sliceIndex: 0
  }

  selectSliceType = selectedSlice => {
    const { tab, index } = selectedSlice
    this.setState({
      sliceType: tab,
      sliceIndex: index
    })
  }

  select = ({ fieldValue, fieldIndex }) => {
    const { dispatch } = this.props
    const { sliceType, sliceIndex } = this.state

    dispatch(
      dashboardSelectMetadataSlice({
        sliceType,
        sliceIndex,
        fieldValue,
        fieldIndex
      })
    )
  }

  componentDidMount() {
    const { dispatch, init } = this.props

    dispatch(dashboardInit(init))

    this.setState({
      ready: true,
      tabs: init.config.metadata.slices.filter(slice=>!slice.hidden).map(slice => {
        return {
          key: slice.field,
          label: slice.display
        }
      })
    })
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      selections: {
        slice: { slices } },
      presets
    } = this.props

    if (
      slices.some((slice, index) => {
        if (!prevProps.selections.slice.slices[index]) return true
        return slice.uuid !== prevProps.selections.slice.slices[index].uuid
      })
    ) {
      if (presets.norm && presets.norm.length)
        dispatch(
          dashboardSelectPreset({ type: 'norm', preset: presets.norm[0] })
        )
    }
  }

  componentWillUnmount() {
    const { dispatch, selections: { slice : { slices }}} = this.props
    // TODO: Figure out why slicer gets mounted and unmounted spuriously once
    if (slices.length > 0) dispatch( dashboardCompleteMetadataSlice(slices))
  }

  render = () => {
    const {
      classes,
      history,
      init: {
        config: {
          main: { baseRoute },
          components: {
            selection: {
              metadataSlice: { navButtons }
            }
          }
        }
      },
      selections: {
        slice: { slices, sliceSelections }
      }
    } = this.props

    const { ready } = this.state

    if (!ready) return null

    const { tabs, sliceIndex } = this.state
    const slice = slices[sliceIndex]
    const sliceType = slice.field
    const selection = sliceSelections[sliceType]
    const builderDisabled = Object.keys(sliceSelections).some(
      key => sliceSelections[key].field === null
    )

    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="center"
        className={classes.grid}
      >
        <Grid xs={10} item>
          <Paper style={{ padding: 50, marginTop: 30 }} elevation={0} classes={{elevation0: classes.boxShadow}}>
            <Grid item>
              <Tabs
                selectedTab={{ tab: sliceType }}
                selectTab={this.selectSliceType}
                tabs={tabs}
              />
            </Grid>
            <MetadataSlice
              slice={slice}
              selection={selection}
              select={this.select}
              uuid={slice.uuid}
            />
            <Grid
              container
              alignItems="center"
              justify="center"
              spacing={3}
              style={{ marginTop: 25 }}
            >
              <Grid>
                {navButtons.preset ? (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color={'secondary'}
                    onClick={() => history.push(`${baseRoute}/preset`)}
                  >
                    Preset
                  </Button>
                ) : null}
                {navButtons.builder ? (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color={'secondary'}
                    onClick={() => history.push(`${baseRoute}/builder`)}
                    disabled={builderDisabled}
                  >
                    Builder
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const Connected = connect(state => {
  const { dashboard } = state
  return {
    init: dashboard.init,
    selections: dashboard.selections,
    presets: getPresets(dashboard)
  }
})

export default withRouter(Connected(Styled(Slicer)))
