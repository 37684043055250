export default theme => {
  return {
    container: {
      paddingTop: 10,
      marginBottom: 10,
      background: theme.palette.background.default
    },
    titleBar: {
      marginTop: 2,
      marginBottom: 2,
      paddingLeft: 40,
      paddingRight: 40
    },
    tabBar: {
      paddingLeft: 40,
      paddingRight: 40
    },
    highlight: {
      color: theme.palette.secondary.main
    },
    colorPrimary: {
      color: theme.palette.text.primary
    },
    mainLabel: {
        fontFamily: 'OktaNeueRegular',
        fontWeight: 700
      }
  }
}
