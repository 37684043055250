import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'

import {
  AlarmOff,
  Alarm,
  DoneAll,
  DonutLarge,
  Warning,
  Clear
} from '@material-ui/icons'

const Styled = withStyles(theme =>
  theme.components.adminView.AdminConfigManager.AdminDataMigrations(theme)
)
const statusIcons = {
  canceled: AlarmOff,
  scheduled: Alarm,
  completed: DoneAll,
  pending: DonutLarge,
  failed: Warning,
  acknowledged: Clear
}
const CustomTableCell = TableCell
class AdminExternalDataMigrations extends React.PureComponent {
  render() {
    const { classes, migrations } = this.props

    return migrations && migrations.length ? (
      <Grid container className={classes.container}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell>App</CustomTableCell>
              <CustomTableCell>Environment</CustomTableCell>
              <CustomTableCell>Notes</CustomTableCell>
              <CustomTableCell>Status</CustomTableCell>
              <CustomTableCell>Scheduled at</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {migrations.map(migration => {
              const StatusIcon = statusIcons[migration.status] || null
              return (
                <TableRow
                  className={classes.migration}
                  key={migration.migration_id}
                >
                  <CustomTableCell component="th" scope="migration">
                    {migration.app_name}
                  </CustomTableCell>
                  <CustomTableCell component="th" scope="migration">
                    {migration.environment}
                  </CustomTableCell>
                  <CustomTableCell>{migration.comment}</CustomTableCell>
                  <CustomTableCell>
                    <StatusIcon />
                  </CustomTableCell>
                  <CustomTableCell>{migration.scheduled_time}</CustomTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Grid>
    ) : (
      <Grid>
        <Typography variant="caption" align="center">
          No Other Jobs Scheduled or Pending
        </Typography>
      </Grid>
    )
  }
}

export default Styled(AdminExternalDataMigrations)
