import axios from 'axios'

export const FORD_OPEN_BULK_QUERY = 'FORD_OPEN_BULK_QUERY'
export const FORD_UPDATE_BULK_QUERY_FILTER = 'FORD_UPDATE_BULK_QUERY_FILTER'
export const FORD_UPDATE_BULK_QUERY_STATIC_FILTER = 'FORD_UPDATE_BULK_QUERY_STATIC_FILTER'
export const FORD_UPDATE_BULK_QUERY_DISPLAY_FILTER = 'FORD_UPDATE_BULK_QUERY_DISPLAY_FILTER'
export const FORD_BULK_QUERY_REQUEST = 'FORD_BULK_QUERY_REQUEST'
export const FORD_BULK_QUERY_RECEIVE = 'FORD_BULK_QUERY_RECEIVE'
export const FORD_BULK_QUERY_DONE = 'FORD_BULK_QUERY_DONE'
export const FORD_BULK_QUERY_FAILURE = 'FORD_BULK_QUERY_FAILURE'

export function openBulkQuery(value) {
  return {
    type: FORD_OPEN_BULK_QUERY,
    data: value,
  }
}

export function updateBulkQueryFilter(data) {
  return {
    type: FORD_UPDATE_BULK_QUERY_FILTER,
    data
  }
}

export function updateBulkQueryStaticFilter(data) {
  return {
    type: FORD_UPDATE_BULK_QUERY_STATIC_FILTER,
    data
  }
}

export function updateBulkQueryDisplayFilter(data) {
  return {
    type: FORD_UPDATE_BULK_QUERY_DISPLAY_FILTER,
    data
  }
}

export function bulkQueryRequest() {
  return {
    type: FORD_BULK_QUERY_REQUEST,
  }
}

export function bulkQueryReceive(data) {
  return {
    type: FORD_BULK_QUERY_RECEIVE,
    data
  }
}

export function bulkQueryFailure(data) {
  return {
    type: FORD_BULK_QUERY_FAILURE,
    data
  }
}

export function bulkQueryDone(data) {
  return {
    type: FORD_BULK_QUERY_DONE,
    data
  }
}

export function fetchBulkQueryData({ slicerMap, slicerList, staticFilterQuery, displayFiltersSlicer }) {
  return dispatch => {
    dispatch(bulkQueryRequest())
    return axios
      .post('/bulk-query', { params: { slicerMap, slicerList, staticFilter: staticFilterQuery, displayFiltersSlicer } })
      .then(result => {
        dispatch(bulkQueryReceive(result.data))
        return result
      })
      .catch(error => {
        dispatch(bulkQueryFailure(error))
      })
  }
}
