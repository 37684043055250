const DASHBOARD_SELECT_METADATA_SLICE = 'DASHBOARD_SELECT_METADATA_SLICE'
const DASHBOARD_COMPLETE_METADATA_SLICE = 'DASHBOARD_COMPLETE_METADATA_SLICE'

const dashboardSelectMetadataSlice = data => {
  return {
    type: DASHBOARD_SELECT_METADATA_SLICE,
    data
  }
}

const dashboardCompleteMetadataSlice = data => {
  return {
    type: DASHBOARD_COMPLETE_METADATA_SLICE,
    data
  }
}

export { 
  DASHBOARD_SELECT_METADATA_SLICE, 
  DASHBOARD_COMPLETE_METADATA_SLICE,
  dashboardSelectMetadataSlice,
  dashboardCompleteMetadataSlice
 }
