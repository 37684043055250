export default {
  '***': {
    displayFilters: null,
    staticFilters: [
      {
        label: 'Age',
        code: 'age',
        multi: true,
        options: [
          {
            label: '18-24',
            value: 1,
            selected: false
          },
          {
            label: '25-34',
            value: 2,
            selected: false
          },
          {
            label: '35-44',
            value: 3,
            selected: false
          },
          {
            label: '45-54',
            value: 4,
            selected: false
          },
          {
            label: '55-64',
            value: 5,
            selected: false
          },
          {
            label: '65+',
            value: 6,
            selected: false
          }
        ]
      },
      {
        label: 'Gender',
        code: 'gender',
        multi: true,
        options: [
          {
            label: 'Male',
            value: 1,
            selected: false
          },
          {
            label: 'Female',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Amazon Account',
        code: 'amazon',
        multi: true,
        options: [
          {
            label: 'Yes',
            value: 1,
            selected: false
          },
          {
            label: 'No',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Prime Member',
        code: 'prime',
        multi: true,
        options: [
          {
            label: 'Yes',
            value: 1,
            selected: false
          },
          {
            label: 'No',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Audiobook Rejecter',
        code: 'rejecter',
        multi: true,
        options: [
          {
            label: 'Rejecter',
            value: 1,
            selected: false
          },
          {
            label: 'Non-Rejecter',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Daily Commuter',
        code: 'commuter',
        multi: true,
        options: [
          {
            label: 'Daily Commuter',
            value: 1,
            selected: false
          },
          {
            label: 'Not Checked',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Connected Consumer',
        code: 'connected',
        multi: true,
        options: [
          {
            label: 'Connected Consumer',
            value: 1,
            selected: false
          },
          {
            label: 'Not Checked',
            value: 2,
            selected: false
          }
        ]
      },
      {
        label: 'Segment',
        code: 'segment',
        multi: true,
        options: [
          {
            label: 'Immersed Enthusiast',
            value: 1,
            selected: false
          },
          {
            label: 'Inspiration Seeker',
            value: 2,
            selected: false
          },
          {
            label: 'Rooted Reader',
            value: 3,
            selected: false
          },
        ]
      },
      {
        label: 'General Population',
        code: 'genpop',
        multi: true,
        options: [
          {
            label: 'General Population',
            value: 1,
            selected: false
          },
          {
            label: 'Augment',
            value: 2,
            selected: false
          },
        ]
      },
      {
        label: 'Audible Account',
        code: 'audible_account',
        multi: true,
        options: [
          {
            label: 'Yes',
            value: 1,
            selected: false
          },
          {
            label: 'No',
            value: 2,
            selected: false
          },
        ]
      },
      {
        label: 'Paid Account',
        code: 'paid_account',
        multi: true,
        options: [
          {
            label: 'Yes',
            value: 1,
            selected: false
          },
          {
            label: 'No',
            value: 2,
            selected: false
          },
        ]
      },
    ]
  }
}
