// Formats: '%,n', '$,n', '$x,n', 'f,n', 'i,n'
// operations: 'last(a)', prod(a,b), ratio(a,b)
export default {
  'tv': {
    '***': [
      {
        metric: 106,
        label: 'IN-MARKET AdPi® (Modeled Score)',
        rowType: 'dh0',
        dataFormat: '.,0',
        reverseStat: false,
        minSampleSize: 0,
        
      },
      {
        metric: 100,
        label: 'Recall',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 102,
        label: 'Lift To Brand Impression  (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 103,
        label: 'Lift To Purchase Consideration (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 107,
        label: '% Net Actions Taken/Intended',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 101,
        label: 'Correct Brand ID',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 110,
        label: 'Ease of Branding (Top Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 112,
        label: 'Breakthrough (Engagement/Relevance)',
        rowType: 'd0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 104,
        label: 'Likeability (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 105,
        label: 'Wear Out (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        label: '',
        rowType: 'eol'
      },
      {
        metric: 109,
        label: 'CREATIVE AdPi® (Modeled Score)',
        rowType: 'dh0',
        dataFormat: '.,0',
        reverseStat: false
      },
      {
        metric: 131,
        label: 'Shows a Vehicle That I\'d Really Love To Buy',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 127,
        label: 'Makes Me Want To Take Action',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 124,
        label: 'Spoke Directly To Me',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 144,
        label: 'Worth Talking About / Worth Seeing Again',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 115,
        label: 'RELEVANCE CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 126,
        label: 'Shows People And Situations I Can Relate To',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 129,
        label: 'Directly Addresses Issues Important To Me',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 128,
        label: 'Portrays A Company I Want To Be Associated With',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 118,
        label: 'EMPATHETIC CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 145,
        label: 'Inspiring / Moving',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 143,
        label: 'Reassuring / Meaningful',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 130,
        label: 'Credible And Honest',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 116,
        label: 'COGNITIVE CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 146,
        label: 'Shows New Side Of Brand / Something New',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 123,
        label: 'Informative',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 113,
        label: 'FOCAL CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 136,
        label: 'Safety',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 138,
        label: 'Performance',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 137,
        label: 'Fuel Efficiency',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 139,
        label: 'Quality',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      }, 
      {
        metric: 114,
        label: 'ENGAGEMENT CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 121,
        label: 'Entertaining',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 125,
        label: 'Unique',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 120,
        label: 'Clever',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 122,
        label: 'Humorous',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 140,
        label: 'Grabs My Attention',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 147,
        label: 'Great Music Soundtrack',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 119,
        label: 'STYLING CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 134,
        label: 'Portrays An Innovative Brand',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 135,
        label: 'Cool',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 141,
        label: 'Visually Appealing',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },      
      {
        metric: 142,
        label: 'Modern',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        label: 'DISENGAGEMENT',
        rowType: 'hb0'
      },
      {
        metric: 132,
        label: 'Boring',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 133,
        label: 'Confusing',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        label: '',
        rowType: 'eol'
      }
    ]
  },
  'online_display': {
    '***': [
      {
        metric: 106,
        label: 'IN-MARKET AdPi® (Modeled Score)',
        rowType: 'dh0',
        dataFormat: '.,0',
        reverseStat: false,
        minSampleSize: 0,
        
      },
      {
        metric: 100,
        label: 'Recall',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 102,
        label: 'Lift To Brand Impression  (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 103,
        label: 'Lift To Purchase Consideration (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 107,
        label: '% Net Actions Taken/Intended',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 101,
        label: 'Correct Brand ID',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 110,
        label: 'Ease of Branding (Top Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 112,
        label: 'Breakthrough (Engagement/Relevance)',
        rowType: 'd0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 104,
        label: 'Likeability (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 105,
        label: 'Wear Out (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        label: '',
        rowType: 'eol'
      },
      {
        metric: 109,
        label: 'CREATIVE AdPi® (Modeled Score)',
        rowType: 'dh0',
        dataFormat: '.,0',
        reverseStat: false
      },
      {
        metric: 131,
        label: 'Shows a Vehicle That I\'d Really Love To Buy',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 127,
        label: 'Makes Me Want To Take Action',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 144,
        label: 'Worth Talking About / Worth Seeing Again',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 140,
        label: 'Grabs My Attention',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 115,
        label: 'RELEVANCE CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 124,
        label: 'Spoke Directly To Me',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 126,
        label: 'Shows People And Situations I Can Relate To',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 129,
        label: 'Directly Addresses Issues Important To Me',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 128,
        label: 'Portrays A Company I Want To Be Associated With',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 118,
        label: 'EMPATHETIC CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 145,
        label: 'Inspiring / Moving',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 143,
        label: 'Reassuring / Meaningful',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 130,
        label: 'Credible And Honest',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 114,
        label: 'ENGAGEMENT CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 121,
        label: 'Entertaining',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 125,
        label: 'Unique',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 120,
        label: 'Clever',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 122,
        label: 'Humorous',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 116,
        label: 'COGNITIVE CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 146,
        label: 'Shows New Side Of Brand / Something New',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 123,
        label: 'Informative',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 113,
        label: 'FOCAL CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 136,
        label: 'Safety',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 138,
        label: 'Performance',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 137,
        label: 'Fuel Efficiency',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 139,
        label: 'Quality',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      }, 
      {
        metric: 119,
        label: 'STYLING CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 134,
        label: 'Portrays An Innovative Brand',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 135,
        label: 'Cool',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 141,
        label: 'Visually Appealing',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },      
      {
        metric: 142,
        label: 'Modern',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        label: 'DISENGAGEMENT',
        rowType: 'hb0'
      },
      {
        metric: 132,
        label: 'Boring',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 133,
        label: 'Confusing',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        label: '',
        rowType: 'eol'
      }
    ]
  },
  'print': {
    '***': [
      {
        metric: 106,
        label: 'IN-MARKET AdPi® (Modeled Score)',
        rowType: 'dh0',
        dataFormat: '.,0',
        reverseStat: false,
        minSampleSize: 0,
        
      },
      {
        metric: 100,
        label: 'Recall',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 102,
        label: 'Lift To Brand Impression  (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 103,
        label: 'Lift To Purchase Consideration (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 107,
        label: '% Net Actions Taken/Intended',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 101,
        label: 'Correct Brand ID',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 110,
        label: 'Ease of Branding (Top Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 112,
        label: 'Breakthrough (Engagement/Relevance)',
        rowType: 'd0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 104,
        label: 'Likeability (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 105,
        label: 'Wear Out (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        label: '',
        rowType: 'eol'
      },
      {
        metric: 109,
        label: 'CREATIVE AdPi® (Modeled Score)',
        rowType: 'dh0',
        dataFormat: '.,0',
        reverseStat: false
      },
      {
        metric: 131,
        label: 'Shows a Vehicle That I\'d Really Love To Buy',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 127,
        label: 'Makes Me Want To Take Action',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 124,
        label: 'Spoke Directly To Me',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 140,
        label: 'Grabs My Attention',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 115,
        label: 'RELEVANCE CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 126,
        label: 'Shows People And Situations I Can Relate To',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 129,
        label: 'Directly Addresses Issues Important To Me',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 128,
        label: 'Portrays A Company I Want To Be Associated With',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 118,
        label: 'EMPATHETIC CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 145,
        label: 'Inspiring / Moving',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 143,
        label: 'Reassuring / Meaningful',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 130,
        label: 'Credible And Honest',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 116,
        label: 'COGNITIVE CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 146,
        label: 'Shows New Side Of Brand / Something New',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 123,
        label: 'Informative',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 113,
        label: 'FOCAL CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 136,
        label: 'Safety',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 138,
        label: 'Performance',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 137,
        label: 'Fuel Efficiency',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 139,
        label: 'Quality',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      }, 
      {
        metric: 114,
        label: 'ENGAGEMENT CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 121,
        label: 'Entertaining',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 125,
        label: 'Unique',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 120,
        label: 'Clever',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 122,
        label: 'Humorous',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 144,
        label: 'Worth Talking About / Worth Seeing Again',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 119,
        label: 'STYLING CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 134,
        label: 'Portrays An Innovative Brand',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 135,
        label: 'Cool',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 141,
        label: 'Visually Appealing',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },      
      {
        metric: 142,
        label: 'Modern',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        label: 'DISENGAGEMENT',
        rowType: 'hb0'
      },
      {
        metric: 132,
        label: 'Boring',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 133,
        label: 'Confusing',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        label: '',
        rowType: 'eol'
      }
    ]
  },
  'ooh': {
    '***': [
      {
        metric: 106,
        label: 'IN-MARKET AdPi® (Modeled Score)',
        rowType: 'dh0',
        dataFormat: '.,0',
        reverseStat: false,
        minSampleSize: 0,
        
      },
      {
        metric: 100,
        label: 'Recall',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 102,
        label: 'Lift To Brand Impression  (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 103,
        label: 'Lift To Purchase Consideration (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 107,
        label: '% Net Actions Taken/Intended',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 101,
        label: 'Correct Brand ID',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 110,
        label: 'Ease of Branding (Top Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 112,
        label: 'Breakthrough (Engagement/Relevance)',
        rowType: 'd0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 104,
        label: 'Likeability (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        metric: 105,
        label: 'Wear Out (Top 2 Box)',
        rowType: 'd0',
        dataFormat: '%,0',
        reverseStat: false
      },
      {
        label: '',
        rowType: 'eol'
      },
      {
        metric: 109,
        label: 'CREATIVE AdPi® (Modeled Score)',
        rowType: 'dh0',
        dataFormat: '.,0',
        reverseStat: false
      },
      {
        metric: 131,
        label: 'Shows a Vehicle That I\'d Really Love To Buy',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 127,
        label: 'Makes Me Want To Take Action',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 124,
        label: 'Spoke Directly To Me',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 140,
        label: 'Grabs My Attention',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 115,
        label: 'RELEVANCE CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 126,
        label: 'Shows People And Situations I Can Relate To',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 129,
        label: 'Directly Addresses Issues Important To Me',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 128,
        label: 'Portrays A Company I Want To Be Associated With',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 118,
        label: 'EMPATHETIC CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 145,
        label: 'Inspiring / Moving',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 143,
        label: 'Reassuring / Meaningful',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 130,
        label: 'Credible And Honest',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 116,
        label: 'COGNITIVE CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 146,
        label: 'Shows New Side Of Brand / Something New',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 123,
        label: 'Informative',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 113,
        label: 'FOCAL CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 136,
        label: 'Safety',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 138,
        label: 'Performance',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 137,
        label: 'Fuel Efficiency',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 139,
        label: 'Quality',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      }, 
      {
        metric: 114,
        label: 'ENGAGEMENT CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 121,
        label: 'Entertaining',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 125,
        label: 'Unique',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 120,
        label: 'Clever',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },

      {
        metric: 122,
        label: 'Humorous',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 144,
        label: 'Worth Talking About / Worth Seeing Again',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 119,
        label: 'STYLING CONNECTION',
        rowType: 'dh0',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 134,
        label: 'Portrays An Innovative Brand',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 135,
        label: 'Cool',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 141,
        label: 'Visually Appealing',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },      
      {
        metric: 142,
        label: 'Modern',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        label: 'DISENGAGEMENT',
        rowType: 'hb0'
      },
      {
        metric: 132,
        label: 'Boring',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        metric: 133,
        label: 'Confusing',
        rowType: 'd1',
        dataFormat: 'idx',
        reverseStat: false
      },
      {
        label: '',
        rowType: 'eol'
      }
    ]
  }
}
