export default {
  '***':
    [
      {
        "id": 100,
        "label": "Industry",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          102,
          104,
          106,
          108,
          110,
          112,
          114,
          116,
          118,
          120,
          122,
          124,
          126,
          128,
          130,
          132,
          134,
          136,
          138,
          140
        ],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 200,
        "label": "Covid-19 Designation",
        "selected": 0,
        "collapse": 0,
        "parent": 0,
        "children": [
          201,
          202
        ],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 102,
        "label": "Alcoholic Beverages",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 104,
        "label": "Auto",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 106,
        "label": "Consumer Financial Services",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 108,
        "label": "Cosmetic & Beauty",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 110,
        "label": "eCommerce",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 112,
        "label": "Entertainment",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 114,
        "label": "Food",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 116,
        "label": "Grocery",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 118,
        "label": "Household Supplies",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 120,
        "label": "Insurance",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 122,
        "label": "Investment Services",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 124,
        "label": "Non-Alcoholic Beverages",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 126,
        "label": "Personal Health & Hygiene",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 128,
        "label": "Pet Food & Supplies",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 130,
        "label": "Pharmaceuticals & Healthcare",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 132,
        "label": "Restaurant",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 134,
        "label": "Retail",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 136,
        "label": "Tech Devices",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 138,
        "label": "Travel",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 140,
        "label": "Wireless",
        "selected": 0,
        "collapse": 0,
        "parent": 100,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 201,
        "label": "Covid-19 Ad",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "No tooltip provided"
      },
      {
        "id": 202,
        "label": "Non Covid-19 Ad",
        "selected": 0,
        "collapse": 0,
        "parent": 200,
        "children": [],
        "tooltip": "No tooltip provided"
      }
    ]
}