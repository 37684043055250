import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'

const Styled = withStyles( theme => theme.components.generic.Confirmation(theme))

class Confirmation extends React.PureComponent {

  componentDidCatch(a) {
      console.log(a)
  }

  render() {
    const {open, confirm, cancel, message, title} = this.props
    // const m = message.split('.')
    const messages = Array.isArray(message) ? message : [message]
    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContent id="alert-dialog-description">
            { messages.map( message => 
                          <Typography variant="subtitle1" gutterBottom key={message}>
                          {message}
                        </Typography>
              )}

            {/* <Typography variant="subtitle1" gutterBottom>
              {m[1]}
            </Typography> */}
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancel} color="secondary">
              Cancel
            </Button>
            <Button onClick={confirm} color="secondary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Styled(Confirmation)
