import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'

import ScorecardLegend from './ScorecardLegend'
import ScorecardExport from './ScorecardExport'
import { MediaButton } from '../../../'

const Styled = withStyles(theme => theme.components.scorecard.ScorecardView.ScorecardFooter(theme))

class ScorecardFooter extends React.PureComponent {
  state = {
    showLegend: false
  }

  toggleLegend = () => {
    const { updateLegend } = this.props
    this.setState({
      showLegend: !this.state.showLegend
    })
    updateLegend(!this.state.showLegend)
  }

  render() {
    const {
      classes,
      adType,
      selections,
      sampleSizeThreshold,
      stats,
      gridData,
      groupName,
      baseEndpoint,
      adSets,
      filter,
      adsMap,
      generateError,
      hideExportMediaButton,
      config: { scorecardExport, scorecardStatTesting },
      genericConfig
    } = this.props
    const { showLegend } = this.state
    return (
      <Drawer variant="permanent" anchor="bottom" PaperProps={{ style: { overflowY: 'hidden' } }}>
        <div id={`scorecard-footer-id`}>
          <Grid container justify="space-between">
            <Grid item className={classes.drawerButton}>
              <Button color="inherit" variant="contained" onClick={this.toggleLegend}>
                {showLegend ? 'Hide Legend' : 'Show Legend'}
              </Button>
            </Grid>
            <Grid item className={classes.drawerButton}>
              <Grid container justify="flex-end" spacing={1}>
                <Grid item>
                  <ScorecardExport
                    gridData={gridData}
                    config={scorecardExport}
                    statTesting={scorecardStatTesting.labels}
                    adSets={adSets}
                    filter={filter}
                    adType={adType}
                    adsMap={adsMap}
                    selections={selections}
                    disabled={!gridData.metrics}
                    genericConfig={genericConfig}
                  />
                </Grid>
                <Grid item>
                  {hideExportMediaButton ? null : (
                    <MediaButton
                      mediaType={adType.mediaType}
                      adList={gridData.adHeaders}
                      endPoint={`${baseEndpoint}/${groupName}/media/download`}
                      groupName={groupName}
                      generateError={generateError}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {showLegend ? (
              <Grid xs={10} item>
                <ScorecardLegend
                  stats={stats}
                  sampleSizeThreshold={sampleSizeThreshold}
                  config={scorecardStatTesting}
                  genericConfig={genericConfig}
                />
              </Grid>
            ) : null}
          </Grid>
        </div>
      </Drawer>
    )
  }
}

export default Styled(ScorecardFooter)
