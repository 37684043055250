import defaultTheme from '../../Template/theme'
// import palette from './palette'
import components from './components'
const theme = {
  ...defaultTheme,
  // palette,
  components
}

export {theme}
export default theme
