import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import DisplayFilterElement from './DisplayFilterElement'

const styles = theme => ({
  paper: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    marginTop: 10,
    marginBottom: 20,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  boxShadow: {
    border: `1px solid ${theme.palette.background.default}`,
    boxShadow: `0 1px 1px rgba(0,0,0,0.14)`
  }
})

class DisplayFilters extends PureComponent {
  render() {
    const { classes, update, elements, stale } = this.props

    return (
      <Paper elevation={0} className={classes.paper} classes={{ elevation0: classes.boxShadow }}>
        <Grid container spacing={0} alignItems="stretch">
          {elements.map((filter, index) => {
            return (
              <DisplayFilterElement
                key={filter.label}
                element={filter}
                update={update}
                stale={stale}
              />
            )
          })}
        </Grid>
      </Paper>
    )
  }
}

DisplayFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  scorecard: PropTypes.shape({
    textHeader: PropTypes.array.isRequired,
    textTable: PropTypes.array.isRequired,
    normHeader: PropTypes.array.isRequired,
    normTable: PropTypes.array.isRequired,
    mainHeader: PropTypes.array.isRequired,
    mainTable: PropTypes.array.isRequired,
  }),
}

export default withStyles(styles)(DisplayFilters)
