import scorecardMainHeader from './ScorecardMainHeader'
import scorecardAdHeader from './ScorecardAdHeader'
import scorecardNormHeader from './ScorecardNormHeader'
import scorecardMetricsLabels from './ScorecardMetricsLabels'
import scorecardNormValues from './ScorecardNormValues'
import scorecardMetricsValues from './ScorecardMetricsValues'

export default {
  dimensions: {
    rowHeight: 30,
    headerHeight: 225,
    headerHeightCollapsed: 130,
    metricsLabelsWidth: 350,
    normWidth: 110,
    metricWidth: 100
  },
  scorecardMainHeader,
  scorecardNormHeader,
  scorecardAdHeader,
  scorecardMetricsLabels,
  scorecardNormValues,
  scorecardMetricsValues
}
