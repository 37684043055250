import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { ScrollSync } from 'react-virtualized'

import ScorecardFixedColumn from './ScorecardFixedColumn'
import ScorecardScrollableColumn from './ScorecardScrollableColumn'

const Styled = withStyles(theme => {
  return {
    ...theme.components.scorecard.ScorecardView.ScorecardScroller(theme),
    gridRow: {
      position: 'relative',
      display: 'flex',
      flexDirections: 'row'
    },
    gridHeader: {
      width: '100%',
      overflow: 'hidden !important'
    },
    gridLeft: {
      overflow: 'hidden !important'
    },
    gridBody: {
      width: '100%'
    }
  }
})

class ScorecardScroller extends React.PureComponent {

  renderScroller = ({ onScroll, scrollLeft, scrollTop }) => {
    const {
      classes,
      viewHeight,
      config: {
        scorecardMainHeader,
        scorecardMetricsLabels,
        scorecardNormHeader,
        scorecardNormValues,
        scorecardAdHeader,
        scorecardMetricsValues,
        dimensions: { 
          rowHeight,
          // headerHeight,
          headerHeightCollapsed,
          metricsLabelsWidth,
          normWidth,
          metricWidth
        }
      },
      gridData,
      gridData: {
        rowCount,
        columnCount
      },
      openMedia,
      collapse,
      collapsed,
      openDetails,
      openQuickview
    } = this.props

    const headerHeight = collapsed ? headerHeightCollapsed : gridData.headerHeight
    return (
      <div className={classes.gridRow}>
        <ScorecardFixedColumn
          config={{
            rowCount,
            columnWidth: metricsLabelsWidth,
            headerHeight: collapsed ? headerHeightCollapsed : headerHeight,
            scrollTop,
            rowHeight
          }}
          title={scorecardMainHeader}
          rows={scorecardMetricsLabels}
          viewHeight={viewHeight}
          gridData={gridData}
          collapse={collapse}
          collapsed={collapsed}
        />
        <ScorecardFixedColumn
          config={{
            rowCount,
            headerHeight: collapsed ? headerHeightCollapsed : headerHeight,            
            columnWidth: normWidth,
            scrollTop,
            rowHeight
          }}
          title={scorecardNormHeader}
          rows={scorecardNormValues}
          viewHeight={viewHeight}
          leftOffset={metricsLabelsWidth}
          gridData={gridData}
          collapse={collapse}
          collapsed={collapsed}
        />
        <ScorecardScrollableColumn
          viewHeight={viewHeight}
          title={scorecardAdHeader}
          rows={scorecardMetricsValues}
          config={{
            rowCount,
            columnCount,
            headerHeight: collapsed ? headerHeightCollapsed : headerHeight,
            metricWidth,
            rowHeight,
            scrollLeft,
            onScroll
          }}
          leftOffset={metricsLabelsWidth + normWidth}
          gridData={gridData}
          openMedia={openMedia}
          collapse={collapse}
          collapsed={collapsed}
          openDetails={openDetails}
          openQuickview={openQuickview}
        />
      </div>
    )
  }

  render() {
    const {
      props: { viewHeight, gridData },
      renderScroller
    } = this

    return (
      <div style={{ height: viewHeight, position: 'relative' }}>
        <ScrollSync dummy={viewHeight} collapsed={this.props.collapsed} gridData={gridData}>{renderScroller}</ScrollSync>
      </div>
    )
  }
}

export default Styled(ScorecardScroller)
