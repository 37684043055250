import setBuilder from '../../../../../Template/config/components/selection/SetBuilder'
import setBuilderToolbar from './setBuilderToolbar'
import setBuilderExport from './setBuilderExport'

export default  {
    ...setBuilder,
    hasPresetButton: false,
    includeSampleSize: false,
    hasNormsTab: false,
    hasAdStorage: false,
    setBuilderToolbar, 
    setBuilderExport
}