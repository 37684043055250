import React, { PureComponent, Fragment } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { format as formatd3 } from 'd3-format'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { AsyncWrapper } from '../../../'
import { monthCodeToText } from '../../../../new_utils'
import DetailHeader from '../DetailsView/DetailHeader'
import ExportTrendCharts from './ExportTrendCharts'

const dollars = formatd3('$,.0f')

const chartHeight = 350

const styles = {
  paper: {
    margin: '10px 0px',
    padding: 20
  },
  boxShadow: {
    border: `1px solid #FAF9FA`,
    boxShadow: `0 1px 1px #E7E7E7`
  }
}

const titleOptions = {
  display: false,
  position: 'top',
  fontFamily: "'Roboto', Arial"
}

const getScalesOptions = (fmt = '.0%', min = 0, max = 1) => data => {
  
  return {
    yAxes: [
      {
        ticks: { min, max, callback: formatd3(fmt) }
      }
    ],
    xAxes: [
      {
        ticks: {
          callback: function(value, index) {
            const month = value || ''
            const size =
              data.trendCharts.persuasion &&
              data.trendCharts.persuasion[index].size

            const tick = [month]

            if (size) {
              tick.push(`Size: ${Math.round(size)}`)
            }

            return tick
          }
        }
      }
    ]
  }
}

const tooltipsOptions = tooltipFormat => {
  return {
    callbacks: {
      label: function(item, data) {
        var label = data.datasets[item.datasetIndex].label || ''

        if (label) {
          label += ': '
        }

        if (item.datasetIndex === 2) {
          label += dollars(item.yLabel)
        } else {
          label += formatd3(tooltipFormat || '')(item.yLabel)
        }

        return label
      }
    }
  }
}

class Trendchart extends PureComponent {
  createChartData = () => {
    const { data, normData, theme, gridData } = this.props
    const { normHeaders } = gridData

    return (
      data.trendMetrics &&
      data.trendMetrics.map((trendMetric, index) => {
        const { field, label, format, scale, hasSpendData } = trendMetric
        const norm =
          normData.filter(
            normRow => normRow.metric === trendMetric.metric
          )[0] ||
          normHeaders.header_metrics.filter(
            header_metric => header_metric.metric === trendMetric.metric
          )[0]
        const chartFormat = format
        const chartLabel = label
        const chartField = field
        const chartScale = scale  ? scale : format.match('%') ? [0, 1] : [80, 160]
        const chartData = {
          labels:
            data.trendCharts &&
            data.trendCharts[field] &&
            data.trendCharts[field].map(d => monthCodeToText(d.month_code)),
          datasets: [
            {
              label: 'Norm',
              fill: false,
              lineTension: 0,
              backgroundColor: theme.palette.grey[700],
              borderColor: theme.palette.grey[700],
              data:
                data.trendCharts &&
                data.trendCharts[field] &&
                data.trendCharts[field].map(d => norm && norm.avg)
            },
            {
              label: chartLabel,
              fill: false,
              lineTension: 0,
              backgroundColor: theme.palette.graphs.default,
              borderColor: theme.palette.graphs.default,
              data:
                data.trendCharts &&
                data.trendCharts[field] &&
                data.trendCharts[field].map(d => d.avg)
            }
          ]
        }

        let chartOptions = {
          maintainAspectRatio: false,
          legend: {
            onClick: () => {}
          },
          title: {
            ...titleOptions,
            text: chartLabel
          },
          scales: {
            ...getScalesOptions(chartFormat, ...chartScale)(data)
          },
          tooltips: {
            ...tooltipsOptions(chartFormat)
          }
        }

        if (hasSpendData) {
          chartData.datasets = [
            {
              label: 'Norm',
              type: 'line',
              fill: false,
              lineTension: 0,
              backgroundColor: theme.palette.grey[700],
              borderColor: theme.palette.grey[700],
              data:
                data.trendCharts[field] &&
                data.trendCharts[field].map(d => norm.avg),
              yAxisID: 'y-axis-1'
            },
            {
              label: chartLabel,
              type: 'line',
              fill: false,
              lineTension: 0,
              backgroundColor: theme.palette.graphs.default,
              borderColor: theme.palette.graphs.default,
              data:
                data.trendCharts[field] &&
                data.trendCharts[field].map(d => d.avg),
              yAxisID: 'y-axis-1'
            },
            {
              label: 'Spend (000s)',
              type: 'bar',
              fill: true,
              backgroundColor: theme.palette.trendchartBox.default,
              // backgroundColor: theme.palette.graphs.default,
              borderColor: theme.palette.trendchartBox.default,
              data: data.spend && data.spend.map(d => d.spend),
              yAxisID: 'y-axis-2'
            }
          ]

          chartOptions = {
            ...chartOptions,
            scales: {
              ...chartOptions.scales,
              yAxes: [
                {
                  position: 'left',
                  id: 'y-axis-1',
                  ticks: {
                    min: scale ? scale[0] : 0,
                    max: scale ? scale[1] : 1.0,
                    callback: function(value) {
                      return formatd3(chartFormat)(value)
                    }
                  }
                },
                {
                  position: 'right',
                  id: 'y-axis-2',
                  ticks: {
                    min: 0.0,
                    callback: function(value) {
                      return dollars(value)
                    }
                  },
                  gridLines: {
                    display: false,
                    color: 'rgba(0,0,0,0)'
                  }
                }
              ]
            }
          }
        }

        return {
          hasSpendData,
          chartField,
          chartLabel,
          chartFormat,
          chartData,
          chartOptions
        }
      })
    )
  }

  render() {
    const {
      classes,
      ad,
      data,
      normData,
      adType,
      handleClose,
      selectTab,
      selectedTab,
      openMedia,
      gridData
    } = this.props

    const trendCharts = this.createChartData()
    const metricsData = data

    return (
      <Fragment>
        <DetailHeader
          ad={ad}
          adType={adType}
          handleClose={handleClose}
          selectTab={selectTab}
          selectedTab={selectedTab}
          openMedia={openMedia}
        >
          <ExportTrendCharts
            data={{ metricsData, normData }}
            ad={ad}
            gridData={gridData}
          />
        </DetailHeader>
        <AsyncWrapper item={data} size={300} thickness={1}>
          <Grid container justify="center">
            <Grid item xs={10}>
              <div style={{ padding: '20px 5px 200px' }}>
                <Grid container>
                  {trendCharts &&
                    trendCharts.length &&
                    trendCharts.map((trendChart, index) => {
                      const Item = trendChart.hasSpendData ? Bar : Line
                      return (
                        <Grid item xs={12} key={trendChart.chartField}>
                          <Typography align="left" variant="h6">
                            {trendChart.chartLabel}
                          </Typography>
                          <Paper
                            id={trendChart.chartField}
                            className={classes.paper}
                            elevation={0}
                            classes={{elevation0:classes.boxShadow}}
                          >
                            <Item
                              data={trendChart.chartData}
                              height={chartHeight}
                              options={trendChart.chartOptions}
                            />
                          </Paper>
                        </Grid>
                      )
                    })}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </AsyncWrapper>
      </Fragment>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Trendchart)
