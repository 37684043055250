// Formats: '%,n', '$,n', '$x,n', 'f,n', 'i,n'
// operations: 'last(a)', prod(a,b), ratio(a,b)
export default {
  '***': {
    '***': {
      '***': [
        {
          metric: 100,
          label: 'In-Market Potential Score',
          rowType: 'demphasized',
          dataFormat: '.,0',
          reverseStat: false,
          inHeader: true,
          minSampleSize: 0
        },
        {
          label: 'IN-MARKET PERFORMANCE',
          rowType: 'hb0'
        },
        {
          label: 'BREAKTHROUGH',
          rowType: 'h0'
        },

        {
          metric: 111,
          label: 'Breakthrough Potential',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          label: 'IMPACT',
          rowType: 'h0'
        },
        {
          metric: 121,
          label: 'Lift to Consideration',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 122,
          label: 'Lift to Impression',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 123,
          label: 'Net Actions',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 124,
          label: 'Talk about the ad with friends/family',
          rowType: 'd1',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 125,
          label: 'Go online for more info',
          rowType: 'd1',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 126,
          label: 'Call for more info',
          rowType: 'd1',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 127,
          label: 'Go to a retail store that sells these services',
          rowType: 'd1',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 128,
          label: "Purchase this brand's products or services",
          rowType: 'd1',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          label: 'CREATIVE DIAGNOSTICS',
          rowType: 'hb0'
        },
        {
          label: 'BRANDING',
          rowType: 'h0'
        },
        {
          metric: 131,
          label: 'Brand Recognition',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 132,
          label: 'Ease of Branding',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        // {
        //   metric: 133,
        //   label: 'Good Fit with Brand',
        //   rowType: 'd0',
        //   dataFormat: '%,1',
        //   reverseStat: false
        // },
        {
          label: 'VALUE PROPOSITION',
          rowType: 'h0'
        },
        {
          metric: 141,
          label: 'Would bring enjoyment to my everyday life',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 142,
          label: 'Is different than what other providers offer',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 143,
          label: 'Would add value to my life',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 144,
          label: 'Is a good value for the money',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 145,
          label: 'Would make me feel more connected',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          label: 'APPEAL',
          rowType: 'h0'
        },
        {
          metric: 151,
          label: 'Likes',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 152,
          label: 'Dislikes',
          rowType: 'd0',
          dataFormat: '%,1',

          reverseStat: false
        },
        {
          metric: 200,
          label: 'CREATIVE POTENTIAL SUMMARY SCORE',
          rowType: 'dh0',
          dataFormat: '.,0',
          reverseStat: false
        },
        // Pathway
        {
          metric: 210,
          label: 'Brand Connection',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 211,
          label: 'Portrays a brand I want to be associated with',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 212,
          label: 'Fulfills my needs',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 213,
          label: 'Makes me feel good about the brand',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 214,
          label: 'Spoke directly to me',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 215,
          label: 'Gives me peace of mind',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        // Pathway
        {
          metric: 220,
          label: 'Relevance',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 221,
          label: 'Shows people/situations I can relate to',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 222,
          label: 'Addresses important issues to me',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 223,
          label: 'Is relevant to me',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        // Pathway
        {
          metric: 230,
          label: 'Brand Reappraisal',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 231,
          label: 'Makes me think of the brand in a new way',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 232,
          label: 'Tells me something new about this brand',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        // Pathway
        {
          metric: 240,
          label: 'Brand Value',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 241,
          label: 'Is a brand I trust',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 242,
          label: 'Has innovative technology',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 243,
          label: "Provides unique features I can't get from others",
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 244,
          label: 'Provides a great entertainment experience',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 245,
          label: 'Has products/services that work well together',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 246,
          label: 'Offers flexible plans to meet a variety of needs',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 247,
          label: 'Is a good value for the money',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        // Pathway
        {
          metric: 250,
          label: 'Cognitive Connection',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 251,
          label: 'Informative',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 252,
          label: 'Clear',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 253,
          label: 'Confusing',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 254,
          label: 'Believable',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 255,
          label: 'Good Fit with Brand',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        // {
        //   metric: 256,
        //   label: 'Makes me think of the brand in a new way',
        //   rowType: 'd0',
        //   dataFormat: '.,2',
        //   reverseStat: false
        // },
        // {
        //   metric: 257,
        //   label: 'Tells me something new about this brand',
        //   rowType: 'd0',
        //   dataFormat: '.,2',
        //   reverseStat: false
        // },
        // Pathway
        {
          metric: 260,
          label: 'Emotional Connection',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 261,
          label: 'Values its customers',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 262,
          label: 'Is inspiring',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 263,
          label: 'Is reassuring',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 264,
          label: 'Is heartwarming',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        // Pathway
        {
          metric: 270,
          label: 'Engagement',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 271,
          label: 'Is clever',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 272,
          label: 'Is unique',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 273,
          label: 'Is entertaining',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 274,
          label: 'Is humorous',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 275,
          label: 'Is worth seeing again',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 276,
          label: 'Is memorable',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 277,
          label: 'Is irritating',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 10000,
          label: 'MMM',
          rowType: 'hidden',
          dataFormat: '.,2',
          aggregated: true,
          stdTest: 'fixedThr'
        },
        {
          metric: 10001,
          label: 'Branding / Emotional',
          rowType: 'hidden',
          dataFormat: '.,2',
          aggregated: true
        },
        {
          metric: 10002,
          label: 'Relevant',
          rowType: 'hidden',
          dataFormat: '.,2',
          aggregated: true
        },
        {
          metric: 10003,
          label: 'Something Different',
          rowType: 'hidden',
          dataFormat: '.,2',
          aggregated: true
        },
        {
          metric: 10004,
          label: 'Call to Action',
          rowType: 'hidden',
          dataFormat: '.,2',
          aggregated: true
        },
        {
          metric: 10005,
          label: 'Value',
          rowType: 'hidden',
          dataFormat: '.,2',
          aggregated: true
        },
        {
          label: '',
          rowType: 'eol'
        }
      ]
    }
  }
}
