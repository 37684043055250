import axios from 'axios'

export const FORD_META_DATA_REQUEST = 'FORD_META_DATA_REQUEST'
export const FORD_META_DATA_RECEIVE = 'FORD_META_DATA_RECEIVE'
export const FORD_META_DATA_FAILURE = 'FORD_META_DATA_FAILURE'

export function metaDataRequest() {
  return {
    type: FORD_META_DATA_REQUEST,
  }
}

export function metaDataReceive(data) {
  return {
    type: FORD_META_DATA_RECEIVE,
    data: data,
  }
}

export function metaDataFailure(error) {
  return {
    type: FORD_META_DATA_FAILURE,
    data: error,
  }
}

export function fetchMetaData(config) {
  return dispatch => {
    dispatch(metaDataRequest())

    const { groupName } = config
    return axios
      .get(`meta`, { groupName })
      .then(meta => {
        dispatch(metaDataReceive(meta.data))
      })
      .catch(err => {
        dispatch(metaDataFailure(err.message))
      })
  }
}
