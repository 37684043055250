import templateScorecardExport from  '../../../../../../Template/config/components/scorecard/ScorecardView/ScorecardExport'
export default {
  ...templateScorecardExport,
  columnWidths: {
    metricsLabels: 45,
    norm: 10,
    metrics: 75
  },
  defaultAlignment: 'center',
  defaultRowHeight: 15,
  defaultFontSize: 10,
  styles: {
    h0: {
      type: 'header',
      height: 15,
      format: {
        fill: '#eeeeee',
        color: '#002C59',
        font: {
          name: 'Arial',
          size: 12,
          bold: true
        }
      }
    },
    h1: {
      type: 'header',
      height: 15,
      format: {
        fill: '#eeeeee',
        color: '#002C59',
        font: {
          name: 'Arial',
          size: 12,
          bold: true
        }
      }
    },
    hb0: {
      type: 'header',
      height: 15,
      format: {
        norm: true,
        fill: '#757575',
        color: '#ffffff',
        font: {
          name: 'Arial',
          size: 12,
          bold: true
        }
      }
    },
    demphasized: {
      type: 'metric',
      height: 15,
      font: {
        name: 'Arial'
      }
    },
    dh0: {
      type: 'metric',
      height: 15,
      font: {
        name: 'Arial'
      },
      format: {
        fill: '#eeeeee'
      }
    },
    d0: {
      type: 'metric',
      height: 15,
      format: {
        border: {
          style: 'thin',
          color: '#eeeeee'
        }
      },
      font: {
        name: 'Arial'
      }
    },
    d1: {
      type: 'metric',
      height: 15,
      offset: '        ',
      format: {
        border: {
          style: 'thin',
          color: '#eeeeee'
        }
      },
      font: {
        name: 'Arial'
      }
    },
    eol: {
      type: 'header',
      height: 20
    }

  },

  sheetHeaders: [
    {
      field: 'brand',
      metricsLabel: 'Brand',
      alignment: 'center',
      font: {
        name: 'Arial'
      }
    },
    {
      field: 'ad_code',
      rowHeight: 25,
      metricsLabel: 'Offer Code',
      alignment: 'center',
      font: {
        name: 'Arial',
        bold: true,
        size: 12
      }
    },
    {
      field: 'title',
      metricsLabel: 'Offer Headline',
      alignment: 'left',
      font: {
        name: 'Arial'
      }
    },
    {
      field: 'speed',
      metricsLabel: 'Speed/Data',
      alignment: 'center',
      font: {
        name: 'Arial'
      }
    },
    {
      field: 'price',
      metricsLabel: 'Price',
      font: {
        name: 'Arial'
      }
    },
    {
      field: 'terms',
      metricsLabel: 'Terms',
      font: {
        name: 'Arial'
      }
    },
    {
      field: 'added_savings',
      metricsLabel: 'Added Savings',
      font: {
        name: 'Arial'
      }
    },
    {
      field: 'perks',
      font: {
        name: 'Arial'
      }
    },
    {
      field: 'market_status',
      metricsLabel: 'Market Status',
      font: {
        name: 'Arial'
      }
    },    
    {
      field: 'month_tested',
      metricsLabel: 'Month Tested',
      font: {
        name: 'Arial'
      }
    },
    {
      field: 'n',
      metricsLabel: 'Sample Size',
      includeInNorm: true,
      format: '#,0',
      font: {
        name: 'Arial'
      }
    },
  ]
}
