export default {
  '***': {
    'market_smb': {
      '***':
        [
          {
            "id": 100,
            "label": "Connectivity",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              110,
              120,
              130,
              140,
              150,
              170,
              180
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 110,
            "label": "High Speed Internet",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 120,
            "label": "Comcast Business WiFi Pro",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "Comcast Business-specific product"
          },
          {
            "id": 130,
            "label": "Comcast Business Connection Pro",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "Comcast Business-specific product"
          },
          {
            "id": 140,
            "label": "Internet Backup",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 150,
            "label": "Ethernet/Fiber",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 170,
            "label": "WiFi",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 180,
            "label": "Gigabit",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 200,
            "label": "Phone",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              210,
              220,
              230,
              240,
              250,
              260,
              280,
              290
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 210,
            "label": "Voicemail Services",
            "selected": 0,
            "collapse": 0,
            "parent": 200,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 220,
            "label": "Conference Calling",
            "selected": 0,
            "collapse": 0,
            "parent": 200,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 230,
            "label": "VoIP (Voice Over Internet Protocol)",
            "selected": 0,
            "collapse": 0,
            "parent": 200,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 240,
            "label": "Text Messaging",
            "selected": 0,
            "collapse": 0,
            "parent": 200,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 250,
            "label": "Faxing",
            "selected": 0,
            "collapse": 0,
            "parent": 200,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 260,
            "label": "Geofencing Communications",
            "selected": 0,
            "collapse": 0,
            "parent": 200,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 280,
            "label": "Be Anywhere Feature",
            "selected": 0,
            "collapse": 0,
            "parent": 200,
            "children": [],
            "tooltip": "Comcast Business-specific product"
          },
          {
            "id": 290,
            "label": "Virtual Conferencing / Meeting",
            "selected": 0,
            "collapse": 0,
            "parent": 200,
            "children": [],
            "tooltip": "Phone services that have an online conferencing feature"
          },
          {
            "id": 300,
            "label": "Mobile",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              310,
              320,
              330
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 310,
            "label": "5G",
            "selected": 0,
            "collapse": 0,
            "parent": 300,
            "children": [],
            "tooltip": "If ad mentions 5G services, must also select \"High Speed Internet\""
          },
          {
            "id": 320,
            "label": "WiFi Hotspots",
            "selected": 0,
            "collapse": 0,
            "parent": 300,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 330,
            "label": "Business Wireless",
            "selected": 0,
            "collapse": 0,
            "parent": 300,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 400,
            "label": "Security",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              410,
              420,
              430,
              440,
              450
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 410,
            "label": "Comcast Business SecurityEdge",
            "selected": 0,
            "collapse": 0,
            "parent": 400,
            "children": [],
            "tooltip": "Comcast Business-specific product"
          },
          {
            "id": 420,
            "label": "Network Monitoring/Security",
            "selected": 0,
            "collapse": 0,
            "parent": 400,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 430,
            "label": "DDOS",
            "selected": 0,
            "collapse": 0,
            "parent": 400,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 440,
            "label": "Enterprise Level Security",
            "selected": 0,
            "collapse": 0,
            "parent": 400,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 450,
            "label": "Internet Security/Firewall Protection",
            "selected": 0,
            "collapse": 0,
            "parent": 400,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 500,
            "label": "TV",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              510,
              520,
              530,
              540,
              550,
              560,
              570
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 510,
            "label": "Satellite TV",
            "selected": 0,
            "collapse": 0,
            "parent": 500,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 520,
            "label": "Video On Demand",
            "selected": 0,
            "collapse": 0,
            "parent": 500,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 530,
            "label": "X1 for Business",
            "selected": 0,
            "collapse": 0,
            "parent": 500,
            "children": [],
            "tooltip": "Comcast Business-specific product"
          },
          {
            "id": 540,
            "label": "Voice Remote",
            "selected": 0,
            "collapse": 0,
            "parent": 500,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 550,
            "label": "Sports Packages (e.g. NFL Ticket)",
            "selected": 0,
            "collapse": 0,
            "parent": 500,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 560,
            "label": "Streaming",
            "selected": 0,
            "collapse": 0,
            "parent": 500,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 570,
            "label": "Hospitality/Hotels",
            "selected": 0,
            "collapse": 0,
            "parent": 500,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 600,
            "label": "Other Comms Products",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              610,
              620,
              630,
              640,
              650,
              660,
              670,
              680,
              690,
              695
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 610,
            "label": "Inventory Management",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 620,
            "label": "Data Storage/Backup/Recovery",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 630,
            "label": "Supply Chain Management",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 640,
            "label": "Cloud Transformation Services",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 650,
            "label": "Video Monitoring",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 660,
            "label": "Fleet Management",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 670,
            "label": "Essential Services",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "Ads featuring support for essential services (e.g. hospitals, transportation, etc.)"
          },
          {
            "id": 680,
            "label": "Comcast Business SmartOffice",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "Comcast Business-specific product"
          },
          {
            "id": 690,
            "label": "Networking",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "Refers to any managed network services for SMB"
          },
          {
            "id": 695,
            "label": "SD-WAN",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 700,
            "label": "Miscellaneous Products",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              710,
              720,
              730,
              740,
              750,
              760,
              770,
              780,
              785,
              790
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 710,
            "label": "Email Marketing Software",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 720,
            "label": "Career Advancement Courses",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 730,
            "label": "Financial Services",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 740,
            "label": "CRM Database Software",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 750,
            "label": "Digital Analytics Software",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 760,
            "label": "Business Advisory Services",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 770,
            "label": "Consulting Services",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 780,
            "label": "Online Communication/Collaboration Platform",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "An app-based platform such as Teams or Zoom"
          },
          {
            "id": 785,
            "label": "Cloud Computing",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 790,
            "label": "Artificial Intelligence",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 800,
            "label": "Brand",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              810,
              820,
              830,
              840,
              850,
              860
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 810,
            "label": "Awards/Accolades",
            "selected": 0,
            "collapse": 0,
            "parent": 800,
            "children": [],
            "tooltip": "Refers to any reference of awards or rankings, 3rd party or commissioned research based."
          },
          {
            "id": 820,
            "label": "Brand/Reputation",
            "selected": 0,
            "collapse": 0,
            "parent": 800,
            "children": [],
            "tooltip": "Refers to a company's history in the market"
          },
          {
            "id": 830,
            "label": "Product Superiority",
            "selected": 0,
            "collapse": 0,
            "parent": 800,
            "children": [],
            "tooltip": "Emphasizing the \"best\" product of the category"
          },
          {
            "id": 840,
            "label": "Customer Service",
            "selected": 0,
            "collapse": 0,
            "parent": 800,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 850,
            "label": "Sponsorship/Partnership",
            "selected": 0,
            "collapse": 0,
            "parent": 800,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 860,
            "label": "Charitable Work/Giving",
            "selected": 0,
            "collapse": 0,
            "parent": 800,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 900,
            "label": "Acquisition",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              905,
              910,
              920,
              930,
              940,
              950,
              960
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 905,
            "label": "No Offer",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 910,
            "label": "Free Equipment/Service",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 920,
            "label": "Limited Time/Special Discount",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 930,
            "label": "Gift Card/Other Incentive",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 940,
            "label": "Free Trial",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 950,
            "label": "Offer Led",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "Focus on product demos or features, followed by an offer for new customers"
          },
          {
            "id": 960,
            "label": "Competitive",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "Compares brand to a competitor or competitor product"
          },
          {
            "id": 1000,
            "label": "Creative Type",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              1010,
              1020,
              1030,
              1040,
              1050
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 1010,
            "label": "Live Action",
            "selected": 0,
            "collapse": 0,
            "parent": 1000,
            "children": [],
            "tooltip": "Consists of mostly filmed scenes"
          },
          {
            "id": 1020,
            "label": "Focus Group",
            "selected": 0,
            "collapse": 0,
            "parent": 1000,
            "children": [],
            "tooltip": "Includes a moderator and group of individuals discussing a brand/product"
          },
          {
            "id": 1030,
            "label": "Animated",
            "selected": 0,
            "collapse": 0,
            "parent": 1000,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 1040,
            "label": "Graphic",
            "selected": 0,
            "collapse": 0,
            "parent": 1000,
            "children": [],
            "tooltip": "Consists primarily of computer-generated product images, little to no focus on people or product demonstrations"
          },
          {
            "id": 1050,
            "label": "Product Demo",
            "selected": 0,
            "collapse": 0,
            "parent": 1000,
            "children": [],
            "tooltip": "Focus on the product features or demonstration of the products, little to no focus on people"
          },
          {
            "id": 1100,
            "label": "Creative Style",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              1110,
              1120,
              1130,
              1140,
              1150,
              1160,
              1170,
              1180,
              1190
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 1110,
            "label": "Emotional",
            "selected": 0,
            "collapse": 0,
            "parent": 1100,
            "children": [],
            "tooltip": "Ads designed to incite emotions (anger, sadness, joy, etc.) "
          },
          {
            "id": 1120,
            "label": "COVID-19 Focus",
            "selected": 0,
            "collapse": 0,
            "parent": 1100,
            "children": [],
            "tooltip": "Ads that directly or indirectly speak to the COVID-19 pandemic"
          },
          {
            "id": 1130,
            "label": "Spokesperson",
            "selected": 0,
            "collapse": 0,
            "parent": 1100,
            "children": [],
            "tooltip": "Includes celebrity and non-celebrity spokespeople"
          },
          {
            "id": 1140,
            "label": "Celebrity",
            "selected": 0,
            "collapse": 0,
            "parent": 1100,
            "children": [],
            "tooltip": "Includes any celebrity inclusion, whether or not that person is a spokesperson"
          },
          {
            "id": 1150,
            "label": "Real Business Owners",
            "selected": 0,
            "collapse": 0,
            "parent": 1100,
            "children": [],
            "tooltip": "Includes images or narratives from real businesses and business owners"
          },
          {
            "id": 1160,
            "label": "Real Employees",
            "selected": 0,
            "collapse": 0,
            "parent": 1100,
            "children": [],
            "tooltip": "Includes images or narratives from real employees of the brand"
          },
          {
            "id": 1170,
            "label": "Testimonial",
            "selected": 0,
            "collapse": 0,
            "parent": 1100,
            "children": [],
            "tooltip": "Includes a peron's written or spoken statement praising the virtue of a brand or service"
          },
          {
            "id": 1180,
            "label": "Case Study",
            "selected": 0,
            "collapse": 0,
            "parent": 1100,
            "children": [],
            "tooltip": "Provides specific examples of how a brand helped a business with a real business issue"
          },
          {
            "id": 1190,
            "label": "Diversity / Inclusivity Elements",
            "selected": 0,
            "collapse": 0,
            "parent": 1100,
            "children": [],
            "tooltip": "No tooltip available"
          }
        ]
    },
    'market_mm_ent': {
      '***':
        [
          {
            "id": 100,
            "label": "Connectivity",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              110,
              120,
              130,
              140,
              150,
              160
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 110,
            "label": "Internet Backup",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 120,
            "label": "WiFi",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 130,
            "label": "Gigabit",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 140,
            "label": "Nationwide Reach",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 150,
            "label": "Ethernet/Fiber",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 160,
            "label": "High Speed Internet",
            "selected": 0,
            "collapse": 0,
            "parent": 100,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 200,
            "label": "Phone, VoIP, UC",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              210,
              220
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 210,
            "label": "VoIP (Voice Over Internet Protocol)",
            "selected": 0,
            "collapse": 0,
            "parent": 200,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 220,
            "label": "Virtual Conferencing / Meeting",
            "selected": 0,
            "collapse": 0,
            "parent": 200,
            "children": [],
            "tooltip": ""
          },
          {
            "id": 300,
            "label": "Mobile",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              310
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 310,
            "label": "5G",
            "selected": 0,
            "collapse": 0,
            "parent": 300,
            "children": [],
            "tooltip": "If ad mentions 5G services, must also select \"High Speed Internet\""
          },
          {
            "id": 400,
            "label": "Cybersecurity",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              410,
              420,
              430,
              440,
              450,
              460,
              470
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 410,
            "label": "DDOS",
            "selected": 0,
            "collapse": 0,
            "parent": 400,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 420,
            "label": "Security for all devices",
            "selected": 0,
            "collapse": 0,
            "parent": 400,
            "children": [],
            "tooltip": "Specific mentions of securing a variety of different devices"
          },
          {
            "id": 430,
            "label": "Network Security",
            "selected": 0,
            "collapse": 0,
            "parent": 400,
            "children": [],
            "tooltip": "General security for internet networks"
          },
          {
            "id": 440,
            "label": "Phishing",
            "selected": 0,
            "collapse": 0,
            "parent": 400,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 450,
            "label": "SecurityEdge",
            "selected": 0,
            "collapse": 0,
            "parent": 400,
            "children": [],
            "tooltip": "Comcast Business-specific product"
          },
          {
            "id": 460,
            "label": "Managed Security",
            "selected": 0,
            "collapse": 0,
            "parent": 400,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 470,
            "label": "UTM (Unified Threat Management)",
            "selected": 0,
            "collapse": 0,
            "parent": 400,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 500,
            "label": "Managed Network Service",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              510,
              520,
              530
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 510,
            "label": "ActiveCore",
            "selected": 0,
            "collapse": 0,
            "parent": 500,
            "children": [],
            "tooltip": "Comcast Business-specific product"
          },
          {
            "id": 520,
            "label": "SD-WAN",
            "selected": 0,
            "collapse": 0,
            "parent": 500,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 530,
            "label": "Cloud Transformation Services",
            "selected": 0,
            "collapse": 0,
            "parent": 500,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 600,
            "label": "Other Business Services",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              610,
              620,
              630,
              640, 
              650
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 610,
            "label": "Cloud Computing",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 620,
            "label": "Artificial Intelligence",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 630,
            "label": "SaaS",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 640,
            "label": "Online Communication/Collaboration Platform",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "An app-based platform such as Teams or Zoom"
          },
          {
            "id": 650,
            "label": "Internet of Things (IoT)",
            "selected": 0,
            "collapse": 0,
            "parent": 600,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 700,
            "label": "Brand",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              710,
              720,
              730,
              740,
              750,
              760
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 710,
            "label": "Awards / Accolates",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "Refers to awards or rankings from 3rd party sources or commissioned research-based"
          },
          {
            "id": 720,
            "label": "Brand / Reputation",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "Refers to a company's history in the market"
          },
          {
            "id": 730,
            "label": "Product Superiority",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "Emphasizing the \"best\" product of the category"
          },
          {
            "id": 740,
            "label": "Customer Service",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 750,
            "label": "Sponsorship / Partnership",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 760,
            "label": "Charitable Work / Giving",
            "selected": 0,
            "collapse": 0,
            "parent": 700,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 800,
            "label": "Creative Type",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              810,
              820,
              830,
              840,
              850
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 810,
            "label": "Live Action",
            "selected": 0,
            "collapse": 0,
            "parent": 800,
            "children": [],
            "tooltip": "Consists of mostly filmed scenes"
          },
          {
            "id": 820,
            "label": "Focus Group",
            "selected": 0,
            "collapse": 0,
            "parent": 800,
            "children": [],
            "tooltip": "Includes a moderator and group of individuals discussing a brand/product"
          },
          {
            "id": 830,
            "label": "Animated",
            "selected": 0,
            "collapse": 0,
            "parent": 800,
            "children": [],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 840,
            "label": "Graphic",
            "selected": 0,
            "collapse": 0,
            "parent": 800,
            "children": [],
            "tooltip": "Consists primarily of computer-generated product images, little to no focus on people or product demonstrations"
          },
          {
            "id": 850,
            "label": "Product Demo",
            "selected": 0,
            "collapse": 0,
            "parent": 800,
            "children": [],
            "tooltip": "Focus on the product features or demonstration of the products, little to no focus on people"
          },
          {
            "id": 900,
            "label": "Creative Style",
            "selected": 0,
            "collapse": 0,
            "parent": 0,
            "children": [
              910,
              920,
              930,
              940,
              950,
              960,
              970,
              980,
              990
            ],
            "tooltip": "No tooltip provided"
          },
          {
            "id": 910,
            "label": "Emotional",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "Ads designed to incite emotions (anger, sadness, joy, etc.) "
          },
          {
            "id": 920,
            "label": "COVID-19 Focus",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "Ads that directly or indirectly speak to the COVID-19 pandemic"
          },
          {
            "id": 930,
            "label": "Spokesperson",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "Includes celebrity and non-celebrity spokepeople"
          },
          {
            "id": 940,
            "label": "Celebrity",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "Includes any celebrity acting or voice-over, even if celebrity is not a brand spokesperson"
          },
          {
            "id": 950,
            "label": "Real Businesses",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "Includes images or narratives from real businesses and business owners"
          },
          {
            "id": 960,
            "label": "Real Employees",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "Includes images or narratives from real employees of the brand"
          },
          {
            "id": 970,
            "label": "Testimonial",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "Includes a peron's written or spoken statement praising the virtue of a brand or service"
          },
          {
            "id": 980,
            "label": "Case Study",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "Includes a peron's written or spoken statement praising the virtue of a brand or service"
          },
          {
            "id": 990,
            "label": "Diversity / Inclusivity Elements",
            "selected": 0,
            "collapse": 0,
            "parent": 900,
            "children": [],
            "tooltip": "No tooltip available"
          }
        ]
    }
  }
}
