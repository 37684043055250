export default theme => {
  return {
    base: {
      flexGrow: 1
    },
    headline: {
      transform: 'scale(1.25)'
    },
    paper: {
      padding: 40,
      marginTop: 40,
      marginBottom: 40
    },
    text: {
      color: 'inherit'
    },
    button: {
      width: 220,
      height: 120,
      borderRadius: 4,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white
      },
      textTransform: 'capitalize'
    }
  }
}
