import React, { Component } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
const styles = theme => ({
  control: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  disabled: {
    backgroundColor: theme.palette.grey[100]
  },
  menuItemRoot: {
    padding: 0
  },
  menuText: {
    fontSize: 12
  },
  activeType: {
    '&:hover': {
      backgroundColor: theme.palette.listSelectBackground.default,
    }
  }
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 285
    }
  }
}

class MenuControl extends Component {
  state = {
    selected: [],
    initial: [],
    open: false
  }

  componentDidMount() {
    const { options, disabled } = this.props
    const selected = options.reduce((array, option) => {
      if (option.selected && !disabled) array.push(option.value)
      return array
    }, [])

    this.setState({
      selected,
      initial: selected
    })
  }

  componentDidUpdate(prevProps) {
    const { disabled, options } = this.props 

    if ( disabled !== prevProps.disabled ) {
      const selected = options.reduce((array, option) => {
        if (option.selected && !disabled) array.push(option.value)
        return array
      }, [])
      this.setState({
        selected
      })
    }
  }

  formatSelected = options => {
    return options
      .filter(d => d.selected === true)
      .map(d => d.label)
      .join(', ')
  }

  handleChange = event => {
    const { value } = event.target
    const { multiple } = this.props

    const selected = Array.isArray(value) ? value : [value]
    if (multiple) {
      this.setState({
        selected
      })
    } else {
      this.setState({
        selected: [selected[1]],
      })
      setTimeout(this.handleClose, 0)
    }
  }

  handleClose = () => {
    const { handleChange, controlId } = this.props

    const { selected, initial } = this.state
    if (
      selected.length !== initial.length ||
      selected.some((item, index) => item !== initial[index])
    ) {
      handleChange(controlId, selected)
      this.setState({
        initial: selected
      })
    }

    this.setState({
      open: false
    })
  }

  handleOpen = () => {
    this.setState({
      open: true
    })
  }

  render() {
    const {
      classes,
      name,
      options,
      disabled,
      tip,
      multiple
    } = this.props
    const classnames = cn(classes.control, disabled ? classes.disabled : null)

    return (
      <Grid className={classnames} style={{ padding: 6 }} item xs>
        <FormControl disabled={disabled} fullWidth>
          <Tooltip title={tip} placement="top">
            <InputLabel
              style={{ fontSize: 14 }}
              htmlFor={`id-for-${name}-filter`}
            >
              {name}
            </InputLabel>
          </Tooltip>
          <Select
            multiple={true}
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            onChange={this.handleChange}
            value={this.state.selected}
            input={<Input disableUnderline id={`id-for-${name}-filter`} />}
            renderValue={() => this.formatSelected(options)}
            MenuProps={MenuProps}
          >
            {options.map(({ value, label }) => {
              const { selected } = this.state
              return (
                <MenuItem key={`option-${value}`} value={value} className={classes.activeType}>
                  {multiple ? (
                    <Checkbox
                      checked={selected.includes(value)}
                      color="secondary"
                    />
                  ) : null}
                  <ListItemText
                    classes={{
                      root: classes.menuItemRoot,
                      primary: classes.menuText
                    }}
                    primary={label}
                  />
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
    )
  }
}

MenuControl.propTypes = {
  name: PropTypes.string.isRequired,
  controlId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired
    })
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

MenuControl.defaultProps = {
  disabled: false,
  handleChange: () => {}
}

export default withStyles(styles)(MenuControl)
