import { setBuilderToolbar } from '../../../../../Template/config/components/selection/SetBuilder'

export default {
  ...setBuilderToolbar,
  enableExcludeNonNormFilters: {
    ad: true,
    norm: true
  },
  exportButtons: {
    ad: {
      data: true,
      media: false
    },
    norm: {
      data: true,
      media: false
    }
  },
  sortOptions: [
    {
      "value": -1,
      "label": "Alpha Order by Offer Code",
      "field": "ad_code",
      "selected": false
    },
    {
      "value": 1,
      "label": "Unbranded OfferPi Score",
      "selected": true,
      "dataFormat": ".,0"
    },
    {
      "value": 2,
      "label": "Unbranded Appeal",
      "selected": false
    },
    {
      "value": 3,
      "label": "Unbranded Consideration",
      "selected": false
    },
    {
      "value": 4,
      "label": "Unbranded Likelihood to Contact",
      "selected": false
    },
    {
      "value": 5,
      "label": "Unbranded Likelihood to Purchase",
      "selected": false,
    },
    {
      "value": 11,
      "label": "Branded OfferPi Score",
      "selected": false,
      "dataFormat": ".,0"
    },
    {
      "value": 12,
      "label": "Branded Appeal",
      "selected": false
    },
    {
      "value": 13,
      "label": "Branded Consideration",
      "selected": false
    },
    {
      "value": 14,
      "label": "Branded Likelihood to Contact",
      "selected": false
    },
    {
      "value": 15,
      "label": "Branded Likelihood to Purchase",
      "selected": false
    }
  ]
}
