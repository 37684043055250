import MetricChartTemplate from '../../../../../Template/theme/components/quickview/Quickview/MetricChart'
export default Object.assign(
  theme => {
    return {
      ...MetricChartTemplate(theme),
      mainMetricOffset: 20,
      subMetricsOffset: 0
    }
  },
  {
    MainMetric: theme => {
      return {
        ...MetricChartTemplate.MainMetric(theme),
        mainMetricColorSaturationPercent: 250,
        mainMetricColorLightenPercent: 0,
        mainMetricColorValuePercent: -45
      }
    },
    SubMetric: theme => {
      return {
        ...MetricChartTemplate.SubMetric(theme)
      }
    }
  }
)
