import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReactPlayer from "react-player";
import { Document, Page } from "react-pdf";
import Paper from "@material-ui/core/Paper";
import AsyncWrapper from "./AsyncWrapper";
import FileDownload from "@material-ui/icons/Inbox";
import Draggable from 'react-draggable';

const Styled = withStyles(theme => theme.components.generic.MediaViewer(theme));

class MediaViewer extends Component {
  state = {
    numPages: null,
    pageNumber: 0,
    playerWidth: 640,
    playerHeight: 360
  };

  componentDidCatch(a) {
    console.log(a);
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };

  advance = () => {
    this.setState({
      pageNumber: (this.state.pageNumber + 1) % this.state.numPages
    });
  };

  onPageLoad = data => {
    const { originalWidth, originalHeight } = data;
    const pdfAspectRatio = originalWidth / originalHeight;
    const windowAspectRatio = window.innerWidth / window.innerHeight;

    let playerHeight, playerWidth, scale;
    if (windowAspectRatio > pdfAspectRatio) {
      playerHeight = Math.floor(window.innerHeight * 0.75);
      playerWidth = Math.floor(playerHeight * pdfAspectRatio);
      scale = playerHeight / originalHeight;
    } else {
      playerWidth = Math.floor(window.innerWidth * 0.75);
      playerHeight = Math.floor(playerWidth / pdfAspectRatio);
      scale = originalWidth / playerWidth;
    }
    console.log(
      `playerWidth: ${playerWidth}, playerHeight: ${playerHeight}, scale: ${scale}`
    );
    this.setState({
      playerWidth,
      playerHeight,
      scale
    });
  };

  render() {
    const { classes, viewer, handleClose } = this.props;
    const { pageNumber, playerWidth } = this.state;

    return !viewer || !viewer.open ? null : (
      <Draggable>
        <Dialog
          maxWidth={false}
          open={viewer.open}
          onClose={handleClose}
          scroll="paper"
          disableEnforceFocus
          style={{ pointerEvents: 'none' }}
          PaperProps={{ style: { pointerEvents: 'auto' } }}
          BackdropProps={{style: { backgroundColor: 'rgba(0,0,0,0)'}} }
        >
          <Paper className={classes.title}>
            <DialogTitle style={{ height: 65 }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant="h6" className={classes.titleText}>
                    {`${viewer.data.ad_code} - ${viewer.data.title}`}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={handleClose} className={classes.closeIcon}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
          </Paper>
          <DialogContent>
            <Grid className={classes.grid} container alignContent="center">
              {["mp4", "mp3"].includes(viewer.data.mediaType) ? (
                <Grid item>
                  <div className={classes.cell}>
                    <AsyncWrapper item={viewer} size={200} thickness={1.0}>
                      <ReactPlayer
                        width={640}
                        height={360}
                        url={viewer.data.url}
                        controls
                        playing
                      />
                    </AsyncWrapper>
                  </div>
                </Grid>
              ) : (
                  <AsyncWrapper item={viewer} size={200} thickness={1.0}>
                    <Grid
                      item
                      style={{
                        overflow: "scroll",
                        width: playerWidth + 40
                        // padding: 20
                      }}
                      onClick={this.advance}
                    >
                      <Document
                        file={{
                          url: viewer.data.url
                        }}
                        onLoadSuccess={this.onDocumentLoad}
                        onRenderSuccess={this.onDocumentRender}
                        loading="Computing PDF Image Size"
                      >
                        <Page
                          pageNumber={pageNumber + 1}
                          onLoadSuccess={this.onPageLoad}
                          width={this.state.playerWidth}
                          loading={null}
                        />
                      </Document>
                    </Grid>
                    <a href={viewer.data.url} download className={classes.download}>
                      <FileDownload />
                    </a>
                  </AsyncWrapper>
                )}
            </Grid>
          </DialogContent>

          {/* {viewer.data.mediaType === 'pdf' && this.state.numPages ? (
          <a href={viewer.data.url} download className={classes.download}>
            <FileDownload />
          </a>
        ) : (
          ''
        )} */}
        </Dialog>
      </Draggable>
    );
  }
}

export default Styled(MediaViewer);
