import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button, Tooltip } from '@material-ui/core'
import { Confirmation } from '../../'

const Styled = withStyles(theme =>
  theme.components.adminView.AdminUserManager.AdminUserAccount(theme)
)

class AdminUserAccount extends React.PureComponent {

  state = {
    open: false,
    action: null,
    user: null
  }

  confirm = () => {
    const { updateUser, deleteUser } = this.props
    const { user: { status, email }, action } = this.state
    if (action === 'Delete') {
      deleteUser({ email})
    } else {
      updateUser({ email, status: status === 'active' ? 'deactivated' : 'active' })
    }
    this.setState({
      open: false,
      action: null,
      user: null
    })
  }

  cancel = () => {
    console.log('Canceled')
    this.setState({
      open: false,
      user: null,
      action: null
    })
  }

  updateActivation = user => {
    this.setState({
      open: true,
      action: user.status === 'active' ? 'De-Activate' : 'Acativate',
      confirmTitle: user.status === 'active' ? 'De-Activation' : 'Activation',
      user
    })
  }

  deleteAccount = user => {
    this.setState({
      open: true,
      confirmTitle: 'Delete',
      action: 'Delete',
      user
    })
  }

  render() {
    const { user, classes } = this.props

    const created_on = new Date(user.created_at)
    const modified_on = new Date(user.modified_at)
    const statusText = user.status === 'active' ? 'Active' : 'Inactive'
    const buttonText = user.status === 'active' ? 'De-activate' : 'Activate'
    const buttonColor = user.status === 'active' ? 'red' : 'green'
    const { confirmTitle, action } = this.state
    return (
      <Fragment>
        <Confirmation open={this.state.open} title={`Account ${confirmTitle}`} confirm={this.confirm} cancel={this.cancel} message={[`${action} the following account?`, user.email]} />
        <Grid container direction="row" className={classes.container}>
          <Grid item>
            <Typography variant="caption" display="block">{`Registered on ${created_on.toLocaleDateString()} at ${created_on.toLocaleTimeString()}`}</Typography>
            <Typography variant="caption" display="block" className={classes.lowerBorder} >{`Last mdified on ${modified_on.toLocaleDateString()} at ${modified_on.toLocaleTimeString()} by ${user.modified_by}`}</Typography>
            <Typography variant="caption" display="block" style={{ marginBottom: 20 }}>Account History</Typography>
            {/* <Grid container>
              <Grid item> */}
            <Tooltip title={"A de-activated user cannot log in to the system"} placement="top">
              <Typography gutterBottom variant="caption" display="block">{`This account is currently ${statusText}`}</Typography>
            </Tooltip>
            <Button variant="outlined" size="small" style={{ color: `${buttonColor}`, marginRight: 10 }} onClick={() => this.updateActivation(user)} classes={{ root: classes.root }}>
              {`${buttonText} Account`}
            </Button>
            {/* </Grid> */}
            {/* <Grid item> */}
            {user.status === 'deactivated' ? (
              <Button variant="outlined" size="small" style={{ color: 'red' }} onClick={() => this.deleteAccount(user)} classes={{ root: classes.root }}>
                {`Delete Account`}
              </Button>
            ) : null
            }
            {/* </Grid> */}
            {/* </Grid> */}
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default Styled(AdminUserAccount)
