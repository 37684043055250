import { createSelector } from 'reselect'
import { format, getStatTest, sortByMetric, monthCodeToText } from '../../new_utils'
import { getSelectedAds } from '../selections'
import { getMetricsLabels } from '../metrics'


const _getMetricDisplayValue = (label, metric, norm, normStandardSampleSize, statTesting) => {
  let display, statTest

  const _norm = {
    ...norm,
    n: statTesting.useNormStandardSampleSize ? normStandardSampleSize && normStandardSampleSize.n : norm && norm.n,
    n_unweighted: statTesting.useNormStandardSampleSize
      ? normStandardSampleSize && normStandardSampleSize.n_unweighted
      : norm && norm.n_unweighted
  }

  const { formatter, formatType } = format.getFormat(label.dataFormat || '%,1')

  if (formatType === 'idx') {
    display = formatter(metric && norm && norm.avg !== 0 && (metric.avg / norm.avg) * 100)
    statTest = getStatTest(metric, _norm, statTesting, label.stdTest, formatType, label.reverseStat)
  } else if (formatType === 's') {
    const interval = label.thresholds.reduce(
      (obj, interval) => (metric.avg >= interval.min && metric.avg <= interval.max ? interval : obj),
      ''
    )
    display = formatter(interval.text)
    statTest = {
      color: interval.color
    }
  } else {
    display = formatter(metric && metric.avg)
    statTest = getStatTest(metric, _norm, statTesting, label.stdTest, formatType, label.reverseStat)
  }

  return {
    display,
    statTest
  }
}

const getAdsMap = dashboard => dashboard.init.metadata.adsMap
const getNormAds = dashboard => dashboard.selections.presets.norm
const getNormData = dashboard => dashboard.scorecard.norm
const getEndMonth = dashboard => dashboard.selections.presets.ad.end_month
const getSelectedAdCodes = dashboard => dashboard.selections.presets.ad.ad_codes
const getAdCalcFlags = dashboard => {
  return {
    ...dashboard.scorecard.calcFlags.ad
  }
}

const getConfigHeaderHeight = dashboard => ({
  headerHeight: dashboard.init.config.components.scorecard.scorecardView.scorecardScroller.dimensions.headerHeight,
  headerRowHeight: dashboard.init.config.components.scorecard.scorecardView.scorecardScroller.dimensions.headerRowHeight
})

const getNormCalcFlags = dashboard => {

  return {
    ...dashboard.scorecard.calcFlags.norm
  }
}
const getMetadataQueryFields = dashboard =>
  dashboard.init.config.metadata.slices.reduce((array, slice) => {
    if (slice.useInQuery) array.push(slice.field)
    return array
  }, [])
const getNormStatAccumulateLastMonthOnly = dashboard =>
  dashboard.init.config.components.scorecard.normStatAccumulateLastMonthOnly
const getSliceSelections = dashboard => dashboard.selections.slice.sliceSelections

const getStatTesting = dashboard => dashboard.init.config.components.scorecard.scorecardView.scorecardStatTesting

const getSortMetric = dashboard => {
  const sortOption = dashboard.selections.sort.sortOptions.find(option => option.selected)
  return sortOption
}
const getSampleSizeMetric = dashboard => dashboard.init.config.components.scorecard.sampleSizeMetric

const getSampleSizeThreshold = dashboard => dashboard.init.config.components.scorecard.sampleSizeThreshold

const getHideMetricWithMissingNorm = dashboard => dashboard.init.config.components.scorecard.hideMetricWithMissingNorm

const getStaticFilters = dashboard => dashboard.selections.filter.staticFilters

const getMetricsData = dashboard => {
  const {
    metrics: { uuid, ready, data, error }
  } = dashboard.scorecard
  return {
    uuid,
    ready,
    error,
    data: data.filter(item => dashboard.selections.presets.ad.ad_codes.includes(item.ad_code))
  }
}

const getStaticFiltersSelections = createSelector([getStaticFilters], staticFilters => {
  return Object.keys(staticFilters)
    .filter(filter => {
      return staticFilters[filter] && staticFilters[filter].options.some(option => option.selected)
    })
    .reduce((array, filter) => {
      const { code, options } = staticFilters[filter]
      const selected = options.filter(option => option.selected)
      const useAugments = selected.length === 1 && selected[0].useAugments
      array.push({
        code,
        useAugments,
        values: selected.reduce((optionArray, option) => {
          optionArray.push(option.value)
          return optionArray
        }, [])
      })
      return array
    }, [])
})

const getLastMonth = (months, lastMonthOnly, end_month, excludeNonNorm) => {
  return lastMonthOnly
    ? months &&
        months.find(month => {
          return month.month_code === end_month && (excludeNonNorm ? month.is_norm : true)
        })
    : months &&
        months.reduce((last_month, month) => {
          if (!month.is_norm && excludeNonNorm) return last_month ? last_month : months[0]
          return month.month_code > ((last_month && last_month.month_code) || 0) ? month : last_month
        }, null)
}

const getNormStats = createSelector(
  [getNormAds, getAdsMap, getNormStatAccumulateLastMonthOnly, getNormCalcFlags],
  (normAds, adsMap, normStatAccumulateLastMonthOnly, normCalcFlags) => {
    const { end_month, ad_codes } = normAds

    const result = ad_codes.reduce(
      (obj, ad_code) => {
        const ad = adsMap[ad_code]
        const last_month_tested = getLastMonth(
          ad && ad.months,
          normStatAccumulateLastMonthOnly,
          end_month,
          normCalcFlags.excludeNonNorm
        )
        if (last_month_tested) {
          obj.counts.days_in_market += last_month_tested.days || normStatAccumulateLastMonthOnly ? 1 : 0
          obj.days_in_market += last_month_tested.days || 0

          obj.counts.spend_ltd += last_month_tested.spend_ltd || normStatAccumulateLastMonthOnly ? 1 : 0
          obj.spend_ltd += last_month_tested.spend_ltd || 0

          obj.counts.occurrences += last_month_tested.occurrences || normStatAccumulateLastMonthOnly ? 1 : 0
          obj.occurrences += last_month_tested.occurrences || 0

          obj.counts.thirty_day_spend += last_month_tested.thirty_day_spend || normStatAccumulateLastMonthOnly ? 1 : 0
          obj.thirty_day_spend += last_month_tested.thirty_day_spend || 0
        }
        return obj
      },
      {
        days_in_market: 0,
        spend_ltd: 0,
        counts: {
          days_in_market: 0,
          spend_ltd: 0,
          occurrences: 0,
          thirty_day_spend: 0
        },
        occurrences: 0,
        thirty_day_spend: 0
      }
    )

    return {
      days_in_market: result.counts.days_in_market ? result.days_in_market / result.counts.days_in_market : 0,
      spend_ltd: result.counts.spend_ltd ? result.spend_ltd / result.counts.spend_ltd : 0,
      occurrences: result.counts.occurrences ? result.occurrences / result.counts.occurrences : 0,
      thirty_day_spend: result.counts.thirty_day_spend ? result.thirty_day_spend / result.counts.thirty_day_spend : 0
    }
  }
)

const getMetricsRowTypeMap = dashboard => dashboard.init.config.components.scorecard.metricsLabels.rowTypeMap

const getMetricsQuery = createSelector(
  [
    getMetricsLabels,
    getSelectedAds,
    getStaticFiltersSelections,
    getAdCalcFlags,
    getMetadataQueryFields,
    getSliceSelections
  ],
  (labels, selectedAds, filters, adCalcFlags, metadataQueryFields, sliceSelections) => {
    const { ad } = selectedAds
    const operators =
      labels.filter &&
      labels
        .filter(label => {
          const { operation } = label
          return operation
        })
        .map(label => {
          const { operation, metric } = label
          return { metric, operation }
        })

    const recall =
      labels.filter &&
      labels.filter(label => label.recall).map(label => {
        return {
          srcMetric: label.recall.metric,
          metric: label.metric
        }
      })

    const metaQuery = metadataQueryFields.map(q => {
      return {
        [q]: sliceSelections[q].field
      }
    })

    const { excludeProprietary, ...calcFlags } = adCalcFlags
    const adCodes = ad.ad_codes
    const { uuid, start_month, end_month } = ad

    return {
      uuid,
      startMonth: start_month || 0,
      endMonth: end_month || 9999,
      operators,
      recall,
      adCodes,
      filters,
      metaQuery,
      calcFlags
    }
  }
)
const getNormQuery = createSelector(
  [
    getMetricsLabels,
    getSelectedAds,
    getStaticFiltersSelections,
    getSampleSizeMetric,
    getNormCalcFlags,
    getMetadataQueryFields,
    getSliceSelections
  ],
  (labels, selectedAds, filters, sampleSizeMetric, normCalcFlags, metadataQueryFields, sliceSelections) => {
    const { norm } = selectedAds
    const operators =
      labels.filter &&
      labels
        .filter(label => {
          const { operation } = label
          return operation
        })
        .map(label => {
          const { operation, metric } = label
          return { metric, operation }
        })

    const recall =
      labels.filter &&
      labels.filter(label => label.recall).map(label => {
        return {
          srcMetric: label.recall.metric,
          metric: label.metric
        }
      })

    const metaQuery = metadataQueryFields.map(q => {
      return {
        [q]: sliceSelections[q].field
      }
    })

    const { excludeProprietary, ...calcFlags } = normCalcFlags
    const adCodes = norm.ad_codes
    const { uuid, start_month, end_month } = norm

    return {
      uuid,
      startMonth: start_month || 0,
      endMonth: end_month || 9999,
      operators,
      recall,
      adCodes,
      filters,
      metaQuery,
      calcFlags,
      sampleSizeMetric
    }
  }
)

const getPerks = createSelector([getSelectedAdCodes, getAdsMap], (adCodes, adsMap) => {
  const perksObj = adCodes.reduce((obj, adCode) => {
    const perks = adsMap[adCode].perks || []
    perks.forEach(perk => {
      obj[perk.label] = perk
    })
    return obj
  }, {})

  return Object.keys(perksObj)
    .reduce((array, perk) => {
      array.push(perksObj[perk].label)
      return array
    }, [])
    .sort((a, b) => (a >  b ? 1 : -1))
})

const getGridData = createSelector(
  [
    getEndMonth,
    getMetricsLabels,
    getMetricsRowTypeMap,
    getSortMetric,
    getSampleSizeMetric,
    getSampleSizeThreshold,
    getHideMetricWithMissingNorm,
    getAdsMap,
    getMetricsData,
    getNormData,
    getStatTesting,
    getNormStats,
    getPerks,
    getConfigHeaderHeight
  ],
  (
    endMonth,
    _metricsLabels,
    rowTypeMap,
    sortMetric,
    sampleSizeMetric,
    sampleSizeThreshold,
    hideMetricWithMissingNorm,
    adsMap,
    metricsData,
    normData,
    statTesting,
    normStats,
    perksList,
    configHeaderHeight
  ) => {
    const numAds = metricsData.data.length || 0

    const ready = metricsData.ready && normData.ready
    const metricsLabels = _metricsLabels.filter(metricLabel => !metricLabel.inHeader)

    const headerMetricsLabels = _metricsLabels.filter(metricLabel => metricLabel.inHeader)
    const numMetrics = metricsLabels.length

    if (!ready) return {}

    const gridData = { metricsLabels: [...metricsLabels], perksList }
    gridData.headerHeight = configHeaderHeight.headerHeight + ((configHeaderHeight.headerRowHeight || 0) * perksList.length)
    gridData.adHeaders = metricsData.data
      .map(ad => {
        const last_month_tested_month_code = adsMap[ad.ad_code].months.reduce(
          (value, month) => (month.month_code > endMonth ? value : Math.max(value, month.month_code)),
          0
        )

        const last_month_tested_month_text = monthCodeToText(last_month_tested_month_code)

        const last_month_tested = adsMap[ad.ad_code].months.find(
          month => month.month_code === last_month_tested_month_code
        )
        const first_air_date =
          (adsMap[ad.ad_code].first_air_date &&
            adsMap[ad.ad_code].first_air_date.length &&
            format.date(adsMap[ad.ad_code].first_air_date, 'mm/dd/yyyy')) ||
          'N/A'
        const header_metrics = headerMetricsLabels.map(headerMetricLabel => {
          const { formatter, formatType } = format.getFormat(headerMetricLabel.dataFormat || '%,1')

          /**
           *
           */
          const adSampleSize = (ad.metrics[sampleSizeMetric] && ad.metrics[sampleSizeMetric].n) || 0
          const metricSampleSize = (ad.metrics[headerMetricLabel.metric] && ad.metrics[headerMetricLabel.metric].n) || 0
          const metric =
            adSampleSize > sampleSizeThreshold && headerMetricLabel.metric
              ? metricSampleSize > (headerMetricLabel.minSampleSize || 0)
                ? ad.metrics[headerMetricLabel.metric]
                : null
              : null
          const normStandardSampleSize = normData.data[sampleSizeMetric]
          const norm = normData.data[headerMetricLabel.metric]

          const { reverseStat } = headerMetricLabel

          const _norm = {
            ...norm,
            n: statTesting.useNormStandardSampleSize
              ? normStandardSampleSize && normStandardSampleSize.n
              : norm && norm.n,
            n_unweighted: statTesting.useNormStandardSampleSize
              ? normStandardSampleSize && normStandardSampleSize.n_unweighted
              : norm && norm.n_unweighted
          }

          const statTest = getStatTest(metric, _norm, statTesting, headerMetricLabel.stdTest, formatType, reverseStat)
          /**
           *
           */
          return {
            ...headerMetricLabel,
            value: metric && metric.avg,
            avg: metric && metric.avg ? formatter(metric && metric.avg) : '',
            statTest
          }
        })
        const spend_ltd = (last_month_tested && last_month_tested.spend_ltd) || 0

        const perks =
          (Array.isArray(adsMap[ad.ad_code].perks) &&
            adsMap[ad.ad_code].perks.reduce((obj, perk) => {
              obj[perk.label] = perk.value
              return obj
            }, {})) ||
          {}
        return {
          ...adsMap[ad.ad_code],
          last_month_tested: last_month_tested_month_text,
          first_air_date,
          header_metrics,
          days_in_market: (last_month_tested && last_month_tested.days) || 0,
          spend_ltd,
          perks,
          occurrences: last_month_tested && last_month_tested.occurrences,
          thirty_day_spend: last_month_tested && last_month_tested.thirty_day_spend,
          n: (ad.metrics[sampleSizeMetric] && ad.metrics[sampleSizeMetric].n) || null,
          recall_efficiency: (ad.metrics[100] && ad.metrics[100].avg && spend_ltd / ad.metrics[100].avg / 100) || 0,
          metrics: ad.metrics
        }
      }) 
      .sort((a, b) => {
        return sortByMetric(a, b, sortMetric)
      })

    gridData.rowHeights = metricsLabels.map(label => rowTypeMap[label.rowType])

    gridData.normHeaders = {
      header_metrics: headerMetricsLabels.map(headerMetricLabel => {
        const { formatter } = format.getFormat(headerMetricLabel.dataFormat || '%,1')
        const value = normData.data[headerMetricLabel.metric] && normData.data[headerMetricLabel.metric].avg
        return {
          ...headerMetricLabel,
          value,
          avg: formatter(value)
        }
      }),
      n: normData.data[sampleSizeMetric] && normData.data[sampleSizeMetric].n,
      ...normStats,
      recall_efficiency:
        (normData.data[100] && normData.data[100].avg && normStats.spend_ltd / normData.data[100].avg / 100) || 0
    }

    gridData.norms = metricsLabels.map(label => {
      const norm = normData.data[label.metric]
      if (!norm) return { rowType: label.rowType }
      const { formatter, formatType } = format.getFormat(label.dataFormat || '%,1')

      const display = formatType === 'idx' ? 100 : norm.avg
      return {
        ...norm,
        display: formatter(display),
        rowType: label.rowType
      }
    })

    gridData.metrics = [...metricsData.data]
      .sort((_a, _b) => {
        const a = { ..._a, ...adsMap[_a.ad_code] }
        const b = { ..._b, ...adsMap[_b.ad_code] }
        return sortByMetric(a, b, sortMetric)
      })
      .map(ad =>
        // FIXME: What happens here if norm is returned blank?  Make sure it's gracefully handled.
        metricsLabels.map(label => {
          const adSampleSize = (ad.metrics[sampleSizeMetric] && ad.metrics[sampleSizeMetric].n) || 0
          const metricSampleSize = (ad.metrics[label.metric] && ad.metrics[label.metric].n) || 0
          const metric =
            adSampleSize > sampleSizeThreshold && label.metric
              ? metricSampleSize > (label.minSampleSize || 0)
                ? normData.data[label.metric] || !hideMetricWithMissingNorm
                  ? ad.metrics[label.metric]
                  : null
                : null
              : null
          const normStandardSampleSize = normData.data[sampleSizeMetric]
          const norm = normData.data[label.metric]

          if (!metric) return { rowType: label.rowType, statTest: {} }

          /* **/
          // const { formatter, formatType } = format.getFormat(
          //   label.dataFormat || '%,1'
          // )
          // const display =
          //   formatType === 'idx' && metric && norm ? (metric.avg / norm.avg) * 100 : metric && metric.avg

          const { display, statTest } = _getMetricDisplayValue(label, metric, norm, normStandardSampleSize, statTesting)
          /* **/

          return {
            ...metric,
            rowType: label.rowType,
            display,
            statTest
          }
        })
      )

    return {
      ...gridData,
      ready,
      metricsUUID: metricsData.uuid,
      normUUID: normData.uuid,
      rowCount: numMetrics,
      columnCount: numAds
    }
  }
)
export { getStaticFiltersSelections, getMetricsLabels, getMetricsQuery, getNormQuery, getGridData }
