export default {
  '***': {
    '***': {
      displayFilters: null,
      staticFilters: [
        {
          label: 'Division',
          excludeFromVerbatims: true,
          code: 'division',
          multi: true,
          options: [
            // {
            //   label: 'Total',
            //   value: 1,
            //   selected: false
            // },
            {
              label: 'Central',
              value: 1,
              selected: false
            },
            {
              label: 'Northeast',
              value: 2,
              selected: false
            },
            {
              label: 'West',
              value: 3,
              selected: false
            }
          ]
        },
        {
          label: 'Age',
          code: 'age',
          multi: true,
          options: [
            {
              label: '18-35',
              value: 1,
              selected: false
            },
            {
              label: '35-49',
              value: 2,
              selected: false
            },
            {
              label: '50-64',
              value: 3,
              selected: false
            },
            {
              label: '65+',
              value: 4,
              selected: false
            }
          ]
        },
        {
          label: 'Income',
          excludeFromVerbatims: true,
          code: 'income',
          multi: true,
          options: [
            {
              label: '$20k-49k',
              value: 1,
              selected: false
            },
            {
              label: '$50k-$99k',
              value: 2,
              selected: false
            },
            {
              label: '$100k+',
              value: 3,
              selected: false
            }
          ]
        },
        {
          label: 'Kids in HH',
          code: 'children',
          multi: true,
          options: [
            {
              label: 'Yes',
              value: 1,
              selected: false
            },
            {
              label: 'No',
              value: 2,
              selected: false
            }
          ]
        },
        {
          label: 'Residence',
          code: 'residence',
          multi: true,
          options: [
            {
              label: 'SFU',
              value: 1,
              selected: false
            },
            {
              label: 'MDU',
              value: 2,
              selected: false
            },
            {
              label: 'Other',
              value: 3,
              selected: false
            }
          ]
        },
        {
          label: 'Customer/Prospect',
          code: 'customer',
          multi: true,
          options: [
            {
              label: 'Xfinity Customer',
              value: 1,
              selected: false
            },
            {
              label: 'Xfinity Prospect',
              value: 2,
              selected: false
            }
          ]
        },
        {
          label: 'Gamers',
          code: 'gamer',
          multi: true,
          options: [
            {
              label: 'Non-Gamer',
              value: 1,
              selected: false
            },
            {
              label: 'Casual Gamer',
              value: 2,
              selected: false
            },
            {
              label: 'Enthusiast Gamer',
              value: 3,
              selected: false
            },
            {
              label: 'Hardcore Gamer',
              value: 4,
              selected: false
            }
          ]
        },
        {
          label: 'Likely Internet Switcher',
          code: 'switcher',
          multi: true,
          options: [
            {
              label: 'Yes',
              value: 1,
              selected: false
            },
            {
              label: 'No',
              value: 2,
              selected: false
            }
          ]
        },
        {
          label: 'Movers',
          code: 'mover',
          multi: true,
          options: [
            {
              label: 'Planning to Move (12 mo)',
              value: 1,
              selected: false
            },
            {
              label: 'Not planning to Move (12 mo)',
              value: 2,
              selected: false
            }
          ]
        },
      ]
    }
  }
}
