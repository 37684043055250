export default theme => {
  return {
    migrationView: {
      marginTop: 20,
      marginBottom: 20,
      maxHeight: 700,
      overflow: 'scroll',
      padding: 20
    },
    container: {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: 4
    },
    test: {
      flexGrow: 1
    },
    commit: {
      flex: '0 0 340px'
    }
  }
}
