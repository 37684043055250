import axios from 'axios'
import { Auth } from 'aws-amplify'

// export const USER_GROUPS_REQUEST = 'USER_GROUPS_REQUEST'
// export const USER_GROUPS_RECEIVE = 'USER_GROUPS_RECEIVE'
// export const USER_GROUPS_FAILURE = 'USER_GROUPS_FAILURE'

// export function userGroupsRequest() {
//   return {
//     type: USER_GROUPS_REQUEST,
//   }
// }

// export function userGroupsReceive(groups) {
//   return {
//     type: USER_GROUPS_RECEIVE,
//     data: groups,
//   }
// }

// export function userGroupsFailure(error) {
//   return {
//     type: USER_GROUPS_FAILURE,
//     data: error,
//   }
// }

// export function fetchUserGroups() {
//   return dispatch => {
//     dispatch(userGroupsRequest())    
//     return Promise.all([Auth.currentSession(), Auth.currentUserInfo()]).then(
//       ([session, user ])=> {
//         const {
//           accessToken: { jwtToken },
//         } = session
//         const {
//           attributes: {
//             email
//           }
//         } = user
//         axios.defaults.headers.common['session'] = generateUUID()
//         axios.defaults.headers.common['token'] = jwtToken
//         axios.defaults.headers.common['user'] = JSON.stringify({ email })
//         axios.defaults.baseURL = '/'
//         return axios.get('api/groups').then(
//           res => {
//             dispatch(userGroupsReceive(res.data || []))
//           },
//           err => {
//             dispatch(userGroupsFailure(err.message || ''))
//           },
//         )
//       },
//       err => {
//         dispatch(userGroupsFailure(err.message || ''))
//       },
//     )
//   }
// }

// function shouldFetchUserGroups(state) {
//   const { user } = state

//   if (user.hasFetched === true) {
//     return false
//   }

//   if (user.isFetching === true) {
//     return false
//   }

//   return true
// }

// export function fetchUserGroupsIfNeeded() {
//   return (dispatch, getState) => {
//     if (shouldFetchUserGroups(getState())) {
//       return dispatch(fetchUserGroups())
//     }
//   }
// }


export const USER_RESOURCES_REQUEST = 'USER_RESOURCES_REQUEST'
export const USER_RESOURCES_RECEIVE = 'USER_RESOURCES_RECEIVE'
export const USER_RESOURCES_FAILURE = 'USER_RESOURCES_FAILURE'

export function userResourcesRequest() {
  return {
    type: USER_RESOURCES_REQUEST,
  }
}

export function userResourcesReceive(groups) {
  return {
    type: USER_RESOURCES_RECEIVE,
    data: groups,
  }
}

export function userResourcesFailure(error) {
  return {
    type: USER_RESOURCES_FAILURE,
    data: error,
  }
}

export function fetchUserResources() {
  return dispatch => {
    dispatch(userResourcesRequest())    
    return Promise.all([Auth.currentSession(), Auth.currentUserInfo()]).then(
      ([session, user ])=> {
        const {
          accessToken,
          idToken
        } = session
        const {
          attributes: {
            email
          },
          username
        } = user
        // axios.defaults.headers.common['session'] = generateUUID()
        axios.defaults.headers.common['accesstoken'] = accessToken.jwtToken
        axios.defaults.headers.common['idtoken'] = idToken.jwtToken
        axios.defaults.headers.common.session = username
        // axios.defaults.headers.common['email'] = email
        axios.defaults.baseURL = '/'
        return axios.get('api/resources').then(
          res => {
            dispatch(userResourcesReceive( {...res.data, email, idToken}))
          },
          err => {
            dispatch(userResourcesFailure(err.message || ''))
          },
        )
      },
      err => {
        dispatch(userResourcesFailure(err.message || ''))
      },
    )
  }
}

const shouldFetchUserResources = state => !state.user.ready && !state.user.isFetching

export function fetchUserResourcesIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchUserResources(getState())) {
      return dispatch(fetchUserResources())
    }
  }
}
