import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { PickDateButton, MonthPicker } from '../../../'

import BrandsList from './BrandsList'

const Styled = withStyles(theme =>
  theme.components.adminView.AdminMetadataManager.Search(theme)
)
class SearchFilter extends React.PureComponent {
  state = {
    isMonthPickerOpen: false,
  }

  openMonthPicker = m => {
    this.setState({
      isMonthPickerOpen: true
    })
  }

  closeMonthPicker = () => {
    this.setState({
      isMonthPickerOpen: false
    })
  }

  applyMonthPicker = range => {
    const { updateRange } = this.props
    this.setState({
      isMonthPickerOpen: false
    })
    updateRange(range)
  }

  render() {
    const {
      classes,
      hasTags,
      maxRange,
      toggleBrand,
      setAllBrands,
      toggleUntaggedOnly,
      brandsMap,
      range,
      untaggedOnly
    } = this.props
    const { isMonthPickerOpen } = this.state

    const brands = Object.keys(brandsMap).reduce((array, brand) => {
      array.push(brandsMap[brand])
      return array
    }, [])

    return (
      <Grid container className={classes.container}>
        <Grid item style={{width: '35%', paddingRight: 10}}>
          <PickDateButton range={range} onClick={this.openMonthPicker} />
          {hasTags ?
            <FormControlLabel
              control={
                <Checkbox
                  checked={untaggedOnly}
                  onChange={toggleUntaggedOnly}
                  name="untaggedOnly"
                  color="secondary"
                />
              }
              label="Exclude Tagged Ads"
            /> : null
          }
        </Grid>
        <Grid item style={{width: '65%', paddingLeft: 10}} >
          <BrandsList
            brands={brands}
            toggle={toggleBrand}
            setAll={setAllBrands}
          />
        </Grid>
        <MonthPicker
          maxRange={maxRange}
          range={range}
          open={isMonthPickerOpen}
          close={this.closeMonthPicker}
          apply={this.applyMonthPicker}
          title="Select Month Range"
        />
      </Grid>

    )
  }
}

export default Styled(SearchFilter)
