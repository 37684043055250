import FilterElement from './FilterElement'
export default Object.assign(
  theme => {
    return {
      container: {
        marginLeft: 25,
        marginRight: 25,
        marginTop: 25
      }
    }
  },
  {
    FilterElement
  }
)
