import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button, Typography, IconButton } from '@material-ui/core'
import cn from 'classnames'

import { monthCodeToText } from '../../../new_utils'
import {
  NavigateBefore,
  NavigateNext
} from '@material-ui/icons'

const Styled = withStyles(theme => theme.components.generic.MonthPicker(theme))

class Months extends React.PureComponent {
  state = {
    currentYear: null,
    startYear: null,
    endYear: null,
    disabledLastYear: false,
    disabledNextYear: false
  }

  componentDidMount() {
    const {
      maxRange: { start_month, end_month },
      selected
    } = this.props

    const currentYear = Math.floor(selected / 12) + 2000
    const startYear = Math.floor(start_month / 12) + 2000
    const endYear = Math.floor(end_month / 12) + 2000
    this.setState({
      currentYear,
      startYear,
      endYear,
      months: this.getMonthList(startYear),
      disabledLastYear: startYear === currentYear,
      disabledNextYear: endYear === currentYear
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { restrictMax, restrictMin } = this.props
    const { currentYear } = this.state

    const updateMonthList =
      prevState.currentYear !== currentYear ||
      (restrictMax && prevProps.restrictMax !== restrictMax) ||
      (restrictMin && prevProps.restrictMin !== restrictMin)

    if (updateMonthList) {
      this.setState({
        months: this.getMonthList(currentYear)
      })
    }
  }

  getMonthList = year => {
    const {
      maxRange: { start_month, end_month },
      restrictMin,
      restrictMax
    } = this.props

    const minMonth = (year - 2000) * 12
    const maxMonth = minMonth + 12
    const months = []
    for (let monthCode = minMonth; monthCode < maxMonth; monthCode++) {
      const text = monthCodeToText(monthCode)
      const [monthText, year] = text.split(' ')
      const disabled =
        monthCode < start_month ||
        monthCode > end_month ||
        (restrictMin && monthCode < restrictMin) ||
        (restrictMax && monthCode > restrictMax)
      months.push({
        monthCode,
        monthText,
        year,
        disabled
      })
    }
    return months
  }

  lastYear = () => {
    this.setState((state, props) => {
      const { currentYear, startYear } = this.state
      return {
        currentYear: currentYear - 1,
        disabledLastYear: currentYear === startYear + 1,
        disabledNextYear: false
      }
    })
  }

  nextYear = () => {
    this.setState((state, props) => {
      const { currentYear, endYear } = this.state
      return {
        currentYear: currentYear + 1,
        disabledNextYear: currentYear === endYear - 1,
        disabledLastYear: false
      }
    })
  }

  render() {
    const { classes, selected, title, update } = this.props
    const {
      months,
      currentYear,
      disabledLastYear,
      disabledNextYear
    } = this.state

    if (!months) return null
    return (
      <Grid container className={classes.monthsContainer}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.monthsTitle}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={1}>
              <IconButton
                variant="text"
                onClick={this.lastYear}
                disabled={disabledLastYear}
              >
                <NavigateBefore />
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle1" align="center">
                {currentYear}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                variant="text"
                onClick={this.nextYear}
                disabled={disabledNextYear}
              >
                <NavigateNext />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignContent="center">
            {months.map(({ monthText, monthCode, disabled }) => (
              <Grid item xs={2} key={monthCode}>
                <Button
                  variant="text"
                  disabled={disabled}
                  onClick={() => update(monthCode)}
                  className={cn(
                    selected === monthCode ? classes.selected : null,
                    classes.hover
                  )}
                >
                  {monthText}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Styled(Months)
