export default {
  columnFormat: [
    { header: 'Brand', key: 'brand', width: 25 },
    { header: 'Offer Code', key: 'ad_code', width: 20 },
    { header: 'Month Tested', key: 'month_tested', width: 20 },
    { header: 'Sample Size', key: 'sample_size', width: 20 },
    { header: 'Ranking Metric', key: 'ranking_metric', width: 20 },
    { header: 'Offer Headline', key: 'title', width: 32 },
  ]
}
