import React from 'react'
import { Auth, Logger } from 'aws-amplify'
import AuthPiece from './AuthPiece'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import StyledContainer from './StyledContainer'

const logger = new Logger('SignIn')

const Styled = withStyles(theme => {
  return {
    submitButton: {
      width: '100%',
      minWidth: 'unset'
    }
  }
})

class SignIn extends AuthPiece {
  constructor(props) {
    super(props)

    this.checkContact = this.checkContact.bind(this)
    this.signIn = this.signIn.bind(this)

    this._validAuthStates = ['signIn', 'signedOut', 'signedUp']
    this.state = {}
  }

  checkContact(user) {
    Auth.verifiedContact(user).then(data => {
        if (data.verified) {
        this.changeState('signedIn', user)
      } else {
        user = Object.assign(user, data)
        this.changeState('verifyContact', user)
      }
    })
  }

  signIn() {
    const { username, password } = this.inputs
    Auth.signIn(username, password)
      .then(user => {
        logger.debug(user)
        if (user.challengeName === 'SMS_MFA') {
          this.changeState('confirmSignIn', user)
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          logger.debug('require new password', user.challengeParam)
          this.changeState('requireNewPassword', user)
        } else {
          this.checkContact(user)
        }
      })
      .catch(err => {
        this.error(err)
      })
  }

  showComponent(theme) {
    const { hide } = this.props
    if (hide && hide.includes(SignIn)) {
      return null
    }

    return (
      <StyledContainer message="Sign In">
        <TextField
          id="email"
          label="email"
          fullWidth
          margin="normal"
          name="username"
          onChange={this.handleInputChange}
        />
        <TextField
          id="password"
          label="password"
          fullWidth
          margin="normal"
          name="password"
          type="password"
          onChange={this.handleInputChange}
        />
        <br />
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          style={{ marginTop: 30 }}
          onClick={this.signIn}
        >
          Sign In
        </Button>
        <Button
          fullWidth
          variant="contained"
          style={{ marginTop: 10, marginBottom: 30 }}
          onClick={() => this.changeState('signUp')}
        >
          Register
        </Button>
        <br />
        <Button
          color="inherit"
          onClick={() => this.changeState('forgotPassword')}
        >
          Forgot password?
        </Button>
        <Button
          color="inherit"
          onClick={() => this.changeState('confirmSignUp')}
        >
          Confirm Email
        </Button>
      </StyledContainer>
    )
  }
}

export default Styled(SignIn)
