

import {
  FORD_META_DATA_REQUEST,
  FORD_META_DATA_RECEIVE,
  FORD_META_DATA_FAILURE,
  APP_CLR_CONFIG,
} from '../actions'

import {
  ADMIN_RECEIVE_METADATA
} from '../../../new_actions'

const initialState = {
  hasFetched: false,
  isFetching: false,
  hasErrored: false,
  errMessage: '',
  list: [],
  map: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FORD_META_DATA_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      }
    case FORD_META_DATA_RECEIVE:
      return {
        ...initialState,
        list: action.data.ads,
        map: action.data.adsMap, // listToMap(action.data.ads, 'ad_code'),
        hasFetched: true,
      }

    case ADMIN_RECEIVE_METADATA:
      return action.data.isAuto ? {
        ...initialState,
        list: action.data.ads,
        map: action.data.adsMap, // listToMap(action.data.ads, 'ad_code'),
        ready: true,
      } : state

    case FORD_META_DATA_FAILURE: {
      return {
        ...initialState,
        hasFetched: false,
        hasErrored: true,
        errMessage: action.data
      }
    }
    case APP_CLR_CONFIG:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
