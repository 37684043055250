import defaultConfig from '../../Template/config'
import main from './main'
import sidebar from './sidebar'
import metadata from './metadata'
import components from './components'

const config = {
  ...defaultConfig,
  main,
  sidebar,
  components,
  metadata
}

export {config}
export default config

