import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { withStyles } from '@material-ui/core/styles'
const Styled = withStyles( theme => theme.components.generic.SingleSelectMenu(theme)) 

class SingleSelectMenu extends Component {
  state = {
    anchorEl: null,
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = item => {
    this.setState(
      () => {
        return { anchorEl: null }
      },
      () => {
        if (item && item.value) {
          this.props.handleSelect(item)
        }
      },
    )
  }

  render() {
    const { anchorEl } = this.state
    const { classes, items, disabled } = this.props

    const selected = items.find(d => d.selected === true)

    return (
      <div>
        <Paper
          variant="outlined"
          disabled={disabled}
          className={classes.button}
          onClick={this.handleClick}
        >
          <Typography
            style={{ textAlign: 'left' }}
            variant="caption"
            color="inherit"
            noWrap
          >
            Ranking Metric
          </Typography>
          <Typography
            style={{ textAlign: 'left' }}
            variant="subtitle1"
            color="inherit"
            noWrap
          >
            {selected && selected.label}
          </Typography>
        </Paper>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {items.map(d => {
            return (
              <MenuItem
                key={d.value}
                selected={d.selected}
                onClick={() => this.handleClose(d)}
                className={classes.activeType}
              >
                {d.label}
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    )
  }
}

SingleSelectMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
}

SingleSelectMenu.defaultProps = {
  disabled: false,
}

export default Styled(SingleSelectMenu)
