import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import SampleSizeIcon from '@material-ui/icons/People'
import cn from 'classnames'
import { format } from '../../../../../../../../new_utils'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardScrollableColumn.ScorecardAdHeader(theme)
)

class ScorecardAdHeader extends React.PureComponent {
  render() {
    const {
      classes,
      columnIndex,
      gridData: { adHeaders, perksList },
      collapse,
      collapsed,
      config
    } = this.props

    const {
      brand,
      ad_code,
      title,
      speed,
      price,
      terms,
      added_savings,
      market_status,
      month_tested,
      n,
      links,
      perks,
      header_metrics
    } = adHeaders[columnIndex]

    return (
      <Grid container direction="column" justify="center" alignItems="center" className={classes.container}>
        <Grid item className={cn(classes.brandImage)}>
          <img className={classes.brandLogo} alt={brand} src={links.brand} />
        </Grid>

        <div onClick={collapse} style={{ width: '100%' }}>
          <Grid item className={cn(classes.headerGrid)}>
            <Tooltip title={ad_code}>
              <Typography
                noWrap
                variant="caption"
                align="center"
                style={{ maxWidth: config.metricWidth }}
                display="block"
              >
                {ad_code}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item className={cn(classes.headerGrid)}>
            <Tooltip title={title}>
              <Typography
                noWrap
                variant="caption"
                align="center"
                style={{ maxWidth: config.metricWidth }}
                display="block"
              >
                {title}
              </Typography>
            </Tooltip>
          </Grid>
          {collapsed ? null : (
            <Fragment>
              <Grid item className={cn(classes.headerGrid)}>
                <Typography
                  noWrap
                  variant="caption"
                  align="center"
                  style={{ maxWidth: config.metricWidth }}
                  display="block"
                >
                  {speed}
                </Typography>
              </Grid>
              <Grid item className={cn(classes.headerGrid)}>
                <Tooltip title={price}>
                  <Typography noWrap variant="caption" align="center" display="block">
                    {price}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item className={cn(classes.headerGrid)}>
                <Tooltip title={terms}>
                  <Typography noWrap variant="caption" align="center" display="block">
                    {terms}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item className={cn(classes.headerGrid)}>
                <Tooltip title={added_savings}>
                  <Typography noWrap variant="caption" align="center" display="block">
                    {added_savings}
                  </Typography>
                </Tooltip>
              </Grid>

              {perksList.map(perk => (
                <Grid item className={cn(classes.headerGrid)}>
                  <Tooltip title={perks[perk]}>
                    <Typography noWrap variant="caption" align="center" display="block">
                      {perks[perk]}
                    </Typography>
                  </Tooltip>
                </Grid>
              ))}

              <Grid item className={cn(classes.headerGrid)}>
                <Tooltip title={market_status}>
                  <Typography noWrap variant="caption" align="center" display="block">
                    {market_status}
                  </Typography>
                </Tooltip>
              </Grid>
            </Fragment>
          )}

          <Grid item className={cn(classes.headerGrid)}>
            <Typography noWrap variant="caption" align="center" display="block">
              {month_tested || 'N/A'}
            </Typography>
          </Grid>

          {collapsed ? null : (
            <Fragment>
              <Grid item className={cn(classes.headerGrid, classes.center)}>
                <SampleSizeIcon className={classes.icon} />
                <Typography noWrap variant="caption" align="center" style={{ display: 'inline' }}>
                  {format.comma(0)(n)}
                </Typography>
              </Grid>
            </Fragment>
          )}

          {header_metrics.map(header_metric => (
            <Grid item className={cn(classes.headerGrid)} key={header_metric.label}>
              <div className={classes.stat} style={{ backgroundColor: header_metric.statTest.color }}>
              <Typography noWrap variant="body2" align="center" className={cn(header_metric.statTest.color ? classes.headerMetricValueInverseText :classes.headerMetricValueText)} display="block">
                  {header_metric.avg}
                </Typography>
              </div>
            </Grid>
          ))}
        </div>
      </Grid>
    )
  }
}

export default Styled(ScorecardAdHeader)
