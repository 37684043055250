import { combineReducers } from 'redux'
import filter from './filter'
import viewer from './viewer'
import scorecard from './scorecard'
import ads from './ads'
import displayFilters from './displayFilter'
import bulkQuery from './bulkQuery'

export default combineReducers({
  ads,
  filter,
  bulkQuery,
  displayFilters,
  viewer,
  scorecard,
})
