// Formats: '%,n', '$,n', '$x,n', 'f,n', 'i,n'
// operations: 'last(a)', prod(a,b), ratio(a,b)
export default {
  '***': {
    'market_smb': {
      '***': [
        {
          metric: 100,
          inHeader: true,
          label: 'AdPi Score',
          rowType: 'demphasized',
          dataFormat: '.,0',
          reverseStat: false,
          minSampleSize: 0
        },
        {
          label: 'IN-MARKET PERFORMANCE',
          rowType: 'hb0'
        },
        {
          label: 'BREAKTHROUGH',
          rowType: 'h0'
        },
        {
          metric: 200,
          label: 'Branded Recall (Last Month Tested)',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false,
          recall: {
            metric: 101
          }
        },
        {
          metric: 101,
          label: 'Branded Recall (Avg. Recall)',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          label: 'IMPACT',
          rowType: 'h0'
        },
        {
          metric: 102,
          label: 'Lift to Consideration',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 103,
          label: 'Lift to Impression',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 104,
          label: 'Net Actions',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 105,
          label: 'Talk about the ad to colleagues or employees',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 106,
          label: 'Ask associates or peers about their experiences',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 107,
          label: 'Go online for more information',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 108,
          label: 'Visit the company\'s website',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 109,
          label: 'Call for more information',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 110,
          label: 'Respond to a mail solicitation',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 111,
          label: 'Take an in-person meeting with a sales rep',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 112,
          label: 'Purchase this brand\'s products or services',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          label: 'CREATIVE DIAGNOSTICS',
          rowType: 'hb0'
        },
        {
          label: 'BRANDING',
          rowType: 'h0'
        },
        {
          metric: 113,
          label: 'Brand Recognition',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 114,
          label: 'Ease of Branding',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 115,
          label: 'Good Fit with Brand',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 116,
          label: 'Demonstrates Brand Credibility',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          label: 'VALUE PROPOSITION',
          rowType: 'h0'
        },
        {
          metric: 117,
          label: 'Would make my life easier',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 118,
          label: 'Is different than what other providers offer',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 119,
          label: 'Is better than what my business is currently using',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 120,
          label: 'Is a good value for the money',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 121,
          label: 'Would improve the way my company does business',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 122,
          label: 'Is a benefit/service my business could use',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          label: 'APPEAL',
          rowType: 'h0'
        },
        {
          metric: 123,
          label: 'Likes',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 124,
          label: 'Dislikes',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: true
        },
        {
          metric: 125,
          label: 'CREATIVE POTENTIAL SUMMARY SCORE',
          rowType: 'dh0',
          dataFormat: '.,0',
          reverseStat: false
        },
        {
          metric: 126,
          label: 'Brand Connection',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 127,
          label: 'Portrays a company I can depend on',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 128,
          label: 'Is trustworthy',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 129,
          label: 'Portrays a company I want to be associated with',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 130,
          label: 'Fulfills my business\'s needs',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 131,
          label: 'Makes me feel good about this brand',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 132,
          label: 'Brand Value',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 133,
          label: 'Has innovative technology',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 134,
          label: "Provides unique features I can't get from other providers",
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 135,
          label: 'Offers technologically advanced solutions',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 136,
          label: 'Helps my business grow',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 137,
          label: 'Has a range of products/services that work well together',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 138,
          label: 'Offers cost-effective solutions',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 139,
          label: 'Relevance',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 140,
          label: 'Shows business situations I can relate to',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 141,
          label: 'Addresses an issue that\'s important to my business',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 142,
          label: 'Is relevant to my business',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 143,
          label: 'Spoke to me directly',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 144,
          label: 'Cognitive Connection',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 145,
          label: 'Is informative',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 146,
          label: 'Is clear',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 147,
          label: 'Is confusing',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: true
        },
        {
          metric: 148,
          label: 'Is believable',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 149,
          label: 'Makes me think of the brand in a new way',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 150,
          label: 'Tells me something new about the brand',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 151,
          label: 'Emotional Connection',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 152,
          label: 'Values its customers',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 153,
          label: 'Helps me achieve my business goals',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 154,
          label: 'Is inspiring',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 155,
          label: 'Is reassuring',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 156,
          label: 'Is heartwarming',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 157,
          label: 'Engagement',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 158,
          label: 'Is clever',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 159,
          label: 'Is unique',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 160,
          label: 'Is entertaining',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 161,
          label: 'Is worth seeing again',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 162,
          label: 'Is memorable',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          label: 'PLATFORM VIEWED',
          rowType: 'h0'
        },
        {
          metric: 163,
          label: 'TV',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 164,
          label: 'Social Sites',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 165,
          label: 'Other Online Sites',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          label: '',
          rowType: 'eol'
        }
      ]
    },
    'market_mm_ent': {
      '***': [
        {
          metric: 100,
          inHeader: true,
          label: 'AdPi Score',
          rowType: 'demphasized',
          dataFormat: '.,0',
          reverseStat: false,
          minSampleSize: 0
        },
        {
          label: 'IN-MARKET PERFORMANCE',
          rowType: 'hb0'
        },
        {
          label: 'BREAKTHROUGH',
          rowType: 'h0'
        },
        {
          metric: 200,
          label: 'Branded Recall (Last Month Tested)',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false,
          recall: {
            metric: 101
          }
        },
        {
          metric: 101,
          label: 'Branded Recall (Avg. Recall)',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          label: 'IMPACT',
          rowType: 'h0'
        },
        {
          metric: 102,
          label: 'Lift to Consideration',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 103,
          label: 'Lift to Impression',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 104,
          label: 'Net Actions',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 105,
          label: 'Talk about the ad to colleagues or peers',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 106,
          label: 'Ask associates or peers about their experiences',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 107,
          label: 'Go online for more information',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 108,
          label: 'Visit the company\'s website',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 109,
          label: 'Call for more information',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 166,
          label: 'Take a meeting with an account rep',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 167,
          label: 'Include this company in my RFP process',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        // {
        //   metric: 110,
        //   label: 'Respond to a mail solicitation',
        //   rowType: 'd1',
        //   dataFormat: '%,1', 
        //   reverseStat: false
        // },
        // {
        //   metric: 111,
        //   label: 'Take an in-person meeting with a sales rep',
        //   rowType: 'd1',
        //   dataFormat: '%,1', 
        //   reverseStat: false
        // },
        {
          metric: 112,
          label: 'Purchase this brand\'s products or services',
          rowType: 'd1',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          label: 'CREATIVE DIAGNOSTICS',
          rowType: 'hb0'
        },
        {
          label: 'BRANDING',
          rowType: 'h0'
        },
        {
          metric: 113,
          label: 'Brand Recognition',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 114,
          label: 'Ease of Branding',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 115,
          label: 'Good Fit with Brand',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 116,
          label: 'Demonstrates Brand Credibility',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          label: 'VALUE PROPOSITION',
          rowType: 'h0'
        },
        {
          metric: 117,
          label: 'Would make my life easier',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 118,
          label: 'Is different than what other providers offer',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        // {
        //   metric: 119,
        //   label: 'Is better than what my business is currently using',
        //   rowType: 'd0',
        //   dataFormat: '%,1', 
        //   reverseStat: false
        // },
        // {
        //   metric: 120,
        //   label: 'Is a good value for the money',
        //   rowType: 'd0',
        //   dataFormat: '%,1', 
        //   reverseStat: false
        // },
        {
          metric: 168,
          label: 'Is better than what my company is currently using',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 121,
          label: 'Would improve the way my company does business',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        // {
        //   metric: 122,
        //   label: 'Is a benefit/service my business could use',
        //   rowType: 'd0',
        //   dataFormat: '%,1', 
        //   reverseStat: false
        // },
        {
          metric: 169,
          label: 'Is a benefit/service my company could use',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          label: 'APPEAL',
          rowType: 'h0'
        },
        {
          metric: 123,
          label: 'Likes',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 124,
          label: 'Dislikes',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: true
        },
        {
          metric: 125,
          label: 'CREATIVE POTENTIAL SUMMARY SCORE',
          rowType: 'dh0',
          dataFormat: '.,0',
          reverseStat: false
        },
        {
          metric: 126,
          label: 'Brand Connection',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 127,
          label: 'Portrays a company I can depend on',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 128,
          label: 'Is trustworthy',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 129,
          label: 'Portrays a company I want to be associated with',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        // {
        //   metric: 130,
        //   label: 'Fulfills my business\'s needs',
        //   rowType: 'd0',
        //   dataFormat: '.,2',
        //   reverseStat: false
        // },
        {
          metric: 170,
          label: 'Fulfills my company\'s needs',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 131,
          label: 'Makes me feel good about this brand',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 132,
          label: 'Brand Value',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 133,
          label: 'Has innovative technology',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 134,
          label: "Provides unique features I can't get from other providers",
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 135,
          label: 'Offers technologically advanced solutions',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        // {
        //   metric: 136,
        //   label: 'Helps my business grow',
        //   rowType: 'd0',
        //   dataFormat: '.,2',
        //   reverseStat: false
        // },
        {
          metric: 171,
          label: 'Helps my company grow',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        }, {
          metric: 137,
          label: 'Has a range of products/services that work well together',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        // {
        //   metric: 138,
        //   label: 'Offers cost-effective solutions',
        //   rowType: 'd0',
        //   dataFormat: '.,2',
        //   reverseStat: false
        // },
        {
          metric: 172,
          label: 'Offers services everywhere my company is located',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 139,
          label: 'Relevance',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 140,
          label: 'Shows business situations I can relate to',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        // {
        //   metric: 141,
        //   label: 'Addresses an issue that\'s important to my business',
        //   rowType: 'd0',
        //   dataFormat: '.,2',
        //   reverseStat: false
        // },
        // {
        //   metric: 142,
        //   label: 'Is relevant to my business',
        //   rowType: 'd0',
        //   dataFormat: '.,2',
        //   reverseStat: false
        // },
        {
          metric: 173,
          label: 'Addresses an issue that\'s important to my company',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 174,
          label: 'Is relevant to my company',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 143,
          label: 'Spoke to me directly',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 144,
          label: 'Cognitive Connection',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 145,
          label: 'Is informative',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 146,
          label: 'Is clear',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 147,
          label: 'Is confusing',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: true
        },
        {
          metric: 148,
          label: 'Is believable',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 149,
          label: 'Makes me think of the brand in a new way',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 150,
          label: 'Tells me something new about the brand',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 151,
          label: 'Emotional Connection',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 152,
          label: 'Values its customers',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        // {
        //   metric: 153,
        //   label: 'Helps me achieve my business goals',
        //   rowType: 'd0',
        //   dataFormat: '.,2',
        //   reverseStat: false
        // },
        {
          metric: 175,
          label: 'Partners with me to find the right business solutions',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 154,
          label: 'Is inspiring',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 155,
          label: 'Is reassuring',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 156,
          label: 'Is heartwarming',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 157,
          label: 'Engagement',
          rowType: 'dh0',
          dataFormat: 'idx',
          reverseStat: false
        },
        {
          metric: 158,
          label: 'Is clever',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 159,
          label: 'Is unique',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 160,
          label: 'Is entertaining',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 161,
          label: 'Is worth seeing again',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          metric: 162,
          label: 'Is memorable',
          rowType: 'd0',
          dataFormat: '.,2',
          reverseStat: false
        },
        {
          label: 'PLATFORM VIEWED',
          rowType: 'h0'
        },
        {
          metric: 163,
          label: 'TV',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 164,
          label: 'Social Sites',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          metric: 165,
          label: 'Other Online Sites',
          rowType: 'd0',
          dataFormat: '%,1', 
          
          reverseStat: false
        },
        {
          label: '',
          rowType: 'eol'
        }
      ]
    }
  }
}
