import React  from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import cn from 'classnames'

const styles = theme => ({
  activated: {
    color: '#ff6734',
    // boxShadow: '1px 1px 1px 3px #ff6734'
  },
  tooltip: {
    fontSize: 14
  },
  hover: {
    '&:hover': {
      backgroundColor: '#e0e0e0',
      cursor: 'pointer'
    },
    border: `1px solid ${theme.palette.background.default}`,
    boxShadow: `0 1px 1px rgba(0,0,0,0.14)`
  }
})

export default withStyles(styles)(props => {
  const { label, icon, update, classes, activated, disabled } = props
  return (
    <Grid item onClick={disabled ? undefined : update}>
      <Tooltip
        placement="bottom"
        title={activated || ''}
        classes={{
          tooltip: classes.tooltip
        }}
      >
        <Paper
          elevation={0}
          className={cn(classes.hover, activated ? classes.activated : null)}
          style={{ height: 62, width: 65, padding: 8, marginTop: -10 }}
          classes={{ root: cn(activated ? classes.activated : null) }}
        >
          <Grid style={{marginLeft: 10}}>
            <Typography
              variant="caption"
              align="center"
              color="inherit"
              style={{ paddigTop: 7 }}
            >
              {label}
            </Typography>
            {icon &&
              React.createElement(icon, {
                style: {
                  fontSize: 28,
                }
              })}
          </Grid>
        </Paper>
      </Tooltip>
    </Grid>
  )
})
