import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import { Grid, Paper, TextField, Typography, Checkbox } from '@material-ui/core'

import { AsyncWrapper } from '../../../new_components'

import AdminUsers from './AdminUsers'
import AdminUserRoles from './AdminUserRoles'
import AdminUserAccount from './AdminUserAccount'
import  AdminAudit  from '../AdminAudit'

import {
  adminFetch,
  adminUpdate,
  adminDelete,
  adminUpdateUserMatch,
  adminUpdateSelectedUser,
  adminToggleIncludeAppUsers,
  adminToggleIncludeInactiveUsers
} from '../../../new_actions'

const Styled = withStyles(theme =>
  theme.components.adminView.AdminUserManager(theme)
)

class AdminUserManager extends React.PureComponent {

  componentDidMount() {
    const { dispatch, users: { ready } } = this.props

    if (!ready)
      dispatch(adminFetch('users'))
  }

  updateMatchName = matchName => {
    const { dispatch } = this.props
    dispatch(adminUpdateUserMatch(matchName.replace(/[?*[\]()^%/\\]/gi, "")))
  }

  updateUser = item => {
    const { dispatch } = this.props
    const { email, ...params } = item
    dispatch(adminUpdate('users', { id: email, ...params }))
  }

  deleteUser = item => {
    const { dispatch } = this.props
    const { email } = item
    dispatch(adminDelete('users', { id: email }))
  }

  updateSelectedUser = user => {
    const { dispatch } = this.props
    dispatch(adminUpdateSelectedUser(user))
  }

  audit = () => {
    console.log(`Auditing`)
  }

  toggleIncludeAppUsers = () => {
    const { dispatch } = this.props
    dispatch(adminToggleIncludeAppUsers())
  }

  toggleIncludeInactiveUsers = () => {
    const { dispatch } = this.props
    dispatch(adminToggleIncludeInactiveUsers())
  }

  render() {
    const { users, groupName, resourceConfig, classes } = this.props

    return (
      <Paper id="user-editor-paper" className={classes.userManagerView}>
        <AsyncWrapper item={users} thickness={6}>
          <Grid container id="user-editor-main-container" direction="column" className={classes.container}>
            <Grid item id="user-editor-control" style={{ flex: 1, paddingBottom: 0 }}>
              <Grid container id="item-editor-control-data-editor" justify="space-between" className={classes.container}>
                <Grid item id="item-editor-control-text-input" >
                  <TextField
                    size="medium"
                    fullWidth
                    helperText="Filter Users"
                    value={users.match}
                    onChange={e => this.updateMatchName(e.target.value)}
                    autoComplete="off"
                    className={classes.userMatchText}
                    style={{ width: 250, marginRight: 20 }}
                  />
                  <Checkbox checked={users.includeAppUsers} onClick={this.toggleIncludeAppUsers} color="secondary"/>
                  <Typography variant="body2" display="inline" >Current Users Only</Typography>
                  <Checkbox checked={users.includeInactiveUsers} onClick={this.toggleIncludeInactiveUsers} color="secondary"/>
                  <Typography variant="body2" display="inline">Include Inactive Users</Typography>
                </Grid>
                <Grid item id="item-editor-control-audit-button" >
                  {/* <Button variant="contained" color="primary" onClick={this.audit}>Access Audit</Button> */}
                  <AdminAudit groupName={groupName}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item id="user-editor-data" style={{ flex: 10 }}>
              <Grid container id="user-editor-data-container" className={classes.container} >
                <Grid item id="user-select-item" className={classes.userSelectItem}>
                  <Typography variant="h6" display="inline" align="center">Select User</Typography>
                  <AdminUsers users={users.matched} selectedUser={users.selectedUser} selectUser={this.updateSelectedUser} uuid={users.uuid} />
                </Grid>
                <Grid item id="user-data-edit-item" className={classes.userDataEditItem}>
                  <Grid container id="user-editor-account-status" direction="column" justify="space-between" style={{height: '100%'}} >
                    <Grid item>
                      <Typography variant="h6">
                        Update User
                      </Typography>
                      <AdminUserRoles resourceConfig={resourceConfig} user={users.selectedUser} update={this.updateUser} />
                    </Grid>
                    <Grid item>
                      <AdminUserAccount user={users.selectedUser} updateUser={this.updateUser} deleteUser={this.deleteUser}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AsyncWrapper>
      </Paper >
    )

  }
}

const Connected = connect(state => {
  const {
          user: {
          accessRights,
    },
    admin: {
          users
        },
    dashboard: {
          init: {
          resourceConfig,
          config: {
          main: {
          appName,
          groupName
        }
        }
      }
    }
  } = state

  return {
          users,
          resourceConfig: {
          ...resourceConfig,
          roles: resourceConfig.roles.filter(role => accessRights.isAdmin || role !== 'manager')
    },
    appName,
    groupName
  }
})
export default Connected(Styled(AdminUserManager))
