import {
  FORD_META_DATA_RECEIVE,
  FORD_OPEN_BULK_QUERY,
  FORD_UPDATE_BULK_QUERY_FILTER,
  FORD_UPDATE_BULK_QUERY_STATIC_FILTER,
  FORD_UPDATE_BULK_QUERY_DISPLAY_FILTER,
  FORD_BULK_QUERY_REQUEST,
  FORD_BULK_QUERY_RECEIVE,
  FORD_BULK_QUERY_DONE,
  FORD_BULK_QUERY_FAILURE,
  APP_SET_AUTO_CONFIG
} from '../actions'

import { initStaticFilters, updateStaticFilter, updateDisplayFilters } from './utils'
import { generateUUID } from '../../../new_utils'

const initialState = {
  open: false,
  isFetching: false,
  hasErrored: false,
  slicerMap: {},
  slicerList: [],
  bulkFilters: [],
  bulkSelections: [],
  staticFilters: [],
  staticFilterQuery: null,
  displayFilters: [],
  displayFiltersSlicer: {},
  result: {}
}

export default function filterReducer(state = initialState, action) {

  switch (action.type) {

    case FORD_META_DATA_RECEIVE: {

      const bulkFilters = action.data.bulkFilters.map(bulkFilter => {
        const { label, field, data } = bulkFilter
        return {
          label,
          field,
          options: data.map(item => {
            const { value, label } = item
            return {
              value,
              label,
            }
          }),
          selections: []
        }
      })

      const slicerMap = action.data.bulkFilters.reduce((obj, bulkFilter) => {
        obj[bulkFilter.field] = []
        return obj
      }, {})

      const slicerList = Object.keys(slicerMap).reduce((array, slicerField) => {
        array.push({
          field: slicerField,
          value: slicerMap[slicerField]
        })
        return array
      }, [])

      const displayFilters = [{
        label: 'Brands',
        code: 'brand',
        multi: true,
        options: Object.keys(action.data.ads.reduce((brands, ad) => {
          const { brand } = ad
          brands[brand] = true
          return brands
        }, {})).map((label, value) => {
          return {
            label,
            value,
            selected: false
          }
        })
      }]

      const displayFiltersSlicer = displayFilters.reduce((obj, displayFilter) => { obj[displayFilter.code] = displayFilter.options.filter(option => option.selected); return obj }, {})

      const bulkSelections = action.data.bulkFilters.map(f => [])

      return {
        ...state,
        slicerMap,
        slicerList,
        bulkFilters,
        bulkSelections,
        displayFilters,
        displayFiltersSlicer
      }
    }

    case FORD_UPDATE_BULK_QUERY_FILTER: {
      const { filterIndex, value } = action.data
      const bulkFilters = [...state.bulkFilters]
      const slicerMap = { ...state.slicerMap }

      const bulkSelections = [...state.bulkSelections]
      bulkFilters[filterIndex].selections = value
      bulkSelections[filterIndex] = bulkFilters[filterIndex].options.filter((option, optionIndex) => value.includes(optionIndex)).map(option => option.value)
      slicerMap[bulkFilters[filterIndex].field] = bulkSelections[filterIndex]
      const slicerList = Object.keys(slicerMap).reduce((array, slicerField) => {
        array.push({
          field: slicerField,
          value: slicerMap[slicerField]
        })
        return array
      }, [])
      return {
        ...state,
        slicerMap,
        slicerList,
        bulkFilters,
        bulkSelections
      }
    }

    case FORD_UPDATE_BULK_QUERY_STATIC_FILTER: {
      const { staticFilterQuery, staticFilters } = updateStaticFilter(state, action.data)
      return {
        ...state,
        staticFilters,
        staticFilterQuery
      }
    }

    case FORD_UPDATE_BULK_QUERY_DISPLAY_FILTER: {
      const displayFilters = updateDisplayFilters(state.displayFilters, action.data)
      const displayFiltersSlicer = displayFilters.reduce(
        (obj, displayFilter) => {
          obj[displayFilter.code] =
            displayFilter.options
              .filter(option => option.selected)
              .reduce((array, item) => {
                array.push(item.label);
                return array
              }, [])
          return obj
        }, {})
      return {
        ...state,
        displayFilters,
        displayFiltersSlicer
      }
    }

    case FORD_BULK_QUERY_REQUEST:
      return {
        ...state,
        hasErrored: false,
        isFetching: true
      }

    case FORD_BULK_QUERY_RECEIVE:
      // const { norms, metrics, metricsLabels} = action.data
      return {
        ...state,
        hasErrored: false,
        isFetching: false,
        result: {
          ...action.data,
          uuid: generateUUID()
        }
      }

    case FORD_BULK_QUERY_DONE:
      return {
        ...state,
        result: {
          uuid: state.result.uuid
        },
        isFetching: false,
        hasErrored: false
      }

    case FORD_BULK_QUERY_FAILURE:
      return {
        ...state,
        hasErrored: true,
        isFetching: false
      }

    case FORD_OPEN_BULK_QUERY:
      return {
        ...state,
        open: !!action.data
      }

    case APP_SET_AUTO_CONFIG:
      return {
        ...initialState,
        staticFilters: initStaticFilters(action.data.staticFilters)
      }

    default:
      return state
  }
}
