// import ScorecardAdHeader from './ScorecardAdHeader'
// import ScorecardMainHeader from './ScorecardMainHeader'
// import ScorecardMetricsLabels from './ScorecardMetricsLabels'
// import ScorecardNormValues from './ScorecardNormValues'
// import ScorecardNormHeader from './ScorecardNormHeader'
// import ScorecardMetricsValues from './ScorecardMetricsValues'

import ScorecardFixedColumn from './ScorecardFixedColumn'
import ScorecardScrollableColumn from './ScorecardScrollableColumn'

export default Object.assign(
  theme => {
    return {
      container: {},
      metricsLabels: {},
      metrics: {
      },
      gridHeader: {
        width: '100%',
        overflow: 'hidden !important'
      },
      gridLeft: {
        overflow: 'hidden !important'
      }
    }
  },
  {
    ScorecardFixedColumn,
    ScorecardScrollableColumn
    // ScorecardAdHeader,
    // ScorecardMainHeader,
    // ScorecardMetricsLabels,
    // ScorecardMetricsValues,
    // ScorecardNormValues,
    // ScorecardNormHeader
  }
)
