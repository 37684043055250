export default theme => {
    return {
        userList: {
            height: 300,
            border: `2px solid ${theme.palette.grey[400]}`,
            borderRadius: 3
        },
        selected: {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.listSelectBackground.default
        },
        appUser: {
          
        },
        hover: {
          "&:hover": {
            backgroundColor: theme.palette.listSelectBackground.default
          }
        },
        inactive: {
          opacity: 0.25
        }
    }
}
