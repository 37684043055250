import React from 'react'
import { Auth } from 'aws-amplify'
import {withRouter} from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import LogoutIcon from '@material-ui/icons/PowerSettingsNew'

import {withStyles} from '@material-ui/core/styles'
const Styled = withStyles(theme => theme.components.navigation.SignOutButton(theme) )

export default withRouter( Styled( props => props.visible !==false ? (
  <Tooltip id="logout-tooltip" title={`${props.email}`}>
    <IconButton
      // color="inherit"
      onClick={e => {
        e.preventDefault()
        Auth.signOut().then(() => {
          props.history.push('/')
          window.location.reload(true)
        })
      }}
    >
      <LogoutIcon />
    </IconButton>
  </Tooltip>
) : null ))
