import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { PickDateButton, MonthPicker } from '../../../../'
import { dashboardUpdateExtraFilterData } from '../../../../../new_actions'

import BrandsList from './BrandsList'
// import AdTagsList from './AdTagsList'
import { AdTagsList } from '../../../../generic'
import ExtraFilter from './ExtraFilter'

const Styled = withStyles(theme =>
  theme.components.selection.Search.SearchFilter(theme)
)
class SearchFilter extends React.PureComponent {
  state = {
    isMonthPickerOpen: false,
    extraFilterFilter: null
  }

  setExtraFilterFilter = extraFilterFilter => {
    this.setState({ extraFilterFilter })
  }

  updateFilter = data => {
    const { dispatch } = this.props
    dispatch(dashboardUpdateExtraFilterData(data))
  }

  typeFilter = adType => ad => ad.ad_type.includes(adType.field)
  brandFilter = brandsMap => ad => brandsMap[ad.brand.code]
  monthFilter = (start_month, end_month, excludeNonNorm = false) => ad => {
    return (
      ad.months.filter(month => {
        return (
          month.month_code >= start_month &&
          month.month_code <= end_month &&
          (excludeNonNorm ? month.is_norm : true)
        )
      }).length > 0
    )
  }

  tagFilter = adTags => ad => {
    return (
      adTags.reduce((clear, tag) => clear && tag.selected === 0, true) ||
      adTags.reduce(
        (included, tag) => included || (tag && ad.tags && tag.selected === 2 && ad.tags[tag.id]),
        false
      )
    )
  }
  extraFilter = ad =>
    this.state.extraFilterFilter ? this.state.extraFilterFilter(ad) : () => true

  updateCandidates = () => {
    const {
      adType,
      adsList,
      adsMap,
      brandsMap,
      adTags,
      range: { start_month, end_month },
      updateCandidates,
      selectedTab,
      calcFlags
    } = this.props
    const existingAds = adsMap.list.map(ad => ad.ad_code)
    const excludeNonNorm = calcFlags[selectedTab.tab].excludeNonNorm
    let matchingSet = adsList
      .filter(this.typeFilter(adType))
      .filter(this.brandFilter(brandsMap))
      .filter(this.monthFilter(start_month, end_month, excludeNonNorm))
      .filter(this.tagFilter(adTags))
      .filter(this.extraFilter)

    updateCandidates(
      {
        removeCandidates: matchingSet
          .filter(ad => existingAds.includes(ad.ad_code))
          .map(ad => ad.ad_code),
        addCandidates: matchingSet
          .filter(ad => !existingAds.includes(ad.ad_code))
          .map(ad => ad.ad_code)
      },
      {
        start_month,
        end_month
      }
    )
  }

  openMonthPicker = m => {
    this.setState({
      isMonthPickerOpen: true
    })
  }

  closeMonthPicker = () => {
    this.setState({
      isMonthPickerOpen: false
    })
  }

  applyMonthPicker = range => {
    const { updateRange } = this.props
    this.setState({
      isMonthPickerOpen: false
    })
    updateRange(range)
  }

  componentDidUpdate(prevProps, prevState) {
    const { range } = this.props

    const tagsChanged = this.props.adTags.reduce((changed, tag, index) => {
      return changed || tag.selected !== prevProps.adTags[index].selected
    }, false)

    const brandChanged = this.props.brandsMap.uuid !== prevProps.brandsMap.uuid

    const extraFilterChanged =
      this.state.extraFilterFilter !== prevState.extraFilterFilter ||
      this.props.extraFilter.data.uuid !== prevProps.extraFilter.data.uuid

    const rangeChanged =
      range.start_month !== prevProps.range.start_month ||
      range.end_month !== prevProps.range.end_month

    if (tagsChanged || brandChanged || extraFilterChanged || rangeChanged) {
      this.updateCandidates()
    }
  }

  render() {
    const {
      classes,
      maxRange,
      toggleBrand,
      setAllBrands,
      brands,
      brandsMap,
      toggleAdTag,
      adTags,
      range,
      genericConfig
    } = this.props
    const { isMonthPickerOpen } = this.state

    // FIXME: This is a total hack that needs to be fixed!!
    const _brands = brands.map(brand => {
      return {
        ...brand,
        selected: brandsMap[brand.code]
      }
    })

    return (
      <div style={{ padding: 25 }}>
        <Grid container spacing={2} className={classes.container}>
          <Grid item style={{ flex: '0 0 250px' }}>
            <PickDateButton range={range} onClick={this.openMonthPicker} />
            <ExtraFilter
              {...this.props}
              setFilter={this.setExtraFilterFilter}
              update={this.updateCandidates}
              updateFilter={this.updateFilter}
            />
          </Grid>
          <Grid item style={{ flex: '1 1 300px' }}>
            <BrandsList
              brands={_brands}
              toggle={toggleBrand}
              setAll={setAllBrands}
            />
          </Grid>
          <Grid item style={{ flex: '2 1 300px' }}>
            <div style={{ padding: 0, height: 375 }}>
              <AdTagsList adTags={adTags} toggle={toggleAdTag} config={genericConfig.MainHeader}/>
            </div>
          </Grid>
        </Grid>
        <MonthPicker
          maxRange={maxRange}
          range={range}
          open={isMonthPickerOpen}
          close={this.closeMonthPicker}
          apply={this.applyMonthPicker}
          title="Select Month Range"
        />
      </div>
    )
  }
}
const Connected = connect( state => {
})
export default Connected(Styled(SearchFilter))
