import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import { Grid, Dialog } from '@material-ui/core'
import {
  Tabs,
  SearchFilter,
  SearchManual,
  SearchActions,
  SearchToolbar
} from '../../new_components'

import {
  dashboardSearchToggleBrand,
  dashboardSearchSelectAllBrands,
  dashboardToggleAdTag,
  dashboardSetExtraFilter
} from '../../new_actions'

import { getAdsList, getSearchFilter } from '../../new_selectors'

import { generateUUID } from '../../new_utils'

const Styled = withStyles(theme => theme.components.selection.Search(theme))

class Search extends React.PureComponent {
  state = {
    selectedSearchTab: {
      tab: 'search'
    },
    searchInputText: '',
    views: {
      search: SearchFilter,
      manual: SearchManual
    },
    filters: {
      brands: {}
    },
    candidates: {
      addCandidates: [],
      removeCandidates: []
    },
    candidateRange: {}
  }

  updateSearchInputText = searchInputText => {
    this.setState({
      searchInputText
    })
  }

  componentDidMount() {
    console.log('Search Mounted')
    const {
      preset: { start_month, end_month },
      dispatch,
      searchFilter
    } = this.props
  dispatch( dashboardSetExtraFilter(searchFilter.extraFilter) )
    this.setState({
      candidateRange: {
        start_month,
        end_month
      }
    })
  }

  componentWillUnmount() {
    console.log('Search UJMounted')
  }

  updateRange = candidateRange => {
    this.setState({
      candidateRange
    })
  }

  updateCandidates = (candidates, candidateRange = {}) => {
    this.setState({
      candidates,
      candidateRange: candidateRange || this.state.candidateRange
    })
  }

  updateSearchTab = ({ tab, index }) => {
    this.setState({
      selectedSearchTab: {
        tab
      },
      candidates: {
        addCandidates: [],
        removeCandidates: []
      }
    })
  }

  add = () => {
    const { update, preset, metadataAdsMap } = this.props
    const {
      candidates: { addCandidates },
      candidateRange
    } = this.state

    this.setState({
      candidates: {
        ...this.state.candidates,
        addCandidates: []
      }
    })

    const newAdCodes = addCandidates.filter(
      ad_code => !preset.ad_codes.includes(ad_code)
    )
    const ad_codes = [...preset.ad_codes, ...newAdCodes]
    const ads = ad_codes.map(ad_code => metadataAdsMap[ad_code])

    const newRange = preset.ad_codes.length
      ? {
          start_month: Math.min(preset.start_month, candidateRange.start_month),
          end_month: Math.max(preset.end_month, candidateRange.end_month)
        }
      : candidateRange

    update({
      ...preset,
      label: `Custom set`,
      link: ads[0].links.thumbnail,
      ad_codes,
      ...newRange,
      is_standard: false,
      is_perishable: true,
      uuid: generateUUID(),
      id: -1
    })
  }

  remove = () => {
    const { update, preset, metadataAdsMap } = this.props
    const {
      candidates: { removeCandidates }
    } = this.state

    this.setState({
      candidates: {
        ...this.state.candidates,
        removeCandidates: []
      }
    })

    const ad_codes = preset.ad_codes.filter(
      ad_code => !removeCandidates.includes(ad_code)
    )
    const ads = ad_codes.map(ad_code => metadataAdsMap[ad_code])
    update({
      ...preset,
      label: `Custom set`,
      link: ads.length ? ads[0].links.thumbnail : null,
      ad_codes,
      is_standard: false,
      is_perishable: true,
      uuid: generateUUID(),
      id: -1
    })
  }

  toggleBrand = brand => {
    const { dispatch } = this.props
    const { code } = brand
    dispatch(dashboardSearchToggleBrand({ code }))
  }

  selectAllBrands = selected => {
    const { dispatch } = this.props
    dispatch(dashboardSearchSelectAllBrands({ selected }))
  }

  toggleAdTag = value => {
    const { dispatch } = this.props
    dispatch(dashboardToggleAdTag(value))
  }

  render() {
    const {
      classes,
      extraFilter,
      isOpen,
      close,
      adType,
      selections,
      adsList,
      adsMap,
      maxRange,
      brands,
      brandsMap,
      adTags,
      metadataAdsMap,
      selectedTab,
      calcFlags,
      config,
      genericConfig
    } = this.props
    const {
      views,
      selectedSearchTab,
      searchInputText,
      candidates: { addCandidates, removeCandidates },
      candidateRange
    } = this.state

    const SearchView = views[selectedSearchTab.tab]

    return (
        <Dialog
          maxWidth={false}
          open={isOpen}
          onClose={close}
          classes={{ paper: classes.paper }}
        >
          <Grid className={classes.container}>
            <SearchToolbar adType={adType} selections={selections} genericConfig={genericConfig.MainHeader}/>
            <Tabs
              tabs={config.tabs}
              selectedTab={this.state.selectedSearchTab}
              selectTab={this.updateSearchTab}
            />
            <SearchView
              brands={brands}
              brandsMap={brandsMap}
              range={candidateRange}
              maxRange={maxRange}
              updateRange={this.updateRange}
              updateCandidates={this.updateCandidates}
              adType={adType}
              selections={selections}
              adsList={adsList}
              adsMap={adsMap}
              metadataAdsMap={metadataAdsMap}
              toggleBrand={this.toggleBrand}
              setAllBrands={this.selectAllBrands}
              adTags={adTags}
              toggleAdTag={this.toggleAdTag}
              extraFilter={extraFilter}
              toggleExtraFilter={this.toggleExtraFilter}
              searchInputText={searchInputText}
              updateSearchInputText={this.updateSearchInputText}
              selectedTab={selectedTab}
              calcFlags={calcFlags}
              genericConfig={genericConfig}
              config={config}
            />
            <SearchActions
              close={close}
              add={this.add}
              remove={this.remove}
              numAdd={addCandidates && addCandidates.length}
              numRemove={removeCandidates && removeCandidates.length}
            />
          </Grid>
        </Dialog>
    )
  }
}

const Connected = connect(state => {
  const { dashboard } = state
  return {
    adsList: getAdsList(dashboard),
    selections: dashboard.selections.slice.sliceSelections,
    metadataAdsMap: dashboard.init.metadata.adsMap,
    brands: dashboard.init.metadata.brandsList,
    extraFilter: dashboard.selections.search.extraFilter,
    searchFilter: getSearchFilter(dashboard),
    maxRange: dashboard.selections.search.maxRange,
    brandsMap: dashboard.selections.search.brandsMap,
    adTags: dashboard.selections.tagHierarchy.tagTree,
    calcFlags: dashboard.scorecard.calcFlags,
    // FIXME: refactor the following!
    brandsSelect: dashboard.selections.search.brandsMap,
    config: dashboard.init.config.components.selection.search,
    genericConfig: dashboard.init.config.components.generic
  }
})
export default Connected(Styled(Search))
