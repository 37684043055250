export default {
    '***': {
      '***': {
        displayFilters: null,
        staticFilters: [
          {
            label: 'Business Size',
            code: 'size',
            multi: true,
            options: [
              {
                label: 'Micro',
                value: 1,
                selected: false
              },
              {
                label: 'Small',
                value: 2,
                selected: false
              },
              {
                label: 'Mid',
                value: 3,
                selected: false
              }
            ]
          },
          {
            label: 'Industry',
            code: 'industry',
            multi: true,
            options: [
              {
                label: 'Retail',
                value: 1,
                selected: false
              },
              {
                label: 'Food & Drink',
                value: 2,
                selected: false
              },
              {
                label: 'Professional Services',
                value: 3,
                selected: false
              },
              {
                label: 'Beauty & Personal Care',
                value: 4,
                selected: false
              },
              {
                label: 'Home & Repairs',
                value: 5,
                selected: false
              },
              {
                label: 'Health & Fitness',
                value: 6,
                selected: false
              },
              {
                label: 'Leisure & Entertainment',
                value: 7,
                selected: false
              },
              {
                label: 'Taxi',
                value: 8,
                selected: false
              }
            ]
          },

          {
            label: 'Length in Business',
            // excludeFromVerbatims: true,
            code: 'length',
            multi: true,
            options: [
              {
                label: 'Less than 5 years',
                value: 1,
                selected: false
              },
              {
                label: 'More than 5 years',
                value: 2,
                selected: false
              },
            ]
          },
          {
            label: 'Employees',
            code: 'employees',
            multi: true,
            options: [
              {
                label: '1',
                value: 1,
                selected: false
              },
              {
                label: '2+',
                value: 2,
                selected: false
              }
            ]
          },
          {
            label: '% Credit Card Transactions',
            code: 'transactions',
            multi: true,
            options: [
              {
                label: '0% - 24%',
                value: 1,
                selected: false
              },
              {
                label: '25% - 49%',
                value: 2,
                selected: false
              },
              {
                label: '50% - 74%',
                value: 3,
                selected: false
              },
              {
                label: '75% - 100%',
                value: 4,
                selected: false
              }
            ]
          },
          {
            label: 'Segment',
            // excludeFromVerbatims: true,
            code: 'segment',
            multi: true,
            options: [
              {
                label: 'Entrepreneurial Visionaries',
                value: 1,
                selected: false
              },
              {
                label: 'Balance-Seeking Skillmasters',
                value: 2,
                selected: false
              },
              {
                label: 'Self-Reliant Solopreneurs',
                value: 3,
                selected: false
              },
              {
                label: 'Obligated Caretakers',
                value: 4,
                selected: false
              },
              {
                label: 'Uncertain Experience Seekers',
                value: 5,
                selected: false
              },
              {
                label: 'Status-Driven Dreamers',
                value: 6,
                selected: false
              },
              {
                label: 'Pragamatic Opportunists',
                value: 7,
                selected: false
              },

            ]
          }
        ]
      }
    }
}