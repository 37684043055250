import QuickviewTemplate from '../../../../../Template/theme/components/quickview/Quickview'
import MetricTable from './MetricTable'
import MetricDonut from './MetricDonut'
import MetricChart from './MetricChart'
import RadarChart from './RadarChart'
import QuickviewCanvasContainer from './QuickviewCanvasContainer'

export default Object.assign(
  theme => QuickviewTemplate(theme),
  {
    ...QuickviewTemplate,
    QuickviewCanvasContainer,
    MetricTable,
    MetricDonut,
    MetricChart,
    RadarChart,
    Name: 'ComcastV2 Quickview'
  }
)
