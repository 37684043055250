import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DateIcon from '@material-ui/icons/DateRange'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { AdType } from '../../../'
import { monthCodeToText } from '../../../../new_utils'
const styles = theme => ({
  appBar: {
    position: 'fixed'
  },
  paper: {
    padding: '0px 20px',
    backgroundColor: theme.palette.background
  },
  boxShadow: {
    border: `1px solid ${theme.palette.background.default}`, //`1px ${theme.palette.background.default}`,
    boxShadow: `0 1px 1px #E7E7E7`
  },
  thumb: {
    boxShadow: theme.shadows[4],
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    display: 'inline-block',
    width: 50,
    height: 50
  },
  mainLabel: {
    fontFamily: 'OktaNeueRegular',
    fontWeight: 700
  },
  colorPrimary: {
    color: theme.palette.text.primary,
    background: theme.palette.background
  }
})

class DetailHeader extends Component {
  state = {}

  componentDidMount() {
    const { hasTrendchart } = this.props

    const tabs =
      hasTrendchart === false
        ? [
            {
              label: 'Word Cloud',
              key: 'wordcloud'
            }
          ]
        : [
            {
              label: 'Word Cloud',
              key: 'wordcloud'
            },
            {
              label: 'Trend Chart',
              key: 'trendchart'
            }
          ]

    this.setState({ tabs })
  }

  render() {
    const { ad, adType, openMedia, handleClose, children, classes } = this.props

    const start_month = ad.months[0].month_code
    const end_month = ad.months[ad.months.length - 1].month_code

    return (
      <Grid container justify="center">
        {/* <Grid item xs={12}>
            <AppBar className={classes.appBar}>
              <Toolbar>
              <IconButton onClick={handleClose} aria-label="Close">
              <CloseIcon />
              </IconButton>
              </Toolbar>
              </AppBar>
            </Grid> */}
        <Grid item xs={10} style={{ marginTop: 20 }} classeName={classes.colorPrimary}>
          <Paper elevation={0} className={classes.paper} classes={{ elevation0: classes.boxShadow }}>
            <Grid container justify="space-between">
              <Grid item style={{marginTop:15}}>
                <IconButton onClick={handleClose} aria-label="Close">
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Grid container style={{padding: '10px 0 10px 0'}} spacing={4} alignItems="flex-start" alignContent="center">
                  <Grid item onClick={() => openMedia(ad)}>
                    <div
                      className={classes.thumb}
                      style={{
                        marginTop: 5,
                        cursor: 'pointer',
                        backgroundImage: `url(${ad.links.thumbnail})`
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" color="textPrimary" classes={{ h4: classes.mainLabel }}>
                      {ad.title}
                    </Typography>
                    <Typography variant="body2" color="textPrimary" style={{ fontSize: 12 }}>
                      {ad.ad_code}
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginTop: 8 }}>
                    <AdType adType={adType} />
                  </Grid>
                  <Grid item>
                    <Grid container justify="flex-start" alignItems="center" spacing={1}>
                      <IconButton>
                        <DateIcon
                          classes={{
                            root: classes.colorPrimary
                          }}
                        />
                      </IconButton>
                      <Typography noWrap variant="body2" color="textPrimary">
                        {`${monthCodeToText(start_month)} - ${monthCodeToText(end_month)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item style={{paddingTop: 20}}>{children}</Grid>
            </Grid>
            {/* <Grid item xs={10}>
              <Tabs
                tabs={tabs}
                selectedTab={selectedTab}
                selectTab={selectTab}
              />
            </Grid> */}
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// DetailHeader.propTypes = {
//   classes: PropTypes.object.isRequired,
//   ad: PropTypes.shape({
//     months: PropTypes.array.isRequired,
//     title: PropTypes.string.isRequired,
//     links: PropTypes.object.isRequired
//   }).isRequired,
//   types: PropTypes.arrayOf(
//     PropTypes.shape({
//       value: PropTypes.string.isRequired,
//       label: PropTypes.string.isRequired,
//       selected: PropTypes.bool.isRequired
//     })
//   ).isRequired,
//   activeTab: PropTypes.number.isRequired,
//   switchTabs: PropTypes.func.isRequired,
//   openMedia: PropTypes.func.isRequired
// }

export default withStyles(styles)(DetailHeader)
