import React from 'react'
import { withRouter } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import HomeIcon from '@material-ui/icons/Home'

import { withStyles } from '@material-ui/core/styles'

const Styled = withStyles(theme =>
  theme.components.navigation.HomeButton(theme)
)
export default withRouter(
  Styled(
    props =>
      props.visible ? (
        <Tooltip id="home-tooltip" title="Home">
          <IconButton
            // color="inherit"
            onClick={e => {
              e.preventDefault()
              props.history.push('/')
            }}
          >
            <HomeIcon />
          </IconButton>
        </Tooltip>
      ) : null
  )
)
