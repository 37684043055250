import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import AdminIcon from '@material-ui/icons/Settings'
import { withStyles } from '@material-ui/core/styles'

const Styled = withStyles(theme =>
  theme.components.navigation.AdminButton(theme)
)

export default Styled(
  props =>
    props.visible ? (
        <IconButton
          style={props.style}
          // color="inherit"
          onClick={props.action}
        >
          <AdminIcon />
        </IconButton>
    ) : null
)
