import defaultTheme from '../../Template/theme'
import components from './components'

const theme = {
  ...defaultTheme,
  // palette: {
  //   ...defaultTheme.palette,
  //   secondary: {
  //     main: '#6138F5',
  //     light: '#9c67ff',
  //     dark: '#0040c1',
  //     contrastText: '#ffffff'
  //   }
  // },
  components
}

export {theme}
export default theme
