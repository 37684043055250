import Dashboard from '../../new_containers/dashboard'
import Auto from '../Auto'
import { withConfig } from '../../new_lib/'
import resourceConfig from './resourceConfig'

const Apps = resourceConfig.resources.filter(app => app.groupName !== 'ford-legacy').map(app => {
  const config = app.config
  const Component = config.config.main.isAuto ? Auto : Dashboard
  return {
    name: app.groupName,
    path: config.config.main.baseRoute,
    component: withConfig(config)(Component)
  }
})

export { resourceConfig }
export default Apps
