import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Excel from 'exceljs/dist/exceljs'
import { monthCodeToText } from '../../../../new_utils'


const dt = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export default class SetBuilderExport extends Component {
  constructor(props) {
    super(props)

    this.exportFile = this.exportFile.bind(this)
  }

  exportFile() {
    const { adList, columnFormat, selectedRankingMetric } = this.props
    const workbook = new Excel.Workbook()

    const adListSheet = workbook.addWorksheet('Ad Search List')
    adListSheet.columns = columnFormat

    adListSheet.getRow(1).font = { size: 12, bold: true }

    for (let i = 0; i < adList.length; i++) {
      adListSheet.addRow({
        ...adList[i],
        brand: adList[i].brand.label
      })
    }

    adListSheet.columns.forEach( (column, index) => {
      adListSheet.getColumn(index+1).eachCell(function(cell, rowNumber) {
        if (['title', 'brand', 'ad_code'].includes(column.key)) {
          cell.alignment = { vertical: 'middle', horizontal: 'left' }
        }
        else {
          cell.alignment = { vertical: 'middle', horizontal: 'center' }
        }

        if ( column.key === 'last_month_tested') { 
          if ( rowNumber > 1 ) cell.value = monthCodeToText(cell.value)
        }
        else if ( column.key === 'ranking_metric' ) {
          if (adList[0].ranking_metric_format && adList[0].ranking_metric_format.split(',')[0] === '%') {
            cell.numFmt = '0.0%'
          }
          else {
            cell.numFmt = '#'
          }
        }
      })
    })

    adListSheet.addRow()
    adListSheet.addRow()

    const row = adListSheet.lastRow
    
    row.getCell(1).value = `Ranking Metric: ${selectedRankingMetric}`
    row.commit()

    workbook.xlsx.writeBuffer({ base64: true }).then(function(xls64) {
      const blb = new Blob([xls64], { type: dt })
      const url = URL.createObjectURL(blb)

      const tag = document.createElement('a')
      tag.href = url
      tag.download = 'previewExport.xlsx'

      document.body.appendChild(tag)
      tag.click()

      setTimeout(function() {
        document.body.removeChild(tag)
        window.URL.revokeObjectURL(url)
      }, 0)
    })
  }

  render() {
    const { adList, children } = this.props
    const isDisabled = adList.length === 0

    return (
      <Button
        variant="outlined"
        color="secondary"
        disabled={isDisabled}
        onClick={this.exportFile}
      >
        {children || 'Export'}
      </Button>
    )
  }
}


