const DASHBOARD_ADMIN_INTERFACE_OPEN = 'DASHBOARD_ADMIN_INTERFACE_OPEN'
const DASHBOARD_ADMIN_INTERFACE_CLOSE = 'DASHBOARD_ADMIN_INTERFACE_CLOSE'

const dashboardAdminInterfaceOpen = isAuto => {
  return {
    type: DASHBOARD_ADMIN_INTERFACE_OPEN,
    data: { isAuto }
  }
}

const dashboardAdminInterfaceClose = () => {
  return {
    type: DASHBOARD_ADMIN_INTERFACE_CLOSE
  }
}

export {
  DASHBOARD_ADMIN_INTERFACE_OPEN,
  DASHBOARD_ADMIN_INTERFACE_CLOSE,
  dashboardAdminInterfaceOpen,
  dashboardAdminInterfaceClose
}
