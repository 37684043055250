export default {
  "groupName": "nissan",
  "label": "Nissan",
  "clientName": "Nissan",
  "sampleSizeMetric": 102,
  "adPiMetric": 106,
  "thresholds": {
    "minSampleSizeInScorecard": {
      "value": 30,
      "color": "#ffffff"
    },
    "medSampleSizeInScorecard": {
      "value": 50,
      "color": "#ff6c00"
    },
    "maxSampleSizeInScorecard": {
      "value": 75,
      "color": "#5c46c9"
    }
  },
  "stats": {
    "above": {
      "description":
        "Significantly above the norm at 90% CI",
      "color": "#444444",
      // "bgColor": "#aed581"
      "bgColor": "#e3f4ba"
    },
    "below": {
      "description": "Significantly below the norm at 90% CI",
      "color": "#444444",
      // "bgColor": "#ffb74d"
      "bgColor": "#fed099"
    }
  },
  "staticFilters": { "***": [
    {
      "label": "Gender",
      "code": "gender",
      "multi": false,
      "options": [
        {
          "label": "Male",
          "value": 1,
          "selected": false
        },
        {
          "label": "Female",
          "value": 2,
          "selected": false
        }
      ]
    },
    {
      "label": "Income",
      "code": "income",
      "multi": false,
      "options": [
        {
          "label": "$25k - $75k",
          "value": 1,
          "selected": false
        },
        {
          "label": "$75k+",
          "value": 2,
          "selected": false
        }
      ]
    },
    {
      "label": "Age",
      "code": "age",
      "multi": false,
      "options": [
        {
          "label": "21-39",
          "value": 1,
          "selected": false
        },
        {
          "label": "40-59",
          "value": 2,
          "selected": false
        }
      ]
    },
    {
      "label": "Purchase",
      "code": "purchase",
      "multi": false,
      "options": [
        {
          "label": "0-6 Month Intender",
          "value": 1,
          "selected": false
        }
      ]
    }
  ]},
  "displayFilters": [
    {
      "label": "Brands",
      "code": "brand",
      "multi": true
    }
  ],
  "styles": {
    "h0": {
      "bgColor": "#eeeeee",
      "color": "#3F537A",
      "fontWeight": "bold"
    },
    "h1": {
      "color": "#3F537A",
      "fontWeight": "bold",
      "offset": 1
    },
    "h2": {
      "color": "#3F537A",
      "bgColor": "#eeeeee",
      "fontWeight": "bold",
      "offset": 1
    },
    "d0": {},
    "d1": {
      "offset": 1
    },
    "d2": {
      "bgColor": "#ffffff",
      "offset": 2
    },
    "blank": {
      "bgColor": "#ffffff",
      "color": "#ffffff"
    },
    "t1": {
      "bgColor": "#eeeeee",
      "fontWeight": "bold",
      "color": "#3F537A",
      "offset": 1
    }
  },
  "routes": {
    "root": "/ford-us"
  }
}
