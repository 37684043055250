import template from '../../../../../../Template/config/components/scorecard/ScorecardView/ScorecardStatTesting'
export default {
  ...template,
  // labels: [
  //   {
  //     high: {
  //       color: '#20BA9D',
  //       text: 'Significantly above the norm at 90%'
  //     },
  //     low: {
  //       color: '#F37B63',
  //       text: 'Significantly below the norm at 90%'
  //     }
  //   }
  // ]
}
