import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import cn from 'classnames'

const Styled = withStyles(theme =>
  theme.components.scorecard.ScorecardView.ScorecardScroller.ScorecardFixedColumn.ScorecardMainHeader(theme)
)

export default Styled(props => {
  const {
    classes,
    collapsed,
    gridData: {
      normHeaders: { header_metrics },
      perksList
    }
  } = props
  return (
    <Grid
      container
      id="main-header"
      direction="column"
      justify="center"
      alignItems="flex-start"
      className={classes.container}
    >
      <Grid item className={cn(classes.headerGrid, classes.top)}>
        <Typography variant="body2">Offer Code</Typography>
      </Grid>
      <Grid item className={cn(classes.headerGrid)}>
        <Typography noWrap variant="body2">
          Offer Headline
        </Typography>
      </Grid>
      {collapsed ? null : (
        <Fragment>
          <Grid item className={cn(classes.headerGrid)}>
            <Typography variant="body2">Speed/Data</Typography>
          </Grid>

          <Grid className={classes.headerGrid} item>
            <Typography variant="body2">Price</Typography>
          </Grid>
          <Grid item className={classes.headerGrid}>
            <Typography noWrap variant="body2">
              Terms
            </Typography>
          </Grid>
          <Grid item className={classes.headerGrid}>
            <Typography noWrap variant="body2">
              Added Savings
            </Typography>
          </Grid>

          {perksList.map(perk => (
            <Grid item key={perk.label} className={cn(classes.headerGrid)}>
              <Typography noWrap variant="body2" align="center" display="block">
                {perk}
              </Typography>
            </Grid>
          ))}

          <Grid item className={classes.headerGrid}>
            <Typography noWrap variant="body2">
              Market Status
            </Typography>
          </Grid>
        </Fragment>
      )}
      <Grid item className={classes.headerGrid}>
        <Typography noWrap variant="body2">
          Month Tested
        </Typography>
      </Grid>

      {collapsed ? null : (
        <Fragment>
          <Grid item className={classes.headerGrid}>
            <Typography noWrap variant="body2">
              Sample Size
            </Typography>
          </Grid>
        </Fragment>
      )}
      {header_metrics.map(header_metric => (
        <Grid item key={header_metric.label} className={cn(classes.headerGrid)} >
          <Typography noWrap variant="body2" align="center" className={classes.headerMetric}>
            {header_metric.label}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
})
