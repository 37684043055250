import React from 'react'
import { connect } from 'react-redux'

import AdminConfigView from './AdminConfigView'
import AdminDataMigrationView from './AdminDataMigrationView'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import {
  adminFetch,
  adminDelete,
  adminUpdate,
  appSetWarning
} from '../../../new_actions'

const Styled = withStyles(theme =>
  theme.components.adminView.AdminConfigManager(theme)
)

class AdminConfigManager extends React.PureComponent {

  apply = (type, data) => {
    const { dispatch } = this.props
    dispatch(adminUpdate(type, data))
  }

  testMigration = migration => {
    const { dispatch } = this.props
    if (!migration.filter.tables) {
      dispatch(
        appSetWarning(
          `Nothing to test! Migration test is intended to verify effect of a database migration on one or more destination tables. 
          Must select at least one database table.`
        )
      )
    } else dispatch(adminFetch('migration/test', migration))
  }

  fetchConfig = () => {
    const { dispatch } = this.props
    dispatch(adminFetch('config/config'))
  }

  fetchMigrationStructure = () => {
    const { dispatch } = this.props
    dispatch(adminFetch('config/migration'))
  }

  fetchMigrations = (all = true) => {
    const { dispatch } = this.props
    dispatch(adminFetch('migration'), { all })
  }

  commitMigration = migration => {
    const { dispatch } = this.props
    const { tables, startMonth, endMonth, ...rest } = migration.filter
    if (!tables && !Object.keys(rest).length) {
      dispatch(
        appSetWarning(
          `Nothing to migrate! Migration sychronizes database tables or server configuration of current server to staging.
           Must select at least a database table or a configuration to migrate.`
        )
      )
    } else {
      dispatch(adminUpdate('migration', migration))
    }
  }

  acknowledgeMigration = migration => {
    const { dispatch } = this.props
    dispatch(adminUpdate(`migration/ack`, { id: migration.migration_id }))
  }

  cancelMigration = migration => {
    const { dispatch } = this.props
    dispatch(adminDelete(`migration`, { id: migration.migration_id }))
  }

  componentDidMount() {
    const {
      dispatch,
      data: {
        serverConfig,
        dataMigration
      },
      environment
    } = this.props
    if (!serverConfig.ready)
      dispatch(adminFetch('config/config'))

    if (!dataMigration.migrations.ready) {
      dispatch(adminFetch('config/migration'))
      dispatch(adminFetch('migration'))
    }


    if (environment === 'production')
      dispatch(appSetWarning('WARNING: You are editing a PRODUCTION server'))
  }

  componentDidUpdate(prevProps) {
    const {
      data: {
        dataMigration: {
          migrationTest: { status }
        }
      },
      dispatch
    } = this.props
    if (
      status &&
      prevProps.data.dataMigration.migrationTest.status !== status
    ) {
      dispatch(appSetWarning(status))
    }
  }

  render() {
    const {
      environment,
      data: { dataMigration, serverConfig },
      appName
    } = this.props

    return (
      <Grid>
        {environment === 'staging' ? null : (
          <AdminDataMigrationView
            dataMigration={dataMigration}
            testMigration={this.testMigration}
            commitMigration={this.commitMigration}
            acknowledgeMigration={this.acknowledgeMigration}
            cancelMigration={this.cancelMigration}
            refreshMigrationSource={this.fetchMigrationStructure}
            refreshMigrationStatus={this.fetchMigrations}
            appName={appName}
            environment={environment}
          />
        )}
        <AdminConfigView
          serverConfig={serverConfig}
          apply={this.apply}
          refresh={this.fetchConfig}
          appName={appName}
        />
      </Grid>
    )
  }
}

const Connected = connect(state => {
  return {}
})
export default Connected(Styled(AdminConfigManager))
