import axios from 'axios'

export const FORD_VIEWER_OPEN_REQUEST = 'FORD_VIEWER_OPEN_REQUEST'
export const FORD_VIEWER_OPEN_RECIEVE = 'FORD_VIEWER_OPEN_RECIEVE'
export const FORD_VIEWER_OPEN_FAILURE = 'FORD_VIEWER_OPEN_FAILURE'

export const FORD_VIEWER_HIDE = 'FORD_VIEWER_HIDE'

function viewerOpenRequest(adCode, monthCode, title) {
  return {
    type: FORD_VIEWER_OPEN_REQUEST,
    adCode,
    monthCode,
    title,
  }
}

function viewerOpenReceive(data) {
  return {
    type: FORD_VIEWER_OPEN_RECIEVE,
    data: data,
  }
}

function viewerOpenFailure(data) {
  return {
    type: FORD_VIEWER_OPEN_FAILURE,
    data: data,
  }
}

export function viewerHide() {
  return {
    type: FORD_VIEWER_HIDE,
  }
}

export function fetchViewerLink(adCode, monthCode, title, config) {
  return dispatch => {
    dispatch(viewerOpenRequest(adCode, monthCode, title))
    const { groupName } = config
    return axios.get(`link`, { params: { adCode, monthCode, groupName } }).then(
      res => {
        dispatch(viewerOpenReceive(res.data))
      },
      err => {
        dispatch(viewerOpenFailure(err.message || ''))
      },
    )
  }
}

function shouldFetchViewerLink(state) {
  const {
    app,
    ford: { viewer },
  } = state

  if (app.ready === false) {
    return false
  }

  if (viewer.isFetching === true) {
    return false
  }

  if (viewer.hasErrored === true) {
    return false
  }

  return true
}

export function fetchViewerLinkIfNeeded(adCode, monthCode, title, config) {
  return (dispatch, getState) => {
    if (shouldFetchViewerLink(getState())) {
      return dispatch(fetchViewerLink(adCode, monthCode, title, config))
    }
  }
}
