const DASHBOARD_SELECT_ADS_TAB = 'DASHBOARD_SELECT_ADS_TAB'

const dashboardSelectAdsTab = data => {
  return {
    type: DASHBOARD_SELECT_ADS_TAB,
    data
  }
}

export { DASHBOARD_SELECT_ADS_TAB, dashboardSelectAdsTab }
